<template>
    <div v-if="roadSigns && roadSigns.length > 0" class="nopads">
        <b-row>
            <span class="section-title">{{ $t('contract_reports.road_signs') }} ({{roadSigns.length}})</span>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : signsVisible}"
                    v-b-toggle.signDetails
                    @click.stop="signsVisible = !signsVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="signDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <road-sign-list
                :road-signs="roadSigns"
                @rowClicked="editRoadSign"
            />
        </b-collapse>
        <hr class="report-divider nopads"/>
        <road-sign-wizard
            v-if="editSign != null"
            :road-sign="editSign"
            :is-admin="isAdmin"
            :user="user"
            @close="editSign = null"
            @closeAndUpdate="closeAndUpdate"
        />
    </div>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import RoadSignList from "../roadsign/RoadSignList";
import RoadSignWizard from "../roadsign/RoadSignWizard";

export default {
    name: "contract-report-road-signs",
    components: {RoadSignWizard, RoadSignList},
    mixins: [restApi],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        user: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            signsVisible: false,
            roadSigns: [],
            editSign: null
        }
    },
    watch: {
        searchParams() {
            this.fetchRoadSigns()
        }
    },
    mounted() {
        this.fetchRoadSigns()
    },
    methods: {
        async fetchRoadSigns() {
            if (this.searchParams && this.searchParams.dateFrom && this.searchParams.dateTo && this.searchParams.contract) {
                this.roadSigns = []
                this.$emit('loading', true)
                let params = {
                    created_from: this.searchParams.dateFrom,
                    created_to: this.searchParams.dateTo,
                    contract: this.searchParams.contract
                }
                try {
                    const response = await new Promise(
                        (resolve, reject) => this.restFetchParams(this.roadSignUrl, params, resolve, reject)
                    );
                    this.roadSigns = response.data
                }
                finally {
                    this.$emit('loading', false)
                }
            }
        },
        editRoadSign(data) {
            this.editSign = data
        },
        closeAndUpdate() {
            this.editSign = null
            this.fetchRoadSigns()
        }
    }
}
</script>
