<template>
    <div class="col-12 nopads">
        <work-assignment-filter
            v-show="selectedContractIds && selectedContractIds.length > 0"
            ref="workAssignmentFilter"
            @filterSelected="updateStatusFilter"
            :selected-contract-ids="selectedContractIds"
        />
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import {workAssignmentHelper} from '../mixins/WorkAssignmentMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import WorkAssignmentFilter from "@/components/workassignment/WorkAssignmentFilter";

export default {
    name: 'WorkAssignmentMap',
    components: {WorkAssignmentFilter},
    mixins: [timeUtils, mapHelper, restApi, workAssignmentHelper, geometryMixin],
    props: {
        workAssignmentResults: {
            type: Array,
            default() {
                return null
            }
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
        selectedOrder: {
            type: Number,
            default: null
        },
        workAssignmentItem: {
            type: Object,
            default: null
        },
        map: {
            type: Object,
            default: null
        },
        infoVisible: {
            type: Boolean,
            default: true
        },
        zoomOnDrawMarker: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        boundingBox: {
            type: Array,
            default: null
        },
        requireBoundingBox: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            detailsMode: false,
            editMode: false,
            workAssignments: [],
            visibleWorkAssignments: [],
            editWorkAssignments: [],
            typeEntries: [],
            selectedTypes: [],
            workAssignment: null,
            listOpen: false,
            allSelected: false,
            visibilityZoomLevel: 5,
            bbox: null,
            status: null,
            TYPE: {
                POINT: 1,
                POLY_LINE: 2
            },
            Z_INDEX: {
                BEHIND: 2,
                INFRONT: 5
            }
        }
    },

    watch: {
        boundingBox() {
            if (!this.workAssignmentResults) {
                this.updateWorkAssignments()
            }
        },
        selectedContractIds() {
            if (!this.workAssignmentResults) {
              this.updateWorkAssignments(true)
            }
        },
        selectedTypes(newVal) {
            if (!newVal.length === 0) {
                this.allSelected = false
            } else if (newVal.length === this.typeEntries.length) {
                this.allSelected = true
            } else {
                this.allSelected = false
            }
            this.filterByTypes()
        },
        'workAssignment.geometry': {
            handler: function () {
                if (this.workAssignment) {
                    this.drawWorkAssignment(this.workAssignment)
                }
            },
            deep: true
        }
    },
    mounted: function () {
        this.init()
    },
    methods: {
        init() {
            // set to default value if there are no selected contracts
            this.$refs.workAssignmentFilter.setDefaultValue()
            if (this.map) {
                if (this.workAssignmentResults) {
                    this.initView(false)
                } else {
                    this.bbox = this.map.getViewBoundsCoordinates()
                    if(this.selectedContractIds && this.selectedContractIds.length === 0) {
                        // in init state set it to default value
                        this.$refs.workAssignmentFilter.setDefaultValue()
                    } else {
                        this.updateWorkAssignments(true)
                    }
                }
            }
        },
        updateStatusFilter(newStatus) {
            this.status = newStatus
            if (!this.workAssignmentResults) {
                this.updateWorkAssignments(true)
            }
        },
        async updateWorkAssignments(force) {
            if (this.map) {
                if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                    this.bbox = null
                    this.hideAssignments()
                } else if (this.status && (force || (!this.requireBoundingBox || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))))) {
                    this.loading = true
                    if (this.requireBoundingBox && this.boundingBox) {
                        const extendRate = 0.1
                        this.bbox = [
                            this.boundingBox[0] - extendRate,
                            this.boundingBox[1] - extendRate,
                            this.boundingBox[2] + extendRate,
                            this.boundingBox[3] + extendRate
                        ]
                    }
                    this.workAssignments = await this.fetchWorkAssignments() || []
                    this.initView(true)
                    this.loading = false
                }
            }
        },
        async fetchWorkAssignments() {
            let params = {}
            if(this.selectedOrder) {
                params.order = this.selectedOrder
            }
            if (this.bbox) {
                params.bbox = this.bbox
            }
            if (this.status && this.status.length > 0) {
                params.status = this.status.includes(0) ? null : this.status;
            }
            params.order = this.selectedOrder
            if(this.selectedContractIds) {
                params.contract = this.selectedContractIds
            } else if(this.selectedContractId) {
                params.contract = this.selectedContractId
            }
            try {
                return (await new Promise((resolve, reject) =>
                    this.restFetchParams(
                            this.workAssignmentUrl, params ,resolve, reject))).data
            } catch (e) {
                return null
            }
        },
        initView: function (skipAssign) {
            if (this.workAssignments || this.workAssignment) {
                this.hideAssignments()
            }
            if (!skipAssign) {
                this.workAssignments = this.workAssignmentResults
                this.workAssignment = this.workAssignmentItem
            }
            if (this.workAssignment) {
                this.drawWorkAssignment(this.workAssignment, this.infoVisible)
            } else if (this.workAssignments && this.workAssignments.length > 0) {
                this.visibleWorkAssignments = this.workAssignments
                this.initTypeEntries()
                this.filterByTypes()
            }
        },

        drawWorkAssignments: function () {
            this.visibleWorkAssignments.forEach(function (workAssignment) {
                this.drawWorkAssignment(workAssignment, false)
            }, this)
        },

        drawWorkAssignment: function (workAssignment) {

            var pointToZoom = null
            var markerStore = this.map.getMarkerStore()
            let markerPosition = this.getWorkAssignmentPointPosition(workAssignment)
            if (markerPosition != null){
                let icon = workAssignment.status > this.ASSIGNED ? markerStore.getClosedWorkAssignmentMarkerIcon() : markerStore.getWorkAssignmentMarkerIcon(workAssignment.status === this.OPEN)
                this.map.showMapMarker(workAssignment.id, this.WORK_ASSIGNMENT, markerPosition.y,
                    markerPosition.x, icon, this.draggable, workAssignment.closed_time ? this.Z_INDEX.BEHIND : this.Z_INDEX.INFRONT)
            }
            let geometry = null
            if (workAssignment.geometry) {
                geometry = workAssignment.geometry
            }else if(workAssignment.observation) {
                geometry = workAssignment.observation.geometry
            }
            if (geometry) {
                let assignmentStrokeColor = '#219ade'
                if (geometry.polygon) {
                    this.map.drawPolygon(workAssignment.id, geometry.polygon.rings[0], this.WORK_ASSIGNMENT, assignmentStrokeColor, '#219ade', 0.2)
                }else if (geometry.line_string) {
                    var xyLine = geometry.line_string.points.map(point => {
                        return {x: point[0], y: point[1]}
                    })
                    this.map.drawPolyLine(workAssignment.id, this.WORK_ASSIGNMENT, xyLine, assignmentStrokeColor)
                }
                if (this.zoomOnDrawMarker) {
                    pointToZoom = markerPosition
                    this.map.zoomToPosition(pointToZoom.y, pointToZoom.x)
                }
            }
        },

        hideAssignments: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.WORK_ASSIGNMENT)
            }
            this.visibleWorkAssignments = []
            this.workAssignment = null
            this.showDetails = false
        },

        onMarkerTap: function (data) {
            if (data.type && data.type === this.WORK_ASSIGNMENT) {
                if (this.workAssignment && data.id === this.workAssignment.id && this.detailsMode) {
                    this.hideWorkAssignmentDetails()
                } else {
                    this.showWorkAssignmentDetails(data)
                }
            }
        },

        showWorkAssignmentTitle: function (data) {
            // Show info bubble
            if (data.type === this.WORK_ASSIGNMENT) {
                let item = this.workAssignments.find(o => o.id === data.id)
                if (item) {
                    let x, y
                    if (item.geometry.point) {
                        x = item.geometry.point.x
                        y = item.geometry.point.y
                    }
                    if (item.geometry.line_string) {
                        x = item.geometry.line_string[0].x
                        y = item.geometry.line_string[0].y
                    }
                    if (item) {
                        this.map.addMarkerLabel(item.id, item.name, y, x)
                    }
                }
            }
        },

        hideWorkAssignmentTitle: function (data) {
            // Hide info bubble
            this.map.hideMarkerLabel(data.id)
        },

        showWorkAssignmentDetails: function (workAssignment) {
            this.workAssignment = workAssignment
            this.editWorkAssignments = [this.workAssignment]
            if (this.workAssignment) {
                this.detailsMode = true
            }
        },

        hideWorkAssignmentDetails: function () {
            this.detailsMode = false
        },

        hideWorkAssignmentEditor: function () {
            this.editMode = false
        },

        editWorkAssignment: function () {
            this.detailsMode = false
            this.editMode = true
            this.$emit('scrollTop')
        },

        closeAndUpdate: function () {
            this.editMode = false
            this.$emit('updateWorkAssignments')
        },

        initTypeEntries: function () {
            if (this.selectedContractId || (this.selectedContractIds && this.selectedContractIds.length > 0)) {
                this.typeEntries = []
                if (this.workAssignments && this.workAssignments.length > 0) {
                    this.workAssignments.forEach(item => {
                        let existingType = this.typeEntries.find(element => element.value === item.type_id)
                        if (!existingType && item.name && item.value) {
                            this.typeEntries.push({text: item.name, value: item.type_id})
                        }
                    })
                    this.sortTypeEntries()
                }
            }
        },

        sortTypeEntries: function () {
            this.typeEntries.sort(function (a, b) {
                if (a.text < b.text) {
                    return -1;
                }
                if (a.text > b.text) {
                    return 1;
                }
                return 0;
            });
        },


        filterByTypes: function () {
            this.hideAssignments()
            this.visibleWorkAssignments = []
            if (this.allSelected || !this.selectedTypes || this.selectedTypes.length < 1) {
                this.visibleWorkAssignments = this.workAssignments
            } else {
                this.workAssignments.forEach(item => {
                    let selectedType = this.selectedTypes.find(element => element === item.type_id)
                    if (selectedType) {
                        this.visibleWorkAssignments.push(item)
                    }
                })
            }
            this.drawWorkAssignments()
        },

        toggleAll(checked) {
            let temp = []
            if (checked) {
                this.typeEntries.forEach(item => {
                    temp.push(item.value)
                })
            }
            this.selectedTypes = temp
        },
        getWorkAssignment: function (id) {
            return this.workAssignments.find(workAssignment => workAssignment.id === id)
        },
        getVisibleWorkAssignments() {
            return this.visibleWorkAssignments
        }
    }
}
</script>
