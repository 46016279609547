<template>
    <div>
        <div
            v-if="loading"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from '../mixins/MapMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";

export default {
    name: "WeatherStationMap",
    mixins: [restApi, mapHelper, geometryMixin],
    props: {
        map: {
            type: Object
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        requireBoundingBox:{
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            weatherStations: [],
            bbox: null,
            visible: 1,
            visibilityZoomLevel: 10,
            loading: false,
            listOpen: false,
        }
    },
    mounted() {
        if (this.map && (!this.requireBoundingBox || this.isBBoxValid(this.boundingBox))) {
            this.updateWeatherStations()
        }
    },
    beforeDestroy() {
        this.hideMarkers()
    },
    watch: {
        map() {
          this.$nextTick(function() {
            this.updateWeatherStations()
          })
        },
        boundingBox() {
            this.updateWeatherStations()
        },
    },
    methods: {
        async updateWeatherStations(editorUpdate = false) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideWeatherStations()
            } else if (!this.requireBoundingBox || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox))) || editorUpdate) {
                const extendRate = 0.05
                if (this.requireBoundingBox) {
                    this.bbox = [
                        this.boundingBox[0] - extendRate,
                        this.boundingBox[1] - extendRate,
                        this.boundingBox[2] + extendRate,
                        this.boundingBox[3] + extendRate
                    ]
                } else {
                    this.bbox = null
                }
                this.loading = true
                this.weatherStations = []
                this.weatherStations = await this.fetchWeatherStations() || []
                this.loading = false
                this.refreshMarkers()
            }
        },
        refreshMarkers() {
            this.hideMarkers()
            this.showMarkers()
        },
        showMarkers() {
            if (this.weatherStations && this.weatherStations.length > 0 && this.map != null) {
                for (let index in this.weatherStations) {
                    let weatherStation = this.weatherStations[index]
                    this.map.showMapMarker(weatherStation.id, this.WEATHER_STATION, weatherStation.position.y,
                        weatherStation.position.x, this.map.getMarkerStore().getWeatherStationMarkerIcon())
                }
            }
        },
        hideMarkers() {
            if (this.map != null) {
                this.map.removeMapMarkerByType(this.WEATHER_STATION)
            }
        },
        hideWeatherStations() {
            this.weatherStations = []
            this.refreshMarkers()
        },
        showStorageTitle: function (data) {
            // Show info bubble
            if (data.type === this.WEATHER_STATION) {
                let item = this.weatherStations.find(s => s.id === data.id)
                if (item) {
                    let x, y
                    if(item.location && item.location.x) {
                        x = item.location.x
                        y = item.location.y
                    }
                    if (item) {
                        this.map.addMarkerLabel(item.id, item.name, y, x, data.type)
                    }
                }
            }
        },
        hideWeatherStationTitle: function (data) {
            // Hide info bubble
            this.map.hideMarkerLabel(data.id, data.type)
        },
        async fetchWeatherStations() {
            let params = {}

            try {
                return (await new Promise((resolve, reject) => this.restFetchParams(this.weatherStationsUrl, params, resolve, reject))).data
            } catch (e) {
                return null
            }
        },

        getWeatherStation(id) {
            return this.weatherStations.find(weatherStation => weatherStation.id === id)
        },

    }
}
</script>

<style scoped>

</style>
