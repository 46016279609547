<template>
    <base-modal
        v-if="value !== null"
        @cancel="$emit('cancel')"
    >
        <material-station-editor
            :station="value"
            @closeAndUpdate="submitStation"
            @cancel="$emit('cancel')"
            :is-observer="isObserver"
            :is-worker="isWorker"
            :disabled="loading"
        />
        <div
            v-if="loading"
            class="spinner"
        />
    </base-modal>
</template>

<script>
import BaseModal from "@/components/BaseModal";
import {EventBus} from '@/event-bus'
import {restApi} from "@/components/mixins/RestApiMixin";
import MaterialStationEditor from "./MaterialStationEditor";

export default {
    name: "MaterialStationModal",
    components: {MaterialStationEditor, BaseModal},
    mixins: [restApi],
    props: {
        value: {
            type: Object
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        isWorker: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        submitStation: function (station) {
            this.loading = true
            if (station.id < 0) {
                this.restAdd(this.materialStationUrl, station, this.success, this.fail)
            } else {
                this.restUpdate(this.materialStationUrl, station, this.success, this.fail)
            }
        },
        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        }
    }
}
</script>
