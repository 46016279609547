<template>
    <b-container fluid
                 class="details-container"
    >
        <div class="col-sm-12 nopads">
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-title editor-title">
                    <h2
                        v-if="roadSignUpdate.id > 0 || roadSigns.length === 1"
                        class="form-title editor-title"
                    >
                        {{ $t('road_sign.edit') }}
                    </h2>
                    <h2
                        v-else-if="roadSigns && roadSigns.length > 1"
                        class="form-title editor-title"
                    >
                        {{ $t('road_sign.edit_multiple') }}
                    </h2>
                    <h2
                        v-else
                        class="form-title editor-title"
                    >
                        {{ $t('road_sign.add_new') }}
                    </h2>
                </div>
                <div class="col-sm-12 form-data">
                    <!-- Show open contracts  -->
                    <div class="col-12 nopads">
                        <b-form-group
                            class="title nopads"
                            label-for="contract"
                        >
                            <span class="span-title">{{ $t('orders.contract') }}</span>
                            <b-form-select
                                id="contract"
                                v-model="roadSignUpdate.contract.id"
                                size="sm"
                                class="sm-3"
                                :disabled="roadSignUpdate.remove === '1'"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('orders.select_contract') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in contracts"
                                    :key="option.id"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-12 nopads">
                        <b-form-group
                            class="title nopads"
                            label-for="contract"
                        >
                            <span class="span-title">{{ $t('road_sign.condition') }}</span>
                            <b-form-select
                                id="contract"
                                v-model="roadSignUpdate.condition"
                                size="sm"
                                class="sm-3"
                                :disabled="roadSignUpdate.remove === '1'"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('road_sign.select_condition') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in getConditions()"
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-12 nopads mt-2">
                        <b-form-group label-for="remove">
                            <b-form-checkbox
                                v-model="roadSignUpdate.remove"
                                size="md"
                                type="text"
                                value=1
                                unchecked-value=0>
                                {{ $t('road_sign.remove') }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>

                    <div class="col-sm-12 button-container">
                        <b-button
                            variant="danger"
                            class="routa-button"
                            @click.stop="$emit('closeForm')"
                        >
                            <i class="fa fa-times"/>
                            <span class="button-text">
                              {{ $t('common.cancel') }}
                            </span>
                        </b-button>
                        <b-button
                            variant="success"
                            class="routa-button"
                            :disabled="loading"
                            @click.stop="submitMultiple"
                        >
                            <i class="fa fa-save"/>
                            <span class="button-text">
                              {{ $t('common.save') }}
                            </span>
                        </b-button>
                    </div>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </b-container>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import {attachmentHelper} from "../mixins/AttachmentMixin";
import {workAssignmentHelper} from "../mixins/WorkAssignmentMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {timeUtils} from "../mixins/TimeUtils";
import {EventBus} from "../../event-bus";

export default {
    name: "RoadSignBulkEditorDialog",
    mixins: [workAssignmentHelper, restApi, timeUtils, attachmentHelper, roadSignHelper],
    props: {
        roadSigns: null,
        isAdmin: Boolean,
        isObserver: Boolean,
    },
    data: function (){
        return{
            roadSignUpdate: this.newRoadSignMultiple(this.roadSign),
            contracts: [],
            loading: false,
        }
    },
    mounted: function() {
        if (this.isAdmin || this.isObserver) {
            this.fetchOpenContracts()
        }
    },
    methods: {
        newRoadSignMultiple: function (like) {
            return {
                contract: like && like.contract ? like.contract : {id: null},
                condition: like && like.condition ? like.condition : null,
                remove: like && like.remove ? true : undefined,
                ...like
            }
        },
        submitMultiple:function (){
            let newRoadSign = this.setDefaultValues(this.roadSignUpdate)
            if (newRoadSign) {
                this.loading = true
                if(!this.roadSigns) this.fail
                var roadSignIds = []
                this.roadSigns.forEach(function (roadSign) {
                    roadSignIds.push(roadSign.id)
                })
                let params = {}
                if(newRoadSign.remove) {
                    params.remove = 1
                } else {
                    if(newRoadSign.contract) {
                        params.contract = newRoadSign.contract
                    }
                    if(newRoadSign.condition) {
                        params.condition = newRoadSign.condition
                    }
                }
                params.roadsign_ids = roadSignIds
                this.restUpdate(this.roadSignsUrl, params, this.success, this.fail)
            }
        },
        success: function (roadSign) {
            this.roadSignUpdate = roadSign
            this.loading = false
            this.$emit('closeAndUpdate', this.roadSignUpdate)
            this.$emit('showRoadSigns')
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        setDefaultValues: function (roadSign) {
            roadSign = JSON.parse(JSON.stringify(roadSign))
            if (roadSign.contract && roadSign.contract.id) {
                roadSign.contract = roadSign.contract.id
            } else {
                roadSign.contract = undefined
            }
            if (!roadSign.condition) {
                roadSign.condition = undefined
            }
            if (roadSign.remove) {
                roadSign.remove = true
            } else {
                roadSign.remove = undefined
            }
            return roadSign
        },
    }
}
</script>

<style scoped>

</style>
