<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
            @click="closeAndUpdate"
        >
            <div
                v-if="!editMode && !showWizard"
                class="header"
            >
                <span class="title">{{ $t('menu.sewer_lines') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="closeAndUpdate"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition-group name="fade">
                <div key="search-sewer-line-list">
                    <keep-alive>
                        <storm-sewer-line-search
                            v-if="!editMode && !showWizard"
                            :user="user"
                            @search="fetchSewerLines"
                        />
                    </keep-alive>
                    <keep-alive>
                        <storm-sewer-line-list
                            v-if="!editMode && !showWizard"
                            :storm-sewer-lines="results"
                            @rowClicked="editSewerLine"
                        />
                    </keep-alive>
                </div>
            </transition-group>
            <transition name="fade">
                <sewer-line-details
                    v-if="editMode"
                    v-model="sewerLine"
                    :user="user"
                    @showList="showList"
                    @closeAndUpdate="closeAndUpdate"
                    @scrollTop="scrollTop"
                />
            </transition>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {EventBus} from "@/event-bus";
import {downloadHelper} from "../mixins/DownloadMixin";
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import StormSewerLineSearch from "@/components/stormsewers/StormSewerLineSearch";
import SewerLineDetails from "@/components/stormsewers/SewerLineDetails";
import StormSewerLineList from "@/components/stormsewers/StormSewerLineList";

export default {
    name: 'StormSewerLines',
    components: {
        StormSewerLineList, StormSewerLineSearch,
        SewerLineDetails
    },
    mixins: [restApi, downloadHelper, stormSewersMixin],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            results: [],
            sewerLine: null,
            editMode: false,
            showWizard: false,
            loading: false,
            lastSearchedParams: null,
            showFileImporter: false,
            scrollPosition: 0
        }
    },

    methods: {
        fetchSewerLines(params) {
            this.loading = true
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.stormSewerUrl, this.lastSearchedParams, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        },
        editSewerLine: function (item) {
            this.editMode = true
            this.sewerLine = this.jsonToItem(item)
        },
        closeAndUpdate: function () {
            this.sewerLine = null
            this.fetchSewerLines(this.lastSearchedParams)
            this.showList()
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.powerStationXlsxUrl, this.getCommonReportParams(this.lastSearchedParams), this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('power_stations.report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
        showList: function () {
            this.editMode = false
            this.showWizard = false
        },
        scrollTop: function () {
            this.scrollPosition = this.$refs.container.scrollTop
            this.$nextTick(function () {
                this.$refs.container.scrollTop = 0
            })
        }
    }
}
</script>
