<template>
    <base-modal style="z-index: 50">
        <div class="col-12">
            <h3>{{ drain && drain.id ? $t('storm_sewers.edit_drain') : $t('storm_sewers.add_drain') }}</h3>
        </div>

        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div class="col-sm-12 nopads">
            <b-form v-if="drain" class="col-sm-12 editor-form">
                <h4 class="form-header">{{ $t('street_lights.basic_info')}}</h4>
                <b-row class="nopads">
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('storm_sewers.status') }}</span>
                        <b-form-select id="lightStatus" size="sm" v-model="drain.status" :options="statusOptions">
                            <template #first>
                                <option :value="null">{{ $t('street_lights.select_status') }}</option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('storm_sewers.radius') }}</span>
                        <b-form-input
                            id="radius"
                            v-model="drain.radius"
                            type="number"
                            min="0"
                            size="sm"/>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.construction_year') }}</span>
                        <b-form-input
                            id="lightYear"
                            v-model="drain.construction_year"
                            type="number"
                            min="0"
                            size="sm"/>
                    </div>
                </b-row>
                <hr/>
                <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.contract')}}</h4>
                <span class="tip-light">{{ $t('storm_sewers.contract_tip')}}</span>
                <open-contract-filter
                    :to="toDate"
                    :from="fromDate"
                    :contract="contract"
                    :show-company="false"
                    :show-title="false"
                    @loading="loading=true"
                    @loadingCompleted="loading=false"
                    @contractSelected="setContract"
                    style="margin-left: -2px; margin-right: -3px;"/>
                <hr/>
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('street_lights.maintenance_info')}}</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : maintenanceVisible}"
                            v-b-toggle.maintenanceSection
                            @click.stop="maintenanceVisible = !maintenanceVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="maintenanceSection">
                    <b-row class="nopads">
                            <span class="span-title">{{ $t('street_lights.last_maintenance') }}</span>
                            <b-form-input
                                id="bulbReplace"
                                v-model="drain.last_maintenance"
                                type="date"
                                size="sm"/>
                    </b-row>
                    <b-row class="nopads">
                            <span class="span-title">{{ $t('street_lights.last_maintenance') }}</span>
                            <b-form-textarea
                                id="info"
                                v-model="drain.maintenance_info"
                                size="sm"
                                :placeholder="$t('street_lights.info')"
                                :rows="4"
                                :max-rows="8"
                            />
                    </b-row>
                </b-collapse>
                <hr/>
                <h4 class="form-header mt-0 pt-0">{{ $t('street_lights.info') }}</h4>
                <b-form-textarea
                    id="info"
                    v-model="drain.info"
                    :placeholder="$t('street_lights.info')"
                    :rows="4"
                    :max-rows="8"
                />

                <!-- Attachments-->
                <hr/>
                <h4 class="form-header">{{ $t('orders.attachments_and_info')}}</h4>
                <div v-if="drain.attachments && drain.attachments.length">
                    <span class="span-title">{{ $t('common.attachments') }}</span>
                    <ol>
                        <li class="pointer"
                            v-for="item in drain.attachments"
                            :key="item.id">
                            <span v-on:click="downloadAttachment(item)">{{ item.file_original_name }}</span>
                            <b-button
                                variant="danger"
                                size="sm"
                                class="mb-1 ml-3 p-0 pl-1 pr-1"
                                style=""
                                @click.stop="removeAttachment(item)"
                            >
                                <i class="fa fa-trash" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                            </b-button>
                        </li>
                    </ol>
                </div>

                <!-- Attachment upload-->
                <div>
                    <span class="span-title">{{ $t('work_assignment.add_attachment') }}</span>
                    <div
                        v-for="(path, counter) in attachments"
                        :key="counter"
                        class="col-sm-12 nopads"
                    >
                        <b-form-group
                            class="title"
                            label-for="counter"
                            v-if="counter === 0 || isAttachmentSet(attachments, counter-1)"
                        >
                            <b-form-file
                                v-model="attachments[counter]"
                                :accept="getValidAttachmentTypes()"
                                :placeholder="$t('common.attachment_placeholder')"
                            />
                        </b-form-group>
                    </div>
                </div>
                <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                    <b-button
                        variant="secondary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </base-modal>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import BaseModal from '../BaseModal'
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {attachmentHelper} from '@/components/mixins/AttachmentMixin';

export default {
    name: 'DrainWizard',
    components: {OpenContractFilter, BaseModal},
    mixins: [restApi, timeUtils, stormSewersMixin, attachmentHelper],
    props: {
        selectedContract: {
            type: Object,
            default: null
        },
        stormDrain: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            drain: null,
            contract: null,
            attachments: new Array(100),
            maintenanceVisible: false,
            variant: 'danger',
            statusOptions: [
                {value: 1, text: this.$t('storm_sewers.status_ok')},
                {value: 10, text: this.$t('storm_sewers.status_defect')},
                {value: 20, text: this.$t('storm_sewers.status_removed')},
            ]
        }
    },
    computed: {
        fromDate() {
            if (this.powerStation) {
                return this.powerStation.created_at
            }
            let d = new Date()
            return d.toISOString()
        },
        toDate() {
            let d = new Date()
            return d.toISOString()
        },
        preselectedContract() {
            return this.drain && this.drain.contract ? this.light.contract : null
        }
    },
    mounted() {
        this.drain = JSON.parse(JSON.stringify(this.stormDrain))
        this.drain.last_maintenance = this.drain.last_maintenance ? this.timestampToDateInput(this.drain.last_maintenance) : null
        this.contract = this.drain.contract ? this.drain.contract : null
        if(this.drain && this.drain.id && this.drain.id > 0) {
            this.fetchDrainAttachments()
        }
    },
    methods: {
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        submit: function () {
            this.loading = true
            if (this.contract) {
                this.drain.contract = this.contract
            }
            this.drain = this.convertPositionToXY(this.drain)
            if(this.drain.id) {
                this.restUpdate(this.stormDrainUrl, this.drain, this.success, this.fail)
            } else {
                this.restAdd(this.stormDrainUrl, this.drain, this.success, this.fail)
            }
        },
        fetchDrainAttachments() {
            this.loading = true
            this.restFetch(this.stormDrainUrl + "/attachments/" + this.drain.id, this.successFetchAttachments, this.failFetchAttachments)
        },
        successFetchAttachments(response) {
            this.loading = false
            if(response !== null) {
                this.drain.attachments = response.data
            }
        },
        failFetchAttachments() {
            this.loading = false
            this.showAlert(this.$t('common.error'))
        },
        downloadAttachment: function (item) {
            this.loading = true
            this.restFetch(this.stormDrainUrl + "/attachment/" + this.drain.id + '/' + item.id,
                response => {
                    this.saveFile(item.file_original_name, response.data)
                    this.loading = false
                }, () => {
                    this.loading = false
                })
        },

        removeAttachment: function (item) {
            if(this.drain.attachments && this.drain.attachments.length > 0) {
                var index = this.drain.attachments.findIndex(attachment => attachment.id === item.id)
                if(index >= 0) {
                    this.drain.attachments.splice(index, 1)
                }
            }
        },
        success: function () {
            this.loading = false
            this.uploadAttachment()
        },
        uploadAttachment: function () {
            let attachments = this.getValidAttachments(this.attachments)
            if (attachments.length > 0) {
                let formData = new FormData()
                attachments.forEach(file => {
                    if (file !== undefined && file !== null) {
                        formData.append('attachments[]', file)
                    }
                })
                this.restPostWithFile(this.stormDrainUrl + "/attachment/" + this.drain.id, {}, formData,
                    () => {
                        this.loading = false
                        this.showAlert(this.$t('common.alert_update_success'), 'success')
                        this.$emit('closeAndUpdate')
                    }, this.fail
                )
            } else {
                this.loading = false
                this.showAlert(this.$t('common.alert_update_success'), 'success')
                this.$emit('closeAndUpdate')
            }
        },
        fail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_add_failed'))
        },
        setContract: function (contract) {
            this.contract = contract
        }
    }
}
</script>
<style>
@import "../../styles/modal.css";
</style>
