import {equipmentOidTypes} from '@/components/mixins/ConversionMixin'

export default class VelhoEdgeSupportsModel {
    // Private properties
    /** @type {string} */
    #external_system_id = equipmentOidTypes.EXTERNAL_EDGE_SUPPORTS

    constructor(
        type = null, additionalType = null, condition = null, material = null, height = null, code = "", position = { x: null, y: null },
        roadNum = null, roadSec = null, distance = null, equipmentDamages = [], locationDeviation = null, locationEnding = { road_num: null, road_sec: null, distance: null },
        locationSpecification = { lane: [], side: null, tracks: null, central_area: null }, locationSpecificationEnding = { lane: [], side: null, tracks: null, central_area: null }, externalSystemId = ""
    ) {
        /** @type {number | null} */
        this.type = type

        /** @type {number | null} */
        this.additional_type = additionalType

        /** @type {number | null} */
        this.condition = condition

        /** @type {number | null} */
        this.material = material

        /** @type {number | null} */
        this.height = height

        /** @type {string} */
        this.code = code
        
        /** @type {{ x: number, y: number }} */
        this.position = position

        /** @type {number | null} */
        this.road_num = roadNum

        /** @type {number | null} */
        this.road_sec = roadSec

        /** @type {number | null} */
        this.distance = distance

        /** @type {Array} */
        this.equipment_damages = equipmentDamages

        /** @type {number | null} */
        this.location_deviation = locationDeviation

        /** @type {{ road_num: number | null, road_sec: number | null, distance: number | null }} */
        this.location_ending = locationEnding

        /** @type {{ lane: number[], side: number | null, tracks: number | null, central_area: number | null }} */
        this.location_specification = locationSpecification

        /** @type {{ lane: number[], side: number | null, tracks: number | null, central_area: number | null }} */
        this.location_specification_ending = locationSpecificationEnding

        if(externalSystemId) {
            this.#external_system_id = externalSystemId
        }
    }

    /**
    * Gets the unique identifier.
    * @returns {string}
    */
    get external_system_id() {
        return this.#external_system_id
    }

    /**
    * Get the json object.
    * @returns {object}
    */
    toJSON() {
        return JSON.parse(JSON.stringify({
            external_system_id: this.#external_system_id,
            type: this.type,
            additional_type: this.additional_type,
            condition: this.condition,
            material: this.material,
            height: this.height,
            code: this.code,
            position: this.position,
            road_num: this.road_num,
            road_sec: this.road_sec,
            distance: this.distance,
            equipment_damages: this.equipment_damages,
            location_deviation: this.location_deviation,
            location_ending: this.location_ending,
            location_specification: {
                ...this.location_specification,
                tracks: this.location_specification.tracks !== null ? [this.location_specification.tracks] : []
            },
            location_specification_ending: {
                ...this.location_specification_ending,
                tracks: this.location_specification_ending.tracks !== null ? [this.location_specification_ending.tracks] : []
            }
        }))
    }

    /**
    * Create default instance with position data.
    * @param {{ x: number, y: number }} position - coordinates.
    * @param {number | null} roadNum - Road number.
    * @param {number | null} roadSec - road section.
    * @param {number | null} distance - distance (road geometry).
    * @returns {VelhoEdgeSupportsModel}
    */
    static instanceWithPosition(position, roadNum, roadSec, distance) {
        const instance = new VelhoEdgeSupportsModel()
        instance.position = position
        instance.road_num = roadNum
        instance.road_sec = roadSec
        instance.distance = distance
        return instance
    }
}