<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showTaskTypeList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('tasktypes.title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showTaskTypeList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <!-- Company filter for admin -->
        <transition name="fade">
            <div class="col-sm-12">
                <keep-alive>
                    <company-filter
                        v-if="isAdmin&&!editMode"
                        v-model="company"
                    />
                </keep-alive>
            </div>
        </transition>
        <transition name="fade">
            <task-type-list
                v-if="!editMode"
                :is-admin="isAdmin"
                :results="results"
                @add="addTaskType"
                @edit="editTaskType"
            />
        </transition>

        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <task-type-editor
                    :is-admin="isAdmin"
                    :task-type="taskType"
                    @close="showTaskTypeList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import TaskTypeList from './TaskTypeList'
import TaskTypeEditor from './TaskTypeEditor'
import CompanyFilter from '../company/CompanyFilter'

export default {
    name: 'TaskTypes',
    components: {CompanyFilter, TaskTypeList, TaskTypeEditor},
    mixins: [restApi, workManagementHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            taskType: null,
            editMode: false,
            loading: false,
            searchCompanyId: null,
            company: null
        }
    },
    watch: {
        company: function () {
            this.fetchTaskTypes(this.company)
        }
    },
    mounted: function () {
        if (!this.isAdmin) {
            this.fetchTaskTypes()
        }
    },
    methods: {
        fetchTaskTypes: function (companyId) {
            this.loading = true
            this.results = []
            this.searchCompanyId = companyId
            this.restFetchParams(this.taskTypeUrl, {company: this.searchCompanyId}, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        addTaskType: function () {
            this.taskType = this.newTaskType()
            this.editMode = true
        },
        editTaskType: function (taskType) {
            this.taskType = taskType
            this.editMode = true
        },
        showTaskTypeList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchTaskTypes(this.searchCompanyId)
            this.showTaskTypeList()
        }

    }
}
</script>
