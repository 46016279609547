<template>
    <div class="col-sm-12 editor-form">
        <h4 class="form-header">{{ $t('contracts.basic_info')}}</h4>
        <b-row>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                   {{ $t('contracts.customer') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="name"
                >
                    <span>
                      <strong>
                        {{ contract.customer.name }}
                      </strong>
                    </span>
                </b-form-group>
            </div>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                    {{ $t('contracts.contractor') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="name"
                >
                    <span>
                      <strong>
                        {{ contract.contractor.name }}
                      </strong>
                    </span>
                </b-form-group>
            </div>
        </b-row>
        <b-row>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                    {{ $t('contracts.name') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="contractname"
                >
                    <b-form-input
                        id="contractname"
                        v-model="contract.name"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
            </div>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                    {{ $t('contracts.number') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="number"
                >
                    <b-form-input
                        id="number"
                        v-model="contract.contract_number"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <div class="col-12 nopads text-right">
                    <b-button
                        v-if="contract.id > 0 && hasContractSystem(user)"
                        variant="info"
                        size="sm"
                        class="form-button"
                        :disabled="!contract.contract_number"
                        @click.stop="showFetchImportedDataModal = !showFetchImportedDataModal"
                    >
                        {{ $t('contracts.fetch_imported_contract_data') }}
                    </b-button>
                </div>
           </div>
        </b-row>
        <b-row>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                    {{ $t('contracts.start') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="start"
                >
                    <b-form-input
                        id="start"
                        type="date"
                        :value="timestampToDateInput(contract.start_date)"
                        size="sm"
                        @change="updateStartDate"
                    />
                </b-form-group>
            </div>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                  {{ $t('contracts.end') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="end"
                >
                    <b-form-input
                        id="end"
                        type="date"
                        size="sm"
                        :value="timestampToDateInput(contract.end_date)"
                        @change="updateEndDate"
                    />
                </b-form-group>
            </div>
        </b-row>
        <b-row>
            <div class="col-sm-4 nopads">
                <span class="span-title">
                    {{ $t('contracts.worksite_key') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="work_site_key"
                >
                    <b-form-input
                        id="work_site_key"
                        v-model="contract.work_site_key"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
            </div>
            <div class="col-sm-4 nopads">
                <span class="span-title">
                  {{ $t('contracts.owners') }}
                </span>
                <multiselect
                    v-model="contract.owners"
                    class="multiselect_routa mt-1"
                    :placeholder="$t('work_time.select_person')"
                    :deselect-label="$t('common.remove_selection')"
                    :select-label="$t('common.select')"
                    :close-on-select="false"
                    :multiple="true"
                    label="first_name"
                    track-by="id"
                    :searchable="true"
                    :internal-search="true"
                    :selected-label="$t('common.selected')"
                    :options="users"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
              <span class="option__desc">
                <span class="option__title">{{ props.option.last_name + ', ' + props.option.first_name }}</span>
              </span>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.last_name + ', ' + props.option.first_name }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>
            </div>
            <div class="col-sm-4 nopads pt-1 pl-2">
                <span class="span-title">{{ $t('contracts.contract_collection.title') }}</span>
                <b-form-select
                    class="title"
                    v-model="contractCollection"
                    size="sm"
                >
                    <option :value="null">
                        {{ $t('contracts.select_contract_collection') }}
                    </option>
                    <option
                        v-for="option in contractCollectionOptions"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </b-form-select>
            </div>
        </b-row>
        <b-row>
            <span class="span-title">{{ $t('contracts.observation_link') }}</span>
        </b-row>
        <b-row>
            <span class="tip-light">{{ $t('contracts.observation_link_hint') }}</span>
        </b-row>
        <b-row>
            <b-form-group>
                <b-form-checkbox
                    class="pt-2"
                    size="sm"
                    v-model="contract.prevent_observation_order_link"
                >
                    {{ $t('contracts.observation_order_link_prevented') }}
                </b-form-checkbox>
            </b-form-group>
        </b-row>
        <contract-task-types
            ref="taskTypeSelector"
            class="mb-2"
            :use-default="contract.id<1"
            v-model="contract.task_types"/>
        <b-button
            v-if="!contractTripList"
            variant="success"
            class="form-button mb-3"
            @click.stop="$emit('addTaskType')">
            {{ $t('contracts.add_tasktype') }}
        </b-button>
        <hr class="mb-1 mt-1"/>
        <b-row class="nopads pb-2">
            <b-col class="nopads" cols="10">
                <h4 class="form-header">{{ $t('contracts.ely_info')}}</h4>
            </b-col>
            <b-col class="nopads pt-2">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : elyVisible}"
                    v-b-toggle.elyFeatures
                    @click.stop="elyVisible = !elyVisible"/>
            </b-col>
        </b-row>
        <b-collapse id="elyFeatures">
            <span class="tip-light">{{ $t('contracts.ely_hint')}}</span>
            <b-row class="nopads">
                <div class="col-sm-4 nopads pt-3">
                    <b-form-checkbox
                        class="pt-3"
                        size="sm"
                        v-model="contract.ely_contract"
                    >
                        {{ $t('contracts.ely_contract') }}
                    </b-form-checkbox>
                </div>
                <div class="col-sm-4 nopads">
                    <span class="span-title">
                      {{ $t('contracts.velho_contract_id') }}
                    </span>
                    <b-form-group
                        class="title"
                        label-for="velho_contract_id"
                    >
                        <b-form-select
                            id="externalContracts"
                            size="sm"
                            v-model="externalContract">
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('contracts.external.select_external_contract') }}
                                </option>
                            </template>
                            <option
                                v-for="c in externalContracts"
                                :key="c.id"
                                :value="c.id"
                            >
                                {{ c.external_name + ' - ' + c.external_id }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4 nopads">
                    <span class="span-title">
                      {{ $t('contracts.harja_contract_id') }}
                    </span>
                    <b-form-group
                        class="title"
                        label-for="harja_contract_id"
                    >
                        <b-form-input
                            id="harja_contract_id"
                            v-model="contract.harja_contract_id"
                            type="number"
                            step="1"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
        </b-collapse>
        <hr class="mb-1 mt-1"/>
        <b-row class="nopads pb-2">
            <b-col class="nopads" cols="10">
                <h4 class="form-header">{{ $t('contracts.areas_and_reporting')}}</h4>
            </b-col>
            <b-col class="nopads pt-2">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : areasVisible}"
                    v-b-toggle.areaFeatures
                    @click.stop="areasVisible = !areasVisible"/>
            </b-col>
        </b-row>
        <b-collapse id="areaFeatures">
            <b-row>
                <div class="col-sm-4 nopads">
                    <!-- Select area group-->
                    <span class="span-title">{{ $t('contracts.area_group') }}</span>
                    <b-form-group
                        class="title"
                        label-for="areaGroups">
                        <b-form-select
                            id="areaGroups"
                            v-model="contract.area_group.id"
                            size="sm"
                            class="sm-3"
                            :disabled="areaGroups.length < 1"
                            @change="checkAreaTracking"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('contracts.select_area_group') }}
                                </option>
                            </template>
                            <option
                                v-for="option in areaGroups"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4 nopads pl-2 pr-2">
                    <!-- Area work record tracking option-->
                    <span class="span-title">{{ $t('contracts.area_tracking') }}</span>
                    <b-form-checkbox
                        class="pt-2"
                        v-model="contract.track_area_work"
                        :disabled="!contract.area_group || !contract.area_group.id || contract.area_group.id < 1"
                    >
                        {{ $t('contracts.area_tracking_enabled') }}
                    </b-form-checkbox>
                </div>
                <div class="col-sm-4 nopads pl-2 pr-2">
                    <!-- Area trip tracking mode option-->
                    <span class="span-title">{{ $t('contracts.area_tracking_mode') }}</span>
                    <b-form-select
                        v-model="contract.report_tracking_mode"
                        :options="trackModeOptions"
                        :disabled="!contract.area_group || !contract.area_group.id || contract.area_group.id < 1"
                        size="sm"/>
                </div>
            </b-row>
        </b-collapse>
        <hr class="mb-1 mt-1"/>
        <b-row class="nopads pb-2">
            <b-col class="nopads" cols="10">
                <h4 class="form-header">{{ $t('contracts.weather_tracking.title') }}</h4>
            </b-col>
            <b-col class="nopads pt-2">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : weatherVisible}"
                    v-b-toggle.weatherFeatures
                    @click.stop="weatherVisible = !weatherVisible"/>
            </b-col>
        </b-row>
        <b-collapse id="weatherFeatures" class="mt-2 mb-2 item-details show-print">
            <b-row class="nopads">
                <b-form-select
                    v-model="weatherTrackingMode"
                    :options="weatherTrackingModeOptions"
                    class="col-sm-2"
                />
                <b-button
                    v-if="weatherTrackingMode === weatherTrackingModes.POINT"
                    id="submit"
                    variant="primary"
                    class="ml-4"
                    size="sm"
                    @click.stop="editWeatherTracking = true"
                    :disabled="weatherTrackingMode !== weatherTrackingModes.POINT"
                >
                    {{ $t('contracts.weather_tracking.edit_position') }}
                </b-button>
            </b-row>
            <coordinate-editor-modal
                v-if="contract.weather_tracking_position"
                :title="$t('contracts.weather_tracking.edit').toString()"
                :visible="editWeatherTracking"
                @hidden="editWeatherTracking = false"
                v-model="contract.weather_tracking_position.position"
            />
        </b-collapse>
        <hr class="mb-1 mt-1"/>
        <b-row class="nopads pb-2">
            <b-col class="nopads" cols="10">
                <h4 class="form-header">{{ $t('contracts.inspections_and_certificates')}}</h4>
            </b-col>
            <b-col class="nopads pt-2">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : inspectionsVisible}"
                    v-b-toggle.inspectionFeatures
                    @click.stop="inspectionsVisible = !inspectionsVisible"/>
            </b-col>
        </b-row>
        <b-collapse id="inspectionFeatures">
            <contract-inspections
                :use-default="contract.id<1"
                v-model="contract.inspections"/>
            <contract-certificate-types
                v-model="contract.certificate_types"/>
        </b-collapse>
        <hr class="mb-1 mt-1"/>
        <b-row class="nopads pb-2">
            <b-col class="nopads" cols="10">
                <h4 class="form-header">{{ $t('contracts.share_to_material_storage') }}</h4>
            </b-col>
            <b-col class="nopads pt-2">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : storagesVisible}"
                    v-b-toggle.storageFeatures
                    @click.stop="storagesVisible = !storagesVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="storageFeatures"
            class="mt-2 mb-2 item-details show-print"
        >
            <span class="tip-light"> {{ $t('contracts.share_hint')}}</span>
            <contract-material-storages
                class="pb-4"
                :contract="contract"
                v-model="contract.material_storages"
            />
        </b-collapse>
        <hr class="mb-1 mt-1"/>
        <div class="col-sm-12 button-container pb-3 pt-4 mt-5">
            <b-button
                v-if="contract.id > 0"
                variant="info"
                class="form-button"
                @click.stop="showContractTripModal = !showContractTripModal"
            >
                {{ $t('daily_summary.generate_report') }}
            </b-button>
            <contract-copy v-if="!contractTripList && contract.id > 0" :contract="contract" :setDefaultValues="setDefaultValues" @contract-copied="softUpdateContract" />
            <b-button
                variant="secondary"
                class="form-button"
                @click.stop="$emit('close')"
            >
                {{ contractTripList ? $t('common.close') : $t('contracts.close_form') }}
            </b-button>
            <b-button
                variant="success"
                class="form-button"
                :disabled="loading"
                @click.stop="submitContract"
            >
          <span v-if="contract.id > 0">
            {{ $t('contracts.save_contract') }}
          </span>
                <span v-else>
            {{ $t('contracts.save_add_contract') }}
          </span>
            </b-button>
        </div>
        <b-modal
            v-model="showContractTripModal"
            ref="contractTripReportModal"
            centered
            hide-footer
            :title="$t('daily_summary.generation_title')"
        >
            <ContractTripSummaryGenerator
                :one-day-mode="true"
                :contract-object="contract"
                @closeModal="showContractTripModal = false"
            />
        </b-modal>
        <b-modal
            v-model="showFetchImportedDataModal"
            centered
            hide-footer
            :title="$t('contracts.fetch_imported_contract_data')"
        >
            <ContractDataImporter
                :contract-object="contract"
                @closeModal="showFetchImportedDataModal = false"
                @closeSuccessModal="successContractDataImporterModal"
            />
        </b-modal>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import ContractInspections from "./ContractInspections";
import ContractTaskTypes from "./ContractTaskTypes";
import ContractCertificateTypes from "./ContractCertificateTypes";
import ContractMaterialStorages from "./ContractMaterialStorages";
import ContractTripSummaryGenerator from "@/components/tripsummary/ContractTripSummaryGenerator";
import ContractCopy from "./ContractCopy";
import ContractDataImporter from "@/components/contract/contractdataimporter/ContractDataImporter";
import {userHelper} from "@/components/mixins/UserMixin";
import {mapHelper} from "../mixins/MapMixin";
import CoordinateEditorModal from "../CoordinateEditorModal";

export default {
    name: 'ContractEditor',
    mixins: [workManagementHelper, restApi, userHelper, mapHelper],
    components: {
        CoordinateEditorModal,
        ContractDataImporter, ContractInspections, ContractTaskTypes, ContractCertificateTypes, ContractCopy,
        ContractTripSummaryGenerator, ContractMaterialStorages},
    props: {
        value: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        contractTripList: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            loading: false,
            contract: null,
            externalContract: null,
            showContractTripModal: false,
            showFetchImportedDataModal: false,
            taskTypes: [],
            areaGroups: [],
            externalContracts: [],
            contractCollectionOptions: [],
            contractCollection: null,
            users: [],
            trackModeOptions: [
                { value: 1, text: this.$t('contracts.area_tracking_mode_1') },
                //{ value: 2, text: this.$t('contracts.area_tracking_mode_2') },
                { value: 3, text: this.$t('contracts.area_tracking_mode_3') }
            ],
            weatherTrackingMode: 0,
            weatherTrackingModes: {
                NONE: 0,
                POINT: 1,
                AREAS: 2,
            },
            editWeatherTracking: false,
            elyVisible: false,
            weatherVisible: false,
            inspectionsVisible: false,
            areasVisible: false,
            storagesVisible: false
        }
    },
    computed: {
        weatherTrackingModeOptions() {
            return [
                { value: this.weatherTrackingModes.NONE, text: this.$t('contracts.weather_tracking.mode.none') },
                { value: this.weatherTrackingModes.POINT, text: this.$t('contracts.weather_tracking.mode.point') },
                { value: this.weatherTrackingModes.AREAS, text: this.$t('contracts.weather_tracking.mode.areas'), disabled: this.contract.area_group.id == null },
            ]
        }
    },
    created() {
        this.value.start_date = new Date(this.value.start_date).getTime()
        this.value.end_date = new Date(this.value.end_date).getTime()
        if (this.value.weather_tracking_mode != null) {
            this.weatherTrackingMode = this.value.weather_tracking_mode
        } else if (this.value.weather_tracking_position != null) {
            this.weatherTrackingMode = this.weatherTrackingModes.POINT
        } else {
            this.weatherTrackingMode = this.weatherTrackingModes.NONE
        }
        this.contract = this.value
        this.externalContract = this.contract.external_contract ? this.contract.external_contract.id : null
        this.fetchAreaGroups()
        this.fetchUsers()
        this.fetchExternalContracts()
        this.fetchContractCollections()
        this.contractCollection = this.contract.contract_collection ? this.contract.contract_collection.id : null
    },
    watch: {
        value() {
            this.contract = this.value
            this.handleOwners()
        },
        weatherTrackingMode(value) {
            this.value.weather_tracking_mode = value
            this.setPointWeatherTrackingState(value === this.weatherTrackingModes.POINT)
        }
    },
    methods: {
        fetchContractCollections: function () {
            this.loading = true
            this.contractCollectionOptions = []
            this.restFetch(this.contractCollectionUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.contractCollectionOptions = response.data
            this.loading = false
        },
        setPointWeatherTrackingState(active) {
            if (!active && !this.value.weather_tracking_position) return;
            this.value.weather_tracking_position = {
                position: {
                    x: this.user.company.location.x,
                    y: this.user.company.location.y
                },
                ...this.value.weather_tracking_position,
                active,
            }
        },
        softUpdateContract(data) {
            if (!data.area_group) {
                data.area_group = {id: null}
            }
            this.contract.id = data.id
            this.$emit('input', data);
            this.updateContractMaterialStorages()
        },
        checkAreaTracking: function () {
            if (!this.contract.area_group || !this.contract.area_group.id || this.contract.area_group < 1) {
                this.contract.track_area_work = false
                if (this.weatherTrackingMode === this.weatherTrackingModes.AREAS) {
                    this.weatherTrackingMode = this.weatherTrackingModes.NONE
                    EventBus.$emit('show-alert', this.$t('contracts.weather_tracking.info.turned_off_from_area'))
                }
            }
        },
        fetchAreaGroups: function () {
            this.areaGroups = []
            this.loading = true
            this.restFetchParams(this.areaGroupUrl, {
                company: this.contract.customer.id,
                no_geom: 1
            }, this.handleAreaGroupResponse)
        },
        handleAreaGroupResponse: function (response) {
            this.loading = false
            if (response != null) {
                let groups = response.data
                if (!!this.user && !this.hasAdmin(this.user.roles)) {
                    groups = response.data.filter(group => group.company_area === this.user.company.id)
                }
                this.areaGroups = groups
            }
        },
        fetchExternalContracts: function () {
            this.externalContracts = []
            this.loading = true
            this.restFetch(this.extContractIdCompanyUrl, this.handleExtContractResponse)
        },
        handleExtContractResponse: function (response) {
            this.loading = false
            if (response != null) {
                this.externalContracts = response.data
            }
        },
        fetchUsers: function () {
            this.loading = true;
            this.users = []
            this.restFetchParams(this.userCompanyUrl, {active: 1}, this.handleUserResponse)
        },

        handleUserResponse: function (response) {
            if (response && response.data) {
                response.data.forEach(function (user) {
                    this.users.push({
                        last_name: user.last_name,
                        first_name: user.first_name,
                        id: user.id
                    })
                }, this)
                this.handleOwners()
            }
            this.loading = false
        },

        handleOwners() {
            if (this.contract && this.contract.owners && this.contract.owners.length > 0) {
                this.contract.owners.forEach((owner, index) => {
                    let userFound = this.users.some((user) => user.id === owner.id)
                    if (!userFound) {
                        this.contract.owners[index].$isDisabled = true
                    }
                })
            }
        },

        updateStartDate: function (event) {
            if (event) {
                this.contract.start_date = new Date(event)
            } else {
                this.contract.start_date = undefined
            }
        },

        updateEndDate: function (event) {
            if (event) {
                this.contract.end_date = new Date(event)
            } else {
                this.contract.end_date = undefined
            }
        },

        setDefaultValues: function (value) {
            let contract = JSON.parse(JSON.stringify(value))
            if (contract.name === '') {
                EventBus.$emit('show-alert', this.$t('contracts.invalid_name'))
                return undefined
            }
            if (contract.customer < 1) {
                EventBus.$emit('show-alert', this.$t('contracts.invalid_customer'))
                return undefined
            }
            if (contract.contractor < 1) {
                EventBus.$emit('show-alert', this.$t('contracts.invalid_contractor'))
                return undefined
            }
            if (contract.work_site_key === '') {
                contract.work_site_key = ''
            }
            if (contract.contract_number === '') {
                contract.contract_number = ''
            }
            if (contract.start_date && (contract.start_date instanceof Date)) {
                contract.start_date = contract.start_date.toISOString()
            }
            if (contract.end_date && (contract.end_date instanceof Date)) {
                contract.end_date = contract.end_date.toISOString()
            }
            if (contract.area_group.id) {
                contract.area_group = {id: contract.area_group.id}
            } else {
                contract.area_group = null
            }
            if (this.externalContract) {
                contract.external_contract = {id: this.externalContract}
            } else {
                contract.external_contract = null
            }
            if(this.contractCollection) {
                contract.contract_collection = {id: this.contractCollection}
            } else {
                contract.contract_collection = null
            }
            return contract
        },

        submitContract: function () {
            let jsonContract = this.setDefaultValues(this.contract)
            if (jsonContract !== undefined) {
                this.loading = true
                if (this.contract.id < 1) {
                    this.restAdd(this.contractUrl, jsonContract, this.success, this.fail)
                } else {
                    this.restUpdate(this.contractUrl, jsonContract, this.success, this.fail)
                }
            }
        },

        success: function (data) {
            if (!data.area_groups) {
                data.area_group = {id: null}
            }
            this.contract.task_types = data.task_types
            if (!this.contract.id || this.contract.id < 1) {
                this.contract.id = data.id
            }
            this.$emit('input', data);
            this.updateContractMaterialStorages()
            this.loading = false
        },

        fail() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },

        updateContractMaterialStorages: function () {
            if (this.contract.material_storages) {
                let materialStorageIds = []
                this.contract.material_storages.forEach(materialStorage=> {
                    materialStorageIds.push(materialStorage.id)
                })
                this.loading = true
                this.restUpdate(this.materialStorageUrl + '/contract/' + this.contract.id, {storages: materialStorageIds}, this.successContractMaterialStorages, this.fail)
            } else {
                this.updateContract()
            }
        },

        successContractDataImporterModal() {
            this.showFetchImportedDataModal = false
            this.updateContract()
        },

        successContractMaterialStorages: function(data) {
            this.contract.material_storage = data
            this.updateContract()
            this.loading = false
        },

        updateContract: function () {
            let jsonContract = this.setDefaultValues(this.contract)
            if (jsonContract !== undefined && this.contract.id > 0) {
                this.loading = true
                this.restFetchParams(this.contractUrl, {id: this.contract.id}, this.successUpdate, this.fail)
            }
        },

        successUpdate: function (response) {
            if (response.data && response.data.length > 0) {
                let contractData = response.data[0]
                if (!contractData.area_group) {
                    contractData.area_group = {id: null}
                }
                this.$emit('input', contractData)
            }
            this.loading = false
        },

        refreshTaskTypes() {
            this.$refs.taskTypeSelector.fetchTaskTypes()
        }
    }
}
</script>
