export const workAssignmentGeomTypes = {
    POINT: 1,
    LINESTRING: 2,
    POLYGON: 3
}

export const workAssignmentHelper = {
    data: function () {
        return {
            OPEN: 1,
            IN_PROGRESS: 2,
            ASSIGNED: 3,
            COMPLETED: 10,
            CANCELLED: 20,
            AREA_DEFECT: 1,
            VEHICLE_DEFECT: 2,
            OBSERVATION: 3,
            LOW: 1,
            NORMAL: 2,
            HIGH: 3
        }
    },
    methods: {
        getStatusColor(status) {
            switch (status) {
                case this.OPEN: return '#00eaff'
                case this.ASSIGNED: return '#9e0c0c'
                case this.IN_PROGRESS: return '#faa633'
                case this.COMPLETED: return '#337003'
                case this.CANCELLED: return '#e14343'
            }
            return '#bcbcbc'
        },
        getTitle: function (item) {
            if (item.area_defect) {
                return this.getAreaDefectInfo(item.area_defect)
            }
            if (item.vehicle_defect) {
                return this.getVehicleInfo(item.vehicle_defect)
            }
            if (item.observation) {
                return this.getObservationInfo(item.observation)
            }
            if (item.work_order) {
                return item.work_order.task_type.name
            }
            if (item.contract) {
                return this.getContractInfo(item.contract)
            }
            if (item.info) {
                return item.info.length < 15 ? item.info : item.info.substring(0, 15) + "..."
            }
            return '-';
        },

        getAreaDefectInfo: function (areaDefect) {
            if (areaDefect && areaDefect.area && areaDefect.area_equipment) {
                return areaDefect.area.name + ' - ' + areaDefect.area_equipment.name
            } else {
                return ''
            }
        },
        getVehicleInfo: function (vehicleDefect) {
            if (vehicleDefect) {
                return vehicleDefect.vehicle.license_plate + ' - ' + vehicleDefect.description
            } else {
                return ''
            }
        },
        getObservationInfo: function (observation) {
            if (observation) {
                return (observation.type_name || observation.type.name)
            } else {
                return ''
            }
        },
        getContractInfo: function (contract) {
            if (contract) {
                return contract.name + " (" + contract.customer.name + " - " + contract.contractor.name + ")"
            } else {
                return ''
            }
        },

        getWorkAssignmentPointPosition(workAssignment){
            let markerPosition = null
            if (workAssignment.geometry) {
                if (workAssignment.geometry.polygon) {
                    markerPosition = {
                        'x': workAssignment.geometry.polygon.rings[0][0][0],
                        'y': workAssignment.geometry.polygon.rings[0][0][1]
                    }
                } else if (workAssignment.geometry.point) {
                    markerPosition = workAssignment.geometry.point
                } else if (workAssignment.geometry.line_string) {
                    markerPosition = {
                        'x': workAssignment.geometry.line_string.points[0][0],
                        'y': workAssignment.geometry.line_string.points[0][1]
                    }
                }
            } else if (workAssignment.position) {
                markerPosition = workAssignment.position
            } else if (workAssignment.observation && workAssignment.observation.geometry.point) {
                markerPosition = workAssignment.observation.geometry.point
            } else if (workAssignment.observation && workAssignment.observation.geometry.line_string){
                let geometry = workAssignment.observation.geometry.line_string
                markerPosition = {'x': geometry.points[0][0], 'y': geometry.points[0][1]}
            } else if (workAssignment.area_defect && workAssignment.area_defect.area_equipment && workAssignment.area_defect.area_equipment.location){
                markerPosition = workAssignment.area_defect.area_equipment.location
            } else if(workAssignment.area_equipment && workAssignment.area_equipment.location){
                markerPosition = workAssignment.area_equipment.location
            }
            return markerPosition
        },
        getWorkAssignmentGeometryType(workAssignment) {
            if(workAssignment) {
                if(workAssignment.geometry && workAssignment.geometry.point) {
                    return workAssignmentGeomTypes.POINT;
                } else if(workAssignment.geometry && workAssignment.geometry.line_string) {
                    return workAssignmentGeomTypes.LINESTRING;
                } else if(workAssignment.geometry && workAssignment.geometry.polygon) {
                    return workAssignmentGeomTypes.POLYGON
                } else if(workAssignment.position) {
                    return workAssignmentGeomTypes.POINT;
                }
            }
            return null
        },
        newWorkAssignment: function (like) {
            const deepCloned = JSON.parse(JSON.stringify(like))
            return {
                id: -1,
                assigned_to: null,
                assigned_company: {id: null},
                status: 1,
                priority_value: 2,
                info: '',
                observation: null,
                vehicle_defect: null,
                area_defect: null,
                progress_value: like ? like.progress_value ? like.progress_value : like.status === 10 ? 100 : 0 : 0,
                contract: like && like.contract ? like.contract : {id: null},
                work_order: like && like.work_order ? like.work_order : {id: null},
                email: '',
                external_tasks: [],
                ...deepCloned
            }
        },
        getStatus: function (isNewAssignment) {
            if (isNewAssignment) {
                return [
                    {key: this.$t('work_assignment.open'), status: this.OPEN},
                    {key: this.$t('work_assignment.assigned'), status: this.ASSIGNED},
                    {key: this.$t('work_assignment.in_progress'), status: this.IN_PROGRESS}
                ]
            } else {
                return [
                    {key: this.$t('work_assignment.open'), status: this.OPEN},
                    {key: this.$t('work_assignment.assigned'), status: this.ASSIGNED},
                    {key: this.$t('work_assignment.in_progress'), status: this.IN_PROGRESS},
                    {key: this.$t('work_assignment.completed'), status: this.COMPLETED},
                    {key: this.$t('work_assignment.cancelled'), status: this.CANCELLED}
                ]
            }
        },

        getStatusString: function (status) {
            switch (status) {
                case this.OPEN:
                    return this.$t('work_assignment.open')
                case this.ASSIGNED:
                    return this.$t('work_assignment.assigned')
                case this.IN_PROGRESS:
                    return this.$t('work_assignment.in_progress')
                case this.COMPLETED:
                    return this.$t('work_assignment.completed')
                case this.CANCELLED:
                    return this.$t('work_assignment.cancelled')
            }
        },

        getPriority: function () {
            return [
                {key: this.$t('work_assignment.low'), priorityValue: this.LOW},
                {key: this.$t('work_assignment.normal'), priorityValue: this.NORMAL},
                {key: this.$t('work_assignment.high'), priorityValue: this.HIGH}
            ]
        },

        getPriorityString: function (priority) {
            switch (priority) {
                case this.LOW:
                    return this.$t('work_assignment.low')
                case this.NORMAL:
                    return this.$t('work_assignment.normal')
                case this.HIGH:
                    return this.$t('work_assignment.high')
            }
        },

        jsonToWorkAssignment: function (assignment) {
            let copy = JSON.parse(JSON.stringify(assignment))
            if (!copy.assigned_to) {
                copy.assigned_to = null
            }
            return copy
        },
        handleContractResults: function (response) {
            if(typeof this.loading === 'boolean') {
                this.loading = false
            } else {
                this.loading--
            }
            if (response != null) {
                this.contracts = response.data
            }
        },
        fetchOpenContracts: function () {
            this.contracts = []
            if(typeof this.loading === 'boolean') {
                this.loading = true
            } else {
                this.loading++
            }
            let today = new Date()
            let params = {
                startDate: this.localTimeToUtcTime(today).toISOString(),
                endDate: this.getEndOfTheDay(today).toISOString()
            }
            this.restFetchParams(this.contractOpenUrl, params, this.handleContractResults)
        },
    }
}
