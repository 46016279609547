<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <h3
                v-if="priceList.id > 0"
                class="p-2"
            >
                {{ $t('pricelist.edit') }}
            </h3>
            <h3
                v-else
                class="p-2"
            >
                {{ $t('pricelist.add') }}
            </h3>
            <div class="col-sm-12 form-data">
                <div class="col-sm-6 nopads">
                    <span class="span-title">
                      {{ $t('pricelist.name') }}
                    </span>
                    <b-form-input
                        id="name"
                        v-model="priceList.name"
                        size="sm"
                        :placeholder="$t('pricelist.name_tip')"
                    />
                </div>
                <div class="col-sm-6 nopads">
                    <span class="span-title">
                      {{ $t('pricelist.distance_unit') }}
                    </span>
                    <b-form-select
                        id="d-unit"
                        v-model="priceList.distance_unit"
                        :options="distanceUnitOptions"
                        size="sm"/>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="span-title">
                        {{ $t('pricelist.weight_unit') }}
                    </span>
                    <b-form-select
                        id="w-unit"
                        v-model="priceList.weight_unit"
                        :options="weightUnitOptions"
                        size="sm"/>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="span-title">
                        {{ $t('pricelist.multiplier') }}
                    </span>
                    <b-form-input
                        id="multiplier"
                        step="0.001"
                        type="number"
                        v-model="priceList.multiplier"
                        size="sm"/>
                </div>
                <div v-if="priceList.id && priceList.id > 0" class="col-sm-6 nopads">
                    <span class="span-title">
                        {{ $t('pricelist.active') }}
                    </span>
                    <b-form-checkbox
                        id="active"
                        v-model="priceList.active"
                        name="checkbox"
                    />
                </div>
                <span class="span-title">
                    {{ $t('pricelist.prices') }}
                </span>

                <!-- Price ranges -->
                <price-range-selector
                    v-for="(item, index) in priceList.prices"
                    v-bind:key="index"
                    :price="item"
                    :multiplier="priceList.multiplier"
                    :weight-unit="priceList.weight_unit"
                    :distance-unit="priceList.distance_unit"
                />

                <!-- Buttons -->
                <div class="col-sm-12 button-container">
                    <b-button
                        id="btn-close"
                        variant="danger"
                        class="form-button"
                        @click.stop="$emit('close')"
                    >
                          {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        id="btn-success"
                        variant="success"
                        class="form-button"
                        :disabled="loading"
                        @click.stop="submitPriceList"
                    >
                          {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import PriceRangeSelector from "./PriceRangeSelector";

export default {
    name: 'PriceListEditor',
    components: {PriceRangeSelector},
    mixins: [restApi],
    props: {
        priceList: {
            type: Object,
            default: null
        },
        hideTitle: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            priceListUpdate: this.priceList,
            distanceUnitOptions: [
                { value: 'km', text: 'km' },
                { value: 'm', text: 'm' },
            ],
            weightUnitOptions: [
                { value: 'kg', text: 'kg' },
                { value: 't', text: 't' },
            ]
        }
    },

    methods: {
        setDefaultValues: function (priceList) {
            if (priceList.name === '') {
                EventBus.$emit('show-alert', this.$t('pricelist.invalid_name'))
                return undefined
            }
            if (!priceList.distance_unit) {
                EventBus.$emit('show-alert', this.$t('pricelist.invalid_distance_unit'))
                return undefined
            }
            if (!priceList.weight_unit) {
                EventBus.$emit('show-alert', this.$t('pricelist.invalid_weight_unit'))
                return undefined
            }
            if (priceList.prices.length < 1) {
                EventBus.$emit('show-alert', this.$t('pricelist.invalid_prices'))
                return undefined
            }
            if (!priceList.multiplier || priceList.multiplier <= 0) {
                EventBus.$emit('show-alert', this.$t('pricelist.invalid_multiplier'))
                return undefined
            }
            if (!priceList.created_at) {
                let now = new Date()
                priceList.created_at = now.toISOString()
            }
            return priceList
        },

        submitPriceList: function () {
            var json = this.setDefaultValues(this.priceList)
            if (json !== undefined) {
                this.loading = true
                if (!this.priceList.id || this.priceList.id < 1) {
                    this.restAdd(this.priceListWeightDistanceUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.priceListWeightDistanceUrl, json, this.success, this.fail)
                }
            }
        },

        success: function (response) {
            this.$emit('closeAndUpdate', response)
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
