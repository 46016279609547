<template>
    <div>
        <map-container
            class="map"
            ref="mapContainer"
            :find-user="false"
            @onMarkerTap="onMarkerTap"
            @onMapChanged="onMapChanged"
        />
    </div>

</template>

<script>
import {mapHelper} from '../mixins/MapMixin'
import MapContainer from "./MapContainer";

export default {
    name: 'bridge-culvert-map',
    components: {MapContainer},
    mixins: [mapHelper],
    props: {
        items: {
            type: Array,
            default() {
                return []
            }
        },
        type: {
            type: Number,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            map: null
        }
    },

    mounted: function () {
        this.onMapChanged(this.$refs.mapContainer.getMap());
    },
    methods: {
        onMapChanged(map) {
            this.map = map;
            if (this.map) {
                this.drawItems();
            }
        },
        drawItems: function () {
            this.items.forEach(item => {
                this.drawItem(item)
            })
        },
        drawItem: function (item) {
            let icon = null
            if (this.type === this.BRIDGE) {
                icon = this.map.getMarkerStore().getBridgeIcon()
            } else if (this.type === this.CULVERT) {
                icon = this.map.getMarkerStore().getCulvertIcon()
            }
            this.map.showMapMarker(item.id, this.type, item.lat, item.lon, icon)
        },
        onMarkerTap: function (data) {
            let obj = this.items.find(item => item.id === data.id)
            this.$emit("onMarkerTap", obj)
        }
    }
}
</script>

<style lang="scss">
.map {
    height: 500px;
    width: 99%;
    margin: 0 auto 0 auto
}
</style>
