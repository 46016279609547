<template>
    <div>
        <!-- Existing user rights -->
        <b-row
            v-for="right in activeRights"
            :key="right.role.id+right.company.id"
            class="role">
            <div class="col-sm-4 nopads pr-2">
                {{ getRoleName(right.role.role) }}
            </div>
            <div class="col-sm-4 nopads ">
                {{ right.company.name }}
            </div>
            <div class="col-sm-3 nopads">
                {{ right.info }}
            </div>
            <div class="col-sm-1 nopads pl-2 text-right">
                <b-button
                    variant="danger"
                    size="sm"
                    class="mr-1"
                    @click.stop="() => $emit('removeUserRight', right)"
                >
                    <i class="fa fa-trash"/>
                </b-button>
            </div>
        </b-row>

        <!-- Placeholde for a new one -->
        <user-right-selector
            :companies="companies"
            :roles="roles"
            :is-admin="isAdmin"
            ref="selector"
            class="mt-3"
            @addUserRight="(right) => $emit('addUserRight', right)"/>
    </div>
</template>

<script>
import UserRightSelector from "./UserRightSelector";
import {userHelper} from "../mixins/UserMixin";
import {restApi} from "../mixins/RestApiMixin";
export default {
    name: "UserRightsEditor",
    components: {UserRightSelector},
    mixins: [userHelper, restApi],
    props: {
        rights: {
            type: Array,
            default: () => ([])
        },
        companies: {
            type: Array,
            default: () => ([])
        },
        roles: {
            type: Array,
            default: () => ([])
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        activeRights() {
            return this.rights.filter(right => {
                return !right.removed_at;
            });
        }
    },
    methods: {
        reset() {
            this.$refs.selector.reset();
        }
    }
}
</script>
