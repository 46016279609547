<template>
    <div class="content-container">
        <b-form class="col-sm-12 editor-form top-margin">
            <div class="col-sm-12 form-title editor-title">
                <h2 class="form-title editor-title">
                    {{ $t('users.account_title') }}
                </h2>
            </div>

            <span class="title">{{ $t('users.name') }}</span>
            <b-form-group class="title">
                <span>{{ user.first_name }} {{ user.last_name }}</span>
            </b-form-group>

            <span class="title">{{ $t('users.email') }}</span>
            <b-form-group class="title">
                <span> {{ user.email }} </span>
            </b-form-group>

            <span class="title">{{ $t('users.company') }}</span>
            <b-form-group class="title">
                <span> {{ user.company.name }} </span>
            </b-form-group>
            <change-password/>
        </b-form>
    </div>
</template>

<script>
import ChangePassword from './ChangePassword'

export default {
    name: 'MyAccount',
    components: {ChangePassword},
    props: {
        user: {
            type: Object,
            default: null
        }
    }
}
</script>
