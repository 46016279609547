export const velhoValidationMixin = {
    data() {
        return {}
    },
    methods: {
        /**
         * Validate road number
         * @param {string} roadNumber
         * @returns {boolean}
         */
        validateRoadNumber: function(roadNumber) {
            const _roadNumber = Number(roadNumber)
            if(isNaN(_roadNumber) || !isFinite(_roadNumber)) { return false }
            if(_roadNumber <= 0) { return false }

            return true;
        },
        /**
         * Validate road section
         * @param {string} roadSection
         * @returns {boolean}
         */
        validateRoadSection: function(roadSection) {
            const _roadSection = Number(roadSection)
            if(isNaN(_roadSection) || !isFinite(_roadSection)) { return false }
            if(_roadSection <= 0) { return false }

            return true;
        },
        /**
         * Validate distance
         * @param {string} distance
         * @returns {boolean}
         */
        validateRoadDistance: function(distance) {
            const _distance = Number(distance)
            if(isNaN(_distance) || !isFinite(_distance)) { return false }
            if(_distance <= 0) { return false }

            return true;
        },
        /**
         * Validate road side
         * @param {string} side
         * @returns {boolean}
         */
        validateRoadSide: function(side) {
            return this.validateIsNumber(side)
        },
        /**
         * Validate road tracks
         * @param {string} track
         * @returns {boolean}
         */
        validateRoadTracks: function(tracks) {
            // Note: Tracks should be array
            return this.validateIsNumber(tracks)
        },
        /**
         * Validate road side
         * @param {string} material
         * @returns {boolean}
         */
        validateMaterial: function(material) {
            return this.validateIsNumber(material)
        },
        /**
         * Validate road sign color
         * @param {string} color
         * @returns {boolean}
         */
        validateSignColor: function(color) {
            return this.validateIsNumber(color)
        },
        /**
         * Validate that the value is a number
         * @param {string|number} value
         * @returns {boolean}
         */
        validateIsNumber: function(value) {
            if(typeof value === "number") { return true }
            if(typeof value === "string" && value.length > 0) {
                const _value = Number(value)
                if(isNaN(_value) || !isFinite(_value)) { return false }
                return true;
            }

            return false;
        },
        /**
         * Validate road sign additional type
         * @param {number} type
         * @returns {boolean}
         */
        validateSignAdditionalType: function(type) {
            return this.validateIsNumber(type)
        }
    }
}