<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark">
            <div class="header">
                <span class="title">{{ $t('menu.forest_work_report') }}</span>
            </div>
        </b-navbar>
        <div class="col-sm-12 nopads">
            <keep-alive>
                <contract-vehicle-filter
                    :is-admin="isAdmin"
                    @searchReport="fetchReports"/>
            </keep-alive>


        </div>
        <div class="col-sm-12 list-content ">
            <forest-work-report-tables
                :isAdmin="isAdmin"
                :params="params"
                :fetch="fetchTrigger"
            />
        </div>
    </div>
</template>

<script>
import ContractVehicleFilter from './ContractVehicleFilter'
import ForestWorkReportTables from './ForestWorkReportTables'
import {restApi} from '../mixins/RestApiMixin'
import {downloadHelper} from "../mixins/DownloadMixin"

export default {
    name: 'ForestWorkReports',
    components: {ContractVehicleFilter, ForestWorkReportTables},
    mixins: [restApi, downloadHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            params: null,
            fetchTrigger: false,
        }
    },
    methods: {
        fetchReports: function (params) {
            this.fetchTrigger = !this.fetchTrigger
            this.params = params
        },

    }
}
</script>
