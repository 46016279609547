<template>
    <base-modal style="z-index: 50" :min-width="true">
        <div class="col-12">
            <h3 v-if="!cable || !cable.id">{{ $t('cables.add') }}</h3>
            <h3 v-else>{{ $t('cables.edit') }}</h3>
        </div>

        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div class="col-sm-12">
            <b-form v-if="updateCable" class="col-sm-12 editor-form">
                <h4 class="form-header">{{ $t('street_lights.basic_info')}}</h4>
                <b-row class="nopads">
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.status') }}</span>
                        <b-form-select id="cableStatus" size="sm" v-model="updateCable.status" :options="statusOptions">
                            <template #first>
                                <option :value="null">{{ $t('street_lights.select_status') }}</option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('cables.purpose') }}</span>
                        <b-form-select id="cableClass"  size="sm" v-model="updateCable.purpose" :options="purposeOptions"></b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('cables.type') }}</span>
                        <b-form-select id="cableType"  size="sm" v-model="updateCable.type" :options="cableTypeOptions"></b-form-select>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6 p-1">
                        <span class="span-title">{{ $t('street_lights.station') }}</span>
                        <b-form-select id="cableStation" size="sm" v-model="selectedStation" :options="stations">
                            <template #first>
                                <option :value="null">{{ $t('street_lights.select_station') }}</option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="col-sm-6 p-1">
                        <open-contract-filter
                            :from="now"
                            :contract="preselectedContract"
                            :show-company="false"
                            :show-title="true"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                            style="margin-left: -2px; margin-right: -3px;"/>
                    </div>
                </b-row>
                <hr/>
                <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                    <b-button
                        variant="secondary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        :disabled="loading||this.cable===null"
                        @click.stop="submit"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </base-modal>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import BaseModal from '../BaseModal'
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {cableHelper} from "@/components/mixins/CableMixin";
import {streetLightHelper} from "@/components/mixins/StreetLightMixin";

export default {
    name: 'CableEditor',
    components: {OpenContractFilter, BaseModal},
    mixins: [restApi, timeUtils, cableHelper, streetLightHelper],
    props: {
        selectedContract: {
            type: Object,
            default: null
        },
        cable: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            updateCable: null,
            contract: null,
            selectedStation: null,
            stations: [],
            variant: 'danger'
        }
    },
    computed: {
        preselectedContract() {
            return this.updateCable && this.updateCable.contract ? this.updateCable.contract : null
        },
        now() {
            return new Date().toISOString();
        }
    },
    mounted() {
        this.fetchStations()
        if (!this.cable) {
            this.updateCable = {
                id: null,
                station: null,
                status: null,
                contract: null,
                type: null,
                purpose: null,
            }
        } else {
            this.updateCable = this.cable
            this.contract = this.updateCable.contract ? this.updateCable.contract : null
            this.selectedStation = this.updateCable.station.id
        }
    },
    methods: {
        fetchStations() {
            this.loading = true
            this.stations = []
            this.restFetch(this.powerStationUrl, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            if (response && response.data) {
                response.data.forEach(station => {
                    this.stations.push({value: station.id, text: station.id + ' ' + station.location_name})
                })
            }
            this.loading = false
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        submit: function () {
            this.loading = true
            if (this.contract) {
                this.updateCable.contract = this.contract
            }
            if (!this.selectedStation) {
                this.showAlert(this.$t('street_light.error_station'))
                return;
            } else {
                this.updateCable.station = {id: this.selectedStation}
            }
            // TODO - Check geometry!!!
            if (this.updateCable.id && this.updateCable.id > 0) {
                this.restUpdate(this.cableUrl, this.updateCable, this.success, this.fail)
            } else {
                this.restAdd(this.cableUrl, this.updateCable, this.success, this.fail)
            }
        },
        success: function () {
            this.loading = false
            this.showAlert(this.$t('cables.added'), 'success')
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_add_failed'))
        },
        setContract: function (contract) {
            this.contract = contract
        }
    }
}
</script>
<style>
@import "../../styles/modal.css";
</style>
