<template>
    <div class="col-sm-12">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="draw-polygon"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="areaGroups && areaGroups.length > 0"
                id="accordion"
                class="col-sm-12 panel-group data-container"
            >
                <div
                    v-for="areaGroup of areaGroups"
                    :key="areaGroup.id"
                    class="data-list-item"
                >
                    <div
                        class="col-sm-12 item-header"
                        @click.stop="$emit('edit', areaGroup)"
                    >
                        <div class="item-img-cont">
                            <font-awesome-icon icon="draw-polygon"/>
                        </div>
                        <div class="item-text-cont">
              <span class="item-title-single">
                {{ areaGroup.name }}
              </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'AreaGroupList',
    props: {
        areaGroups: {
            type: Array,
            default: function () {
                return []
            }
        }
    }
}
</script>
