<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 nopads">
        <transition name="fade">
            <div class="list-content result-table">
                <b-table
                    show-empty
                    small
                    striped
                    :tbody-tr-class="highlightRow"
                    style="cursor:pointer;"
                    :items="results"
                    :fields="fields"
                    @row-clicked="rowClicked"
                >
                    <template v-slot:cell(created_time)="row">
                        {{ getDateString(row.value) }}
                    </template>
                    <template v-slot:cell(type)="row">
                        {{ row.item.type.name }}
                    </template>
                    <template v-slot:cell(value)="row">
                        <span v-if="beyondLimit(row.item.value)" class="no-pads" style="font-weight: bold; color: #c50505"> {{ row.item.value }} </span>
                        <span v-else class="no-pads"> {{ row.item.value }} </span>
                    </template>
                    <template v-slot:cell(city)="row">
                        {{ row.item.city.name }}
                    </template>

                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>

import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'ReportList',
    mixins: [timeUtils],
    props: {
       results: {
            type: Array,
            default() {
                return []
            }
        },
        upperLimit: {
           type: Number,
            default: null
        },
        lowerLimit: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            selectedRowId: null,
            sortBy: 'id',
            sortDesc: false,
            fields: [
                {key: 'id', label: this.$t('observations.id'), sortable: true},
                {key: 'created_time', label: this.$t('observations.created_time'), sortable: true},
                {key: 'type', label: this.$t('observations.type'), sortable: true},
                {key: 'value', label: this.$t('observations.value'), sortable: true},
                {key: 'road_direction', label: this.$t('observations.direction'), sortable: true},
                {key: 'road_number', label: this.$t('observations.road_number'), sortable: true},
                {key: 'section_number', label: this.$t('observations.road_section_number'), sortable: true},
                {key: 'distance_from_sec_start', label: this.$t('observations.distance_from_sec_start'), sortable: true},
                {key: 'city', label: this.$t('observations.city'), sortable: true},
                {key: 'street_name', label: this.$t('observations.street_name'), sortable: true},
            ]
        }
    },
    methods: {
        beyondLimit(value) {
            return (this.upperLimit && value > this.upperLimit) || (this.lowerLimit && value < this.lowerLimit)
        },
        rowClicked(item) {
            this.$emit('rowClicked', item)
            const selectedItemId = item.id;
            this.selectedRowId = selectedItemId === this.selectedRowId ? null : selectedItemId;
        },
        selectRow(index) {
            const selectedItemId = this.results[index].id;
            this.selectedRowId = selectedItemId === this.selectedRowId ? null : selectedItemId;
        },
        highlightRow(item) {
            // Apply 'bg-primary' class to the row if it's selected
            return {
                'report-selection': item.id === this.selectedRowId
            };
        },
    }
}
</script>
<style scoped>
</style>
