export const downloadHelper = {
    methods: {
        downloadFile: function (response, fileName) {
            var blob = new Blob([response.data],
                {type: response.headers['Content-Type']})
            if (window.navigator.msSaveOrOpenBlob) { // For I.E.
                navigator.msSaveBlob(blob, fileName)
            } else { // For other browsers:
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName
                document.body.appendChild(link)
                link.click()
                setTimeout(function () {
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(link.href)
                }, 0)
            }
        },
        downloadZipFile: function (response, fileName) {
            try {
                // Create a Blob from the response
                const blob = new Blob([response.data], { type: 'application/zip' });

                // Create a link element, set its download attribute, and trigger it
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName; // Set the name of the file to be downloaded
                link.click();

                // Cleanup the link element after triggering the download
                window.URL.revokeObjectURL(link.href);
            } catch (error) {
                //eslint-disable-next-line
                console.error('Error during file download:', error);
            }
        },
        getCommonReportParams: function (params) {
            params.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
            params.locale = this.$i18n.locale
            return params
        }
    }
}
