<template>
    <div>
        <transition name="fade">
            <price-list-list
                :results="results"
                @add="$emit('add')"
                @edit="editPriceList"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {priceHelper} from "../../mixins/PricingMixin";
import PriceListList from "./PriceListList";

export default {
    name: 'WeightDistancePriceLists',
    components: {PriceListList},
    mixins: [restApi, priceHelper],
    data: function () {
        return {
            results: [],
            loading: false,
        }
    },
    mounted: function () {
       this.fetchPriceLists()
    },
    methods: {
        fetchPriceLists: function () {
            this.loading = true
            this.results = []
            this.restFetch(this.priceListUrl,this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
        },
        editPriceList: function (priceList) {
            this.$emit('edit', priceList)
        },
        update: function () {
            this.fetchPriceLists()
        }
    }
}
</script>
