<template>
    <div class="content-container nopads">
        <div
            class="header"
        >
            <span class="title">{{ $t('menu.default_task_types') }}</span>
        </div>
        <div class="col-sm-12">
            <b-form v-if="selectedTaskTypes.length > 0" inline>
                <div class="col-sm-12 inspectable-container p-2">
                    <div
                        v-for="item in selectedTaskTypes"
                        :key="item.id"
                        :class="{'d-inline' : true}">
                <span class="inspectables inspectables-selected"
                      @click="updateTaskTypeSelection(item)">
                  {{ item.name }}
                  <strong>
                    X
                  </strong>
                </span>
                    </div>
                </div>
            </b-form>
            <div v-else
                 class="col-sm-12 p-2">
        <span>
          {{ $t('contracts.no_selected_task_types') }}
        </span>
            </div>

            <div class="col-sm-12">
                <!-- Task type list -->
                <span class="span-title"
                      v-if="selectedTaskTypes.length !== taskTypes.length"
                >
        {{ $t('contracts.select_task_type') }}
      </span>
                <div class=" inspectable-container">
                    <div
                        v-for="item in taskTypes"
                        :key="item.id"
                        :class="{ hidden : isSelected(item.id), 'd-inline' : !isSelected(item.id)}"
                    >
          <span
              class="inspectables"
              @click="updateTaskTypeSelection(item)"
          >
            {{ item.name }}
          </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 button-container">
            <b-button
                variant="success"
                class="form-button"
                :disabled="loading"
                @click.stop="submitTaskTypes"
            >
        <span>
            {{ $t('common.save') }}
          </span>
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'default-task-types',
    mixins: [restApi],
    components: {},
    data: function () {
        return {
            loading: false,
            defaultTaskTypesLoading: false,
            taskTypesLoading: false,
            taskTypes: [],
            selectedTaskTypes: []
        }
    },

    created: function () {
        this.fetchTaskTypes()
        this.fetchDefaultTaskTypes()
    },

    methods: {
        fetchDefaultTaskTypes: function () {
            this.defaultTaskTypesLoading = true
            this.isLoading()
            this.restFetch(this.defaultTaskTypeUrl, this.handleDefaultTaskTypes, this.onDefaultTaskTypeLoaded)
        },

        handleDefaultTaskTypes: function (results) {
            this.onDefaultTaskTypeLoaded()
            results.data.forEach(item => {
                this.selectedTaskTypes.push(item)
            })
        },

        onDefaultTaskTypeLoaded: function () {
            this.defaultTaskTypesLoading = false
            this.isLoading()
        },

        fetchTaskTypes: function () {
            this.taskTypesLoading = true
            this.isLoading()
            this.restFetch(this.taskTypeUrl, this.handleTaskTypes, this.onTaskTypeLoaded)
        },

        handleTaskTypes: function (response) {
            this.taskTypes = response.data
            this.onTaskTypeLoaded()
        },

        onTaskTypeLoaded: function () {
            this.taskTypesLoading = false
            this.isLoading()
        },

        indexOfTaskType: function (id) {
            return this.selectedTaskTypes.findIndex(function (obj) {
                return obj.id === id
            })
        },

        isSelected: function (id) {
            let index = this.indexOfTaskType(id)
            return index >= 0
        },

        updateTaskTypeSelection: function (taskType) {
            let index = this.selectedTaskTypes.findIndex(item => item.id === taskType.id)
            if (index >= 0) {
                this.selectedTaskTypes.splice(index, 1)
            } else {
                this.selectedTaskTypes.push(taskType)
            }
        },

        submitTaskTypes: function () {
            this.loading = true
            let ids = []
            this.selectedTaskTypes.forEach(item => ids.push(item.id))
            this.restAdd(this.defaultTaskTypeUrl, {task_types: ids}, this.submitTaskSuccess, this.submitTaskFail)
        },

        submitTaskSuccess: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            this.loading = false
        },

        submitTaskFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        isLoading: function () {
            this.loading = this.defaultTaskTypesLoading || this.taskTypesLoading
        }
    }
}
</script>
