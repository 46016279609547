<template>
    <div>
        <div class="col-sm-12 nopads">
            <transition
                name="fade"
            >
                <keep-alive>
                    <workday-details-search
                        :is-admin="isAdmin"
                        @search="searchDayDetailsWithParams"
                    />
                </keep-alive>
            </transition>
            <!-- Buttons -->
            <div
                class="button-container col-sm-12"
            >
                <b-button
                    id="work-time-add-record"
                    variant="success"
                    size="sm"
                    class="result-button"
                    @click="addRecord"
                >
                    {{ $t('work_time.add_record') }}
                </b-button>
                <b-button
                    variant="outline-success"
                    class="result-button"
                    size="sm"
                    :disabled="viewMode === DAY_DETAILS && (!detailResults || !detailResults.records)"
                    @click="exportExcel"
                >
                    {{ $t('trip.download_excel') }}
                </b-button>
            </div>
            <!-- Results -->
            <transition
                name="fade"
            >
                <workday-detail-list
                    v-if="detailResults && viewMode !== DETAILS"
                    ref="details"
                    :is-admin="isAdmin"
                    :params="searchDetailParams"
                    :results="detailResults"
                    @showDetails="showDayRecords"
                />
            </transition>
            <transition
                v-if="viewMode === DAY_DETAILS && (!detailResults || !detailResults.records)"
                name="fade"
            >
                <div class="col-sm-12">
                    {{ $t('trip.no_results') }}
                </div>
            </transition>
        </div>
        <!-- Editor -->
        <transition name="fade">
            <work-time-editor
                v-if="viewMode === EDIT"
                :work-time-record="workTimeRecord"
                @close="closeAndUpdate"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <transition name="fade">
            <work-time-add
                v-if="viewMode === ADD"
                :is-admin="isAdmin"
                :user-id="detailsUserId"
                :time-range="detailsRange"
                :name="detailsName"
                @close="closeAndUpdate"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />

        <!-- Work time records for a day - Pop up -->
        <transition
            v-if="viewMode === DETAILS"
            name="fade"
        >
            <work-time-list
                :is-observer="isObserver"
                :results="recordResults"
                :name="detailsName"
                :time-range="detailsRange"
                :user-id="detailsUserId"
                @closeRecords="closeAndUpdate"
                @edit="editWorkTimeRecord"
                @add="addUserRecord"
            />
        </transition>
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {workManagementHelper} from '@/components/mixins/WorkManagementMixin'
import WorkTimeList from '../WorkTimeList'
import WorkTimeEditor from '../WorkTimeEditor'
import {timeUtils} from '@/components/mixins/TimeUtils'
import WorkTimeAdd from '../WorkTimeRecordAdd'
import WorkdayDetailList from '../WorkDayDetailList'
import WorkdayDetailsSearch from '../WorkDayDetailsSearch'

export default {
    name: 'WorkTimeReports',
    components: {
        WorkdayDetailsSearch, WorkdayDetailList, WorkTimeAdd, WorkTimeList, WorkTimeEditor
    },
    mixins: [restApi, workManagementHelper, timeUtils],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            NONE: 0,
            DAY_DETAILS: 1,
            EDIT: 2,
            ADD: 3,
            DETAILS: 4,
            results: [],
            detailResults: null,
            recordResults: [],
            workTimeRecord: null,
            loading: false,
            detailsName: '',
            detailsRange: '',
            detailsUserId: -1,
            searchDayParams: null,
            searchDetailParams: null,
            viewMode: this.DAY_DETAILS,
            lastViewMode: null,
            lastDay: null
        }
    },
    mounted: function () {
        this.viewMode = this.DAY_DETAILS
    },
    methods: {
        exportExcel: function () {
            this.$refs.details.exportExcel(this.searchDetailParams)
        },
        addRecord: function () {
            this.detailsUserId = null
            this.lastViewMode = this.viewMode
            this.viewMode = this.ADD
        },
        addUserRecord: function () {
            this.lastViewMode = this.viewMode
            this.viewMode = this.ADD
        },
        searchRecordsWithParams: function (params) {
            if (this.viewMode === this.DETAILS) {
                // Hide view to update records
                this.viewMode = null
            }
            this.recordResults = []
            this.loading = true
            this.restFetchParams(this.workTimeUrl, params, this.handleRecordsResponse)
        },
        handleRecordsResponse: function (response) {
            this.recordResults = response.data
            this.loading = false
            this.viewMode = this.DETAILS
        },
        searchDayDetailsWithParams: function (params) {
            this.detailResults = null
            this.loading = true
            this.searchDetailParams = params
            this.restFetchParams(this.workDayDetailsUrl, params, this.handleDetailsResponse)
        },
        handleDetailsResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.detailResults = response.data
            }
            this.viewMode = this.DAY_DETAILS
        },
        editWorkTimeRecord: function (record) {
            this.workTimeRecord = record
            this.lastViewMode = this.viewMode
            this.viewMode = this.EDIT
        },
        closeAndUpdate: function () {
            this.viewMode = this.lastViewMode ? this.lastViewMode : this.DAY_DETAILS
            if (this.viewMode === this.DAY_DETAILS) {
                this.searchDayDetailsWithParams(this.searchDetailParams)
            }
            if (this.viewMode === this.DETAILS) {
                this.showDayRecords(this.lastDay)
                this.lastViewMode = this.DAY_DETAILS
            }
        },
        showDayRecords: function (day) {
            this.lastDay = day
            let params = {
                user: day.user,
                from: this.setTime(day.start_time),
                to: day.end_time ? this.setTime(day.end_time) : this.getEndOfTheDay(day.start_time)
            }
            this.detailsUserId = day.user
            this.detailsName = day.first_name + ' ' + day.last_name
            this.detailsRange = this.toLocalTime(day.start_time) + ' - '
            if (day.end_time) {
                this.detailsRange += this.toLocalTime(day.end_time)
            }
            this.searchRecordsWithParams(params)
        }
    }
}
</script>
