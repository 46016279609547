<template>
    <table class="table-sm" style="font-size: .9em">
        <tr>
            <td class="item-detail-text strong">
                {{ $t('subcontractors.applicant')  }}
            </td>
            <td class="item-detail-text">
                {{ application.requested_by.first_name }} {{ application.requested_by.last_name }}
            </td>
        </tr>
        <tr>
            <td class="item-detail-text strong">
                {{ $t('subcontractors.contractor') }}
            </td>
            <td class="item-detail-text">
                {{ application.contractor.name }}
            </td>
        </tr>
        <tr>
            <td class="item-detail-text strong">
                {{ $t('subcontractors.customer') }}
            </td>
            <td class="item-detail-text">
                {{ application.customer.name }}
            </td>
        </tr>
        <tr>
            <td class="item-detail-text strong">
                {{ $t('subcontractors.applied_on') }}
            </td>
            <td class="item-detail-text">
                {{ toLocalTime(application.requested_at) }}
            </td>
        </tr>
        <tr>
            <td class="item-detail-text strong">
                {{ $t('subcontractors.name') }}
            </td>
            <td class="item-detail-text">
                {{ application.subcontractor_name }}
            </td>
        </tr>
        <tr>
            <td class="item-detail-text strong">
                {{ $t('subcontractors.business_id') }}
            </td>
            <td class="item-detail-text">
                {{ application.subcontractor_business_id }}
            </td>
        </tr>
        <tr>
            <td class="item-detail-text strong">
                {{ $t('subcontractors.time_range') }}
            </td>
            <td class="item-detail-text">
                {{ getShortDateString(application.time_from) }} - {{ getShortDateString(application.time_to) }}
            </td>
        </tr>
        <tr>
            <td class="item-detail-text strong">
                {{ $t('orders.info') }}
            </td>
            <td class="item-detail-text">
                {{ application.info }}
            </td>
        </tr>
        <tr>
            <td class="item-detail-text strong">
                {{ $t('subcontractors.approval') }}
            </td>
            <td class="item-detail-text">
                {{ application.approval === 1 ? this.$t('work_permission.accepted') : (application.approval === 2 ? this.$t('work_permission.rejected') : this.$t('subcontractors.unhandled')) }}
            </td>
        </tr>
    </table>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: "ApplicationForm",
    mixins: [timeUtils],
    props: {
        application: {
            type: Object,
            default: null
        }
    }
}
</script>
<style>
.strong {
    font-weight: bold;
}
</style>
