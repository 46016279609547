import { render, staticRenderFns } from "./VehicleDefectSearch.vue?vue&type=template&id=2dcc9871&scoped=true"
import script from "./VehicleDefectSearch.vue?vue&type=script&lang=js"
export * from "./VehicleDefectSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dcc9871",
  null
  
)

export default component.exports