<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <!-- Result table-->
        <transition name="fade">
            <div class="col-sm-12 list-content result-table">
                <!-- Main table element -->
                <b-table
                    hover
                    show-empty
                    small
                    :items="inspections"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :sort-compare="sortCompare"
                    @row-clicked="onRowSelected"
                >
                    <template v-slot:cell(created)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                    <template v-slot:cell(type)="row">
                        {{ getInspectionTypeName(row.value) }}
                    </template>
                    <template v-slot:cell(frequency)="row">
                        {{ getInspectionFrequency(row.value) }}
                    </template>
                    <template v-slot:cell(outcome)="data">
                        <font-awesome-icon
                            v-if="hasStatus(data.item.results, resultBan)"
                            icon="exclamation-circle"
                            class="ban"
                        />
                        <font-awesome-icon
                            v-else-if="hasStatus(data.item.results, resultDefect)"
                            icon="exclamation-circle"
                            class="defect"
                        />
                    </template>
                    <template v-slot:cell(target)="row">
                        {{ getInspectionTarget(row.item) }}
                    </template>
                    <template v-slot:cell(inspector)="row">
                        {{ getInspector(row.item.reporter) }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.value.name }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {inspectionHelper} from "@/components/mixins/InspectionMixin";

export default {
    name: 'ContractInspectionsList',
    mixins: [timeUtils, restApi, inspectionHelper],
    props: {
        inspections: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            sortBy: 'created',
            sortDesc: true,
            sortDirection: 'asc',
            tableItems: [],
            fields: [
                {key: 'outcome', label: '', sortable: false},
                {
                    key: 'created',
                    label: this.$t('ins_report.list_label.created'),
                    sortable: true,
                    filterByFormatted: true
                },
                {
                    key: 'type',
                    label: this.$t('ins_report.list_label.report_type'),
                    sortable: true,
                    filterByFormatted: true
                },
                {key: 'name', label: this.$t('ins_report.list_label.name'), sortable: true, filterByFormatted: true},
                {
                    key: 'frequency',
                    label: this.$t('inspection_editor.inspection_frequency'),
                    sortable: true,
                    filterByFormatted: true
                },
                {
                    key: 'target',
                    label: this.$t('ins_report.list_label.target'),
                    sortable: true,
                    filterByFormatted: true
                },
                {
                    key: 'inspector',
                    label: this.$t('ins_report.list_label.inspector'),
                    sortable: true,
                    filterByFormatted: true
                },
                {
                    key: 'contract',
                    label: this.$t('ins_report.list_label.contract'),
                    sortable: true,
                    filterByFormatted: true
                }
            ]
        }
    },
    watch: {
        inspections() {
            this.initTableItems()
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        initTableItems: function () {
            this.tableItems = []
            this.inspections.forEach(function (observation) {
                if (observation.can_edit === true) {
                    observation.created_time = this.setTime(observation.created_time)
                    if (observation.closed_time !== undefined) {
                        observation.closed_time = this.setTime(observation.closed_time)
                    }
                    let contract = observation.contract ? observation.contract.name : undefined
                    if (!contract && observation.work_order) {
                        contract = observation.work_order.contract_task_type.contract ? observation.work_order.contract_task_type.contract.name : '-'
                    }
                    this.tableItems.push({
                        id: observation.id,
                        reporter: observation.reporter.first_name + ' ' + observation.reporter.last_name,
                        type_name: observation.type.name,
                        group_name: observation.type.observation_group.name,
                        geometry_type: observation.type.geometry_type === 1 ? this.$t('inspections.point') : this.$t('inspections.line'),
                        length: observation.length ? observation.length : '',
                        created_time: observation.created_time,
                        closed_time: observation.closed_time,
                        city: observation.city ? observation.city.name : '',
                        street_name: observation.street_name ? observation.street_name : '',
                        road_number: observation.road_number ? observation.road_number : '',
                        section_number: observation.section_number ? observation.section_number : '',
                        distance_from_sec_start: observation.distance_from_sec_start ? observation.distance_from_sec_start : '',
                        road_section_length: observation.road_section_length ? observation.road_section_length : '',
                        contract: contract,
                        order: observation.work_order ? observation.work_order.task_type.name : '',
                        status: (observation.closed_time ? this.$t('inspections.closed') : this.$t('inspections.open')),
                        observation_group: observation.type.observation_group.name,
                        road_direction: observation.road_direction,
                        road_lane: observation.road_lane,
                        info: observation.info ? observation.info : ''
                    })
                }
            }, this)
        },
        onRowSelected(item) {
            this.$emit('inspectionSelected', item)
        },
        hasStatus: function (results, status) {
            return results.find(item => item.result === status) !== undefined
        },
        sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
            if (key === 'inspector') {
                const a = this.getInspector(aRow['reporter'])
                const b = this.getInspector(bRow['reporter'])
                return a.localeCompare(b, compareLocale, compareOptions)
            } else if (key === 'target') {
                const a = this.getInspectionTarget(aRow)
                const b = this.getInspectionTarget(bRow)
                return a.localeCompare(b, compareLocale, compareOptions)
            } else if (key === 'created') {
                const a = aRow.created
                const b = bRow.created
                return a < b ? -1 : a > b ? 1 : 0
            } else if (key === 'frequency') {
                const a = this.getInspectionFrequency(aRow.frequency)
                const b = this.getInspectionFrequency(bRow.frequency)
                return a < b ? -1 : a > b ? 1 : 0
            }
            return false
        }

    }
}
</script>
