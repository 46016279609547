<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="content-container" ref="container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showObservationList"
        >
            <div class="header">
                <span class="title">{{ $t('observations.observations') }}</span>
            </div>
        </b-navbar>
        <keep-alive>
            <observation-list
                v-if="!editMode"
                :user="user"
                :is-map-viewer="isMapViewer"
                :is-admin="isAdmin"
                @search="fetchObservations"
            />
        </keep-alive>
        <div
            v-if="results.length > 0"
            class="button-container col-sm-12 trip-buttons"
        >
            <b-button
                v-if="!mapVisible && !reportVisible"
                variant="outline-success"
                :disabled="results.length < 1"
                class="result-button"
                @click="exportExcel"
            >
                {{ $t('trip.download_excel') }}
            </b-button>

            <b-button
                v-if="!mapVisible"
                variant="outline-success"
                :disabled="results.length < 1"
                class="result-button"
                @click="exportRoadReport"
            >
                {{ $t('observations.download_road_report') }}
            </b-button>

            <b-button
                v-if="!mapVisible && !reportVisible"
                variant="outline-primary"
                :disabled="results.length < 1"
                class="result-button"
                @click="showDetailsReport = true"
            >
                {{ $t('observations.details_report') }}
            </b-button>

            <b-button
                v-if="!reportVisible"
                variant="success"
                class="result-button"
                @click="toggleMapView"
            >
                {{ toggleMapViewLabel }}
            </b-button>

            <b-button
                v-if="!mapVisible"
                variant="success"
                class="result-button"
                @click="toggleReportView"
            >
                {{ toggleReportViewLabel }}
            </b-button>
        </div>

        <observation-details-report
            v-if="showDetailsReport && observations.length > 0"
            :observations = "observations"
            @close="showDetailsReport = false"
        />

        <!--Report View-->
        <div
            v-if="reportVisible"
            class="col-sm-12">
            <div class="header col-sm-12 button-container pt-1">
                <font-awesome-icon
                    v-if="roadReportData !== null"
                    icon="print"
                    class="print-icon"
                    @click.stop="print"
                />
            </div>
            <div id="printContainer" class="report" v-if="roadReportData !== null">

                <div class="header">
                    <span class="title">{{ $t('observations.road_report_title') }}</span>
                </div>

                <div class="col-sm-12 nopads coordinate-map dont-print">
                    <road-report-map
                        :observation-results="observations"
                        :show-selection-editor="showSelectionEditor"
                        :user="user"
                    />
                </div>

                <div class="col-sm-12 nopads">

                    <road-report-summary :reportData="roadReportData"/>
                    <hr class="report-divider"/>

                    <div v-for="(road, roadNumber) in roadReportData.roads" :key="roadNumber">
                        <div v-if="road.name === roadNumber" class="col-sm-12 report-heading">{{ road.name }}</div>
                        <div v-else class="col-sm-12 report-heading">{{ $t('road_reports.road_number') }} {{
                                roadNumber
                            }} - {{ road.name }}
                        </div>
                        <road-report-summary :reportData="road"/>

                        <div v-for="(section, sectionNumber) in road.sections" :key="sectionNumber">
                            <div class="col-sm-12 report-heading-sm mt-4">{{ $t('road_reports.road_section') }}
                                {{ sectionNumber }}
                            </div>
                            <road-report-summary :reportData="section"/>
                        </div>
                        <hr class="report-divider"/>

                    </div>

                </div>
            </div>
        </div>

        <!--Map View-->
        <div class="trip-results">
            <road-report-map
                ref="roadReportMap"
                v-if="mapVisible"
                :observation-results="observations"
                :show-selection-editor="showSelectionEditor"
                @updateObservations="closeAndUpdate"
                @onPolylineTap="editObservation"
                @onMarkerTap="editObservation"
            />

            <!--Result table-->
            <transition name="fade">
                <div
                    v-if="!mapVisible && !editMode"
                    class="col-sm-12 list-container"
                >
                    <div class="col-sm-12 list-content result-table">
                        <b-form inline class="p-2">
                            <b-checkbox switch v-model="extended" class="mr-1 ml-1">{{$t('observations.extended_data')}}</b-checkbox>
                            <b-checkbox switch v-model="selectAll" class="mr-1 ml-1">{{$t('observations.select_all')}}</b-checkbox>
                            <b-button
                                v-if="(isAdmin || isObserver) && selectedTableItems.length"
                                variant="primary"
                                size="sm"
                                class="mr-1 ml-1"
                                @click="editSelected"
                            >
                                {{ $t('observations.edit_selected') }}
                            </b-button>
                        </b-form>
                        <b-row class="nopads">
                            <b-table
                                small
                                style="width: 100%; overflow-x: auto"
                                id="resultTable"
                                ref="resultTable"
                                striped
                                :items="tableItems"
                                :fields="fields"
                                selectable
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :select-mode="selectMode"
                                selected-variant="success"
                                @row-selected="rowSelected"
                                @row-dblclicked="editItem"
                            >
                                <template v-slot:cell(action)="row">
                                    <b-tooltip :target="'edit_'+row.item.id" triggers="hover"  placement="left">
                                        {{ $t('observations.edit_observation') }}
                                    </b-tooltip>
                                    <font-awesome-icon
                                        :id="'edit_'+row.item.id"
                                        icon="info-circle"
                                        class="row-icon"
                                        @click.stop="editObservation(row.item.id)"
                                    />
                                </template>
                                <template v-slot:cell(photo)="row">
                                    <font-awesome-icon
                                        v-if="row.item.photos && row.item.photos.length > 0"
                                        icon="camera"
                                        @click.stop="showPhotoViewer(row.item.id)"
                                        class="row-icon"/>
                                </template>
                                <template v-slot:cell(created_time)="row">
                                    {{ getDateString(row.value) }}
                                </template>
                                <template v-slot:cell(road_address)="row">
                                    {{ getStreetAddressString(row.item) }}
                                </template>
                                <template v-slot:cell(csv)="row">
                                    <b-tooltip :target="'coord_'+row.item.id" triggers="hover"  placement="left">
                                        {{ $t('observations.download_coordinates') }}
                                    </b-tooltip>
                                    <font-awesome-icon
                                        :id="'coord_'+row.item.id"
                                        icon="file-csv"
                                        class="row-icon"
                                        style="color: #4AAE9B"
                                        @click.stop="downloadGeometry(row.item.id)"
                                    />
                                </template>
                            </b-table>
                        </b-row>
                    </div>
                </div>
            </transition>
        </div>

        <transition name="fade">
            <observation-editor
                v-if="editMode && selectedObservation"
                id="editor"
                :observation="selectedObservation"
                :user="user"
                :is-observer="isObserver"
                @scrollTop="scrollTop"
                @close="closeForm"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>

        <transition name="fade">
            <observation-bulk-editor
                v-if="editMode && selectedItems && selectedItems.length > 0"
                id="bulkEditor"
                :observations="selectedItems"
                :user="user"
                @scrollTop="scrollTop"
                @close="closeForm"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <transition name="fade">
            <observation-photo-viewer
                v-if="photoObservation"
                :observation="photoObservation"
                @close="photoObservation = null"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import ObservationList from './ObservationList'
import {vehicleHelper} from '../mixins/VehicleMixin'
import {timeUtils} from '../mixins/TimeUtils'
import ObservationEditor from './ObservationEditor'
import {downloadHelper} from '../mixins/DownloadMixin'
import RoadReportSummary from './RoadReportSummary'
import RoadReportMap from '../map/RoadReportMap'
import {printHelper} from '../mixins/PrintMixin'
import {Printd} from "printd";
import {coordinateConverter} from "../mixins/CoordinateConversionMixin"
import ObservationBulkEditor from "@/components/observation/ObservationBulkEditor";
import ObservationDetailsReport from "@/components/observation/ObservationDetailsReport";
import ObservationPhotoViewer from "@/components/observation/ObservationPhotoViewer";
import {observationHelper} from "@/components/mixins/ObservationMixin";

export default {
    name: 'Observations',
    components: {
        ObservationPhotoViewer,
        ObservationDetailsReport,
        ObservationBulkEditor,
        ObservationList,
        ObservationEditor,
        RoadReportSummary,
        RoadReportMap
    },
    mixins: [timeUtils, restApi, vehicleHelper, downloadHelper, printHelper, coordinateConverter, observationHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isMapViewer: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            loading: true,
            error: false,
            extended: false,
            results: [],
            roadReportData: null,
            tableItems: [],
            observationDetails: null,
            editMode: false,
            mapVisible: false,
            mapWasVisible: false,
            reportVisible: false,
            selectedTableItems: [],
            selectedItems: [],
            selectedObservation: null,
            photoObservation: null,
            lastSearchedParams: null,
            strDetails: this.$t('observations.details'),
            selectAll: false,
            showDetailsReport: false,
            toggleMapViewLabel: this.$t('trip.show_map'),
            toggleMapViewSelectedLabel: this.$t('trip.show_map_selected'),
            toggleReportViewLabel: this.$t('observations.show_road_report'),
            showSelectionEditor: false,
            simplifiedFields: [
                {
                    key: 'id',
                    label: this.$t('observations.id'),
                    sortable: true
                },
                {
                    key: 'created_time',
                    label: this.$t('observations.created_time'),
                    sortable: true
                },
                {
                    key: 'type_name',
                    label: this.$t('observations.type'),
                    sortable: true
                },
                {
                    key: 'length',
                    label: this.$t('observations.length'),
                    sortable: true
                },
                {
                    key: 'road_address',
                    label: this.$t('observations.road_location'),
                    sortable: true
                },
                {
                    key: 'contract',
                    label: this.$t('observations.contract'),
                    sortable: true
                },
                {
                    key: 'order',
                    label: this.$t('observations.order'),
                    sortable: true
                },
                {
                    key: 'reporter',
                    label: this.$t('observations.reporter'),
                    sortable: true
                },
                {
                    key: 'info',
                    label: this.$t('work_report.info'),
                    sortable: false
                },
                {
                    key: 'photo',
                    label: ''
                },
                {
                    key: 'action',
                    label: ''
                },
                {
                    key: 'csv',
                    label: ''
                }
            ],
            fullFields: [
                {
                    key: 'id',
                    label: this.$t('observations.id'),
                    sortable: true
                },
                {
                    key: 'created_time',
                    label: this.$t('observations.created_time'),
                    sortable: true
                },
                {
                    key: 'type_name',
                    label: this.$t('observations.type'),
                    sortable: true
                },
                {
                    key: 'group_name',
                    label: this.$t('observations.group'),
                    sortable: true
                },
                {
                    key: 'length',
                    label: this.$t('observations.length'),
                    sortable: true
                },
                {
                    key: 'road_direction',
                    label: this.$t('observations.direction'),
                    sortable: true
                },
                {
                    key: 'geometry_type',
                    label: this.$t('observations.geometry_type'),
                    sortable: true
                },
                {
                    key: 'road_number',
                    label: this.$t('observations.road_number'),
                    sortable: true
                },
                {
                    key: 'section_number',
                    label: this.$t('observations.road_section_number'),
                    sortable: true
                },
                {
                    key: 'distance_from_sec_start',
                    label: this.$t('observations.distance_from_sec_start'),
                    sortable: true
                },
                {
                    key: 'road_section_length',
                    label: this.$t('observations.road_section_length'),
                    sortable: true
                },
                {
                    key: 'contract',
                    label: this.$t('observations.contract'),
                    sortable: true
                },
                {
                    key: 'order',
                    label: this.$t('observations.order'),
                    sortable: true
                },
                {
                    key: 'reporter',
                    label: this.$t('observations.reporter'),
                    sortable: true
                },
                {
                    key: 'city',
                    label: this.$t('observations.city'),
                    sortable: true
                },
                {
                    key: 'street_name',
                    label: this.$t('observations.street_name'),
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t('observations.status'),
                    sortable: true
                },
                {
                    key: 'info',
                    label: this.$t('work_report.info'),
                    sortable: false
                },
                {
                    key: 'photo',
                    label: ''
                },
                {
                    key: 'action',
                    label: ''
                },
                {
                    key: 'csv',
                    label: ''
                }
            ],
            sortBy: 'created_time',
            sortDesc: true,
            selectMode: 'multi'
        }
    },
    watch: {
        selectedTableItems: function (value) {
            if(!this.mapVisible) {
                if(value.length > 0) {
                    this.toggleMapViewLabel = this.toggleMapViewSelectedLabel
                } else {
                    this.toggleMapViewLabel = this.$t('trip.show_map')
                }
            }
        },
        mapVisible: function (value) {
            let showSelected = this.selectedTableItems.length > 0 ? this.toggleMapViewSelectedLabel : this.$t('trip.show_map')
            this.toggleMapViewLabel = value ? this.$t('trip.show_table') : showSelected
            if(!value) {
                this.clearSelection()
            }
        },
        reportVisible: function (value) {
            this.toggleReportViewLabel = value ? this.$t('trip.show_table') : this.$t('observations.show_road_report')
            if(!value) {
                this.clearSelection()
            }
        },
        selectAll: function (value) {
            if (value) {
                this.selectAllItem()
            } else {
                this.clearSelection()
            }
        }
    },
    mounted: function () {
        this.loading = false
        this.d = new Printd()
    },
    computed: {
        observations() {
            if(this.selectedTableItems.length > 0) {
                return this.results.filter(result => {
                    return this.selectedTableItems.some(item => item.id === result.id);
                });
            } else {
                return this.results
            }
        },
        fields() {
            return this.extended ? this.fullFields : this.simplifiedFields
        }
    },
    methods: {
        clearPolygon: function () {
            this.$nextTick(() => {
                this.$refs.roadReportMap.clearPolygon()
            })
        },

        rowSelected: function (items) {
            if (this.isAdmin || this.isObserver) {
                this.selectedTableItems = items
            }
        },
        selectAllItem: function () {
            if (this.$refs.resultTable && (this.isAdmin || this.isObserver)) {
                this.$refs.resultTable.clearSelected()
                let table = this.$refs.resultTable.$el
                let tableBody = table.getElementsByTagName('tbody')[0]
                let tableRows = tableBody.getElementsByTagName('tr')
                this.tableItems.forEach(function (item, index) {
                    tableRows[index].click()
                })
            }
        },
        clearSelection: function () {
            this.selectAll = false
            this.selectedItems = []
            this.selectedTableItems = []
            this.selectedObservation = null
            if (this.$refs.resultTable) {
                this.$refs.resultTable.clearSelected()
            }
        },
        openEditor: function () {
            this.editMode = true
            this.scrollTop()
        },
        editItem: function (observation) {
            this.editObservation(observation.id)
        },
        editObservation: function (id) {
            this.selectedItems = []
            var observation = this.results.find(o => o.id === id)
            if (observation) {
                this.selectedObservation = observation
                this.openEditor()
            }
        },
        editSelected: function () {
            this.selectedObservation = null
            this.selectedItems = []
            const items = []
            items.push(...this.selectedTableItems)
            items.forEach(obj => {
                const observation = this.results.find(o => o.id === obj.id);
                if (observation) {
                    this.selectedItems.push(observation)
                }
            })
            // Open large editor if only one is selected
            if (this.selectedItems.length === 1){
                this.selectedObservation = this.selectedItems[0]
                this.selectedItems = []
            }
            this.openEditor()
        },
        fetchObservations: function (params) {
            this.mapWasVisible = this.mapVisible
            this.resetPage()
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.observationUrl, this.lastSearchedParams, this.handleResponse, this.handleError)
        },
        resetPage: function () {
            this.error = false
            this.results = []
            this.error = false
            if (this.mapVisible && this.$refs.observationMap) {
                this.$refs.observationMap.hideObservations()
            }
            this.mapVisible = false
            this.reportVisible = false
            this.tableItems = []
        },
        handleError: function (error) {
            this.loading = false
            if (error.response && error.response.status && error.response.status === 500) {
                EventBus.$emit('show-alert', this.$t('observations.err_too_much'))
            } else {
                EventBus.$emit('show-alert', this.$t('observations.err_general'))
            }
        },

        handleResponse: function (response) {
            this.clearSelection()
            if (this.mapWasVisible) {
                this.toggleMapView()
            }
            this.loading = false
            this.results = response.data
            this.tableItems = []
            this.results.forEach(function (observation) {
                if (observation.can_edit === true) {
                    observation.created_time = this.setTime(observation.created_time)
                    if (observation.closed_time !== undefined) {
                        observation.closed_time = this.setTime(observation.closed_time)
                    }
                    let contract = observation.contract ? observation.contract.name : undefined
                    if (!contract && observation.work_order) {
                        contract = observation.work_order.contract_task_type.contract ? observation.work_order.contract_task_type.contract.name : '-'
                    }
                    this.tableItems.push({
                        id: observation.id,
                        reporter: observation.reporter ? observation.reporter.first_name + ' ' + observation.reporter.last_name : "-",
                        type_name: observation.type.name,
                        group_name: observation.type.observation_group.name,
                        geometry_type: observation.type.geometry_type === 1 ? this.$t('observations.point') : this.$t('observations.line'),
                        length: observation.length ? observation.length : '',
                        created_time: observation.created_time,
                        closed_time: observation.closed_time,
                        city: observation.city ? observation.city.name : '',
                        street_name: observation.street_name ? observation.street_name : '',
                        road_number: observation.road_number ? observation.road_number : '',
                        section_number: observation.section_number ? observation.section_number : '',
                        distance_from_sec_start: observation.distance_from_sec_start ? observation.distance_from_sec_start : '',
                        road_section_length: observation.road_section_length ? observation.road_section_length : '',
                        contract: contract,
                        order: observation.work_order ? observation.work_order.task_type.name : '',
                        status: (observation.closed_time ? this.$t('observations.closed') : this.$t('observations.open')),
                        observation_group: observation.type.observation_group.name,
                        road_direction: observation.road_direction,
                        road_lane: observation.road_lane,
                        info: observation.info ? observation.info : '',
                        photos: observation.photos
                    })
                }
            }, this)
        },
        toggleMapView: function () {
            this.mapVisible = !this.mapVisible
            this.showSelectionEditor = true
        },
        toggleReportView: function () {
            if (!this.reportVisible) {
                this.loading = true
                this.roadReportData = null
                this.showSelectionEditor = false
                this.restFetchParams(this.observationRoadReportDataUrl, this.lastSearchedParams, this.handleReportDataResponse, this.handleError)
            } else {
                this.reportVisible = false
            }
        },
        handleReportDataResponse: function (response) {
            this.roadReportData = response.data
            this.reportVisible = true
            this.loading = false
        },
        showObservationList: function () {
            this.clearSelection()
            this.mapVisible = false
            this.reportVisible = false
            this.editMode = false
        },
        closeForm: function () {
            this.editMode = false
            if (this.mapWasVisible) {
                this.toggleMapView()
            }
            //Ensure nothing is selected
            this.clearSelection()
        },
        closeAndUpdate: function () {
            this.editMode = false
            this.fetchObservations(this.lastSearchedParams)
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.observationReportUrl, this.getCommonReportParams(this.lastSearchedParams), this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('observations.report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
        exportRoadReport: function () {
            this.loading = true
            this.restGetDownloadFile(this.observationRoadReportUrl, this.getCommonReportParams(this.lastSearchedParams), this.roadReportDownloadSuccess, this.fileDownloadFail)
        },
        roadReportDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('observations.road_report_file_name'))
        },
        scrollTop: function () {
            this.$nextTick(function () {
                this.$refs.container.scrollTop = 0
            })
        },
        print: function () {
            if (!this.loading) {
                this.d.print(document.getElementById('printContainer'), this.printStyle)
            }
        },
        downloadGeometry: function (id) {
            let observation = this.results.find(item => item.id === id)
            if (observation) {
                let csvData = this.getGeometryAsCsv(observation)
                if (csvData) {
                    // Generate csv and download
                    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                    let url = URL.createObjectURL(blob);
                    let pom = document.createElement('a');
                    pom.href = url;
                    pom.setAttribute('download', 'Routa_geometry.csv');
                    pom.click();
                }
            }
        },
        getGeometryAsCsv: function (observation) {
            let result = ''
            let data = []
            if (observation.geometry.point) {
                data = [[observation.geometry.point.x, observation.geometry.point.y]]
            } else {
                data = observation.geometry.line_string.points
            }
            data.forEach(point => {
                let tm35FinCoords = this.wgs84ToTm35Fin(point[1], point[0])
                result += tm35FinCoords[0] + ',' + tm35FinCoords[1] + '\r\n'
            })
            return result
        },
        showPhotoViewer(observation) {
            this.photoObservation = this.observations.find(item => item.id === observation)
        }
    }
}
</script>
