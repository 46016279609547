<template>
    <div class="col-sm-12 nopads">
        <div v-if="workAssignment">
            <b-row>
                <b-col class="nopads report-header" cols="10">
                    <div class="section-title">{{ $t('work_assignment.title') }}</div>
                </b-col>
                <b-col class="nopads">
                    <font-awesome-icon
                        icon="arrow-down"
                        class="pointer right no-focus arrow-icon dont-print"
                        v-bind:class="{rotated : detailsVisible}"
                        v-b-toggle.assignmentDetails
                        @click.stop="detailsVisible = !detailsVisible"/>
                </b-col>
            </b-row>
            <b-collapse
                id="assignmentDetails">
                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('observations.created_time') }}</span>
                        <span class="item-detail-text">{{ toLocalTime(workAssignment.created) }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('orders.status') }}</span>
                        <span class="item-detail-text">{{ getStatusString(workAssignment.status) }}</span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('work_assignment.created_by') }}</span>
                        <span class="item-detail-text">{{
                                workAssignment.created_by.last_name
                            }}, {{ workAssignment.created_by.first_name }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('work_assignment.assigned_to') }}</span>
                        <span v-if="workAssignment.assigned_to" class="item-detail-text">{{
                                workAssignment.assigned_to.last_name
                            }}, {{ workAssignment.assigned_to.first_name }}</span>
                        <span v-else class="item-detail-text">-</span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('orders.info') }}</span>
                        <span class="item-detail-text">{{ workAssignment.info || ' - ' }}</span>
                    </div>
                    <div class="col-sm-6 nopads" v-if="workAssignment.closing_date">
                        <span class="item-detail-title">{{ $t('observations.closed_time') }}</span>
                        <span class="item-detail-text">{{ toLocalTime(workAssignment.closing_date) }}</span>
                    </div>
                </b-row>
            </b-collapse>
        </div>
        <div v-if="!loading && !workAssignment && !canAdd" class="pt-2">
            {{ $t('work_assignment.no_assignment') }}
        </div>
        <div class="button-container"
             v-if="canAdd || (canEdit && workAssignment)">
            <b-button
                variant="primary"
                :disabled="loading"
                size="sm"
                class="result-button"
                @click.stop="$emit('editAssignment', workAssignment)">
                {{ workAssignment ? $t('work_assignment.edit') : $t('work_assignment.add') }}
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workAssignmentHelper} from '../mixins/WorkAssignmentMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'WorkAssignmentView',
    mixins: [restApi, workAssignmentHelper, timeUtils],
    props: ['id', 'type', 'canAdd', 'canEdit'],
    data: function () {
        return {
            workAssignment: null,
            loading: true,
            detailsVisible: false
        }
    },
    mounted: function () {
        this.fetchAssignment()
    },
    methods: {
        fetchAssignment: function () {
            this.loading = true
            this.restFetchParams(this.workAssignmentByDefectUrl, {
                type: this.type,
                id: this.id
            }, this.success, this.fail)
        },
        success: function (response) {
            this.loading = false
            this.workAssignment = response.data
        },
        fail: function () {
            this.loading = false
            this.workAssignment = null
        }
    }

}
</script>
