<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        v-if="results && results.length > 0"
        class="col-sm-12 p-2"
    >
        <div
            id="vehicle-list"
            class="list-content result-table p-2 pointer"
        >
            <b-table
                class="clickable"
                show-empty
                small
                stacked="sm"
                :items="results"
                :fields="fields"
                :sort-by.sync="diarySortBy"
                :sort-desc.sync="sortDesc"
                @row-clicked="emitEdit"
            >
                <template v-slot:cell(user)="row">
                    <div>
                        {{ row.item.user.first_name }} {{ row.item.user.last_name}}
                    </div>
                </template>
                <template v-slot:cell(record_date)="row">
                    <div>
                        {{ getShortDateString(row.value) }}
                    </div>
                </template>
                <template v-slot:cell(duty_officer)="row">
                    <div>
                        {{ row.item.duty_officer ? row.item.duty_officer.first_name + ' ' + row.item.duty_officer.last_name : $t('contract_diary.not_defined')}}
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {printHelper} from '../mixins/PrintMixin'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'ContractDiaryList',
    mixins: [timeUtils, printHelper, restApi],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        elyFields: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            attachment: null,
            sortDesc: false,
            diarySortBy: 'report_date',
        }
    },
    computed: {
        fields() {
            return [
                {key: 'record_date', label: this.$t('contract_diary.date'), sortable: false},
                {key: 'user', label: this.$t('observations.reporter'), sortable: false},
                {key: 'events', label: this.$t('contract_diary.events'), sortable: false},
            ]
        }
    },
    methods: {
        emitEdit: function (result) {
            let item = this.results.find(item => result.id === item.id)
            if (item) {
                this.$emit('edit', item)
            }
        }
    }
}
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
</style>
