<template>
    <base-modal v-if="showReport" style="z-index: 50" @cancel="$emit('cancel')">
        <danger-report-form
            :coordinates="coordinates"
            :selected-contract="selectedContract"
            @close="$emit('close')"
        />
        </base-modal>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from "@/components/mixins/MapMixin";
import BaseModal from "@/components/BaseModal.vue";
import {dangerHelper} from "@/components/mixins/DangerMixin";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin"
import dangerReportForm from "@/components/dangerreports/DangerReportForm.vue";


export default {
    name: 'DangerReportModal',
    components: {dangerReportForm, BaseModal},
    mixins: [restApi, timeUtils, mapHelper, attachmentHelper, dangerHelper],
    props: {
        isAdmin: Boolean,
        showReport: Boolean,
        coordinates: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
        }
    },


    methods: {


    }
}
</script>
