<template>
    <table class="table table-sm">
        <tbody>
        <tr>
            <td class="item-detail-title">
                {{ $t('orders.task_type') }}
            </td>
            <td class="item-detail-text">
                <span v-if="order.task_type.name">{{ order.task_type.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('orders.contract') }}
            </td>
            <td class="item-detail-text">
                <span v-if="order.contract.name">{{ order.contract.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.number') }}
            </td>
            <td class="item-detail-text">
                <span v-if="order.contract.contract_number">{{ order.contract.contract_number }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.customer') }}
            </td>
            <td class="item-detail-text">
                <span v-if="order.contract.customer">{{ order.contract.customer.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.contractor') }}
            </td>
            <td class="item-detail-text">
                <span v-if="order.contract.contractor">{{ order.contract.contractor.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.start') }}
            </td>
            <td class="item-detail-text">
                <span v-if="order.contract.start_date">{{ toLocalTime(order.contract.start_date) }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.end') }}
            </td>
            <td class="item-detail-text">
                <span v-if="order.contract.end_date">{{ toLocalTime(order.contract.end_date) }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'TargetInfoOrder',
    mixins: [timeUtils],
    props: {
        order: {
            type: Object,
            default: null
        }
    }
}
</script>
