<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
            @click="closeAndUpdate"
        >
            <div
                v-if="!editMode && !showWizard"
                class="header"
            >
                <span class="title">{{ $t('menu.sewer_drains') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="closeAndUpdate"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition-group name="fade">
                <div key="search-sewer-line-list">
                    <keep-alive>
                        <storm-sewer-drain-search
                            v-if="!editMode && !showWizard"
                            :user="user"
                            @search="fetchSewerDrains"
                        />
                    </keep-alive>
                    <keep-alive>
                        <storm-sewer-drain-list
                            v-if="!editMode && !showWizard"
                            :storm-sewer-drains="results"
                            @rowClicked="editSewerDrain"
                        />
                    </keep-alive>
                </div>
            </transition-group>
            <transition name="fade">
                <sewer-drain-details
                    v-if="editMode"
                    v-model="sewerDrain"
                    :user="user"
                    @showList="showList"
                    @closeAndUpdate="closeAndUpdate"
                    @scrollTop="scrollTop"
                />
            </transition>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from "@/event-bus";
import {downloadHelper} from "../mixins/DownloadMixin";
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import StormSewerDrainSearch from "@/components/stormsewers/StormSewerDrainSearch";
import SewerDrainDetails from "@/components/stormsewers/SewerDrainDetails";
import StormSewerDrainList from "@/components/stormsewers/StormSewerDrainList";

export default {
    name: 'StormSewerDrains',
    components: {
        SewerDrainDetails,
        StormSewerDrainList, StormSewerDrainSearch},
    mixins: [restApi, downloadHelper, stormSewersMixin],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            results: [],
            editMode: false,
            showWizard: false,
            sewerDrain: null,
            loading: false,
            lastSearchedParams: null,
            showFileImporter: false,
            scrollPosition: 0
        }
    },

    methods: {
        fetchSewerDrains(params) {
            this.loading = true
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.stormDrainUrl, this.lastSearchedParams, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        },
        editSewerDrain: function (item) {
            this.editMode = true
            this.sewerDrain = this.jsonToItem(item)
        },
        closeAndUpdate: function () {
            this.fetchSewerDrains(this.lastSearchedParams)
            this.showList()
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.powerStationXlsxUrl, this.getCommonReportParams(this.lastSearchedParams), this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('power_stations.report_file_name'))
        },
        showList: function () {
            this.editMode = false
            this.showWizard = false
        },
        scrollTop: function () {
            this.scrollPosition = this.$refs.container.scrollTop
            this.$nextTick(function () {
                this.$refs.container.scrollTop = 0
            })
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
    }
}
</script>
