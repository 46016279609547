<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div v-if="!editMode"
                 class="header">
                <span class="title">{{ $t('menu.general_guides') }}</span>
            </div>
            <div v-else-if="!isMobile"
                 class="header col-sm-12 nopads"
                 @click="showList">
                <span class="pointer">
                  <i class="fa fa-arrow-left"/>
                </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>
        <div class="col-sm-12"
             v-if="isObserver && !editMode">
            <div
                class="data-list-item add"
                @click.stop="addNewGuide()"
            >
                <div class="item-img-cont add-title">
                    <i class="fa fa-plus"/>
                </div>
                <div class="item-text-cont">
                    <span class="item-title-add">{{ $t('common.add_new') }}</span>
                    <font-awesome-icon
                        icon="file-contract"
                        class="card-body-icon"
                    />
                </div>
            </div>
        </div>
        <div v-if="!editMode">
            <general-guides-search
                @search="searchSharedDocuments"
            />
            <general-guides-list
                v-bind:class="{hidden:editMode}"
                :items = "results"
                :loading = "loading"
                :fetchFinished = "fetchFinished"
                @edit="editDocumentDetails"
            />
        </div>
            <transition name="fade">
                <general-guides-editor
                    v-if="editMode"
                    ref="guidesEditor"
                    @close="showList"
                    :document = "document"
                    :editable = "editable"
                />
            </transition>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import GeneralGuidesList from './GeneralGuidesList'
import GeneralGuidesEditor from './GeneralGuidesEditor'
import GeneralGuidesSearch from './GeneralGuidesSearch'

export default {
    name: "GeneralGuides",
    components: {
        GeneralGuidesList,
        GeneralGuidesEditor,
        GeneralGuidesSearch
    },
    mixins: [restApi],
    data: function () {
        return {
            isMobile: false,
            loading: false,
            isObserver: true,
            editMode: false,
            editable: true,
            generalGuide: null,
            document: null,
            fetchFinished: false,
            lastSearchedParams: null,
            results: []
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    methods:{
        addNewGuide: function () {
            this.document = {
                id: -1,
                name: '',
                description: '',
                added_time: null,
                added_by: null,
                attachment: null
            }
            this.editMode = true
        },

        showList: function () {
            this.searchSharedDocuments(this.lastSearchedParams)
            this.editMode = false
            this.document = null
        },

        editDocumentDetails: function (result) {
            this.editable = this.user ? result.company.id === this.user.company.id : false
            this.editMode = true
            this.setDocument(result)
        },

        setDocument: function (document) {
            this.document = document
        },

        searchSharedDocuments: function (params) {
            if (!params) {
                params = {searchMode: 1}
            }
            this.loading = true
            this.searchParams = params
            this.lastSearchedParams = params
            if (params.searchMode === 1) {
                this.restFetchParams(this.sharedDocumentUrl + '/sharedTo', this.searchParams, this.handleDocumentSearch, this.handleSearchFail)
            }
            else {
                this.restFetchParams(this.sharedDocumentUrl + '/sharedBy', this.searchParams, this.handleDocumentSearch, this.handleSearchFail)
            }
        },

        handleDocumentSearch: function (response) {
            if (response.status === 200) {
                this.fetchFinished = true
            }
            else {
                this.fetchFinished = false
            }
            this.results = response ? response.data : []
            this.loading = false
        },

        handleSearchFail() {
            this.loading = false
        }
    }
}
</script>

