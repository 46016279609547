export const dangerTypes = {
    ACCIDENT: 1,
    CLOSE_CALL: 2,
    OTHER: 3
}

export const dangerConsequences = {
    PERSONAL_INJURY: 1,
    ECONOMICAL_LOSS: 2,
    DELAYS_IN_WORK: 3,
    DAMAGE: 4,
    ENVIRONMENTAL_DAMAGE: 5
}

export const dangerHelper = {
    methods: {
        getDangerTypeName: function (type) {
            switch (type) {
                case dangerTypes.ACCIDENT:
                    return this.$t('danger_report.event_type_list.accident')
                case dangerTypes.CLOSE_CALL:
                    return this.$t('danger_report.event_type_list.close_call')
            }
            return this.$t('danger_report.event_type_list.unknown')
        },
        getDangerConsequencesName: function (type) {
            switch (type) {
                case dangerConsequences.PERSONAL_INJURY:
                    return this.$t('danger_report.consequences.personal_injury')
                case dangerConsequences.ECONOMICAL_LOSS:
                    return this.$t('danger_report.consequences.economical_loss')
                case dangerConsequences.DELAYS_IN_WORK:
                    return this.$t('danger_report.consequences.delays_in_work')
                case dangerConsequences.DAMAGE:
                    return this.$t('danger_report.consequences.damage')
                case dangerConsequences.ENVIRONMENTAL_DAMAGE:
                    return this.$t('danger_report.consequences.environmental_damage')
            }
            return this.$t('danger_report.consequences.unknown')
        },
        getDangerType: function () {
            return [
                {name: this.$t('danger_report.event_type_list.accident'), value: dangerTypes.ACCIDENT},
                {name: this.$t('danger_report.event_type_list.close_call'), value: dangerTypes.CLOSE_CALL}
            ]
        }
    }

}
