<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 p-2 pt-4">
                <h3
                    v-if="modeUpdate.id > 0"
                    class="nopads"
                >
                    {{ $t('work_time.edit_mode') }}
                </h3>
                <h3
                    v-else
                    class="nopads"
                >
                    {{ $t('work_time.add_mode') }}
                </h3>
            </div>
            <div class="col-sm-12 form-data">
                <div
                    v-if="isAdmin"
                    class="nopads"
                >
                    <span class="span-title">{{ $t('users.company') }}</span>
                    <b-form-select
                        v-if="modeUpdate.id < 1"
                        id="company"
                        v-model="company"
                        size="sm"
                        class="mb-3"
                        :disabled="!companies || companies.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('users.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </b-form-select>
                    <span v-else>{{ modeUpdate.company.name }}</span>
                </div>

                <span class="span-title">{{ $t('common.name') }}</span>
                <b-form-group
                    class="title"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="modeUpdate.name"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">{{ $t('work_time.type') }}</span>
                <b-form-group label-for="types">
                    <b-form-radio-group
                        id="types"
                        v-model="type"
                        :options="options"
                        name="type-options"
                    />
                </b-form-group>
                <b-row>
                    <div
                        v-if="type === 1"
                        class="col-sm-4 nopads"
                    >
                        <span class="span-title">{{ $t('work_time.tracing') }}</span>
                        <b-form-group
                            class="title"
                            label-for="name"
                        >
                            <b-form-checkbox
                                id="tracing"
                                v-model="tracing"
                                name="checkbox"
                                value="true"
                                unchecked-value="false"
                            >
                                {{ $t('work_time.location_tracing_enabled') }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                    <div class="col-sm-4 nopads" v-if="type === 1">
                        <!-- Select area group-->
                        <span class="span-title">{{ $t('contracts.area_group') }}</span>
                        <b-form-group
                            class="title"
                            label-for="areaGroups">
                            <b-form-select
                                id="areaGroups"
                                v-model="areaGroupId"
                                size="sm"
                                class="sm-3"
                                :disabled="areaGroups.length < 1"
                                @change="checkAreaTracking"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('work_time.select_area_group') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in areaGroups"
                                    :key="option.id"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </b-row>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'WorkTimeModeEditor',
    mixins: [workManagementHelper, restApi],
    props: {
        mode: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            company: null,
            group: null,
            groups: [],
            companies: [],
            type: null,
            tracing: false,
            modeUpdate: this.jsonToItem(this.mode),
            options: [
                {text: this.$t('work_time.work_type'), value: 1},
                {text: this.$t('work_time.absent_type'), value: 2}
            ],
            areaGroups: [],
            areaGroupId: null
        }
    },
    watch: {
        type: function (value) {
            if (value !== 1) {
                this.tracked = false
                this.areaGroupId = null
            }
        }
    },
    mounted: function () {
        if (this.isAdmin) {
            this.fetchCompanies()
            if (this.modeUpdate.id > 0) {
                this.company = this.modeUpdate.company.id
            }
        }
        if (this.modeUpdate.id > 0) {
            if (this.modeUpdate.work_type) {
                this.type = 1
            } else if (this.modeUpdate.absent_type) {
                this.type = 2
            }
        }
        if (this.modeUpdate.tracked) {
            this.tracing = true
        }
        if (this.modeUpdate.area_group) {
            this.areaGroupId = this.modeUpdate.area_group.id
        }
        this.fetchAreaGroups();
    },

    methods: {
        fetchCompanies: function () {
            if (this.isAdmin) {
                this.loading = true
                this.restFetch(this.companyUrl, this.handleCompanies)
            }
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        setDefaultValues: function (mode) {
            if (mode.name === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return undefined
            }
            mode.work_type = false
            mode.absent_type = false
            if (this.type === 1) {
                mode.work_type = true
                mode.area_group = this.areaGroupId ? {id: this.areaGroupId} : null
            } else if (this.type === 2) {
                mode.absent_type = true
                mode.area_group = null
            }
            if (this.isAdmin) {
                if (this.company) {
                    mode.company = {id: this.company}
                } else {
                    EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_company'))
                    return undefined
                }
            }
            mode.tracked = (this.tracing === 'true')
            return mode
        },

        submit: function () {
            let json = this.setDefaultValues(this.modeUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.modeUpdate.id < 1) {
                    this.restAdd(this.workTimeModeUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.workTimeModeUrl, json, this.success, this.fail)
                }
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        fetchAreaGroups: function () {
            this.areaGroups = []
            this.loading = true
            this.restFetchParams(this.areaGroupUrl, {no_geom: 1}, this.handleAreaGroupResponse)
        },
        handleAreaGroupResponse: function (response) {
            this.loading = false
            if (response != null) {
                this.areaGroups = response.data
            }
        },


    }
}
</script>
