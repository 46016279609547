<template>
    <div class="col-sm-12">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="hands-helping"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="results && results.length"
                id="accordion"
                class="col-sm-12 panel-group data-container"
            >
                <div
                    v-for="result of results"
                    :key="result.id"
                    v-b-toggle="'accordion-'+result.customer.id+'-'+result.contractor.id"
                    class="data-list-item"
                >
                    <div class="col-sm-12 item-header">
                        <div class="item-img-cont">
                            <font-awesome-icon icon="hands-helping"/>
                        </div>
                        <div class="item-text-cont">
              <span class="item-title-single">
                {{ result.customer.name }} - {{ result.contractor.name }}
              </span>
                        </div>
                    </div>
                    <b-collapse
                        :id="'accordion-'+result.customer.id+'-'+result.contractor.id"
                        class="mt-2 item-details"
                    >
                        <div class="col-sm-12 button-container">
                            <b-button
                                variant="danger"
                                class="routa-button"
                                @click.stop="deleteRelationship(result)"
                            >
                                <i class="fa fa-times"/>
                                <span class="button-text">
                  {{ $t('common.remove') }}
                </span>
                            </b-button>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../../mixins/WorkManagementMixin'
import {restApi} from '../../mixins/RestApiMixin'
import {EventBus} from '@/event-bus'

export default {
    name: 'CompanyRelationshipList',
    mixins: [workManagementHelper, restApi],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false
        }
    },
    methods: {
        deleteRelationship: function (relationship) {
            // TODO - Confirmation dialog!
            if (relationship) {
                var url = this.companyRelationshipUrl + '?customer=' + relationship.customer.id + '&contractor=' + relationship.contractor.id
                this.restDelete(url, this.success, this.fail)
            }
        },

        success: function () {
            this.loading = true
            this.$emit('update')
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
