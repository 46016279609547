<template>
    <div class="w-100">
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
            v-bind:class="{ list_closed: !listOpen }"
        >
        <span
            class="vehicle-list-title"
            @click.stop="toggleList">
            {{ $t('materialstorage.title_plural') }}
        </span>
            <div
                class="vehicle-list-caret"
                v-bind:class="{ caret_open: listOpen }"
                @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>
            <!-- History road type selection -->
            <b-form-group
                class="mt-2 mr-3 ml-3 mb-2 text-light">
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="visible"
                    :options="storageOptions"
                    name="radio-options"
                    buttons
                    button-variant="light"
                    size="sm"
                    @change="updateStorages"
                />
            </b-form-group>
        </div>

    </div>
    <div
        v-if="loading"
        class="spinner"
    />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from '../mixins/MapMixin'
import {getMaterialStorageStatus, Status} from './MaterialStorageHelper'
import {geometryMixin} from "@/components/mixins/GeometryMixin";

export default {
    name: "MaterialStorageMap",
    mixins: [restApi, mapHelper, geometryMixin],
    props: {
        map: {
            type: Object
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        requireBoundingBox:{
            type: Boolean,
            default: false
        },
        visibleStorages:  {
            type: Number,
            default: 1
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            OWN: 1,
            CONTRACT: 2,
            ALL: 3,
            storages: [],
            visible: 1,
            bbox: null,
            visibilityZoomLevel: 10,
            loading: false,
            listOpen: false,
            storageOptions: [
                { text: this.$t('materialstorage.own_storages'), value: 1 },
                { text: this.$t('materialstorage.contract_storages'), value: 2 },
                { text: this.$t('common.all'), value: 3 },
            ]
        }
    },
    mounted() {
        this.visible = this.visibleStorages
        this.updateStorages()
    },
    beforeDestroy() {
        this.hideMarkers()
    },
    watch: {
        map() {
          this.$nextTick(function() {
            this.updateStorages()
          })
        },
        selectedContractId() {
            this.updateStorages()
        },
        selectedContractIds() {
            this.updateStorages()
        },
        boundingBox() {
            this.updateStorages()
        }
    },
    methods: {
        async updateStorages(editorUpdate = false) {
            if (this.map) {
                if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                    this.bbox = null
                    this.hideStorages()
                } else if (this.boundingBox && this.isBBoxValid(this.boundingBox) &&
                    (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)) || editorUpdate) {
                    const extendRate = 0.05
                    this.bbox = [
                        this.boundingBox[0] - extendRate,
                        this.boundingBox[1] - extendRate,
                        this.boundingBox[2] + extendRate,
                        this.boundingBox[3] + extendRate
                    ]
                    this.loading = true
                    this.storages = []
                    if (this.visible === this.OWN || this.visible === this.ALL) {
                        this.storages = await this.fetchActiveMaterialStorages() || []
                    }
                    if (this.visible === this.CONTRACT || this.visible === this.ALL) {
                        let contractStorages = await this.fetchActiveContractMaterialStorages() || []
                        this.storages.push(...contractStorages)
                    }
                    this.loading = false
                    this.$emit('settingsChanged', this.visible)
                    this.refreshMarkers()
                }
            }
        },
        refreshMarkers() {
            this.hideMarkers()
            this.showMarkers()
        },
        showMarkers() {
            if (this.storages && this.storages.length > 0 && this.map != null) {
                for (let index in this.storages) {
                    let storage = this.storages[index]
                    let status = getMaterialStorageStatus(storage)
                    let icons = {
                        [Status.AVAILABLE]: this.map.getMarkerStore().getStorageMarkerAvailableIcon(),
                        [Status.ORDERED]: this.map.getMarkerStore().getStorageMarkerOrderedIcon(),
                        [Status.OUT_OF_STOCK]: this.map.getMarkerStore().getStorageMarkerOutOfStockIcon()
                    }
                    let icon = icons[status] || icons[Status.OUT_OF_STOCK]
                    this.map.showMapMarker(storage.id, this.MATERIAL_STORAGE, storage.location.y,
                        storage.location.x, icon)
                }
            }
        },
        hideMarkers() {
            if (this.map != null) {
                this.map.removeMapMarkerByType(this.MATERIAL_STORAGE)
            }
        },
        hideStorages() {
            this.storages = []
            this.refreshMarkers()
        },
        showStorageTitle: function (data) {
            // Show info bubble
            if (data.type === this.MATERIAL_STORAGE) {
                let item = this.storages.find(s => s.id === data.id)
                if (item) {
                    let x, y
                    if(item.location && item.location.x) {
                        x = item.location.x
                        y = item.location.y
                    }
                    if (item) {
                        this.map.addMarkerLabel(item.id, item.name, y, x, data.type)
                    }
                }
            }
        },
        hideStorageTitle: function (data) {
            // Hide info bubble
            this.map.hideMarkerLabel(data.id, data.type)
        },
        async fetchActiveMaterialStorages() {
            let params = {}
            params.active = true
            if(this.selectedContractIds && this.selectedContractIds.length > 0) {
                params.contract = this.selectedContractIds
            } else if(this.selectedContractId) {
                params.contract = this.selectedContractId
            }
            if(this.bbox && this.bbox.length > 0) {
                params.bbox = this.bbox
            }
            try {
                return (await new Promise((resolve, reject) => this.restFetchParams(this.materialStorageUrl, params, resolve, reject))).data
            } catch (e) {
                return null
            }
        },
        async fetchActiveContractMaterialStorages() {
            let params = {}
            if(this.selectedContractIds && this.selectedContractIds.length > 0) {
                params.contract = this.selectedContractIds
            } else if(this.selectedContractId) {
                params.contract = this.selectedContractId
            }
            if(this.boundingBox && this.boundingBox.length > 0) {
                params.bbox = this.boundingBox
            }
            try {
                return (await new Promise((resolve, reject) => this.restFetchParams(this.materialStorageContractsUrl,params, resolve, reject))).data
            } catch (e) {
                return null
            }
        },
        getStorage(id) {
            return this.storages.find(storage => storage.id === id)
        },
        toggleList: function () {
            this.listOpen = !this.listOpen
        }
    }
}
</script>

<style scoped>

</style>
