<template>
    <div
        id="editor"
        class="col-sm-12 nopads"
    >
        <base-modal
            v-if="!assignmentMode"
            class="col-sm-12"
            :no-top-padding="true"
        >
            <h2
                v-if="defectUpdate.id > 0"
                st
                class="form-title editor-title"
            >
                {{ $t('defects.edit') }}
            </h2>
            <h2
                v-else
                class="form-title editor-title"
            >
                {{ $t('defects.new') }}
            </h2>
            <div class="col-sm-12 form-data">
                <span class="span-title">{{ $t('trip_list.vehicle') }}</span>
                <b-form-select
                    id="vehicle"
                    v-model="defectUpdate.vehicle.id"
                    size="sm"
                    class="sm-3"
                    :disabled="!isNew"
                >
                    <option
                        v-for="vehicle in vehicles"
                        :key="vehicle.id"
                        :value="vehicle.id"
                    >
                        {{ vehicle.license_plate }} {{ vehicle.make }} {{ vehicle.vehicle_model }}
                    </option>
                </b-form-select>

                <span class="span-title">{{ $t('vehicle_defects.inspectable') }}</span>
                <b-form-input
                    v-model="defectUpdate.inspectable_name"
                    type="text"
                    size="sm"
                />

                <span class="span-title">{{ $t('defects.description') }}</span>
                    <b-form-textarea
                        id="info"
                        v-model="defectUpdate.description"
                        :rows="3"
                        :max-rows="6"
                    />

                <span class="span-title">{{ $t('defects.status') }}</span>
                <b-form-group class="nopads">
                    <b-form-select
                        id="status"
                        v-model="defectUpdate.status"
                        size="sm"
                        class="sm-3"
                    >
                        <option
                            v-if="!isNew"
                            :value="2"
                        >
                            {{ $t('inspection.options.okay') }}
                        </option>
                        <option :value="resultDefect">
                            {{ $t('inspection.options.defects') }}
                        </option>
                        <option :value="resultBan">
                            {{ $t('inspection.options.ban') }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <work-assignment-view
                    v-if="!isNew"
                    :type="this.VEHICLE_DEFECT"
                    :id="defect.id"
                    :can-edit="true"
                    :can-add="isAdmin || isObserver"
                    @editAssignment="editAssignment"
                />

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="primary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        :disabled="loading || !validateFields()"
                        @click.stop="submit"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </base-modal>
        <div v-if="!isNew && assignmentMode">
            <transition name="fade">
                <work-assignment-editor
                    :vehicle-defect="defect"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    :work-assignment="workAssignment"
                    @close="setAssignmentEditorVisibility(false)"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {inspectionHelper} from '../../mixins/InspectionMixin'
import {EventBus} from '@/event-bus'
import WorkAssignmentEditor from '../../workassignment/WorkAssignmentEditor'
import {workAssignmentHelper} from '../../mixins/WorkAssignmentMixin'
import WorkAssignmentView from '../../workassignment/WorkAssignmentView'
import BaseModal from "@/components/BaseModal.vue";

export default {
    name: 'VehicleDefectEditor',
    components: {BaseModal, WorkAssignmentEditor, WorkAssignmentView},
    mixins: [restApi, inspectionHelper, workAssignmentHelper],
    props: {
        defect: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            vehicles: [],
            loading: false,
            defectUpdate: this.defect,
            isNew: this.defect.id < 1,
            workAssignment: null,
            assignmentMode: false
        }
    },
    mounted: function () {
        if (this.isNew) {
            this.fetchVehicles()
        } else {
            this.vehicles = [this.defectUpdate.vehicle]
        }
    },
    methods: {
        fetchVehicles: function () {
            this.loading = true
            this.restFetch(this.vehicleUrl, this.handleVehicles)
        },

        handleVehicles: function (response) {
            this.loading = false
            this.vehicles = response.data
        },

        checkValues: function (defect) {
            if (defect.status < 1 ||
                !defect.vehicle || defect.vehicle.id < 1 ||
                !defect.inspectable_name || defect.inspectable_name === '' ||
                !defect.description || defect.description === '') {
                return undefined
            }
            if (this.isNew) {
                defect.fixer = null
                defect.fixed_date = null
            }
            return defect
        },

        submit: function () {
            var json = this.checkValues(this.defectUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.isNew) {
                    this.restAdd(this.vehicleDefectUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.vehicleDefectUrl, json, this.success, this.fail)
                }
            } else {
                EventBus.$emit('show-alert', this.$t('defects.missing_fields'))
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        validateFields: function () {
            return this.defectUpdate.vehicle.id !== -1 && this.defectUpdate.inspectable_name.length > 0 && this.defectUpdate.status !== -1
        },

        setAssignmentEditorVisibility: function (visible) {
            this.assignmentMode = visible
        },

        closeAndUpdate: function (assignment) {
            this.setAssignmentEditorVisibility(false)
            this.workAssignment = assignment
        },
        editAssignment: function (assignment) {
            this.workAssignment = assignment
            this.setAssignmentEditorVisibility(true)
        }
    }

}
</script>

<style scoped>

</style>
