<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                class="mt-2"
                @submit.prevent="onSubmit"
                @reset="onReset"
            >
                <b-row class="nopads">
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('subcontractors.customer') }}</span>
                        <b-form-select
                            v-model="customer"
                            size="sm"
                            :disabled="!!loading"
                        >
                            <option :value="null">
                                {{ $t('subcontractors.select_customer') }}
                            </option>
                            <option
                                v-for="customer in customers"
                                :key="customer.id"
                                :value="customer.id"
                            >
                                {{ customer.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('subcontractors.contractor') }}</span>
                        <b-form-select
                            v-model="contractor"
                            size="sm"
                            :disabled="!!loading"
                        >
                            <option :value="null">
                                {{ $t('subcontractors.select_contractor') }}
                            </option>
                            <option
                                v-for="contractor in contractors"
                                :key="contractor.id"
                                :value="contractor.id"
                            >
                                {{ contractor.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('subcontractors.name') }}</span>
                        <b-form-input
                            v-model="subcontractorName"
                            :placeholder="$t('subcontractors.name')"
                            size="sm"
                            :disabled="!!loading"
                        />
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('delivery_note.clear') }}
                    </b-button>
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="!!loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: "ApplicationSearch",
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            contractor: null,
            customer: null,
            subcontractorName: null,
            customers: [],
            contractors: [],
            loading: 0
        }
    },
    mounted() {
        this.fetchContractors()
        this.fetchCustomers()
    },
    methods: {
        fetchCustomers: function () {
            this.loading++
            this.vehicleOptions = []
            this.restFetch(this.customerUrl, this.handleCustomers)
        },
        handleCustomers: function (response) {
            if (response && response.data) {
                this.customers = response.data
            }
            this.loading--
        },
        fetchContractors: function () {
            this.loading++
            this.vehicleOptions = []
            this.restFetch(this.contractorUrl, this.handleContractors)
        },
        handleContractors: function (response) {
            if (response && response.data) {
                this.contractors = response.data
            }
            this.loading--
        },
        onSubmit: function () {
            this.$emit('search', {customer: this.customer, contractor: this.contractor, subcontractorName: this.subcontractorName})
        },
        onReset: function () {
            this.customer = null
            this.contractor = null
            this.subcontractorName = null
        }
    }
}
</script>
