<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
        <span
            v-if="isAdmin"
            class="title"
        >
          {{ $t('menu.admin') }} / {{ $t('menu.admin_companies') }}
        </span>
                <span
                    v-else
                    class="title"
                >
          {{ $t('companies.title') }}
        </span>
            </div>
            <div
                v-else
                @click="showList"
                class="header"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span
                    class="pointer"
                >
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>
        <div class="col-sm-12 nopads">
            <transition name="fade">
                <company-list
                    v-if="!editMode"
                    :results="results"
                    :is-admin="isAdmin"
                    @add="addCompany"
                    @edit="editCompany"
                />
            </transition>
            <transition name="fade">
                <company-editor
                    v-if="editMode"
                    :company="company"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import CompanyList from './CompanyList'
import CompanyEditor from './CompanyEditor'

export default {
    name: 'Companies',
    components: {CompanyList, CompanyEditor},
    mixins: [restApi, workManagementHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            company: null,
            editMode: false,
            loading: true
        }
    },
    mounted: function () {
        this.fetchCompanies()
    },
    methods: {

        fetchCompanies: function () {
            this.loading = true
            this.restFetch(this.companyUrl, this.handleResponse)
        },

        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },

        editCompany: function (company) {
            this.company = company
            if (!this.company.location) {
                this.company.location = {x: null, y: null}
            }
            this.editMode = true
        },

        addCompany: function () {
            this.company = this.newCompany()
            this.editMode = true
        },

        showList: function () {
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.fetchCompanies()
            this.showList()
        }

    }
}
</script>
