<template>
    <div class="content-container">
        <div v-if="!newWorkAssignmentFor">
            <b-navbar
                variant="faded"
                class="header-background"
                type="dark"
            >
                <div class="header">
                    <span class="title">{{ $t('areas.equipment') }}</span>
                </div>
            </b-navbar>
            <div class="col-sm-12 nopads">
                <!-- Equipment filter -->
                <transition name="fade">
                    <keep-alive>
                        <area-equipment-filter
                            v-if="!editMode"
                            :user="user"
                            :is-admin="isAdmin"
                            @search="fetchEquipment"
                        />
                    </keep-alive>
                </transition>
            </div>
            <!-- Equipment list -->
            <div class="col-sm-12 nopads">
                <span
                    v-if="!editMode"
                    class="span-title result-info"
                >
                    {{ $t('common.results') }}: {{ results ? results.length : 0 }}
                </span>
                <transition name="fade">
                    <equipment-list
                        v-if="!editMode"
                        :is-admin="isAdmin"
                        :results="results"
                        @add="addEquipment"
                        @edit="editEquipment"
                        @newWorkAssignment="newWorkAssignment"
                    />
                </transition>
            </div>
            <!-- Editor -->
            <transition
                v-if="editMode"
                name="fade"
            >
                <equipment-editor
                    :equipment="equipment"
                    :is-admin="isAdmin"
                    @close="showEquipmentList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <transition
            v-else
            name="fade"
        >
            <work-assignment-editor
                :is-admin="isAdmin"
                :is-observer="isObserver"
                :area-equipment="newWorkAssignmentFor"
                @close="closeAssignmentEditor"
                @closeAndUpdate="closeAssignmentEditor"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import EquipmentList from './AreaEquipmentList'
import {areaHelper} from '../../mixins/AreaMixin'
import EquipmentEditor from './AreaEquipmentEditor'
import AreaEquipmentFilter from './AreaEquipmentFilter'
import WorkAssignmentEditor from "../../workassignment/WorkAssignmentEditor";

export default {
    name: 'AreaEquipment',
    components: {WorkAssignmentEditor, EquipmentEditor, AreaEquipmentFilter, EquipmentList},
    mixins: [restApi, areaHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            loading: true,
            error: false,
            editMode: false,
            equipment: null,
            results: [],
            lastSearchedParams: null,
            newWorkAssignmentFor: null
        }
    },
    mounted: function () {
        this.loading = false
    },
    methods: {
        fetchEquipment: function (params) {
            this.resetPage()
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.areaEquipmentUrl, this.lastSearchedParams, this.handleResponse, this.handleError)
        },

        resetPage: function () {
            this.error = false
            this.results = []
        },

        handleError: function (error) {
            this.loading = false
            if (error.response.status && error.response.status !== 200) {
                EventBus.$emit('show-alert', this.$t('equipment.err_general'))
            }
        },

        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
        },

        addEquipment: function () {
            this.equipment = this.newEquipment()
            this.editMode = true
        },

        editEquipment: function (item) {
            this.equipment = item
            this.editMode = true
        },

        showEquipmentList: function () {
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.showEquipmentList()
            this.fetchEquipment(this.lastSearchedParams)
        },

        newWorkAssignment(equipment) {
            this.newWorkAssignmentFor = equipment;
        },

        closeAssignmentEditor() {
            this.newWorkAssignmentFor = null;
        }
    }
}
</script>

<style scoped>
.result-info {
    margin-left: 1em;
    margin-bottom: 10px;
}
</style>
