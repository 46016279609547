<template>
    <div v-if="methodReports.length > 0" class="nopads">
        <div>
            <b-row>
                <b-col class="nopads report-header" cols="10">
                    <span class="section-title">{{ $t('contract_reports.paving_methods') }}</span>
                    <table>
                        <tr v-for="(item, key) in methodReports" v-bind:key="key" class="nopads">
                            <td class="nopads pr-3 report-info" cols="auto">
                                <div>{{ item.name }}</div>
                            </td>
                            <td class="nopads pr-3 report-info text-right" cols="auto">
                                <div>{{ item.totalAmount.toFixed(1) }} {{ item.unit }}</div>
                            </td>
                            <td class="no-pads pr-3 report-info text-right" cols="auto">
                                <div>{{ item.totalDistance ? Math.round(item.totalDistance) : 0 }} m</div>
                            </td>
                            <td class="no-pads pr-3 report-info text-right" cols="auto">
                                <div>{{ item.totalArea ? Math.round(item.totalArea) : 0 }} m2</div>
                            </td>
                            <td class="no-pads pr-3 report-info text-right" cols="auto">
                                <div>{{
                                        item.totalArea ? ((item.totalAmount * 1000) / item.totalArea).toFixed(2) : '-'
                                    }} kg/m2
                                </div>
                            </td>
                        </tr>
                    </table>
                </b-col>
                <b-col class="nopads">
                    <font-awesome-icon
                        icon="arrow-down"
                        class="pointer right no-focus arrow-icon dont-print"
                        v-bind:class="{rotated : methodsVisible}"
                        v-b-toggle.methodDetails
                        @click.stop="methodsVisible = !methodsVisible"/>
                </b-col>
            </b-row>
            <b-row class="nopads mt-2" v-if="methodsVisible">
                <b-col md="6" class="nopads chart-container text-center pl-4 pr-4">
                    <span class="item-text">t</span>
                    <div class="col-12 nopads pl-4 pr-4">
                        <bar-chart
                            class="routa-bars dont-print mt-0"
                            ref="barMethods"
                            :labels="chartLabels"
                            :data="chartData"
                            :colors="chartColors"
                        />
                    </div>
                    <img
                        class="print-only routa-charts-print"
                        id="barMethodsImage"
                        alt="Used paving methods image"
                    />
                </b-col>
                <b-col md="6" class="nopads chart-container text-center pl-4 pr-4">
                    <span class="item-text">m2</span>
                    <div class="col-12 nopads pl-4 pr-4">
                        <bar-chart
                            class="routa-bars dont-print mt-0"
                            ref="barArea"
                            :labels="chartLabels"
                            :data="chartAreaData"
                            :colors="chartColors"
                        />
                    </div>
                    <img
                        class="print-only routa-charts-print"
                        id="barAreaImage"
                        alt="Used paving methods image"
                    />
                </b-col>
            </b-row>
            <b-collapse
                id="methodDetails"
                class="mb-2 item-details show-print"
            >
                <div v-for="report in methodReports" :key="report.name" class="pb-2">
                    <div style="font-size: 1.4em">{{ report.name }}</div>
                    <div style="font-weight: bold" class="pb-2"> {{ report.totalAmount.toFixed(2) }} {{
                            report.unit
                        }}
                    </div>
                    <line-unload-record-table
                        v-if="report.lineUnloadRecords.length > 0"
                        :records="report.lineUnloadRecords"
                    />
                    <point-unload-record-table
                        v-if="report.pointUnloadRecords.length > 0"
                        :records="report.pointUnloadRecords"
                    />
                </div>
            </b-collapse>
        </div>
        <hr class="report-divider nopads"/>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import LineUnloadRecordTable from "@/components/contractreport/LineUnloadRecordTable";
import PointUnloadRecordTable from "@/components/contractreport/PointUnloadRecordTable";
import BarChart from "@/components/chart/BarChart";

export default {
    name: "contract-report-paving-methods",
    components: {BarChart, PointUnloadRecordTable, LineUnloadRecordTable},
    mixins: [timeUtils],
    props: {
        deliveryNotes: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            methodsVisible: false,
            methodReports: [],
            visible: false,
        }
    },
    computed: {
        chartData() {
            let data = []
            this.methodReports.forEach(report => data.push({value: report.totalAmount.toFixed(2)}))
            return data
        },
        chartAreaData() {
            let data = []
            this.methodReports.forEach(report => data.push({value: report.totalArea.toFixed(2)}))
            return data
        },
        chartLabels() {
            let labels = []
            this.methodReports.forEach(report => labels.push(report.name))
            return labels
        },
        chartColors() {
            let colors = []
            this.methodReports.forEach(() => colors.push('#faa633'))
            return colors
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        toggleVisibility() {
            this.visible = !this.visible
        },
        initChartData() {

        },
        initData() {
            if (this.deliveryNotes.length > 0) {
                // Lanes
                this.deliveryNotes.forEach(item => {
                    if (item.line_unload_records.length > 0) {
                        item.line_unload_records.forEach(line => {
                            let methodReport = this.methodReports.find(report => report.name === line.unload_method)
                            if (methodReport) {
                                methodReport.totalAmount += line.amount ? line.amount : 0
                                methodReport.totalDistance += line.travel_distance ? line.travel_distance : 0
                                methodReport.totalArea += line.travel_distance && line.width ? line.travel_distance * line.width : 0
                                methodReport.lineUnloadRecords.push(line)
                            } else {
                                this.methodReports.push({
                                    name: line.unload_method,
                                    totalAmount: line.amount ? line.amount : 0,
                                    totalDistance: line.travel_distance ? line.travel_distance : 0,
                                    totalArea: line.travel_distance && line.width ? line.travel_distance * line.width : 0,
                                    unit: line.unit,
                                    lineUnloadRecords: [line],
                                    pointUnloadRecords: []
                                })
                            }
                        })
                    }
                })
                // Small areas
                this.deliveryNotes.forEach(item => {
                    if (item.point_unload_records.length > 0) {
                        item.point_unload_records.forEach(point => {
                            let methodReport = this.methodReports.find(report => report.name === this.$t('contract_reports.small_areas'))
                            if (methodReport) {
                                methodReport.totalAmount += point.amount ? point.amount : 0
                                methodReport.totalArea += point.area_size ? point.area_size : 0
                                methodReport.pointUnloadRecords.push(point)
                            } else {
                                this.methodReports.push({
                                    name: this.$t('contract_reports.small_areas'),
                                    totalAmount: point.amount ? point.amount : 0,
                                    unit: point.unit,
                                    totalArea: point.area_size ? point.area_size : 0,
                                    lineUnloadRecords: [],
                                    pointUnloadRecords: [point]
                                })
                            }
                        })
                    }
                })
            }
        },
        generateImages: function () {
            try {
                document.getElementById("barMethodsImage").src = this.$refs.barMethods.getChartAsImage();
                document.getElementById("barAreaImage").src = this.$refs.barArea.getChartAsImage();
                // eslint-disable-next-line no-empty
            } catch (e) {
            }
        }
    }
}
</script>

