import proj4 from "proj4";

export const coordinateSystems = {
    WGS84: 1,
    TM35_FIN: 2
}

export const downloadOptions = {
    GEOJSON: 'downloadGeoJson',
    SHAPE: 'downloadShape'
}

export const coordinateConverter = {
    methods: {
        initConverter: function () {
            proj4.defs(
                [
                    [
                        'EPSG:3047',
                        '+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs'
                    ],
                    [
                        'EPSG:3067', // TM35-FIN
                        '+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs'
                    ],
                    [
                        'WGS84',
                        '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'
                    ]
                ]
            )
        },
        wgs84ToTm35Fin: function (lat, lon) {
            this.initConverter()
            return proj4('EPSG:3067', 'WGS84').inverse([parseFloat(lon), parseFloat(lat)])
        },
        tm35FinTowgs84: function (lat, lon) {
            this.initConverter()
            return proj4('EPSG:3067', 'WGS84').forward([parseFloat(lon), parseFloat(lat)])
        }
    }
}
