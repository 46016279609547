<template>
    <div>
        <base-modal class="road-search-wizard">
            <div class="editor-form form-data">
                <button
                    class="button--nostyle"
                    aria-label="close modal"
                    @click="$emit('close')"
                    style="margin-left: auto; display: block; font-size: .9em"
                >
                    {{ $t('common.close') }} <i class="fa fa-times" />
                </button>
                <b-tabs  v-model="tabIndex" fill>
                    <b-tab :title="$t('trip_list.search_with_road_name')" active :title-link-class="linkTabClass(0)">
                        <template #title>
                            <b-row class="nopads text-center">
                                <div class="col-2 nopads" >
                                </div>
                                <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 0}">
                                    {{ $t('trip_list.search_with_road_name') }}
                                </div>
                            </b-row>
                        </template>

                        <span class="span-title"> {{ $t('map.road_name') }} </span>
                        <b-form-input size="sm" class="pb-2" v-model="roadName" id="road-name" debounce="400" :state="!!roadName" @keydown.enter.prevent="searchRoad"/>
                        <span class="span-title"> {{ $t('map.city') }} </span>
                        <b-form-input size="sm" class="pb-2" v-model="city" id="city-name" debounce="400" :state="!!city" @keydown.enter.prevent="searchRoad"/>
                        <hr class="ml-2 mr-2 mb-0"/>
                        <span class="span-title pt-0"> {{ $t('map.road_number') }} </span>
                        <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="roadNumber" id="road-number" debounce="400" @keydown.enter.prevent="searchRoad"/>


                    </b-tab>
                    <b-tab :title="$t('map.street_address')" :title-link-class="linkTabClass(1)">
                        <template #title>
                            <b-row class="nopads text-center">
                                <div class="col-2 nopads" >
                                </div>
                                <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 1}">
                                    {{ $t('map.street_address') }}
                                </div>
                            </b-row>
                        </template>

                        <span class="span-title pt-0"> {{ $t('map.road_number') }} </span>
                        <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="roadNumber" id="road-number" debounce="400" :state="!!roadNumber" @keydown.enter.prevent="searchRoad"/>

                        <b-row class="nopads">
                            <b-col class="nopads mr-2">
                                <span class="span-title"> {{ $t('map.road_section_start') }} </span>
                            </b-col>
                            <b-col class="nopads ml-2">
                                <span class="span-title"> {{ $t('map.distance_start') }} </span>
                            </b-col>
                        </b-row>
                        <b-row class="nopads">
                            <b-col class="nopads mr-2">
                                <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="roadSectionFrom" :state="!!roadSectionFrom" id="road-section-from" debounce="400" @keydown.enter.prevent="searchRoad"/>
                            </b-col>
                            <b-col class="nopads ml-2">
                                <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="distanceFrom" id="distance-from" debounce="400" @keydown.enter.prevent="searchRoad"/>
                            </b-col>
                        </b-row>
                        <b-row class="nopads">
                            <b-col class="nopads mr-2">
                                <span class="span-title"> {{ $t('map.road_section_end') }} </span>
                            </b-col>
                            <b-col class="nopads ml-2">
                                <span class="span-title"> {{ $t('map.distance_end') }} </span>
                            </b-col>
                        </b-row>
                        <b-row class="nopads">
                            <b-col class="nopads mr-2">
                                <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="roadSectionTo" id="road-section-to" debounce="400" @keydown.enter.prevent="searchRoad"/>
                            </b-col>
                            <b-col class="nopads ml-2">
                                <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="distanceTo" id="distance-to" debounce="400" @keydown.enter.prevent="searchRoad"/>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
                <div class="button-container">
                    <b-button
                        variant="primary"
                        class="result-button"
                        size="sm"
                        @click.stop="searchRoad"
                        :disabled="!isSearchable && !loading"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                </div>
                <ul v-if="suggestion && suggestion.features" class="suggestions-list">
                    <li @click="drawOnMap(suggestion)">
                        {{ getSuggestionLabel(suggestion) }}
                    </li>
                </ul>
                <span v-else-if="searchDone">{{ $t('area_report.no_results')}}</span>
            </div>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
                />
        </base-modal>
    </div>
</template>

<script>
import BaseModal from '../BaseModal'
import {restApi} from "@/components/mixins/RestApiMixin";
import {EventBus} from "@/event-bus";

export default {
    name: 'road-search-wizard',
    mixins: [restApi],
    components: { BaseModal },
    props: {
        coordinates: {
            type: Object,
            default() {
                return null
            },
        },
        map: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            loading: false,
            roadName: '',
            roadNumber: null,
            roadSectionFrom: null,
            roadSectionTo: null,
            distanceFrom: null,
            distanceTo: null,
            city: '',
            suggestion: null,
            searchDone: false,
            tabIndex: 0,
        }
    },

    watch: {
        tabIndex() {
            this.resetForm()
        }
    },

    computed: {
        isSearchable() {
            if (this.tabIndex === 0) {
                return (!!this.roadName && !!this.city );
            } else {
                return (!!this.roadNumber  && !!this.roadSectionFrom)
            }
        }
    },

    methods: {
        searchRoad() {
            this.suggestion = null
            if (this.tabIndex === 1) {
                // Search for road section
                this.loading = true
                this.restFetchParams(this.roadSectionUrl, {
                    road: this.roadNumber,
                    fromSection: this.roadSectionFrom,
                    fromDistance: this.distanceFrom,
                    toSection: this.roadSectionTo,
                    toDistance: this.distanceTo
                }, this.handleResponse, this.onError)
            } else {
                // Search with name and city
                this.loading = true
                this.restFetchParams(this.roadGeometryUrl, {
                    roadName: this.roadName,
                    city: this.city,
                    roadNum: this.roadNumber,
                    secNum: this.roadSectionFrom,
                    geoJson: 1
                }, this.handleResponse, this.onError)
            }
        },
        handleResponse(response) {
            this.loading = false
            if (response && response.data) {
                this.suggestion = response.data
                this.searchDone = true
            }
        },
        onError() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        drawOnMap(geoJson) {
            if (geoJson) {
                this.$emit('roadSelected', JSON.stringify(geoJson))
            }
        },
        resetForm() {
            this.roadName = ''
            this.roadNumber = null
            this.roadSectionFrom = null
            this.roadSectionTo = null
            this.distanceFrom = null
            this.distanceTo = null
            this.city = ''
            this.searchDone = false
            this.suggestion = null
        },
        getSuggestionLabel(suggestion) {
            if (suggestion.features && suggestion.features.length > 0) {
                let result = ''
                if (suggestion.features[0].properties.roadNumber) {
                    result += suggestion.features[0].properties.roadNumber
                    if (this.roadSectionFrom) {
                        result += "/ " + this.roadSectionFrom
                    }
                    if (this.roadSectionTo) {
                        result += " - " + this.roadSectionTo
                    }
                }
                if (suggestion.features[0].properties.roadName) {
                    result = result.length > 0 ? result + " " : ""
                    result += suggestion.features[0].properties.roadName
                }
                if (suggestion.features[0].properties.city) {
                    result = result.length > 0 ? result + ", " : ""
                    result += suggestion.features[0].properties.city
                }
                return result
            }
            return this.$t('area_report.no_results')
        },

        linkTabClass(index) {
            if (this.tabIndex === index) {
                return ['tab-title', 'tab-title-active']
            } else {
                return ['tab-title']
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.road-search-wizard {
    z-index: 1000;
}
@import '../../styles/modal.css';
ul.suggestions-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0 0;
    max-height: 400px;
    overflow: auto;

    li {
        padding: 1em 0.5em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        transition: background-color 0.25s ease-out;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }
        &:only-child {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}
</style>
