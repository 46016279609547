<template>
    <div class="col-sm-12 light-bg mt-2">
        <!--Show graphs here according to given efficiency results -->
        <b-col
            v-for="item in efficiencies"
            v-bind:key="item.id"
            sm="6"
            class="nopads chart-container p-2">
            <span class="report-title mt-0">{{ getEfficiencyTitle(item) }}</span>
            <div class="col-sm-12 nopads">
                <bar-chart
                    class="routa-bars dont-print"
                    ref="barDistance"
                    @onBarClick="barClicked"
                    :show-x-axis-label="false"
                    :colors="getEfficiencyBarColor(item)"
                    :labels="getEfficiencyLabels(item)"
                    :data-ids="getContractIds(item)"
                    :data="getEfficiencyData(item)"/>
            </div>
        </b-col>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import BarChart from "../chart/BarChart";

export default {
    name: "EfficiencyCharts",
    components: {BarChart},
    props: {
        efficiencies: {
            type: Array,
            default: function () {
                return []
            }
        },
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data: function () {
        return {
            chartData: [],
            chartLabels: [],
            contractIds: [],
            loading: false,
            barColors: [],
            color: '#faa633'
        }
    },

    mounted: function () {
        this.updateData()
    },

    watch: {
        efficiencies: function () {
            this.updateData()
        },
        results: function () {
            this.updateData()
        }
    },

    methods: {
        updateData: function () {
            this.chartData = []
            this.chartLabels = []
            this.barColors = []
            if (this.efficiencies) {
                this.efficiencies.forEach(item => {
                    this.chartData['eff_' + item.id] = this.getResultsForEff(item.id)
                    this.chartLabels['eff_' + item.id] = this.getLabelsForEff(item.id)
                    this.barColors['eff_' + item.id] = this.getBarColorsForEff(item.id)
                    this.contractIds['eff_' + item.id] = this.getContractIdsForEff(item.id)
                })
            }
        },

        getResultsForEff: function (effId) {
            let results = []
            if (this.results) {
                this.results.forEach(result => {
                    let efficiencies = result.efficiencies
                    efficiencies.forEach(eff => {
                        if (eff.id === effId) {
                            results.push(eff.value)
                        }
                    })
                })
            }
            return results
        },

        getLabelsForEff: function (effId) {
            let results = []
            if (this.results) {
                this.results.forEach(result => {
                    let efficiencies = result.efficiencies
                    efficiencies.forEach(eff => {
                        if (eff.id === effId) {
                            results.push(result.contract_number + ' - ' + result.contract_name)
                        }
                    })
                })
            }
            return results
        },

        getBarColorsForEff: function (effId) {
            let results = []
            if (this.results) {
                this.results.forEach(result => {
                    let efficiencies = result.efficiencies
                    efficiencies.forEach(eff => {
                        if (eff.id === effId) {
                            results.push(this.color)
                        }
                    })
                })
            }
            return results
        },

        getContractIdsForEff: function (effId) {
            let results = []
            if (this.results) {
                this.results.forEach(result => {
                    let ids = result.efficiencies
                    ids.forEach(eff => {
                        if (eff.id === effId) {
                            results.push(result.contract_id)
                        }
                    })
                })
            }
            return results
        },

        getEfficiencyTitle: function (item) {
            return item.name + ' (' + item.unit + ')'
        },

        getEfficiencyData: function (item) {
            if (this.chartData['eff_' + item.id]) {
                return this.chartData['eff_' + item.id]
            }
            return []
        },

        getEfficiencyLabels: function (item) {
            if (this.chartLabels['eff_' + item.id]) {
                return this.chartLabels['eff_' + item.id]
            }
            return []
        },

        getContractIds: function (item) {
            if (this.contractIds['eff_' + item.id]) {
                return this.contractIds['eff_' + item.id]
            }
            return []
        },

        getEfficiencyBarColor: function (item) {
            if (this.barColors['eff_' + item.id]) {
                return this.barColors['eff_' + item.id]
            }
            return []
        },

        barClicked: function (dataId) {
            this.$emit("openContract", dataId)
        }

    }
}
</script>

<style scoped>

</style>
