<template>
    <div class="col-12 nopads">
        <transition name="fade">
            <post-editor
                v-if="selectedPost"
                style="pointer-events: all"
                :item="selectedPost"
                :selected-contract="selectedContract"
                @close="closePostWizard"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from '@/components/mixins/MapMixin'
import {restApi} from '@/components/mixins/RestApiMixin'
import PostEditor from "@/components/velho/PostEditor";
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import {condition} from "@/components/mixins/RoadSignMixin";

export default {
    name: 'PostMap',
    mixins: [mapHelper, restApi, geometryMixin],
    components: {PostEditor},
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContract: {
            type: Object,
            default: null
        },
        selectedConditions: {
            type: Array,
            default() {
                return []
            }
        },

    },
    data: function () {
        return {
            loading: 0,
            posts: [],
            bbox: null,
            visibilityZoomLevel: 15,
            selectedPost: null
        }
    },

    watch: {
        map() {
            this.$nextTick(function () {
                this.initView()
            })
        },
        boundingBox() {
            this.updateView()
        },
        selectedConditions() {
            this.clearDrawnItems()
            this.drawPosts()
        }

    },
    beforeDestroy() {
        this.hideItems()
    },
    mounted() {
        this.initView()
    },
    computed: {
        visiblePost() {
            return this.selectedConditions.length === 0 ? this.posts : this.posts.filter((value) => this.selectedConditions.includes(value.condition) || (this.selectedConditions.includes(condition.UNSET) && value.condition === undefined))
        }
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideItems()
            } else if (force || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.005
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchPosts()
            }
        },

        clearDrawnItems: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.EXTERNAL_POSTS)
            }
        },

        hideItems: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.EXTERNAL_POSTS)
            }
            this.posts = []
        },

        closeAndUpdate() {
            this.closePostWizard()
            this.fetchPosts()
        },

        fetchPosts: function () {
            this.loading++
            this.posts = []
            let params = {}
            if(this.bbox && this.bbox.length > 0) {
                params.bbox = this.bbox
                this.restFetchParams(this.extPostsUrl, params, this.handleSearch, () => {
                    this.loading--
                })
            }
        },

        handleSearch: function (response) {
            this.hideItems()
            if (response && response.data && response.data.length > 0) {
                this.posts = response.data
                this.drawPosts()
            }
            this.loading--
        },

        drawPosts: function () {
            this.visiblePost.forEach(function (post, index) {
                let icon = this.map.getMarkerStore().getPostIcon()
                this.map.showMapMarker(index, this.EXTERNAL_POSTS, post.position.y, post.position.x,
                    icon, false, 5, false, post.external_system_id)
            }, this)
        },

        editPost(id) {
            this.fetchPost(id)
        },

        fetchPost: function (id) {
            this.loading++
            this.selectedPost = null
            this.restFetch(this.extPostsUrl + '/' + id, this.handlePost)
        },

        handlePost: function (response) {
            if (response && response.data) {
                // Set Velho oid as contract id
                if (this.selectedContractId) {
                    response.data.contract = {
                        id: this.selectedContractId
                    }
                }
                this.selectedPost = response.data
            }
            this.loading--
        },

        closePostWizard() {
            this.selectedPost = null
        }

    }
}
</script>
