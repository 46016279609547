<template>
    <div class="nopads">
        <b-row class="nopads">
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.road_number') }}</span>
                <b-form-input
                    v-model="roadInfo.road_num"
                    type="number"
                    min="0"
                    size="sm"
                    :state="this.validateRoadNumber(roadInfo.road_num)"
                />
            </div>
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.road_section_number') }}</span>
                <b-form-input
                    v-model="roadInfo.road_sec"
                    type="number"
                    min="0"
                    size="sm"
                    :state="this.validateRoadSection(roadInfo.road_sec)"
                />
            </div>
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.distance') }}</span>
                <b-form-input
                    v-model="roadInfo.distance"
                    type="number"
                    min="0"
                    size="sm"
                    :state="this.validateRoadDistance(roadInfo.distance)"
                />
            </div>
        </b-row>
        <b-row class="nopads">
            <div v-if="!this.hideTracksSelector" class="col-sm-6 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_conversion.tracks') }}</span>
                <b-form-select size="sm" v-model="locationSpecification.tracks" v-bind:state="tracksRequired ? locationSpecification.tracks != null : null">
                    <template slot="first">
                        <option :value="null">
                            {{ $t('velho_equipment.select_equipment_track') }}
                        </option>
                    </template>
                    <option
                        v-for="option in tracksSelector"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
            </div>
            <div v-if="!this.hideSideSelector" class="col-sm-6 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('road_sign.side') }}</span>
                <b-form-select size="sm" v-model="locationSpecification.side" v-bind:state="sideRequired ? locationSpecification.side != null : null">
                    <template slot="first">
                        <option :value="null">
                            {{ $t('velho_equipment.select_equipment_side') }}
                        </option>
                    </template>
                    <option
                        v-for="option in sideSelector"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
            </div>
        </b-row>
    </div>
</template>

<script>
import {velhoHelper, side, tracks} from "@/components/mixins/VelhoMixin";
import {velhoValidationMixin} from "@/components/mixins/VelhoValidationMixin";

export default {
    name: 'velho-equipment-location-details-editor',
    mixins: [velhoHelper, velhoValidationMixin],
    components: {},
    props: {
        roadInfo: {
            type: Object,
            default: null
        },
        locationSpecification: {
            type: Object,
            default: null
        },
        item: {
            type: Object,
            default: null
        },
        hideSideSelector: {
            type: Boolean,
            default: false
        },
        hideTracksSelector: {
            type: Boolean,
            default: false
        },
        tracksRequired: {
            type: Boolean,
            default: true
        },
        sideRequired: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        sideSelector() {
            return  Object.values(side).map(value => {
                return {
                    text: this.getVelhoSideString(value),
                    value: value
                }
            });

        },
        tracksSelector() {
            return  Object.values(tracks).map(value => {
                return {
                    text: this.getVelhoTrackString(value),
                    value: value
                }
            });
        }
    },
    data() {
        return {}
    }
}
</script>