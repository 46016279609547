<template>
    <div class="col-sm-12 editor-form">
        <!-- Attachments-->
        <h4 class="form-header">{{ $t('contracts.attachments')}}</h4>
        <span class="tip-light">{{ $t('contracts.attachment_hint')}}</span>
        <div v-if="contract.attachments && contract.attachments.length" class="mt-2 mb-2">
            <span class="span-title">{{ $t('common.attachments') }}</span>
                <b-row class="nopads mb-1 pb-1 border-bottom" style="font-size: .9em"
                    v-for="item in contract.attachments"
                    :key="item.id">
                    <div class="col-10 pointer link pl-0 pt-1 ml-0" v-on:click="downloadAttachment(item)">
                        {{ item.file_original_name }}
                    </div>
                    <div class="col-2 text-right">
                        <b-button
                            variant="danger"
                            size="sm"
                            class="ml-3"
                            @click.stop="removeAttachment(item)"
                        >
                            <font-awesome-icon icon="trash"/>
                        </b-button>
                    </div>
                </b-row>
        </div>
        <!-- Attachment upload-->
        <div>
            <span class="span-title">{{ $t('work_assignment.add_attachment') }}</span>
            <div
                v-for="(path, counter) in attachments"
                :key="counter"
                class="col-sm-12 nopads"
            >
                <b-form-group
                    class="title"
                    label-for="counter"
                    v-if="counter === 0 || isAttachmentSet(attachments, counter-1)"
                >
                    <b-form-file
                        v-model="attachments[counter]"
                        :accept="getValidAttachmentTypes()"
                        :placeholder="$t('common.attachment_placeholder')"
                    />
                </b-form-group>
            </div>
        </div>

        <div class="col-sm-12 button-container pb-3 pt-4 mt-5">
            <b-button
                variant="secondary"
                class="form-button"
                @click.stop="reset"
            >
                {{ $t('common.cancel') }}
            </b-button>
            <b-button
                variant="success"
                class="form-button"
                :disabled="loading"
                @click.stop="updateAttachments"
            >
              <span v-if="contract.id > 0">
                {{ $t('common.save') }}
              </span>
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";

export default {
    name: 'ContractDocuments',
    mixins: [workManagementHelper, restApi, attachmentHelper],
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            contract: null,
            attachments: new Array(5),
            removedAttachments: []
        }
    },
    created() {
        this.contract = this.value
    },
    watch: {
      value: function() {
          this.contract = this.value
      },
    },
    methods: {
        updateContract: function () {
            if (this.contract.id > 0) {
                this.loading = true
                this.restFetchParams(this.contractUrl, {id: this.contract.id}, this.successUpdate, this.fail)
            }
        },

        successUpdate: function (response) {
            if (response.data && response.data.length > 0) {
                let contractData = response.data[0]
                if (!contractData.area_group) {
                    contractData.area_group = {id: null}
                }
                this.contract = contractData
            }
            this.loading = false
        },

        removeAttachment: function(item) {
            if(this.contract.attachments && this.contract.attachments.length > 0) {
                var index = this.contract.attachments.findIndex(attachment => attachment.id === item.id)
                if(index >= 0) {
                    this.removedAttachments.push(this.contract.attachments[index])
                    this.contract.attachments.splice(index, 1)
                }
            }
       },

        reset() {
            this.contract = this.value
            this.removedAttachments = []
        },

        updateAttachments() {
            // Remove if needed
            if (this.removedAttachments.length > 0) {
                this.loading = true
                this.restUpdate(this.contractAttachmentRemoveUrl + "/" + this.contract.id, {attachments: this.removedAttachments},
                    () => {
                        this.removedAttachments = []
                        this.uploadAttachment()
                    }
                    , this.fail
                    )
            } else {
                // Upload new
                this.uploadAttachment()
            }
        },

        uploadAttachment: function () {
            if (this.attachments.length > 0) {
                let attachments = this.getValidAttachments(this.attachments)
                if (attachments.length > 0) {
                    this.loading = true
                    let formData = new FormData()
                    attachments.forEach(file => {
                        if (file !== undefined && file !== null) {
                            formData.append('attachments[]', file)
                        }
                    })
                    this.restPostWithFile(this.contractAttachmentUrl + this.contract.id, {}, formData,
                        () => {
                            this.attachments = new Array(5)
                            this.updateContract()
                        }, this.fail
                    )
                }
            } else {
                this.loading = false
            }
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        downloadAttachment: function (item) {
            this.loading = true
            this.restFetch(this.contractAttachmentUrl + this.contract.id + '/' + item.id,
                response => {
                    this.saveFile(item.file_original_name, response.data)
                    this.loading = false
                }, () => {
                    this.loading = false
                })
        }
    }
}
</script>
