<template>
    <base-modal
        v-if="value !== null"
        @cancel="$emit('cancel')"
    >
        <material-storage-editor
            :storage="value"
            @closeAndUpdate="$emit('closeAndUpdate')"
            @cancel="$emit('cancel')"
            :user="user"
            :disabled="!!loading"
        />
        <div
            v-if="!!loading"
            class="spinner"
        />
    </base-modal>
</template>

<script>
import BaseModal from '../BaseModal'
import MaterialStorageEditor from './MaterialStorageEditor'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'MaterialStorageEditorModal',
    components: {MaterialStorageEditor, BaseModal},
    mixins: [restApi],
    props: {
        value: {
            type: Object
        },
        user: {
            type: Object
        }
    },
    data() {
        return {
            loading: 0
        }
    },
}
</script>

<style scoped>

</style>
