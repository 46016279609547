<template>
    <div>
        <user-alert-editor-modal
            v-model="alert"
            @alertResolved="alertResolved"
        />
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";
import UserAlert from '@/components/user/alert/UserAlert'
import UserAlertEditorModal from "@/components/user/alert/UserAlertEditorModal"
import {restApi} from "../../mixins/RestApiMixin"

export default {
    name: 'UserAlertMap',
    mixins: [mapHelper, restApi],
    components: {UserAlertEditorModal},
    props: {
        map: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            timer: null,
            alerts: [],
            alert: null,
            refetch: true
        }
    },
    watch: {
        map() {
          this.$nextTick(function() {
            this.fetchUserAlerts()
          })
        },
        alerts() {
            this.drawAlerts()
        }
    },
    mounted() {
        this.fetchUserAlerts()
    },
    beforeDestroy: function () {
        this.refetch = false
        if (this.timer) {
            clearTimeout(this.timer)
        }
    },
    methods: {
        editAlert: function (alertId) {
            this.alert = this.alerts.find(item => item.id === alertId)
        },
        drawAlerts() {
            this.map.removeMapMarkerByType(this.USER_ALERT)
            let icon = this.map.getMarkerStore().getAlertMarkerIcon()
            this.alerts.forEach(alert => {
                if (alert.location) {
                    this.map.showMapMarker(alert.id, this.USER_ALERT, alert.location.y, alert.location.x, icon)
                }
            })
        },

        alertResolved() {
            this.alert = null
            this.fetchUserAlerts()
        },

        async fetchUserAlerts() {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            try {
                this.alerts = await UserAlert.fetch(this, false)
            } catch (e) {
                // Fetch failed
            }
            if (this.refetch) {
                this.timer = setTimeout(this.fetchUserAlerts, 30000)
            }
        }
    }
}
</script>

<style scoped>

</style>
