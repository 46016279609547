<template>
    <div class="col-12 nopads">
        <transition name="fade">
            <template>
            <sewer-line-wizard
                v-if="selectedSewerLine != null"
                style="pointer-events: all"
                :storm-sewer-line="selectedSewerLine"
                @close="selectedSewerLine = null"
                @closeAndUpdate="fetchSewers"
            />
            <drain-wizard
                v-if="selectedDrain != null"
                style="pointer-events: all"
                :storm-drain="selectedDrain"
                @close="selectedDrain = null"
                @closeAndUpdate="fetchDrains"
            />
            </template>
        </transition>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '@/components/mixins/TimeUtils'
import {mapHelper} from '@/components/mixins/MapMixin'
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {restApi} from '../mixins/RestApiMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import SewerLineWizard from "@/components/stormsewers/SewerLineWizard";
import DrainWizard from "@/components/stormsewers/DrainWizard";

export default {
    name: 'StormSewersMap',
    components: {DrainWizard, SewerLineWizard},
    mixins: [timeUtils, mapHelper, restApi, geometryMixin, stormSewersMixin, roadSignHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        filters: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: 0,
            sewers: [],
            drains: [],
            bbox: null,
            visibilityZoomLevel: 13,
            selectedSewerLine: null,
            selectedDrain: null,
        }
    },

    watch: {
      map() {
        this.$nextTick(function () {
          this.initView()
        })
      },
      boundingBox() {
         this.updateView()
      },
      filters() {
         this.updateView(true)
      },
      selectedContractIds() {
        this.updateView(true)
      }
    },
    beforeDestroy() {
        this.hideAll()
    },
    mounted() {
        this.initView()
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideAll()
            } else if (force || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.005
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchSewers()
                this.fetchDrains()
            }
        },

        fetchSewers: function () {
            this.selectedSewerLine = null
            let params = {}
            if(this.bbox && this.bbox.length > 0 && this.filters.includes(this.SEWERS)) {
                this.loading++
                params.bbox = this.bbox
                this.restFetchParams(this.stormSewerMapUrl, params, this.handleSearch, () => {
                    this.loading--
                })
            } else {
                this.hideSewers()
            }
        },

        handleSearch: function (response) {
            this.hideSewers()
            if (response && response.data) {
                this.sewers = response.data
                this.drawSewers()
            }
            this.loading--
        },

        newDrain(coord) {
            this.selectedDrain = this.newStormDrain(coord)
        },

        drawSewers: function () {
            if(!this.filters.includes(this.SEWERS)) {
                return
            }
            this.sewers.forEach(function (sewer) {
                var points = sewer.geometry.line_string.points.map(point => {
                    return {x: point[0], y: point[1]}
                })
                this.map.drawPolyLine(sewer.id, this.SEWERS, points, sewer.status === 10 ? '#c50505' : '#1a6fb9')
            }, this)
        },

        hideSewers: function () {
          if (this.map) {
            this.map.removeMapItemsByType(this.SEWERS)
          }
        },

        fetchDrains: function () {
            this.selectedDrain = null
            let params = {}
            if(this.bbox && this.bbox.length > 0 && this.filters.includes(this.DRAINS)) {
                this.loading++
                params.bbox = this.bbox
                this.restFetchParams(this.stormDrainMapUrl, params, this.handleDrains, () => {
                    this.loading--
                })
            } else {
                this.hideDrains()
            }
        },

        handleDrains: function (response) {
            this.hideDrains()
            if (response && response.data) {
                this.drains = response.data
                this.drawDrains()
            }
            this.loading--
        },

        drawDrains: function () {
            if(!this.filters.includes(this.DRAINS)) {
                return
            }
            this.drains.forEach(function (drain) {
                let icon = drain.status === 10 ? this.map.getMarkerStore().getDrainDefectIcon() : this.map.getMarkerStore().getDrainIcon()
                this.map.showMapMarker(drain.id, this.DRAINS, drain.position.y, drain.position.x, icon)
            }, this)
        },

        hideDrains: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.DRAINS)
            }
        },

        hideAll() {
            this.hideSewers()
            this.hideDrains()
        },

        editSewerLine(id) {
            if (this.loading < 1) {
                this.loading++
                this.restFetch(this.stormSewerUrl + '/' + id, this.handleSewerLine)
            }
        },

        editDrain(id) {
            if (this.loading < 1) {
                this.loading++
                this.restFetch(this.stormDrainUrl + '/' + id, this.handleDrain)
            }
        },

        handleSewerLine(response) {
            this.loading--
            if (response && response.data) {
                this.selectedSewerLine = response.data
            }
        },

        handleDrain(response) {
            this.loading--
            if (response && response.data) {
                this.selectedDrain = response.data
            }
        },

        closeRoadSignWizard() {
            this.selectedRoadSign = null
        },

        getSewerLines() {
            return this.sewers
        },

        getSewerDrains() {
            return this.drains
        }
    }
}
</script>
