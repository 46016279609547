<template>
    <div class="col-sm-12 p-1">
        <h3 class="text-center">{{ isObserver ? $t('material_station.title') : stationUpdate.name }}</h3>
        <b-form class="col-sm-12 editor-form">
            <div v-if="isObserver">
                <span class="span-title p-1">{{ $t('material_station.name') }}</span>
                <b-form-group class="title" label-for="name">
                    <b-form-input
                        v-model="stationUpdate.name"
                        type="text"
                        size="sm"
                        :state="stationUpdate.name !== null && stationUpdate.name.trim() !== ''"
                    />
                </b-form-group>
                <coordinate-editor
                    v-model="stationUpdate.location"
                    size="sm"
                    :point-type="MATERIAL_STATION"
                    :title="$t('material_station.location')"
                />
            </div>
            <hr/>
            <material-list
                v-model="stationUpdate.station_materials"
                :isObserver="isObserver"
                :isWorker="isWorker"
            />
            <hr/>
            <span class="span-title p-1">{{ $t('material_station.scales') }}</span>
            <scale-list
                v-model="stationUpdate.station_scales"
                :editable="isObserver || isWorker"
            />
            <hr/>
            <div class="button-container pt-3 mb-0">
                <b-button
                    variant="danger"
                    :disabled="disabled"
                    class="form-button"
                    @click.stop="$emit('cancel')"
                >
                    <span>{{ $t('common.cancel') }}</span>
                </b-button>
                <b-button
                    variant="success"
                    :disabled="!canSubmit"
                    class="form-button"
                    v-if="isObserver || isWorker"
                    @click.stop="$emit('closeAndUpdate', stationUpdate)"
                >
                    <span>{{ $t('common.save') }}</span>
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import CoordinateEditor from "@/components/CoordinateEditor";
import {mapHelper} from '../../mixins/MapMixin';
import MaterialList from "@/components/materials/station/MaterialList";
import ScaleList from "@/components/materials/station/ScaleList";

export default {
    name: "MaterialStationEditor",
    components: {ScaleList, CoordinateEditor, MaterialList},
    mixins: [mapHelper],
    props: {
        station: {
            type: Object
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        isWorker: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            stationUpdate: JSON.parse(JSON.stringify(this.station))
        }
    },
    computed: {
        canSubmit() {
            return this.validateValues() && !this.disabled
        }
    },
    methods: {
        validateValues() {
            // Check if station name exists
            if (this.stationUpdate.name == null || this.stationUpdate.name.trim() === '') {
                return false
            }
            // Check for empty materials
            if (this.stationUpdate.station_materials) {
                let invalidItem = this.stationUpdate.station_materials.find(item => item.material === undefined || item.type_test_code === undefined || item.type_test_code.length < 1)
                if (invalidItem) {
                    return false
                }
            }
            // Check for empty scales
            if (this.stationUpdate.station_scales) {
                let invalidItem = this.stationUpdate.station_scales.find(item => !item.name || !item.scale_id)
                if (invalidItem) {
                    return false
                }
            }
            return true
        }
    }

}
</script>
