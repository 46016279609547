<template>
    <div class="nopads">
        <div v-if="materialReports && materialReports.length > 0" class="nopads">
            <b-row>
                <b-col class="nopads report-header" cols="10">
                    <span class="section-title" style="display: block">{{ $t('work_report.materials') }}</span>
                </b-col>
                <b-col class="nopads">
                    <font-awesome-icon
                        icon="arrow-down"
                        class="pointer right no-focus arrow-icon dont-print"
                        v-bind:class="{rotated : reportsVisible}"
                        v-b-toggle.materialDetails
                        @click.stop="reportsVisible = !reportsVisible"/>
                </b-col>
            </b-row>
            <b-collapse
                id="materialDetails"
                class="mt-2 mb-2 item-details show-print"
            >
                <manual-material-report-details
                    :results="materialReports"/>
            </b-collapse>
            <hr class="report-divider nopads"/>
        </div>
        <div v-if="team && team.length > 0" class="nopads">
            <b-row>
                <b-col class="nopads report-header" cols="10">
                    <span class="section-title" style="display: block">{{ $t('work_report.work_team') }}</span>
                    <b-row class="nopads">
                        <b-col class="nopads">
                            <div>{{ team ? team.length : 0 }} {{ $t('contract_reports.team_members') }}</div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="nopads">
                    <font-awesome-icon
                        icon="arrow-down"
                        class="pointer right no-focus arrow-icon dont-print"
                        v-bind:class="{rotated : teamsVisible}"
                        v-b-toggle.teamDetails
                        @click.stop="teamsVisible = !teamsVisible"/>
                </b-col>
            </b-row>
            <b-collapse
                id="teamDetails"
                class="mt-2 mb-2 item-details show-print"
            >
                <team-report-details
                    :results="team"/>
            </b-collapse>
            <hr class="report-divider nopads"/>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import ManualMaterialReportDetails from "@/components/contractreport/ManualMaterialReportDetails";
import TeamReportDetails from "@/components/contractreport/TeamReportDetails";

export default {
    name: "contract-work-reports",
    mixins: [timeUtils, restApi],
    components: {TeamReportDetails, ManualMaterialReportDetails},
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            results: [],
            materialReports: [],
            team: [],
            reportsVisible: false,
            teamsVisible: false
        }
    },
    mounted() {
        this.fetchWorkReports()
    },
    methods: {
        fetchWorkReports: function () {
            this.results = []
            this.materialReports = []
            this.team = []
            if (this.searchParams) {
                let mappedParams = {
                    contract: this.searchParams.contract,
                    startTime: this.searchParams.dateFrom ? this.getDateIsoString(new Date(this.searchParams.dateFrom)) : null,
                    endTime: this.searchParams.dateTo ? this.getDateIsoString(new Date(this.searchParams.dateTo)) : null,
                }
                this.$emit('loading', true)
                this.restFetchParams(this.workReportUrl, mappedParams, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response && response.data) {
                this.results = response.data
                this.extractTeams();
                this.extractManualMaterials()
            }
            this.$emit('loading', false)
        },
        extractManualMaterials() {
            this.results.forEach(report => {
                if (report.material_reports) {
                    report.material_reports.forEach(item => this.materialReports.push(item))
                }
            })
        },
        extractTeams() {
            this.results.forEach(report => {
                if (report.persons) {
                    report.persons.forEach(person => {
                        if (!this.personInTeam(person)) {
                            this.team.push(person)
                        }
                    })
                }
            })
        },
        personInTeam(person) {
            return this.team.find(item => item.id === person.id) !== undefined
        }
    }
}
</script>
