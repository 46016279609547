import { getDistance } from 'geolib';

export const measureDistanceMixin = {
    methods: {
        createLineObservation(coordinates) {
            this.showObservationWizardOnCoord = coordinates.map(item => { return {lat: item.lat, lng: item.lon} })
        },

        initMeasureDistance(coord) {
            if (!this.measureDistanceEnabled) {
                this.measureDistanceEnabled = true
                this.addMeasureDistance(coord)
                this.$bvToast.toast(`${this.measureDistanceTargetItem ? this.$t('map.draw_line_info') : this.$t('map.measurement_info')}`, {
                    title: this.measureDistanceTargetItem ? this.$t('map.draw_line') : this.$t('map.measure_distance'),
                    toaster: 'b-toaster-bottom-center',
                    id: 'map-measurement-toast',
                    autoHideDelay: 10000,
                    solid: true
                });
            }
        },

        initMeasureRoad() {
            if (!this.measureRoadEnabled) {
                this.measureRoadEnabled = true
                this.$bvToast.toast(this.$t('map.measure_roads_info'), {
                    title: this.$t('map.measure_road'),
                    toaster: 'b-toaster-bottom-center',
                    id: 'road-measurement-toast',
                    autoHideDelay: 5000,
                    solid: true
                });
            }
        },

        measureDistanceTargetItemCallback(coords) {
            let dataToBeSaved = {
                ...this.measureDistanceTargetItem,
                geometry: {
                    line_string: {
                        points: coords.map((coord) => {
                            return [coord.position.x, coord.position.y]
                        })
                    }
                },
                bearing: 0
            }
            this.loading = true
            this.restAdd(this.observationUrl, dataToBeSaved, this.success, this.fail)
        },

        addMeasureDistance(coord) {
            this.measureDistances.push({
                id: this.measureDistances.length,
                position: {
                    x: coord.lng,
                    y: coord.lat
                }
            })
        },

        updateMeasureDistance(data) {
            this.measureDistances = this.measureDistances.map((measureDistance) => {
                return data.id == measureDistance.id ? data : measureDistance
            })
        },

        removeMeasureDistances() {
            this.measureDistances = []
            this.measureDistanceEnabled = false
            this.$refs.measureDistances.hideDistances()
            this.$bvToast.hide('map-measurement-toast')
        },

        removeMeasureRoads() {
            this.measureRoadEnabled = false
            this.$refs.measureRoad.close()
            this.$bvToast.hide('road-measurement-toast')
        },

        getPointDistance(pointA, pointB) {
            return getDistance({latitude: pointA.y,longitude: pointA.x}, {latitude: pointB.y,longitude: pointB.x})
        },

        calculateLineStringLength: function(lineString) {
            if (lineString.length < 2) {
                return 0;
            }
            let result = 0;
            for (let i = 1; i < lineString.length; i++) {
                result += getDistance({latitude: lineString[i-1][1],longitude: lineString[i-1][0]},
                    {latitude: lineString[i][1],longitude: lineString[i][0]});
            }
            return result;
        },

        calculateGeoJsonDataLength: function(geometries) {
            if (geometries) {
                let result = 0
                geometries.forEach(item => {
                    if (item.geometry && item.geometry.features) {
                        item.geometry.features.forEach(feat => {
                            if(feat.geometry.type === 'LineString') {
                                result += this.calculateLineStringLength(feat.geometry.coordinates)
                            }
                        })
                    }
                })
                // round the result and convert it into kilometers
                const resultKm = result ? result / 1000  : 0
                return resultKm.toFixed(1)
            }
            return 0;
        }
    }
}
