<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <div
                v-if="results"
                id="f_work_time"
                class="col-sm-12 list-content result-table">
                <b-table
                    hover
                    show-empty
                    small
                    style="cursor: pointer"
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :bordered="true"
                    @row-clicked="openWorkRecord"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('forest_work_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(start_time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                    <template v-slot:cell(end_time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
        <!-- Work time records for a day - Pop up -->
        <transition v-if="showDetails" name="fade">
            <work-time-list
                :is-observer="isObserver"
                :show-approval="false"
                :results="recordResults"
                :name="detailsName"
                :time-range="detailsRange"
                :user-id="detailsUserId"
                @closeRecords="closeEditor"
                @edit="editWorkRecord"
                @add="addWorkRecord"
            />
        </transition>
        <!-- Editor -->
        <transition name="fade">
            <work-time-editor
                v-if="editMode"
                :work-time-record="workTimeRecord"
                @close="closeEditor"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <transition name="fade">
            <work-time-add
                v-if="addMode"
                :is-admin="isAdmin"
                :user-id="detailsUserId"
                :time-range="detailsRange"
                :name="detailsName"
                @close="closeEditor"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {workShift} from "../mixins/WorkShift";
import WorkTimeEditor from "../worktime/WorkTimeEditor";
import WorkTimeList from "../worktime/WorkTimeList";
import WorkTimeAdd from "../worktime/WorkTimeRecordAdd";


export default {
    name: 'ForestWorkTimeList',
    components: {WorkTimeAdd, WorkTimeEditor, WorkTimeList},
    mixins: [timeUtils, workShift, restApi],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            sortBy: 'time',
            sortDesc: true,
            fields: [
                {key: 'person', label: this.$t('forest_work_report.person'), sortable: true},
                {key: 'company', label: this.$t('forest_work_report.company'), sortable: true},
                {key: 'contract', label: this.$t('forest_work_report.contract'), sortable: true},
                {key: 'vehicle', label: this.$t('forest_work_report.vehicle'), sortable: true},
                {key: 'task_type', label: this.$t('forest_work_report.order'), sortable: true},
                {key: 'work_mode', label: this.$t('forest_work_report.work_mode'), sortable: true},
                {key: 'start_time', label: this.$t('forest_work_report.start_time'), sortable: true},
                {key: 'end_time', label: this.$t('forest_work_report.end_time'), sortable: true},
                {key: 'total_time', label: this.$t('forest_work_report.total_time'), sortable: true},
                {key: 'shift', label: this.$t('work_shift.shift'), sortable: true},
            ],
            showDetails: false,
            items: [],
            recordResults: [],
            detailsUserId: '',
            workTimeRecord: null,
            detailsName: '',
            detailsRange: '',
            loading: false,
            editMode: false,
            addMode: false
        }
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        initTableItems: function () {
            this.items = []
            if (this.results) {
                this.results.forEach(function (item) {
                    let line = item.work_record
                    let vehicleInfo = (line.vehicle) ? line.vehicle.make + ' ' + line.vehicle.license_plate : ""
                    this.items.push(
                        {
                            person: line.user.first_name + " " + line.user.last_name,
                            company: line.user.company.name,
                            vehicle: vehicleInfo,
                            start_time: line.approved_start_time,
                            end_time: line.approved_end_time,
                            work_mode: line.mode.name,
                            shift: this.getWorkShiftName(line.shift),
                            contract: line.order.contract_task_type.contract.name,
                            task_type: line.order.contract_task_type.task_type.name,
                            total_time: this.getWorkTime(item.work_time),
                        }
                    )
                }, this)
            }
        },

        openWorkRecord: function (item, index) {
            if (this.results && index >= 0 && index < this.results.length) {
                let record = this.results[index].work_record
                let params = {
                    user: record.user.id,
                    from: this.getStartOfTheDay(record.start_time),
                    to: record.end_time ? this.setTime(record.end_time) : this.getEndOfTheDay(record.start_time)
                }
                this.detailsUserId = record.user.id
                this.detailsName = record.user.first_name + ' ' + record.user.last_name
                this.detailsRange = this.toLocalTime(record.start_time) + ' - '
                if (record.end_time) {
                    this.detailsRange += this.toLocalTime(record.end_time)
                }
                this.searchRecordsWithParams(params)
            }
        },
        searchRecordsWithParams: function (params) {
            if (this.showDetails) {
                // Hide view to update records
                this.showDetails = false
            }
            this.recordResults = []
            this.loading = true
            this.restFetchParams(this.workTimeUrl, params, this.handleRecordsResponse)
        },
        handleRecordsResponse: function (response) {
            this.recordResults = response.data
            this.loading = false
            this.showDetails = true
        },
        closeEditor: function () {
            this.showDetails = false
            this.editMode = false
            this.addMode = false
        },
        closeAndUpdate: function () {
            this.closeEditor()
            this.$emit('onReportDataUpdated');
        },
        editWorkRecord: function (record) {
            this.workTimeRecord = record
            this.editMode = true
        },
        addWorkRecord: function () {
            this.addMode = true
        }
    }
}
</script>
