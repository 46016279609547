<template>
    <div class="col-sm-12 pl-3 pr-3">
        <div class="col-sm-12" :class="{'list-content' : !hideBorders}">
            <div class="col-12 pt-2">
                <b-row class="nopads pt-1" v-for="(item, index) in materialSummaries" v-bind:key="index" >
                    <b-col class="nopads pl-1 item-detail-title">
                        {{ item.material.name }}
                    </b-col>
                    <b-col class="nopads pl-1 item-detail-text">
                        {{ item.station }}
                    </b-col>
                    <b-col class="nopads pl-1 item-detail-text">
                        {{ item.amount ? item.amount.toFixed(2) : "-" }} {{ item.unit }}
                    </b-col>
                </b-row>
            </div>
            <b-table
                v-if="tableItems"
                size="sm"
                class="mt-3"
                show-empty
                :small="true"
                :responsive="true"
                :fields="fields"
                :items="tableItems"
                style="cursor: pointer"
                @row-clicked="emitEdit"
            >
                <template slot="empty">
                    <div class="text-center my-2">
                        {{ $t('common.no_results') }}
                    </div>
                </template>
                <template v-slot:cell(date)="row">
                    {{ toLocalTime(row.value) }}
                </template>
                <template v-slot:cell(work_distance)="row">
                    {{ row.value && row.value > 0 ? row.value.toFixed(2) : '' }}
                </template>
                <template v-slot:cell(resettle_distance)="row">
                    {{ row.value && row.value > 0 ? row.value.toFixed(2) : '' }}
                </template>
                <template v-slot:cell(status)="row">
                  <span class="nopads"
                        v-bind:class="{
                          transport: inDelivery(row.value),
                          unloading: unloading(row.value)
                        }">
                    {{ getStatusString(row.value) }}
                  </span>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {deliveryNoteHelper} from "@/components/mixins/DeliveryNoteMixin";
import {deliveryStatus} from "@/components/mixins/DeliveryNoteMixin";

export default {
    name: "DeliveryNoteList",
    mixins: [timeUtils, deliveryNoteHelper],
    props: {
        notes: {
            type: Array,
            default() {
                return []
            }
        },
        station: {
            type: Number
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        hideBorders: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fields: [
                {key: 'date', label: this.$t('delivery_note.date'), sortable: true},
                {key: 'contract', label: this.$t('delivery_note.contract'), sortable: true},
                {key: 'order', label: this.$t('delivery_note.order'), sortable: true},
                {key: 'vehicle', label: this.$t('delivery_note.vehicle'), sortable: true},
                {key: 'material', label: this.$t('delivery_note.material'), sortable: true},
                {key: 'amount', label: this.$t('delivery_note.amount'), sortable: true},
                {key: 'station', label: this.$t('delivery_note.station'), sortable: true},
                {key: 'status', label: this.$t('delivery_note.status'), sortable: true},
                {key: 'work_distance', label: this.$t('daily_summary.work_distance'), sortable: true},
                {key: 'resettle_distance', label: this.$t('daily_summary.resettle_distance'), sortable: true}
            ],
            tableItems: [],
            materialSummaries: []
        }
    },
    watch: {
        notes: function () {
            this.initTableItems()
            this.initSummaries()
        }
    },
    mounted: function () {
        this.initTableItems()
        this.initSummaries()
    },
    methods: {
        inDelivery(status) {
            return status === deliveryStatus.IN_DELIVERY
        },
        unloading(status) {
            return status === deliveryStatus.UNLOADING
        },
        getMaterialId(item) {
            return item.station_material ?
                'sta' + item.station_material.material.id :
                item.storage_material ? 'sto' + item.storage_material.id :
                    item.manually_added_material ? 'mm' + item.manually_added_material.id : undefined
        },
        getMaterialName(item) {
            return item.station_material ?
                item.station_material.material.name + " (" + (item.station_material.type_test_code) + ")" :
                item.storage_material ? item.storage_material.name :
                item.manually_added_material ? item.manually_added_material.material : ''
        },
        getStationName(item) {
            return item.station_material ?
                item.station_material.station.name :
                item.storage_material ? item.storage_material.storage.name : ''
        },
        getStationId(item) {
            return item.station_material ?
                item.station_material.station.id :
                item.storage_material ? item.storage_material.storage.id : ''
        },
        initTableItems: function () {
            this.tableItems = []
            this.notes.forEach(item => {
                this.tableItems.push(
                    {
                        date: item.created,
                        contract: item.order.contract.name,
                        order: item.order.task_type.name,
                        vehicle: item.vehicle.license_plate,
                        material: this.getMaterialName(item),
                        amount: item.amount + " " + item.unit,
                        station: this.getStationName(item),
                        status: item.status,
                        stationId: this.getStationId(item),
                        id: item.id,
                        work_distance: this.getWorkDistance(item, item.amount, item.unit),
                        resettle_distance: this.getResettleDistance(item, item.amount, item.unit)
                    }
                )
            })
        },
        getWorkDistance: function (note, amount, unit) {
            let result = 0;
            if (note.trips && amount && unit) {
                note.trips.forEach(trip => {
                    if (trip.mode === 3 && trip.delivery_amount === amount && trip.delivery_unit === unit) {
                        result += trip.length
                    }
                })
            }
            return result;
        },
        getResettleDistance: function (note, amount, unit) {
            let result = 0;
            if (note.trips && amount && unit) {
                note.trips.forEach(trip => {
                    if (trip.mode === 1 && trip.delivery_amount === amount && trip.delivery_unit === unit) {
                        result += trip.length
                    }
                })
            }
            return result;
        },
        emitEdit: function (note) {
            if (this.isObserver) {
                let item = this.notes.find(item => item.id === note.id)
                if (item) {
                    this.$emit('edit', item)
                }
            }
        },
        initSummaries() {
            this.materialSummaries = []
            this.notes.forEach(item => {
                let added = false
                this.materialSummaries.forEach(result => {
                    if (result.material.id === this.getMaterialId(item)) {
                        result.amount += item.amount
                        added = true
                    }
                })
                if (!added) {
                    this.materialSummaries.push({
                        material: {id: this.getMaterialId(item), name: this.getMaterialName(item)},
                        station: item.station_material ? item.station_material.station.name : item.storage_material ? item.storage_material.storage.name : "-",
                        amount: item.amount,
                        unit: item.unit
                    })
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.transport {
    color: #ffffff;
    background: #1F7F35;
    padding: .2em;
}

.unloading {
    color: #ffffff;
    background: #1E88E5;
    padding: .2em;
}

</style>
