<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="onReset"
        >
            <b-container fluid>
                <b-row class="nopads">
                    <b-col cols="12" sm="6" md="6" lg="6" xl="2" class="mb-3">
                        <b-form-group class="nopads" label-for="start">
                            <span class="span-title">{{ $t('orders.create_time') }} - {{ $t('trip_list.road_sec_from') }}</span>
                            <b-form-input id="start" v-model="createdFromDate" type="date" size="sm" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6" md="6" lg="6" xl="2" class="mb-3">
                        <b-form-group class="nopads" label-for="end">
                            <span class="span-title">{{ $t('orders.create_time') }} - {{ $t('trip_list.road_sec_to') }}</span>
                            <b-form-input id="end" v-model="createdToDate" type="date" size="sm" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6" md="6" lg="6" xl="2" class="mb-3">
                        <b-form-group class="nopads" label-for="closedStart">
                            <span class="span-title">{{ $t('observations.closed_time') }} - {{ $t('trip_list.road_sec_from') }}</span>
                            <b-form-input id="closedStart" v-model="closedFromDate" type="date" size="sm" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6" md="6" lg="6" xl="2" class="mb-3">
                        <b-form-group class="nopads" label-for="closedEnd">
                            <span class="span-title">{{ $t('observations.closed_time') }} - {{ $t('trip_list.road_sec_to') }}</span>
                            <b-form-input id="closedEnd" v-model="closedToDate" type="date" size="sm" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>
            <b-row class="nopads">
                <keep-alive>
                    <div
                        class="col-sm-4"
                        v-if="isAdmin">
                        <company-filter v-model="company"
                        />
                    </div>
                </keep-alive>
                <div class="col-sm-4">
                    <b-form-group class="nopads" label-for="assignedTo">
                        <span class="span-title">{{ $t('work_assignment.assigned_to') }}</span>
                        <b-form-select
                            id="assignedTo"
                            v-model="assignedTo"
                            size="sm"
                            :disabled="!users||!users.length"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('trip_list.select_user') }}
                                </option>
                            </template>
                            <option
                                v-for="option in users"
                                size="sm"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.first_name + ' ' + option.last_name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group class="nopads" label-for="createdBy">
                        <span class="span-title">{{ $t('work_assignment.created_by') }}</span>
                        <b-form-select
                            id="assignedTo"
                            v-model="createdBy"
                            size="sm"
                            :disabled="!users||!users.length"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('trip_list.select_user') }}
                                </option>
                            </template>
                            <option
                                v-for="option in users"
                                size="sm"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.first_name + ' ' + option.last_name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div
                    class="col-sm-4"
                    v-if="isAdmin||isObserver"
                >
                    <span class="span-title">{{ $t('orders.status') }}</span>
                    <multiselect
                        v-model="statuses"
                        :options="getStatus()"
                        :multiple="true"
                        :close-on-select="false"
                        label="key"
                        track-by="status"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :select-label="$t('common.select')"
                        :deselect-label="$t('common.remove_selection')"
                        :placeholder="$t('orders.select_status')"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                      <span class="option__desc">
                        <span class="option__title">{{ props.option.key }}</span>
                      </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.key }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </b-row>

            <b-row class="nopads">
                <div class="col-sm-4" style="margin-top: -0.3em;">
                    <open-contract-filter
                        :from="createdFromDate"
                        :to="createdToDate"
                        :show-only-my-contracts-option="true"
                        :show-title="true"
                        :show-company="false"
                        @contractSelected="setContract"/>
                </div>
                <div class="col-sm-4">
                    <contract-order-filter
                        v-model="order"
                        :contract-id="contract ? contract.id : undefined"
                        :show-executor-text="true"
                    />
                </div>
                <div class="col-sm-4">
                        <span class="span-title">{{ $t('common.id') }}</span>
                        <b-form-input
                            id="id"
                            v-model="id"
                            type="number"
                            size="sm"
                        />
                </div>
            </b-row>

            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import CompanyFilter from '../company/CompanyFilter'
import {workAssignmentHelper} from '../mixins/WorkAssignmentMixin'
import OpenContractFilter from "../contract/OpenContractFilter";
import ContractOrderFilter from "../order/ContractOrderFilter";

export default {
    name: 'WorkAssignmentSearch',
    components: {CompanyFilter, OpenContractFilter, ContractOrderFilter},
    mixins: [restApi, timeUtils, workAssignmentHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean,
        user: null
    },
    data: function () {
        return {
            company: null,
            companyParam: null,
            companyOptions: [],
            users: [],
            order: null,
            contract: null,
            assignedTo: null,
            statuses: null,
            createdBy: null,
            createdFromDate: null,
            createdToDate: null,
            closedFromDate: null,
            closedToDate: null,
            id: null
        }
    },
    watch: {
        company: function (value) {
            if (value) {
                this.fetchUsers()
            } else {
                this.users = []
            }
        }
    },
    computed: {
        statusInit() {
            return [
                {key: this.$t('work_assignment.open'), status: this.OPEN},
                {key: this.$t('work_assignment.assigned'), status: this.ASSIGNED},
                {key: this.$t('work_assignment.in_progress'), status: this.IN_PROGRESS}
            ]
        }
    },
    mounted: function () {
        this.statuses = this.statusInit
        if (this.isObserver) {
            this.fetchUsers()
        }
        if (!this.isAdmin && !this.isObserver && this.user) {
            this.users.push(this.user)
        }

        this.createdFromDate = this.getDateAsString(this.getMonthStartAsDate())
    },
    methods: {
        fetchUsers: function () {
            this.loading = true
            this.restFetchParams(this.userUrl, {company: this.company}, this.handleUsers)
        },
        handleUsers: function (response) {
            this.users = response.data
            this.loading = false
        },
        getContractCompanies: function () {
            this.companyOptions = []
            this.companyParam = null
            this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
            if (this.contract) {
                this.restFetchParams(this.contractCompanyUrl, {contract: this.contract.id}, this.handleContractCompanies)
            }
        },
        handleContractCompanies: function (response) {
            if (response && response.data) {
                this.companyPlaceholder = this.$t('')
                response.data.forEach(company => {
                    this.companyOptions.push({name: company.name, value: company.id})
                })
                this.companyPlaceholder = this.$t('users.select_company')
            }
            this.loading = false
        },
        setContract: function (contract) {
            this.contract = contract ? contract : null
            this.getContractCompanies()
        },
        onSubmit: function () {
            var params = {}
            if (this.company) {
                params.company = this.company
            }
            if (this.contract) {
                params.contract = this.contract.id
                params.contractor = this.contract.contractor.id
                params.customer = this.contract.customer.id
            }
            if (this.id) {
                params.id = this.id
            }
            if (this.order) {
                params.order = this.order.id
            }
            if (this.assignedTo) {
                params.assigned_to = this.assignedTo
            }
            if (this.createdBy) {
                params.created_by = this.createdBy
            }
            if (this.createdFromDate !== null && this.createdFromDate.trim()) {
                params.created_from = this.localTimeToUtcTime(this.createdFromDate).toISOString()
            }
            if (this.createdToDate !== null && this.createdToDate.trim()) {
                params.created_to = this.getEndOfTheDay(this.createdToDate).toISOString()
            }
            if (this.statuses && this.statuses.length > 0) {
                let statusArray = []
                this.statuses.forEach(item => {
                    statusArray.push(item.status)
                })
                params.statuses = statusArray
            }
            if (this.closedFromDate !== null && this.closedFromDate.trim()) {
                params.closedFromDate = this.localTimeToUtcTime(this.closedFromDate).toISOString()
            }
            if (this.closedToDate !== null && this.closedToDate.trim()) {
                params.closed_to = this.getEndOfTheDay(this.closedToDate).toISOString()
            }
            this.$emit('search', params)
        },
        onReset: function () {
            this.statuses = null
            this.company = null
            this.assignedTo = null
            this.createdBy = null
            this.order = null
            this.createdFromDate = null
            this.createdToDate = null
            this.closedFromDate = null
            this.closedToDate = null
        }
    }
}
</script>

<style scoped>

</style>
