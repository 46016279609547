<template>
    <div class="col-sm-12 nopads">
        <div class="list-content result-table">
            <b-table
                show-empty
                small
                :striped="!darkHeader ? 'striped' : null"
                :head-variant="darkHeader ? 'dark' : null"
                :thead-class="darkHeader ? 'white-text' : null"
                :items="recordData"
                :fields="fields"
            >
                <template v-slot:cell(start_time)="row">
                    {{ toLocalTime(row.value) }}
                </template>
                <template v-slot:cell(area_type)="row">
                    {{ areaTypeToText(row.item) }}
                </template>
                <template v-slot:cell(vehicle)="row">
                    {{
                        row.value ? row.value.make + ' ' + row.value.vehicle_model + ' ' + row.value.license_plate : ''
                    }}
                </template>
                <template v-slot:cell(amount)="row">
                    {{ row.value ? row.value.toFixed(2) : '' }}
                </template>
                <template v-slot:cell(surface_density)="row">
                    {{ calculateDensity(row.item) }}
                </template>
                <template v-slot:cell(object_number)="row">
                    {{
                        row.item.work_order && row.item.work_order.object_number > 0 ? row.item.work_order.object_number : ''
                    }}
                </template>
                <template v-slot:cell(task_type)="row">
                    {{ row.item.work_order ? row.item.work_order.task_type.name : '' }}
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {units} from "@/components/mixins/DeliveryNoteMixin";

export default {
    name: "point-unload-record-table",
    mixins: [timeUtils],
    props: {
        records: {
            type: Array,
            default() {
                return []
            }
        },
        darkHeader: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        recordData() {
            let data = this.records
            return data.sort((a, b) => {
                return new Date(a.start_time) - new Date(b.start_time)
            })
        }
    },
    data: function () {
        return {
            fields: [
                {start_time: {label: this.$t('delivery_note.date'), sortable: true}},
                {area_type: {label: this.$t('contract_reports.unload_records.area_type'), sortable: true}},
                {vehicle: {labels: this.$t('contract_reports.unload_records.vehicle'), sortable: true}},
                {road_name: {label: this.$t('contract_reports.unload_records.road_name'), sortable: true}},
                {road_num: {label: this.$t('contract_reports.unload_records.road_num'), sortable: true}},
                {road_sec: {label: this.$t('contract_reports.unload_records.road_sec'), sortable: true}},
                {distance: {label: this.$t('contract_reports.unload_records.road_distance'), sortable: true}},
                {area_size: {label: this.$t('contract_reports.unload_records.area_size'), sortable: true}},
                {amount: {label: this.$t('contract_reports.unload_records.amount'), sortable: true}},
                {unit: {label: this.$t('contract_reports.unload_records.unit')}},
                {surface_density: {label: units.KILOGRAMS_PER_SQUARE_METRE, sortable: true}},
                {object_number: {label: this.$t('orders.object'), sortable: true}},
                {task_type: {label: this.$t('work_report.task_type'), sortable: true}}
            ]
        }
    },
    methods: {
        areaTypeToText: function (record) {
            let area = ''
            if (record.area_size <= 50) area = 1
            else if (record.area_size > 50 && record.area_size <= 150) area = 2
            else if (record.area_size > 150 && record.area_size <= 500) area = 3
            else if (record.area_size > 500) area = 4

            switch (area) {
                case 1:
                    return this.$t('area_types.type_0_50')
                case 2:
                    return this.$t('area_types.type_50_150')
                case 3:
                    return this.$t('area_types.type_150_500')
                case 4:
                    return this.$t('area_types.type_bus_stops')
            }
            return area
        },
        calculateDensity: function (record) {
            // Calculate if necessary info provided and unit is tons
            if ((record.area_size > 0 && record.amount > 0) && record.unit === units.TONS) {
                // Tons to kilograms
                let amount = record.amount * 1000
                let density = amount / record.area_size
                return density.toFixed(2);
            }
            return ''
        }
    }
}
</script>
