<script>
import {Pie} from 'vue-chartjs'

export default {
    name: 'pie-chart',
    props: {
        labels: {
            type: Array,
            default() {
                return []
            }
        },
        colors: {
            type: Array,
            default() {
                return []
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            default() {
                return ''
            }
        },
        unit: {
            default() {
                return ''
            }
        }
    },
    extends: Pie,
    data: function () {
        return {
            chartData: this.getChartData(),
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    caretSize: 0,
                    bodyFontSize: 18,
                    bodyFontColor: 'rgba(0,0,0,.8)',
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    borderColor: 'rgba(0,0,0,0.3)',
                    borderWidth: 1,
                    callbacks: {
                        label: function (tooltipItems, chartData) {
                            return chartData.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' ' +
                                chartData.datasets[tooltipItems.datasetIndex].unit
                        }
                    }
                },
            }
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options)
    },
    watch: {
        data() {
            this.chartData = this.getChartData()
            this.renderChart(this.chartData, this.options)
        }
    },
    methods: {
        getChartData: function () {
            let values = []
            this.data.forEach(item => values.push(item.value))
            return {
                labels: this.labels,
                datasets: [
                    {
                        label: this.title,
                        backgroundColor: this.colors,
                        unit: this.data && this.data.length > 0 ? this.data[0].unit : '',
                        data: values
                    }
                ]
            }
        },
        getChartAsImage: function () {
            return this.$data._chart.toBase64Image()
        }
    }
}
</script>
