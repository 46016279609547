<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12">
        <transition name="fade">
            <div class="list-content result-table pointer">
                <b-table
                    show-empty
                    small
                    striped
                    @row-clicked="rowClicked"
                    :items="storages"
                    :fields="fields"
                />
            </div>
        </transition>
    </div>
</template>

<script>


export default {
    name: 'material-storage-list',
    props: {
        storages: {
            type: Array,
            default() {
                return []
            }
        }
    },
    computed: {
        fields() {
            return [
                {key: 'id', label: this.$t('materialstorage.list.id'), sortable: true},
                {key: 'name', label: this.$t('materialstorage.list.name'), sortable: true}]
        }
    },
    methods: {
        rowClicked: function (details) {
            this.$emit('rowClicked', details)
        }
    }
}
</script>
