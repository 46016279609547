<template>
    <div
        v-bind:class="{ 'details-container': !this.isAddEquipmentMode }"
        @click.stop="close"
    >
        <div
            v-bind:class="{ 'details dynamic_width': !this.isAddEquipmentMode }"
            @click.stop=""
        >
            <h3 v-if="!this.isAddEquipmentMode" class="pl-1">
                {{ $t('roadside_furniture.editor_title')  }}
            </h3>
            <location-deviation v-if="!this.isAddEquipmentMode" :location-deviation="item.location_deviation"/>
            <div class="pl-2 pr-2">
                <b-row v-if="!this.isAddEquipmentMode">
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('common.id') }}</span>
                        <span class="item-detail-text">{{ item.external_system_id }}</span>
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <road-addess-view :road-number="item.road_num" :section-number="item.road_sec" :distance="item.distance" :show-street-name="false"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.type') }}</span>
                        <b-form-select
                            v-model="item.type"
                            size="sm"
                            :state="this.validateIsNumber(item.type)"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('roadside_furniture.select_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getRoadsideFurnitureTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.material') }}</span>
                        <b-form-select
                            v-model="item.material"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('materials.select_material') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getMaterials()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <condition-selector v-model="item.condition" :required="true"/>
                    </div>
                </b-row>
                <location-details
                    v-if="!this.isAddEquipmentMode"
                    :lane-data="this.item.location_specification.lane"
                    :side-data="this.item.location_specification.side"
                    :track-data="this.item.location_specification.tracks"
                />
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.wc_drainage') }}</span>
                        <b-form-select
                            v-model="item.wc_drainage"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('roadside_furniture.select_wc_drainage') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getRoadsideFurnitureWCDrainageTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.inva_wc') }}</span>
                        <b-form-checkbox
                            id="inva_wc"
                            v-model="item.inva_wc"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.wc_household_water') }}</span>
                        <b-form-checkbox
                            id="WC_household_water"
                            v-model="item.wc_household_water"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.equipment_amount') }}</span>
                        <b-form-input
                            id="equipment_amount"
                            v-model="item.equipment_amount"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.standard_construction') }}</span>
                        <b-form-checkbox
                            id="standard_construction"
                            v-model="item.standard_construction"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('portal.code') }}</span>
                        <b-form-input
                            id="code"
                            min="1"
                            v-model="item.code"
                            type="text"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.wc_heating') }}</span>
                        <b-form-checkbox
                            id="precipitation_nest"
                            v-model="item.wc_heating"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.transferable') }}</span>
                        <b-form-checkbox
                            id="transferable"
                            v-model="item.transferable"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.buss_stop_transparency') }}</span>
                        <b-form-checkbox
                            id="buss_stop_transparency"
                            v-model="item.buss_stop_transparency"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.lighting') }}</span>
                        <b-form-checkbox
                            id="lighting"
                            v-model="item.lighting"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.info') }}</span>
                        <b-form-input
                            id="info"
                            min="1"
                            v-model="item.info"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.bath_room') }}</span>
                        <b-form-checkbox
                            id="bath_room"
                            v-model="item.bath_room"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.buss_stop_wall_amount') }}</span>
                        <b-form-input
                            id="buss_stop_wall_amount"
                            v-model="item.buss_stop_wall_amount"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.buss_stop_code') }}</span>
                        <b-form-input
                            id="buss_stop_code"
                            min="1"
                            v-model="item.buss_stop_code"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('roadside_furniture.service_area_code') }}</span>
                        <b-form-input
                            id="service_area_code"
                            min="1"
                            v-model="item.service_area_code"
                            type="text"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads" v-if="this.isAddEquipmentMode">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-location-details-editor
                        :roadInfo="item"
                        :locationSpecification="item.location_specification"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-damages :items="item.equipment_damages"/>
                    </div>
                </b-row>
                <equipment-actions v-if="!this.isAddEquipmentMode" ref="actions" v-model="item.actions" />
                <linked-equipment
                    v-if="item.linked_equipment && item.linked_equipment.length > 0"
                    :linked-equipments="item.linked_equipment"
                />
            </div>
            <velho-editor-buttons
                v-if="!this.isAddEquipmentMode"
                :url="extRoadsideFurnitureUrl"
                :selected-contract="selectedContract"
                :item="item"
                :disabled="loading"
                @close="close"
                @submit="handleSubmit"
                @removalConfirmed="removalConfirmed"
            />
        </div>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import ConditionSelector from "@/components/velho/ConditionSelector";
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import EquipmentActions from "@/components/velho/EquipmentActions";
import {roadsideFurnitureHelper} from "@/components/mixins/RoadsideFurnitureMixin";
import VelhoEditorButtons from "@/components/velho/VelhoEditorButtons";
import LinkedEquipment from "@/components/velho/LinkedEquipment";
import LocationDetails from "@/components/velho/LocationDetails.vue";
import LocationDeviation from "@/components/velho/LocationDeviation";
import VelhoEquipmentLocationDetailsEditor from "@/components/velho/VelhoEquipmentLocationDetailsEditor";
import VelhoEquipmentDamages from "@/components/velho/VelhoEquipmentDamages";
import RoadAddessView from "@/components/velho/RoadAddressView";
import {velhoValidationMixin} from "@/components/mixins/VelhoValidationMixin";

export default {
    name: 'RoadsideFurnitureEditor',
    components: {
        RoadAddessView,
        LocationDeviation, VelhoEquipmentLocationDetailsEditor, VelhoEquipmentDamages,
        LocationDetails, VelhoEditorButtons, EquipmentActions, ConditionSelector, LinkedEquipment},
    mixins: [mapHelper, conversionModelHelper, restApi, velhoHelper, roadsideFurnitureHelper, velhoValidationMixin],
    props: {
        item: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        },
        addEquipmentEditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAddEquipmentMode() {
            return this.addEquipmentMode
        }
    },
    data() {
        return {
            addEquipmentMode: this.addEquipmentEditor,
            map: null,
            loading: false,
        }
    },
    methods: {
        handleSubmit: function(url, item, selectedContract, replace = false) {
            if(replace) {
                this.submitEquipmentsreplace(this.extEquipmentsReplaceUrl, [item], selectedContract)
            } else {
                this.submitEquipment(url, item, selectedContract);
            }
        },
        close: function () {
            this.$emit('close')
        },
        isValidForm: function() {
            if(!this.item) { return false }
            if(!this.validateRoadNumber(this.item.road_num)) { return false }
            if(!this.validateRoadSection(this.item.road_sec)) { return false }
            if(!this.validateRoadDistance(this.item.distance)) { return false }
            if(!this.validateRoadSide(this.item.location_specification.side)) { return false }
            if(!this.validateRoadTracks(this.item.location_specification.tracks)) { return false }
            if(!this.validateIsNumber(this.item.type)) { return false }
            if(!this.validateIsNumber(this.item.condition)) { return false }

            return true
        }
    }
}
</script>

<style scoped>
</style>

