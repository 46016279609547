<template>
    <div class="col-12 nopads">
        <!-- Selected task types with amounts and units -->
        <span class="span-title">
        {{ $t('contracts.task_type') }}
      </span>
        <div class="col-sm-12 nopads">
            <div v-if="selectedTaskTypes.length > 0">
                <b-col cols="12" sm="6" md="4" lg="3" xl="2"
                    v-for="item in selectedTaskTypes"
                    :key="item.id"
                    class="inline"
                >
                    <div class="col-sm-12 task-type-container">
                        <span class="sub-title">
                           {{ item.task_type.name }}
                        </span>
                        <b-row class="nopads">
                            <div class="col-6 pr-1 text-light">{{ $t('contracts.amount') }}</div>
                            <div class="col-6 text-light"><label>{{ $t('contracts.unit') }}</label></div>
                        </b-row>
                        <b-row class="nopads">
                            <div class="col-6 nopads pr-1">
                                <b-form-input
                                    :id="item.task_type + 'amount'"
                                    v-model="item.amount"
                                    type="text"
                                    size="sm"
                                />
                            </div>
                            <div class="col-6 nopads">
                                <b-form-select
                                    :id="item.id + 'unit'"
                                    v-model="item.unit"
                                    :options="options"
                                    size="sm"
                                >
                                    <template #first>
                                        <option
                                            :value="''"
                                            disabled
                                        >
                                            {{ $t('contracts.unit') }}
                                        </option>
                                    </template>
                                </b-form-select>
                            </div>
                        </b-row>
                        <div
                            v-if="item.id === undefined"
                            class="col-sm-12 pt-2"
                        >
                            <b-button
                                variant="danger"
                                size="sm"
                                @click.stop="removeFromSelection(item.task_type)"
                            >
                              <span>
                                {{ $t('common.remove') }}
                              </span>
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </div>
            <div
                v-else
                class="col-sm-12 small-pad"
            >
                            <span>
                              {{ $t('contracts.no_selected_task_types') }}
                            </span>
            </div>
        </div>
        <!-- Task type list -->
        <span class="span-title">
        {{ $t('contracts.select_task_type') }}
      </span>
        <div class="col-sm-12 row inspectable-container nopads">
            <div
                v-for="item in taskTypes"
                :key="item.id"
                class="col-sm-6 col-md-4 col-lg-3 col-xl-2 inline inspectables nopads"
                :class="{ 'inspectables-disabled' : isSelected(item.id) }"
                @click="addToSelection(item)"
              >
                {{ item.name }}
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {units, workManagementHelper} from '../mixins/WorkManagementMixin'

export default {
    name: 'contract-task-types',
    mixins: [workManagementHelper, restApi],
    props: {
        useDefault: {
            type: Boolean,
            default: true
        },
        value: {
            type: Array,
            default: function () {
                return []
            }
        }
    },

    data: function () {
        return {
            loading: false,
            defaultTaskTypesLoading: false,
            taskTypesLoading: false,
            taskTypes: [],
            selectedTaskTypes: this.value,
            options: [
                {value: units.HOUR, text: this.getUnitText(units.HOUR)},
                {value: units.DAY, text: this.getUnitText(units.DAY)},
                {value: units.WEEK, text: this.getUnitText(units.WEEK)},
                {value: units.MONTH, text: this.getUnitText(units.MONTH)},
                {value: units.TONS, text: this.getUnitText(units.TONS)},
                {value: units.METERS, text: this.getUnitText(units.METERS)},
                {value: units.KILOMETERS, text: this.getUnitText(units.KILOMETERS)},
                {value: units.SQUARE_METERS, text: this.getUnitText(units.SQUARE_METERS)},
                {value: units.CUBIC_METERS, text: this.getUnitText(units.CUBIC_METERS)},
                {value: units.PCS, text: this.getUnitText(units.PCS)}
            ]
        }
    },

    watch: {
        'selectedTaskTypes': {
            handler: function () {
                this.$emit('input', this.selectedTaskTypes)
            },
            deep: true
        },
        value: function () {
            this.selectedTaskTypes = this.value
        }
    },

    created: function () {
        this.fetchTaskTypes()
        if (this.useDefault) {
            this.fetchDefaultTaskTypes()
        }
    },

    methods: {
        fetchDefaultTaskTypes: function () {
            this.defaultTaskTypesLoading = true
            this.isLoading()
            this.restFetch(this.defaultTaskTypeUrl, this.handleDefaultTaskTypes, this.onDefaultTaskTypeLoaded)
        },

        handleDefaultTaskTypes: function (results) {
            this.onDefaultTaskTypeLoaded()
            results.data.forEach(item => {
                this.updateTaskType(item.task_type)
            })
        },

        onDefaultTaskTypeLoaded: function () {
            this.defaultTaskTypesLoading = false
            this.isLoading()
        },

        fetchTaskTypes: function () {
            this.taskTypesLoading = true
            this.isLoading()
            this.restFetch(this.taskTypeUrl, this.handleTaskTypes, this.onTaskTypeLoaded)
        },

        handleTaskTypes: function (response) {
            this.taskTypes = response.data
            this.onTaskTypeLoaded()
        },

        onTaskTypeLoaded: function () {
            this.taskTypesLoading = false
            this.isLoading()
        },

        indexOfTaskType: function (id) {
            return this.selectedTaskTypes.findIndex(function (obj) {
                return obj.task_type.id === id
            })
        },

        isSelected: function (id) {
            let index = this.indexOfTaskType(id)
            return index >= 0
        },

        addToSelection(item) {
            let index = this.selectedTaskTypes.findIndex(selectedItem => selectedItem.task_type.id === item.id)
            if (index < 0) {
                this.selectedTaskTypes.push({
                    'task_type': {'id': item.id, 'name': item.name},
                    'amount': 0,
                    'unit': ''
                })
            }
        },
        removeFromSelection (item) {
            let index = this.indexOfTaskType(item.id)
            if (index >= 0) {
                this.selectedTaskTypes.splice(index, 1)
            }
        },
            isLoading: function () {
            this.loading = this.defaultTaskTypesLoading || this.taskTypesLoading
        }
    }
}
</script>
