<template>
    <div class="nopads">
        <b-form-group
            class="title nopads"
            label-for="order"
        >
            <span v-if="showTitle" class="span-title">{{ $t('observations.order') }}</span>
            <b-form-select
                id="order"
                v-model="selectedOrder"
                size="sm"
                :disabled="loading > 0"
                @input="handleInput"
            >
                <template slot="first">
                    <option :value="null">
                        {{ $t('delivery_note.form.select_order') }}
                    </option>
                </template>
                <option
                    v-for="option in orders"
                    :key="option.id"
                    :value="option.id"
                >
                    {{ showExecutorText ? getOrderAndExecutorStr(option) : getOrderStr(option) }}
                    {{ showInfo && option.info ? ' - ' + getOrderInfo(option) : ''}}
                </option>
            </b-form-select>
        </b-form-group>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {orderMixin} from "../mixins/OrderMixin"

export default {
    name: 'ContractOrderFilter',
    mixins: [restApi, orderMixin],
    props: {
        value: {
            type: Number,
            default: function () {
                return 0
            }
        },
        preSelectedOrder: {
            type: Number,
            default: function () {
                return null
            }
        },
        contractId: {
            type: Number,
            default: function () {
                return null
            }
        },
        openOnly: {
            type: Boolean,
            default: false
        },
        openOrPlannedOnly: {
            type: Boolean,
            default: false
        },
        showExecutorText: {
            type: Boolean,
            default: false
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showInfo: {
            type: Boolean,
            default: true
        }
    },

    model: {
        prop: 'order',
        event: 'orderSelected'
    },

    data: function () {
        return {
            loading: 0,
            orders: [],
            selectedOrder: null,
        }
    },

    watch: {
        contractId: function () {
            this.fetchOrders()
        },
        preSelectedOrder: function () {
            this.selectedOrder = this.preSelectedOrder
            if (!this.selectedOrder && this.orders) {
                let exists = this.orders.find(item => item.id === this.selectedOrder)
                if (!exists) {
                    this.selectedOrder = null
                }
            }
        }
    },

    mounted() {
        this.selectedOrder = this.preSelectedOrder
        if (this.contractId) {
            this.fetchOrders()
        }
    },

    methods: {
        handleInput: function (e) {
            if (this.orders && this.orders.length > 0) {
                this.$emit('orderSelected', this.orders.find(item => item.id === e))
            }
        },

        fetchOrders: function () {
            this.orders = []
            if (this.contractId) {
                this.loading++
                let params = {
                    contract: this.contractId
                }
                if (this.openOnly) {
                    params.open = 1
                }
                if(this.openOrPlannedOnly) {
                    params.open_or_planned = 1
                }
                this.restFetchParams(this.orderUrl, params, this.handleOrders)
            }
        },

        handleOrders: function (response) {
            this.loading--
            if (response && response.data) {
                this.orders = response.data
            }
            if(!this.selectedOrder) {
                this.selectedOrder = this.preSelectedOrder
            }
            if (this.selectedOrder) {
                let exists = this.orders.find(item => item.id === this.selectedOrder)
                if (!exists) {
                    this.selectedOrder = null
                } else {
                    this.$emit('orderSelected', this.orders.find(item => item.id === this.selectedOrder))
                }
            }
        },

        reset: function () {
            this.orders = []
            this.selectedOrder = null
        },

        getOrderInfo(order) {
          if (order.info) {
              return order.info.length > 40 ? (order.info.substr(0,35) + '...') : order.info
          }
          return ''
        }
    }
}
</script>
