<template>
    <div class="col-12 pl-2 pr-2">
        <div class="col-sm-12 list-content result-table" v-if="results">
            <b-table
                id="resultTable"
                ref="resultTable"
                small
                :items="results"
                :fields="fields"
                @row-clicked="emitEdit"
            >
                <template v-slot:cell(time)="row">
                    {{ row.value ? toLocalTime(row.value) : '' }}
                </template>
                <template v-slot:cell(contract)="row">
                    {{ row.value ? row.value.name : '' }}
                </template>
                <template v-slot:cell(addition)="row">
                    {{ row.item.added_by ? $t('areas.manual_addition') : $t('areas.tracking') }}
                </template>
                <template v-slot:cell(cost)="row">
                    {{ row.value && row.value > 0 ? (row.value/100).toFixed(2) : '' }}
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "../../mixins/TimeUtils";

export default {
    name: 'AreaWorkList',
    mixins: [timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            sortBy: 'created',
            sortDesc: true,
            sortDirection: 'asc',
            fields: [
                {key: 'time', label: this.$t('areas.time'), sortable: true},
                {key: 'area_name', label: this.$t('areas.area'), sortable: true},
                {key: 'area_square_meters', label: this.$t('areas.square_meters'), sortable: true},
                {key: 'task_type_name', label: this.$t('areas.work_task'), sortable: true},
                {key: 'contract', label: this.$t('areas.work_contract'), sortable: true},
                {key: 'addition', label: this.$t('areas.addition'), sortable: true},
                {key: 'cost', label: this.$t('daily_summary.cost'), sortable: true}
            ],
        }
    },
    mounted: function () {
    },
    methods: {
        emitEdit: function (result) {
            // Find defect item from original items and emit request to edit
            let p = this.results.find(item => item.id === result.id)
            if (p) {
                this.$emit('edit', p)
            }
        },
    }
}
</script>

<style scoped>
</style>
