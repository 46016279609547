<template>
    <div class="col-sm-6 nopads top-margin">
        <form @submit.prevent="changePassword">
      <span class="title">
        {{ $t('users.change_password_title') }}
      </span>
            <b-row>
                <b-input-group>
                    <b-form-input
                        id="currentPassword"
                        v-model="currentPassword"
                        :type="showCurrentPassword ? 'text' : 'password'"
                        :placeholder="$t('users.current_password')"
                        required
                        autocomplete="none"
                    />
                    <b-input-group-append>
                        <b-button
                            size="sm"
                            variant="light"
                            id="showCurrentPassword"
                            @mousedown="showCurrentPassword = true"
                            @mouseup="showCurrentPassword = false"
                            @mouseout="showCurrentPassword = false"
                            style="max-height: 3.3em; border: #BCBCBC 1px solid">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-row>
            <b-row>
                <b-input-group>
                    <b-form-input
                        id="newPassword"
                        v-model="newPassword"
                        :type="showNewPassword ? 'text' : 'password'"
                        :placeholder="$t('users.new_password')"
                        required
                        autocomplete="none"
                        @input="validatePassword"
                    />
                    <b-input-group-append>
                        <b-button
                            size="sm"
                            variant="light"
                            id="showNewPassword"
                            @mousedown="showNewPassword = true"
                            @mouseup="showNewPassword = false"
                            @mouseout="showNewPassword = false"
                            style="max-height: 3.3em; border: #BCBCBC 1px solid">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-row>
            <b-row>
                <b-input-group>
                    <b-form-input
                        id="confirmPassword"
                        v-model="confirmPassword"
                        :disabled="!passwordOk"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :placeholder="$t('users.password_again')"
                        required
                        autocomplete="none"
                        @input="validateConfirmPassword"
                    />
                    <b-input-group-append>
                        <b-button
                            size="sm"
                            variant="light"
                            :disabled="!passwordOk"
                            id="showConfirmPassword"
                            @mousedown="showConfirmPassword = true"
                            @mouseup="showConfirmPassword = false"
                            @mouseout="showConfirmPassword = false"
                            style="max-height: 3.3em; border: #BCBCBC 1px solid">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-row>
            <b-row>
                <b-button
                    type="submit"
                    size="lg"
                    variant="primary col-sm-12"
                    :disabled="!confirmPasswordOk"
                >
                    {{ $t('users.change_password_title') }}
                </b-button>
            </b-row>
            <b-row>
                <span v-if="!passwordOk && !passwordChanged">{{ $t('users.password_tip') }}</span>
                <span v-else-if="!confirmPasswordOk && !passwordChanged">{{ $t('users.confirm_tip') }}</span>
                <span v-if="passwordChanged && newPassword.length < 1">{{ $t('users.password_set_ok') }}</span>
                <span
                    v-if="error && newPassword.length < 1"
                    class="error"
                >
          {{ $t('users.password_set_err') }}
        </span>
            </b-row>
        </form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from '@/event-bus'
export default {
    name: 'ChangePassword',
    mixins: [restApi],
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            passwordOk: false,
            confirmPasswordOk: false,
            passwordChanged: false,
            error: false,
            loading: false,
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
        }
    },

    methods: {

        changePassword: function () {
            this.loading = true
            this.restUpdate(this.changePasswordUrl, {
                old: this.currentPassword,
                new: this.newPassword
            }, this.success, this.fail)
        },
        success: function () {
            this.loading = false
            this.currentPassword = ''
            this.newPassword = ''
            this.confirmPassword = ''
            this.passwordChanged = true
            this.error = false
        },
        fail: function () {
            this.loading = false
            this.passwordChanged = false
            this.error = true
            EventBus.$emit('show-alert', this.$t('users.password_set_err'))
        },
        validatePassword: function () {
            this.passwordOk = this.newPassword.length >= 8
            this.confirmPasswordOk = (this.confirmPassword === this.newPassword)
        },
        validateConfirmPassword: function () {
            this.confirmPasswordOk = (
                this.confirmPassword.length >= 8 &&
                this.newPassword === this.confirmPassword
            )
        }
    }
}
</script>
<style>
</style>
