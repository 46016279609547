<template>
    <base-modal @cancel="$emit('close')" style="z-index: 50; pointer-events: all;">
        <div class="details-container">
            <div
                class="details dynamic_width"
            >
                <h3 class="pl-3 pr-2">{{ $t('traffic_control.modal_title')}}</h3>
                <traffic-control-editor
                    ref="trafficControlEditor"
                    @scrollTop="$emit('scrollTop')"
                    :traffic-control-item="trafficControlItem"
                    @close="$emit('close')"
                    @closeAndUpdate="$emit('closeAndUpdate')"
                />
            </div>
        </div>

    </base-modal>
</template>

<script>

import BaseModal from '../BaseModal'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import TrafficControlEditor from "@/components/trafficcontrol/TrafficControlEditor";

export default {
    name: "TrafficControlModal",
    components: {TrafficControlEditor, BaseModal},
    mixins: [
        mapHelper,
        restApi,
    ],
    props: {
        trafficControlItem: {
            type: Object,
            default: null
        },
    },
}

</script>

<style>
@import "../../styles/modal.css";
</style>
