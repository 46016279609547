<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <div class="col-sm-12 list-content result-table">
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                    @row-clicked="emitShow"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                    <template v-slot:cell(user)="row">
                        {{ row.value.last_name }}, {{ row.value.first_name }}
                    </template>
                    <template v-slot:cell(company)="row">
                        {{ row.value.name }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.value.name }} <span
                        v-if="row.value.contract_number">({{ row.value.contract_number }})</span>
                    </template>
                    <template v-slot:cell(type)="row">
                        {{ getTypeString(row.value) }}
                    </template>
                    <template v-slot:cell(location)="row">
                        <span v-if="row.value.x && row.value.y">{{
                                row.value.y.toFixed(6)
                            }} {{ row.value.x.toFixed(6) }}</span>
                    </template>
                    <template v-slot:cell(state)="row">
                        {{ getStateString(row.item) }}
                    </template>
                    <template v-slot:cell(state)="row">
                        {{ getStateString(row.item) }}
                    </template>
                    <template v-slot:cell(resolver)="row">
          <span v-if="row.item.resolved_by">
            {{ row.item.resolved_by.last_name }}, {{ row.item.resolved_by.first_name }}
          </span>
                    </template>
                    <template v-slot:cell(resolved_time)="row">
          <span v-if="row.item.resolved_time">
            {{ toLocalTime(row.item.resolved_time) }}
          </span>
                    </template>
                    <template v-slot:cell(note)="row">
                        {{ row.item.resolved_notes }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'UserAlertList',
    mixins: [timeUtils],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        },
        canAdd: Boolean
    },
    data() {
        return {
            sortBy: 'username',
            sortDesc: false,
            fields: [
                {key: 'time', label: this.$t('user_alert.time'), sortable: true},
                {key: 'user', label: this.$t('user_alert.person'), sortable: true},
                {key: 'company', label: this.$t('user_alert.company'), sortable: true},
                {key: 'contract', label: this.$t('user_alert.contract'), sortable: true},
                {key: 'type', label: this.$t('user_alert.type'), sortable: true},
                {key: 'location', label: this.$t('user_alert.location'), sortable: true},
                {key: 'state', label: this.$t('user_alert.state'), sortable: true},
                {key: 'resolver', label: this.$t('user_alert.resolver'), sortable: true},
                {key: 'resolved_time', label: this.$t('user_alert.resolve_date'), sortable: true},
                {key: 'note', label: this.$t('user_alert.notes'), sortable: true},
            ],
            loading: false
        }
    },
    methods: {
        emitShow: function (result) {
            let item = this.results.find(item => result.id === item.id)
            if (item) {
                this.$emit('show', item)
            }
        },
        getTypeString(type) {
            switch (type) {
                case 1:
                    return this.$t('user_alert.types.user_initiated')
                case 2:
                    return this.$t('user_alert.types.automatic')
            }
            return ''
        },
        getStateString(alert) {
            if (alert.resolved_time) {
                return this.$t('user_alert.states.resolved')
            }
            return this.$t('user_alert.states.open')
        }
    }
}
</script>
