export const utility = {
    methods: {
        getUniqueId: function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0
                const v = c === 'x' ? r : (r & 0x3 | 0x8)
                return v.toString(16)
            })
        },
        getLanguage: function () {
            const lang = navigator.language.split('-')[0]
            switch (lang) {
                case 'en':
                    return 'en'
                case 'fi':
                    return 'fi'
                case 'fr':
                    return 'fr'
            }
            return 'en'
        }
    }
}
