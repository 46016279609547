<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-container">
        <div
            v-if="canPlay"
            class="col-sm-12 button-container"
        >
            <b-button
                variant="primary"
                @click.stop="showVideoView()"
            >
                <font-awesome-icon icon="play-circle"/>
                {{ $t('observations_videos.play') }}
            </b-button>
        </div>
        <div class="col-sm-12 list-content result-table">
            <transition name="fade">
                <b-table
                    striped
                    small
                    responsive
                    :items="tableItems"
                    :fields="fields"
                >
                    <template
                        slot="vehicle"
                        slot-scope="row"
                    >
            <span>
              {{ row.value.make }} {{ row.value.vehicle_model }}
            </span>
                    </template>
                    <template
                        slot="work_order"
                        slot-scope="row"
                    >
            <span>
              {{ row.value.task_type.name }}
            </span>
                    </template>
                    <template
                        slot="start_time"
                        slot-scope="row"
                    >
            <span>
              {{ $d(new Date(row.value)) }} {{ $d(new Date(row.value), 'short') }}
            </span>
                    </template>
                    <template
                        slot="customer"
                        slot-scope="row"
                    >
            <span>
              {{ row.value.name }}
            </span>
                    </template>
                    <template
                        slot="contract"
                        slot-scope="row"
                    >
            <span>
              {{ row.value.name }}
            </span>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-dropdown
                            variant="primary"
                            size="sm"
                        >
                            <b-dropdown-item @click.stop="showVideoMetadata(row.index, true)">
                                {{ $t('observations_videos.front_video') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click.stop="showVideoMetadata(row.index, false)">
                                {{ $t('observations_videos.rear_video') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
            </transition>
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'ObservationVideoList',
    mixins: [restApi, timeUtils],
    props: {
        isAdmin: Boolean,
        canPlay: Boolean,
        videosMetadata: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data: function () {
        return {
            tableItems: [],
            fields: [
                {
                    start_time: {
                        label: this.$t('observations_videos.start_time'),
                        sortable: false
                    }
                },
                {
                    customer: {
                        label: this.$t('observations_videos.customer'),
                        sortable: false
                    }
                },
                {
                    contract: {
                        label: this.$t('observations_videos.contract'),
                        sortable: false
                    }
                },
                {
                    work_order: {
                        label: this.$t('observations_videos.order'),
                        sortable: false
                    }
                },
                {
                    vehicle: {
                        label: this.$t('observations_videos.vehicle'),
                        sortable: false
                    }
                },
                {
                    actions: {
                        label: '',
                        sortable: false
                    }
                }
            ]
        }
    },
    watch: {
        videosMetadata: function (videosMetadata) {
            this.tableItems = []
            videosMetadata.forEach(metadata => {
                this.tableItems.push({
                    vehicle: this.getVehicleString(metadata[0].vehicle),
                    work_order: metadata[0].work_order.task_type.name,
                    contract: metadata[0].work_order.contract.name,
                    customer: metadata[0].work_order.contract.customer.name,
                    start_time: this.toLocalTime(metadata[0].start_time)
                })
            })
        }
    },
    methods: {
        showVideoView: function () {
            this.$emit('showVideoView')
        },
        showVideoMetadata: function (index, isFrontVideo) {
            this.$emit('videoMetadata', this.videosMetadata[index], isFrontVideo)
        },
        getVehicleString: function (vehicle) {
            let result = '-'
            if (vehicle && vehicle.make) {
                result = vehicle.make
                if (vehicle.vehicle_model) {
                    result += ' ' + vehicle.vehicle_model
                }
            }
            return result
        }
    }
}
</script>

<style scoped>

</style>
