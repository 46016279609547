export const contractHelper = {
    methods: {
        getUniqueId: function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0
                const v = c === 'x' ? r : (r & 0x3 | 0x8)
                return v.toString(16)
            })
        },
        setContractCollections(contracts) {
            // Initialize a Map to keep track of unique ids
            const uniqueIds = new Map();
            this.contractCollectionOptions = []

            if (contracts && contracts.length > 0) {
                contracts.forEach(contract => {
                    if (contract && contract.contract_collection && contract.contract_collection.id) {
                        // Check if id is already present
                        if (!uniqueIds.has(contract.contract_collection.id)) {
                            uniqueIds.set(contract.contract_collection.id, true); // Mark this id as encountered
                            this.contractCollectionOptions.push(contract.contract_collection);
                        }
                    }
                });
            }
        },
        filterContracts: function () {
            // Initialize the filteredContracts to an empty array
            this.filteredContracts = [];

            // If both selectedCompany and contractCollection are defined
            if (this.selectedCompany && this.contractCollection && this.contractCollection.id) {
                this.filteredContracts = this.contracts.filter(item =>
                    item.customer.id === this.selectedCompany &&
                    item.contract_collection &&
                    item.contract_collection.id === this.contractCollection.id
                );
                return;
            }

            // If only selectedCompany is defined
            if (this.selectedCompany) {
                this.filteredContracts = this.contracts.filter(item =>
                    item.customer.id === this.selectedCompany
                );
                return;
            }

            // If only contractCollection is defined
            if (this.contractCollection && this.contractCollection.id) {
                this.filteredContracts = this.contracts.filter(item =>
                    item.contract_collection &&
                    item.contract_collection.id === this.contractCollection.id
                );
                return;
            }

            // If neither selectedCompany nor contractCollection is defined, include all contracts
            this.filteredContracts = this.contracts;
        }
    }
}
