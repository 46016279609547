<template>
    <div class="col-sm-12 nopads">
        <b-form v-if="observationCollectionUpdate" class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    class="form-title editor-title"
                >
                    {{ observationCollectionUpdate.id && observationCollectionUpdate.id > 0 ? $t('observation_collections.edit') : $t('observation_collections.add')}}
                </h2>
            </div>
            <span class="span-title p-2">
                {{ $t('common.name') }}
            </span>
            <b-form-group
                 class="title p-2"
                 label-for="name"
            >
                <b-form-input
                    id="name"
                    v-model="observationCollectionUpdate.name"
                    type="text"
                    size="mb"
                />
            </b-form-group>
            <span class="span-title p-2">
                {{ $t('menu.observation_types') }}
            </span>
            <b-form-group
                class="p-2"
                label-for="observation_types"
            >
                <multiselect
                    v-model="observationTypesSelected"
                    :options="observationTypeOptions"
                    :multiple="true"
                    :close-on-select="false"
                    label="text"
                    track-by="value"
                    :searchable="true"
                    :selected-label="$t('common.selected')"
                    :select-label="$t('common.select')"
                    :deselect-label="$t('common.remove_selection')"
                    :placeholder="$t('pricing_model_conversion.select_code')"
                    :disabled="loading"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.text }}</span>
                  </span>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.text }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>
            </b-form-group>
            <!-- Buttons -->
            <div class="col-sm-12 button-container">
                <b-button
                    variant="danger"
                    class="form-button"
                    @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="form-button"
                    :disabled="loading"
                    @click.stop="submit"
                >
                  {{ $t('common.save') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <b-modal
            v-model="deletion"
            size="sm"
            centered
            ok-variant="danger"
            :cancel-title="$t('common.no')"
            :ok-title="$t('common.yes')"
            @ok="deleteObservationCollection()"
            @hide="deletion = false"
        >
            <div class="modal-body">
                <span>{{ $t('common.confirm_delete') }}</span>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '@/components/mixins/RestApiMixin'

export default {
    name: 'ObservationCollectionEditor',
    mixins: [restApi],
    props: {
        observationCollection: {
            type: Object,
            default: null
        },
        observationTypes: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data: function () {
        return {
            loading: false,
            observationCollectionUpdate: JSON.parse(JSON.stringify(this.observationCollection)),
            observationTypeOptions: [],
            deletion: false,
            observationTypesSelected: [],
        }
    },
    mounted: function () {
        if(this.observationTypes && this.observationTypes.length > 0) {
            this.observationTypeOptions.push({
                value: null,
                text: this.$t('observation_collections.observation_type_select')
            })
            this.observationTypes.forEach(observationType => {
                if(observationType.id && observationType.name) {
                    this.observationTypeOptions.push({
                        value: observationType.id,
                        text: observationType.name
                    })
                }
            })
        }
        if(this.observationCollection && this.observationCollection.observation_types && this.observationCollection.observation_types.length > 0) {
            this.observationCollection.observation_types.forEach(observationType => {
                this.observationTypesSelected.push({
                    value: observationType.id,
                    text: observationType.name
                })
            })
        }
    },
    methods: {
        setDefaultValues: function (observationCollection) {
            if (!observationCollection.name) {
                EventBus.$emit('show-alert', this.$t('observation_collections.name_missing'))
                return undefined
            }
            if(this.observationTypesSelected) {
                let selectedIds = []
                this.observationTypesSelected.forEach(observationTypeSelected => {
                    selectedIds.push({id: observationTypeSelected.value})
                })
                observationCollection.observation_types = selectedIds
            }
            return observationCollection
        },

        submit: function () {
            var json = this.setDefaultValues(this.observationCollectionUpdate)
            if (json !== undefined) {
                this.loading = true
                if(json.id && json.id > 0) {
                    this.restUpdate(this.observationCollectionUrl, json, this.success, this.fail)
                } else {
                    this.restAdd(this.observationCollectionUrl, json, this.success, this.fail)
                }
            }
        },

        deleteObservationCollection: function () {
            this.deletion = false
            this.loading = true
            var url = this.observationCollectionUrl + '/' + this.observationCollectionUpdate.id
            this.restDelete(url, this.success, this.fail)
        },

        confirmDeleteObservationCollection() {
            if (!this.loading) {
                this.deletion = true
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
