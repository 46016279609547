<template>
    <div class="list-content result-table">
        <b-table
            show-empty
            small
            fixed
            :items="tableData"
            :fields="fields"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'contractor-vehicle-results',
    mixins: [restApi, timeUtils],
    props: {
        results: {
            type: Array
        }
    },
    data: function () {
        return {
            fields: [
                {key: 'make', label: this.$t('vehicle_list.make'), sortable: false},
                {key: 'vehicle_model', label: this.$t('vehicle_list.model'), sortable: false},
                {key: 'license_plate', label: this.$t('vehicle_defects.license_plate'), sortable: false},
                {key: 'inspection_status', label: this.$t('contractor_reports.inspection_status'), sortable: false}
            ],
            tableData: []
        }
    },
    mounted() {
        this.initializeData()
    },
    methods: {
        initializeData() {
            this.tableData = []
            this.results.forEach(vehicle => {
                let tableRow = []
                let cellVariants = {}
                tableRow['make'] = vehicle.make
                tableRow['vehicle_model'] = vehicle.vehicle_model
                tableRow['license_plate'] = vehicle.license_plate
                // TODO -  Inspection status
                tableRow['inspection_status'] = vehicle.inspection_status ? this.$t('common.ok') : this.$t('contractor_reports.inspection_missing')
                if (!vehicle.inspection_status) {
                    cellVariants['inspection_status'] = 'danger'
                }
                tableRow['_cellVariants'] = cellVariants
                this.tableData.push(tableRow)
            })
        }
    }
}
</script>
