<template>
    <div class="details-container">
        <div class="dynamic_width nopads" style="background-color: #FFFFFF; margin: auto">
            <div class="col-12 p-2" ><h3 class="nopads">{{ $t('common.import_from_file')}}</h3></div>
            <div style="position: relative; height: 40vh; padding: .2em; text-align: left">
                <map-container
                    ref="mapContainer"
                    :find-user="false"
                    :center="mapCenter"
                    @onMapChanged="mapChanged"
                />
            </div>
            <div class="col-sm-12 p-1 pt-3">
                <b-form-group
                    class="title"
                >
                    <b-form-file
                        size="sm"
                        v-model="importFile"
                        :accept="'csv'"
                        :placeholder="$t('common.attachment_placeholder')"
                    />
                </b-form-group>
            </div>
            <div class="col-sm-12 p-2">
                <span class="span-title">{{ $t('street_lights.station') }}</span>
                <b-form-select id="lightStation" size="sm" v-model="selectedStation" :options="stations">
                    <template #first>
                        <option :value="null">{{ $t('street_lights.select_station') }}</option>
                    </template>
                </b-form-select>
            </div>

            <div class="col-12 p-1 pt-3 pb-3 button-container">
                <b-button
                    size="sm"
                    class="form-button"
                    variant="secondary"
                    :disabled="loading"
                    @click.stop="$emit('close')">
                    {{ $t('common.cancel')}}
                </b-button>
                <b-button
                    size="sm"
                    class="form-button"
                    variant="success"
                    :disabled="loading || !importedCables || importedCables.length < 1 || !this.selectedStation"
                    @click.stop="submitCables">
                    {{ $t('common.save')}}
                </b-button>
            </div>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from "@/event-bus";
import {mapHelper} from '../mixins/MapMixin'
import {cableHelper} from "@/components/mixins/CableMixin";
import MapContainer from "@/components/map/MapContainer";

export default {
    name: 'CableImporter',
    components: {MapContainer},
    mixins: [restApi, mapHelper, cableHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            importFile: null,
            importedCables: null,
            mapCenter: null,
            map: null,
            selectedStation: null,
            stations: []
        }
    },

    watch: {
        selectedStation: function () {
            if (this.importedCables) {
                this.setStationAndPurpose()
            }
        },
        importFile() {
            this.submitFile()
        }
    },

    mounted() {
        if(this.getMapSettingsIn(['center'])){
            let center = this.getMapSettingsIn(['center'])
            this.mapCenter = {
                y: center.lat,
                x: center.lng
            }
        }
        this.$nextTick(() => {
            this.map = this.$refs.mapContainer.getMap()
        })
        this.fetchStations()
    },

    methods: {
        setStationAndPurpose() {
            this.importedCables.forEach(cable => {
                cable.station = this.selectedStation ? {id: this.selectedStation } : null
                cable.purpose = this.PURPOSE_LIGHT
            })
        },
        mapChanged(map) {
            this.map = map
        },
        submitFile() {
            this.hideCables()
            if (this.importFile) {
                let formData = new FormData()
                this.loading = true
                formData.append('importFile', this.importFile)
                this.restPostWithFile(this.cableImportUrl, {}, formData,
                    (response) => {
                        this.loading = false
                        this.importedCables = response
                        this.setStationAndPurpose()
                        this.drawCables()
                    }, this.fail
                )
            }
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        drawCables: function () {
            // Zoom to cables
            if (this.importedCables && this.importedCables.length > 0) {
                this.importedCables.forEach(function (cable, index) {
                    var points = cable.geometry.points.map(point => {
                        return {x: point[0], y: point[1]}
                    })
                    this.map.drawPolyLine(index+1, this.CABLE, points, this.getTypeColor(cable.type),
                        this.isDashed(cable.type), false, this.getWidth(cable.type))
                }, this)
                this.map.zoomToGroup(this.CABLE)
            }
        },
        hideCables: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.CABLE)
            }
        },
        submitCables() {
            if (this.importedCables && this.importedCables.length > 0) {
                this.restAdd(this.cableBulkUrl, this.importedCables,
                    () => {
                        this.loading = false
                        this.importFile = null
                        this.hideCables()
                        EventBus.$emit('show-alert', this.$t('cables.import_successful'), 'success')
                    }, this.fail
                )
            }
        },
        fetchStations() {
            this.loading = true
            this.stations = []
            this.restFetch(this.powerStationUrl, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            if (response && response.data) {
                response.data.forEach(station => {
                    this.stations.push({value: station.id, text: station.id + ' ' + station.location_name})
                })
            }
            this.loading = false
        }
    }
}
</script>
