<template>
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <div
                v-if="results"
                id="forwarder"
                class="col-sm-12 list-content result-table"
            >
                <b-table id="forwarderTable"
                         hover
                         show-empty
                         small
                         :items="items"
                         :fields="fields"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         :bordered="true"
                >
                    <template v-slot:cell(selected)="row">
                        <b-form-group>
                            <div class="text-center">
                                <input type="checkbox"
                                       v-model="row.item.selected"
                                       :value="false"
                                       @change="selectedItem(row.item)"
                                />
                            </div>
                        </b-form-group>
                    </template>
                    <template slot="head(selected)">
                        <div class="text-center">
                            <i class="fa fa-trash"/>
                        </div>
                    </template>
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('forest_work_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
        <div class="col-sm-12 button-container">
            <b-button
                type="submit"
                variant="danger"
                class="result-button"
                size="sm"
                :hidden="selectedReports.length === 0"
                @click="deleteReports"
            >
                {{ $t('forest_work_report.delete_selected') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {EventBus} from "@/event-bus";
import {restApi} from "../mixins/RestApiMixin";

export default {
    name: 'ForwarderWorkReportList',
    mixins: [timeUtils, restApi],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'time',
            sortDesc: true,
            fields: [
                {key: 'id', label: this.$t('forest_work_report.report_id'), sortable: true},
                {key: 'reportedBy', label: this.$t('forest_work_report.reporter'), sortable: true},
                {key: 'vehicle', label: this.$t('forest_work_report.vehicle'), sortable: true},
                {key: 'time', label: this.$t('forest_work_report.time'), sortable: true},
                {key: 'contractName', label: this.$t('forest_work_report.contract'), sortable: true},
                {key: 'total_amount', label: this.$t('forest_work_report.wood_amount'), sortable: true},
                {key: 'fuel_consumption', label: this.$t('forest_work_report.fuel_consumption'), sortable: true},
                {key: 'average_distance', label: this.$t('forest_work_report.distance'), sortable: true},
                {key: 'selected', label: "Delete", sortable: false}
            ],
            items: [],
            selectedReports: [],
        }
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        initTableItems: function () {
            this.items = []
            if (this.results) {
                this.results.forEach(function (item) {
                    this.items.push({
                            id: item.id,
                            time: item.time,
                            contract: item.contract,
                            contractName: item.contract.name,
                            reportedBy: item.reporter.first_name + ' ' + item.reporter.last_name,
                            average_distance: item.average_distance,
                            fuel_consumption: item.fuel_consumption,
                            total_amount: item.total_amount,
                            vehicle: item.vehicle.make + ' ' + item.vehicle.license_plate
                        }
                    )
                }, this)
            }
        },

        selectedItem: function (report) {
            if (report.selected) {
                this.selectedReports.push(report.id)
            } else {
                this.selectedReports.splice(this.selectedReports.indexOf(report.id), 1)
            }
        },

        deleteReports: function () {
            this.selectedReports.forEach(function (reportId) {
                var url = this.forwarderWorkReportUrl + '/' + reportId
                this.restDelete(url, this.success, this.fail)
            }, this)

            this.selectedReports = []
        },

        success: function (id) {
            let deletedItem = -1;

            this.results.forEach(function (item, index) {
                if (item.id === id) {
                    deletedItem = index
                }
            }, this)

            if (deletedItem !== -1) {
                this.results.splice(deletedItem, 1)
            }
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        }
    }
}
</script>
