<template>
    <div class="col-sm-12 list-content result-table">
        <b-table
            hover
            show-empty
            small
            selectable
            :items="stations"
            :fields="fields"
            @row-clicked="editItem"
        >
            <template slot="empty">
                <div class="text-center my-2">
                    {{ $t('common.no_results') }}
                </div>
            </template>
            <template
                v-slot:cell(id)="row"
            >
                <span>{{ row.value }}</span>
            </template>
            <template
                v-slot:cell(name)="row"
            >
                <span>{{ row.value }}</span>
            </template>

            <template v-slot:cell(station_scales)="row">
            <span v-if="row.item.station_scales && row.item.station_scales.length > 0">
              {{ $t('common.yes') }}
            </span>
                <span v-else>
              {{ $t('common.no') }}
            </span>
            </template>

            <template v-slot:cell(station_materials)="row">
                <div
                    v-for="result of row.item.station_materials"
                    :key="result.id">
                    {{ result.material.name }}
                </div>
            </template>

        </b-table>
    </div>
</template>

<script>
export default {
    name: 'material-station-list',
    props: {
        stations: {
            type: Array,
            default: function () {
                return []
            }
        },
    },
    data: function () {
        return {
            fields: [
                {key: 'id', label: this.$t('common.id'), sortable: true},
                {key: 'name', label: this.$t('material_station.name'), sortable: true},
                {key: 'station_scales', label: this.$t('material_station.scales'), sortable: true},
                {key: 'station_materials', label: this.$t('material_station.materials'), sortable: true}
            ]
        }
    },
    methods: {
        editItem: function (station) {
            let r = this.stations.find(item => item.id === station.id)
            if (r) {
                this.$emit('rowClicked', r)
            }
        }
    }
}
</script>
