<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-content pl-2 pr-2">
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="tableItems"
                    :fields="fields"
                    :bordered="true"
                    @row-clicked="emitEdit"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(id)="row">
                        {{ row.item.id }}
                    </template>
                    <template v-slot:cell(code)="row">
                        {{ getForestCodeNumberAndName(row.item.code) }}
                    </template>
                    <template v-slot:cell(task_type)="row">
                        {{ row.item.task_type.name }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '@/components/mixins/TimeUtils'

export default {
    name: 'ConversionTaskTypeModelList',
    mixins: [timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        codes: {
            type: Array,
            default() {
                return []
            }
        },
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            tableItems: [],
            loading: false
        }
    },
    computed: {
        fields() {
            return [
                {key: 'id', label: 'Id', sortable: true},
                {key: 'code', label: this.$t('tasktypes.conversion.code'), sortable: true},
                {key: 'task_type', label: this.$t('tasktypes.conversion.task_type'), sortable: true},
            ]
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    methods: {
        initTableItems() {
            this.tableItems = []
            if (this.results) {
                this.results.forEach(item => {
                    this.tableItems.push(item)
                })
            }
        },
        getForestCodeNumberAndName(code) {
            let codeFound = code ? this.codes.find(item => item.id === code.id) : null
            return codeFound ? codeFound.name + ' ' + '(' + codeFound.code + ')' : ""
        },
        emitEdit: function (result) {
            let item = this.results.find(item => result.id === item.id)
            if (item) {
                this.$emit('edit', item)
            }
        },
    },
}
</script>
