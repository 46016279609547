export const cableHelper = {
    data: function () {
        return {
            TYPE_GROUND: 43,
            TYPE_AIR: 44,
            TYPE_WATER: 45,
            TYPE_GROUND_BACKUP: 46,

            PURPOSE_LIGHT: 1,
            PURPOSE_OTHER: 10,

            purposeOptions: [
                {value: 1, text: this.$t('cables.purpose_light')},
                {value: 10, text: this.$t('cables.purpose_other')}
            ]
        }
    },
    computed: {
        cableTypeOptions() {
            return [
                {value: null, text: this.$t('common.unknown')},
                {value: this.TYPE_AIR, text: this.$t('cables.type_air')},
                {value: this.TYPE_GROUND, text: this.$t('cables.type_ground')},
                {value: this.TYPE_WATER, text: this.$t('cables.type_water')},
                {value: this.TYPE_GROUND_BACKUP, text: this.$t('cables.type_ground_backup')}
            ]
        }
    },
    methods: {
        getTypeString(type) {
            switch (type) {
                case this.TYPE_AIR: return this.$t('cables.type_air')
                case this.TYPE_GROUND: return this.$t('cables.type_ground')
                case this.TYPE_WATER: return this.$t('cables.type_water')
                case this.TYPE_GROUND_BACKUP: return this.$t('cables.type_ground_backup')
            }
            return this.$t('common.unkonwn')
        },

        getPurposeString(purpose) {
            switch (purpose) {
                case this.PURPOSE_LIGHT: return this.$t('cables.purpose_light')
                case this.PURPOSE_OTHER: return this.$t('cables.purpose_other')
            }
            return this.$t('common.unkonwn')
        },

        getWidth(type) {
            switch (type) {
                case this.TYPE_AIR: return 3
                case this.TYPE_GROUND_BACKUP: return 5
            }
            return 4
        },

        getTypeColor(type) {
            switch (type) {
                case this.TYPE_AIR: return '#4af8f2'
                case this.TYPE_GROUND: return 'rgba(96,59,51,0.71)'
                case this.TYPE_WATER: return '#224462'
                case this.TYPE_GROUND_BACKUP: return '#888787'
            }
            return '#404041'
        },

        isDashed(type) {
            return type !== this.TYPE_AIR
        }

    }
}
