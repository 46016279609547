export const units = {
    KILOGRAMS: 'kg',
    TONS: 't',
    METERS: 'm',
    SQUARE_METERS: 'm2',
    CUBIC_METERS: 'm3',
    KILOGRAMS_PER_SQUARE_METRE: 'kg/m2',
    KILOGRAMS_PER_CUBIC_METRE: 'kg/m3'
}

export const weighingNoteHelper = {
    methods: {
        newWeighingNote: function () {
            return {
                id: -1,
                order: {
                    id: -1
                },
                contract: {
                    id: -1
                },
                amount: null,
                unit: -1,
            }
        },

        jsonToItem: function (item) {
            return JSON.parse(JSON.stringify(item))
        }
    }
}
