<template>
    <div class="col-sm-12">
        <div class="report-title m-2">{{ $t('road_reports.total_observations') }}</div>
        <span class="item-detail-text col-sm-3">{{ reportData.total_pcs }}</span>
        <b-table class="mt-2" small :items="items" :fields="fields"></b-table>
    </div>
</template>

<script>
export default {
    name: "RoadReportSummary",
    props: {
        reportData: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            items: []
        }
    },
    computed: {
        fields() {
            return [
                {key: 'obs_type', label: this.$t('road_reports.type')},
                {key: 'count', label: this.$t('road_reports.count')},
                {key: 'len', label: this.$t('road_reports.length')}
            ]
        }
    },
    mounted: function () {
        this.items = []
        for (const [typeName, type] of Object.entries(this.reportData.obs_types)) {
            this.items.push({
                obs_type: typeName,
                count: type.pcs,
                len: type['length'] >= 0 ? Math.round(type['length']) : ''
            })
        }
    }
}
</script>

<style scoped>

</style>
