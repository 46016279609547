<template>
    <div class="col-sm-12">
        <h3>{{ $t('ins_wizard.select_vehicle') }}</h3>
        <div
            id="vehicle-list"
            class="col-sm-12 nopads"
        >
            <div
                v-for="result of results"
                :key="result.id"
                @click.stop="$emit('onVehicleSelected', result)"
                class="data-list-item"
            >
                <div class="col-sm-12 item-header">
                    <div class="item-img-cont">
                        <font-awesome-icon
                            :icon="getIcon(result.vehicle_type)"
                        />
                    </div>
                    <div class="item-text-cont">
                        <span class="item-title">{{ result.license_plate }}</span>
                        <span class="item-text">{{ result.make }} {{ result.vehicle_model }}
              </span>
                    </div>

                </div>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>

import {restApi} from "../../mixins/RestApiMixin"
import {vehicleHelper} from '../../mixins/VehicleMixin'

export default {
    name: 'vehicle-selector',
    mixins: [restApi, vehicleHelper],
    data() {
        return {
            results: [],
            loading: false
        }
    },
    created() {
        this.fetchVehicles()
    },
    methods: {
        fetchVehicles: function () {
            this.loading = true
            this.results = []
            this.restFetch(this.vehicleUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        }
    }
}
</script>
