<template>
    <vue-multiselect
        :value="value"
        @input="val => $emit('input', val)"
        :placeholder="placeholder || this.$t('common.select_company')"
        :deselect-label="$t('common.remove_selection')"
        :select-label="$t('common.select')"
        :close-on-select="true"
        :multiple="false"
        label="name"
        track-by="name"
        :searchable="true"
        :internal-search="true"
        :selected-label="$t('common.selected')"
        :options="companies">
    </vue-multiselect>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import VueMultiselect from "vue-multiselect/src/Multiselect";

export default {
    name: "CompanySelector",
    components: {VueMultiselect},
    mixins: [restApi],
    props: {
        value: {
            type: Object
        },
        placeholder: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            companies: []
        }
    },
    mounted() {
        this.fetchCompanies()
    },
    methods: {
        async fetchCompanies() {
            this.companies = (await new Promise(resolve => this.restFetch(this.companyUrl, resolve))).data
        }
    }
}
</script>

<style scoped>

</style>
