<template>
    <div v-if="results && results.id">
        <div class="stat-container p-2">
            <b-row class="nopads pt-1 stat-header pb-2">
                <b-col>
                    {{ summaryItem.order.task_type.name }}
                    {{ summaryItem.order.executors && summaryItem.order.executors.length > 0 ? '(' + formatExecutors(summaryItem.order.executors) + ')' : '' }}
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button
                        variant="outline-success"
                        class="result-button float-right"
                        @click="exportExcel"
                    >
                    {{ $t('trip.download_excel') }}
                    </b-button>
                </b-col>
            </b-row>
            <b-row class="nopads">
                <b-col>
                    {{ summaryItem && summaryItem.order && summaryItem.order.info ? summaryItem.order.info : '' }}
                </b-col>
            </b-row>
            <b-row class="nopads pt-1">
                <b-col class="nopads pl-1 pr-1 stat-title">
                    {{ $t('daily_summary.time_range') }}
                </b-col>
                <b-col class="nopads pl-1 pr-1 stat-title">
                    {{ $t('daily_summary.used_pricelist') }}
                </b-col>
                <b-col class="nopads pl-1 pr-1 stat-title">
                    {{ $t('daily_summary.price_list_sum_combined') }}
                </b-col>
                <b-col class="nopads pl-1 pr-1 stat-title">
                    {{ $t('daily_summary.price_list_weight_combined') }}
                </b-col>
            </b-row>
            <b-row class="nopads pt-1">
                <b-col class="nopads pl-1 pr-1 stat-text">
                    {{ getShortDateWithoutTimezone(searchParams.dateFrom) }} - {{ getShortDateWithoutTimezone(searchParams.dateTo) }}
                </b-col>
                <b-col class="nopads pl-1 pr-1 stat-text">
                    {{ results.name ? results.name : $t('daily_summary.no_pricelist_name') }}
                </b-col>
                <b-col class="nopads pl-1 pr-1 stat-text">
                    {{ totalSum.toFixed(2) }} €
                </b-col>
                <b-col class="nopads pl-1 pr-1 stat-text">
                    {{ totalWeight.toFixed(1) }} t
                </b-col>
            </b-row>
            <b-row class="nopads pt-1 pb-1">
                <b-col class="nopads pl-1 item-detail-title">
                    {{ $t('daily_summary.total_material_weight') }}
                </b-col>
                <b-col class="nopads pl-1 item-detail-text">
                    {{ totalMaterialWeight > 0 ? totalMaterialWeight + ' ' + this.results.weight_unit : null }}
                </b-col>
            </b-row>
            <b-row class="nopads pt-1 pb-1">
                <b-col class="nopads pl-1 item-detail-title">
                    {{ $t('daily_summary.total_material_sum') }}
                </b-col>
                <b-col class="nopads pl-1 item-detail-text">
                    {{ totalMaterialSum > 0 ? totalMaterialSum.toFixed(2) + ' ' + "€" : null }}
                </b-col>
            </b-row>
        </div>

        <b-table
            v-if="fields && fields.length > 0"
            size="sm"
            class="mt-3"
            show-empty
            :small="true"
            :responsive="true"
            :fields="fields"
            :items="tableItems"
            style="cursor: pointer"
        >
            <template slot="empty">
                <div class="text-center my-2">
                    {{ $t('common.no_results') }}
                </div>
            </template>
            <template v-slot:cell(price)="row">
                {{ (row.item.price * results.multiplier / 100).toFixed(2) }}
            </template>
            <template v-slot:cell(sum)="row">
                {{ row.value && row.value > 0 ? row.value.toFixed(2) : '' }}
            </template>
            <template v-slot:cell(material_combined)="row">
                {{ row.value && row.value > 0 ? row.value.toFixed(2) : '' }}
            </template>
        </b-table>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {deliveryNoteHelper, units} from "@/components/mixins/DeliveryNoteMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {downloadHelper} from "@/components/mixins/DownloadMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import {EventBus} from "../../event-bus";

export default {
    name: "DeliveryNotePriceList.vue",
    mixins: [deliveryNoteHelper, units, restApi, timeUtils, downloadHelper],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        notes: {
            type: Array,
            default() {
                return []
            }
        },
        summaryItem: {
            type: Object,
            default: null
        },
        items: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            fields: [],
            tableItems: [],
            totalWeight: 0,
            totalSum: 0,
            totalMaterialWeight: 0,
            totalMaterialSum: 0,
            results: {},
            loading: false
        }
    },
    watch: {
        items() {
            this.fetchDeliveryPriceList()
        }
    },
    mounted() {
        this.fetchDeliveryPriceList()
    },
    methods: {
        initTableItems: function () {
            this.tableItems = []
            this.totalWeight = 0
            this.totalSum = 0
            this.totalMaterialWeight = 0
            this.totalMaterialSum = 0
            let lastPrice = null
            this.results && Object.keys(this.results).length !== 0 && this.notes && this.notes.length > 0 &&
            this.results.prices.forEach(item => {
                if (item.distance_to) {
                    let weight = this.getWeightAndMaterial(item.distance_from, item.distance_to, this.notes,
                        this.results.weight_unit, this.results.distance_unit)
                    let sum = 0
                    // let material_sum = null
                    if (item.distance_to) {
                        sum = this.getSum(weight.amount, item.price * this.results.multiplier/ 100)
                    }
                    this.totalWeight += weight.amount
                    this.totalSum += sum
                    let tableItem = {
                        distance_from: item.distance_from,
                        distance_to: item.distance_to,
                        multiplier: (item.price * this.results.multiplier / 100).toFixed(3),
                        weight: weight.amount.toFixed(2),
                        sum: sum,
                        material_combined: 0
                    }
                    if(weight && weight.amount > 0 && weight.materials.length > 0 && this.notes && this.notes.length > 0 ) {
                        weight.materials.forEach(material => {
                            if(material.id && material.weight > 0) {
                                const propertyName = `material${material.id}`;
                                tableItem[propertyName] = material.weight.toFixed(2);
                                tableItem.material_combined += material.weight;
                                this.totalMaterialWeight += material.weight
                            }
                        })
                        let materialSumCombined = this.getSum(tableItem.material_combined, item.price * this.results.multiplier/ 100)
                        tableItem.material_sum_combined = materialSumCombined.toFixed(2)
                        this.totalMaterialSum += materialSumCombined
                    }
                    this.tableItems.push(tableItem)
                    lastPrice = item
                } else if (lastPrice) {
                    let lastRowSum = 0
                    let lastRowWeight = 0
                    let materials = []
                    this.notes.forEach(note => {
                        let distance = 0
                        let sum = 0
                        if (note.trips) {
                            note.trips.forEach(trip => {
                                distance += trip.mode === 3 ? trip.length : 0
                            })
                        }
                        if (distance >= item.distance_from) {
                            sum += this.getSumWithLast(note.amount, distance, item.distance_from, item.price, this.results.multiplier, lastPrice )
                            lastRowSum += sum
                            lastRowWeight += note.amount
                            this.totalWeight += note.amount
                            this.totalSum += sum
                            if(note.storage_material && note.storage_material.id) {
                                const existingMaterial = materials.find(mat => mat.id === note.storage_material.id);

                                if (existingMaterial) {
                                    // If material with the same id exists, add weight to it
                                    existingMaterial.weight += this.convertWeight(note.amount, this.results.weight_unit, note);
                                } else {
                                    // If material with the id doesn't exist, push a new material
                                    materials.push({
                                        id: note.storage_material.id,
                                        weight: this.convertWeight(note.amount, this.results.weight_unit, note)
                                    });
                                }
                            }
                        }
                    })
                    let tableItem = {
                        distance_from: item.distance_from,
                        distance_to: item.distance_to,
                        multiplier: (item.price * this.results.multiplier / 100).toFixed(3),
                        weight: lastRowWeight.toFixed(2),
                        sum: lastRowSum,
                        material_combined: 0
                    }
                    if(lastRowWeight > 0 && materials && materials.length > 0) {
                        materials.forEach(material => {
                            if(material.id && material.weight > 0) {
                                const propertyName = `material${material.id}`;
                                tableItem[propertyName] = material.weight;
                                tableItem.material_combined += material.weight
                                this.totalMaterialWeight += material.weight
                            }
                        })
                    }
                    let materialSum = this.getSum(tableItem.material_combined, item.price * this.results.multiplier/ 100)
                    tableItem.material_sum_combined = materialSum.toFixed(2)
                    this.totalMaterialSum += materialSum
                    this.tableItems.push(tableItem)
                }
            })
        },
        initFieldItems() {
            this.fields = [
                {key: 'distance_from', label: this.$t('daily_summary.price_list_from') +
                    ' ' + '(' + this.results.distance_unit + ')', sortable: true},
                {key: 'distance_to', label: this.$t('daily_summary.price_list_to') +
                        ' ' + '(' +  this.results.distance_unit + ')', sortable: true}]
            if (this.notes && this.notes.length > 0) {
                this.notes.forEach(note => {
                    let materialId = null
                    let label = null
                    if(note.storage_material) {
                        materialId = note.storage_material.id;
                        label = note.storage_material.name
                    }
                    if (note.manually_added_material) {
                        materialId = note.manually_added_material.material
                        label = note.manually_added_material.material
                    }
                    // Check if a field with the same materialId already exists
                    const existingField = this.fields.find(field => field.key === 'material' + materialId);

                    if (!existingField) {
                        // Create a new field for the materialId
                        const newField = {
                            key: 'material' + materialId,
                            label: label + ' (' + this.results.weight_unit + ')',
                            sortable: true,
                        };

                        // Push the new field to this.fields
                        this.fields.push(newField);
                    }
                });
            }
            this.fields = [
                ...this.fields,
                {key: 'multiplier', label: this.$t('daily_summary.price_list_multiplier'), sortable: true},
                {key: 'material_combined', label: this.$t('daily_summary.price_combined') + ' (' + this.results.weight_unit + ')', sortable: true},
                {key: 'material_sum_combined', label: this.$t('daily_summary.cost'), sortable: true},
            ]

        },
        formatExecutors(executors) {
            return executors.map(executor => executor.company.name).join(', ');
        },
        getWeightAndMaterial(distance_from, distance_to, notes, weight_unit, distance_unit) {
            let amount = 0
            let materials = []
            if (notes && notes.length > 0) {
                notes.forEach(note => {
                    let tripLengthSum = 0;
                    if (note.trips && note.trips.length > 0) {
                        note.trips.forEach(trip => {
                            if (trip.mode === 3) {
                                tripLengthSum += distance_unit === 'm' ? this.kilometersToMeters(trip.length) : trip.length
                            }
                        })
                    }
                    if (tripLengthSum > distance_from && tripLengthSum <= distance_to ||
                        tripLengthSum > distance_from && distance_to === undefined) {
                        // Handle both storage materials and manual materials
                        if (note) {
                            let mode = null
                            let materialId = null
                            if (note.storage_material && note.storage_material.id) {
                                mode = 1
                                materialId = note.storage_material.id
                            } else if (note.manually_added_material) {
                                mode = 2
                                materialId = note.manually_added_material.material
                            }
                            if (mode) {
                                const existingMaterial = materials.find(mat => mat.id === materialId && mat.mode === mode)
                                if (existingMaterial) {
                                    // If material with the same id exists, add weight to it
                                    existingMaterial.weight += this.convertWeight(note.amount, weight_unit, note);
                                } else {
                                    // If material with the id doesn't exist, push a new material
                                    materials.push({
                                        mode: mode, // storage
                                        id: materialId,
                                        weight: this.convertWeight(note.amount, weight_unit, note)
                                    });
                                }
                            }
                        }
                        if(note.unit === weight_unit) {
                            amount += note.amount
                        } else if(note.unit === units.TONS && weight_unit === units.KILOGRAMS) {
                            amount += this.tonsToKilograms(note.amount)
                        } else if(note.unit === units.KILOGRAMS && weight_unit === units.TONS) {
                            amount += this.kilogramsToTons(note.amount)
                        }
                    }
                })
            }
            return {amount: amount, materials: materials}
        },
        convertWeight(amount, weight_unit, note) {
            let weight = 0;
            if(note.unit === weight_unit) {
                weight = note.amount
            } else if(note.unit === units.TONS && weight_unit === units.KILOGRAMS) {
                weight = this.tonsToKilograms(note.amount);
            } else if(note.unit === units.KILOGRAMS && weight_unit === units.TONS) {
                weight = this.kilogramsToTons(note.amount);
            }
            return weight
        },
        getSum(weight, multiplier) {
            return multiplier * weight;
        },
        exportExcel: function () {
            this.loading = true
            let excelData = {
                data: {
                    table_items: this.tableItems,
                    fields: this.fields,
                }
            }
            this.restGetDownloadFile(this.orderUrl + "/" + "pricelist" + "/" + "report", this.getCommonReportParams(excelData), this.success, this.fail)
        },
        success: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('orders.order_pricelist_file_name'))
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        getSumWithLast(weight, distance, distanceFrom, price, multiplier, lastPrice) {
            let distanceDelta = distance - distanceFrom
            let basePriceValue = (weight * lastPrice.price * multiplier) / 100
            let addedPrice = (weight * price * multiplier * Math.ceil(distanceDelta)) / 100
            return basePriceValue + addedPrice
        },
        fetchDeliveryPriceList() {
            if (this.summaryItem) {
                this.loading = true
                    this.restFetch(
                        this.orderUrl + "/" + this.summaryItem.order.id + "/pricelist",
                        this.fetchPriceListSuccess,
                        this.fetchFail
                    )
            }
        },
        fetchPriceListSuccess: function (response) {
            this.results = response.data
            this.$emit('priceListSuccess')
            this.loading = false
            this.initTableItems()
            this.initFieldItems()
        },
        fetchFail: function () {
            this.loading = false
        }
    }
}
</script>
