export const apiKeyHelper = {
    methods: {
        newApiKey: function () {
            return {
                id: null,
                purpose: '',
                company: null,
                api_key: '',
                status: null,
                service_provider: null
            }
        },
        jsonToApiKey: function (item) {
            return JSON.parse(JSON.stringify(item))
        }
    }
}
