<template>
    <div
        class="details-container"
    >
        <div
            class="details dynamic_width" style="background: #f3f3f7"
        >
            <h1 class = "pl-3 pr-2">{{ $t('delivery_note.title')}}</h1>
            <div class="pl-3 pr-3">
                <b-row class="upper-text">
                    {{ summaryItem.contract }}
                </b-row>
                <b-row>
                    {{ summaryItem.order ? getOrderAndExecutorStr(summaryItem.order) : '-' }}
                </b-row>
                <b-row>
                    {{ summaryItem.vehicle }}
                </b-row>
                <b-row>
                    {{ summaryItem.driver }}
                </b-row>
                <b-row>
                    {{ summaryItem.delivery_tons.toFixed(2) }} t
                </b-row>
            </div>
            <b-tabs fill style="background: #FFFFFF; border: solid 1px #e1e1e1">
                <b-tab :title="$t('daily_summary.notes_list')" title-item-class="tab-title-class">
                    <delivery-note-list
                        :hide-borders="true"
                        :notes="notes"/>
                </b-tab>
                <b-tab :title="$t('daily_summary.cost_calculated')" title-item-class="tab-title-class">
                    <delivery-note-price-list
                        class="p-1"
                        v-if="notes.length > 0"
                        :notes="notes"
                        :summaryItem="summaryItem"
                    />
                </b-tab>
            </b-tabs>

            <div class="col-12 nopads pt-3 pr-3">
                <div class="button-container">
                    <b-button
                        variant="primary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                </div>
            </div>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>

    </div>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import {timeUtils} from '../mixins/TimeUtils'
import DeliveryNoteList from "@/components/materials/DeliveryNoteList";
import DeliveryNotePriceList from "@/components/materials/DeliveryNotePriceList";
import {orderMixin} from "@/components/mixins/OrderMixin";


export default {
    name: "ContractTripDeliveries",
    components: {DeliveryNoteList, DeliveryNotePriceList},
    mixins: [restApi, timeUtils, orderMixin],
    props: {
        summaryItem: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            notes: []
        }
    },
    mounted() {
        this.fetchDeliveryNotes()
    },
    methods: {
        fetchDeliveryNotes: function () {
            if (this.summaryItem) {
                this.loading++
                this.restFetch(
                    this.tripSummaryUrl + "/" + this.summaryItem.id + "/delivery",
                    this.fetchNotesSuccess,
                    this.fetchFail
                )
            }
        },
        fetchNotesSuccess: function (response) {
            this.notes = response.data
            this.loading--
        },
        fetchFail: function () {
            this.loading = false
        }
    }
}
</script>
<style>
.tab-title-class {
    font-weight: bold;
}
</style>
