<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showReportsList">
            <div class="header">
                <span class="title">{{ $t('menu.danger_reports') }}</span>
            </div>
        </b-navbar>

        <div class="search-form">
            <keep-alive>
                <search-filter
                    v-if="!detailsMode"
                    :is-admin="isAdmin"
                    :is-danger-report="true"
                    :user="user"
                    @search="fetchDangerReports"/>
            </keep-alive>
        </div>
        <div class="button-container nopads pr-3"
             v-if="!detailsMode"
        >
            <b-button
                variant="success"
                class="result-button"
                @click.stop="createDangerReport"
            >
                {{ $t('common.add_new') }}
            </b-button>
            <b-button
                variant="outline-success"
                class="result-button"
                @click="downloadDangerReportExcel"
                :disabled="results.length === 0"
            >
                {{ $t('common.download_excel') }}
            </b-button>
        </div>
        <keep-alive>
            <danger-report-list
                v-if="!detailsMode"
                :results="results"
                @details="showReportDetails"/>
        </keep-alive>

        <transition name="fade">
            <danger-report-details
                v-if="detailsMode"
                id="details"
                ref="details"
                :report="reportDetails"
                :is-observer="isObserver"
                @close="showReportsList"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <DangerReportModal
            @close="hideDangerReport"
            @cancel="hideDangerReport"
            @closeAndUpdate="hideDangerReport"
            :show-report="showDangerReportCreateForm"
        />
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import SearchFilter from '../search/SearchFilter'
import {restApi} from '../mixins/RestApiMixin'
import {downloadHelper} from '../mixins/DownloadMixin'
import DangerReportList from "./DangerReportList";
import DangerReportDetails from "./DangerReportDetails";
import {EventBus} from "@/event-bus";
import DangerReportModal from "@/components/dangerreports/DangerReportModal.vue";

export default {
    name: 'danger-reports',
    components: {DangerReportModal, DangerReportDetails, DangerReportList, SearchFilter},
    mixins: [restApi, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            loading: false,
            results: [],
            detailsMode: false,
            reportDetails: null,
            lastSearchedParams: null,
            showDangerReportCreateForm: false,
        }
    },
    methods: {

        createDangerReport: function () {
            this.showDangerReportCreateForm = true
        },
        hideDangerReport: function () {
            this.showDangerReportCreateForm = false
        },

        fetchDangerReports: function (params) {
            if (params) {
                this.loading = true
                this.lastSearchedParams = params
                this.restFetchParams(this.dangerReportUrl, params, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response) {
                this.results = response.data
            }
            this.loading = false
        },
        showReportDetails: function (details) {
            this.reportDetails = details
            this.detailsMode = true
        },
        showReportsList: function () {
            this.detailsMode = false
        },
        closeAndUpdate: function () {
            this.detailsMode = false
            this.fetchDangerReports(this.lastSearchedParams)
        },
        downloadDangerReportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.dangerReportUrl + '/excel',
                this.getCommonReportParams(this.lastSearchedParams),
                this.downloadSuccess,
                this.downloadFail)
        },
        downloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('danger_report.danger_report_file_name'))
        },
        downloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        }
    }
}
</script>
