<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 nopads p-2 pb-0 pt-4">
                <h3
                    v-if="dailyAllowanceUpdate.id > 0"
                    class="nopads"
                >
                    {{ $t('daily_allowance.edit') }}
                </h3>
                <h3
                    v-else
                    class="nopads"
                >
                    {{ $t('daily_allowance.add') }}
                </h3>
            </div>
            <div class="col-sm-12 form-data">
                <!-- Companies -->
                <div
                    v-if="isAdmin && dailyAllowanceUpdate.id<1"
                    class="nopads"
                >
          <span class="span-title">
            {{ $t('users.company') }}
          </span>
                    <b-form-select
                        id="company"
                        v-model="company"
                        size="sm"
                        class="mb-3"
                        :disabled="!companies || companies.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('users.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </b-form-select>
                </div>

                <!-- Users -->
                <div
                    v-if="dailyAllowanceUpdate.id < 1"
                    class="nopads"
                >
          <span class="span-title">
            {{ $t('work_time.user') }}
          </span>
                    <b-form-select
                        v-model="dailyAllowanceUpdate.user.id"
                        size="sm"
                        class="mb-3"
                        :disabled="!users || users.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('work_time.select_user') }}
                            </option>
                        </template>
                        <option
                            v-for="item in users"
                            :key="item.id"
                            :value="item.id"
                        >
                            {{ item.last_name }}, {{ item.first_name }}
                        </option>
                    </b-form-select>
                </div>
                <div v-else>
                    <h3>
                        {{ dailyAllowanceUpdate.user.first_name + ' ' + dailyAllowanceUpdate.user.last_name }}
                    </h3>
                </div>

                <!-- Start time -->
                <div class="col-sm-6 nopads">
          <span class="span-title">
            {{ $t('work_time.start') }}
          </span>
                    <datetime
                        v-model="dailyAllowanceUpdate.start_time"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>

                <!-- Start place -->
                <div class="col-sm-12 nopads">
          <span class="span-title">
            {{ $t('work_time.start_location') }}
          </span>
                    <b-form-textarea
                        id="start_location"
                        v-model="dailyAllowanceUpdate.start_place"
                        :placeholder="$t('work_time.start_location')"
                        :rows="1"
                        :max-rows="2"
                    />
                </div>

                <!-- End time -->
                <div class="col-sm-6 nopads">
          <span class="span-title">
            {{ $t('work_time.end') }}
          </span>
                    <datetime
                        v-model="dailyAllowanceUpdate.end_time"
                        type="datetime"
                        format="dd:MM:yyyy HH:mm"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>

                <!-- end place -->
                <div class="col-sm-12 nopads">
          <span class="span-title">
            {{ $t('work_time.end_location') }}
          </span>
                    <b-form-textarea
                        id="end_location"
                        v-model="dailyAllowanceUpdate.end_place"
                        :placeholder="$t('work_time.end_location')"
                        :rows="1"
                        :max-rows="2"
                    />
                </div>

                <!-- Note -->
                <div class="col-sm-12 nopads">
          <span class="span-title">
            {{ $t('work_time.note') }}
          </span>
                    <b-form-textarea
                        id="info"
                        v-model="dailyAllowanceUpdate.info"
                        :placeholder="$t('work_time.note')"
                        :rows="4"
                        :max-rows="6"
                    />
                </div>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>

                    <b-button
                        v-if="isObserver && dailyAllowanceUpdate.id>0"
                        variant="primary"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="approveAllowance"
                    >
                        <i class="fa fa-check"/>
                        <span class="button-text">
              {{ $t('daily_allowance.approve_allowance') }}
            </span>
                    </b-button>

                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {allowanceHelper} from '../mixins/AllowanceMixin'

export default {
    name: 'DailyAllowanceEditor',
    mixins: [restApi, allowanceHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean,
        dailyAllowance: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            company: null,
            companies: [],
            users: [],
            dailyAllowanceUpdate: this.dailyAllowance ? this.copyAndFormatObj(this.dailyAllowance) : this.newDailyAllowance(),
            note: '',
            PARTIAL_ALLOWANCE_HOURS: 6
        }
    },

    watch: {
        company: function () {
            if (this.company) {
                this.fetchUsers()
            }
        }
    },

    mounted: function () {
       this.fetchUsers()
    },

    methods: {
        fetchCompanies: function () {
            if (this.isAdmin) {
                this.loading = true
                this.restFetch(this.companyUrl, this.handleCompanies)
            }
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        fetchUsers: function () {
            if (this.isAdmin) {
                this.restFetchParams(this.userUrl, {company: this.company}, this.handleUsers)
            } else {
                this.restFetch(this.userUrl, this.handleUsers)
            }
        },

        handleUsers: function (response) {
            this.users = response.data
            this.loading = false
        },

        submit: function () {
            if (!this.dailyAllowanceUpdate.start_time) {
                EventBus.$emit('show-alert', this.$t('daily_allowance.error_start_missing'))
                return
            }
            if (!this.dailyAllowanceUpdate.end_time) {
                EventBus.$emit('show-alert', this.$t('daily_allowance.error_end_missing'))
                return
            }
            let hours = this.diffInHours(this.dailyAllowanceUpdate.end_time, this.dailyAllowanceUpdate.start_time)
            if (hours < this.PARTIAL_ALLOWANCE_HOURS) {
                EventBus.$emit('show-alert', this.$t('daily_allowance.not_enough_hours'))
                return
            }
            this.loading = true
            let json = JSON.parse(JSON.stringify(this.dailyAllowanceUpdate))
            if (json.user) {
                json.user = {id: json.user.id}
            }
            if (json.id > 0) {
                this.restUpdate(this.dailyAllowanceUrl, json, this.success, this.fail)
            } else {
                this.restAdd(this.dailyAllowanceUrl, json, this.success, this.fail)
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function (response) {
            this.loading = false
            if (response.status === 406) {
                EventBus.$emit('show-alert', this.$t('daily_allowance.error_overlapping'))
            } else {
                if (this.dailyAllowanceUpdate.id < 0) {
                    EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
                } else {
                    EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
                }
            }
        },
        approveAllowance: function () {
            this.loading = true
            this.restAdd(this.dailyAllowanceApproveUrl + this.dailyAllowanceUpdate.id, null, this.success, this.fail)
        },
        diffInHours: function (dt2, dt1) {
            var diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000
            diff /= (60 * 60)
            return diff
        }
    }
}
</script>
