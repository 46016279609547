
export const edgeSupportHelper = {
    methods: {
        getEdgeSupportTypes: function () {
            return [
                {name: this.$t('edge_support.types.natural_stone'), value: edgeSupportTypes.NATURAL_STONE},
                {name: this.$t('edge_support.types.concrete_and_composite'), value: edgeSupportTypes.CONCRETE_AND_COMPOSITE},
                {name: this.$t('edge_support.types.immersed_concrete'), value: edgeSupportTypes.IMMERSED_CONCRETE},
                {name: this.$t('edge_support.types.adhesive'), value: edgeSupportTypes.ADHESIVE},
                {name: this.$t('edge_support.types.slip_cast_concrete'), value: edgeSupportTypes.SLIP_CAST_CONCRETE},
                {name: this.$t('edge_support.types.special'), value: edgeSupportTypes.SPECIAL},
                {name: this.$t('edge_support.types.wood'), value: edgeSupportTypes.WOOD},
                {name: this.$t('edge_support.types.asphalt'), value: edgeSupportTypes.ASPHALT},
                {name: this.$t('edge_support.types.metal'), value: edgeSupportTypes.METAL},
                {name: this.$t('edge_support.types.recycled'), value: edgeSupportTypes.RECYCLED},
                {name: this.$t('edge_support.types.nailed_concrete'), value: edgeSupportTypes.NAILED_CONCRETE},
                {name: this.$t('edge_support.types.unknown'), value: edgeSupportTypes.UNKNOWN},
            ]
        },
        getEdgeSupportAdditionalTypes: function () {
            return [
                {name: this.$t('edge_support.additional_types.plug_or_other_traffic_diffider'), value: edgeSupportAdditionalTypes.PLUG_OR_OTHER_TRAFFIC_DIVIDER},
                {name: this.$t('edge_support.additional_types.protection_road_island'), value: edgeSupportAdditionalTypes.PROTECTION_ROAD_ISLAND},
                {name: this.$t('edge_support.additional_types.channeling_island_or_raised_central_area'), value: edgeSupportAdditionalTypes.CHANNELING_ISLAND_OR_RAISED_CENTRAL_AREA},
                {name: this.$t('edge_support.additional_types.rotary_island'), value: edgeSupportAdditionalTypes.ROTARY_ISLAND},
                {name: this.$t('edge_support.additional_types.sidewalk'), value: edgeSupportAdditionalTypes.SIDEWALK},
                {name: this.$t('edge_support.additional_types.other'), value: edgeSupportAdditionalTypes.OTHER},
            ]
        },
        getEdgeSupportMaterials: function () {
            return [
                {name: this.$t('edge_support.materials.select_material'), value: null},
                {name: this.$t('edge_support.materials.natural_stone'), value: edgeSupportMaterials.NATURAL_STONE},
                {name: this.$t('edge_support.materials.other'), value: edgeSupportMaterials.OTHER},
                {name: this.$t('edge_support.materials.concrete'), value: edgeSupportMaterials.CONCRETE},
                {name: this.$t('edge_support.materials.asphalt'), value: edgeSupportMaterials.ASPHALT},
            ]
        },
    }
}

export const edgeSupportTypes = {
    NATURAL_STONE: 1,
    CONCRETE_AND_COMPOSITE: 2,
    IMMERSED_CONCRETE: 3,
    ADHESIVE: 4,
    SLIP_CAST_CONCRETE: 5,
    SPECIAL: 6,
    WOOD: 7,
    ASPHALT: 8,
    METAL: 9,
    RECYCLED: 10,
    NAILED_CONCRETE: 11,
    UNKNOWN: 12
}

export const edgeSupportAdditionalTypes = {
    PLUG_OR_OTHER_TRAFFIC_DIVIDER: 1,
    PROTECTION_ROAD_ISLAND: 2,
    CHANNELING_ISLAND_OR_RAISED_CENTRAL_AREA: 3,
    ROTARY_ISLAND: 4,
    SIDEWALK: 5,
    OTHER: 6,
}

export const edgeSupportMaterials = {
    OTHER: 8,
    CONCRETE: 9,
    NATURAL_STONE: 12,
    ASPHALT: 18,
}



