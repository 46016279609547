<template>
    <div class="w-100">
        <div class="map-item-filter">
            <div
                class="col-sm-12 nopads vehicle-list"
                v-bind:class="{ list_closed: !listOpen }"
            >
                <span
                    class="vehicle-list-title"
                    @click.stop="toggleList">
                {{ $t('menu.traffic_control') }} ({{ typeEntries ? typeEntries.length : 0 }})
                </span>
                <div
                    class="vehicle-list-caret"
                    v-bind:class="{ caret_open: listOpen }"
                    @click.stop="toggleList">
                    <font-awesome-icon icon="caret-down"/>
                </div>
                <div class="col-sm-12 nopads vehicle-list-container" v-if="listOpen">
                    <div v-if="selectedContractIds && selectedContractIds.length > 0" class="badge-filters__wrapper">
                        <div class="badge-filters">
                            <b-badge
                                v-for="item in timeFilters"
                                :key="item.value"
                                class="ml-2"
                                @click="toggleTimeFilter(item.value)"
                                :variant="isActiveTimeFilter(item.value) ? 'primary' : 'light'"
                                style="padding: .5em 1em"
                            >
                                {{ item.text }}
                            </b-badge>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <traffic-control-modal
            v-if="trafficControlItem"
            :traffic-control-item="trafficControlItem"
            @close="closeTrafficControlItem"
            @closeAndUpdate="closeAndUpdateTrafficControlItems"
        />
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />

    </div>

</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import TrafficControlModal from '@/components/trafficcontrol/TrafficControlModal'
import {restApi} from '../mixins/RestApiMixin'
import {promisify} from "../../modules/promise";
import  {geometryMixin} from "@/components/mixins/GeometryMixin";

const STATUS = {
    ALL: 0,
    OPEN: 1,
    CLOSED: 2,
}

const INIT_STATUS = STATUS.OPEN

export default {
    name: 'TrafficControlMap',
    components: {
        TrafficControlModal
    },
    mixins: [timeUtils, mapHelper, restApi, geometryMixin],
    props: {
        trafficControlItemResults: {
            type: Array,
            default() {
                return null
            }
        },
        map: {
            type: Object,
            default: null
        },
        zoomOnDrawMarker: {
            type: Boolean,
            default: false
        },
        zoomToTrafficControlItems: {
            type: Boolean,
            default: false
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
        selectedOrder: {
            type: Number,
            default: null
        },
        boundingBox: {
            type: Array,
            default: null
        },
        requireBoundingBox: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            detailsMode: false,
            editMode: false,
            trafficControlItems: [],
            showTrafficControlEditorModal: false,
            visibleTrafficControlItems: [],
            typeEntries: [],
            selectedTypes: [],
            trafficControlItem: null,
            listOpen: false,
            allSelected: true,
            indeterminate: false,
            visibilityZoomLevel: 9,
            selectedTimeFilter: INIT_STATUS,
            timeFilters: [
                {
                    text: this.$t("common.all"),
                    value: STATUS.ALL,
                },
                {
                    text: this.$t("common.open"),
                    value: STATUS.OPEN,
                },
                {
                    text: this.$t("common.closed"),
                    value: STATUS.CLOSED,
                },
            ],
        }
    },

    watch: {
        map() {
            this.$nextTick(function () {
                if (this.trafficControlItemResults && this.trafficControlItemResults.length > 0) {
                    this.initView()
                } else if (this.isBBoxValid(this.boundingBox)) {
                    this.fetchTrafficControlItems(true)
                }
            })
        },
        selectedTypes(newVal) {
            if (newVal.length === 0) {
                this.indeterminate = false
                this.allSelected = false
            } else if (newVal.length === this.typeEntries.length) {
                this.indeterminate = false
                this.allSelected = true
            } else {
                this.indeterminate = true
                this.allSelected = false
            }
            this.filterByTypes()
        },
        boundingBox() {
            if (!this.trafficControlItemResults && this.map && this.isBBoxValid(this.boundingBox)) {
                this.fetchTrafficControlItems()
            }
        },
        selectedContractIds() {
            if (!this.trafficControlItemResults && this.map) {
                this.fetchTrafficControlItems(true)
            }
        },
        selectedOrder() {
            if (!this.trafficControlItemResults && this.map) {
                this.fetchTrafficControlItems(true)
            }
        },
        requireBoundingBox: {
            type: Boolean,
            default: false
        },
        'trafficControlItem.geometry': {
            handler: function () {
                if (this.trafficControlItem && (!this.trafficControlItems && this.trafficControlItems.length < 1)) {
                    this.drawTrafficControlItem(this.trafficControlItem)
                }
            },
            deep: true
        },
    },
    mounted: function () {
         if (this.trafficControlItemResults && this.trafficControlItemResults.length > 0) {
            this.trafficControlItems = this.trafficControlItemResults
            this.initView()
        } else if (this.map && (!this.requireBoundingBox || this.isBBoxValid(this.boundingBox))) {
            this.fetchTrafficControlItems()
        }
    },
    beforeDestroy() {
        this.hideTrafficControlItems()
    },
    methods: {
        fetchTrafficControlItems(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideTrafficControlItems()
            } else if (force || (!this.requireBoundingBox || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox))))) {
                this.loading = true
                let params = {}
                if (this.requireBoundingBox && this.boundingBox) {
                    const extendRate = 0.01
                    this.bbox = [
                        this.boundingBox[0] - extendRate,
                        this.boundingBox[1] - extendRate,
                        this.boundingBox[2] + extendRate,
                        this.boundingBox[3] + extendRate
                    ]
                    params.bbox = this.bbox
                }
                if(this.selectedContractIds) {
                    params.contract = this.selectedContractIds
                    if(this.selectedOrder) {
                        params.order = this.selectedOrder
                    }
                } else if(this.selectedContractId) {
                    params.contract = this.selectedContractId
                    if(this.selectedOrder) {
                        params.order = this.selectedOrder
                    }
                }
                this.restFetchParams(this.trafficControlMapUrl, params, this.handleTrafficControlItemsResponse, this.handleTrafficControlItemsError)
            }
        },

        handleTrafficControlItemsResponse: function (response) {
            this.trafficControlItems = []
            if (response && response.data) {
                this.trafficControlItems.push(...response.data)
            }
            this.initView()
            this.loading = false
        },

        handleTrafficControlItemsError: function () {
            this.loading = false
            this.initView()
        },

        async initView() {
            if (this.trafficControlItems || this.trafficControlItem) {
                this.hideTrafficControlItems()
            }
            if (this.trafficControlItems && this.trafficControlItems.length > 0) {
                this.visibleTrafficControlItems = this.trafficControlItems
                this.initTypeEntries()
                this.filterByTypes()
            } else if (this.trafficControlItem) {
                this.drawTrafficControlItem(this.trafficControlItem)
            }
            if (this.map && this.trafficControlItems && this.zoomToObservations) {
                await this.$nextTick()
                this.map.zoomToGroup(this.TRAFFIC_CONTROL_ITEM)
            }
        },

        drawTrafficControlItems: function () {
            this.visibleTrafficControlItems.forEach(function (trafficControlItem) {
                this.drawTrafficControlItem(trafficControlItem, false)
            }, this)
        },

        isClosed(item) {
            if (item && item.end_time) {
                let date = new Date(item.end_time)
                date.setHours(23,59,59)
                let now = new Date().getTime()
                return now > date.getTime()
            }
            return true;
        },

        drawTrafficControlItem: function (trafficControlItem) {
            var pointToZoom = null
            if (trafficControlItem.geometry.line_string) {
                var closed = this.isClosed(trafficControlItem)
                var color = closed ? '#6c6b6b' : '#fa9307'
                var points = trafficControlItem.geometry.line_string.points.map(point => {
                    return {x: point[0], y: point[1]}
                })
                this.map.drawPolyLine(trafficControlItem.id, this.TRAFFIC_CONTROL_ITEM, points, color, true, false, 5, 1, false, closed)
                pointToZoom = points[0]
            }
            if (pointToZoom && this.zoomOnDrawMarker) {
                this.map.zoomToPosition(pointToZoom.y, pointToZoom.x)
            }
        },

        hideTrafficControlItems: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.TRAFFIC_CONTROL_ITEM)
            }
            this.visibleTrafficControlItems = []
            this.showDetails = false
        },

        async onPolylineTap(data) {
            if (data.type !== this.TRAFFIC_CONTROL_ITEM) {
                return;
            }
            if (this.trafficControlItem && data.id === this.trafficControlItem.id && this.detailsMode) {
                this.detailsMode = false;
                return;
            }
            this.loading = true;
            const response = await promisify(this.restFetch)(this.trafficControlDetailsUrl + '/' + data.id)
                .catch(this.handleError)
                .finally(() => this.loading = false);
            if (!response || !response.data) {
                return;
            }
            this.showTrafficControlItemDetails(response.data)
        },

        handleError: function () {
            // TODO - Indicate error somehow
        },

        showTrafficControlItemDetails: function (trafficControlItem) {
            this.trafficControlItem = trafficControlItem
            if (this.trafficControlItem) {
                this.detailsMode = true
            }
        },

        closeAndUpdate: function () {
            this.editMode = false
            this.fetchTrafficControlItems(true)
        },

        initTypeEntries: function () {
            this.typeEntries = []
            if (this.trafficControlItems && this.trafficControlItems.length > 0) {
                this.trafficControlItems.forEach(item => {
                    let existingType = this.typeEntries.find(element => element.value === item.type_id)
                    if (!existingType) {
                        this.typeEntries.push({text: item.name, value: item.type_id})
                    }
                })
                this.sortTypeEntries()
            }
            // By default, select all types
            if (!this.selectedTypes || this.selectedTypes.length < 1 || this.allSelected) {
                this.toggleAll(true)
            }
        },

        sortTypeEntries: function () {
            this.typeEntries.sort(function (a, b) {
                if (a.text < b.text) {
                    return -1;
                }
                if (a.text > b.text) {
                    return 1;
                }
                return 0;
            });
        },


        filterByTypes: function () {
            this.hideTrafficControlItems()
            this.visibleTrafficControlItems = []
            if (this.allSelected) {
                this.visibleTrafficControlItems = this.trafficControlItems
            } else {
                this.trafficControlItems.forEach(item => {
                    let selectedType = this.selectedTypes.find(element => element === item.type_id)
                    if (selectedType) {
                        this.visibleTrafficControlItems.push(item)
                    }
                })
            }
            this.$emit('visibleTrafficControlItemsChanged', this.visibleTrafficControlItems)
            this.drawTrafficControlItems()
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        setNewTrafficControlItem(item) {
            this.trafficControlItem = item
        },

        closeTrafficControlItem() {
            this.trafficControlItem = null
        },

        closeAndUpdateTrafficControlItems() {
            this.fetchTrafficControlItems(true)
            this.trafficControlItem = null
            this.$emit('removeMeasureRoad')
        },

        toggleAll(checked) {
            this.selectedTypes = []
            if (checked) {
                this.typeEntries.forEach(item => {
                    this.selectedTypes.push(item.value)
                })
            }
        },
        toggleTimeFilter(val) {
            this.selectedTimeFilter = val
        },
        isActiveTimeFilter(val) {
            return val === this.selectedTimeFilter
        },
        getVisibleTrafficControlItems() {
            return this.visibleTrafficControlItems
        }
    }
}
</script>

<style lang="scss" scoped>
.badge-filters {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    flex: 5;
    flex-wrap: wrap;

    & > * {
        cursor: pointer;
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
    }
}
</style>
