<template>
    <div style="display: block; height: auto; overflow-y: auto">
        <b-table
            :items="value"
            :responsive="true"
            :borderless="true"
            :small="true"
            :fields="fields"
        >
            <template v-slot:cell(id)="row">
                <div class="p-0 pt-1">
                    {{ row.value }}
                </div>
            </template>
            <template v-slot:cell(material)="row">
                <b-form-select
                    size="sm"
                    class="m-0"
                    v-model="row.item.material.id"
                    :state="row.item.material.id > 0"
                    :disabled="!isObserver && row.item.material.id > 0"
                >
                    <template slot="first">
                        <option :value="null" disabled>
                            {{ $t('material_station.material.select_material') }}
                        </option>
                    </template>
                    <option
                        v-for="material in materials"
                        :key="material.id"
                        :value="material.id"
                    >
                        {{ material.name }}
                    </option>
                </b-form-select>
            </template>
            <template v-slot:cell(type_test_code)="row">
                <b-form-input
                    v-model="row.item.type_test_code"
                    type="text"
                    class="m-0"
                    size="sm"
                    :state="row.item.type_test_code !== undefined && row.item.type_test_code.length > 0"
                />
            </template>
            <template v-slot:cell(edit)="row">
                <font-awesome-icon icon="trash" @click.stop="value.splice(value.indexOf(row.item), 1)"
                                   style="cursor: pointer; margin-top: .5em"/>
            </template>
        </b-table>
        <div class="button-container">
            <b-button
                variant="primary"
                class="form-button"
                @click.stop="value.push(newMaterial())"
            >
                <span>{{ $t('material_station.material.add_new') }}</span>
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: "MaterialList",
    mixins: [restApi],
    props: {
        value: {
            type: Array,
            default() {
                return [];
            }
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        isWorker: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            materials: [],
            loading: false
        }
    },
    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: this.$t('common.id'),
                    sortable: false
                },
                {
                    key: 'material',
                    label: this.$t('material_station.material.material'),
                    sortable: false
                },
                {
                    key: 'type_test_code',
                    label: this.$t('material_station.material.type_test_code'),
                    sortable: false
                },
                {
                    key: 'edit',
                    label: ''
                }
            ]
        }
    },
    created() {
        this.fetchMaterialTypes()
    },
    methods: {
        fetchMaterialTypes: function () {
            this.loading = true
            this.restFetch(this.materialTypeUrl, this.success, this.fail)
        },
        success: function (response) {
            this.loading = false
            this.materials = response.data
        },
        fail: function () {
            this.loading = false
        },
        newMaterial: function () {
            return {
                material: {
                    id: null
                },
                type_test_code: ''
            }
        }
    }
}
</script>


<style scoped lang="scss">

</style>
