<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-row>
            <div class="col-sm-6">
                <b-form-group
                    class="title"
                    label-for="type"
                >
                    <span class="span-title">{{ $t('material_station.name') }}</span>
                    <b-form-input
                        v-model="name"
                        type="text"
                        size="sm"
                    />

                </b-form-group>
            </div>
        </b-row>
        <div class="col-sm-12 button-container">
            <b-button type="submit"
                      variant="primary"
                      class="result-button"
                      size="sm"
                      @click.stop="emitSearch">
                {{ $t('ins_report.search') }}
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'material-station-search',
    data: function () {
        return {
            name: null,
        }
    },
    methods: {
        emitSearch: function () {
            this.$emit('search', {name: this.name})
        }
    }
}
</script>
