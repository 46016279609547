<template>
    <div class="col-sm-12">
        <bridge-search
            @search="fetchBridges"
        />

        <h3>{{ $t('ins_wizard.select_bridge') }}</h3>
        <transition name="fade">
            <!-- Show open contracts  -->
            <div v-if="results && results.length > 0" class="col-12 nopads">
                <div v-for="item in results"
                     v-bind:key="item.digiroad_id"
                     class="inspection-selector"
                     @click.stop="$emit('onBridgeSelected', item)">
                    <span class="title inspection-wizard-selector-text">{{ item.name }} - {{ item.digiroad_id }}</span>
                </div>
            </div>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import BridgeSearch from "@/components/bridge/BridgeSearch";

export default {
    name: 'inspection-bridge-selector',
    components: {BridgeSearch},
    mixins: [restApi],
    data: function () {
        return {
            results: [],
            loading: false
        }
    },
    mounted() {
        this.fetchBridges()
    },
    methods: {
        fetchBridges: function (params) {
            this.loading = true
            this.results = []
            this.restFetchParams(this.bridgeUrl, params, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        }
    }
}
</script>
