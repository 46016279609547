import { render, staticRenderFns } from "./ContractDangerReports.vue?vue&type=template&id=a7f00ca0"
import script from "./ContractDangerReports.vue?vue&type=script&lang=js"
export * from "./ContractDangerReports.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports