<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background nopads"
            type="dark"
        >
            <div v-if="!editMode" class="header nopads">
                <span class="title p-3">
                   {{ $t('menu.weighing_notes') }}
                </span>
            </div>
            <div
                v-else
                @click="closeEdit"
                class="header"
            >
                <span class="pointer">
                  <i class="fa fa-arrow-left"/>
                </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>
        <div class="col-sm-12 nopads">
            <transition-group name="fade">
                <div key="search-weighing-note-list">
                    <keep-alive>
                        <weighing-note-search
                            v-if="!editMode"
                            ref="noteSearch"
                            class="pl-2 pr-2"
                            :showOnlyMyContractsOption="true"
                            :contracts="searchContracts"
                            @search="fetchWeighingNotes"
                            @onlyUserOwnedContractSelected="onlyUserOwnedContractSet"
                        />
                    </keep-alive>
                    <div v-if="!editMode" class="col-sm-12">
                        <div
                            class="data-list-item add"
                            @click.stop="addNote"
                        >
                            <div class="item-img-cont add-title">
                                <i class="fa fa-plus"/>
                            </div>
                            <div class="item-text-cont">
                                <span class="item-title-add">{{ $t('common.add_new') }}</span>
                                <font-awesome-icon
                                    icon="file-contract"
                                    class="card-body-icon"
                                />
                            </div>
                        </div>
                    </div>
                    <keep-alive>
                        <weighing-note-list
                            v-if="!editMode"
                            :notes="notes"
                            :isAdmin="isAdmin"
                            :isObserver="isObserver"
                            @edit="editNote"
                        />
                    </keep-alive>
                </div>
            </transition-group>
            <transition name="fade">
                <weighing-note-editor
                    v-if="editMode"
                    :note="weighingNote"
                    :contracts="editorContracts"
                    @onlyUserOwnedContractSelected="onlyUserOwnedContractSet"
                    @closeAndUpdate="update"
                    @close="closeEdit"
                />
            </transition>
        </div>
        <div
            v-if="!!loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";
import {weighingNoteHelper} from "@/components/mixins/WeighingNoteMixin";
import WeighingNoteEditor from "@/components/materials/station/WeighingNoteEditor";
import WeighingNoteList from "@/components/materials/station/WeighingNoteList";
import WeighingNoteSearch from "@/components/materials/station/WeighingNoteSearch";

export default {
    name: "WeighingNotes",
    components: {WeighingNoteSearch, WeighingNoteEditor, WeighingNoteList},
    mixins: [restApi, weighingNoteHelper, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            notes: [],
            editorContracts: [],
            searchContracts: [],
            weighingNote: null,
            editMode: false,
            lastSearchParams: null,
            loading: 0,
            onlyUserOwnedContracts: 0
        }
    },
    mounted: function () {
        this.fetchContracts()
        this.weighingNote = this.newWeighingNote()
    },
    methods: {
        fetchWeighingNotes: function (params) {
            this.loading++
            if (!params) {
                params = {}
            } else {
                this.lastSearchParams = params
            }
            this.restFetchParams(this.weighingNoteUrl, this.lastSearchParams, this.fetchNotesSuccess, this.fetchFail)
        },
        fetchContracts: function (type=null) {
            this.loading++
            let params = {}
            if(parseInt(this.onlyUserOwnedContracts) === 1) {
                params.onlyUserOwnedContracts = 1
            }
            params.startDate = this.getStartOfTheDay(new Date())
            params.endDate = this.getEndOfTheDay(new Date())
            if(type=="search") {
                this.restFetchParams(this.contractOpenUrl, params, this.searchContractsSuccess, this.fetchFail)
            } else if(type=="editor") {
                this.restFetchParams(this.contractOpenUrl, params, this.editorContractsSuccess, this.fetchFail)
            } else {
                this.restFetchParams(this.contractOpenUrl, params, this.contractsSuccess, this.fetchFail)
            }
        },
        fetchNotesSuccess: function (response) {
            this.notes = response.data
            this.loading--
        },
        contractsSuccess: function (response) {
            this.searchContracts = response.data
            this.editorContracts = response.data
            this.loading--
        },
        searchContractsSuccess: function (response) {
            this.searchContracts = response.data
            this.loading--
        },
        editorContractsSuccess: function (response) {
            this.editorContracts = response.data
            this.loading--
        },
        fetchFail: function () {
            this.loading--
        },
        update: function () {
            this.closeEdit()
            if (this.lastSearchParams) {
                this.fetchWeighingNotes(this.lastSearchParams)
            } else {
                this.$nextTick(() => this.$refs.noteSearch.refreshParams())
            }

        },
        closeEdit: function () {
            this.editMode = false
            this.weighingNote = this.newWeighingNote()
        },
        editNote: function (note) {
            this.weighingNote = note
            this.editMode = true
        },
        addNote: function () {
            this.weighingNote = this.newWeighingNote()
            this.editMode = true
        },
        onlyUserOwnedContractSet: function(value, type) {
            this.onlyUserOwnedContracts = value
            this.fetchContracts(type)
        }
    }
}
</script>
