<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-content nopads">
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="tableItems"
                    :fields="fields"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(id)="row">
                        {{ row.item.id }}
                    </template>
                    <template v-slot:cell(tasktype)="row">
                        {{ row.item.contract_task_type.task_type.name }}
                    </template>
                    <template v-slot:cell(customer)="row">
                        {{ row.item.contract.customer.name }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.item.contract.name }}
                    </template>
                    <template v-slot:cell(contractor)="row">
                        {{ row.item.contract.contractor.name }}
                    </template>
                    <template v-slot:cell(executor)="row">
                        <div
                            v-for="result of row.item.executors"
                            :key="result.id">
                            {{ result.company.name }}
                        </div>
                    </template>
                    <template v-slot:cell(status)="row">
                        {{ getStatusString(row.item.status) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'

export default {
    name: 'StationOrderList',
    mixins: [workManagementHelper],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            sortBy: 'tasktype',
            sortDesc: false,
            tableItems: [],
            loading: false
        }
    },
    computed: {
        fields() {
            return [
                {key: 'id', label: 'Id', sortable: true},
                {key: 'tasktype', label: this.$t('orders.task_type'), sortable: true},
                {key: 'customer', label: this.$t('orders.customer'), sortable: true},
                {key: 'contract', label: this.$t('orders.contract'), sortable: true},
                {key: 'contractor', label: this.$t('orders.contractor'), sortable: true},
                {key: 'info', label: this.$t('orders.info'), sortable: true},
                {key: 'status', label: this.$t('orders.status'), sortable: true},
                {key: 'object_number', label: this.$t('orders.object_number'), sortable: true}
            ]
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    methods: {
        initTableItems() {
            this.tableItems = []
            if (this.results) {
                this.results.forEach(item => {
                    item._rowVariant = this.getRowVariant(item.status)
                    this.tableItems.push(item)
                })
            }
        },
        getRowVariant: function (status) {
            switch (true) {
                case this.isCancelled(status):
                    return 'danger'
                case this.isCompleted(status):
                    return 'success'
                case this.isOpen(status):
                    return 'primary'
                case this.isInProgress(status):
                    return 'warning'
            }
            return ''
        }
    },
}
</script>
