<template>
    <div id="surface_info">
        <div class="road-info-title">
            {{ $t('road_details.pavement') }}
        </div>
        <b-table
            small
            responsive
            borderless
            class="border-bottom"
            :fields="surfaceFields"
            :items="surfaceData"
        />
        <b-table
            responsive
            small
            borderless
            class="border-bottom"
            :fields="laneFields"
            :items="laneData"
        />
        <b-table
            small
            responsive
            borderless
            class="border-bottom"
            :fields="foundationFields"
            :items="foundationData"
        />
    </div>
</template>


<script>
export default {
    name: 'RoadLinkSurfaceInfo',
    props: {
        roadLinkInfo: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            surfaceData: [],
            surfaceFields: [
                {surface: this.$t('road_details.class')},
                {maintenanceClass: this.$t('road_details.maintenance_category')},
                {width: this.$t('road_details.width')}
            ],
            foundationData: [],
            foundationFields: [
                {name: this.$t('road_details.basis_handling')},
                {thickness: this.$t('road_details.basis_thickness')},
                {updated: this.$t('road_details.update')},
                {info: this.$t('road_details.definition')}
            ],
            laneData: [],
            laneFields: [
                {lane: this.$t('road_details.lane')},
                {surfaceName: this.$t('road_details.surface_name')},
                {class: this.$t('road_details.surface_class')},
                {start: this.$t('road_details.start')},
                {end: this.$t('road_details.end')}
            ]
        }
    },

    mounted: function () {
        this.initData()
    },

    methods: {

        initData: function () {
            if (this.roadLinkInfo) {
                this.initRoadLinkData()
                this.initFoundationData()
                this.initLaneData()
            }
        },

        initRoadLinkData: function () {
            this.surfaceData = [{
                surface: this.getSurfaceInfo(this.roadLinkInfo.surface),
                maintenanceClass: this.getSurfaceMaintenanceInfo(this.roadLinkInfo.surfaceMaintenance),
                width: this.getSurfaceWidthInfo(this.roadLinkInfo.surfaceWidth)
            }]
        },

        initLaneData: function () {
            this.laneData = []
            if (this.roadLinkInfo.laneSurface) {
                this.roadLinkInfo.laneSurface.forEach(obj =>
                    this.laneData.push({
                        track: obj.track,
                        lane: obj.laneNum,
                        start: obj.startDistance,
                        end: obj.endDistance,
                        surfaceName: obj.surfaceName,
                        class: obj.surfaceClassName
                    })
                )
            }
        },

        initFoundationData: function () {
            this.foundationData = []
            if (this.roadLinkInfo.foundation) {
                this.roadLinkInfo.foundation.forEach(obj =>
                    this.foundationData.push({
                        name: obj.foundationName,
                        thickness: obj.foundationThickness ? obj.foundationThickness : '-',
                        updated: obj.updated,
                        info: obj.info ? obj.info : '-'
                    })
                )
            }
        },

        getSurfaceInfo: function (surface) {
            var roadInfo

            surface = surface.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.surfaceClass === item.surfaceClass
                ))
            )

            roadInfo = surface.reduce(function (accumulator, currentValue) {
                if (accumulator.length > 0) {
                    accumulator += '\n'
                }
                return accumulator + currentValue.surfaceName
            }, '')

            if (surface.length < 1) {
                roadInfo += '-'
            }
            return roadInfo
        },

        /**
         * Returns surface maintenance content
         */
        getSurfaceMaintenanceInfo: function (maintenance) {
            if (maintenance && maintenance.length > 0) {
                return maintenance[0].className + ' (' + maintenance[0].class + ')'
            }
            return '-'
        },

        getSurfaceWidthInfo: function (surfaceWidths) {
            if (surfaceWidths && surfaceWidths.length > 0) {
                return surfaceWidths[0].width + ' cm'
            }
            return '-'
        }
    }
}
</script>
