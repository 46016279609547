<template>
    <div ref="container" class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">{{ $t('menu.data_import') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12">
            <b-row class="nopads mb-1" style="background: #FFFFFF; border-radius: 5px">
                <div class="col-sm-6 pt-1">
                    <span class="title">{{ $t('storm_sewers.sewers') }}</span>
                </div>
                <div class="col-sm-6">
                    <div class="button-container col-sm-12 trip-buttons">
                        <b-button
                            size="sm"
                            variant="outline-primary"
                            class="result-button"
                            :disabled="loading"
                            @click="selectedDataType = SEWERS"
                        >
                            {{ $t('common.import_from_file') }}
                        </b-button>
                    </div>
                </div>
            </b-row>
            <b-row class="nopads mb-1" style="background: #FFFFFF; border-radius: 5px">
                <div class="col-sm-6 pt-1">
                    <span class="title">{{ $t('storm_sewers.drains') }}</span>
                </div>
                <div class="col-sm-6">
                    <div class="button-container col-sm-12 trip-buttons">
                        <b-button
                            size="sm"
                            variant="outline-primary"
                            class="result-button"
                            :disabled="loading"
                            @click="selectedDataType = DRAINS"
                        >
                            {{ $t('common.import_from_file') }}
                        </b-button>
                    </div>
                </div>
            </b-row>

            <storm-sewer-importer
                v-if="selectedDataType === SEWERS"
                @close="selectedDataType = null"/>

            <storm-drain-importer
                v-if="selectedDataType === DRAINS"
                @close="selectedDataType = null"/>

        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import StormSewerImporter from "@/components/dataimport/StormSewerImporter";
import StormDrainImporter from "@/components/dataimport/StormDrainImporter";

export default {
    name: 'DataImporter',
    components: {StormDrainImporter, StormSewerImporter},
    mixins: [restApi],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            SEWERS: 1,
            DRAINS: 2,
            selectedDataType: null
        }
    },

    methods: {
    }
}
</script>
