<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="modelUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('pricing_model_conversion.edit_mode') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('pricing_model_conversion.add_mode') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <div
                    class="nopads"
                >
                    <span class="span-title">{{ $t('pricing_model_conversion.unit') }}</span>
                    <multiselect
                        v-model="unit"
                        :options="unitOptions"
                        :multiple="false"
                        :close-on-select="true"
                        label="text"
                        track-by="value"
                        :searchable="true"
                        :selected-label="$t('common.selected')"
                        :select-label="$t('common.select')"
                        :deselect-label="$t('common.remove_selection')"
                        :placeholder="$t('pricing_model_conversion.select_unit')"
                        :disabled="loading"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.text }}</span>
                  </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.text }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>

                <span class="span-title">{{ $t('pricing_model_conversion.code') }}</span>
                <multiselect
                    v-model="code"
                    :options="forestCodeOptions"
                    :multiple="false"
                    :close-on-select="true"
                    label="text"
                    track-by="value"
                    :searchable="true"
                    :selected-label="$t('common.selected')"
                    :select-label="$t('common.select')"
                    :deselect-label="$t('common.remove_selection')"
                    :placeholder="$t('pricing_model_conversion.select_code')"
                    :disabled="loading"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.text }}</span>
                  </span>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.text }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>
                <span class="span-title">{{ $t('pricing_model_conversion.pricing_model') }}</span>
                <multiselect
                    v-model="pricingModel"
                    :options="pricingModelOptions"
                    :multiple="false"
                    :close-on-select="true"
                    label="text"
                    track-by="value"
                    :searchable="true"
                    :selected-label="$t('common.selected')"
                    :select-label="$t('common.select')"
                    :deselect-label="$t('common.remove_selection')"
                    :placeholder="$t('pricing_model_conversion.select_pricing_model')"
                    :disabled="loading || priceList"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.text }}</span>
                  </span>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.text }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>

                <b-form-group
                    class="title mt-2"
                    label-for="price_list"
                >
                    <b-form-checkbox
                        id="price_list"
                        v-model="priceList"
                        size="mb"
                        :value="true"
                        :unchecked-value="false"
                    >
                        <span>{{ $t('pricing_model_conversion.price_list') }}</span>
                    </b-form-checkbox>
                </b-form-group>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="!canSubmit"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <b-modal
            v-model="deletion"
            size="sm"
            centered
            ok-variant="danger"
            :cancel-title="$t('common.no')"
            :ok-title="$t('common.yes')"
            @ok="deleteConversionModel()"
            @hide="deletion = false"
        >
            <div class="modal-body">
                <span>{{ $t('common.confirm_delete') }}</span>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {priceHelper} from "@/components/mixins/PricingMixin";
import {conversionModelHelper, units} from '@/components/mixins/ConversionMixin'
import {restApi} from '@/components/mixins/RestApiMixin'

export default {
    name: 'ConversionModelEditor',
    mixins: [conversionModelHelper, restApi, units, priceHelper],
    props: {
        model: {
            type: Object,
            default: null
        },
        conversionModels: {
            type: Array,
            default() {
                return []
            }
        },
        codes: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            unit: null,
            code: null,
            priceList: null,
            deletion: false,
            pricingModel: null,
            pricingModels: [],
            pricingModelOptions: [],
            modelUpdate: this.jsonToItem(this.model),
            unitOptions: [
                {text: this.$t('pricing_model_conversion.select_unit'), value: null},
                {text: units.KILOGRAMS, value: 1},
                {text: units.TONS, value: 2},
                {text: units.METERS, value: 3},
                {text: units.SQUARE_METERS, value: 4},
                {text: units.CUBIC_METERS, value: 5},
                {text: units.KILOMETERS, value: 6},
                {text: units.PCS, value: 7},
                {text: units.HOURS, value: 8},
            ]
        }
    },
    mounted: function () {
        this.fetchPricingModels()
        if (this.modelUpdate.id > 0) {
            this.unit = this.modelUpdate.unit ? this.unitOptions.find(item => item.text === this.modelUpdate.unit) : null
            this.priceList = !!this.modelUpdate.price_list
            if (this.modelUpdate.code && this.modelUpdate.code.id) {
                let foundCode = this.codes.find(item => item.id === this.modelUpdate.code.id)
                if (foundCode) {
                    this.code = {text: foundCode.name + ' ' + '(' + foundCode.code + ')', value: foundCode.code, id: foundCode.id}
                }
            }
        }
    },

    computed: {
        canSubmit() {
            if (this.loading || !this.unit) {
                return false;
            }
            if ((!this.pricingModel || !this.pricingModel.value) && !this.priceList) {
                return false;
            }
            return true
        },
        forestCodeOptions() {
            const nullVal = { value: null, text: this.$t('pricing_model_conversion.select_code') }
            if ((!this.codes || !Object.keys(this.codes).length) || this.loading) {
                return [nullVal]
            }
            return [
                nullVal,
                ...this.codes.map(item => ({
                    value: item.code,
                    id: item.id,
                    text: item.name + ' ' + '(' + item.code + ')',
                }))
            ]
        },
    },

    methods: {
        fetchPricingModels: function () {
            this.loading = true
            this.restFetch(this.pricingModeUrl, this.handlePricingModelsSuccess)
        },

        handlePricingModelsSuccess: function (response) {
            this.pricingModels = response.data
            this.handlePricingModels()
            this.loading = false
        },

        handlePricingModels() {
            this.pricingModelOptions = []
            // set empty option value
            this.pricingModelOptions.push({text: this.$t('pricing_model_conversion.select_pricing_model'), value: null})
            this.pricingModels && this.pricingModels.forEach(pricingModel => {
                if(this.modelUpdate.pricing_model && this.modelUpdate.pricing_model.id && pricingModel.id === this.modelUpdate.pricing_model.id) {
                    // set preselected pricing model
                    this.pricingModel = {text: this.pricingModelToText(pricingModel), value: pricingModel.id}
                }
                this.pricingModelOptions.push({text: this.pricingModelToText(pricingModel), value: pricingModel.id})
            })
        },

        setDefaultValues: function (model) {
            if (!this.unit || !this.unit.value) {
                EventBus.$emit('show-alert', this.$t('pricing_model_conversion.invalid_unit'))
                return undefined
            }
            if ((!this.pricingModel || !this.pricingModel.value) && !this.priceList) {
                EventBus.$emit('show-alert', this.$t('pricing_model_conversion.invalid_pricing_model'))
                return undefined
            }
            if(this.unit) {
                model.unit = this.unit.text
            }
            if(this.code && this.code.id) {
                model.code = this.codes.find(item => item.id === this.code.id)
            } else {
                model.code = null
            }
            if(this.priceList) {
                model.price_list = this.priceList
            } else {
                model.price_list = false
            }
            if(this.pricingModel && !this.priceList) {
                model.pricing_model = this.pricingModels.find(item => item.id === this.pricingModel.value)
            }
            if(this.checkForDuplicates(model)) {
                EventBus.$emit('show-alert', this.$t('pricing_model_conversion.duplicate_conversion'))
                return undefined
            }
            return model
        },

        submit: function () {
            let json = this.setDefaultValues(this.modelUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.modelUpdate.id < 1) {
                    this.restAdd(this.pricingModeConversionUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.pricingModeConversionUrl, json, this.success, this.fail)
                }
            }
        },

        checkForDuplicates(updateModel) {
            let duplicates = false
            if(this.conversionModels !== null) {
                this.conversionModels.every(cModel => {
                    let codeDefined = cModel.hasOwnProperty('code')
                    if(cModel.unit === updateModel.unit &&
                        // check if forestcode is defined and same as update forestcode
                        (codeDefined && updateModel.code && cModel.code.code === updateModel.code.code ||
                        // or check if forestcode is not defined and trying to update with null
                        !codeDefined && !updateModel.code) &&
                        // we also want to check if we are updating existing conversion model
                        cModel.id !== updateModel.id)
                    {
                        duplicates = true
                        return false;
                    }
                    return true
                })

            }
            return duplicates
        },

        deleteConversionModel: function () {
            this.deletion = false
            this.loading = true
            var url = this.pricingModeConversionUrl + '/' + this.modelUpdate.id
            this.restDelete(url, this.success, this.fail)
        },

        confirmDeleteConversionModel() {
            if (!this.loading) {
                this.deletion = true
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
