import {equipmentOidTypes} from '@/components/mixins/ConversionMixin'

export default class VelhoStairsModel {
    // Private properties
    /** @type {string} */
    #external_system_id = equipmentOidTypes.EXTERNAL_STAIRS

    constructor(
        railingType = null, code = "", condition = null, material = null, position = { x: null, y: null }, roadNum = null, roadSec = null, distance = null,
        equipmentDamages = [], locationDeviation = null, locationSpecification = { lane: [], side: null, tracks: null, central_area: null }, externalSystemId = ""
    ) {
        /** @type {number | null} */
        this.railing_type = railingType

        /** @type {string} */
        this.code = code

        /** @type {number | null} */
        this.condition = condition

         /** @type {number | null} */
         this.material = material
       
        /** @type {{ x: number, y: number }} */
        this.position = position

        /** @type {number | null} */
        this.road_num = roadNum

        /** @type {number | null} */
        this.road_sec = roadSec

        /** @type {number | null} */
        this.distance = distance

        /** @type {Array} */
        this.equipment_damages = equipmentDamages

        /** @type {number | null} */
        this.location_deviation = locationDeviation

        /** @type {{ lane: number[], side: number | null, tracks: number | null, central_area: number | null }} */
        this.location_specification = locationSpecification

        if(externalSystemId) {
            this.#external_system_id = externalSystemId
        }
    }

    /**
    * Gets the unique identifier.
    * @returns {string}
    */
    get external_system_id() {
        return this.#external_system_id
    }

    /**
    * Get the json object.
    * @returns {object}
    */
    toJSON() {
        return JSON.parse(
            JSON.stringify({
            external_system_id: this.#external_system_id,
            condition: this.condition,
            material: this.material,
            position: this.position,
            road_num: this.road_num,
            road_sec: this.road_sec,
            distance: this.distance,
            code: this.code,
            railing_type: this.railing_type,
            equipment_damages: this.equipment_damages,
            location_deviation: this.location_deviation,
            location_specification: {
                ...this.location_specification,
                tracks: this.location_specification.tracks !== null ? [this.location_specification.tracks] : []
            }
        }))
    }

    /**
    * Create default instance with position data.
    * @param {{ x: number, y: number }} position - coordinates.
    * @param {number | null} roadNum - Road number.
    * @param {number | null} roadSec - road section.
    * @param {number | null} distance - distance (road geometry).
    * @returns {VelhoStairsModel}
    */
    static instanceWithPosition(position, roadNum, roadSec, distance) {
        const instance = new VelhoStairsModel()
        instance.position = position
        instance.road_num = roadNum
        instance.road_sec = roadSec
        instance.distance = distance
        return instance
    }
}