<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <b-row class="nopads">
            <b-col cols="10" class="nopads">
                <span class="vehicle-list-title ml-2">
                {{ title }}
                </span>
            </b-col>
            <b-col cols="2" class="nopads">
                <div
                    class="vehicle-list-caret"
                    @click.stop="close">
                    <font-awesome-icon icon="times-circle"/>
                </div>
            </b-col>
            </b-row>

            <div class="col-sm-12 nopads vehicle-list-container">
                <b-row v-if="roadGeometry">
                    <span class="text-light p-2" style="font-size: 1.4em"> {{ roadLength }} m</span>
                </b-row>
                <b-row class="nopads">
                    <b-col class="p-1 text-right">
                        <b-button
                            v-if="roadGeometry && showMakeNewTrafficControlButton"
                            variant="primary"
                            size="md"
                            class="m-1"
                            @click.stop="createNewTrafficControlItem"
                        >
                            <span class="map-button-text">{{ $t('map.new_traffic_control_entity') }}</span>
                        </b-button>
                        <b-button
                            v-if="roadGeometry && !hideMakeNewObservationButton"
                            variant="primary"
                            size="md"
                            class="m-1"
                            @click.stop="confirmSelection"
                        >
                            <span class="map-button-text">{{ buttonText }}</span>
                        </b-button>
                        <b-button
                            class="m-1"
                            variant="info"
                            size="md"
                            @click.stop="reset"
                        >
                            <span class="map-button-text">{{ $t('common.clear') }}</span>
                        </b-button>
                        </b-col>
                </b-row>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import {getDistance} from 'geolib'
import {EventBus} from '@/event-bus'


export default {
    name: 'MeasureRoadMap',
    mixins: [timeUtils, mapHelper, restApi],
    props: {
        map: {
            type: Object,
            default: null
        },
        hideMakeNewObservationButton: {
            type: Boolean,
            default: false
        },
        showMakeNewTrafficControlButton: {
            type: Boolean,
            default: false
        },
        infoVisible: {
            type: Boolean,
            default: true
        },
        confirmText: {
            type: String,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            startPoint: null,
            endPoint: null,
            roadGeometry: null,
            roadLength: 0,
            buttonText: null
        }
    },

    computed: {
        title() {
            if (!this.startPoint) {
                return this.$t('map.measure_road_set_start')
            }
            if (!this.endPoint) {
                return this.$t('map.measure_road_set_end')
            }
            if (this.roadGeometry) {
                return this.$t('map.measure_road_length')
            }
            return '';
        }
    },

    mounted() {
        if (!this.confirmText) {
            this.buttonText = this.$t('map.create_observation')
        } else {
            this.buttonText = this.confirmText
        }
    },

    methods: {
        close() {
            this.reset()
            this.$emit('close')
        },

        reset() {
            this.roadGeometry = null
            this.startPoint = null
            this.endPoint = null
            this.map.removeMapMarkerByType(this.MEASURE_ROAD)
            this.map.removePolyline(3, this.MEASURE_ROAD)
        },

        addMeasurePoint(coord) {
            if (!this.startPoint) {
                this.startPoint = coord
                this.drawPoint(this.startPoint, 1)
            } else if (!this.endPoint) {
                this.endPoint = coord
                this.drawPoint(this.endPoint, 2)
                this.fetchRoad()
            } else {
                if(coord && coord.id) {
                    switch (coord.id) {
                        case 1:
                            this.startPoint = {lat: coord.position.y, lng: coord.position.x}
                            this.drawPoint(this.startPoint, 1)
                            break;
                        case 2:
                            this.endPoint = {lat: coord.position.y, lng: coord.position.x}
                            this.drawPoint(this.endPoint, 2)
                            break;
                    }
                    this.fetchRoad()
                }
            }
        },

        fetchRoad() {
            this.loading = true
            this.roadGeometry = null
            this.restFetchParams(this.roadGeometryPointsUrl, {
                startLat: this.startPoint.lat,
                startLon: this.startPoint.lng,
                endLat: this.endPoint.lat,
                endLon: this.endPoint.lng
            }, this.handleResponse, this.onError)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response && response.data && response.data.geometry) {
                this.roadGeometry = response.data
                this.calculateRoadLength()
                this.drawRoad()
            }
        },

        onError() {
          // eslint-disable-next-line
            EventBus.$emit('show-alert', this.$t('map.error_road_geometry'))
            this.loading = false
            this.reset()
        },

        calculateRoadLength() {
            let totalLength = 0
            let lastPoint = undefined
            this.roadGeometry.geometry.forEach(item => {
                if (lastPoint) {
                    totalLength += getDistance(lastPoint, item)
                }
                lastPoint = item
            })
            this.roadLength = totalLength
        },

        drawPoint(point, id) {
            let icon = this.map.getMarkerStore().getMeasureDistanceMarkerIcon(this.map.getMapType() === 'LEAFLET')
            this.map.showMapMarker(id, this.MEASURE_ROAD, point.lat,
                point.lng, icon, true)
        },

        drawRoad() {
            var xyLine = this.roadGeometry.geometry.map(point => {
                return {x: point.lon, y: point.lat}
            })
            this.map.drawPolyLine(3, this.MEASURE_ROAD, xyLine, '#404041', false, false)
        },

        confirmSelection() {
            this.$emit('confirmSelection', this.roadGeometry.geometry, this.roadGeometry.properties)
        },

        createNewTrafficControlItem() {
            this.$emit('createNewTrafficControlItem', this.roadGeometry.geometry, this.roadGeometry.properties)
        }

    }
}
</script>
