<template>
    <div
        id="editor"
        class="col-sm-12 nopads"
    >
        <b-form v-if="documentUpdate" class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title"
            >
                <h2
                    v-if="documentUpdate.id  > 0"
                    class="form-title editor-title"
                >
                    {{editable ? $t('common.edit') : $t('general_guides.file')}}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('general_guides.add_new_guide') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <div
                    class="nopads"
                >
                    <span class="span-title">
                        {{ $t('general_guides.document_name') }}
                    </span>
                    <b-form-group
                        label-for="document-name"
                    >
                        <b-form-input
                            :disabled = "editable && documentUpdate.id !== -1"
                            id="document-name"
                            v-model="documentUpdate.name"
                            type="text"
                            size="sm"
                        />
                    </b-form-group>

                    <span class="span-title">
                        {{ $t('general_guides.category') }}
                    </span>
                    <b-input-group>
                        <b-form-select
                            id="sort-by-select"
                            size="sm"
                            style="margin-top: 3px"
                            v-model="category"
                            :options="categories"
                        >
                            <template #first>
                                <option value=null>{{ $t('general_guides.select_category') }}</option>
                            </template>
                        </b-form-select>
                        <b-button size="sm" class="form-button" variant="success" @click="showNewCategory = true">
                            {{ $t('common.add') }}
                        </b-button>
                    </b-input-group>

                    <span class="span-title">
                        {{ $t('general_guides.document_description') }}
                    </span>
                    <b-form-group
                        label-for="document-description"
                    >
                        <b-form-input
                            :disabled = "editable && documentUpdate.id !== -1"
                            id="document-description"
                            v-model="documentUpdate.description"
                            type="text"
                            size="sm"
                        />
                    </b-form-group>
                <div
                    v-if="documentUpdate.id === -1"
                >
                    <span class="span-title">
                        {{ $t('general_guides.choose_file') }}
                    </span>
                    <span class="tip-light">
                        (.pdf, .doc, .docx, .jpg, .jpeg, .png)
                    </span>
                            <b-form-group>
                                <b-form-file
                                    size="sm"
                                    accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                    v-model="attachments[0]"
                                    :placeholder="$t('common.attachment_placeholder')"
                                />
                            </b-form-group>
                        </div>
                    <div
                        v-else
                    >
                        <div v-if="documentUpdate.attachment" class="mb-2">
                            <span class="span-title">{{ $t('general_guides.attachment') }}</span>
                            <div class="pointer link mb-2"
                                 v-on:click="downloadAttachment(documentUpdate)"
                            >
                                {{ documentUpdate.attachment.file_original_name }}
                                <b-button
                                    variant="primary"
                                    size="sm"
                                    class="ml-3"
                                    @click.stop="downloadAttachment(documentUpdate)"
                                >
                                    <i class="fa fa-download"/>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="col-sm-12 button-container">
                        <b-button
                            v-if="documentUpdate.id > 0 && editable"
                            variant="danger"
                            class="routa-button"
                            :disabled="loading"
                            @click.stop="confirmDeleteSharedDocument(documentUpdate.id)"
                        >
                            <i class="fa fa-trash"/>
                            <span class="button-text">
                                {{ $t('common.remove') }}
                            </span>
                        </b-button>
                        <b-button
                            variant="secondary"
                            class="routa-button"
                            @click.stop="$emit('close')"
                        >
                            <i class="fa fa-times"/>
                            <span class="button-text">
                                {{ $t('common.cancel') }}
                            </span>
                        </b-button>
                        <b-button
                            v-if="editable || documentUpdate.id === -1"
                            variant="success"
                            class="routa-button"
                            :disabled="loading"
                            @click.stop="validateInputData()"
                        >
                            <i class="fa fa-save"/>
                            <span class="button-text">
                                {{ $t('common.save') }}
                            </span>
                        </b-button>

                    </div>
                </div>
        </b-form>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
        <b-modal
            v-model="deletion"
            size="sm"
            centered
            ok-variant="danger"
            :cancel-title="$t('common.no')"
            :ok-title="$t('common.yes')"
            @ok="deleteSharedDocument(documentUpdate.id)"
            @hide="deletion = false"
        >
            <div class="modal-body">
                <span>{{ $t('common.confirm_delete') }}</span>
            </div>
        </b-modal>
        <b-modal
            v-model="showNewCategory"
            size="sm"
            centered
            :cancel-title="$t('common.cancel')"
            :ok-title="$t('common.save')"
            ok-variant="success"
            :hide-header="true"
            :ok-disabled="loading"
            @ok="submitCategory"
            @hide="showNewCategory = false"
        >
            <div class="modal-body">
                <span>{{ $t('general_guides.category') }}</span>
                <b-form-input
                    v-model="newCategoryName"
                    type="text"
                    size="sm"/>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {attachmentHelper} from '../mixins/AttachmentMixin'
import {EventBus} from "@/event-bus";

export default {
    name: "GeneralGuidesEditor",
    mixins : [restApi, attachmentHelper],
    props: {
        editable: {
            type: Boolean,
            default: true
        },
        document: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            id: '',
            guideName: '',
            documentUpdate: null,
            status: '',
            loading: false,
            attachments: [],
            categories: [],
            category: null,
            deletion: false,
            showNewCategory: false,
            newCategoryName: null
        }
    },

    mounted() {
        this.documentUpdate = JSON.parse(JSON.stringify(this.document))
        if (this.documentUpdate.category) {
            this.category = this.documentUpdate.category.id
        }
        this.fetchCategories()
    },
    methods:{
        fetchCategories() {
            this.loading = true
            this.categories = []
            this.restFetch(this.sharedDocumentCategoryUrl, this.handleCategories)
        },
        handleCategories(response) {
            this.loading = false
            if (response && response.data) {
                response.data.forEach(cat => this.categories.push(
                    {
                        value: cat.id,
                        text: cat.name
                    }
                ))
            }
        },
        submitCategory() {
            if (!this.loading) {
                this.loading = true
                if (this.newCategoryName) {
                    this.restAdd(this.sharedDocumentCategoryUrl, {name: this.newCategoryName}, this.success, this.failed)
                }
            }
        },
        success() {
           this.loading = false
           this.fetchCategories()
        },
        failed() {
            this.loading = false
        },
        validateInputData() {
            this.loading = true
            if (this.category) {
                this.documentUpdate.category = {id: this.category}
            } else {
                this.documentUpdate.category = null
            }
            if (this.documentUpdate.name === '') {
                EventBus.$emit('show-alert', this.$t('general_guides.document_name_missing'))
                this.loading = false
            }
            else if (this.documentUpdate.attachment === null && this.attachments.length === 0) {
                EventBus.$emit('show-alert', this.$t('general_guides.attachment_missing'))
                this.loading = false
            } else if (this.documentUpdate.id === -1){
                let formData = new FormData()
                this.attachments.forEach(file => {
                    formData.append('attachments[]', file)
                })
                this.restPostWithFile(this.sharedDocumentUrl + "/attachment/validate", {}, formData,
                    () => {
                        this.submitDocument()
                    },
                    () => {
                        EventBus.$emit('show-alert', this.$t('general_guides.invalid_file_type'))
                        this.loading = false
                    }
                )
            } else {
                this.submitDocument()
            }
        },

        submitDocument: function() {
            this.loading = true
            if (this.documentUpdate.id > 0) {
                this.restUpdate(this.sharedDocumentUrl, this.documentUpdate, this.updateSuccess, this.updateFail)
            } else {
                this.restAdd(this.sharedDocumentUrl, this.documentUpdate, this.uploadSuccess, this.uploadFail)
            }

        },

        updateSuccess: function () {
            this.loading = false
            this.$emit('close')
        },

        updateFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        uploadSuccess: function (data) {
            this.status = data.id
            this.uploadAttachment(data.id)
        },

        uploadFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
            this.loading = false
        },

        uploadAttachment: function (id) {
            let formData = new FormData()
            this.attachments.forEach(file => {
                formData.append('attachments[]', file)
            })
            this.restPostWithFile(this.sharedDocumentUrl + "/attachment/" + id, {}, formData,
                () => {
                    this.loading = false
                    this.$emit('close')
                },
                () => {
                    this.loading = false
                    EventBus.$emit('show-alert', this.$t('general_guides.upload_failed'))
                }
            )
        },

        deleteSharedDocument: function (id) {
            this.loading = true
            this.restDelete(this.sharedDocumentUrl + "/attachment/" + id, this.deleteSuccess, this.deleteFail)
        },

        confirmDeleteSharedDocument: function () {
            if (!this.loading) {
                this.deletion = true
            }
        },

        deleteSuccess: function () {
            this.loading = false
            this.$emit('close')
        },

        deleteFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        downloadAttachment: function (document) {
            this.loading = true
            this.restFetch(this.sharedDocumentUrl + "/attachment/" + document.id + "/" + document.attachment.id,
                response => {
                    this.saveFile(document.attachment.file_original_name, response.data)
                    this.loading = false
                }, () => {
                    this.loading = false
                    EventBus.$emit('show-alert', this.$t('common.download_failed'))
                })
        },
    }
}
</script>

