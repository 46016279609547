<template>
    <div class="col-sm-12 nopads">
        <div class="list-content result-table">
            <b-table
                show-empty
                small
                :striped="!darkHeader ? 'striped' : null"
                :head-variant="darkHeader ? 'dark' : null"
                :thead-class="darkHeader ? 'white-text' : null"
                :items="recordData"
                :fields="fields"
            >
                <template v-slot:cell(vehicle)="row">
                    {{
                        row.value ? row.value.make + ' ' + row.value.vehicle_model + ' ' + row.value.license_plate : ''
                    }}
                </template>
                <template v-slot:cell(start_time)="row">
                    {{ toLocalTime(row.value) }}
                </template>
                <template v-slot:cell(width)="row">
                    {{ row.value ? row.value.toFixed(2) : '' }}
                </template>
                <template v-slot:cell(amount)="row">
                    {{ row.value ? row.value.toFixed(2) : '' }}
                </template>
                <template v-slot:cell(start_road_num)="row">
                    {{ row.value > 0 ? row.value : '' }}
                </template>
                <template v-slot:cell(start_road_sec)="row">
                    {{ row.value > 0 ? row.value : '' }}
                </template>
                <template v-slot:cell(start_distance)="row">
                    {{ row.value > 0 ? Math.round(row.value) : '' }}
                </template>
                <template v-slot:cell(end_road_num)="row">
                    {{ row.value > 0 ? row.value : '' }}
                </template>
                <template v-slot:cell(end_road_sec)="row">
                    {{ row.value > 0 ? row.value : '' }}
                </template>
                <template v-slot:cell(end_distance)="row">
                    {{ row.value > 0 ? Math.round(row.value) : '' }}
                </template>
                <template v-slot:cell(travel_distance)="row">
                    {{ row.value > 0 ? Math.round(row.value) : '' }}
                </template>
                <template v-slot:cell(surface_density)="row">
                    {{ calculateDensity(row.item) }}
                </template>
                <template v-slot:cell(object_number)="row">
                    {{
                        row.item.work_order && row.item.work_order.object_number > 0 ? row.item.work_order.object_number : ''
                    }}
                </template>
                <template v-slot:cell(task_type)="row">
                    {{ row.item.work_order ? row.item.work_order.task_type.name : '' }}
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {units} from "@/components/mixins/DeliveryNoteMixin";

export default {
    name: "line-unload-record-table",
    mixins: [timeUtils],
    props: {
        records: {
            type: Array,
            default() {
                return []
            }
        },
        darkHeader: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        recordData() {
            let data = this.records
            return data.sort((a, b) => {
                return new Date(a.start_time) - new Date(b.start_time)
            })
        }
    },
    data: function () {
        return {
            fields: [
                {start_time: {label: this.$t('delivery_note.date'), sortable: true}},
                {id: {label: this.$t('common.id'), sortable: true}},
                {vehicle: {labels: this.$t('contract_reports.unload_records.vehicle'), sortable: true}},
                {unload_method: {label: this.$t('contract_reports.unload_records.method'), sortable: true}},
                {start_road_name: {label: this.$t('contract_reports.unload_records.start_road_name'), sortable: true}},
                {start_road_num: {label: this.$t('contract_reports.unload_records.start_road_num'), sortable: true}},
                {start_road_sec: {label: this.$t('contract_reports.unload_records.start_road_sec'), sortable: true}},
                {
                    start_distance: {
                        label: this.$t('contract_reports.unload_records.start_road_distance'),
                        sortable: true
                    }
                },
                {end_road_name: {label: this.$t('contract_reports.unload_records.end_road_name'), sortable: true}},
                {end_road_num: {label: this.$t('contract_reports.unload_records.end_road_num'), sortable: true}},
                {end_road_sec: {label: this.$t('contract_reports.unload_records.end_road_sec'), sortable: true}},
                {end_distance: {label: this.$t('contract_reports.unload_records.end_road_distance'), sortable: true}},
                {travel_distance: {label: this.$t('contract_reports.unload_records.travel_distance'), sortable: true}},
                {width: {label: this.$t('contract_reports.unload_records.width'), sortable: true}},
                {amount: {label: this.$t('contract_reports.unload_records.amount'), sortable: true}},
                {unit: {label: this.$t('contract_reports.unload_records.unit')}},
                {surface_density: {label: units.KILOGRAMS_PER_SQUARE_METRE, sortable: true}},
                {object_number: {label: this.$t('orders.object'), sortable: true}},
                {task_type: {label: this.$t('work_report.task_type'), sortable: true}}
            ]
        }
    },
    methods: {
        calculateDensity: function (record) {
            // Calculate if necessary info provided and unit is tons
            if ((record.travel_distance > 0 && record.width > 0 && record.amount > 0) && record.unit === units.TONS) {
                // Tons to kilograms
                let amount = record.amount * 1000
                let area = record.travel_distance * record.width
                let density = amount / area
                return density.toFixed(2);
            }
            return ''
        }
    }
}
</script>
