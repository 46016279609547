<template>
    <div id="forest-work-report-tables"
         class="col-sm-12 list-container">

        <div class="col-sm-12 nopads">
            <hr class="report-divider"/>
            <b-row :hidden="!showForwarderTable">
                <div class="col-6 nopads">
                    <span class="title">{{ $t('forest_work_report.forwarder_table') }}</span>
                </div>
                <div class="col-6 button-container nopads pr-1">
                    <b-button
                        variant="outline-success"
                        class="result-button"
                        @click="exportExcelForwarder"
                        :disabled="forwarderResults.length === 0"
                    >
                        {{ $t('common.download_excel') }}
                    </b-button>
                </div>
                <transition name="fade">
                    <forwarder-work-report-list
                        :results="forwarderResults"/>
                </transition>
            </b-row>

            <div class="col-sm-12 nopads" :hidden="!showHarvesterTable">
                <hr class="report-divider"/>
                <b-row class="nopads">
                    <div class="col-6 nopads">
                        <span class="title">{{ $t('forest_work_report.harvester_table') }}</span>
                    </div>
                    <div class="col-6 button-container nopads pr-1">
                        <b-button
                            variant="outline-success"
                            class="result-button"
                            @click="exportExcelHarvester"
                            :disabled="harvesterResults.length == 0"
                        >
                            {{ $t('common.download_excel') }}
                        </b-button>
                    </div>
                    <transition name="fade">
                        <harvester-work-report-list
                            :results="harvesterResults"/>
                    </transition>
                </b-row>
            </div>

            <div class="col-sm-12 nopads">
                <hr class="report-divider"/>
                <span class="title">{{ $t('forest_work_report.contract_worktime_table') }}</span>
                <transition name="fade">
                    <forest-work-time-list
                        :is-admin="isAdmin"
                        :is-observer="isObserver"
                        :results="worktime"
                        @onReportDataUpdated="$emit('onReportDataUpdated')"/>
                </transition>
            </div>
        </div>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import ForestWorkTimeList from './ForestWorkTimeList'
import ForwarderWorkReportList from './ForwarderWorkReportList'
import HarvesterWorkReportList from './HarvesterWorkReportList'
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from "@/event-bus"
import {downloadHelper} from "../mixins/DownloadMixin"
import {vehicleTypes} from '../mixins/VehicleMixin'

export default {
    name: 'ForestWorkReportTables',
    components: {ForwarderWorkReportList, HarvesterWorkReportList, ForestWorkTimeList},
    mixins: [restApi, downloadHelper],
    props: {
        params: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        fetch: Boolean,
    },
    data: function () {
        return {
            loading: false,
            forwarderResults: [],
            harvesterResults: [],
            worktime: [],
            showForwarderTable: true,
            showHarvesterTable: true,
            forwarderEffLines: [],
            harvesterEffLines: [],
        }
    },

    mounted: function () {
        this.fetchReportData()
    },

    watch: {
        fetch: function () {
            this.fetchForestReports()
        }
    },

    methods: {
        fetchReportData: function () {
            if (this.params != null) {
                this.fetchForestReports()
            }
        },

        fetchForestReports: function () {
            this.loading = true
            this.forwarderResults = []
            this.worktime = []
            this.harvesterResults = []
            switch (this.params.vehicleType) {
                case 0:
                    this.showHarvesterTable = true
                    this.showForwarderTable = true
                    this.restFetchParams(this.forwarderWorkReportUrl, this.params, this.handleForwarderResponse)
                    this.restFetchParams(this.harvesterWorkReportUrl, this.params, this.handleHarvesterResponse)
                    break
                case vehicleTypes.FORWARDER:
                    this.restFetchParams(this.forwarderWorkReportUrl, this.params, this.handleForwarderResponse)
                    this.showHarvesterTable = false
                    this.showForwarderTable = true
                    break
                case vehicleTypes.HARVESTER:
                    this.restFetchParams(this.harvesterWorkReportUrl, this.params, this.handleHarvesterResponse)
                    this.showHarvesterTable = true
                    this.showForwarderTable = false
                    break
            }
            this.restFetchParams(this.workTimeContractUrl, this.params, this.handleWorkTimeResponse)
        },

        handleForwarderResponse: function (response) {
            if (response) {
                this.forwarderResults = response.data
            } else {
                this.forwarderResults = []
            }
            this.loading = false
        },

        handleWorkTimeResponse: function (response) {
            if (response) {
                this.worktime = response.data
            } else {
                this.worktime = []
                this.loading = false
            }
        },

        handleForwarderEffResponse: function (response) {
            if (response) {
                this.forwarderEffLines = response.data
            }
            this.loading = false
        },

        handleHarvesterEffResponse: function (response) {
            if (response) {
                this.harvesterEffLines = response.data
            }
            this.loading = false
        },

        handleHarvesterResponse: function (response) {
            if (response) {
                this.harvesterResults = response.data
            } else {
                this.harvesterResults = []
            }
            this.loading = false
        },

        exportExcelHarvester: function () {
            this.loading = true
            this.restGetDownloadFile(this.harvesterWorkReportExcelUrl, this.getCommonReportParams(this.params), this.successHarvester, this.fail)

        },
        exportExcelForwarder: function () {
            this.loading = true
            this.restGetDownloadFile(this.forwarderWorkReportExcelUrl, this.getCommonReportParams(this.params), this.successForwarder, this.fail)
        },
        successForwarder: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('forest_work_report.forwarder_report_file_name'))
        },
        successHarvester: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('forest_work_report.harvester_report_file_name'))
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
    }
}
</script>
