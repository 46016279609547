<template>
    <b-form>
        <span>{{ $t('contracts.customer') }}</span>
        <company-selector v-model="customer" :placeholder="this.$t('contracts.select_customer')"/>
        <span class="mt-2">{{ $t('contracts.choose_file') }}</span>
        <b-form-file class="mb-2" size="sm" v-model="file"/>
        <div class="button-container">
            <b-button
                variant="primary"
                class="m-1"
                @click.stop="sendFile"
                :disabled="!canSendFile"
            >
                {{ $t('common.send') }}
            </b-button>
            <b-button
                variant="danger"
                class="m-1"
                @click.stop="$emit('close')"
            >
                {{ $t('common.cancel') }}
            </b-button>
        </div>
    </b-form>
</template>

<script>
import {EventBus} from '@/event-bus'
import CompanySelector from '../company/CompanySelector'
import {restApi} from "../mixins/RestApiMixin";

export default {
    name: "ContractImporter",
    components: {CompanySelector},
    mixins: [restApi],
    data() {
        return {
            customer: null,
            format: 'UPM',
            file: null,
            loading: false
        }
    },
    computed: {
        canSendFile() {
            let customerValid = this.customer && !isNaN(this.customer.id)
            let fileValid = this.file != null
            return customerValid && fileValid && !this.loading
        }
    },
    methods: {
        async sendFile() {
            if (!this.canSendFile) {
                return
            }
            this.loading = true
            let formdata = new FormData()
            formdata.set('customer', this.customer.id)
            formdata.set('format', this.format)
            formdata.set('contracts', this.file)
            try {
                let response = await new Promise((resolve, reject) => this.restPostForm(this.contractXlsxUrl, formdata, resolve, reject));
                let len = 0
                if (Array.isArray(response)) {
                    len = response.length
                }
                this.loading = false
                EventBus.$emit('show-alert', this.$t('contracts.import_from_file_count', {count: len}), 'success')
                this.$emit('closeAndUpdate')
            } catch (e) {
                this.loading = false
                EventBus.$emit('show-alert', this.$t('contracts.import_from_file_failed'))
            }
        }
    }
}
</script>

<style scoped>

</style>
