export const spreaderDataMixin = {

    data() {
        return {
            NACL_DRY: 1,
            NACL_LIQUID: 2,
            CACL_DRY: 3,
            NA_FORM: 4,
            K_FORM: 5,
            SAND: 6,
            WATER: 7,
            NACL_DRY_FINE: 8,
            PURE_GRANULAR_SODIUM_FORMATE: 9,
            GRANULAR_SODIUM_FORMATE_AND_MELTIUM: 10,
            OTHER: 100
        }
    },

    methods: {
        getMaterialName(materialCode) {
            switch (materialCode) {
                case this.NACL_DRY_FINE:
                case this.NACL_DRY: return this.$t('spreader_data.material_nacl')
                case this.NACL_LIQUID: return this.$t('spreader_data.material_nacl_liquid')
                case this.CACL_DRY: return this.$t('spreader_data.material_cacl')
                case this.K_FORM: return this.$t('spreader_data.material_potassium')
                case this.SAND: return this.$t('spreader_data.material_sand')
                case this.WATER: return this.$t('spreader_data.material_water')
                case this.PURE_GRANULAR_SODIUM_FORMATE: return this.$t('spreader_data.material_pure_granular_sodium_formate')
                case this.GRANULAR_SODIUM_FORMATE_AND_MELTIUM: return this.$t('spreader_data.material_granular_sodium_formate_and_meltium')
            }
            return materialCode
        },
        getMaterialColor(materialCode) {
            switch (materialCode) {
                case this.NACL_DRY_FINE:
                case this.NACL_DRY: return '#986fc9'
                case this.NACL_LIQUID: return '#a89bd9'
                case this.CACL_DRY: return '#94d2d0'
                case this.K_FORM: return '#ccd250'
                case this.SAND: return '#da9a43'
                case this.WATER: return '#5f9cd3'
                case this.PURE_GRANULAR_SODIUM_FORMATE: return '#7bc2b3'
                case this.GRANULAR_SODIUM_FORMATE_AND_MELTIUM: return '#3fb098'
            }
            return "#bcbcbc"
        }    }
}
