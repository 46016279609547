<template>
    <div class="col-sm-12 nopads">
        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <open-contract-filter
                    :to="to"
                    :from="from"
                    :show-only-my-contracts-option="true"
                    :contract="contract"
                    @loading="$emit('loading')"
                    @loadingCompleted="$emit('loadingCompleted')"
                    @contractSelected="setContract"/>
            </div>
            <div class="col-sm-6 nopads pl-2">
                <b-form-group
                    class="title nopads"
                    label-for="contract"
                >
                    <span class="span-title">{{ $t('observations.order') }}</span>
                    <b-form-select
                        id="order"
                        v-model="selectedOrder"
                        size="sm"
                        :disabled="!contract"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('orders.select_order') }}
                            </option>
                        </template>
                        <option
                            v-for="option in orders"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.contract_task_type.task_type.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
        </b-row>

    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'
import OpenContractFilter from "../contract/OpenContractFilter";

export default {
    name: 'OpenOrderFilter',
    components: {OpenContractFilter},
    mixins: [timeUtils, restApi],
    props: {
        from: {
            type: String,
            default: null
        },
        to: {
            type: String,
            default: null
        },
        order: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            contract: null,
            selectedOrder: null,
            orders: []
        }
    },
    mounted: function () {
        if (this.order) {
            this.selectedOrder = this.order.id
            this.contract = this.order.contract
            this.fetchOrders()
        }
    },
    watch: {
        from: function () {
            this.fetchOrders()
        },
        to: function () {
            this.fetchOrders()
        },
        selectedOrder: function (value) {
            if (this.orders && this.orders.length > 0) {
                let order = this.orders.find(item => item.id === value)
                this.$emit('orderSelected', order)
            }
        }
    },
    methods: {
        fetchOrders: function () {
            if (this.from && this.contract) {
                this.orders = []
                this.restFetchParams(this.orderOpenUrl,
                    {
                        startDate: this.localTimeToUtcTime(this.from).toISOString(),
                        endDate: this.to ? this.getEndOfTheDay(this.to).toISOString() : undefined,
                        contract: this.contract.id
                    },
                    this.handleOrders)
            }
        },
        handleOrders: function (response) {
            if (response && response.data) {
                this.orders = response.data
            }
            if (this.selectedOrder) {
                let exists = this.orders.find(item => item.id === this.selectedOrder)
                if (!exists) {
                    this.selectedOrder = null
                }
            }
            this.$emit('loadingCompleted')
        },
        setContract: function (contract) {
            this.contract = contract
            if (contract) {
                this.fetchOrders()
            }
        }
    }
}
</script>
