<template>
    <div class="col-sm-12">
        <b-form class="col-sm-12 list-content">
            <b-row class="nopads  pt-2 pb-2">
                <div class="col-8 item-text-cont">
                    <span class="item-title">{{ user.last_name }}, {{ user.first_name }}</span>
                </div>
                <div
                    class="col-4 text-right"
                >
                    <b-button
                        variant="success"
                        class="result-button"
                        @click="$emit('add')"
                    >
                        {{ $t('common.add_new') }}
                    </b-button>
                </div>
            </b-row>
            <b-table
                show-empty
                small
                stacked="md"
                :items="user.certificates"
                :fields="fields"
                @row-clicked="emitDetails"
                class="pointer"
            >
                <template v-slot:cell(name)="row">
                    {{ row.item.type.name }}
                </template>
                <template v-slot:cell(planned)="row">
                    {{ row.value ? 'X' : '' }}
                </template>
                <template v-slot:cell(granted_day)="row">
                    {{ getShortDateString(row.value) }}
                </template>
                <template v-slot:cell(expiration_day)="row">
                    {{ getShortDateString(row.value) }}
                </template>

            </b-table>
        </b-form>
    </div>
</template>

<script>
import {timeUtils} from '.././mixins/TimeUtils'

export default {
    name: 'UsersCertificateList',
    mixins: [timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            fields: [
                {key: 'name', label: this.$t('certificate.certificate_type'), sortable: true},
                {key: 'planned', label: this.$t('certificate.certificate_planned'), sortable: true},
                {key: 'granted_day', label: this.$t('certificate.granted_day'), sortable: true},
                {key: 'expiration_day', label: this.$t('certificate.expiration_day'), sortable: true}
            ]
        }
    },
    methods: {
        emitDetails: function (result) {
            // Find item from original items and emit request to edit
            let p = this.user.certificates.find(item => item.id === result.id)
            if (p) {
                this.$emit('edit', p)
            }
        }
    }
}
</script>
