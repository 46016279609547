<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="content-container" ref="container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showDeliveriesList"
        >
            <div class="header">
                <span class="title">{{ $t('menu.delivery_notes') }}</span>
            </div>
        </b-navbar>
        <keep-alive>
            <delivery-note-search
                v-if="!editMode"
                :contracts="contracts"
                :show-storages="true"
                @search="fetchDeliveryNotes"
            />
        </keep-alive>
        <div class="button-container nopads pr-3 pb-1"
             v-if="!editMode"
        >
            <b-button
                variant="outline-success"
                class="result-button"
                @click="downloadDeliveryNoteExcel"
                :disabled="notes.length === 0"
            >
                {{ $t('common.download_excel') }}
            </b-button>
        </div>
        <keep-alive>
            <delivery-note-list
                class="pl-3 pr-3"
                v-if="!editMode"
                :notes="notes"
                :isAdmin="isAdmin"
                :isObserver="isObserver"
                @edit="editNote"/>
        </keep-alive>
        <transition name="fade">
            <delivery-note-editor
                v-if="editMode"
                :note="deliveryNote"
                :contracts="contracts"
                @update="update"
                @close="showDeliveriesList"/>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
//import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {downloadHelper} from '../mixins/DownloadMixin'
import DeliveryNoteList from "@/components/materials/DeliveryNoteList";
import DeliveryNoteSearch from "@/components/materials/DeliveryNoteSearch";
import DeliveryNoteEditor from "@/components/materials/DeliveryNoteEditor";
import {EventBus} from "@/event-bus";

export default {
    name: 'Deliveries',
    components: {
        DeliveryNoteEditor,
        DeliveryNoteSearch,
        DeliveryNoteList
    },
    mixins: [timeUtils, downloadHelper, restApi],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            loading: 0,
            contracts: [],
            notes: [],
            editMode: false,
            deliveryNote: null
        }
    },
    mounted: function () {
        this.fetchContracts()
    },
    methods: {
        fetchDeliveryNotes: function (params) {
            this.loading++
            this.notes = []
            if (!params) {
                params = {}
            }
            if (this.selectedStation) {
                params.station = this.selectedStation.id
            } else {
                params.station = this.station
            }
            this.lastSearchParams = params
            this.restFetchParams(this.materialDeliveryUrl, this.lastSearchParams, this.fetchNotesSuccess, this.fetchFail)
        },
        fetchContracts: function () {
            this.loading++
            let params = {}
            if (this.station) {
                let station = this.stations.find(x => x.id === this.station)
                if (station) {
                    params.contractor = station.company.id
                }
            }
            params.startDate = this.getStartOfTheDay(new Date())
            params.endDate = this.getEndOfTheDay(new Date())
            this.restFetchParams(this.contractOpenUrl, params, this.contractsSuccess, this.fetchFail)
        },
        fetchNotesSuccess: function (response) {
            this.notes = response.data
            this.loading--
        },
        contractsSuccess: function (response) {
            this.contracts = response.data
            this.loading--
        },
        fetchFail: function () {
            this.loading--
        },
        update: function () {
            this.showDeliveriesList()
            this.fetchDeliveryNotes(this.lastSearchParams)
        },
        editNote: function (note) {
            this.deliveryNote = note
            this.editMode = true
        },
        showDeliveriesList: function () {
            this.editMode = false
            this.deliveryNote = null
        },
        downloadDeliveryNoteExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.materialDeliveryReportUrl + '/excel',
                this.getCommonReportParams( this.lastSearchParams),
                this.downloadSuccess,
                this.downloadFail)
        },
        downloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('material_reports.deliveries_file_name'))
        },
        downloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        }
    }
}
</script>
