<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <b-row class="nopads">
                <b-col cols="10" class="nopads">
                    <span class="vehicle-list-title ml-2">
                        {{ $t('map.select_assets')}}
                    </span>
                </b-col>
                <b-col cols="2" class="nopads">
                    <div
                        class="vehicle-list-caret"
                        @click.stop="$emit('close')">
                        <font-awesome-icon icon="times-circle"/>
                    </div>
                </b-col>
            </b-row>
            <b-row class="nopads">
                <b-col class="p-1 text-right">
                    <b-button
                        v-if="polygonDrawing && showingObservations && hasListener('observationsToRoadGeometry')"
                        variant="primary"
                        size="lg"
                        class="m-1"
                        @click.stop="$emit('observationsToRoadGeometry')"
                    >
                        <span class="map-button-text">{{ $t('orders.observations_to_geometry') }}</span>
                    </b-button>
                    <b-button
                        v-if="polygonDrawing && showEditSelection"
                        variant="primary"
                        size="lg"
                        class="m-1"
                        @click.stop="$emit('showSelectionSelector')"
                    >
                        <span class="map-button-text">{{ $t('map.edit_selected_assets') }}</span>
                    </b-button>
                    <b-button
                        class="m-1"
                        variant="info"
                        size="lg"
                        @click.stop="$emit('clearPolygon')"
                    >
                        <span class="map-button-text">{{ $t('common.clear') }}</span>
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SelectionTool',
    props: {
        polygonDrawing: Boolean,
        showingObservations: {
            type: Boolean,
            default: false
        },
        showEditSelection: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {

        }
    },
    mounted: function () {
    },
    methods: {
        hasListener(listener) {
            return !!(this.$listeners && this.$listeners[listener])
        }
    }
}
</script>
<style>
</style>
