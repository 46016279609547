<template>
    <div class="col-sm-12 nopads pt-2">
        <transition name="fade">
            <keep-alive>
                <street-light-search
                    :user="user"
                    @search="fetchStreetLights"
                />
            </keep-alive>
        </transition>
        <transition name="fade">
            <div class="col-sm-12">
                <div
                    class="button-container col-sm-12 trip-buttons"
                >
                    <b-button
                        size="sm"
                        variant="outline-primary"
                        class="result-button"
                        :disabled="loading"
                        @click="importFromFile"
                    >
                        {{ $t('common.import_from_file') }}
                    </b-button>
                    <b-button
                        size="sm"
                        v-if="results.length > 0"
                        variant="outline-success"
                        :disabled="results.length < 1"
                        class="result-button"
                        @click="exportExcel"
                    >
                        {{ $t('trip.download_excel') }}
                    </b-button>
                </div>
                <street-light-list
                    :street-lights="results"
                    @rowClicked="editStreetLight"
                />
            </div>
        </transition>

        <street-light-wizard
            v-if="streetLight != null"
            :street-light="streetLight"
            :user="user"
            @close="streetLight=null"
            @closeAndUpdate="closeAndUpdate"
        />

        <street-light-importer
            v-if="showFileImporter"
            @close="showFileImporter = false"
            @closeAndUpdate="hideFileImporter"/>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from "@/event-bus";
import {downloadHelper} from "../mixins/DownloadMixin";
import StreetLightSearch from "@/components/streetlights/StreetLightSearch";
import StreetLightList from "@/components/streetlights/StreetLightList";
import StreetLightWizard from "@/components/streetlights/StreetLightWizard";
import StreetLightImporter from "@/components/streetlights/StreetLightImporter";

export default {
    name: 'StreetLights',
    components: {StreetLightImporter, StreetLightWizard, StreetLightList, StreetLightSearch},
    mixins: [restApi, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            results: [],
            streetLight: null,
            loading: false,
            lastSearchedParams: null,
            showFileImporter: false
        }
    },

    methods: {
        fetchStreetLights: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.streetLightUrl, this.lastSearchedParams, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        },
        editStreetLight: function (streetLight) {
            this.streetLight = streetLight
        },
        closeAndUpdate: function () {
            this.streetLight = null
            this.fetchStreetLights(this.lastSearchedParams)
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.streetLightXlsxUrl, this.getCommonReportParams(this.lastSearchedParams), this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('street_lights.report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
        importFromFile() {
            this.showFileImporter = true
        },
        hideFileImporter() {
            this.showFileImporter = false
            this.fetchStreetLights(this.lastSearchedParams)
        }
    }
}
</script>
