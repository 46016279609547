<template>
    <div class="col-sm-12 nopads">
        <span :class="searchTitleStyle ? 'search-title' : 'span-title mt-0'">{{ $t('materialstorage.title') }}</span>
        <multiselect
            v-model="selectedStorage"
            class="multiselect_routa"
            :placeholder="$t('materialstorage.select_storage')"
            :deselect-label="$t('common.remove_selection')"
            :select-label="$t('common.select')"
            :close-on-select="true"
            label="name"
            track-by="name"
            :searchable="true"
            :internal-search="true"
            :selected-label="$t('common.selected')"
            :options="storages"
        >
            <template
                slot="singleLabel"
                slot-scope="props"
            >
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>
            <template
                slot="option"
                slot-scope="props"
            >
                <div class="option__desc">
                    <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                </div>
            </template>
            <span slot="noResult">{{ $t('common.not_found') }}</span>
            <span slot="noOptions">{{ $t('common.no_results') }}</span>
        </multiselect>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'MaterialStorageFilter',
    mixins: [timeUtils, restApi],
    data: function () {
        return {
            selectedStorage: null,
            storages: []
        }
    },
    props: {
        value: {
            type: Number,
            default: null
        },
        searchTitleStyle: {
            type: Boolean,
            default: false
        }
    },
    mounted: function () {
       this.fetchStorages()
    },
    watch: {
        selectedStorage: function (value) {
            if (this.storages && this.storages.length > 0) {
                this.$emit('input', value ? value.id : null)
            }
        }
    },
    methods: {
        fetchStorages: function () {
            this.storages = []
            this.restFetch(this.materialStorageSearchListUrl, this.handleStorages)
        },
        handleStorages: function (response) {
            if (response && response.data) {
                const sortedAplhabetically = response.data.sort((a, b) => a.name.localeCompare(b.name))
                this.storages = sortedAplhabetically
            }
            if (this.selectedStorage) {
                let exists = this.storages.find(item => item.id === this.selectedStorage)
                if (!exists) {
                    this.selectedStorage = null
                }
            }
            this.$emit('loadingCompleted')
        }
    }
}
</script>
