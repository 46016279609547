<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
        <transition name="fade">
            <div class="col-sm-12 list-container">
                <div
                    v-if="results"
                    class="col-sm-12 list-content result-table"
                >
                    <!-- Main table element -->
                    <b-table
                        hover
                        show-empty
                        small
                        :items="results"
                        :fields="fields"
                        :filter="filter"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :sort-compare="sortCompare"
                        @row-clicked="emitDetails"
                    >
                        <template v-slot:cell(created)="row">
                            {{ toLocalTime(row.value) }}
                        </template>
                        <template v-slot:cell(type)="row">
                            {{ getInspectionTypeName(row.value) }}
                        </template>
                        <template v-slot:cell(frequency)="row">
                            {{ getInspectionFrequency(row.value) }}
                        </template>
                        <template v-slot:cell(outcome)="data">
                            <font-awesome-icon
                                v-if="hasStatus(data.item.results, resultBan)"
                                icon="exclamation-circle"
                                class="ban"
                            />
                            <font-awesome-icon
                                v-else-if="hasStatus(data.item.results, resultDefect)"
                                icon="exclamation-circle"
                                class="defect"
                            />
                        </template>
                        <template v-slot:cell(target)="row">
                            {{ getInspectionTarget(row.item) }}
                        </template>
                        <template v-slot:cell(inspector)="row">
                            {{ getInspector(row.item.reporter) }}
                        </template>
                        <template v-slot:cell(contract)="row">
                            {{ row.value.name }}
                        </template>
                    </b-table>
                </div>
            </div>
        </transition>
</template>

<script>
import {inspectionHelper} from '../../mixins/InspectionMixin'
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'VehicleInspectionList',
    mixins: [inspectionHelper, timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'created',
            sortDesc: true,
            sortDirection: 'asc',
            licensePlate: '',
            fromDate: '',
            toDate: '',
            filter: '',
            type: null,
            types: [
                {value: null, text: this.$t('observations.search_all')},
                {value: 1, text: this.$t('inspection_template.type.vehicle')},
                {value: 2, text: this.$t('inspection_template.type.work')},
                {value: 3, text: this.$t('inspection_template.type.task')},
                {value: 4, text: this.$t('inspection_template.type.company')},
                {value: 5, text: this.$t('inspection_template.type.bridge')},
                {value: 6, text: this.$t('inspection_template.type.culvert')}
            ],
            fields: [
                {key: 'outcome', label: '', sortable: false},
                {
                    key: 'created',
                    label: this.$t('ins_report.list_label.created'),
                    sortable: true,
                    filterByFormatted: true
                },
                {
                    key: 'type',
                    label: this.$t('ins_report.list_label.report_type'),
                    sortable: true,
                    filterByFormatted: true
                },
                {key: 'name', label: this.$t('ins_report.list_label.name'), sortable: true, filterByFormatted: true},
                {
                    key: 'frequency',
                    label: this.$t('inspection_editor.inspection_frequency'),
                    sortable: true,
                    filterByFormatted: true
                },
                {
                    key: 'target',
                    label: this.$t('ins_report.list_label.target'),
                    sortable: true,
                    filterByFormatted: true
                },
                {
                    key: 'inspector',
                    label: this.$t('ins_report.list_label.inspector'),
                    sortable: true,
                    filterByFormatted: true
                },
                {
                    key: 'contract',
                    label: this.$t('ins_report.list_label.contract'),
                    sortable: true,
                    filterByFormatted: true
                }
            ]
        }
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        placeholderField() {
            return this.$t('ins_report.choose_field')
        },
        placeholderQuery() {
            return this.$t('ins_report.query')
        }
    },
    methods: {
        onResetSearch: function () {
            this.licensePlate = ''
            this.fromDate = ''
            this.toDate = ''
        },
        onSubmitSearch: function () {
            var params = {}
            if (this.licensePlate.trim()) {
                params.license_plate = this.licensePlate
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.from = this.localTimeToUtcTime(this.fromDate).toISOString()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.to = this.getEndOfTheDay(this.toDate).toISOString()
            }

            params.type = this.type
            this.$emit('search', params)
        },
        emitDetails: function (details) {
            this.$emit('details', details)
        },
        hasStatus: function (results, status) {
            return results.find(item => item.result === status) !== undefined
        },
        sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
            if (key === 'inspector') {
                const a = this.getInspector(aRow['reporter'])
                const b = this.getInspector(bRow['reporter'])
                return a.localeCompare(b, compareLocale, compareOptions)
            } else if (key === 'target') {
                const a = this.getInspectionTarget(aRow)
                const b = this.getInspectionTarget(bRow)
                return a.localeCompare(b, compareLocale, compareOptions)
            } else if (key === 'created') {
                const a = aRow.created
                const b = bRow.created
                return a < b ? -1 : a > b ? 1 : 0
            } else if (key === 'frequency') {
                const a = this.getInspectionFrequency(aRow.frequency)
                const b = this.getInspectionFrequency(bRow.frequency)
                return a < b ? -1 : a > b ? 1 : 0
            }
            return false
        }
    }
}
</script>
