<template>
    <div class="col-sm-12">
        <h3>{{ $t('areas.select_company') }}</h3>

        <div
            id="vehicle-list"
            class="col-sm-12 list-content"
        >
            <div
                v-for="result of results"
                :key="result.id"
                @click.stop="$emit('onCompanySelected', result)"
                class="data-list-item"
            >
                <div class="col-sm-12 item-header">
                    <div class="item-img-cont">
                        <font-awesome-icon icon="building"
                        />
                    </div>
                    <div class="item-text-cont">
              <span class="item-title-single">
                {{ result.name }}
              </span>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'

export default {
    name: 'contractor-selector',
    mixins: [restApi],
    data: function () {
        return {
            loading: false,
            results: []
        }
    },
    created: function () {
        this.fetchContractors()
    },
    methods: {
        fetchContractors: function () {
            this.loading = true
            this.restFetchParams(this.contractorUrl, {}, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
        }
    }
}
</script>
