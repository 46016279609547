<template>
        <b-row v-if="priceRange" class="nopads mt-2">
            <div class="col-3 nopads pr-2">
                <b-input-group size="sm" :append="distanceUnit">
                    <b-form-input
                        id="input-from"
                        v-model="priceRange.distance_from"
                        type="number"
                        :disabled="true"
                    />
                </b-input-group>
            </div>
            <div class="mr-2 ml-2">-</div>
            <div class="col-3 nopads pr-2">
                <b-input-group size="sm" :append="distanceUnit">
                    <b-form-input
                        id="input-to"
                        v-model="priceRange.distance_to"
                        type="number"
                        :disabled="true"
                    />
                </b-input-group>
            </div>
            <div class="col-2 nopads pl-2 pr-2">
                <b-input-group size="sm" :append="'€ / ' + weightUnit">
                    <b-form-input
                        id="price"
                        v-model="priceValue"
                        type="number"
                    />
                </b-input-group>
            </div>
            <div class="col-1 nopads pl-2 pr-2">
                <span v-if="multiplier !== 1">{{ (priceValue * multiplier).toFixed(3) }} € / {{ weightUnit }}</span>
            </div>

            <!--
            <div class="col-sm-2 nopads pl-2">
                <b-button
                    v-if="userRightUpdate.id < 1"
                    size="sm"
                    variant="success"
                    class="mr-1"
                    @click.stop="addUserRight"
                    :disabled="roleId < 0 || (companyId < 0 && isAdmin)"
                >
                    <i class="fa fa-plus"/>
                </b-button>
            </div>
            -->
        </b-row>
</template>

<script>

export default {
    name: 'PriceRangeSelector',
    props: {
        price: {
            type: Object,
            default: null
        },
        multiplier: {
            type: Number,
            default: 1
        },
        weightUnit: {
            type: String,
            default: "-"
        },
        distanceUnit: {
            type: String,
            default: "-"
        }
    },
    data: function () {
        return {
            priceRange: null,
            priceValue: 0
        }
    },

    watch: {
        priceValue() {
            if (this.priceValue && this.priceRange) {
                this.priceRange.price = (this.priceValue * 100).toFixed(1)
            }
        }
    },

    mounted: function () {
        this.priceRange = this.price
        this.priceValue = this.priceRange.price ? this.priceRange.price / 100.0 : 0
    }
}
</script>
