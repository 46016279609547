export async function register(restApi, deviceId) {
    await new Promise((resolve, reject) =>
        restApi.restAdd(
            restApi.userDevice,
            {
                device: deviceId
            },
            resolve,
            reject
        )
    )
}

export async function unregister(restApi, deviceId) {
    await new Promise((resolve, reject) =>
        restApi.restDeleteItem(
            restApi.userDevice,
            {
                device: deviceId
            },
            resolve,
            reject
        )
    )
}

export default {
    register,
    unregister,
}
