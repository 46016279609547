<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <h3
                v-if="priceList.id > 0"
                class="p-2"
            >
                {{ $t('pricelist.edit') }}
            </h3>
            <h3
                v-else
                class="p-2"
            >
                {{ $t('pricelist.add') }}
            </h3>
            <div class="col-sm-12 form-data">
                <div class="col-sm-6 nopads">
                    <span class="span-title">
                      {{ $t('pricelist.name') }}
                    </span>
                    <b-form-input
                        id="name"
                        v-model="priceListUpdate.name"
                        size="sm"
                    />
                </div>
                <div v-if="priceListUpdate.id && priceListUpdate.id > 0" class="col-sm-6 nopads">
                    <span class="span-title">
                        {{ $t('pricelist.active') }}
                    </span>
                    <b-form-checkbox
                        id="active"
                        v-model="priceListUpdate.active"
                        name="checkbox"
                    />
                </div>
                <b-row class="nopads pt-3">
                    <b-col cols="3" class="span-title nopads pr-2">{{ $t('common.area')}}</b-col>
                    <b-col cols="3" class="span-title nopads pr-2">{{ $t('orders.task_type')}}</b-col>
                    <b-col cols="3" class="span-title nopads pr-2">{{ $t('orders.unit_price')}}</b-col>
                </b-row>

                <!-- Area prices -->
                <area-price-selector
                    v-for="(item, index) in priceListUpdate.prices"
                    v-bind:key="index"
                    :index="index"
                    :priceItem="item"
                    :errorRow="hasErrorCombination(item)"
                    :areas="areas"
                    :taskTypes="taskTypes"
                    @priceUpdated="updatePrice"
                    @updateEvent="resetErrors"
                />
                <div class="col-sm-12 button-container">
                    <b-button
                        size="sm"
                        variant="success"
                        class="mr-1"
                        @click.stop="addPriceItem"
                    >
                        <i class="fa fa-plus"/>
                    </b-button>
                </div>

                <!-- Buttons -->
                <hr/>
                <div class="col-sm-12 button-container">
                    <b-button
                        id="btn-close"
                        variant="danger"
                        class="form-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        id="btn-success"
                        variant="success"
                        class="form-button"
                        :disabled="loading > 0 || !priceListUpdate.name || priceListUpdate.name.length < 1 || !validPrices"
                        @click.stop="submitPriceList">
                          {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import AreaPriceSelector from "@/components/pricingmodels/pricelists/AreaPriceSelector";

export default {
    name: 'AreaPriceListEditor',
    components: {AreaPriceSelector},
    mixins: [restApi],
    props: {
        priceList: {
            type: Object,
            default: null
        },
        hideTitle: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: 0,
            priceListUpdate: this.priceList,
            areas: [],
            taskTypes: [],
            errorCombinations: []
        }
    },
    computed: {
        validPrices() {
            let item = this.priceListUpdate.prices.find(item => !item.area || !item.area.id || !item.task_type ||
                !item.task_type.id || !item.price)
            return item === null || item === undefined
        }
    },

    mounted() {
        this.fetchAreas()
        this.fetchTaskTypes()
    },

    methods: {
        setDefaultValues: function (priceList) {
            if (priceList.name === '') {
                EventBus.$emit('show-alert', this.$t('pricelist.invalid_name'))
                return undefined
            }
            if (priceList.prices.length < 1) {
                EventBus.$emit('show-alert', this.$t('pricelist.invalid_prices'))
                return undefined
            }
            if(priceList.prices.length > 1) {
                const seenCombinations = [];
                let hasDuplicatePrices = false;

                priceList.prices.forEach((price) => {
                    const combination = [price.area.id, price.task_type.id];

                    // Check if the combination has been seen before
                    if (seenCombinations.some((seenCombination) => this.compareArrays(seenCombination, combination))) {
                        hasDuplicatePrices = true;
                        this.errorCombinations = [combination]
                        return; // Exit the forEach loop early if a duplicate is found
                    }

                    // Add the combination to the array of seen combinations
                    seenCombinations.push(combination);
                });

                if (hasDuplicatePrices) {
                    EventBus.$emit('show-alert', this.$t('pricelist.invalid_duplicates'))
                    return undefined
                }
            }
            if (!priceList.created_at) {
                let now = new Date()
                priceList.created_at = now.toISOString()
            }
            return priceList
        },

        compareArrays(arr1, arr2) {
            // Compare two arrays for equality
            return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
        },

        submitPriceList: function () {
            let json = this.setDefaultValues(this.priceListUpdate)
            if(json !== undefined) {
                this.loading = true
                if (!this.priceListUpdate.id || this.priceListUpdate.id < 1) {
                    this.restAdd(this.areaPriceListUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.areaPriceListUrl, json, this.success, this.fail)
                }
            }
        },

        hasErrorCombination(item) {
            return this.errorCombinations.some(
                (errorItem) =>
                    errorItem[0] === item.area.id && errorItem[1] === item.task_type.id
            );
        },

        resetErrors() {
            this.errorCombinations = []
        },

        success: function (response) {
            this.$emit('closeAndUpdate', response)
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        addPriceItem() {
            let presetTaskType = {id: null}
            let presetArea = {id: null}
            if (this.priceListUpdate.prices.length > 0) {
                presetArea = JSON.parse(JSON.stringify(this.priceListUpdate.prices[this.priceListUpdate.prices.length-1].area))
                presetTaskType = JSON.parse(JSON.stringify(this.priceListUpdate.prices[this.priceListUpdate.prices.length-1].task_type))
            }
            this.priceListUpdate.prices.push({area: presetArea, task_type: presetTaskType, price: 0.0})
        },

        fetchAreas: function () {
            this.loading++
            this.restFetchParams(this.areaUrl, {no_geom: 1}, this.handleAreas)
        },

        handleAreas: function (response) {
            this.loading--
            if (response && response.data) {
                this.areas = response.data
            }
        },

        fetchTaskTypes: function () {
            this.loading++
            this.restFetch(this.taskTypeUrl, this.handleTaskTypes, this.onTaskTypeLoaded)
        },

        handleTaskTypes: function (response) {
            this.loading--
            if (response && response.data) {
                this.taskTypes = response.data
            }
        },

        updatePrice(index, price) {
            this.priceListUpdate.prices[index] = price
        }

    }
}
</script>
