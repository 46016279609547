<template>
        <work-assignment-summary-values
            :total="total"
            :newAs="newAs"
            :open="open"
            :closed="closed"
            :average="average"
        />
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import {workAssignmentHelper} from "../mixins/WorkAssignmentMixin";
import WorkAssignmentSummaryValues from "@/components/contractreport/WorkAssignmentSummary.vue";

export default {
    name: "WorkAssignmentSummary",
    mixins: [timeUtils, restApi, workAssignmentHelper],
    components: {WorkAssignmentSummaryValues},
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        },
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            loading: false,
            workAssignmentsVisible: false,
            newAs: 0,
            open: 0,
            closed: 0,
            average: 0,
            total: 0,
            workAssignment: null,
        }
    },

    created() {
        this.handleResponse()
    },

    watch: {
        results: function () {
            this.handleResponse()
        }
    },
    methods: {
        averageTime: function () {
            let amount = 0
            let totalMinutes = 0
            this.results.forEach(value => {
                let min = this.getDurationInMinutes(value.created, value.closing_date === undefined ? Date.now() : value.closing_date)
                if (!isNaN(min)) {
                    totalMinutes += min
                    amount++
                }
            })
            this.average = isNaN(totalMinutes/amount) ? 0 : totalMinutes/amount
        },



        handleResponse: function () {
            this.newAs = 0
            this.open = 0
            this.closed = 0
            this.average = 0
            this.total = 0
            this.setSummaryData()
            this.averageTime()
        },
        setSummaryData() {
            if (this.results) {
                this.total = this.results.length
                this.results.forEach(item => {
                    switch (item.status) {
                        case this.OPEN:
                            this.newAs++
                            break
                        case this.ASSIGNED:
                        case this.IN_PROGRESS:
                            this.open++
                            break
                        default:
                            this.closed++
                            break
                    }
                })
            }
        }
    }
}
</script>

