<script>
import 'chartjs-plugin-annotation';
import {Bar} from 'vue-chartjs'

export default {
    name: 'bar-chart',
    props: {
        showXAxisLabel: {
            type: Boolean,
            default: true
        },
        labels: {
            type: Array,
            default() {
                return []
            }
        },
        colors: {
            type: Array,
            default() {
                return []
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        dataIds: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            default() {
                return ''
            }
        },
        upperLimit: {
            type: Number,
            default: null
        },
        lowerLimit: {
            type: Number,
            default: null
        }
    },
    extends: Bar,
    data: function () {
        return {
            chartData: null,
            options: {
                responsive: true,
                onClick: this.handleClick,
                hover: {
                    onHover: function (e) {
                        var point = this.getElementAtEvent(e);
                        if (point.length) {
                            e.target.style.cursor = 'pointer'
                        } else {
                            e.target.style.cursor = 'default'
                        }
                    }
                },
                legend: {
                    display: false
                },
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            display: this.showXAxisLabel
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            display: true,
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    },
    mounted() {
        if (this.data) {
            let annotations = []
            if (this.lowerLimit) {
                annotations.push({
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: this.lowerLimit,
                    borderColor: '#c57f7f',
                    borderWidth: 1,
                })
            }
            if (this.upperLimit) {
                annotations.push({
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: this.lowerLimit,
                    borderColor: '#f56a6a',
                    borderWidth: 1,
                })
            }
            this.options.annotation = {annotations: annotations}
            this.chartData = this.getChartData()
            this.renderChart(this.chartData, this.options)
        }
    },
    watch: {
        data() {
            this.chartData = this.getChartData()
            this.renderChart(this.chartData, this.options)
        }
    },
    methods: {
        getChartData: function () {
            let values = []
            this.data.forEach(item => values.push(item.value))
            return {
                labels: this.labels,
                datasets: [
                    {
                        label: this.title,
                        backgroundColor: this.colors,
                        data: values,
                        maxBarThickness: 50
                    }
                ]
            }
        },
        getChartAsImage: function () {
            return this.$data._chart.toBase64Image()
        },
        handleClick: function (evt, data) {
          // Emit the data id of the item of clicked bar
            if (data && data.length > 0) {
                let dataIndex = data[0]._index
                if (dataIndex >= 0) {
                    this.$emit('onBarClick', dataIndex)
                }
                this.updateColorSet(event, data)
            }
        },
        updateColorSet(event) {
            const chart = this.$data._chart;
            if (chart) {
                // Get the chart element at the click position
                const element = chart.getElementAtEvent(event)
                if (element.length > 0) {
                    const datasetIndex = element[0]._datasetIndex
                    const index = element[0]._index
                    const colors = [...this.colors]
                    colors[index] = '#f5d982'
                    this.$set(this.chartData.datasets[datasetIndex], 'backgroundColor', colors)
                    chart.update()
                }
            }
        },
        setSelected(index) {
            const chart = this.$data._chart
            if (chart) {
                const colors = [...this.colors]
                colors[index] = '#f5d982'
                this.$set(this.chartData.datasets[0], 'backgroundColor', colors)
                chart.update()
            }
        }
    }
}
</script>
