<template>
    <div class="col-12 nopads">
      <span class="span-title">
            {{ $t('contracts.certificate_types') }}
      </span>

        <b-form v-if="selectedCertificateTypes.length > 0" inline>
            <div class="col-sm-12 inspectable-container p-2">
                <div
                    v-for="item in selectedCertificateTypes"
                    :key="item.id"
                    :class="{'d-inline' : true}">
                <span class="inspectables inspectables-selected"
                      @click="removeFromSelection(item)">
                  {{ item.name }}
                  <strong>
                    X
                  </strong>
                </span>
                </div>
            </div>
        </b-form>
        <div v-else
             class="col-sm-12 p-2">
        <span>
          {{ $t('contracts.no_certificate_type') }}
        </span>
        </div>
        <span
            v-if="selectedCertificateTypes.length !== certificateTypes.length"
            class="span-title p-2">
                {{ $t('contracts.add_certificate_type') }}
        </span>
        <div class="col-sm-12 row inspectable-container nopads">
            <div
                v-for="item in certificateTypes"
                :key="item.id"
                class="col-sm-6 col-md-4 col-lg-3 col-xl-2 inline inspectables nopads"
                :class="{ 'inspectables-disabled' : isSelected(item.id) }"
                @click="addToSelection(item)">
                  {{ item.name }}
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'contract-certificate-types',
    mixins: [restApi],
    props: {
        value: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false,
            certificateTypes: [],
            selectedCertificateTypes: this.value
        }
    },
    watch: {
        selectedCertificateTypes: function () {
            this.$emit('input', this.selectedCertificateTypes)
        }
    },
    created: function () {
        this.fetchCertificateTypes()
    },
    methods: {
        fetchCertificateTypes: function () {
            this.loading = true
            this.restFetch(this.certificateTypeUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.certificateTypes = response.data
            this.loading = false
        },
        isSelected: function (id) {
            return this.selectedCertificateTypes.find(item => item.id === id) !== undefined
        },
        addToSelection(item) {
            let index = this.selectedCertificateTypes.findIndex(selectedItem => selectedItem.id === item.id)
            if (index < 0) {
                this.selectedCertificateTypes.push(item)
            }
        },
        removeFromSelection (item) {
            let index = this.selectedCertificateTypes.findIndex(selecteditem => selecteditem.id === item.id)
            if (index >= 0) {
                this.selectedCertificateTypes.splice(index, 1)
            }
        }
    }
}
</script>
