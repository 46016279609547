<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            @click="hideDetails">
            <div
                v-if="!detailsMode"
                class="header">
                <span class="title">{{ $t('menu.forest_contract_eff') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="hideDetails">
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
            </div>
        </b-navbar>
        <div class="col-sm-12 nopads">
            <keep-alive>
                <open-contract-filter
                    v-if="!detailsMode"
                    :is-admin="isAdmin"
                    @searchReport="fetchEfficiencyResults"/>
            </keep-alive>
        </div>
        <div v-if="!detailsMode" class="col-12 button-container">
            <b-button
                class="result-button"
                variant="outline-primary"
                @click="changeView"
            >
                {{ toggleViewLabel }}
            </b-button>
        </div>
        <div class="col-sm-12">
            <!-- Efficiency graph view -->
            <efficiency-charts
                v-if="!detailsMode && graphsVisible"
                ref="graphs"
                :results="results"
                :efficiencies="efficiencies"
                @openContract="showContract"/>
            <!-- Contract list -->
            <keep-alive>
                <efficiency-by-contract-list
                    v-if="!detailsMode && !graphsVisible && efficiencies.length > 0"
                    ref="contractList"
                    :results="results"
                    :efficiencies="efficiencies"
                    @rowClick="rowClick"
                />
            </keep-alive>
            <!-- Contract specific report -->
            <div v-if="detailsMode" class="report">
                <h2 class="pl-1"> {{ contract.contract_name }} - {{ contract.contract_number }} </h2>
                <div class="col-sm-6 nopads" v-for="item of efficiencies" v-bind:key="item.id">
                    <div class="col-12">
                        <span class="item-detail-title">{{ item.name }}</span>
                    </div>
                    <div class="col-12 mb-2">
                        <span>{{ getEfficiencyValue(contract, item.id) }} {{ item.unit }}</span>
                    </div>
                </div>
                <forest-work-report-tables
                    v-if="detailsMode"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    :params="forestParams"
                    @onReportDataUpdated="updateData"
                />
            </div>
        </div>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import OpenContractFilter from "./OpenContractFilter";
import ForestWorkReportTables from "../forest/ForestWorkReportTables"
import EfficiencyByContractList from "./EfficiencyByContractList"
import {restApi} from "../mixins/RestApiMixin"
import EfficiencyCharts from "./EfficiencyGraphs";

export default {
    name: "EfficiencyByContract",
    components: {EfficiencyCharts, OpenContractFilter, ForestWorkReportTables, EfficiencyByContractList},
    mixins: [restApi],
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            params: null,
            efficiencies: [],
            results: [],
            detailsMode: false,
            loading: false,
            forestParams: null,
            contract: null,
            graphsVisible: false,
            toggleViewLabel: ''
        }
    },

    mounted: function () {
        this.fetchEfficiencies()
        this.toggleViewLabel = this.$t('forest_work_eff.show_graphs')
    },

    methods: {
        updateData: function () {
            this.detailsMode = false
            this.fetchEfficiencyResults(this.params)
        },

        fetchEfficiencies: function () {
            this.loading = true
            this.efficiencies = []
            this.restFetch(this.efficiencyUrl, this.handleEfficiencies)
        },

        handleEfficiencies: function (response) {
            this.loading = false
            if (response && response.data) {
                this.efficiencies = response.data
            }
        },

        fetchEfficiencyResults: function (params) {
            if (params) {
                this.params = params
                this.results = []
                this.loading = true
                this.restFetchParams(this.efficiencyCalculateUrl, this.params, this.handleResults)
            }
        },

        handleResults: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
                // Open contract if it was shown
                if (this.contract) {
                    this.contract = this.results.find(item => item.contract_id === this.contract.contract_id)
                }
                if (this.contract) {
                    this.$nextTick(function () {
                        this.rowClick(this.contract)
                    })
                }
            }
        },

        rowClick: function (item) {
            this.contract = item
            this.forestParams = {
                from: this.params.from,
                to: this.params.to,
                contracts: [item.contract_id],
                vehicleType: 0
            }
            this.detailsMode = true
        },

        showContract: function (contractId) {
            let contract = this.results.find(item => item.contract_id === contractId)
            if (contract) {
                this.rowClick(contract)
            }
        },

        hideDetails: function () {
            this.detailsMode = false
            this.contract = null
        },

        getEfficiencyValue: function (contract, efficiencyItemId) {
            let item = contract.efficiencies.find(eff => eff.id === efficiencyItemId);
            if (item) {
                return item.value
            }
            return '0'
        },

        changeView: function () {
            this.graphsVisible = !this.graphsVisible
            this.toggleViewLabel = this.graphsVisible ? this.$t('forest_work_eff.show_list') : this.$t('forest_work_eff.show_graphs')
        }
    }
}
</script>

<style scoped>

</style>
