<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('menu.additional_cost') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
                <span class="pointer">
                  <i class="fa fa-arrow-left"/>
                </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12">
            <div
                v-if="!editMode"
                class="button-container col-sm-12 trip-buttons"
            >
                <b-button
                    variant="success"
                    class="result-button"
                    @click="addItem"
                >
                    {{ $t('common.add_new') }}
                </b-button>
            </div>
            <transition
                name="fade"
            >
                <additional-cost-editor
                    v-if="editMode"
                    :additional-cost="result"
                    @closeAndUpdate="searchWithParams"
                    @close="showList"
                />
            </transition>
            <transition
                name="fade"
                v-if="!editMode"
            >
                <additional-cost-list
                    :results="results"
                    @rowClicked="editItem"/>
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {additionalCostHelper} from "@/components/mixins/AdditionalCostMixin";
import AdditionalCostEditor from "@/components/additionalcost/AdditionalCostEditor";
import AdditionalCostList from "@/components/additionalcost/AdditionalCostList";

export default {
    name: 'additional-costs-user',
    components: {AdditionalCostList, AdditionalCostEditor},
    mixins: [restApi, timeUtils, additionalCostHelper],
    data: function () {
        return {
            loading: false,
            editMode: false,
            results: [],
            result: null
        }
    },
    created() {
        this.searchWithParams()
    },
    methods: {
        searchWithParams: function () {
            this.results = []
            this.loading = true
            this.restFetchParams(this.additionalCostUrl, null, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
            this.showList()
        },
        showList: function () {
            this.editMode = false
        },
        editItem: function (item) {
            this.result = item
            this.editMode = true
        },
        addItem: function () {
            this.result = null
            this.editMode = true
        }
    }
}
</script>
