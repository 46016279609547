<template>
    <b-row class="nopads">
        <b-input-group>
            <b-form-input
                id="new-property-name"
                v-model="propertyName"
                size="sm"
                :placeholder="$t('orders.add_property')"
            />
            <b-input-group-append>
                <b-button
                    size="sm"
                    variant="success"
                    @click.stop="addProperty"
                    :disabled="!propertyName"
                    autofocus
                >
                    <i class="fa fa-plus"/>
                </b-button>
            </b-input-group-append>
        </b-input-group>
    </b-row>
</template>

<script>
import {userHelper} from '../mixins/UserMixin'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'OrderGeometryPropertySelector',
    mixins: [userHelper, restApi],
    props: {
        userRight: {
            type: Object,
            default: null
        },
        largeMode: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            propertyName: ""
        }
    },

    methods: {
        addProperty: function () {
            // this.userRightUpdate.info = this.info
            this.$emit('addProperty', this.propertyName)
            this.reset()
        },
        reset: function () {
            this.propertyName = ""
        }
    }
}
</script>
