export const geomTypes = {
    POINT: 1,
    LINESTRING: 2,
    POLYGON: 3,
}

export const observationHelper = {
    methods: {
        newObservationGroup: function () {
            return {
                id: -1,
                name: null,
                company: null
            }
        },

        newObservationType: function () {
            return {
                id: -1,
                name: null,
                geometry_type: 1,
                observation_group: null
            }
        },

        jsonToItem: function (item) {
            return JSON.parse(JSON.stringify(item)) // deep cloning of the vehicle object
        },

        getTripModeString: function (mode) {
            switch (mode) {
                case geomTypes.POINT:
                    return this.$t('observation.point')
                case geomTypes.LINESTRING:
                    return this.$t('observation.linestring')
            }
            return this.$t('common.unknown')
        },

        getObservationGeometryType(observation) {
            if(observation) {
                if(observation.geometry && observation.geometry.point) {
                    return geomTypes.POINT;
                } else if(observation.geometry && observation.geometry.line_string) {
                    return geomTypes.LINESTRING;
                } else if(observation.geometry && observation.geometry.polygon) {
                    return geomTypes.POLYGON
                } else if(observation.position) {
                    return geomTypes.POINT;
                }
            }
            return null
        },

        getStreetAddressString: function (observation) {
            let result = ''
            if (observation) {
                // Road number and name
                if (observation.road_number) {
                    result += observation.road_number
                    if (observation.section_number) {
                        result += '/' + observation.section_number
                    }
                }
                if (observation.distance_from_sec_start) {
                    if (result.length > 0) {
                        result += '/'
                    }
                    result += observation.distance_from_sec_start + ' m '
                }
                if (observation.street_name) {
                    if (result.length > 0) {
                        result += ' '
                    }
                    result += observation.street_name
                }
            }
            return result
        },
        getLatitude: function (observation) {
            if (observation && observation.geometry && observation.geometry.point) {
                return observation.geometry.point.y
            }
            return undefined
        },
        getLongitude: function (observation) {
            if (observation && observation.geometry && observation.geometry.point) {
                return observation.geometry.point.x
            }
            return undefined
        },
        getPoints: function (observation) {
            if (observation.geometry && observation.geometry.line_string) {
                let points = observation.geometry.line_string.points
                let results = []
                points.forEach(function (point) {
                    if (point.length === 2) {
                        results.push({x: point[0], y: point[1]})
                    }
                })
                return results
            }
            return undefined
        },
        getPhotoUrl: function (baseUrl, photo) {
            return baseUrl + '/' + this.observation.id + '/' + photo.id
        }
    }
}
