<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 p-2 pt-4">
                <h3
                    v-if="allowanceUpdate.id > 0"
                    class="nopads"
                >
                    {{ $t('meal_allowance.edit') }}
                </h3>
                <h3
                    v-else
                    class="nopads"
                >
                    {{ $t('meal_allowance.add') }}
                </h3>
            </div>
            <div class="col-sm-12 form-data">
                <!-- Companies -->
                <div
                    v-if="isAdmin && allowanceUpdate.id<1"
                    class="nopads"
                >
          <span class="span-title">
            {{ $t('users.company') }}
          </span>
                    <b-form-select
                        id="company"
                        v-model="company"
                        size="sm"
                        class="mb-3"
                        :disabled="!companies || companies.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('users.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </b-form-select>
                </div>

                <!-- Users -->
                <div
                    v-if="allowanceUpdate.id < 1"
                    class="nopads"
                >
          <span class="span-title">
            {{ $t('work_time.user') }}
          </span>
                    <b-form-select
                        v-model="allowanceUpdate.user.id"
                        size="sm"
                        class="mb-3"
                        :disabled="!users || users.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('work_time.select_user') }}
                            </option>
                        </template>
                        <option
                            v-for="item in users"
                            :key="item.id"
                            :value="item.id"
                        >
                            {{ item.last_name }}, {{ item.first_name }}
                        </option>
                    </b-form-select>
                </div>
                <div v-else>
                    <h3>
                        {{ allowanceUpdate.user.first_name + ' ' + allowanceUpdate.user.last_name }}
                    </h3>
                </div>

                <!-- Date -->
                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
          <span class="span-title">
            {{ $t('work_time.start') }}
          </span>
                        <datetime
                            v-model="allowanceUpdate.date"
                            type="date"
                            size="sm"
                            format="dd.MM.yyyy"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                    <div class="col-sm-6 nopads pl-2">
          <span class="span-title">
            {{ $t('meal_allowance.increased') }}
          </span>
                        <b-form-checkbox
                            class="pt-2"
                            id="checkbox-1"
                            v-model="allowanceUpdate.increased_allowance"
                        >
                            {{ $t('meal_allowance.increased') }}
                        </b-form-checkbox>
                    </div>
                </b-row>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>

                    <b-button
                        v-if="isObserver && allowanceUpdate.id>0"
                        variant="primary"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="approveAllowance"
                    >
                        <i class="fa fa-check"/>
                        <span class="button-text">
              {{ $t('meal_allowance.approve_allowance') }}
            </span>
                    </b-button>

                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {allowanceHelper} from '../mixins/AllowanceMixin'

export default {
    name: 'MealAllowanceEditor',
    mixins: [restApi, allowanceHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean,
        allowance: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            company: null,
            companies: [],
            users: [],
            allowanceUpdate: this.newMealAllowance(),
            PARTIAL_ALLOWANCE_HOURS: 6
        }
    },

    watch: {
        company: function () {
            if (this.company) {
                this.fetchUsers()
            }
        }
    },

    mounted: function () {
        if (this.allowance) {
            this.allowanceUpdate = this.allowance
        }
        if (this.isAdmin) {
            this.fetchCompanies()
        } else {
            this.fetchUsers()
        }
    },

    methods: {
        fetchCompanies: function () {
            if (this.isAdmin) {
                this.loading = true
                this.restFetch(this.companyUrl, this.handleCompanies)
            }
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        fetchUsers: function () {
            if (this.isAdmin) {
                this.restFetchParams(this.userUrl, {company: this.company}, this.handleUsers)
            } else {
                this.restFetch(this.userUrl, this.handleUsers)
            }
        },

        handleUsers: function (response) {
            this.users = response.data
            this.loading = false
        },

        submit: function () {
            if (!this.allowanceUpdate.date) {
                EventBus.$emit('show-alert', this.$t('daily_allowance.error_start_missing'))
                return
            }
            this.loading = true
            let json = JSON.parse(JSON.stringify(this.allowanceUpdate))
            if (json.user) {
                json.user = {id: json.user.id}
            }
            if (json.id > 0) {
                this.restUpdate(this.mealAllowanceUrl, json, this.success, this.fail)
            } else {
                this.restAdd(this.mealAllowanceUrl, json, this.success, this.fail)
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function (response) {
            this.loading = false
            if (response.status === 406) {
                EventBus.$emit('show-alert', this.$t('meal_allowance.error_overlapping'))
            } else {
                if (this.allowanceUpdate.id < 0) {
                    EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
                } else {
                    EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
                }
            }
        },
        approveAllowance: function () {
            this.loading = true
            this.restAdd(this.mealAllowanceApproveUrl + this.allowanceUpdate.id, null, this.success, this.fail)
        }
    }
}
</script>
