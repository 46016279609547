<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <span class="vehicle-list-title">
                {{ $t('map.imported_layer') }}
            </span>
            <div class="p-2 text-right">
                <b-button
                    variant="info"
                    size="lg"
                    @click.stop="$emit('hideImportedObjects')"
                >
                    <span class="map-button-text">{{ $t('common.hide') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapImportedObjects'
}
</script>
