<template>
    <div ref="container" class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">{{ $t('observation_reports.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <report-search
                :user="user"
                :templates="templates"
                @reportSelected="onReportSelected"
                @addTemplate="editMode = true"
                @search="fetchReport"
            />
            <transition name="fade">
                <div v-if="results.length > 0">
                <b-row class="nopads">
                    <!-- MAP -->
                    <div class="col-sm-6 nopads pl-3 pr-3 mb-2">
                        <div
                            class="col-sm-12 stat-map-container nopads"
                            style="overflow: hidden; height: 40vh;"
                        >
                            <map-container
                                ref="mapContainer"
                                :find-user="false"
                                @onMapChanged="onMapChanged"
                                @onMarkerTap="showPointDetails"
                            />
                        </div>
                    </div>
                    <!-- CHART -->
                    <div class="col-sm-6 nopads  pl-1 mb-2" style="overflow: hidden">
                        <observation-value-charts
                            ref="valueChart"
                            :title="selectedReport.name"
                            :lower-limit="this.selectedReport.lower_alert_limit"
                            :upper-limit="this.selectedReport.upper_alert_limit"
                            :results="results"
                            @itemClicked="onItemClicked"/>
                    </div>
                </b-row>

                <report-list
                    ref="resultList"
                    class="pl-3 pr-3"
                    :upper-limit="selectedReport ? selectedReport.upper_alert_limit : null"
                    :lower-limit="selectedReport ? selectedReport.lower_alert_limit : null"
                    :results="results"
                    @rowClicked="observationClicked"
                />
            </div>
            </transition>
            <observation-report-editor
                v-if="editMode"
                @close="editMode = false"
                @closeAndUpdate="fetchReportTemplates"/>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../../mixins/RestApiMixin'
import ReportSearch from "@/components/observation/reports/ReportSearch";
import ReportList from "@/components/observation/reports/ReportList";
import ObservationReportEditor from "@/components/observation/reports/ObservationReportEditor";
import MapContainer from "@/components/map/MapContainer";
import {mapHelper} from "@/components/mixins/MapMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import ObservationValueCharts from "@/components/observation/reports/ObservationValueCharts";

export default {
    name: 'ObservationReports',
    components: {
        ObservationValueCharts,
        MapContainer,
        ObservationReportEditor,
        ReportList,
        ReportSearch
    },
    mixins: [restApi, mapHelper, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            templates: [],
            results: [],
            powerStation: null,
            loading: false,
            lastSearchedParams: null,
            editMode: false,
            selectedReport: null,
            map: null
        }
    },
    mounted() {
        this.fetchReportTemplates()
    },
    methods: {
        onMapChanged(map) {
            this.map = map
            if (this.map && this.results.length > 0) {
                this.drawResultsOnMap()
            }
        },
        showPointDetails(data) {
            const pointItem = this.results.find(item => data.id === item.id)
            let bubbleData = []
            bubbleData[this.$t('observations.created_time')] = this.toLocalTime(pointItem.created_time)
            bubbleData[this.$t('observations.value')] = pointItem.value
            this.map.showInfoBubble(this.OBSERVATION, data.id, pointItem.type.name, bubbleData, pointItem.geometry.point.y, pointItem.geometry.point.x)
        },
        fetchReportTemplates: function () {
            this.editMode = false
            this.loading = true
            this.templates = []
            this.restFetch(this.observationReportTemplateUrl, this.handleTemplates, () => {
                this.loading = false
            })
        },
        handleTemplates: function (response) {
            if (response && response.data) {
                this.templates = response.data
            }
            this.loading = false
        },
        closeAndUpdate: function () {
            this.editMode = false
            this.fetchReport(this.lastSearchedParams)
        },
        fetchReport: function (param) {
            this.editMode = false
            this.loading = true
            this.results = []
            this.lastSearchedParams = param
            this.restFetchParams(this.observationUrl, param, this.handleResults, () => {
                this.loading = false
            })
        },
        handleResults: function (response) {
            if (response && response.data) {
                this.results = response.data
                this.drawResultsOnMap()
            }
            this.loading = false
        },
        onReportSelected(id) {
            this.selectedReport = this.templates.find(item => item.id === id)
        },
        drawResultsOnMap() {
            if (this.map) {
                this.hideMapItems()
                this.results.forEach(item => {
                    let icon = this.beyondLimit(item.value) ? this.map.getMarkerStore().getSolidCircleIcon() :
                        this.map.getMarkerStore().getNeutralCircleIcon()
                    this.map.showMapMarker(item.id, this.OBSERVATION, item.geometry.point.y, item.geometry.point.x, icon)
                })
               this.map.zoomToGroup(this.OBSERVATION)
            }
        },
        hideMapItems() {
            if (this.map) {
                this.map.removeMapItemsByType(this.OBSERVATION)
            }
        },
        beyondLimit(value) {
            if (!this.selectedReport) {
                return false
            }
            return (this.selectedReport.upper_alert_limit && parseFloat(value) > this.selectedReport.upper_alert_limit) ||
                (this.selectedReport.lower_alert_limit && parseFloat(value) < this.selectedReport.lower_alert_limit)
        },
        observationClicked(item) {
            if (this.map) {
                this.map.zoomToPosition(item.geometry.point.y, item.geometry.point.x, 13)
            }
            this.selectItem(item)
        },
        onItemClicked(index) {
            if (this.map && index >= 0 && index < this.results.length) {
                const item = this.results[index]
                this.map.zoomToPosition(item.geometry.point.y, item.geometry.point.x, 13)
                this.$refs.resultList.selectRow(index)
            }
        },
        selectItem(selected) {
            const index = this.results.findIndex(item => item.id === selected.id)
            if (index > 0) {
                this.$refs.valueChart.setSelected(index)
            }
        }
    }
}
</script>
