<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="deviceUpdate.imei"
                    class="form-title editor-title"
                >
                    {{ $t('tracking_devices.edit') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('tracking_devices.add') }}
                </h2>
            </div>
            <b-row class="p-1">
                <div class="col-sm-6 nopads p-1">
          <span class="span-title">
            {{ $t('tracking_devices.imei') }}
          </span>
                    <b-form-input
                        id="imei"
                        v-model="imei"
                        type="text"
                        size="sm"
                        :placeholder="$t('tracking_devices.hint_imei')"
                        :disabled="deviceUpdate.imei !== undefined"
                    />
                </div>
                <div class="col-sm-6 nopads p-1">
          <span class="span-title">
            {{ $t('tracking_devices.vehicle') }}
          </span>
                    <b-form-select
                        id="vehicle"
                        v-model="vehicle"
                        size="sm"
                        :disabled="!vehicles || vehicles.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('tracking_devices.select_vehicle') }}
                            </option>
                        </template>
                        <option
                            v-for="v in vehicles"
                            :key="v.id"
                            :value="v.id"
                        >
                            {{ v.license_plate }} - {{ v.make }} - {{ v.vehicle_model }}
                        </option>
                    </b-form-select>
                </div>
            </b-row>
            <b-row>
                <span class="hint-text pl-2">
                   {{ $t('tracking_devices.hint_contract') }}
                </span>
            </b-row>
            <b-row class="p-1">
                <div class="col-sm-6 nopads pl-1 pr-1" style="margin-top: -3px">
                    <open-contract-filter
                        :to="toDate"
                        :from="fromDate"
                        :contract="contract"
                        :show-company="false"
                        :showOnlyMyContractsOption="true"
                        @loading="loading=true"
                        @loadingCompleted="loading=false"
                        @contractSelected="setContract"
                        style="margin-left: -2px; margin-right: -3px;"/>
                </div>
                <div class="col-sm-6 nopads pl-1 pr-1">
                    <contract-order-filter
                        v-model="order"
                        :pre-selected-order="contract && order ? order.id : undefined"
                        :contract-id="contract ? contract.id : undefined"
                        :show-executor-text="true"
                    />
                </div>
            </b-row>
            <b-row>
               <span class="hint-text pl-2">
                   {{ $t('tracking_devices.hint_user') }}
               </span>
            </b-row>
            <b-row class="p-1">
               <div class="col-sm-6 nopads p-1">
                      <span class="span-title">
                        {{ $t('tracking_devices.user') }}
                      </span>
                    <b-form-select
                        id="vehicle"
                        v-model="user"
                        size="sm"
                        :disabled="!users || users.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                            >
                                {{ $t('tracking_devices.select_user') }}
                            </option>
                        </template>
                        <option
                            v-for="u in users"
                            :key="u.id"
                            :value="u.id"
                        >
                            {{ u.last_name }}, {{ u.first_name }}
                        </option>
                    </b-form-select>
                </div>
            </b-row>

            <b-row class="p-1">
                <div class="col-sm-6 nopads p-1">
                    <b-form-checkbox
                        id="harja_enabled"
                        name="harja_enabled"
                        v-model="deviceUpdate.harja_enabled"
                        :value="true"
                        :unchecked-value="false"
                    >
                        {{ $t('contracts.harja_enabled') }}
                    </b-form-checkbox>
                </div>
            </b-row>

            <div class="col-sm-12 button-container">
                <b-button
                    variant="danger"
                    class="routa-button"
                    @click.stop="$emit('close')"
                >
                    <i class="fa fa-times"/>
                    <span class="button-text">
            {{ $t('common.cancel') }}
          </span>
                </b-button>
                <b-button
                    variant="success"
                    class="routa-button"
                    :disabled="loading"
                    @click.stop="submitDevice"
                >
                    <i class="fa fa-save"/>
                    <span class="button-text">
            {{ $t('common.save') }}
          </span>
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import ContractOrderFilter from "../order/ContractOrderFilter";

export default {
    name: 'TrackingDeviceEditor',
    components: {ContractOrderFilter, OpenContractFilter},
    mixins: [restApi, workManagementHelper],
    props: {
        device: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            vehicle: null,
            contract: null,
            order: null,
            vehicles: [],
            user: null,
            users: [],
            imei: null,
            deviceUpdate: this.jsonToItem(this.device),
            colors: ''
        }
    },
    computed: {
        fromDate() {
            let d = new Date()
            return d.toISOString()
        },
        toDate() {
            let d = new Date()
            return d.toISOString()
        }
    },
    mounted: function () {
        this.fetchVehicles()
        this.fetchUsers()
        if (this.deviceUpdate.imei) {
            this.vehicle = this.deviceUpdate.vehicle.id
            if (this.deviceUpdate.user) {
                this.user = this.deviceUpdate.user.id
            }
            this.imei = this.deviceUpdate.imei
            this.contract = this.deviceUpdate.contract
            this.order = this.deviceUpdate.work_order
        }
    },
    methods: {
        fetchVehicles: function () {
            this.loading = true
            this.restFetch(this.vehicleUrl, this.handleVehicles)
        },

        handleVehicles: function (response) {
            if (response && response.data) {
                this.vehicles = response.data
            }
            this.loading = false
        },

        fetchUsers: function () {
            this.loading = true
            this.restFetch(this.userUrl, this.handleUsers)
        },

        handleUsers: function (response) {
            if (response && response.data) {
                this.users = response.data
            }
            this.loading = false
        },

        setContract(contract) {
            this.contract = contract
        },

        setDefaultValues: function () {
            if (!this.imei) {
                EventBus.$emit('show-alert', this.$t('tracking_device.imei_missing'))
                return undefined
            }
            if (!this.vehicle) {
                EventBus.$emit('show-alert', this.$t('tracking_device.vehicle_missing'))
                return undefined
            }
            let result = {imei: this.imei, vehicle: {id: this.vehicle}, harja_enabled: this.deviceUpdate.harja_enabled,}
            if (this.user) {
                result.user = {id: this.user}
            }
            if (this.contract) {
                result.contract = {id: this.contract.id}
            }
            if (this.order) {
                result.work_order = {id: this.order.id }
            }
            return result
        },

        submitDevice: function () {
            var json = this.setDefaultValues()
            if (json !== undefined) {
                if (!this.device.imei) {
                    this.restAdd(this.trackingDeviceUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.trackingDeviceUrl, json, this.success, this.fail)
                }
                this.loading = true
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
