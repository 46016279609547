<template>
    <div class="col-sm-12 list-content result-table">
        <b-table
            ref="effResultTable"
            id="forwarderTable"
            :items="items"
            :fields="fields"
            :responsive="true"
            :small="true"
            hover
            class="pointer"
            @row-clicked="emitRowClick">
        </b-table>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";

export default {
    name: "EfficiencyByContractList",
    mixins: [restApi],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        },
        efficiencies: {
            type: Array,
            default: function () {
                return []
            }
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            fields: [],
            items: [],
            loading: false
        }
    },

    watch: {
        results: function () {
            this.initTableData()
        }
    },

    mounted: function () {
        this.initFields()
        this.initTableData()
    },

    methods: {
        initFields: function () {
            this.fields = []
            this.fields.push({key: 'contractNumber', label: this.$t('forest_work_eff.contract_number'), sortable: true})
            this.fields.push({key: 'contractName', label: this.$t('forest_work_eff.contract_name'), sortable: true})
            this.efficiencies.forEach(item => {
                let name = item.name
                if (item.unit) {
                    name = name + ' (' + item.unit + ')'
                }
                this.fields.push({key: String(item.id), label: name, sortable: true})
            })
        },

        initTableData: function () {
            this.items = [];
            this.results.forEach(result => {
                let tableRow = {
                    contractId: result.contract_id,
                    contractName: result.contract_name,
                    contractNumber: result.contract_number
                }
                this.efficiencies.forEach(eff => {
                    tableRow[eff.id] = this.getEfficiencyValue(eff.id, result)
                })
                this.items.push(tableRow)
            })
        },

        getEfficiencyValue: function (effId, result) {
            let retVal = '-'
            let values = result.efficiencies
            if (values && values.length > 0) {
                values.forEach(item => {
                    if (String(item.id) === String(effId)) {
                        retVal = item.value
                        return
                    }
                })
            }
            return retVal
        },

        emitRowClick: function (result) {
            let r = this.results.find(item => item.contract_id === result.contractId)
            if (r) {
                this.$emit('rowClick', r)
            }
        },
    }
}
</script>

<style scoped>

</style>
