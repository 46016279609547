<template>
    <div v-if="showAddPropertiesModal && this.loadedGeometrySelected >= 0 && loadedGeometries && loadedGeometries.length > 0"
         class="col-6 col-lg-3 right file-dialog property-dialog">
        <div>
            <h4 class="span-title pl-1">{{ $t('orders.add_property_value') }}</h4>
        </div>

        <div v-if="newProperties && Object.keys(newProperties)" class="col-12 nopads">
            <div v-for="(geometryProperty, index) in Object.keys(newProperties)" :key="'geometry-property'+index" class="col-12 nopads">
                <div v-if="!excludedFields.includes(geometryProperty)" class="nopads">
                    <span class="span-title">{{ geometryProperty }}</span>
                    <b-input-group>
                        <b-form-input
                            id="name"
                            type="text"
                            size="sm"
                            v-model="newProperties[geometryProperty]"
                        />
                        <b-input-group-append>
                            <b-button
                                variant="danger"
                                size="sm"
                                @click.stop="() => $emit('removeProperty', geometryProperty)"
                            >
                                <i class="fa fa-trash"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </div>
        </div>
        <hr/>
      <!-- Placeholder for a new one -->
          <order-geometry-property-selector
              :large-mode="true"
              ref="orderGeometryPropertySelector"
              @addProperty="(newProperty) => addProperty(newProperty)"/>
        <div class="button-container pr-1 pt-2">
            <b-button
                variant="secondary"
                size="sm"
                class="form-button"
                @click.stop="resetDrawing"
            >
                {{ $t('common.cancel') }}
            </b-button>
            <b-button
                variant="success"
                size="sm"
                class="form-button"
                @click.stop="addToGeometry"
            >
              {{ this.showAddPropertiesModal ? this.$t('common.save')
                : addPolylineEnabled ? this.$t('orders.add_line_to_geometry')
                    : addPointEnabled ? this.$t('orders.add_point_to_geometry')
                        : this.$t('orders.add_line_to_geometry') }}
            </b-button>
        </div>
    </div>
</template>

<script>

import OrderGeometryPropertySelector from "./OrderGeometryPropertySelector.vue";

export default {
    name: "OrderGeometryAddPropertyModal",
  components: {OrderGeometryPropertySelector},
    props: {
        showAddPropertiesModal: {
            type: Boolean,
            default: false
        },
        loadedGeometrySelected: {
            type: Number,
            default: null
        },
        loadedGeometries: {
            type: Array,
            default() {
                return []
            }
        },
        newProperties: {
            type: Object,
            default: null
        },
        selectedLayer: {
            type: Object,
            default: null
        },
        excludedFields: {
            type: Array,
            default() {
                return []
            }
        },
        addPolylineEnabled: {
            type: Boolean,
            default: false
        },
        addPointEnabled: {
            type: Boolean,
            default: false
        },

    },
    methods: {
        reset() {
            this.$refs.orderGeometryPropertySelector.reset();
        },
        addToGeometry() {
            this.$emit('onAddToGeometry')
        },
        addProperty(property) {
          this.$emit('addProperty', property)
        },
        resetDrawing() {
            this.$emit('onResetDrawing')
        }
    }
}
</script>
<style>
.property-dialog {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 0;
    overflow: auto;
    z-index: 2999;
    border: none;
    background: #f3f3f7;
    box-shadow: -2px 0 2px 0 #aaaaaa;
}
</style>
