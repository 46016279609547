<template>
  <div class="d-inline">
    <b-button
      :variant="buttonVariant"
      class="form-button"
      @click="showModal"
    >
      {{ $t('contracts.copy_contract.copy') }}
    </b-button>
    <b-modal
      id="contract-copy-modal"
      title="Copy contract"
      hide-header
      hide-footer
      hide-header-close
      centered
      >
      <div class="text-center d-flex flex-column align-items-center p-3">
        <h2 class="mb-3" style="font-weight: bold">
          {{ $t('common.are_you_sure') }}
        </h2>
          {{ $t('contracts.copy_contract.are_you_sure', { name: contract.name }) }}
        <ul class="actions-list w-100 py-4">
          <li>
            <span>
              {{ $t('contracts.copy_contract.copy_orders') }}
            </span>
            <span>
              <b-form-checkbox v-model="copyOrders" name="copy-orders" switch />
            </span>
          </li>
          <li>
            <span>
              {{ $t('contracts.copy_contract.copy_attachments') }}
            </span>
            <span>
              <b-form-checkbox v-model="copyAttachments" name="copy-attachments" switch />
            </span>
          </li>
        </ul>
      </div>
      <div class="col-12 button-container">
        <b-button
          variant="dark"
          @click="closeModal"
          class="mr-2"
        >
          {{ $t('common.cancel') }}
        </b-button>
        <b-button
          variant="success"
          @click="submit"
          :disabled="isLoading"
        >
          <span style="display: flex; align-items: center; justify-content: center">
            {{ !isLoading ? $t('common.yes') : $t('common.loading') }} <b-spinner v-if="isLoading" small />
          </span>
        </b-button>
      </div>
      </b-modal>
  </div>
</template>
<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from '@/event-bus'

export default {
  name: 'contract-copy',
  props: {
    contract: {
      type: Object,
      default: null
    },
    buttonVariant: {
      type: String,
      default: 'primary'
    },
    setDefaultValues: {
      type: Function,
      default: () => {}
    }
  },
  mixins: [restApi],
  data() {
    return {
      isLoading: false,
      copyOrders: true,
      copyAttachments: true,
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show('contract-copy-modal')
    },
    closeModal() {
      this.$bvModal.hide('contract-copy-modal')
    },
    success(data) {
      this.closeModal()
      EventBus.$emit('show-alert', this.$t('contracts.copy_contract.success', { name: data.name }), 'success')
      this.$emit('contract-copied', data)
      this.isLoading = false
    },
    fail() {
      this.closeModal()
      EventBus.$emit('show-alert', this.$t('contracts.copy_contract.error'), 'danger')
      this.isLoading = false
    },
    submit() {
      if (this.contract === null) {
        return
      }
      this.isLoading = true
      this.restAdd(this.copyContractUrl(this.contract.id), {
          copy_orders: this.copyOrders,
          copy_attachments: this.copyAttachments
        }, this.success, this.fail)
    },
  },
}
</script>
<style lang="scss" scoped>
.actions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e9ecef;
    padding: 1rem .25rem;

    &:last-child {
      border-bottom: 1px solid #e9ecef;
    }
  }
}
</style>
