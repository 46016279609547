<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header">
                <span class="title">
                  {{ $t('contract_user_reports.title') }}
                </span>
            </div>
        </b-navbar>
        <div class="search-form">
            <span class="pl-2 pr-2">{{ $t('ins_report_details.time') }}</span>
            <b-row class="nopads">
                <div class="col-sm-6 p-2">
                    <datetime
                        v-model="fromDate"
                        type="date"
                        format="dd.MM.yyyy"
                        class="routa-input"
                    />
                </div>
                <div class="col-sm-6 p-2">
                    <datetime
                        v-model="toDate"
                        type="date"
                        format="dd.MM.yyyy"
                        class="routa-input"
                    />
                </div>
            </b-row>
            <b-row class="nopads">
                <!-- Contractor selection -->
                <b-form-group
                    class="col-sm-6 p-2"
                    label-for="contractor"
                >
                    <b-form-select
                        id="contractorOrder"
                        v-model="contractor"
                        size="sm"
                        :disabled="contractors.length < 1"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('orders.select_contractor') }}
                            </option>
                        </template>
                        <option
                            v-for="option in contractors"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </b-row>
        <div class="button-container pr-3">
            <b-button
                class="result-button"
                @click.stop="fetchReport"
                variant="primary"
            >
                {{ $t('common.search_verb') }}
            </b-button>
        </div>
        </div>

        <div v-if="noResults" class="col-sm-12">
            <span slot="noOptions">{{ $t('common.no_results') }}</span>
        </div>
        <div class="col-12">
            <div class="list-content" v-if="results.length > 0">
            <div class="button-container pr-3">
                <b-button
                    class="result-button"
                    @click.stop="downloadReport"
                    variant="outline-success"
                >
                    {{ $t('trip.download_excel') }}
                </b-button>
            </div>
            <!-- Persons -->
            <b-table
                small
                striped
                :items="results"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template v-slot:cell(time)="row">
                    {{ toLocalTime(row.value) }}
                </template>
            </b-table>
        </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'
import {EventBus} from '@/event-bus'
import {downloadHelper} from "@/components/mixins/DownloadMixin";

export default {
    name: 'contract-user-reports',
    mixins: [restApi, timeUtils, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            sortBy: 'last_name',
            sortDesc: false,
            fromDate: new Date().toISOString(),
            toDate: null,
            contractor: null,
            results: [],
            contractors: [],
            loading: false,
            noResults: false,
            fields: [
                {key: 'last_name', label: this.$t('users.last_name'), sortable: true},
                {key: 'first_name', label: this.$t('users.first_name'), sortable: true},
                {key: 'company_name', label: this.$t('users.company'), sortable: true},
                {key: 'contract_name', label: this.$t('contracts.name'), sortable: true},
                {key: 'task_type_name', label: this.$t('observations.order'), sortable: true},
                {key: 'time', label: this.$t('ins_report_details.time'), sortable: true},
            ]
        }
    },
    mounted() {
        this.fetchContractors()
    },
    methods: {
        fetchContractors: function () {
            this.loading = true
            this.restFetch(this.contractorUrl, this.handleContractors)
        },
        handleContractors: function (response) {
            this.loading = false
            if (response && response.data) {
                this.contractors = response.data
            }
        },
        fetchReport: function () {
            this.noResults = false
            this.results = []
            if (this.fromDate) {
                this.loading = true
                this.restFetchParams(this.contractUserUrl, {from: this.fromDate, to: this.toDate, company: this.contractor},
                    this.handleResponse, this.fail)
            }
        },
        handleResponse: function (response) {
            this.loading = false
            if (!response.data || response.data.length < 1) {
                this.noResults = true
            } else {
                this.results = response.data
            }
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
        },
        async downloadReport() {
            this.loading = true;
            const { err, ...response } = await this.fetchExcel().catch((err) => ({err}));
            this.loading = false;
            if (err) {
                EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
                return;
            }
            this.downloadFile(response, this.$t('contracts.user_report_file_name'))
        },

        fetchExcel() {
            return this.axios.get(
                this.contractUserReportUrl,
                {
                    params: this.getCommonReportParams({from: this.fromDate, to: this.toDate, company: this.contractor}),
                    responseType: 'arraybuffer'
                }
            )
        },
    }
}
</script>
