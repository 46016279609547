export const printHelper = {
    data: function () {
        return {
            printStyle: [
                '@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,400,400italic,600");' +
                '@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");' +
                '@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css")' +
                'body { font-size: 10px; font-family: "Source Sans Pro", sans-serif; }' +
                '.span-header { font-size: 1.2em; color: #90b677; margin-bottom: 1em; line-height: 3em; vertical-align: bottom;}' +
                '.row { display: block; }' +
                '.report-divider {border-color: #90b677; }' +
                'table {width: 100%; }' +
                'thead { font-size: 0.8em; font-weight:600; text-transform: uppercase; text-align: left;}' +
                'th, td {line-height: 1.4em; border-bottom: 1px solid #ddd; text-align: left;}' +
                '.report-table td {border-top: 1px solid #ddd; border-bottom: none;}' +
                '.report-table th {border-bottom: none;}' +
                '.report-header {margin-bottom: 1em}' +
                '.report-info {display:inline-block; padding-right: 1em;}' +
                '.item-detail-title {font-size: 0.8em; font-weight:600; text-transform: uppercase; margin-top: .5em; display: block}' +
                '.print-only {font-weight: 600; display: block; margin-top: 2em;}' +
                '.dont-print {display: none;}' +
                '.hide-print {display: none;}' +
                '.show-print {display: block !important;}' +
                '.sr-only {display: none;}' +
                '.b-table-details > td:first-child {border: none; padding-top: 0; padding-bottom: 0;}' +
                '.card {padding: .5em .75em 1em; }' +
                '.card table {border-collapse: collapse; border: 1px solid #ddd;}' +
                '.card th, .card td {border-top: 1px solid #ddd; border-bottom: 1px solid #ddd;}' +
                '.text-success {color: #28A745;}' +
                '.text-warning {color: #faa633;}' +
                '.text-danger {color: #d91f1e;}' +
                '.signature { display: inline-block; width: 45%; margin-top: 1em; margin-bottom: 2em; margin-right: 2em;}' +
                '.signature-line { display: block;  height: 3em; width: 100%; border-bottom: 1px solid #000000;}' +
                '.report-result{ border: 1px solid #bcbcbc; margin-top: .5em; margin-bottom: .5em; padding: .5em; }' +
                '.result-icon { display: inline-block;  margin: .15em; font-size: 2em; line-height: 1.2em; vertical-align: center; padding-left: 0; padding-right: 0; color: #888888; float: right; }' +
                '.result-title { font-size: 1.4em; line-height: 2.4em; color: #1E88E5; font-weight: 400; vertical-align: center; margin-top: 1em; }' +
                '.report-title{ display: block; color: #404041; font-weight: 800; font-size: 0.9em; text-transform: uppercase; line-height: 1.4; margin-top: .5em; }' +
                '.report-descr{ display: block; color: #404041; font-weight: 400; font-size: 0.9em; line-height: 1.4; margin: .5em }' +
                '.ban{ color: #d91f1e; }' +
                '.completed{ color: #28A745; }' +
                '.defect{ color: #dc9431; }' +
                '.result-icon { display: none }' +
                '.result-value { padding: .5em; font-weight: 500; text-transform: uppercase; margin: .2em; float: right; display: inline-block; }' +
//                '.image-view { width: 95%; margin: 5px; max-width: 400px; border: 1px solid #bcbcbc; border-radius: .5em; background: #888888; }' +
                '.chart-container { width: 45vw; text-align: center; display: inline-block; padding-top: 2em; padding-left: 1em; padding-right: 1em; }' +
                '.routa-pie-print { height: 30%; width: 30%; margin: auto; }' +
                '.routa-bars-print { height: 10em; width: 90%; margin: auto; }' +
                '.routa-bars-print-large { height: 20em; width: 90%; margin: auto; margin-top: 3.5em }' +
                '.routa-charts-print { height: 25em; margin: auto; margin-top: 3.5em }' +
                '.title { display: block; color: #404041; font-weight: 400; margin: 0; padding: .3em; }' +
                '.attachment-icon { width: 30px; height:30px }' +
                '.table-fixed {table-layout: fixed}' +
                '.text-center {text-align: center} ' +
                '.image-view {height: 600px; object-fit: cover; border: 1px solid #bcbcbc; width: 100%;}' +
                '.image-view-lg {height: 600px; object-fit: cover; border: 1px solid #bcbcbc; width: 100%;}' +
                '.image-portrait {height: 600px; object-fit: cover; border: 1px solid #bcbcbc; width: 100%;}' +
                '.image-portrait-lg {height: 600px; margin: auto;  object-fit: cover; border: 1px solid #bcbcbc; width: 100%;}' +
                '.multi-img-row .image-view {height: 300px; object-fit: cover; border: 1px solid #bcbcbc; width: 100%;}' +
                '.multi-img-row .image-view-lg {height: 300px; object-fit: cover; border: 1px solid #bcbcbc; width: 100%;}' +
                '.multi-img-row .image-portrait {height: 300px; object-fit: cover; border: 1px solid #bcbcbc; width: 100%;}' +
                '.multi-img-row .image-portrait-lg {height: 300px; margin: auto;  object-fit: cover; border: 1px solid #bcbcbc; width: 100%;}' +
                '.col-6 {display: inline-block; width: 47%; padding: 5px; overflow:hidden; vertical-align: top}' +
                '.map-container { position: relative; display: block;  height: 100%; overflow: hidden;}' +
                '.pl-3 {padding-left:  1em}' +
                '.pr-3 {padding-right: 1em}' +
                'div {font-size: 10px; font-family: "Source Sans Pro", sans-serif;}' +
                '.print-logo {margin-top: 2em; height: 1em; width: auto;}' +
                '.detail-map {position: absolute; height: 195px; width: 350px; overflow: hidden; border-radius: 1.2em; border: 1px solid #BCBCBC;}'+
                '.report-photos {height: 200px; width: 350px; overflow: hidden; margin-top: 210px;}' +
                '.slide-img-container {overflow: hidden; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;}' +
                '.image-contain { object-fit: contain !important;}' +
                '.report-header {margin: 0; margin-top: 20px; font-size: 1.2em; font-weight: bold; text-transform: uppercase;}' +
                '.summary-map-container {position: relative; border: 1px solid #d9d9d9; border-radius: 1em; overflow: hidden; width: 750px; height: 350px; margin: auto; margin-bottom: 15px}' +
                '.observation-marker {width: 20px; height: auto; text-align: center; color: #fad62e; z-index: 3; border-radius: 2px; background: rgba(64, 64, 65, 0.5); font-size: 8px;}' +
                '.print-icon-padding {padding: 5px}' +
//                '.print-img-margin {margin-left: -11.5em}' +
                '.print-page {display: block; height: 1100px;}' +
                '.print-map-data-row {display: block; margin-bottom: 5em; margin-top: 1em;}'
            ]
        }
    },

    methods: {}
}
