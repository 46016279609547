export const allowanceType = {
    PARTIAL: 1,
    FULL: 2
}

export const allowanceHelper = {
    methods: {
        newDailyAllowance: function () {
            return {
                id: -1,
                user: {id: null},
                approver: null,
                start_time: null,
                start_place: null,
                end_time: null,
                end_place: null,
                info: null
            }
        },

        newMealAllowance: function () {
            return {
                id: -1,
                user: {id: null},
                approver: null,
                date: null,
                increased: null
            }
        },

        getTypeString: function (status) {
            switch (status) {
                case allowanceType.PARTIAL:
                    return this.$t('daily_allowance.partial')
                case allowanceType.FULL:
                    return this.$t('daily_allowance.full')
            }
        },
        copyAndFormatObj: function (item) {
            const copyObj = JSON.parse(JSON.stringify(item))
            if (copyObj.start_time) {
                copyObj.start_time = copyObj.start_time + 'Z'
            }
            if (copyObj.end_time) {
                copyObj.end_time = copyObj.end_time + 'Z'
            }
            return copyObj
        },
        copyAndFormatMealObj: function (item) {
            return JSON.parse(JSON.stringify(item))
        }
    }
}
