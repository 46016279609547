<template>
    <div class="col-sm-12 editor pt-2">
        <div class="col-sm-12 editor-form pb-4">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    class="form-title editor-title"
                >
                    {{ $t('work_time.title') }}
                </h2>
            </div>

            <b-tabs justified v-model="tabIndex">
                <b-tab :title="$t('work_time.reports')" active :title-link-class="linkTabClass(0)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 0}">
                                {{ $t('work_time.reports') }}
                            </div>
                        </b-row>
                    </template>
                    <work-time-reports
                        :is-observer="hasObserver(user.roles)"/>
                </b-tab>
                <b-tab :title="$t('menu.daily_allowances')" :title-link-class="linkTabClass(1)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 1}">
                                {{ $t('menu.daily_allowances') }}
                            </div>
                        </b-row>
                    </template>
                    <daily-allowances
                        :is-observer="hasObserver(user.roles)"/>
                </b-tab>
                <b-tab :title="$t('menu.meal_allowances')" :title-link-class="linkTabClass(2)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 2}">
                                {{ $t('menu.meal_allowances') }}
                            </div>
                        </b-row>
                    </template>
                    <meal-allowances
                        :is-observer="hasObserver(user.roles)"/>
                </b-tab>
                <b-tab :title="$t('menu.work_time_types')" :title-link-class="linkTabClass(3)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 3}">
                                {{ $t('menu.work_time_types') }}
                            </div>
                        </b-row>
                    </template>
                    <work-time-modes
                        :is-observer="hasObserver(user.roles)"/>
                </b-tab>
                <b-tab :title="$t('menu.cost_center')" :title-link-class="linkTabClass(4)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 4}">
                                {{ $t('menu.cost_center') }}
                            </div>
                        </b-row>
                    </template>
                    <cost-centers
                        :is-observer="hasObserver(user.roles)"/>
                </b-tab>
            </b-tabs>
        <transition name="fade">
            <order-editor
                ref="editor"
                v-if="order"
                :contract="value"
                :order="order"
                :user="user"
                @close="closeOrderEditor"
                @closeAndUpdate="closeOrderAndUpdate"
            />
        </transition>
        <transition name="fade">
            <task-type-editor
                v-if="taskType"
                :task-type="newTaskType()"
                @close="hideTaskTypeEditor"
                @closeAndUpdate="hideTaskTypeEditor"/>
        </transition>
        <transition
            name="fade"
        >
            <additional-cost-editor
                v-if="costItem"
                :additional-cost="costItem"
                @closeAndUpdate="updateCosts"
                @close="costItem = null"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {workManagementHelper} from "@/components/mixins/WorkManagementMixin";
import WorkTimeReports from "@/components/worktime/new/WorkTimeReports";
import {userHelper} from "@/components/mixins/UserMixin";
import CostCenters from "@/components/costcenter/CostCenters";
import WorkTimeModes from "@/components/worktime/WorkTimeModes";
import DailyAllowances from "@/components/allowances/DailyAllowances";
import MealAllowances from "@/components/allowances/MealAllowances";

export default {
    name: 'WorkTimeView',
    components: {
        MealAllowances,
        DailyAllowances,
        WorkTimeModes,
        CostCenters,
        WorkTimeReports,
    },
    mixins: [restApi, workManagementHelper, userHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            contract: null,
            orders: [],
            ordersToAdd: [],
            order: null,
            closeOnCancel: false,
            addMessage: '',
            taskType: false,
            tabIndex: 0,
            costItem: null
        }
    },
    computed: {
        hasActiveOrders() {
            return !this.loading && (!this.$refs.contractOrders || this.$refs.contractOrders.hasActiveOrders())
        },
        hasTaskTypes() {
            return this.contract.task_types && this.contract.task_types.length > 0
        }
    },
    mounted: function () {
        this.contract = this.value
    },
    watch: {
        contract(val) {
            this.$emit('input', val)
        },
        order: function() {
            this.order ? this.$emit('orderActiveChange', true) : this.$emit('orderActiveChange', false)
        },
        activeOrderEditor: function () {
            if(!this.activeOrderEditor) this.closeOrderEditor()
        }
    },
    methods: {
        softUpdateContract(data) {
            this.contract = data
            this.$emit('input', data)
            this.$emit('scrollTop')
        },
        editOrder: function (order) {
            this.order = order
            this.$emit('scrollTop')
        },
        close: function () {
            this.$emit('showList')
        },
        closeAndUpdate: function (data) {
            if (this.value.id < 1 && data.id > 0) {
                // If we just added a contract successfully, ask to create orders as well
                this.contract = data
                this.addOpenOrders()
                this.closeOnCancel = true
            }
            if (this.contractTripList) {
                this.emitCloseAndUpdate()
            }
        },
        emitCloseAndUpdate() {
            this.$emit('closeAndUpdate', this.contract)
        },
        addOrder: function () {
            this.order = this.createEditorOrder()
            this.$emit('scrollTop')
        },
        closeOrderEditor: function () {
            this.order = null
        },
        closeOrderAndUpdate: function () {
            this.closeOrderEditor()
            this.$nextTick(() => {
                this.$refs.contractOrders.fetchContractOrders()
                this.$refs.calendar.refresh()
            })
        },
        showTaskTypeEditor: function () {
            this.taskType = true
            this.$emit('scrollTop')
        },

        hideTaskTypeEditor: function () {
            this.taskType = false
            this.$nextTick(() => this.$refs.contractEditor.refreshTaskTypes())
        },

        linkTabClass(index) {
            if (this.tabIndex === index) {
                return ['tab-title', 'tab-title-active']
            } else {
                return ['tab-title']
            }
        },
        editCost(costItem) {
            this.costItem = costItem
        },
        updateCosts() {
            this.costItem = null
            this.$refs.costs.updateCosts()
        },
        onOrdersUpdated() {
            this.$refs.calendar.refresh()
        }
    }
}
</script>

<style>
.tab-title {
    font-size: .9em;
    border: none !important;
    margin-right: .5em;
    margin-left: .5em;
    color: #949494 !important;
    font-weight: bold;
}

.tab-title:hover {
    color: #007BFF;
}

.tab-title-active {
    border-bottom: 2px solid #7FC000 !important;
}

.tab-title-text {
    color: #949494 !important;
    font-weight: bold;
}


.tab-title-text-active {
    color: #000000 !important;
    font-weight: bold;
}


.tab-title-cont {
    height: 2.5em !important;
    padding-top: 1em;
    padding-bottom: 1em;
}

.contract-tabs .nav-link {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.contract-tabs .nav-tabs {
    height: 3em;
    padding-top: .55em;
}

</style>
