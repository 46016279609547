<template>
    <div>
        <div v-if="locationDeviation && !isEditMode" class="pl-3 pr-3">
            <span class="span-title">{{ $t('equipments.location_deviation') }}</span>
            <span class="item-text">{{ getLocationDeviationString() }}</span>
        </div>
        <div v-if="isEditMode">
            <span class="span-title">{{ $t('equipments.location_deviation') }}</span>
            <b-form-group label-for="value">
                <b-form-select
                    :value="locationDeviation"
                    size="sm"
                    @change="updateLocationDeviation"
                >
                    <template slot="first">
                        <option
                            :value="null"
                        >
                            {{ $t('equipments.select_location_deviation') }}
                        </option>
                    </template>
                    <option
                        v-for="option in locationDeviationSelector"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
            </b-form-group>
        </div>
    </div>
</template>

<script>
import {deviations, velhoHelper} from "@/components/mixins/VelhoMixin";

export default {
    name: 'LocationDeviation',
    mixins: [velhoHelper],
    props: {
        locationDeviation: {
            type: Number,
            default: null
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isEditMode() {
            return this.editMode
        },
        locationDeviationSelector() {
            return  Object.values(deviations).map(value => {
                return {
                    text: this.getVelhoLocationDeviationString(value),
                    value: value
                }
            });
        },
    },
    methods: {
        getLocationDeviationString() {
            switch (this.locationDeviation) {
                case 1: return this.$t('equipments.deviation_1')
                case 2: return this.$t('equipments.deviation_2')
                case 3: return this.$t('equipments.deviation_3')
                case 4: return this.$t('equipments.deviation_4')
                case 5: return this.$t('equipments.deviation_5')
                case 6: return this.$t('equipments.deviation_6')
                case 7: return this.$t('equipments.deviation_7')
            }
            return this.$t('common.unknown')
        },
        updateLocationDeviation(newValue) {
        this.$emit('updateLocationDeviation', newValue);
        }
    }
}
</script>

<style scoped>
</style>

