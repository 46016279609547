<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            @click="showDefectList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('area_defects.title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showDefectList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <keep-alive>
                <area-defect-search
                    v-if="!editMode"
                    @search="fetchDefects"
                />
            </keep-alive>
            <transition name="fade">
                <keep-alive>
                    <area-defect-list
                        v-if="!editMode"
                        :items="defects"
                        :is-admin="isAdmin"
                        @add="addDefect"
                        @edit="editDefect"
                    />
                </keep-alive>
            </transition>
            <transition name="fade">
                <area-defect-editor
                    v-if="editMode"
                    :defect="defect"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    @close="showDefectList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import AreaDefectList from './AreaDefectList'
import AreaDefectSearch from './AreaDefectSearch'
import {restApi} from '../../mixins/RestApiMixin'
import AreaDefectEditor from './AreaDefectEditor'
import {areaHelper} from '../../mixins/AreaMixin'

export default {
    name: 'AreaDefects',
    components: {AreaDefectList, AreaDefectEditor, AreaDefectSearch},
    mixins: [restApi, areaHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            defects: [],
            defect: null,
            editMode: false,
            loading: false,
            areas: [],
            lastSearchedParams: null
        }
    },
    methods: {
        fetchDefects: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.defects = []
            let url = null

            if (this.lastSearchedParams == null) {
                url = this.areaDefectOpenUrl
            } else if (this.lastSearchedParams.status === 1) {
                url = this.areaDefectOpenUrl
            } else {
                url = this.areaDefectUrl
            }
            this.restFetchParams(url, this.lastSearchedParams, this.handleResponse)
            },

        handleResponse: function (response) {
            this.defects = response.data
            this.loading = false
        },

        editDefect: function (defect) {
            this.defect = JSON.parse(JSON.stringify(defect))
            this.editMode = true
        },

        addDefect: function () {
            this.defect = this.newDefect()
            this.editMode = true
        },

        showDefectList: function () {
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.showDefectList()
            this.fetchDefects(this.lastSearchedParams)
        }
    }
}
</script>

<style scoped>

</style>
