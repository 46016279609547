<template>
    <div class="col-sm-12 nopads pt-2">
            <transition name="fade">
                <keep-alive>
                    <cable-search
                        :user="user"
                        @search="fetchCables"
                    />
                </keep-alive>
            </transition>
            <transition name="fade">
                <div class="col-sm-12">
                    <div
                        class="button-container col-sm-12 trip-buttons"
                    >
                        <b-button
                            size="sm"
                            variant="outline-primary"
                            class="result-button"
                            :disabled="loading"
                            @click="importFromFile"
                        >
                            {{ $t('common.import_from_file') }}
                        </b-button>
                        <b-button
                            size="sm"
                            v-if="results.length > 0"
                            variant="outline-success"
                            :disabled="results.length < 1"
                            class="result-button"
                            @click="exportExcel"
                        >
                            {{ $t('trip.download_excel') }}
                        </b-button>
                    </div>
                    <cable-list
                        v-if="!cable"
                        :cables="results"
                        @rowClicked="editCable"
                    />
                </div>
            </transition>

            <cable-editor
                v-if="cable != null"
                :cable="cable"
                @close="cable=null"
                @closeAndUpdate="closeAndUpdate"
            />

            <cable-importer
                v-if="showFileImporter"
                @close="showFileImporter = false"
                @closeAndUpdate="hideFileImporter"/>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {downloadHelper} from "../mixins/DownloadMixin";
import CableSearch from "@/components/streetlights/CableSearch";
import CableEditor from "@/components/streetlights/CableEditor";
import CableList from "@/components/streetlights/CableList";
import CableImporter from "@/components/streetlights/CableImporter";

export default {
    name: 'Cables',
    components: {
        CableImporter,
        CableList,
        CableEditor, CableSearch},
    mixins: [restApi, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            results: [],
            cable: null,
            loading: false,
            lastSearchedParams: null,
            showFileImporter: false
        }
    },

    methods: {
        fetchCables: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.cableUrl, this.lastSearchedParams, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        },
        editCable: function (cable) {
            this.cable = cable
        },
        closeAndUpdate: function () {
            this.cable = null
            this.fetchCables(this.lastSearchedParams)
        },
        importFromFile() {
            this.showFileImporter = true
        },
        hideFileImporter() {
            this.showFileImporter = false
            this.fetchCables(this.lastSearchedParams)
        },
        exportExcel() {
            // TODO
        }
    }
}
</script>
