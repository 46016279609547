<template>
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <!-- Main table element -->
            <div class="col-sm-12 list-content result-table">
                <b-table
                    small
                    id="resultTable"
                    ref="resultTable"
                    :items="results"
                    :fields="fields"
                    @row-clicked="emitDetails"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(created)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                    <template v-slot:cell(fixed)="row">
                        {{ row.value ? toLocalTime(row.value) : '' }}
                    </template>
                    <template
                        slot="status"
                        slot-scope="row"
                    >
                        {{ row.value === 1 ? $t('area_report.status_open') : $t('area_report.completed') }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'VehicleDefectList',
    mixins: [timeUtils],
    props: {
        items: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            sortBy: 'vehicle.lisence_plate',
            sortDesc: true,
            sortDirection: 'asc',
            fields: [
                {key: 'created', label: this.$t('defects.created'), sortable: true},
                {key: 'license_plate', label: this.$t('vehicle_defects.license_plate'), sortable: true},
                {key: 'vehicle', label: this.$t('vehicle_defects.vehicle'), sortable: true},
                {key: 'reporter', label: this.$t('defects.reporter'), sortable: true},
                {key: 'inspectable', label: this.$t('vehicle_defects.inspectable'), sortable: true},
                {key: 'description', label: this.$t('defects.description'), sortable: true},
                {key: 'status', label: this.$t('defects.status'), sortable: true},
                {key: 'fixed', label: this.$t('defects.fixed'), sortable: true},
                {key: 'fixed_by', label: this.$t('defects.fixed_by'), sortable: true},
            ],
            results: []
        }
    },
    watch: {
        items: function () {
            this.initTableItems()
        }
    },
    methods: {
        initTableItems: function () {
            this.results = []
            this.items.forEach(item => {
                this.results.push({
                    id: item.id,
                    license_plate: item.vehicle.license_plate,
                    vehicle: this.getVehicleString(item.vehicle),
                    reporter: item.reporter.last_name + ', ' + item.reporter.first_name,
                    created: item.created_date,
                    description: item.description,
                    inspectable: item.inspectable_name,
                    status: this.getDefectStatusString(item.status),
                    fixed: item.fixed_date,
                    fixed_by: item.fixer ? item.fixer.last_name + ', ' + item.fixer.first_name : '',
                    _rowVariant: this.getDefectRowVariant(item.status)
                })
            })
        },
        emitDetails: function (result) {
            // Find defect item from original items and emit request to edit
            let p = this.items.find(item => item.id === result.id)
            if (p) {
                this.$emit('edit', p)
            }
        },
        getDefectRowVariant: function (status) {
            switch (status) {
                case 3:
                    return 'warning'
                case 4:
                    return 'danger'
            }
            return ''
        },
        getDefectStatusString: function (status) {
            switch (status) {
                case 2:
                    return this.$t('inspection.options.okay')
                case 3:
                    return this.$t('inspection.options.defects')
                case 4:
                    return this.$t('inspection.options.ban')
            }
            return '-'
        },
        getVehicleString: function (vehicle) {
            let result = '-'
            if (vehicle && vehicle.make) {
                result = vehicle.make
                if (vehicle.vehicle_model) {
                    result += ' ' + vehicle.vehicle_model
                }
            }
            return result
        }
    }
}
</script>

<style scoped>

</style>
