<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="additionalCostUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('additional_cost.edit') }}
                </h2>
                <h2 v-else
                    class="form-title editor-title"
                >
                    {{ $t('additional_cost.add') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <!-- Users -->
                <div
                    v-if="additionalCostUpdate.id < 1 && (isAdmin || isObserver)"
                    class="nopads pr-1 pl-1"
                >
                      <span class="span-title">
                        {{ $t('work_time.user') }}
                      </span>
                    <b-form-select
                        v-model="additionalCostUpdate.user.id"
                        size="mb"
                        class="mb-3"
                        :disabled="!users || users.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('work_time.select_user') }}
                            </option>
                        </template>
                        <option
                            v-for="item in users"
                            :key="item.id"
                            :value="item.id"
                        >
                            {{ item.last_name }}, {{ item.first_name }}
                        </option>
                    </b-form-select>
                </div>
                <div v-if="additionalCostUpdate.id > 0"
                >
                    <h3>
                        {{ additionalCostUpdate.user.first_name + ' ' + additionalCostUpdate.user.last_name }}
                    </h3>
                </div>
                <div class="pr-1 pl-1">
                    <b-form-group
                        class="nopads"
                        label-for="type"
                    >
                        <span class="search-title">{{ $t('delivery_note.contract') }}</span>
                        <b-form-select
                            size="sm"
                            v-model="selectedContract"
                            :disabled="!!loading"
                        >
                            <option :value="null">
                                {{ $t('delivery_note.form.select_contract') }}
                            </option>
                            <option
                                v-for="contract in contracts"
                                :key="contract.id"
                                :value="contract.id"
                            >
                                {{ contract.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div class="pr-1 pl-1">
                    <contract-order-filter
                        v-model="selectedOrder"
                        :pre-selected-order="additionalCostUpdate.work_order ? additionalCostUpdate.work_order.id : null"
                        :contract-id="selectedContract"
                        :show-executor-text="true"
                    />
                </div>

                <div class="pr-1 pl-1">
                    <b-form-group
                        class="nopads"
                        label-for="type"
                    >
                        <span class="span-title">{{ $t('additional_cost.type') }}</span>
                        <b-form-select
                            id="type"
                            v-model="additionalCostUpdate.type"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="null">
                                {{ $t('work_permission.select_type') }}
                            </option>
                            <option
                                v-for="option in getAdditionalCostType()"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div>
                    <b-form-group
                        class="title"
                        label-for="amount"
                    >
                        <span class="span-title">{{ $t('additional_cost.amount') }}</span>
                        <b-form-input
                            id="amount"
                            v-model="additionalCostUpdate.amount"
                            :placeholder="$t('additional_cost.amount')"
                            type="number"
                            size="mb"
                        />
                    </b-form-group>
                </div>

                <div>
                    <b-form-group
                        class="title"
                        label-for="unit"
                    >
                        <span class="span-title">{{ $t('additional_cost.unit') }}</span>
                        <b-form-input
                            id="unit"
                            v-model="additionalCostUpdate.unit"
                            :placeholder="$t('additional_cost.unit')"
                            type="text"
                            size="mb"
                        />
                    </b-form-group>
                </div>

                <div>
                    <b-form-group
                        class="title"
                        label-for="unit_price"
                    >
                        <span class="span-title">{{ $t('additional_cost.unit_price') }}</span>
                        <b-form-input
                            id="unit_price"
                            v-model="additionalCostUpdate.unit_price"
                            :placeholder="$t('additional_cost.unit_price')"
                            type="number"
                            size="mb"
                        />
                    </b-form-group>
                </div>

                <!-- Info -->
                <div class="col-sm-12 nopads">
                      <span class="span-title">
                        {{ $t('work_time.note') }}
                      </span>
                    <b-form-textarea
                        id="info"
                        v-model="additionalCostUpdate.info"
                        :placeholder="$t('work_time.note')"
                        :rows="4"
                        :max-rows="6"
                    />
                </div>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        v-if="isObserver && additionalCostUpdate.id>0"
                        variant="primary"
                        class="routa-button"
                        :disabled="!!loading"
                        @click.stop="handleApproval(3)"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('work_permission.reject') }}</span>
                    </b-button>
                    <b-button
                        v-if="isObserver && additionalCostUpdate.id>0"
                        variant="primary"
                        class="routa-button"
                        :disabled="!!loading"
                        @click.stop="handleApproval(2)"
                    >
                        <i class="fa fa-check"/>
                        <span class="button-text">{{ $t('daily_allowance.approve_allowance') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="!!loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="!!loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {additionalCostHelper} from "@/components/mixins/AdditionalCostMixin"
import {orderMixin} from "@/components/mixins/OrderMixin";
import ContractOrderFilter from "../order/ContractOrderFilter";

export default {
    name: 'additional-cost-editor',
    components: {ContractOrderFilter},
    mixins: [restApi, additionalCostHelper, orderMixin],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean,
        additionalCost: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: 0,
            users: [],
            additionalCostUpdate: this.newAdditionalCost(this.additionalCost),
            contracts: [],
            selectedContract: null,
            selectedOrder: null
        }
    },

    watch: {
        additionalCost() {
            if (this.additionalCost) {
                this.additionalCostUpdate = this.additionalCost
                if (this.additionalCost.work_order) {
                    this.selectedOrder = this.additionalCost.work_order
                    this.selectedContract = this.additionalCost.work_order.contract.id
                }
            }
        }
    },

    mounted: function () {
        this.additionalCostUpdate = this.additionalCost ? this.additionalCost : this.newAdditionalCost()
        if (this.additionalCost && this.additionalCost.work_order) {
            this.selectedOrder = this.additionalCost.work_order
            this.selectedContract = this.additionalCost.work_order.contract.id
        }
        this.fetchUsers()
        this.fetchContracts()
    },

    methods: {
        fetchContracts: function () {
            this.loading++
            let params = {open: 1}
            this.restFetchParams(this.contractUrl, params, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading--
            this.contracts = response ? response.data : []
            if (this.additionalCostUpdate.work_order &&
                this.additionalCostUpdate.work_order.contract &&
                this.contracts.find(item => item.id === this.additionalCostUpdate.work_order.contract.id) === null) {
                this.contracts.push(this.additionalCostUpdate.work_order.contract)
            }
        },
        fetchUsers: function () {
            this.loading++
            this.restFetch(this.userUrl, this.handleUsers)
        },
        handleUsers: function (response) {
            this.loading--
            this.users = response.data
        },
        submit: function () {
            if (!this.selectedContract) {
                EventBus.$emit('show-alert', this.$t('ins_wizard.select_contract'))
                return
            } else {
                this.additionalCostUpdate.contract = {id: this.selectedContract}
            }
            if (!this.selectedOrder) {
                EventBus.$emit('show-alert', this.$t('ins_wizard.select_order'))
                return
            } else {
                this.additionalCostUpdate.work_order = this.selectedOrder
            }
            if (!this.additionalCostUpdate.user) {
                EventBus.$emit('show-alert', this.$t('trip_list.select_user'))
                return
            }
            if (!this.additionalCostUpdate.type) {
                EventBus.$emit('show-alert', this.$t('additional_cost.select_type'))
                return
            }
            if (!this.additionalCostUpdate.amount) {
                EventBus.$emit('show-alert', this.$t('additional_cost.enter_amount'))
                return
            }
            if (!this.additionalCostUpdate.unit) {
                EventBus.$emit('show-alert', this.$t('additional_cost.enter_unit'))
                return
            }
            if (!this.additionalCostUpdate.unit_price) {
                EventBus.$emit('show-alert', this.$t('additional_cost.enter_unit_price'))
                return
            }
            if (this.selectedContract) {
                this.additionalCostUpdate.contract = {id: this.selectedContract}
            }
            this.loading++
            if (this.additionalCostUpdate.id > 0) {
                this.restUpdate(this.additionalCostUrl, this.additionalCostUpdate, this.success, this.fail)
            } else {
                this.restAdd(this.additionalCostUrl, this.additionalCostUpdate, this.success, this.fail)
            }
        },
        success: function () {
            this.loading--
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading--
            if (this.additionalCostUpdate.id < 0) {
                EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
            } else {
                EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            }
        },
        handleApproval: function (status) {
            this.restUpdate(this.additionalCostUrl + "/approval/" + this.additionalCostUpdate.id,
                {status: status}, this.success, this.fail)
        }
    }
}
</script>
