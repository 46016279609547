export const selectFromMapMixin = {
    methods: {
        /**
         * @param {{point} | {line_string: {points}}} geometry
         * @param polygon
         * @returns {boolean}
         */
        isGeometryInPolygon(geometry, polygon) {
            if (geometry.point != null) return this.isValueInPolygon(geometry.point, polygon)
            if (geometry.line_string != null && geometry.line_string.points != null)
                return this.isValueInPolygon(geometry.line_string.points, polygon, true)
            return false
        },

        /**
         * @param {{x: number, y: number} | {x: number, y: number}[] | [number, number] | [number, number][]} value
         * @param polygon
         * @param {boolean} partial
         * @returns {boolean}
         */
        isValueInPolygon(value, polygon, partial = false) {
            if (!Array.isArray(value)) {
                return this.isPointInPolygon(value.y, value.x, polygon)
            }
            if (value.length === 0) return false;
            if (typeof value[0] === 'number') {
                return this.isPointInPolygon(value[1], value[0], polygon)
            }
            if (partial) {
                return value.findIndex(val => this.isValueInPolygon(val, polygon)) !== -1
            } else {
                return value.every(val => this.isValueInPolygon(val, polygon))
            }
        },

        isPointInPolygon (latitude, longitude, polygon) {
            if (typeof latitude !== 'number' || typeof longitude !== 'number') {
                throw new TypeError('Invalid latitude or longitude. Numbers are expected')
            } else if (!polygon || !Array.isArray(polygon)) {
                throw new TypeError('Invalid polygon. Array with locations expected')
            } else if (polygon.length === 0) {
                throw new TypeError('Invalid polygon. Non-empty Array expected')
            }

            const x = longitude; const y = latitude

            let inside = false
            for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
                const xi = polygon[i][0]; const yi = polygon[i][1]
                const xj = polygon[j][0]; const yj = polygon[j][1]

                const intersect = ((yi > y) !== (yj > y)) &&
                    (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
                if (intersect) inside = !inside
            }

            return inside
        },
    },
}
