<template>
    <div class="col-sm-12">
        <h2>{{ userAssignments ? $t('work_assignment.user_assignments') : $t('work_assignment.open_assignments') }}</h2>
        <div class="button-container">
            <b-button
                variant="outline-primary"
                class="result-button"
                @click="toggleList"
            >
                {{ userAssignments ? $t('work_assignment.open_assignments') : $t('work_assignment.user_assignments') }}
            </b-button>
        </div>
        <div v-if="results && results.length > 0" class="col-sm-12 nopads">
            <!-- Assignments -->
            <work-assignment-user-list
                :results="results"
                @closeAndUpdate="fetchAssignments"
            />
        </div>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import WorkAssignmentUserList from './WorkAssignmentUserList'
import {userHelper} from '../mixins/UserMixin'

export default {
    name: 'WorkAssignmentsUser',
    components: {WorkAssignmentUserList},
    mixins: [restApi, userHelper],
    data: function () {
        return {
            loading: true,
            userAssignments: true,
            results: []
        }
    },
    mounted: function () {
        this.fetchAssignments();
    },
    methods: {
        fetchAssignments: function () {
            this.results = []
            if (this.userAssignments) {
                this.fetchUserAssignments()
            } else {
                this.fetchUnassigned()
            }
        },
        toggleList: function () {
            this.userAssignments = !this.userAssignments
            this.fetchAssignments()
        },
        fetchUserAssignments: function () {
            this.loading = true
            this.restFetch(this.workAssignmentUserUrl, this.handleResponse)
        },
        fetchUnassigned: function () {
            this.loading = true
            this.restFetch(this.workAssignmentUnassignedUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            if (response && response.status === 200) {
                this.results = response.data
            } else {
                this.error = 'Error'
            }
            this.loading = false
        }
    }
}
</script>
