<template>
    <div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
            <span class="span-title">
              {{ $t('orders.status') }}
            </span>
                        <b-form-select
                            id="status"
                            v-model="status"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option :value="0">
                                {{ $t('inspection.options.open') }}
                            </option>
                            <option :value="2">
                                {{ $t('inspection.options.okay') }}
                            </option>
                            <option :value="3">
                                {{ $t('inspection.options.defects') }}
                            </option>
                            <option :value="4">
                                {{ $t('inspection.options.ban') }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="start"
                    >
                        <span class="span-title">
                          {{ $t('orders.create_time') }} - {{ $t('trip_list.road_sec_from') }}
                        </span>
                        <b-form-input
                            id="start"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="end"
                    >
                        <span class="span-title">
                          {{ $t('orders.create_time') }} - {{ $t('trip_list.road_sec_to') }}
                        </span>
                        <b-form-input
                            id="end"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'SingleVehicleDefectSearch',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            status: 0,
            fromDate: null,
            toDate: null,
        }
    },
    props: {
        vehicle: {
            type: Object
        }
    },
    methods: {
        onSubmit: function () {
            var params = {}
            if (this.status >= 0) {
                params.status = this.status
            }
            params.vehicle = this.vehicle.id
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.dateFrom = this.localTimeToUtcTime(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.dateTo = this.getEndOfTheDay(this.toDate).getTime()

            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.status = 0
            this.fromDate = null
            this.toDate = null
        }
    }
}
</script>

<style scoped>

</style>
