<template>
    <div>
        <span class="span-title">{{ $t('daily_summary.invoice_state') }}</span>
        <b-form-select
            id="invoiceState"
            v-model="selectedInvoiceState"
            size="sm"
            class="mb-3"
        >
            <template slot="first">
                <option :value="null">
                    {{ $t('daily_summary.select_invoice_state') }}
                </option>
            </template>
            <option
                v-for="option in invoiceStateOptions"
                :key="option.value"
                :value="option.value"
            >
                {{ option.text }}
            </option>
        </b-form-select>
    </div>
</template>

<script>
export default {
    name: "InvoiceStateFilter.vue",
    props: {
        value: {
            type: Object,
            default: null
        },
        invoiceStateOptions: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data: function () {
        return {
            loading: false,
            selectedInvoiceState: null
        }
    },
    watch: {
        selectedInvoiceState: function (){
            let invoiceState = this.invoiceStateOptions.find(item => item.value === this.selectedInvoiceState)
            this.$emit('input', invoiceState)
        }
    },
}
</script>
