<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <table class="table table-sm table-fixed">
        <tbody>
        <tr>
            <td class="item-detail-title">
                {{ $t('orders.contract') }}
            </td>
            <td class="item-detail-text">
                <span v-if="contract.name">{{ contract.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.number') }}
            </td>
            <td class="item-detail-text">
                <span v-if="contract.contract_number">{{ contract.contract_number }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.customer') }}
            </td>
            <td class="item-detail-text">
                <span v-if="contract.customer">{{ contract.customer.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.contractor') }}
            </td>
            <td class="item-detail-text">
                <span v-if="contract.contractor">{{ contract.contractor.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.start') }}
            </td>
            <td class="item-detail-text">
                <span v-if="contract.start_date">{{ toLocalTime(contract.start_date) }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('contracts.end') }}
            </td>
            <td class="item-detail-text">
                <span v-if="contract.end_date">{{ toLocalTime(contract.end_date) }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'TargetInfoContract',
    mixins: [timeUtils],
    props: {
        contract: {
            type: Object,
            default: null
        }
    }
}
</script>
