export const attachmentHelper = {
    methods: {
        isAttachmentSet: function (attachments, index) {
            if (index >= 0 && index < attachments.length) {
                return attachments[index] !== undefined && attachments[index] !== null
            }
            return false
        },
        getValidAttachments: function (attachments) {
            let validAttachments = []
            if (attachments) {
                for (let i = 0; i < attachments.length; i++) {
                    if (attachments[i] !== undefined && attachments[i] !== null) {
                        validAttachments.push(attachments[i])
                    }
                }
            }
            return validAttachments
        },
        saveFile: function (fileName, base64File) {
            let a = document.createElement('a')
            document.body.appendChild(a)
            a.style = 'display: none'
            a.href = base64File
            a.download = fileName
            a.click()
        },
        getValidAttachmentTypes:function (){
            return "image/jpeg, image/jpg, image/png, application/pdf, ms-word," +
                ".doc, .docx, .csv, .xls, .xlsx, text/csv, application/csv," +
                "text/comma-separated-values, application/csv, application/excel," +
                "application/vnd.msexcel, text/anytext, application/vnd. ms-excel," +
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    }
}
