<template>
    <div>
        <div
            v-if="this.sideLoading"
        >
            <div
                id="sideloader"
                class="spinner"
            >
            </div>
            <span> {{ $t('license_report.username_loading') }}</span>

        </div>
        <div
            v-else
        >
            <span>{{ $t('license_report.username_label') }}: {{ this.selectedUser }}</span>
            <b-table
                :items="selectedUserDataMapped"
                :bordered="true"
                :fields="fields"
                :small="true"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {userHelper} from '../mixins/UserMixin'

export default {
    name: "LicenseRightsInspector",
    mixins: [restApi, userHelper],
    data: function () {
        return {
            sideLoading: true,
            userdata: [],
            resultready: true,
            submitparams: [],
            previoususer: null,
            selectedUserData: null,
            temp: {}
        }
    },
    props: {
        selectedUser: {
            type: String,
            default: ''
        }
    },
    watch: {
        selectedUser(){
            this.sideLoading = true
            this.newUserSelected()
        }
    },
    computed: {
        fields() {
            return [
                {key: 'rolename', label: this.$t('license_report.rights_inspector_role_label'), sortable: true},
                {key: 'info', label: this.$t('license_report.rights_inspector_role_info_label'), sortable: true},
                {key: 'issuer', label: this.$t('license_report.rights_inspector_role_issuer_label'), sortable: true},
                {key: 'issuedAt', label: this.$t('license_report.rights_inspector_role_issued_label'), sortable: true},
                {key: 'remover', label: this.$t('license_report.rights_inspector_role_remover_label'), sortable: true},
                {key: 'removedAt', label: this.$t('license_report.rights_inspector_role_removed_label'), sortable: true}
            ]
        },
        selectedUserDataMapped() {
            return this.selectedUserData.map(right =>{
                let temp = {}
                temp['info'] = right.info
                temp['rolename'] = this.getRoleName(right.role.role)
                temp['info'] = right.info
                if (right.issued_by != null && right.issued_by.first_name != null && right.issued_by.last_name != null){
                    temp['issuer'] = `${right.issued_by.first_name} ${right.issued_by.last_name}`
                }
                else {
                    temp['issuer'] = 'Undefined'
                }

                if (right.issued_at != null){
                    temp['issuedAt'] = right.issued_at
                }
                else {
                    temp['issuedAt'] = 'Undefined'
                }

                if(right.removed_by != null && right.removed_by.first_name != null && right.removed_by.last_name != null){
                    temp['remover'] = `${right.removed_by.first_name} ${right.removed_by.last_name}`
                }
                else {
                    temp['remover'] = ''
                }

                if(right.removed_at != null)
                {
                    temp['removedAt'] = right.removed_at
                }
                else {
                    temp['removedAt'] = ''
                }
                return temp
            })
        }
    },
    mounted: function () {
        this.fetchUsers()
    },
    beforeUpdate() {
        if (this.selectedUser !== null) {
            this.selectedUserDataMapped.forEach(row => {
                if (row.removedAt !== '') {
                    row._rowVariant = 'danger'
                }
            })
        }
    },
    methods: {
        fetchUsers: function () {
            this.sideLoading= true
            this.restFetch(this.userUrl, this.callbackUsersFetched)
        },
        callbackUsersFetched: function (response) {
            this.userdata = response.data
            this.newUserSelected()
        },
        newUserSelected: function () {
            this.previoususer = this.selectedUser
            this.selectedUserData = this.userdata.find(el => el.username === this.selectedUser).rights
            this.sideLoading = false
        },
    }
}

</script>
