<template>
    <div class="content-container">
        <!-- Top bar-->
        <b-navbar
            variant="faded"
        >
            <div
                class="header"
            >
              <span class="title">
                {{ $t('menu.material_stations') }}
              </span>
            </div>
        </b-navbar>

        <material-station-search
            @search="fetchStations"
        />

        <div class="col-sm-12 list-container">
            <!-- Add button-->
            <div
                class="data-list-item add"
                v-if="isObserver"
                @click.stop="addNew"
            >
                <div class="item-img-cont add-title">
                    <i class="fa fa-plus"/>
                </div>
                <div class="item-text-cont">
                    <span class="item-title-add">{{ $t('common.add_new') }}</span>
                    <font-awesome-icon
                        icon="lock"
                        class="card-body-icon"
                    />
                </div>
            </div>
            <material-station-list
                :stations="stations"
                @rowClicked="editStation"
            />
        </div>

        <!-- Editor-->
        <material-station-modal
            v-model="station"
            @cancel="station = null"
            @closeAndUpdate="closeAndUpdate"
            :is-observer="isObserver"
        />

        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import MaterialStationSearch from "@/components/materials/station/MaterialStationSearch";
import MaterialStationList from "@/components/materials/station/MaterialStationList";
import MaterialStationModal from "@/components/materials/station/MaterialStationModal";
import {workManagementHelper} from "@/components/mixins/WorkManagementMixin";

export default {
    name: 'material-stations',
    mixins: [restApi, workManagementHelper],
    props: {
        isObserver: {
            type: Boolean,
            default: null
        },
    },
    components: {
        MaterialStationModal,
        MaterialStationList,
        MaterialStationSearch
    },
    data: function () {
        return {
            loading: false,
            stations: [],
            station: null
        }
    },
    created: function () {
        this.fetchStations()
    },
    methods: {
        fetchStations: function (params) {
            this.loading = true
            this.restFetchParams(this.materialStationUrl, params, this.handleResponse, this.onError)
        },
        handleResponse: function (response) {
            this.stations = response.data
            this.loading = false
        },
        onError: function () {
            this.loading = false
            this.stations = []
        },
        editStation: function (station) {
            if (this.isObserver) {
                this.station = station
                this.editMode = true
            }
        },
        closeAndUpdate: function () {
            this.station = null
            this.stations = []
            this.fetchStations()
        },
        addNew: function () {
            this.station = this.newMaterialStation()
        }
    }
}
</script>
