<template>
    <b-row class="col-sm-12">
        <div class="col-xl-6 nopads p-1">
            <!--OPEN-->
            <vehicle-defect-summary-values
                :header="$t('defects.open_defect')"
                :defect="openDefect"
                :ban="openBan"
                :average-age="openAverageAge"/>
        </div>
        <div class="col-xl-6 nopads p-1">
            <!--CLOSED-->
            <vehicle-defect-summary-values
                :header="$t('defects.closed_defect')"
                :defect="closedDefect"
                :ban="closedBan"
                :average-age="closedAverageAge"/>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </b-row>

</template>



<script>
import {vehicleHelper} from '../../mixins/VehicleMixin'
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";
import vehicleDefectSummaryValues from "@/components/vehicle/defect/VehicleDefectSummaryValues.vue";

export default {
    name: 'VehicleStatistic',
    components: {vehicleDefectSummaryValues},
    mixins: [vehicleHelper, restApi, timeUtils],
    props: {
        isAdmin: Boolean,
        result: {
            type: Object,
            default: null
        },
        defectData: {
            type: Array,
            default() {
                return []
            }
        },
        vehicleId: Number,
        editMode: Boolean,
        isObserver: Boolean,
    },
    data: function () {
        return {
            loading: false,
            openDefect: 0,
            openBan: 0,
            openAverageAge: '0',
            closedDefect: 0,
            closedBan: 0,
            closedAverageAge: '0',
        }
    },

    watch: {
        defectData: function (data) {
            this.generateSummaries(data)
        }
    },

    methods: {
        generateSummaries(defects) {
            let closedAmount = 0
            let openAmount = 0
            this.closedBan = 0
            this.closedDefect = 0
            this.closedAverageAge = 0
            this.openBan = 0
            this.openDefect = 0
            this.openAverageAge = 0
            defects.forEach(defect => {
                if (defect.fixed_date) {
                    closedAmount++
                    defect.status === 4 ? this.closedBan++ : this.closedDefect++
                    this.closedAverageAge += this.getDurationInMinutes(new Date(defect.created_date), new Date(defect.fixed_date))
                } else if (defect.created_date) {
                    openAmount++
                    defect.status === 4 ? this.openBan++ : this.openDefect++
                    this.openAverageAge += this.getDurationInMinutes(new Date(defect.created_date), new Date())
                }
            })
            this.closedAverageAge = isNaN(this.closedAverageAge /closedAmount) ? "0" : this.getWorkDays(this.closedAverageAge /closedAmount)
            this.openAverageAge = isNaN(this.openAverageAge /openAmount) ? "0" : this.getWorkDays(this.openAverageAge /openAmount)
        },

    }

}
</script>
