<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="updateRecord.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('common.edit') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('common.add_new') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <div class="nopads pl-1 pr-1">
                    <span class="span-title">
                      {{ $t('users.company') }}
                    </span>
                    <b-form-select
                        id="company"
                        v-model="company"
                        size="sm"
                        class="mb-3"
                        :disabled="!companies || companies.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('users.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </b-form-select>
                    </div>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">
                            {{ $t('common.id') }}
                        </span>
                        <b-form-input
                            id="name"
                            v-model="updateRecord.external_id"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">
                              {{ $t('common.name') }}
                        </span>
                        <b-form-input
                            id="name"
                            v-model="updateRecord.external_name"
                            type="text"
                            size="sm"
                        />
                    </div>
                </b-row>
                <span class="span-title">{{ $t('inspection_editor.inactive') }}</span>
                <b-form-checkbox
                    id="in_active"
                    v-model="updateRecord.inactive"
                    checked="true"
                    unchecked-value="false"
                    size="sm"
                />
                <!-- Buttons -->
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="form-button"
                        @click.stop="$emit('close')"
                    >
                       {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="form-button"
                        :disabled="loading || !company || !updateRecord.external_name || !updateRecord.external_id"
                        @click.stop="submit"
                    >
                          {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: 'ExternalContractIdEditor',
    mixins: [restApi],
    props: {
        record: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            company: null,
            companies: [],
            updateRecord: null,
        }
    },

    created: function () {
        this.updateRecord = this.record
        this.fetchCompanies()
        this.company = this.updateRecord.company ? this.updateRecord.company.id : null
    },

    methods: {
        fetchCompanies: function () {
            this.loading = true
            this.restFetch(this.companyUrl, this.handleCompanies)
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        submit: function () {
            this.loading = true
            this.updateRecord.company = {id: this.company}
            if (this.updateRecord.id < 1) {
                this.restAdd(this.extContractIdUrl, this.updateRecord, this.success, this.fail)
            } else {
                this.restUpdate(this.extContractIdUrl, this.updateRecord, this.success, this.fail)
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>

