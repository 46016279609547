export const orderMixin = {
    methods: {
        getOrderStr: function (order) {
            let str = ''
            if (order.task_type) {
                str = order.task_type.name
            }else {
                str = order.contract_task_type.task_type.name
            }
            if (order.object_number) {
                str += " " + this.$t('orders.object') + " " + order.object_number
            }
            if (order.info) {
                str += " - " + order.info.replace(/(.{15})..+/, "$1…")
            }
            return str
        },
        getOrderAndExecutorStr: function (order) {
            let str = ''
            if (order.task_type) {
                str = order.task_type.name
            }else {
                str = order.contract_task_type.task_type.name
            }
            if (order.executors && order.executors.length > 0) {
                str += " ("
                let executorsStr = ""
                order.executors.forEach(item => {
                    if (item.company && item.company.name) {
                        if (executorsStr.length > 0) {
                            executorsStr += ", "
                        }
                        executorsStr += item.company.name
                    }
                })
                str += executorsStr + ")"
            }
            return str
        },
        isOrderOpen(order) {
            return order !== null && order.status < 3 && order.status > 0
        },
        getOrderInfo(order) {
            if (order.info) {
                return order.info.length > 40 ? (order.info.substr(0, 35) + '...') : order.info
            }
            return ''
        },
        getOrderStringWithInfo(order) {
            let result = this.getOrderAndExecutorStr(order)
            result += order.info ? ' - ' + this.getOrderInfo(order) : ''
            return result
        }
    }
}
