export const results = {
    resultSkip: 1,
    resultOk: 2,
    resultDefect: 3,
    resultBan: 4
}

export const inspectionState = {
    resultOk: 1,
    resultDefect: 2,
    resultBan: 3,
    resultSkip: 4
}

export const areaReportHelper = {
    methods: {
        newAreaReport: function () {
            return {
                id: -1,
                area: null,
                note: '',
                created_time: null,
                equipment_inspection_results: []
            }
        },

        copyReport: function (areaReport) {
            return JSON.parse(JSON.stringify(areaReport))
        },

        getEquipmentResult: function (equipmentInspectionResult) {
            switch (equipmentInspectionResult) {
                case results.resultSkip:
                    return this.$t('equipment_report.options.skipped')
                case results.resultOk:
                    return this.$t('equipment_report.options.ok')
                case results.resultDefect:
                    return this.$t('equipment_report.options.defects')
                case results.resultBan:
                    return this.$t('equipment_report.options.ban')
            }
        },

        isOk: function (result) {
            return result === results.resultOk
        },

        isDefect: function (result) {
            return result === results.resultDefect
        },

        isBanned: function (result) {
            return result === results.resultBan
        },

        newAreaEquipmentReport: function () {
            return {
                id: -1,
                equipment: null,
                result: null,
                value: '',
                info: '',
                photos: Array(5)
            }
        },
        getCompletedTime: function (date) {
            if (date === undefined || date === null || date === '') {
                return '-'
            }
            return this.$d(new Date(date)) + ' ' + this.$d(new Date(date), 'short')
        },
        getReportPhotoUrl: function (resultId, photo) {
            if (photo && photo.id) {
                return this.areaReportPhotoUrl + resultId + '/' + photo.id
            }
            return null
        },
        getAreaPolygon: function (area) {
            let result = []
            if (area.geometry && area.geometry.rings) {
                let coordinates = area.geometry.rings[0]
                coordinates.forEach(point => {
                    result.push({
                        latitude: point[1],
                        longitude: point[0]
                    })
                })
            }
            return result
        }
    }
}
