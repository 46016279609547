
export const edgePileHelper = {
    methods: {
        getEdgePileTypes: function () {
            return [
                {name: this.$t('edge_pile.types.edge_pile'), value: edgePileTypes.EDGE_PILE},
                {name: this.$t('edge_pile.types.connection'), value: edgePileTypes.CONNECTION},
                {name: this.$t('edge_pile.types.other'), value: edgePileTypes.OTHER},
                {name: this.$t('edge_pile.types.plowing_sign'), value: edgePileTypes.PLOWING_SIGN},
            ]
        },
        getEdgePileColors: function () {
            return [
                {name: this.$t('edge_pile.colors.white'), value: edgePileColors.WHITE},
                {name: this.$t('edge_pile.colors.yellow'), value: edgePileColors.YELLOW},
                {name: this.$t('edge_pile.colors.red'), value: edgePileColors.RED},
                {name: this.$t('edge_pile.colors.orange'), value: edgePileColors.ORANGE},
                {name: this.$t('edge_pile.colors.blue'), value: edgePileColors.BLUE},
                {name: this.$t('edge_pile.colors.black'), value: edgePileColors.BLACK},
                {name: this.$t('edge_pile.colors.other'), value: edgePileColors.OTHER}
            ]
        },
        getEdgePileMaterials: function () {
            return [
                {name: this.$t('edge_pile.materials.aluminium'), value: edgePileMaterials.ALUMINIUM},
                {name: this.$t('edge_pile.materials.wood'), value: edgePileMaterials.WOOD},
                {name: this.$t('edge_pile.materials.plastic'), value: edgePileMaterials.PLASTIC},
                {name: this.$t('edge_pile.materials.metal'), value: edgePileMaterials.METAL},
            ]
        },
    }
}

export const edgePileTypes = {
    EDGE_PILE: 1,
    CONNECTION: 2,
    OTHER: 3,
    PLOWING_SIGN: 4,
}

export const edgePileColors = {
    WHITE: 1,
    YELLOW: 2,
    RED: 3,
    ORANGE: 4,
    BLUE: 5,
    BLACK: 6,
    OTHER: 7
}

export const edgePileMaterials = {
    ALUMINIUM: 1,
    WOOD: 4,
    PLASTIC: 10,
    METAL: 17,
}



