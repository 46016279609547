<template>
    <div
        id="container"
        class="col-sm-12 nopads"
    >
        <transition name="fade">
            <b-form
                v-if="inspectionTemplate!=null && !loading"
                class="col-sm-12 editor-form"
            >
                <div class="col-sm-12 form-title editor-title">
                    <h2 class="form-title editor-title">
                        {{ getTitle(inspectionTemplate.type) }}
                    </h2>
                    <span class="form-sub-title">{{ vehicle.make }} {{
                            vehicle.vehicle_model
                        }} - {{ vehicle.license_plate }}</span>
                </div>
                <div class="col-sm-12 form-data">
                    <div
                        v-for="(item, index) of inspectables"
                        :key="item.name"
                    >
                        <b-form-group
                            class="span-title"
                            :label="item.name"
                            :label-for="item.name"
                        >
                            <b-form-select
                                v-model="inspectablesValues[index]"
                                :options="options"
                                size="mb"
                            />
                        </b-form-group>
                        <div
                            v-if="infoVisible(index)"
                            class="info"
                        >
                            <b-form-group
                                :label="$t('inspection.additional_info')"
                                class="span-title"
                                label-for="info"
                            >
                                <b-form-textarea
                                    id="info"
                                    v-model="inspectablesInfo[index]"
                                    :placeholder="$t('inspection.additional_info_placeholder')"
                                    :rows="2"
                                    :max-rows="6"
                                />
                            </b-form-group>
                        </div>
                    </div>

                </div>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitInspection('+result.id+')"
                    >
                        <i class="fa fa-share-square"/>
                        <span class="button-text">{{ $t('common.send') }}</span>
                    </b-button>
                </div>
            </b-form>
        </transition>
        <div
            v-if="inspectionTemplate==null"
            class="col-sm-12"
        >
            {{ $t('inspection.no_template_found') }}
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {inspectionHelper} from '../mixins/InspectionMixin'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'VehicleInspector',
    mixins: [inspectionHelper, restApi],
    props: {
        vehicle: {
            type: Object,
            default: null
        },
        inspectionTemplate: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            options: [
                {value: 1, text: this.$t('inspection.options.not_tested')},
                {value: 2, text: this.$t('inspection.options.okay')},
                {value: 3, text: this.$t('inspection.options.defects')},
                {value: 4, text: this.$t('inspection.options.ban')}
            ],
            inspectionType: this.inspectionTemplate.type,
            inspectionName: this.inspectionTemplate.name,
            inspectables: this.inspectionTemplate.inspectables,
            inspectablesValues: [],
            inspectablesInfo: []
        }
    },

    methods: {
        getTitle: function (type) {
            switch (type) {
                case 1:
                    return this.$t('inspection_template.type.weekly')
                case 2:
                    return this.$t('inspection_template.type.monthly')
                case 3:
                    return this.$t('inspection_template.type.annually')
            }
        },
        getInspectionResultsFromForm: function () {
            // Loop through inspectables to generate results
            var results = []
            for (var i = 0; i < this.inspectables.length; i++) {
                var inspectable = this.inspectables[i]
                var value = this.inspectablesValues[i]
                if (value === undefined) {
                    value = 1 // Skipped
                }
                var key = inspectable.name

                var info = this.inspectablesInfo[i]
                if (info === undefined) {
                    info = ''
                }

                results.push({id: '', inspectable: key, result: value, info: info})
            }
            var d = new Date()
            var createTime = d.toISOString()

            return {
                vehicle: {
                    id: this.vehicle.id
                },
                type: this.inspectionType,
                name: this.inspectionName,
                created: createTime,
                results: results
            }
        },

        submitInspection: function () {
            this.loading = true
            var jsonResults = this.getInspectionResultsFromForm()
            this.restAdd(this.inspectionReportUrl, jsonResults, this.success, this.fail)
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        infoVisible: function (index) {
            return this.inspectablesValues[index] === 3 || this.inspectablesValues[index] === 4
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>

<style>
p {
    padding-left: .5em;
    padding-right: .5em;
    font-size: 1.2em;
}

div.info {
    margin-left: 30px;
}
</style>
