
export const roadsideAdvertisementHelper = {
    methods: {
        getRoadsideAdvertisementLightingTypes: function () {
            return [
                {name: this.$t('roadside_advertisement.lighting_types.unlit'), value: roadsideAdvertisementLightingTypes.UNLIT},
                {name: this.$t('roadside_advertisement.lighting_types.led'), value: roadsideAdvertisementLightingTypes.LED},
                {name: this.$t('roadside_advertisement.lighting_types.externally_lit'), value: roadsideAdvertisementLightingTypes.EXTERNALLY_LIT},
            ]
        },
    }
}
export const roadsideAdvertisementLightingTypes = {
    UNLIT: 1,
    LED: 2,
    EXTERNALLY_LIT: 3,
}
