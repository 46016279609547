export const Status = {
    AVAILABLE: 0,
    ORDERED: 1,
    OUT_OF_STOCK: 2
}

const StatusPriority = {
    [Status.AVAILABLE]: 1,
    [Status.ORDERED]: 2,
    [Status.OUT_OF_STOCK]: 3
}

export function newMaterialStorage(name, lat, lng, active = true, materialRecords = [], contracts = []) {
    return {
        name: name,
        location: {
            x: lng,
            y: lat
        },
        active: active,
        material_records: materialRecords,
        contracts: contracts
    }
}

export function getMaterialStorageStatus(materialStorage) {
    if (materialStorage.material_records.length === 0) {
        return null
    }
    return materialStorage.material_records.map(r => r.status).reduce((previous, current) => {
        return StatusPriority[previous] > StatusPriority[current] ? previous : current
    })
}

export function newMaterialRecord(name, amount = 0, unit = '', status = Status.AVAILABLE) {
    return {
        name: name,
        amount: amount,
        unit: unit,
        status: status,
        active: true
    }
}
