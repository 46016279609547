<template>
    <div class="">
        <b-navbar
            v-if="!vehicle"
            variant="faded"
        >
            <span class="title">
              {{ $t('menu.fleet_defects') }}
            </span>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <keep-alive>
                <single-vehicle-defect-search
                    v-if="vehicle"
                    :vehicle="vehicle"
                    @search="fetchDefects"
                />
                <vehicle-defect-search
                    v-else-if="Object.keys(items).length === 0"
                    @search="fetchDefects"
                />
            </keep-alive>
            <div
                v-if="!vehicle"
                class="pl-3 pr-3"
            >
                <div
                class="data-list-item add"
                @click.stop="addDefect"
            >
                <div class="item-img-cont add-title">
                    <i class="fa fa-plus"/>
                </div>
                <div class="item-text-cont">
                    <span class="item-title-add">{{ $t('common.add_new') }}</span>
                    <font-awesome-icon
                        icon="wrench"
                        class="card-body-icon"
                    />
                </div>
            </div>
            </div>
            <transition name="fade">
                <keep-alive>
                    <vehicle-defect-list
                        v-if="!editMode"
                        :items="results"
                        @edit="editDefect"
                    />
                </keep-alive>
            </transition>
            <transition name="fade">
                <vehicle-defect-editor
                    v-if="editMode"
                    :defect="defect"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import VehicleDefectEditor from './VehicleDefectEditor'
import VehicleDefectList from './VehicleDefectList'
import VehicleDefectSearch from './VehicleDefectSearch'
import {restApi} from '../../mixins/RestApiMixin'
import {vehicleHelper} from '../../mixins/VehicleMixin'
import SingleVehicleDefectSearch from "@/components/vehicle/defect/SingleVehicleDefectSearch";

export default {
    name: 'VehicleDefects',
    components: {SingleVehicleDefectSearch, VehicleDefectList, VehicleDefectEditor, VehicleDefectSearch},
    mixins: [restApi, vehicleHelper],
    props: {
        isObserver: {
            type: Boolean,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: null
        },
        vehicle: {
            type: Object,
            default: null
        },
        items: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            results: [],
            defect: null,
            editMode: false,
            loading: false,
            vehicles: [],
            lastSearchedParams: null
        }
    },
    watch: {
        items() {
            this.results = this.items
        },
    },
    methods: {
        fetchDefects: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.results = []
            let url = null
            if (this.lastSearchedParams && this.lastSearchedParams.status !== 0) {
                url = this.vehicleDefectUrl
            } else {
                url = this.vehicleDefectOpenUrl
            }
            this.restFetchParams(url, this.lastSearchedParams, this.handleResponse)
        },

        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
            this.$emit('search-results', response.data)
        },

        editDefect: function (defect) {
            this.defect = defect
            this.editMode = true
        },

        addDefect: function () {
            this.defect = this.newDefect()
            this.editMode = true
        },

        showList: function () {
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.showList()
            this.fetchDefects(this.lastSearchedParams)
        }
    }
}
</script>

<style scoped>

</style>
