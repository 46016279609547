<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('menu.observation_collections') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
                <font-awesome-icon
                    icon="trash"
                    class="print-icon right mt-2"
                    @click.stop="removeObservationCollection"
                />
            </div>
        </b-navbar>

        <transition name="fade">
            <observation-collection-list
                v-if="!editMode"
                :results="results"
                :observation-types="observationTypes"
                @add="addNew"
                @edit="edit"
            />
        </transition>

        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <observation-collection-editor
                    ref="obsCollectionEditor"
                    :observation-collection="observationCollection"
                    :observation-types="observationTypes"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import ObservationCollectionList from "@/components/observation/collection/ObservationCollectionList";
import ObservationCollectionEditor from "@/components/observation/collection/ObservationCollectionEditor";

export default {
    name: 'ObservationCollections',
    components: {ObservationCollectionEditor, ObservationCollectionList},
    mixins: [restApi],
    data: function () {
        return {
            results: [],
            observationCollection: null,
            observationTypes: [],
            editMode: false,
            loading: false
        }
    },
    mounted: function () {
        this.fetchObservationTypes()
        this.fetchObservationCollections()
    },
    methods: {
        fetchObservationCollections: function () {
            this.loading = true
            this.results = []
            this.restFetch(this.observationCollectionUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        fetchObservationTypes: function () {
            this.loading = true
            this.observationTypes = []
            this.restFetch(this.observationTypeUrl, this.handleObservationTypes)
        },
        handleObservationTypes: function (response) {
            this.observationTypes = response.data
            this.loading = false
        },
        addNew: function () {
            this.observationCollection = {id: null, name: null, observation_types: null}
            this.editMode = true
        },
        edit: function (observationCollection) {
            this.observationCollection = observationCollection
            this.editMode = true
        },
        removeObservationCollection: function () {
            this.$refs["obsCollectionEditor"].confirmDeleteObservationCollection()
        },
        showList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchObservationCollections()
            this.showList()
        }

    }
}
</script>
