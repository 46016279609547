<template>
    <div
        v-bind:class="{ 'details-container': !this.isAddEquipmentMode }"
        @click.stop="close"
    >
        <div
            v-bind:class="{ 'details dynamic_width': !this.isAddEquipmentMode }"
            @click.stop=""
        >
            <h3 class="pl-1" v-if="!this.isAddEquipmentMode">
                {{ $t('drain.drain')  }}
            </h3>
            <location-deviation v-if="!this.isAddEquipmentMode" :location-deviation="item.location_deviation"/>
            <div class="pl-2 pr-2">
                <b-row v-if="!this.isAddEquipmentMode">
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('common.id') }}</span>
                        <span class="item-detail-text">{{ item.external_system_id }}</span>
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <road-addess-view :road-number="item.road_num" :section-number="item.road_sec" :distance="item.distance" :show-street-name="false"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.type') }}</span>
                        <b-form-select
                            v-model="item.type"
                            size="sm"
                            :state="this.validateIsNumber(item.type)"
                        >
                            <option
                                v-for="item in getDrainTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.material') }}</span>
                        <b-form-select
                            v-model="item.material"
                            size="sm"
                            v-bind:state="this.validateIsNumber(item.material)"
                        >
                            <option
                                v-for="item in getDrainMaterials()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <condition-selector v-model="item.condition"/>
                    </div>
                </b-row>
                <location-details
                    v-if="!this.isAddEquipmentMode"
                    :lane-data="this.item.location_specification.lane"
                    :side-data="this.item.location_specification.side"
                    :track-data="this.item.location_specification.tracks"
                />
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.cover_type.title') }}</span>
                        <b-form-select
                            v-model="item.cover_type"
                            size="sm"
                            v-bind:state="this.isStormDrain(item.type) ? this.validateIsNumber(item.cover_type) : null"
                        >
                            <option
                                v-for="item in getDrainCoverTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.cover_material') }}</span>
                        <b-form-select
                            v-model="item.cover_material"
                            size="sm"
                            :state="this.validateIsNumber(item.cover_material)"
                        >
                            <option
                                v-for="item in getDrainCoverMaterials()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.cover_size') }}</span>
                        <b-form-input
                            id="cover_size"
                            v-model="item.cover_size"
                            type="number"
                            size="sm"
                            v-bind:state="this.isStormDrain(item.type) ? this.validateIsNumber(item.cover_size) : null"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.cover_load_class') }}</span>
                        <b-form-input
                            id="cover_load_class"
                            v-model="item.cover_load_class"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.cover_adjustment_mechanism.title') }}</span>
                        <b-form-select
                            v-model="item.cover_adjustment_mechanism"
                            size="sm"
                        >
                            <option
                                v-for="item in getDrainCoverAdjustmentMechanisms()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.cover_height_position') }}</span>
                        <b-form-input
                            id="cover_height_position"
                            v-model="item.cover_height_position"
                            type="number"
                            size="sm"
                            :state="this.validateIsNumber(item.cover_height_position)"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.depth') }}</span>
                        <b-form-input
                            id="depth"
                            v-model="item.depth"
                            type="number"
                            size="sm"
                            :state="this.validateIsNumber(item.depth)"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.cover_adjustment_option') }}</span>
                        <b-form-checkbox
                            id="cover_adjustment_option"
                            v-model="item.cover_adjustment_option"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.precipitation_nest') }}</span>
                        <b-form-checkbox
                            id="precipitation_nest"
                            v-model="item.precipitation_nest"
                            true-value="true"
                            false-value="false"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.precipitation_nest_depth') }}</span>
                        <b-form-input
                            id="depth"
                            v-model="item.precipitation_nest_depth"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.drain_outlets') }}</span>
                        <b-form-input
                            id="drain_outlets"
                            v-model="item.drain_outlets"
                            type="number"
                            size="sm"
                            v-bind:state="this.isStormDrain(item.type) ? this.validateIsNumber(item.drain_outlets) : null"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.drain_inputs') }}</span>
                        <b-form-input
                            id="drain_inputs"
                            v-model="item.drain_inputs"
                            type="number"
                            size="sm"
                            v-bind:state="this.isStormDrain(item.type) ? this.validateIsNumber(item.drain_inputs) : null"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.inside_diameter') }}</span>
                        <b-form-input
                            id="inside_diameter"
                            v-model="item.inside_diameter"
                            type="number"
                            size="sm"
                            :state="this.validateIsNumber(item.inside_diameter)"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.base_elevation') }}</span>
                        <b-form-input
                            id="base_elevation"
                            v-model="item.base_elevation"
                            type="number"
                            size="sm"
                            :state="this.validateIsNumber(item.base_elevation)"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.wall_thickness') }}</span>
                        <b-form-input
                            id="wall_thickness"
                            v-model="item.wall_thickness"
                            type="number"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('drain.product_name') }}</span>
                        <b-form-input
                            id="product_name"
                            min="1"
                            v-model="item.product_name"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('portal.code') }}</span>
                        <b-form-input
                            id="height"
                            min="1"
                            v-model="item.code"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1" v-if="this.isAddEquipmentMode">
                        <location-deviation :editMode="true" :location-deviation="item.location_deviation" @updateLocationDeviation="updateLocationDeviation"/>
                    </div>
                </b-row>
                <b-row class="nopads" v-if="this.isAddEquipmentMode">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-location-details-editor
                        :roadInfo="item"
                        :locationSpecification="item.location_specification"
                        :sideRequired="item.location_deviation !== null && this.sideRequired(item.location_deviation)"
                        :hideSideSelector="!this.sideRequired(item.location_deviation)"
                        :hideTracksSelector="true"
                        :tracksRequired="false"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-damages :items="item.equipment_damages"/>
                    </div>
                </b-row>
                <equipment-actions v-if="!this.isAddEquipmentMode" ref="actions" v-model="item.actions" />
                <linked-equipment
                    v-if="item.linked_equipment && item.linked_equipment.length > 0"
                    :linked-equipments="item.linked_equipment"
                />
            </div>
            <velho-editor-buttons
                v-if="!this.isAddEquipmentMode"
                :url="extDrainsUrl"
                :selected-contract="selectedContract"
                :item="item"
                :disabled="loading"
                @close="close"
                @submit="handleSubmit"
                @removalConfirmed="removalConfirmed"
            />
        </div>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import ConditionSelector from "@/components/velho/ConditionSelector";
import {deviations, velhoHelper} from "@/components/mixins/VelhoMixin";
import EquipmentActions from "@/components/velho/EquipmentActions";
import {drainHelper} from "@/components/mixins/DrainMixin";
import VelhoEditorButtons from "@/components/velho/VelhoEditorButtons";
import LinkedEquipment from "@/components/velho/LinkedEquipment";
import LocationDetails from "@/components/velho/LocationDetails.vue";
import LocationDeviation from "@/components/velho/LocationDeviation";
import VelhoEquipmentLocationDetailsEditor from "@/components/velho/VelhoEquipmentLocationDetailsEditor";
import VelhoEquipmentDamages from "@/components/velho/VelhoEquipmentDamages";
import RoadAddessView from "@/components/velho/RoadAddressView";
import {velhoValidationMixin} from "@/components/mixins/VelhoValidationMixin";
import {velhoRequiredMixin} from "@/components/mixins/VelhoRequiredMixin";

export default {
    name: 'DrainEditor',
    components: {
        RoadAddessView,
        LocationDeviation, VelhoEquipmentLocationDetailsEditor, VelhoEquipmentDamages,
        LocationDetails, VelhoEditorButtons, EquipmentActions, ConditionSelector, LinkedEquipment},
    mixins: [mapHelper, conversionModelHelper, restApi, velhoHelper, drainHelper, velhoValidationMixin, velhoRequiredMixin],
    props: {
        item: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        },
        addEquipmentEditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAddEquipmentMode() {
            return this.addEquipmentMode
        }
    },
    data() {
        return {
            addEquipmentMode: this.addEquipmentEditor,
            map: null,
            loading: false,
        }
    },
    methods: {
        handleSubmit: function(url, item, selectedContract, replace = false) {
            if(replace) {
                this.submitEquipmentsreplace(this.extEquipmentsReplaceUrl, [item], selectedContract)
            } else {
                this.submitEquipment(url, item, selectedContract);
            }
        },
        close: function () {
            this.$emit('close')
        },
        updateLocationDeviation(value) {
            this.item.location_deviation = value
            if(value === deviations.AT_THE_END_OF_THE_ROAD) {
                this.item.location_specification.side = null
            }
        },
        isValidForm: function() {
            if(!this.item) { return false }
            if(!this.validateIsNumber(this.item.type)) { return false }
            if(!this.validateRoadNumber(this.item.road_num)) { return false }
            if(!this.validateRoadSection(this.item.road_sec)) { return false }
            if(!this.validateRoadDistance(this.item.distance)) { return false }
            if(!this.validateIsNumber(this.item.type)) { return false }
            if(!this.validateIsNumber(this.item.material)) { return false }
            if(!this.validateIsNumber(this.item.inside_diameter)) { return false }
            if(!this.validateIsNumber(this.item.depth)) { return false }
            if(!this.validateIsNumber(this.item.cover_height_position)) { return false }
            if(!this.validateIsNumber(this.item.base_elevation)) { return false }

            if(this.isStormDrain(this.item.type)) {
                this.validateIsNumber(this.item.drain_outlets)
                this.validateIsNumber(this.item.drain_inputs)
                this.validateIsNumber(this.item.cover_size)
                this.validateIsNumber(this.item.cover_type)
            }

            if(this.item.location_deviation !== null && this.sideRequired(this.item.location_deviation)) {
                if(!this.validateRoadSide(this.item.location_specification.side)) { return false }
            }
            
            return true;
        }
    }
}
</script>

<style scoped>
</style>

