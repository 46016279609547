<template>
    <div>
        <span class="span-title">{{ $t('orders.pricing_model') }}</span>
        <b-form-select
            id="pricingModel"
            v-model="selectedPricingModel"
            size="sm"
            class="mb-3"
        ><template slot="first">
            <option
                :value="null"
            >
                {{ $t('orders.no_pricing') }}
            </option>
        </template>
            <option
                v-for="option in pricingModels"
                :key="option.id"
                :value="option.id"
            >
                € / {{ unitToText(option.unit1) }} {{ option.unit2 ? "/" + unitToText(option.unit2) : "" }}
            </option>
        </b-form-select>
    </div>
</template>

<script>
import {priceHelper} from "../mixins/PricingMixin";
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: "PricingModelFilter",
    mixins: [priceHelper, restApi],
    props: {
        value: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            pricingModels: [],
            selectedPricingModel: null
        }
    },
    watch: {
        selectedPricingModel: function (){
            let model = this.pricingModels.find(item => item.id === this.selectedPricingModel)
            this.$emit('input', model)
        }
    },
    mounted: function () {
        this.fetchPricingModels()
    },
    methods: {
        fetchPricingModels: function () {
            this.loading = true
            this.pricingModels = []
            this.restFetch(this.pricingModeUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.pricingModels = response.data
            if(this.value) {
                let model = this.pricingModels.find(item => item.id === this.value.id)
                if(model) {
                    this.selectedPricingModel = model.id
                } else {
                    this.selectedPricingModel = null
                    this.$emit('input', null)
                }
            }
            this.loading = false
        },
    }
}
</script>

<style scoped>

</style>
