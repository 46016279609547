<template>
            <div class="col-12 stat-container">
                <div class="stat-header">{{ loading ? (header + ' ' + $t('common.loading')) : header }}</div>
                <b-row class="nopads">
                    <div class="col-2">
                        <font-awesome-icon icon="route" class="stat-icon"/>
                    </div>
                    <div class="col-3">
                        <span class="stat-title">{{ $t('trip.mode_work') }}</span>
                        <span class="stat-text"> {{  workDistance.toFixed(2) + ' km' }} </span>
                    </div>
                    <div class="col-3">
                        <span class="stat-title">{{ $t('trip.mode_resettle') }}</span>
                        <span class="stat-text"> {{  resettleDistance.toFixed(2) + ' km' }} </span>
                    </div>
                    <div class="col-3">
                        <span class="stat-title">{{ $t('common.total') }}</span>
                        <span class="stat-text"> {{ (workDistance + resettleDistance).toFixed(2) + ' km' }} </span>
                    </div>
                </b-row>
                <b-row class="nopads pt-1">
                    <div class="col-2">
                        <font-awesome-icon icon="clock" class="stat-icon"/>
                    </div>
                    <div class="col-3">
                        <span class="stat-title">{{ $t('trip.mode_work') }} </span>
                        <span class="stat-text">{{  this.getWorkTime(workTime) }}  </span>
                    </div>
                    <div class="col-3">
                        <span class="stat-title">{{ $t('trip.mode_resettle') }}</span>
                        <span class="stat-text"> {{  this.getWorkTime(resettleTime) }} </span>
                    </div>
                    <div class="col-3">
                        <span class="stat-title">{{ $t('common.total') }}</span>
                        <span class="stat-text">{{ this.getWorkTime(workTime + resettleTime) }} </span>
                    </div>
                </b-row>
                <b-row class="nopads pt-1 pb-1">
                    <div class="col-2">
                        <font-awesome-icon icon="chart-line" class="stat-icon"/>
                    </div>
                    <div class="col-3">
                        <span class="stat-title">{{ $t('vehicle_statistics.utilization') }} </span>
                        <span class="stat-text"> {{  ( utilizationRate * 100).toFixed(2) + '%' }} </span>
                    </div>
                </b-row>
            </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'VehicleStatisticValues',
    mixins: [timeUtils],
    props: {
        header:  {
            type: String,
            default: null
        },
        workDistance: Number,
        resettleDistance: Number,
        workTime: Number,
        resettleTime: Number,
        utilizationRate: Number,
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
</style>
