<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <b-row class="nopads">
                <div class="col-sm-6">
                    <span class="search-title">{{ $t('delivery_note.date') }} - {{ $t('trip_list.road_sec_from') }}</span>
                    <datetime
                        v-model="fromDate"
                        type="datetime"
                        style="font-size: .9em; line-height: 1.4em"
                        format="dd.MM.yyyy HH:mm"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>
                <div class="col-sm-6">
                    <span class="search-title">{{ $t('delivery_note.date') }} - {{ $t('trip_list.road_sec_to') }}</span>
                    <datetime
                        v-model="toDate"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        style="font-size: .9em; line-height: 1.4em"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-6 pl-3 pr-3">
                    <span class="span-title">{{ $t('observation_reports.template') }}</span>
                    <b-form-select id="template"  size="sm" v-model="template" :options="templateOptions"/>
                </div>
                <div class="col-sm-6 pl-3 pr-3">
                    <open-contract-filter
                        ref="filter"
                        :from="fromDate"
                        :to="toDate"
                        :contract="contract"
                        :apply-padding="false"
                        :row-view="false"
                        :showOnlyMyContractsOption="true"
                        :show-title="true"
                        :show-company="false"
                        @contractSelected="setContract"/>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    variant="info"
                    class="result-button"
                    size="sm"
                    v-on:click.stop="$emit('addTemplate')"
                >
                    {{ $t('observation_reports.add') }}
                </b-button>
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                    :disabled="!template"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import {timeUtils} from '../../mixins/TimeUtils'
import OpenContractFilter from "@/components/contract/OpenContractFilter";

export default {
    name: 'ReportSearch',
    components: {OpenContractFilter},
    mixins: [timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        templates: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            fromDate: null,
            toDate: null,
            contract: null,
            template: null,
            templateOptions: [],
            company: null,
        }
    },
    watch: {
        templates: function () {
            this.initTemplateOptions()
        },
        template: function () {
            this.$emit('reportSelected', this.template)
        }
    },
    mounted() {
        this.initTemplateOptions()
        this.fromDate = this.getMonthStart()
        this.toDate = this.getToday()
    },
    methods: {
        initTemplateOptions() {
            if (this.templates) {
                this.templateOptions = []
                this.templates.forEach(item =>
                    this.templateOptions.push({text: item.name, value: item.id})
                )
            } else {
                this.templateOptions = []
            }
        },
        setContract: function (contract) {
            this.contract = contract
        },
        companySet: function (company) {
            this.company = company
        },
        onSubmit: function () {
            let params = {}
            if (this.contract) {
                params.contract = this.contract.id
            }
            params.from = this.fromDate
            params.to = this.to
            let template = this.templates.find(item => item.id === this.template)
            params.observationType = template.observation_type.id
            this.$emit('search', params)
        },
        onReset: function () {
            this.contract = null
            this.company = null
            this.$refs.filter.resetData()
        }
    }
}
</script>
