export const deliveryStatus = {
    LOADING: 1,
    IN_DELIVERY: 5,
    UNLOADING: 10,
    UNLOADED: 15
}

export const units = {
    KILOGRAMS: 'kg',
    TONS: 't',
    METERS: 'm',
    SQUARE_METERS: 'm2',
    CUBIC_METERS: 'm3',
    KILOGRAMS_PER_SQUARE_METRE: 'kg/m2',
    KILOGRAMS_PER_CUBIC_METRE: 'kg/m3'
}

export const deliveryNoteHelper = {
    methods: {
        getStatus: function () {
            return [
                {value: deliveryStatus.LOADING},
                {value: deliveryStatus.IN_DELIVERY},
                {value: deliveryStatus.UNLOADING},
                {value: deliveryStatus.UNLOADED}
            ]
        },

        getStatusString: function (status) {
            switch (status) {
                case deliveryStatus.LOADING:
                    return this.$t('delivery_note.statuses.loading')
                case deliveryStatus.IN_DELIVERY:
                    return this.$t('delivery_note.statuses.in_delivery')
                case deliveryStatus.UNLOADING:
                    return this.$t('delivery_note.statuses.unloading')
                case deliveryStatus.UNLOADED:
                    return this.$t('delivery_note.statuses.unloaded')
            }
        },

        kilogramsToTons(value) {
            return value ? value / 1000 : null
        },

        tonsToKilograms(value) {
            return value ? value * 1000 : null
        },

        kilometersToMeters(value) {
            return value ? value * 1000 : null
        },

        newDeliveryNote: function () {
            return {
                id: -1,
                order: {
                    id: -1
                },
                vehicle: {
                    license_plate: ''
                },
                station_material: {
                    id: -1
                },
                amount: '',
                unit: -1,
                status: deliveryStatus.IN_DELIVERY,
            }
        },

        jsonToItem: function (item) {
            return JSON.parse(JSON.stringify(item))
        }
    }
}
