<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('pricing_models.title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <transition name="fade">
            <pricing-model-list
                v-if="!editMode"
                :results="results"
                @add="addNew"
            />
        </transition>

        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <pricing-model-editor
                    :pricing-model="pricingModel"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import PricingModelList from "./PricingModelList";
import PricingModelEditor from "./PricingModelEditor";

export default {
    name: 'PricingModels',
    components: {PricingModelEditor, PricingModelList},
    mixins: [restApi],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            pricingModel: null,
            editMode: false,
            loading: false
        }
    },
    mounted: function () {
         this.fetchPricingModels()
    },
    methods: {
        fetchPricingModels: function () {
            this.loading = true
            this.results = []
            this.restFetch(this.pricingModeUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        addNew: function () {
            this.pricingModel = {id: null, unit1: null, unit2: null}
            this.editMode = true
        },
        showList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchPricingModels()
            this.showList()
        }

    }
}
</script>
