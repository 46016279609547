<template>

    <div
        class="col-sm-12 p-2 pl-3 pr-3"
    >
        <div
            id="vehicle-list"
            class="list-content result-table p-2 pointer"
        >
            <b-table
                small
                id="vehicle-list"
                :responsive="true"
                hover="hover"
                :items="results"
                :fields="fields"
                @row-clicked="emitData"
            >
                <template v-slot:cell(vehicle_type)="row">
                    {{ getVehicleTypeName(row.value) }}
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {vehicleHelper} from '../mixins/VehicleMixin'
import {restApi} from "../mixins/RestApiMixin"
import {mapHelper} from "../mixins/MapMixin";
import {timeUtils} from "../mixins/TimeUtils";

export default {
    name: 'VehicleList',
    mixins: [vehicleHelper, restApi, mapHelper, timeUtils],
    components: {},
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data() {
        return {
            fields: [
                {
                    key: 'license_plate',
                    label: this.$t('ins_report.list_label.license_plate'),
                    sortable: true,
                },
                {
                    key: 'make',
                    label: this.$t('vehicle_list.make'),
                    sortable: true
                },
                {
                    key: 'vehicle_model',
                    label: this.$t('vehicle_list.model'),
                    sortable: true
                },
                {
                    key: 'vehicle_type',
                    label: this.$t('vehicle_list.type'),
                    sortable: true,
                }
            ]
        }
    },
    watch: {
        results: function () {
            this.results.forEach(vehicle => vehicle["_rowVariant"] = vehicle.in_use ? null : 'secondary')
        }
    },
    methods: {
        emitData: function (selected) {
            // Find defect item from original items and emit request to edit
            let p = this.results.find(item => item.id === selected.id)
            if (p) {
                this.$emit('edit', p)
            }
        }

    }
}
</script>
