<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-container">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
                <span class="item-title-add">{{ $t('area_report.new_report') }}</span>
                <font-awesome-icon
                    icon="file-invoice"
                    class="card-body-icon"
                />
            </div>
        </div>
        <transition name="fade">
            <div
                v-if="results"
                id="accordion"
                class="col-sm-12 list-content result-table"
            >
                <!-- Main table element -->
                <b-table
                    hover
                    show-empty
                    small
                    style="cursor:pointer;"
                    :items="results"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @row-clicked="emitDetails"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(completed_time)=row>
                        <span>{{ toLocalTime(row.value) }}</span>
                    </template>
                    <template v-slot:cell(status)="row">
                        {{ row.value === 1 ? $t('area_report.status_open') : $t('area_report.completed') }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {areaReportHelper} from '../../mixins/AreaReportMixin'
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'AreaReportList',
    mixins: [areaReportHelper, timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data() {
        return {
            sortBy: 'completed_time',
            sortDesc: true,
            fields: [
                {key: 'area.name', label: this.$t('area_report.area_name'), sortable: true},
                {key: 'completed_time', label: this.$t('area_report.completed'), sortable: true},
                {key: 'status', label: this.$t('area_report.status'), sortable: true}
            ]
        }
    },
    methods: {
        emitDetails: function (details) {
            this.$emit('details', details)
        }
    }
}
</script>
