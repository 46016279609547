<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <contract-filter
                ref="contractFilter"
                :is-admin="isAdmin"
                :showOnlyMyContractsOption="true"
                :user="user"
                @contractSet="setContract"
                @companySet="setCompany"
            />
            <b-row class="nopads">
                <div class="col-sm-6">
                    <contract-order-filter
                        v-model="order"
                        :contract-id="contract"
                        :show-executor-text="true"
                    />
                </div>
                <div class="col-sm-6" style="padding-top: 6px">
                    <span class="search-title">{{ $t('work_time.person') }}</span>
                    <multiselect
                        v-model="selectedUsers"
                        class="multiselect_routa"
                        :placeholder="$t('work_time.select_person')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="false"
                        :multiple="true"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="users"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                          </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="type"
                    >
                        <span class="span-title">{{ $t('additional_cost.type') }}</span>
                        <b-form-select
                            id="type"
                            v-model="type"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="null">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option
                                v-for="option in getAdditionalCostType()"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                        <span class="span-title">{{ $t('additional_cost.status') }}</span>
                        <b-form-select
                            id="status"
                            v-model="status"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="null">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option
                                v-for="option in getAdditionalCostStatus()"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import Multiselect from 'vue-multiselect'
import ContractFilter from "@/components/contract/ContractFilter";
import {orderMixin} from "@/components/mixins/OrderMixin";
import {additionalCostHelper} from "@/components/mixins/AdditionalCostMixin";
import ContractOrderFilter from "../order/ContractOrderFilter";

export default {
    name: 'additional-cost-search',
    components: {ContractOrderFilter, ContractFilter, Multiselect},
    mixins: [restApi, orderMixin, additionalCostHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            users: [],
            selectedUsers: [],
            contract: null,
            order: null,
            company: null,
            type: null,
            status: null
        }
    },
    mounted: function () {
        this.fetchUsers()
    },
    methods: {
        setContract: function (contract) {
            this.contract = contract
        },
        setCompany: function (company) {
            this.company = company
        },
        fetchUsers: function () {
            this.users = []
            this.restFetch(this.userUrl, this.handleUserResponse)
        },

        handleUserResponse: function (response) {
            if (response && response.data) {
                response.data.forEach(function (user) {
                    this.users.push({
                        value: user.id,
                        name: user.last_name + ', ' + user.first_name,
                        company: user.company.name,
                        email: user.email
                    })
                }, this)
            }
            this.loading = false
        },

        onSubmit: function () {
            let params = {}
            if (this.selectedUsers.length > 0) {
                let results = []
                this.selectedUsers.forEach(function (item) {
                    results.push(item.value)
                }, this)
                params.users = results
            }
            if (this.contract !== null) {
                params.contract = this.contract
            }
            if (this.order) {
                params.order = this.order.id
            }
            if (this.company !== null) {
                params.company = this.company
            }
            if (this.type) {
                params.type = this.type
            }
            if (this.status) {
                params.status = this.status
            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.selectedUsers = []
            this.contract = null
            this.order = null
            this.company = null
            this.type = null
            this.status = null
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
