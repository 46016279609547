import {equipmentOidTypes} from '@/components/mixins/ConversionMixin'

export default class VelhoDrainModel {
    // Private properties
    /** @type {string} */
    #external_system_id = equipmentOidTypes.EXTERNAL_DRAINS

    constructor(
        condition = null, position = { x: null, y: null }, roadNum = null, roadSec = null, distance = null, type = null, material = null,
        coverType = null, coverMaterial = null, coverSize = null, coverHeightPosition = null, coverLoadClass = null, coverAdjustmentMechanism = null,
        coverAdjustmentOption = null, depth = null, precipitationNest = false, precipitationNestDepth = null, drainOutlets = null, drainInputs = null,
        insideDiameter = null, baseElevation = null, wallThickness = null, code = "", productName = "", equipmentDamages = [], locationDeviation = null,
        locationSpecification = { lane: [], side: null, tracks: null, central_area: null }, externalSystemId = ""
    ) {
        /** @type {number | null} */
        this.condition = condition

        /** @type {{ x: number, y: number }} */
        this.position = position

        /** @type {number | null} */
        this.road_num = roadNum

        /** @type {number | null} */
        this.road_sec = roadSec

        /** @type {number | null} */
        this.distance = distance

        /** @type {number | null} */
        this.type = type

        /** @type {number | null} */
        this.material = material

        /** @type {number | null} */
        this.cover_type = coverType

        /** @type {number | null} */
        this.cover_material = coverMaterial

        /** @type {number | null} */
        this.cover_size = coverSize

        /** @type {number | null} */
        this.cover_height_position = coverHeightPosition
        
        /** @type {number | null} */
        this.cover_load_class = coverLoadClass

        /** @type {number | null} */
        this.cover_adjustment_mechanism = coverAdjustmentMechanism

        /** @type {number | null} */
        this.cover_adjustment_option = coverAdjustmentOption

        /** @type {number | null} */
        this.depth = depth

        /** @type {boolean} */
        this.precipitation_nest = precipitationNest
        
        /** @type {number | null} */
        this.precipitation_nest_depth = precipitationNestDepth

        /** @type {number | null} */
        this.drain_outlets = drainOutlets

        /** @type {number | null} */
        this.drain_inputs = drainInputs

        /** @type {number | null} */
        this.inside_diameter = insideDiameter

        /** @type {number | null} */
        this.base_elevation = baseElevation
        
        /** @type {number | null} */
        this.wall_thickness = wallThickness
        
        /** @type {string} */
        this.code = code

        /** @type {string} */
        this.product_name = productName

        /** @type {Array} */
        this.equipment_damages = equipmentDamages

        /** @type {number | null} */
        this.location_deviation = locationDeviation

        /** @type {{ lane: number[], side: number | null, tracks: number | null, central_area: number | null }} */
        this.location_specification = locationSpecification

        if(externalSystemId) {
            this.#external_system_id = externalSystemId
        }
    }

    /**
    * Gets the unique identifier.
    * @returns {string}
    */
    get external_system_id() {
        return this.#external_system_id
    }

    /**
    * Get the culvert json object.
    * @returns {object}
    */
    toJSON() {
        return JSON.parse(
            JSON.stringify({
            external_system_id: this.#external_system_id,
            condition: this.condition,
            position: this.position,
            road_num: this.road_num,
            road_sec: this.road_sec,
            distance: this.distance,
            type: this.type,
            material: this.material,
            cover_type: this.cover_type,
            cover_material: this.cover_material,
            cover_size: this.cover_size,
            cover_height_position: this.cover_height_position,
            cover_load_class: this.cover_load_class,
            cover_adjustment_mechanism: this.cover_adjustment_mechanism,
            cover_adjustment_option: this.cover_adjustment_option,
            depth: this.depth,
            precipitation_nest: this.precipitation_nest,
            precipitation_nest_depth: this.precipitation_nest_depth,
            drain_outlets: this.drain_outlets,
            drain_inputs: this.drain_inputs,
            inside_diameter: this.inside_diameter,
            base_elevation: this.base_elevation,
            wall_thickness: this.wall_thickness,
            code: this.code,
            product_name: this.product_name,
            equipment_damages: this.equipment_damages,
            location_deviation: this.location_deviation,
            location_specification: {
                ...this.location_specification,
                tracks: this.location_specification.tracks !== null ? [this.location_specification.tracks] : []
            }
        }))
    }

    /**
    * Create default instance with position data.
    * @param {{ x: number, y: number }} position - coordinates.
    * @param {number | null} roadNum - Road number.
    * @param {number | null} roadSec - road section.
    * @param {number | null} distance - distance (road geometry).
    * @returns {VelhoDrainModel}
    */
    static instanceWithPosition(position, roadNum, roadSec, distance) {
        const instance = new VelhoDrainModel()
        instance.position = position
        instance.road_num = roadNum
        instance.road_sec = roadSec
        instance.distance = distance
        return instance
    }
}