<template>
    <div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
export default {
    name: 'MapUsers',
    mixins: [restApi, timeUtils, mapHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false,
            fetching: false,
            destroyed: false,
            timer: null,
            logEntries: []
        }
    },
    watch: {
        map() {
            this.$nextTick(function() {
                this.fetchUserLocations()
            })
        },
        selectedContractId() {
            this.fetchUserLocations()
        },
        selectedContractIds() {
            this.fetchUserLocations()
        }
    },
    mounted: function () {
        this.fetchUserLocations()
    },
    beforeDestroy: function () {
        this.destroyed = true
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.hideUsers()
    },
    methods: {
        fetchUserLocations: function () {
            if (!this.fetching) {
                let params = {}
                this.fetching = true
                if (this.timer) {
                    clearTimeout(this.timer)
                } else {
                    this.loading = true
                }
                if(this.selectedContractIds) {
                    params.contract = this.selectedContractIds
                } else if(this.selectedContractId) {
                    params.contract = this.selectedContractId
                }
                this.restFetchParams(this.userLocationUrl, params, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            this.loading = false
            this.fetching = false
            if (response) {
                this.hideUsers()
                this.logEntries = response.data
                this.showUsers()
            }
            if (!this.destroyed) {
                this.timer = setTimeout(this.fetchUserLocations, 60000)
            }
        },
        hideUsers: function () {
            this.map.removeMapMarkerByType(this.USER)
        },
        getUserMarker: function (age) {
            let MIN15 = 15 * 60 * 1000;
            if (age < MIN15) {
                return this.map.getMarkerStore().getPersonMarkerIcon()
            }
            return this.map.getMarkerStore().getInactivePersonMarkerIcon()
        },
        showUsers: function () {
            this.logEntries.forEach(item => {
                // Draw marker for latest point
                let now = new Date();
                let then = new Date(this.setTime(item.time))
                let marker = this.getUserMarker(now.getTime() - then.getTime())
                this.map.showMapMarker(item.id, this.USER, item.position.y, item.position.x, marker)
            })
        },
        showUserDetails: function (data) {
            // Show info bubble
            if (data.type === this.USER) {
                let item = this.getRecord(data.id)
                if (item) {
                    let bubbleContent = {
                        [this.$t('user_alert.location')]: item.position.y.toFixed(6) + ' ' + item.position.x.toFixed(6),
                        [this.$t('vehicle_position.time')]: this.toLocalTime(item.time),
                        [this.$t('common.company')]: item.company,
                        [this.$t('work_time.mode')]: item.work_time_mode_name,
                        [this.$t('vehicle_position.vehicle')]: item.vehicle ? item.vehicle.make + ' ' + item.vehicle.vehicle_model + ' - ' + item.vehicle.license_plate : '-',
                        [this.$t('orders.contract')]: item.contract_name,
                        [this.$t('orders.task_type')]: item.task_type_name
                    }
                    this.map.showInfoBubble(this.USER, data.id, item.user.first_name + ' ' + item.user.last_name, bubbleContent,
                        item.position.y, item.position.x)
                }
            }
        },
        getRecord: function (id) {
            return this.logEntries.find(function (item) {
                return item.id === id
            })
        }
    }
}
</script>
