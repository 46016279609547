<template>
    <div v-if="value">
        <h3>{{ value.user.first_name }} {{ value.user.last_name }}</h3>
        <span class="item-detail-title">{{ $t('users.phone') }}</span>
        <span>{{ value.user.phone }}</span>
        <span class="item-detail-title">{{ $t('user_alert.time') }}</span>
        <span>{{ toLocalTime(value.time) }}</span>
        <span class="item-detail-title">{{ $t('user_alert.location') }}</span>
        <span>{{ value.location.y }} {{ value.location.x }}</span>
        <span class="item-detail-title">{{ $t('user_alert.contract') }}</span>
        <span v-if="value.contract">{{ value.contract.name }} {{ value.contract.contract_number }}</span>
        <span v-else>-</span>
        <span class="item-detail-title">{{ $t('user_alert.company') }}</span>
        <span v-if="value.company">{{ value.company.name }}</span>
        <span v-else>-</span>
        <span class="item-detail-title">{{ $t('user_alert.type') }}</span>
        <span>{{ alertTypeName(value.type) }}</span>
        <div class="col-sm-12 button-container">
            <b-button
                variant="primary"
                @click.stop="$refs.resolveModal.show()"
            >
                {{ $t('user_alert.resolve') }}
            </b-button>
        </div>
        <b-modal
            ref="resolveModal"
            centered
            hide-footer
            :title="$t('user_alert.resolve_alert')"
        >
            <span class="item-detail-title">{{ $t('user_alert.notes') }}</span>
            <b-form-input
                ref="resolveNotes"
            ></b-form-input>
            <div class="button-container">
                <b-button
                    variant="primary"
                    @click.stop="resolveAlert"
                >
                    {{ $t('user_alert.resolve') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin"
import UserAlert from "@/components/user/alert/UserAlert"
import {restApi} from "@/components/mixins/RestApiMixin"
import {timeUtils} from "../../mixins/TimeUtils"

export default {
    name: "UserAlertEditor",
    mixins: [mapHelper, restApi, timeUtils],
    props: {
        value: {
            type: UserAlert,
            default: null
        }
    },
    methods: {
        alertTypeName(alertType) {
            switch (alertType) {
                case UserAlert.TYPE_AUTOMATIC:
                    return this.$t('user_alert.types.automatic')
                case UserAlert.TYPE_USER_INITIATED:
                    return this.$t('user_alert.types.user_initiated')
            }
            return '-'
        },
        async resolveAlert() {
            await this.value.resolveAlert(this, this.$refs.resolveNotes.localValue)
            this.$refs.resolveModal.hide()
            this.$emit('alertResolved')
        }
    }
}
</script>

<style scoped>

</style>
