<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
            v-bind:class="{ list_closed: !listOpen }"
        >
            <span
                class="vehicle-list-title"
                @click.stop="toggleList">
            {{ $t('menu.work_assignment') }}
            </span>
            <div
                class="vehicle-list-caret"
                v-bind:class="{ caret_open: listOpen }"
                @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>
            <div class="col-sm-12 nopads vehicle-list-container" v-if="listOpen">
                <div class="badge-filters__wrapper ml-3 mt-3">
                    <div class="badge-filters">
                        <b-badge
                            v-for="item in timeFilters"
                            :key="item.value"
                            @click="toggleTimeFilter(item.value)"
                            :variant="isActiveTimeFilter(item.value) ? 'primary' : 'light'"
                            style="padding: .5em 1em"
                        >
                            {{ item.text }}
                        </b-badge>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {restApi} from '../mixins/RestApiMixin'
const STATUS = {
    INCOMPLETE: null,
    ALL: 0,
    OPEN: 1,
    INPROGRESS: 2,
    ASSIGNED: 3,
    READY: 10,
}
// Allow selection of multiple time filters
// const INIT_STATUS = [STATUS.ALL]
export default {
    name: "work-assignment-filter",
    mixins: [restApi],
    props: {
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            isOpen: true,
            loading: false,
            listOpen: false,
            selectedTimeFilters: [],
            timeFilters: [
                {
                    text: this.$t("common.all"),
                    value: STATUS.ALL,
                },
                {
                    text: this.$t("common.open"),
                    value: STATUS.OPEN,
                },
                {
                    text: this.$t("common.in_progress"),
                    value: STATUS.INPROGRESS,
                },
                {
                    text: this.$t("work_assignment.assigned"),
                    value: STATUS.ASSIGNED,
                },
                {
                    text: this.$t("map_filters.contracts_ready"),
                    value: STATUS.READY,
                },
            ],
        };
    },
    methods: {
        toggleTimeFilter(val) {
            if (val === STATUS.ALL) {
                // If the value is 0, clear all other filters and select only 0
                this.selectedTimeFilters = this.selectedContractIds && this.selectedContractIds.length > 0 ? [STATUS.ALL] : [STATUS.OPEN, STATUS.INPROGRESS, STATUS.ASSIGNED];
            } else {
                // If 0 is already selected, remove it
                this.selectedTimeFilters = this.selectedTimeFilters.filter(item => item !== STATUS.ALL);

                const index = this.selectedTimeFilters.indexOf(val);
                if (index > -1) {
                    // If the filter is already selected, remove it
                    this.selectedTimeFilters.splice(index, 1);
                } else {
                    // If the filter is not selected, add it
                    this.selectedTimeFilters.push(val);
                }
            }
        },
        setDefaultValue() {
            this.selectedTimeFilters = [STATUS.OPEN, STATUS.INPROGRESS, STATUS.ASSIGNED];
        },
        isActiveTimeFilter(val) {
            return this.selectedTimeFilters.includes(val);
        },
        toggleList: function () {
            this.listOpen = !this.listOpen
        },
    },
    computed: {
        amountOfFiltersSelected() {
            return (this.selectedTimeFilters ? 1 : 0) + (this.selectedContractId ? 1 : 0)
        }
    },
    watch: {
        selectedTimeFilters(val) {
            this.$emit('filterSelected', val)
        }
    },
}
</script>
<style lang="scss" scoped>
.badge-filters {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    flex: 5;
    flex-wrap: wrap;
    & > * {
        cursor: pointer;
    }
    &__wrapper {
        display: flex;
        align-items: flex-start;
    }
}
</style>
