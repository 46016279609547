import {material} from "./ConversionMixin";

export const railingTypes = {
    STEEL: 1,
    PIPE: 2,
    WIRE: 3,
    TWIN_PIPE: 4,
    CONCRETE: 5,
    OTHER: 12,
    STEEL_BUILT: 13,
    CONCRETE_BUILT: 14
}

export const railingPostTypes = {
    U_100: 1,
    U_160: 2,
    TRACK_RAIL: 3,
    CONCRETE: 4,
    OTHER: 5,
    REDUCED_U_160: 6,
    SIGMA: 7,
}

export const railingConstructions = {
    BRIDGE_RAILING_ON_THE_GROUND: 1,
    BRIDGE_RAILING_ON_A_PIPE_PILE: 2,
    BRIDGE_RAILING_IN_THE_CORNER_RETAINING_WALL: 3,
    ANCHORED_CONCRETE_RAILING: 4,
    POST_IN_CONCRETE_CASTING: 5,
    COMPRESSED_POST_SPACING: 6,
    CONSTRUCTING_WITH_PLASTIC_POLES: 7,
}

export const railingSides = {
    ONE_SIDED: 1,
    TWO_SIDED: 2
}

export const railingHelper = {
    methods: {
        getRailingTypes: function () {
            return [
                {name: this.$t('materials.steel'), value: railingTypes.STEEL},
                {name: this.$t('railing.pipe'), value: railingTypes.PIPE},
                {name: this.$t('railing.wire'), value: railingTypes.WIRE},
                {name: this.$t('railing.twin_pipe'), value: railingTypes.TWIN_PIPE},
                {name: this.$t('materials.concrete'), value: railingTypes.CONCRETE},
                {name: this.$t('materials.other'), value: railingTypes.OTHER},
                {name: this.$t('railing.steel_built'), value: railingTypes.STEEL_BUILT},
                {name: this.$t('railing.concrete_built'), value: railingTypes.CONCRETE_BUILT},
            ]
        },
        getRailingPostTypes: function () {
            return [
                {name: this.$t('railing.post_type.u_100'), value: railingPostTypes.U_100},
                {name: this.$t('railing.post_type.u_160'), value: railingPostTypes.U_160},
                {name: this.$t('railing.post_type.track_rail'), value: railingPostTypes.TRACK_RAIL},
                {name: this.$t('railing.post_type.concrete'), value: railingPostTypes.CONCRETE},
                {name: this.$t('railing.post_type.other'), value: railingPostTypes.OTHER},
                {name: this.$t('railing.post_type.reduced_u_160'), value: railingPostTypes.REDUCED_U_160},
                {name: this.$t('railing.post_type.sigma'), value: railingPostTypes.SIGMA},
            ]
        },
        getRailingConstructions: function () {
            return [
                {name: this.$t('railing.construction.bridge_railing_on_the_ground'), value: railingConstructions.BRIDGE_RAILING_ON_THE_GROUND},
                {name: this.$t('railing.construction.bridge_railing_on_a_pipe_line'), value: railingConstructions.BRIDGE_RAILING_ON_A_PIPE_PILE},
                {name: this.$t('railing.construction.bridge_railing_in_the_corner_retaining_wall'), value: railingConstructions.BRIDGE_RAILING_IN_THE_CORNER_RETAINING_WALL},
                {name: this.$t('railing.construction.anchored_concrete_railing'), value: railingConstructions.ANCHORED_CONCRETE_RAILING},
                {name: this.$t('railing.construction.post_in_concrete_casting'), value: railingConstructions.POST_IN_CONCRETE_CASTING},
                {name: this.$t('railing.construction.compressed_post_spacing'), value: railingConstructions.COMPRESSED_POST_SPACING},
            ]
        },
        getRailingSides: function () {
            return [
                {name: this.$t('railing.side.one_sided'), value: railingSides.ONE_SIDED},
                {name: this.$t('railing.side.two_sided'), value: railingSides.TWO_SIDED},
            ]
        },
        getMaterials: function () {
            return [
                {name: this.getMaterialString(material.ALUMINIUM), value: material.ALUMINIUM},
                {name: this.getMaterialString(material.PLYWOOD), value: material.PLYWOOD},
                {name: this.getMaterialString(material.GLAS), value: material.GLAS},
                {name: this.getMaterialString(material.WOOD), value: material.WOOD},
                {name: this.getMaterialString(material.STEEL), value: material.STEEL},
                {name: this.getMaterialString(material.CAST_IRON), value: material.CAST_IRON},
                {name: this.getMaterialString(material.GROUND_SUBSTANCE), value: material.GROUND_SUBSTANCE},
                {name: this.getMaterialString(material.OTHER), value: material.OTHER},
                {name: this.getMaterialString(material.CONCRETE), value: material.CONCRETE},
                {name: this.getMaterialString(material.PLASTIC), value: material.PLASTIC},
                {name: this.getMaterialString(material.ROCK), value: material.ROCK},
                {name: this.getMaterialString(material.PLEXIGLASS), value: material.PLEXIGLASS},
                {name: this.getMaterialString(material.GLASS_FIBER), value: material.GLASS_FIBER},
                {name: this.getMaterialString(material.ANIMAL_NET), value: material.ANIMAL_NET},
                {name: this.getMaterialString(material.ARMOR_MESH), value: material.ARMOR_MESH},
                {name: this.getMaterialString(material.METAL), value: material.METAL},
                {name: this.getMaterialString(material.ASPHALT), value: material.ASPHALT},
                {name: this.getMaterialString(material.ALUMINIUM_COMPOSITE), value: material.ALUMINIUM_COMPOSITE},
            ]
        },
    }
}
