<template>
    <div class="col-sm-12 nopads">
        <transition name="fade">
            <div
                v-if="results"
                id="accordion"
                class="col-sm-12 list-content result-table pointer"
            >
                <b-table
                    hover
                    show-empty
                    small
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @row-clicked="emitDetails"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('common.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(start_time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                    <template v-slot:cell(end_time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'TrafficControlReportList',
    mixins: [timeUtils],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'start_time',
            sortDesc: true,
            fields: [
                {key: 'id', label: this.$t('danger_report.report_id'), sortable: true},
                {key: 'contract', label: this.$t('danger_report.contract'), sortable: true},
                {key: 'start_time', label: this.$t('danger_report.event_time'), sortable: true},
                {key: 'end_time', label: this.$t('danger_report.event_time'), sortable: true},
                {key: 'road_name', label: this.$t('road_sign.road_name'), sortable: true},
                {key: 'road_number', label: this.$t('map.road_number'), sortable: true},
                {key: 'road_section', label: this.$t('map.road_section'), sortable: true},
                {key: 'description', label: this.$t('danger_report.description'), sortable: true},
            ],
            items: []
        }
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        initTableItems: function () {
            this.items = []
            this.results.forEach(function (item) {
                this.items.push(
                    {
                        id: item.id,
                        start_time: item.start_time,
                        end_time: item.end_time,
                        road_name: item.road_name,
                        road_number: item.road_number,
                        road_section: item.road_section,
                        description: item.description,
                        contract: item.contract ? item.contract.name : "",
                    }
                )
            }, this)
        },
        emitDetails: function (details) {
            let report = this.results.find(item => item.id === details.id)
            this.$emit('details', report)
        }
    }
}
</script>
