<template>
    <div>
        <div class="coordinate-map">
            <marker-editor-map
                ref="map"
                :lat="coordinate ? coordinate.lat : 62.7"
                :lon="coordinate ? coordinate.lng : 25.2"
                :type=0
                @mounted="mapMounted"
            />
        </div>
    </div>
</template>

<script>
import MarkerEditorMap from "../map/MarkerEditorMap";

export default {
    name: 'FileImportPreview',
    components: {MarkerEditorMap},
    props: {
        coordinate: {
            type: Object,
            default: null
        },
        value: {
            type: Object
        },
        pointType: {
            type: Number,
            default: null
        },
        geoJsonToRender: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            map: null,
            geoJson: ''
        }
    },
    methods: {
        mapMounted(map) {
            this.map = map;
        },
        renderGeoJson: function (geoJson){
            this.geoJson = geoJson
            this.map.hideImportedObjects()
            this.map.addGeoJsonObjects(geoJson)
        },
        getGeoJson: function () {
            return this.geoJson
        }
    }
}
</script>

<style scoped>
</style>
