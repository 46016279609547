<template>
    <div
        class="details-container"
        @click.stop="$emit('close')"
    >
        <div
            class="details"
            style="width: 780px; margin: auto;"
            @click.stop=""
        >
            <div class="col-12 mb-2 pb-2 dont-print" style="border-bottom: #d9d9d9 1px solid">
                <b-form inline class="justify-content-end">
                    <b-form-checkbox size="sm" switch v-model="showAllPhotos" class="mr-2 ml-2">{{ $t('observations.show_all_photos')}}</b-form-checkbox>
                    <b-button
                        variant="secondary"
                        class="mr-1 ml-1"
                        size="sm"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                    <b-button
                        variant="primary"
                        class="mr-1 ml-1"
                        size="sm"
                        :disabled="loading > 0 || printReady !== observations.length"
                        @click.stop="print"
                    >
                        {{ $t('common.print') }}
                    </b-button>
                </b-form>
            </div>
            <div id="printContainer">
                <div class="print-page">
                    <b-row class="nopads">
                        <div class="col-6 nopads">
                            <h1 class="report-header">
                                {{ $t('observations.pdf_title')}}
                            </h1>
                            {{ getCurrentDay() }}
                        </div>
                        <div class="col-6 nopads" style="text-align: right">
                            <img src="../../assets/routa_dark.jpg"
                                 class="print-logo" alt="logo"/>
                        </div>
                    </b-row>
                    <hr />
                    <!-- Summary map -->
                    <b-row>
                        <div class="map-container summary-map-container">
                            <map-container
                                ref="mapContainer"
                                :find-user="false"
                                @onMapChanged="mapChanged"
                            />
                        </div>
                    </b-row>
                    <!-- Summary figures -->
                    <b-table class="mt-2" small :items="items" :fields="fields"></b-table>
                </div>
                <hr class="dont-print"/>
                <observation-print-details
                    v-for="observation in observations"
                    v-bind:key="observation.id"
                    :observation="observation"
                    :report-completed="reportCompleted"
                    :show-all-photos="showAllPhotos"
                    @loadingStarted="loading++"
                    @loadingCompleted="loading--"
                    @readyToPrint="printReady++"
                />
            </div>
        </div>
        <div
            v-if="loading > 0 || printReady !== observations.length"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {printHelper} from '../mixins/PrintMixin'
import {Printd} from "printd";
import {timeUtils} from "@/components/mixins/TimeUtils";
import ObservationPrintDetails from "@/components/observation/ObservationPrintDetails";
import MapContainer from "@/components/map/MapContainer";
import {geomTypes} from "@/components/mixins/ObservationMixin";

export default {
    name: 'ObservationDetailsReport',
    components: {MapContainer, ObservationPrintDetails},
    mixins: [printHelper, timeUtils],
    props: {
        observations: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            map: null,
            loading: 0,
            reportCompleted: false,
            printReady: 0,
            fullScreenPhotoData: null,
            detailsVisible: null,
            showAllPhotos: false,
            GROUP_ID: 0,
            Z_INDEX: {
                BEHIND: 2,
                INFRONT: 5
            },
            fields: [
                {key: 'name', label: this.$t('road_reports.type')},
                {key: 'count', label: this.$t('road_reports.count')}
            ],
            items: []
        }
    },
    watch: {
        loading: function () {
            if (this.loading === 0 || this.loading < 0) {
                this.loading = 0
                this.reportCompleted = true
            }
        }
    },
    mounted() {
        this.d = new Printd()
        this.map = this.$refs.mapContainer ? this.$refs.mapContainer.getMap() : undefined
        this.initView()
    },
    methods: {
        print() {
            if (!this.loading) {
                this.d.print(document.getElementById('printContainer'), this.printStyle)
            }
        },

        mapChanged: function (map) {
            this.map = map
            this.initView()
        },

        initView() {
            // Draw observations on the map
            if (this.map) {
                this.$nextTick(() => this.map.hideMapControls())
                this.icon = this.map.getMarkerStore().getObservationMarkerIcon()
                //Remove any previous observations
                this.map.removeMapItemsByType(this.OBSERVATION)
                //Draw the new observations
                this.drawObservations()
                //Zoom to the observations
                this.map.zoomToGroup(this.OBSERVATION)
            }
            // Table summaries
            this.initTableItems()
        },

        initTableItems() {
           let tbl = []
           this.observations.forEach(obs => {
               let existing = tbl.find(item => item.id === obs.type.id)
               if (!existing) {
                   tbl.push({
                       id: obs.type.id,
                       name: obs.type.name,
                       count: 1
                   })
               } else {
                   existing.count++
               }
           })
           this.items = tbl
        },

        drawObservations: function () {
            for(let observation of this.observations) {
                if (observation.type.geometry_type === geomTypes.POINT) {
                    this.map.showMapMarker(observation.id, this.OBSERVATION, observation.geometry.point.y,
                        observation.geometry.point.x, this.icon, observation.closed_time ? this.Z_INDEX.BEHIND : this.Z_INDEX.INFRONT, this.GROUP_ID)
                } else if (observation.type.geometry_type === geomTypes.LINESTRING) {
                    var color = observation.closed_time ? '#bcbcbc' : '#bb330e'
                    var points = observation.geometry.line_string.points.map(point => {
                        return {x: point[0], y: point[1]}
                    })
                    this.map.drawPolyLine(observation.id, this.OBSERVATION, points, color, false, false, 3, 1, true)
                }
            }
        },

    }
}
</script>

<style scoped>
.report-header {
    margin: 0;
    padding-top: .2em;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.summary-map-container {
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 1em;
    overflow: hidden;
    width: 750px;
    height: 350px;
    margin: auto;
    margin-bottom: .5em;
}

</style>

