import { render, staticRenderFns } from "./CulvertEditor.vue?vue&type=template&id=1e867045&scoped=true"
import script from "./CulvertEditor.vue?vue&type=script&lang=js"
export * from "./CulvertEditor.vue?vue&type=script&lang=js"
import style0 from "./CulvertEditor.vue?vue&type=style&index=0&id=1e867045&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e867045",
  null
  
)

export default component.exports