<template>
    <div class="nopads" v-if="results.length > 0">
        <div class="nopads">
            <b-row>
                <b-col class="nopads report-header" cols="10">
                    <span class="section-title" style="display: block">{{ $t('work_report.contract_diary') }} ({{results.length}})</span>
                </b-col>
                <b-col class="nopads">
                    <font-awesome-icon
                        icon="arrow-down"
                        class="pointer right no-focus arrow-icon dont-print"
                        v-bind:class="{rotated : diariesVisible}"
                        v-b-toggle.contractDiaries
                        @click.stop="diariesVisible = !diariesVisible"/>
                </b-col>
            </b-row>
            <b-collapse
                id="contractDiaries"
                class="mt-2 mb-2 item-details show-print"
            >
                <div v-if="results && results.length > 0" class="nopads">
                    <contract-diary-list
                        :ely-fields="elyFields"
                        :results="results"
                        @edit="editDiary"
                    />
                </div>
            </b-collapse>
            <hr class="report-divider nopads"/>
        </div>
        <transition name="fade">
            <div v-if="contractDiary" class="details-container">
                <div class="details dynamic_width nopads">
                    <contract-diary-editor
                        v-model="contractDiary"
                        :report-list-view="true"
                        :show-ely-fields="elyFields"
                        @close="close"
                        @closeAndUpdate="closeAndUpdate"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import ContractDiaryList from "@/components/contractreport/ContractDiaryList.vue";
import ContractDiaryEditor from "@/components/contractreport/ContractDiaryEditor.vue";

export default {
    name: "contract-diaries-report",
    components: {ContractDiaryEditor, ContractDiaryList},
    mixins: [timeUtils, restApi],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        elyFields: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            diariesVisible: false,
            editMode: false,
            contractDiary: null,
            results: [],
            events: "",
            deviations: "",
            date: null,
        }
    },
    watch: {
        searchParams () {
            this.fetchDiaries()
        }
    },
    mounted() {
        this.fetchDiaries()
    },
    methods: {
        fetchDiaries: function () {
            this.results = []
            this.diariesVisible = false
            if (this.searchParams) {
                let mappedParams = {
                    contract: this.searchParams.contract,
                    from: this.searchParams.dateFrom ? this.getDateIsoString(new Date(this.searchParams.dateFrom)) : null,
                    to: this.searchParams.dateTo ? this.getDateIsoString(new Date(this.searchParams.dateTo)) : null,
                }
                this.$emit('loading', true)
                this.restFetchParams(this.contractDiaryUrl, mappedParams, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response && response.data) {
                this.results = response.data
            }
            this.$emit('loading', false)
        },
        editDiary(diary) {
            this.contractDiary = diary
        },
        close() {
            this.contractDiary = null
        },
        closeAndUpdate() {
            this.contractDiary = null
            this.fetchDiaries()
        }
    }
}
</script>
