<template>
    <div>
        <base-modal
            id="copy_modal"
            ref="copy_modal"
            :hide-header=true
            :hide-footer=true
            @ok="doCopyWorkAssignment">
            <div class="col-sm-12 nopads pl-2 pr-2">
                <span class="pl-2 pb-2 title" style="text-transform: uppercase">{{ $t('work_assignment.copy') }}</span>
                <span class="pl-2 pr-2 pb-2">{{ $t('work_assignment.copy_confirm') }}</span>
            </div>
            <div class="col-12">
                <div class="d-flex align-items-center pt-4 pr-2">
                    <ul class="actions-list w-100" style="list-style: none;">
                        <li class="d-flex">
                                        <span>
                                            {{ $t('contracts.copy_contract.copy_attachments') }}
                                        </span>
                            <span class="ml-auto">
                                            <b-form-checkbox v-model="copyAttachments" name="copy-attachments" switch />
                                        </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="cancelCopying">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="doCopyWorkAssignment">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </base-modal>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import BaseModal from "@/components/BaseModal";
import {EventBus} from '@/event-bus'
import {restApi} from "@/components/mixins/RestApiMixin";
export default {
    name: "WorkAssignmentCopyModal",
    components: {BaseModal},
    mixins: [restApi],
    props: {
        assignmentUpdate: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            loading: false,
            copyAttachments: false
        }
    },
    methods: {
        doCopyWorkAssignment() {
            this.loading = true
            this.restAdd(this.workAssignmentUrl+'/'+this.assignmentUpdate.id+'/copy',
                {copy_attachments: this.copyAttachments ? true : null},
                this.copySuccess, this.fail)
        },
        copySuccess: function (data) {
            this.loading = false
            this.$emit('updateSuccess', data)
        },
        fail: function () {
            this.loading = false
            this.$emit('cancel')
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        cancelCopying() {
            this.$emit('cancel')
        },
    }
}
</script>
