import Vue from 'vue'
import Vuex from 'vuex'

// Store Modules
import * as contracts from './modules/contracts'

Vue.use(Vuex)

const state = {
    token: '',
    mapKey: '',
    mapEnv: '',
    copiedGeometries: null,
    mapSettings: {},
}

const mutations = {
    storeCopiedGeometries(state, value) {
        state.copiedGeometries = value
    },
    storeToken(state, value) {
        state.token = value
    },
    storeMapKey(state, value) {
        state.mapKey = value
    },
    storeMapEnv(state, value) {
        state.mapEnv = value
    },
    storeMapSettings(state, value) {
        state.mapSettings = {
            ...state.mapSettings,
            ...value,
        }
    },
}
export default new Vuex.Store({
    modules: {
        contracts,
    },
    state,
    mutations,
})
