<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <b-row class="nopads">
                <div class="col-sm-6">
                    <span class="span-title">{{ $t('street_lights.status') }}</span>
                    <b-form-group
                        label-for="value"
                    >
                        <b-form-select
                            v-model="status"
                            size="sm"
                            :options="statusOptions"
                        >
                            <template #first>
                                <option :value="null">{{ $t('common.all') }}</option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <span class="span-title">{{ $t('street_lights.station') }}</span>
                    <b-form-select id="lightStation" size="sm" v-model="station" :options="stations">
                        <template #first>
                            <option :value="null">{{ $t('street_lights.select_station') }}</option>
                        </template>
                    </b-form-select>
                </div>
            </b-row>
            <div class="col-12 nopads">
                <contract-filter
                    ref="filter"
                    :user="user"
                    :showOnlyMyContractsOption="true"
                    :is-admin="isAdmin"
                    @contractSet="setContract"
                    @companySet="companySet"
                />
            </div>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import ContractFilter from '../contract/ContractFilter'
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from "@/components/mixins/RestApiMixin"
import {cableHelper} from "@/components/mixins/CableMixin";
import {streetLightHelper} from "@/components/mixins/StreetLightMixin";

export default {
    name: 'CableSearch',
    components: {ContractFilter},
    mixins: [timeUtils, restApi, cableHelper, streetLightHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    data: function () {
        return {
            OK: 1,
            DEFECT: 2,
            PLANNED: 3,
            NOT_IN_USE: 4,
            contract: null,
            company: null,
            status: null,
            stations: [],
            station: null
        }
    },
    mounted() {
        this.fetchStations()
    },
    methods: {
        setContract: function (contract) {
            this.contract = contract
        },
        companySet: function (company) {
            this.company = company
        },
        onSubmit: function () {
            let params = {}
            if (this.contract) {
                params.contract = this.contract
            }
            if (this.sign) {
                params.sign = this.sign.value
            }
            if (this.company) {
                params.company = this.company
            }
            if (this.status) {
                params.status = this.status
            }
            if (this.station) {
                params.station = this.station
            }
            this.$emit('search', params)
        },
        onReset: function () {
            this.contract = null
            this.status = null
            this.station = null
            this.$refs.filter.resetData()
        },
        fetchStations() {
            this.loading = true
            this.stations = []
            this.restFetch(this.powerStationUrl, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            if (response && response.data) {
                response.data.forEach(station => {
                    this.stations.push({value: station.id, text: station.id + ' ' + station.street})
                })
            }
            this.loading = false
        },
    }
}
</script>
