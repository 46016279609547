<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="asd">
        <b-table
            :items="tableItems"
            :responsive="true"
            :fields="fields"
            small
        >
            <template
                v-slot:cell(name)="row"
            >
                <b-form-input
                    v-if="editable"
                    v-model="row.item.name"
                    type="text"
                    size="sm"
                    :required="editable"
                    :state="validateName(row.item.name)"
                />
                <span v-else>{{ row.value }}</span>
                <div v-if="validateUnit(row.item.name) !== null">
                    {{ $t('materialstorage.name_required')}}
                </div>
            </template>
            <template
                v-slot:cell(amount)="row"
            >
                <b-form-input
                    v-if="editable"
                    :value="row.item.amount"
                    @input="i => row.item.amount = Number(i)"
                    type="number"
                    size="sm"
                />
                <span v-else>{{ row.value }}</span>
            </template>
            <template
                v-slot:cell(unit)="row"
            >
                <div>
                <b-form-select
                    v-if="editable"
                    v-model="row.item.unit"
                    :options="units"
                    size="sm"
                    :required="editable"
                    :state="validateUnit(row.item.unit)"
                >
                </b-form-select>

                <span v-else>{{ row.value }}</span>
                </div>
                <div v-if="validateUnit(row.item.unit) !== null">
                    {{ $t('materialstorage.unit_required')}}
                </div>
            </template>
            <template
                v-slot:cell(status)="row"
            >
                <b-form-select
                    v-if="editable"
                    :value="row.item.status"
                    @input="i => row.item.status = Number(i)"
                    :options="statuses"
                    size="sm"
                >
                </b-form-select>
                <span v-else>{{ statuses[row.value] }}</span>
            </template>
            <template
                v-slot:cell(edit)="row"
            >
                <b-button
                    variant="danger"
                    @click.stop="inactivateMaterial(row.item.id, row.item.name)"
                >
                    <i class="fa fa-times"/>
                </b-button>
            </template>
        </b-table>
        <div class="button-container">
            <b-button
                v-if="editable"
                variant="success"
                size="sm"
                @click.stop="addNewMaterial()"
            >
                <span>{{ $t('materialstorage.record.add_new') }}</span>
            </b-button>
        </div>
        <div class="col-12 nopads"
             v-if="inactiveItems && inactiveItems.length > 0"
        >
            <span class="span-title">{{ $t('materialstorage.inactive_materials') }}</span>
            <b-table
                :items="inactiveItems"
                :responsive="true"
                :fields="fields"
                small
            >
                <template
                    v-slot:cell(edit)="row"
                >
                    <div class="button-container">
                        <b-button
                            variant="primary"
                            size="sm"
                            @click.stop="activateMaterial(row.item.id)"
                        >
                            {{ $t('materialstorage.restore')}}
                        </b-button>
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {newMaterialRecord, Status} from './MaterialStorageHelper'

export default {
    name: 'MaterialRecordList',
    props: {
        value: {
            type: Array
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editing: [],
            tableItems: [],
            inactiveItems: [],
            units : [
                {value: 'kpl', text: this.$t('unit.pcs')},
                {value: 'kg', text: this.$t('unit.kg')},
                {value: 't', text: this.$t('unit.tons')},
                {value: 'm', text: this.$t('unit.meters')},
                {value: 'km', text: this.$t('unit.km')},
                {value: 'm2', text: this.$t('unit.square_m')},
                {value: 'm3', text: this.$t('unit.cubic_m')},
                {value: 'l', text: this.$t('unit.liter')},
            ]
        }
    },
    computed: {
        fields() {
            return [
                {
                    key: 'name',
                    label: this.$t('materialstorage.record.name'),
                    sortable: true
                },
                {
                    key: 'amount',
                    label: this.$t('materialstorage.record.amount'),
                    sortable: true
                },
                {
                    key: 'unit',
                    label: this.$t('materialstorage.record.unit'),
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t('materialstorage.record.status'),
                    sortable: true
                },
                !this.editable ? undefined : {
                    key: 'edit',
                    label: ''
                }
            ]
        },
        statuses() {
            return {
                [Status.AVAILABLE]: this.$t('materialstorage.record.status_available'),
                [Status.ORDERED]: this.$t('materialstorage.record.status_ordered'),
                [Status.OUT_OF_STOCK]: this.$t('materialstorage.record.status_out_of_stock')
            }
        }
    },
    mounted() {
        this.initTableItems()
    },
    methods: {
        validateUnit(unit) {
            if (this.editable && !unit) {
                return false;
            }
            return null;
        },
        initTableItems() {
            this.tableItems = this.value.filter(item => item.active === true)
            this.inactiveItems = this.value.filter(item => item.active === false)
        },
        activateMaterial(id) {
            let material = this.value.find(item => item.id === id)
            if (material) {
                material.active = true
                this.initTableItems()
            }
        },
        inactivateMaterial(id, name) {
            if (id) {
                let material = this.value.find(item => item.id === id)
                if (material) {
                    material.active = false
                    this.initTableItems()
                }
            } else {
                // Remove material from array - not added to backend yet
                const pos = this.value.findIndex(item => item.name === name)
                if (pos >= 0) {
                    this.value.splice(pos, 1)
                    this.initTableItems()
                }
            }
        },
        validateName(name) {
            if (this.value.filter(record => record.name === name).length !== 1) {
                return false
            }
            return null
        },
        addNewMaterial() {
            this.value.push(this.newMaterialRecord(''))
            this.initTableItems()
        },
        newMaterialRecord
    }
}
</script>

<style scoped>
.asd {
    display: block;
    height: auto;
    overflow-y: auto;
}
</style>
