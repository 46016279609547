<template>
    <div class="col-12 nopads">
        <span class="span-title">{{ $t('equipments.other_equipment') }}</span>
        <b-row class="nopads mb-2">
            <div class="col-3">
                {{ $t('equipments.equipment') }}
            </div>
            <div class="col-3 ">
                {{ $t('equipments.action') }}
            </div>
            <div class="col-3 ">
                {{ $t('equipments.condition') }}
            </div>
            <div class="col-3 pr-3 pl-0" v-b-tooltip:fullScreenContainer.hover :title="$t('orders.calculate_tooltip')">
                {{ $t('equipments.material') }}
            </div>
        </b-row>
        <div v-for="(item, index) in linkedEquipments" :key="index">
            <b-row class="nopads">
                <div class="col-sm-3 mt-1">
                    <span class="mt-1">{{ getVelhoEquipmentTypeOid(item.oid) }}</span>
                </div>
                <div class="col-sm-3">
                    <multiselect
                        v-model="item.actions"
                        class="multiselect_routa mt-1"
                        :placeholder="$t('equipment_actions.select_action')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="false"
                        :multiple="true"
                        label="name"
                        track-by="value"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="getEquipmentActions()"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
                <div class="col-sm-3">
                    <span class="d-inline-block">
                        <b-form-select
                            v-model="item.condition"
                            size="sm"
                            class="sm-3 mt-1"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('orders.select_geometry_label') }}
                                </option>
                            </template>
                            <option
                                v-for="(propKey) in getConditions()"
                                :key="propKey.value"
                                :value="propKey.value"
                            >
                                {{ propKey.name }}
                            </option>
                        </b-form-select>
                    </span>
                </div>
                <div class="col-sm-3">
                    <span class="d-inline-block">
                        <b-form-select
                            v-model="item.material"
                            :id="'layer-label' + item.name + index"
                            size="sm"
                            class="sm-3 mt-1"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('orders.select_geometry_label') }}
                                </option>
                            </template>
                            <option
                                v-for="(propKey) in getMaterials()"
                                :key="propKey.value"
                                :value="propKey.value"
                            >
                                {{ propKey.name }}
                            </option>
                        </b-form-select>
                    </span>
                </div>
            </b-row>
        </div>
    </div>
</template>

<script>
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";

export default {
    name: 'LinkedEquipment',
    mixins: [roadSignHelper, conversionModelHelper],
    props: {
        linkedEquipments: {
            type: Array,
            default: null
        }
    }
}
</script>
