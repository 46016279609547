<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-content nopads">
        <transition name="fade">
            <div class="list-content result-table pointer">
                <b-table
                    show-empty
                    small
                    striped
                    @row-clicked="rowClicked"
                    :items="bridges"
                    :fields="fields"
                />
            </div>
        </transition>
    </div>
</template>

<script>


export default {
    name: 'bridge-list',
    props: {
        bridges: {
            type: Array,
            default() {
                return []
            }
        }
    },
    computed: {
        fields() {
            return [
                {key: 'digiroad_id', label: this.$t('common.id'), sortable: true},
                {key: 'bridgeNumber', label: this.$t('bridge.bridge_num'), sortable: true},
                {key: 'name', label: this.$t('bridge.name'), sortable: true},
                {key: 'length', label: this.$t('bridge.length'), sortable: true},
                {key: 'startDistance', label: this.$t('bridge.start_distance'), sortable: true},
                {key: 'roadNum', label: this.$t('bridge.road_num'), sortable: true},
                {key: 'secNum', label: this.$t('bridge.sec_num'), sortable: true},
                {key: 'type', label: this.$t('bridge.type'), sortable: true},
                {key: 'city', label: this.$t('bridge.city'), sortable: true},
                {key: 'jobArea', label: this.$t('bridge.job'), sortable: true}]
        }
    },
    methods: {
        rowClicked: function (details) {
            this.$emit('rowClicked', details)
        }
    }
}
</script>
