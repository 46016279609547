<template>
    <div class="col-sm-12 nopads">
        <!-- Details -->
        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_permission.requested_by') }}</span>
                <span class="item-detail-text">
                    {{ getName(workPermission.requested_by) }}
                </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_permission.created') }}</span>
                <span class="item-detail-text">
                    {{ getDateString(workPermission.created) }}
                </span>
            </div>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_permission.type') }}</span>
                <span class="item-detail-text">
                    {{ getTypeString(workPermission.type) }}
                </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_permission.contract') }}</span>
                <span class="item-detail-text">
                    {{ workPermission.contract ? workPermission.contract.name : '-' }}
                </span>
            </div>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_permission.handled_by') }}</span>
                <span class="item-detail-text">
                    {{ getName(workPermission.handled_by) }}
                </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_permission.handled_at') }}</span>
                <span class="item-detail-text">
                    {{ getDateString(workPermission.handling_time) }}
                </span>
            </div>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_permission.info') }}</span>
                <span class="item-detail-text">
                    {{ workPermission.info }}
                </span>
            </div>
        </b-row>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {workPermissionHelper} from '../mixins/WorkPermissionMixin'

export default {
    name: 'work-assignment-work-permission',
    mixins: [timeUtils, workPermissionHelper],
    props: {
        workPermission: {
            type: Object,
            required: true
        }
    }
}
</script>
