<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showObservationGroupList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('observations.group_title') }}</span>
            </div>
            <div
                v-else
                @click="showObservationGroupList"
                class="header"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div
            v-if="!editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <observation-group-list
                    :is-admin="isAdmin"
                    :results="results"
                    @add="addObservationGroup"
                    @edit="editObservationGroup"
                />
            </transition>
        </div>

        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <observation-group-editor
                    :is-admin="isAdmin"
                    :observation-group="observationGroup"
                    @close="showObservationGroupList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {observationHelper} from '../../mixins/ObservationMixin'
import ObservationGroupList from './ObservationGroupList'
import ObservationGroupEditor from './ObservationGroupEditor'

export default {
    name: 'ObservationGroups',
    components: {ObservationGroupList, ObservationGroupEditor},
    mixins: [restApi, observationHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            observationGroup: null,
            editMode: false,
            loading: false
        }
    },
    mounted: function () {
        this.fetchObservationGroups()
    },
    methods: {
        fetchObservationGroups: function () {
            this.loading = true
            this.restFetch(this.observationGroupUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        searchWithCompany: function (companyId) {
            this.results = []
            if (companyId) {
                this.loading = true
                this.restFetchParams(this.observationGroupUrl, {company: companyId}, this.handleResponse)
            }
        },
        addObservationGroup: function () {
            this.observationGroup = this.newObservationGroup()
            this.editMode = true
        },
        editObservationGroup: function (group) {
            this.observationGroup = group
            this.editMode = true
        },
        showObservationGroupList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchObservationGroups()
            this.showObservationGroupList()
        }
    }
}
</script>
