<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('forest_work_eff.efficiencies') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
            </div>
        </b-navbar>
        <div class="col-sm-12 nopads">
            <!-- Add new -->
            <div
                class="data-list-item add ml-3 mr-3"
                @click.stop="addEfficiency"
            >
                <div class="item-img-cont add-title">
                    <i class="fa fa-plus"/>
                </div>
                <div class="item-text-cont">
                    <span class="item-title-add">{{ $t('common.add_new') }}</span>
                    <font-awesome-icon
                        icon="file-contract"
                        class="card-body-icon"
                    />
                </div>
            </div>
            <!-- List -->
            <efficiency-list
                v-if="!editMode"
                :results="results"
                @add="addEfficiency"
                @edit="editEfficiency"
            />
            <efficiency-editor
                v-if="editMode"
                :efficiency="efficiency"
                @close="showList"
                @closeAndUpdate="closeAndUpdate"/>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import EfficiencyEditor from './EfficiencyEditor'
import EfficiencyList from './EfficiencyList'

export default {
    name: "Efficiency",
    components: {EfficiencyList, EfficiencyEditor},
    mixins: [restApi],
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            editMode: false,

            results: [],
            efficiency: null,
            loading: true,
        }
    },

    mounted: function () {
        this.fetchEfficiencies()
    },

    methods: {
        fetchEfficiencies: function () {
            this.loading = true
            this.restFetch(this.efficiencyUrl, this.handleResponse)
        },

        handleResponse: function (response) {
            if (response) {
                this.results = response.data
            }
            this.loading = false
        },


        addEfficiency: function () {
            this.efficiency = this.newEff()
            this.editMode = true
        },

        editEfficiency: function (eff) {
            this.efficiency = eff
            this.editMode = true
        },


        newEff: function () {
            return {
                id: -1,
                name: '',
                task_types: [],
            }
        },

        showList: function () {
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.fetchEfficiencies()
            this.showList()
        },

    }

}
</script>

<style scoped>

</style>
