<template>
    <div class="col-sm-12 list-container p-3">
        <transition name="fade">
            <!-- Main table element -->
            <div class="col-sm-12 list-content result-table">
                <b-table
                    small
                    id="resultTable"
                    ref="resultTable"
                    :items="results"
                    :fields="fields"
                    @row-clicked="emitDetails"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '../../mixins/TimeUtils'
import {vehicleHelper} from '../../mixins/VehicleMixin'

export default {
    name: 'VehicleAllocationList',
    mixins: [timeUtils, vehicleHelper],
    props: {
        items: {
            type: Array,
            default() {
                return []
            }
        },
        params: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            fields: [
                {
                    vehicle: {
                        label: this.$t('trip_list.vehicle'),
                        sortable: true
                    }
                },
                {
                    duration: {
                        label: this.$t('trip_list.duration') + ' ' + '(h)',
                        sortable: true
                    }
                },
                {
                    vehicle_allocation_time: {
                        label: this.$t('trip_list.vehicle_allocation') + ' ' + '(h)',
                        sortable: true
                    }
                },
                {
                    allocation_percent: {
                        label: this.$t('trip_list.allocation_percent') + ' ' + '(%)',
                        sortable: true
                    }
                },
            ],
            results: []
        }
    },
    watch: {
        items: function () {
            this.initTableItems()
        }
    },
    methods: {
        initTableItems: function () {
            this.results = []
            this.items.forEach(function(item) {
                this.results.push({
                    vehicle_id: item.vehicle_id,
                    vehicle: item.vehicle,
                    allocation_percent: item.allocation_percent.toFixed(2),
                    duration: this.getWorkTime(Math.round(item.duration / 60)),
                    vehicle_allocation_time: this.getWorkTime(item.vehicle_allocation_time)
                })
            }, this)
        },
        emitDetails: function (result) {
            // Find defect item from original items and emit request to edit
            let p = this.items.find(item => item.id === result.id)
            if (p) {
                this.$emit('edit', p)
            }
        },
    }
}
</script>

<style scoped>

</style>
