<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <contract-filter
                ref="filter"
                :user="user"
                :showOnlyMyContractsOption="true"
                :is-admin="isAdmin"
                @contractSet="setContract"
                @companySet="companySet"
            />
            <b-row class="nopads">
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="start"
                    >
                        <span class="span-title">{{ $t('orders.create_time') }} - {{
                                $t('trip_list.road_sec_from')
                            }}</span>
                        <b-form-input
                            id="start"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="end"
                    >
                        <span class="span-title">{{ $t('orders.create_time') }} - {{
                                $t('trip_list.road_sec_to')
                            }}</span>
                        <b-form-input
                            id="end"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-3">
                    <span class="span-title">{{ $t('road_sign.sign_type') }}</span>
                    <b-form-group
                        label-for="value"
                    >
                        <b-form-select
                            v-model="type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                >
                                    {{ $t('road_sign.select_sign_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getRoadSignTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-3">
                    <span class="span-title">{{ $t('road_sign.road_sign') }}</span>
                    <multiselect
                        v-model="sign"
                        :placeholder="$t('road_sign.select_sign')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="true"
                        :multiple="false"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="signs"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                            <b-row class="nopads">
                                <b-col cols="auto" class="nopads">
                                    <img class="option__image"
                                         :src="require(`@/assets/roadsign/${ props.option.icon}`)" height="25px" alt="">
                                </b-col>
                                <b-col style="padding-top: 2px;">
                                    <span class="option__title" style="font-weight: 400">{{ props.option.name }}</span>
                                </b-col>
                            </b-row>
                        </template>
                        <template slot="option"
                                  slot-scope="props">
                            <b-row class="nopads">
                                <b-col cols="auto" class="nopads">
                                    <img class="option__image"
                                         :src="require(`@/assets/roadsign/${ props.option.icon}`)" height="35px" alt="">
                                </b-col>
                                <b-col class="pt-2">
                                    <span class="option__title pt-2">{{ props.option.name }}</span>
                                </b-col>
                            </b-row>
                        </template>

                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
                <div class="col-sm-3">
                    <span class="span-title">{{ $t('observation_list.status') }}</span>
                    <b-form-group
                        class="title nopads"
                        label-for="status"
                    >
                        <b-form-select
                            id="status"
                            v-model="status"
                            :options="statusGroups"
                            size="sm"
                            class="no-margin"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-3">
                    <span class="span-title">{{ $t('road_sign.condition') }}</span>
                    <multiselect
                        v-model="condition"
                        :placeholder="$t('road_sign.select_sign')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="true"
                        :multiple="true"
                        label="name"
                        track-by="value"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="getConditions()"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                            <b-row class="nopads">
                                <b-col style="padding-top: 2px;">
                                    <span class="option__title" style="font-weight: 400">{{ props.option.name }}</span>
                                </b-col>
                            </b-row>
                        </template>
                        <template slot="option"
                                  slot-scope="props">
                            <b-row class="nopads">
                                <b-col class="pt-2">
                                    <span class="option__title pt-2">{{ props.option.name }}</span>
                                </b-col>
                            </b-row>
                        </template>

                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import ContractFilter from '../contract/ContractFilter'
import {timeUtils} from '../mixins/TimeUtils'
import {roadSignHelper, roadSignTypes} from "@/components/mixins/RoadSignMixin";

export default {
    name: 'road-sign-search',
    components: {ContractFilter},
    mixins: [roadSignHelper, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    data: function () {
        return {
            contract: null,
            company: null,
            fromDate: null,
            toDate: null,
            type: null,
            sign: null,
            signs: [],
            condition: [],
            status: null,
            statusGroups: [
                {value: null, text: this.$t('observations.search_all')},
                {value: 1, text: this.$t('observations.open')},
                {value: 2, text: this.$t('observations.closed')}
            ]
        }
    },
    watch: {
        type: function (value) {
            if (value === null) {
                this.sign = null
                this.signs = []
            } else {
                this.signs = this.getSigns(this.type)
            }
        }
    },
    methods: {
        setContract: function (contract) {
            this.contract = contract
        },
        companySet: function (company) {
            this.company = company
        },
        onSubmit: function () {
            let params = {}
            if (this.contract) {
                params.contract = this.contract
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.created_from = new Date(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.created_to = new Date(this.toDate).getTime()
            }
            if (this.type) {
                params.type = this.getTypeParam()
            }
            if (this.sign) {
                params.sign = this.sign.value
            }
            if (this.condition && this.condition.length > 0) {
                let conditionIds = []
                this.condition.forEach(cond => {
                    conditionIds.push(cond.value)
                })
                params.condition = conditionIds
            }
            if (this.company) {
                params.company = this.company
            }
            if (this.status) {
                params.status = this.status
            }
            this.$emit('search', params)
        },
        getTypeParam: function () {
            switch (this.type) {
                case roadSignTypes.WARNINGS:
                    return 'A'
                case roadSignTypes.RESTRICTIONS:
                    return 'C'
                case roadSignTypes.RULES:
                    return 'E'
                case roadSignTypes.PRIORITIES:
                    return 'B'
                case roadSignTypes.GUIDES:
                    return 'F'
                case roadSignTypes.ADDITIONAL_PLATES:
                    return 'H'
                case roadSignTypes.OTHERS:
                    return 'I'
                default:
                    return this.type
            }
        },
        onReset: function () {
            this.contract = null
            this.fromDate = null
            this.toDate = null
            this.type = null
            this.condition = []
            this.sign = null
            this.signs = []
            this.company = null
            this.$refs.filter.resetData()
        }
    }
}
</script>
