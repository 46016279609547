<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="observationGroupUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('observations.edit_group') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('observations.add_group') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <span class="span-title">{{ $t('observations.name') }}</span>
                <b-form-group
                    class="title"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="observationGroupUpdate.name"
                        type="text"
                        size="mb"
                    />
                </b-form-group>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {observationHelper} from '../../mixins/ObservationMixin'
import {restApi} from '../../mixins/RestApiMixin'

export default {
    name: 'ObservationGroupEditor',
    mixins: [observationHelper, restApi],
    props: {
        observationGroup: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            observationGroupUpdate: this.jsonToItem(this.observationGroup)
        }
    },

    methods: {
        setDefaultValues: function (group) {
            if (group.name === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return undefined
            }
            if (this.isAdmin) {
                group.company = {id: this.company}
            }
            return group
        },

        submit: function () {
            let json = this.setDefaultValues(this.observationGroupUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.observationGroupUpdate.id < 1) {
                    this.restAdd(this.observationGroupUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.observationGroupUrl, json, this.success, this.fail)
                }
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
