<template>
    <div
        class="details-container"
        @click.stop="close"
    >
        <div
            class="details dynamic_width"
            @click.stop=""
        >
            <b-row>
                <div>
                    <h3 class="p-3">{{ $t('orders.file_download_title') }}</h3>
                </div>
            </b-row>

            <b-row>
                <b-form-radio-group
                    class="pl-2 pr-2"
                    id="radio-group-2"
                    v-model="downloadMethod"
                    :options="downloadModeOptions"
                    name="method-radio-options"
                />
            </b-row>

            <b-row class="mb-3">
                <div class="col-sm-12">
                    {{ $t('orders.geojson_download_text') }}
                </div>
            </b-row>
            <b-row>
                <b-form-radio-group
                    class="pl-2 pr-2"
                    id="radio-group-1"
                    v-model="coordSystem"
                    :options="coordinateModeOptions"
                    name="coord-radio-options"
                />
            </b-row>
            <b-row>
                <div class="col-12 text-right">
                    <b-button
                        variant="secondary"
                        class="form-button"
                        @click.stop="close"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="form-button"
                        @click.stop="downloadData"
                    >
                        {{ $t('common.download') }}
                    </b-button>

                </div>
            </b-row>
        </div>
    </div>
</template>

<script>

import {coordinateSystems, downloadOptions} from "@/components/mixins/CoordinateConversionMixin";

export default {
    name: "GeometryLayerDownload",
    props: {
        coordinateSystems: {
            type: Object,
            default: null
        },
        geometryLayer: {
            type: Object,
            default: null
        },
        downloadModal: {
            type: Boolean,
            default: false
        },
        excludedFields: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            coordSystem: coordinateSystems.WGS84,
            downloadMethod: downloadOptions.GEOJSON,

        }
    },
    computed: {
        downloadModeOptions() {
            return [
                {text: this.$t('common.download_geojson'), value: downloadOptions.GEOJSON},
                {text: this.$t('trip.download_shape'), value: downloadOptions.SHAPE},
            ]
        },
        coordinateModeOptions() {
            return [
                {text: this.$t('orders.download_wg84_title'), value: coordinateSystems.WGS84},
                {text: this.$t('orders.download_tm35_title'), value: coordinateSystems.TM35_FIN},
            ]
        }
    },

    methods: {
        downloadData() {
            let modifiedGeometryLayer = this.modifyGeometry(this.geometryLayer)
            // Emit the modified geometry layer and the coordinate system
            this.$emit(this.downloadMethod, modifiedGeometryLayer, this.coordSystem);
        },

        modifyGeometry(geometryLayer) {
            // Deep clone the geometryLayer to avoid mutating the original
            let modifiedGeometryLayer = JSON.parse(JSON.stringify(geometryLayer));

            if (modifiedGeometryLayer && modifiedGeometryLayer.geometry && modifiedGeometryLayer.geometry.features && modifiedGeometryLayer.geometry.features.length > 0) {
                modifiedGeometryLayer.geometry.features.forEach(feature => {
                    if (feature.properties) {
                        // Iterate over the excludedFields array and delete the matching properties
                        this.excludedFields.forEach(field => {
                            delete feature.properties[field];
                        });
                    }
                });
            }
            return modifiedGeometryLayer
        },

        close() {
            this.$emit("close")
        },
    }
}
</script>
