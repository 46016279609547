<template>
    <div class="col-12 nopads">
        <div v-if="selectedContracts.length < 1" class="col-sm-12 nopads" style="font-style: italic">
            <span>
              {{ $t('materialstorage.no_contracts') }}
            </span>
        </div>
        <span
            v-if="selectedContracts.length !== contracts.length"
            class="span-title pt-2">
            {{ $t('materialstorage.share_to_contract') }}
        </span>
        <div class="col-sm-12 nopads">
            <!-- All open contracts -->
            <multiselect
                v-model="selectedContracts"
                class="multiselect_routa"
                :placeholder="$t('materialstorage.select_contract')"
                :deselect-label="$t('common.remove_selection')"
                :select-label="$t('common.select')"
                :close-on-select="false"
                :multiple="true"
                :disabled="!editable"
                open-direction="bottom"
                label="name"
                track-by="name"
                :searchable="true"
                :internal-search="true"
                :clear-on-select="false"
                :selected-label="$t('common.selected')"
                :options="contracts"
            >
                <template
                    slot="singleLabel"
                    slot-scope="props"
                >
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
                </template>
                <template
                    slot="option"
                    slot-scope="props"
                >
                    <div class="option__desc">
                        <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                    </div>
                </template>
                <span slot="noResult">{{ $t('common.not_found') }}</span>
                <span slot="noOptions">{{ $t('common.no_results') }}</span>
            </multiselect>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from "../mixins/TimeUtils";

export default {
    name: 'MaterialStorageContracts',
    mixins: [restApi, timeUtils],
    props: {
        value: {
            type: Array,
            default: function () {
                return []
            }
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            loading: false,
            contracts: [],
            selectedContracts: this.value ? this.value : []
        }
    },
    watch: {
        selectedContracts: function () {
            this.$emit('input', this.selectedContracts)
        }
    },
    mounted: function () {
        this.fetchContracts()
    },
    methods: {
        fetchContracts: function () {
            this.loading = true
            this.contracts = []
            let now = new Date()
            this.restFetchParams(this.contractOpenUrl,
                {
                    startDate: now.toISOString()
                },
                this.handleContracts)
        },
        handleContracts: function (results) {
            this.loading = false
            if (results.data) {
                this.contracts = results.data
            }
        },
        isSelected: function (id) {
            return this.selectedContracts.find(item => item.id === id) !== undefined
        },
        updateSelection: function (contract) {
            if (this.editable) {
                let index = this.selectedContracts.findIndex(item => item.id === contract.id)
                if (index >= 0) {
                    this.selectedContracts.splice(index, 1)
                } else {
                    this.selectedContracts.push(contract)
                }
            }
        }
    }

}
</script>
