<template>
    <div class="col-sm-12 nopads list-content">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                @submit.prevent="onSubmit"
                @reset="onReset"
            >
                <b-row class="nopads">
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('delivery_note.contract') }}</span>
                        <b-form-select
                            v-model="contract"
                            size="sm"
                            :disabled="!!loading"
                        >
                            <option :value="null">
                                {{ $t('delivery_note.form.select_contract') }}
                            </option>
                            <option
                                v-for="contract in contracts"
                                :key="contract.id"
                                :value="contract.id"
                            >
                                {{ contract.name }}
                            </option>
                        </b-form-select>
                        <b-form-group v-if="showOnlyMyContractsOption">
                            <b-form-checkbox
                                v-model="onlyUserOwnedContracts"
                                size="md"
                                type="text"
                                value=1
                                unchecked-value=0>
                                {{ $t('contracts.only_user_owned_contracts') }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                    <div class="col-sm-4"  style="margin-top: -.4em">
                        <contract-order-filter
                            v-model="order"
                            :contract-id="contract"
                            :show-executor-text="true"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('delivery_note.date') }} - {{
                                $t('trip_list.road_sec_from')
                            }}</span>
                        <datetime
                            v-model="fromDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('delivery_note.date') }} - {{
                                $t('trip_list.road_sec_to')
                            }}</span>
                        <datetime
                            v-model="toDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('delivery_note.clear') }}
                    </b-button>
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="!!loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {orderMixin} from "@/components/mixins/OrderMixin";
import ContractOrderFilter from "../../order/ContractOrderFilter";

export default {
    name: "WeighingNoteSearch",
    components: {ContractOrderFilter},
    mixins: [restApi, timeUtils, orderMixin],
    data: function () {
        return {
            fromDate: null,
            toDate: null,
            contract: null,
            order: null,
            loading: 0,
            onlyUserOwnedContracts: 0
        }
    },
    props: {
        contracts: {
            type: Array,
            default() {
                return []
            }
        },
        showOnlyMyContractsOption: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        onlyUserOwnedContracts: function() {
            this.$emit('onlyUserOwnedContractSelected', this.onlyUserOwnedContracts, "search")
        },
    },
    mounted() {
        if (!this.fromDate) {
            this.fromDate = this.getMonthStart()
        }
    },
    methods: {

        onSubmit: function () {
            let params = {}
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.time_from = new Date(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.time_to = new Date(this.toDate).getTime()
            }
            if (this.contract !== null) {
                params.contract = this.contract
            }
            if (this.order) {
                params.order = this.order.id
            }
            this.$emit('search', params)
        },

        refreshParams: function() {
            this.onSubmit()
        },

        onReset: function () {
            this.fromDate = null
            this.toDate = null
            this.contract = null
            this.onlyUserOwnedContracts = 0
            this.order = null
        },
    }
}
</script>
