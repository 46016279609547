<template>
    <div>
        <div
            v-if="loading"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {mapHelper} from '../../mixins/MapMixin'

export default {
    name: "MaterialStationMap",
    mixins: [restApi, mapHelper],
    props: {
        map: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            stations: [],
            loading: false
        }
    },
    mounted() {
        if (!this.disabled) {
            this.updateStations()
        }
    },
    beforeDestroy() {
        this.hideMarkers()
    },
    watch: {
        map() {
          this.$nextTick(function () {
            this.showMarkers()
          })
        },
        stations() {
            this.refreshMarkers()
        },
        disabled() {
            if (!this.disabled) {
                this.updateStations()
            } else {
                this.hideMarkers()
            }
        }
    },
    methods: {
        updateStations: function () {
            this.loading = true
            this.restFetchParams(this.materialStationUrl, {}, this.success, this.fail)
        },
        success: function (data) {
            this.stations = data.data
            this.loading = false
        },
        fail: function () {
            this.loading = false
        },
        refreshMarkers: function () {
            this.hideMarkers()
            this.showMarkers()
        },
        hideMarkers: function () {
            if (this.map != null) {
                this.map.removeMapMarkerByType(this.MATERIAL_STATION)
            }
        },
        showMarkers: function () {
            if (!this.disabled && this.map != null) {
                for (let index in this.stations) {
                    let station = this.stations[index]
                    this.map.showMapMarker(
                        station.id,
                        this.MATERIAL_STATION,
                        station.location.y,
                        station.location.x,
                        this.map.getMarkerStore().getStationMarkerIcon()
                    )
                }
            }
        },
        getStation: function (id) {
            return this.stations.find(station => station.id === id)
        }
    }
}
</script>
