<template>
    <div>
        <div class="calendar-header-text" :style="{width: textColWidthPercentage + '%'}">
            {{ $t('calendar.contract_title') }}
        </div>
        <div class="calendar-header-week"
             :style="{width: weekColWidth + 'px'}"
             v-for="(week, index) in weeks"
             v-bind:key="index">
            {{ week }}
        </div>
    </div>
</template>


<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
export default {
    name: 'CalendarHeader',
    mixins: [timeUtils],
    data: function () {
        return {
            start: null,
            end: null,
            weeks: []
        }
    },
    props: {
        textColWidthPercentage: {
            type: Number,
            default: 40
        },
        timeColWidth: {
            type: Number,
            default: 100  // px
        },
        visibleWeeksLimit: {
            type: Number,
            default: 20
        },
        calendarStartWeek: {
            type: Number,
            default: 70
        }
    },
    computed: {
        weekColWidth() {
            return Math.round(this.timeColWidth / 7) * 7
        }
    },
    watch: {
        calendarStartWeek: function () {
            this.initHeader()
        }
    },
    created: function () {
        this.start = new Date();
        this.end = new Date()
        this.end.setDate(this.end.getDate() + (7 * this.visibleWeeksLimit));
        this.initHeader()
    },
    methods: {
        initHeader() {
            this.weeks = []
            let startWeek = this.calendarStartWeek;
            for (let i = 0; i < this.visibleWeeksLimit; i++) {
                let weekNumber = ((startWeek+i) % 52)
                if (weekNumber === 0) {
                    weekNumber = 52
                }
                this.weeks.push(this.$t('calendar.wk_prefix') + weekNumber)
            }
        }
    }
}
</script>
<style scoped>
.calendar-header-text, .calendar-header-week {
    display: inline-block;
    background: #f3f3f7;
    border: 1px #BCBCBC solid;
    font-size: .9em;
    padding: .2em .5em;
    font-weight: bold;
}
.calendar-header-week {
    text-align: center;
    border-left: none;
}
</style>
