<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="p-2">
            <b-form
                @submit.prevent="onSubmitSearch"
                @reset="onResetSearch"
            >
                <b-row class="nopads">
                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('ins_report.from_date') }}</span>
                        <b-form-group
                            class="title no-margin"
                            label-for="from_date"
                        >
                            <b-form-input
                                id="from_date"
                                v-model="fromDate"
                                type="date"
                                class="no-margin"
                                size="sm"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('ins_report.to_date') }}</span>
                        <b-form-group
                            class="title"
                            label-for="to_date"
                        >
                            <b-form-input
                                id="to_date"
                                v-model="toDate"
                                type="date"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        class="form-button"
                        type="submit"
                        variant="primary"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        class="form-button"
                        type="reset"
                        variant="danger"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
</template>

<script>
import {inspectionHelper} from '../../mixins/InspectionMixin'
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'VehicleInspectionSearch',
    mixins: [inspectionHelper, timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            fromDate: '',
            toDate: ''
        }
    },
    mounted() {
        this.fromDate = this.timestampToDateInput(new Date(this.getMonthStart()).getTime())
    },
    methods: {
        onResetSearch: function () {
            this.fromDate = ''
            this.toDate = ''
        },
        onSubmitSearch: function () {
            var params = {}
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.from = this.localTimeToUtcTime(this.fromDate).toISOString()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.to = this.getEndOfTheDay(this.toDate).toISOString()
            }
            this.$emit('search', params)
        },
    }
}
</script>
