<template>
        <base-modal
            v-if="this.contractWeatherData !== null"
            @cancel="$emit('cancel')"

        >
            <div class="editor-form form-data">
                <h3>{{ contractWeatherData.station === undefined ? weatherStationName : contractWeatherData.station.name }}</h3>

            <weather-station-data-details
                :data="contractWeatherData"
                :name="contractWeatherData.name"
            />
                <div class="button-container pt-3 mb-0">
                    <b-button
                        variant="danger"
                        class="form-button"
                        @click.stop="$emit('cancel')"
                    >
                        <span>{{ $t('common.close') }}</span>
                    </b-button>
                </div>
                </div>
        </base-modal>
</template>

<script>
import WeatherStationDataDetails from "./WeatherStationDataDetails";
import {restApi} from "../mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import BaseModal from "@/components/BaseModal.vue";

export default {
    name: "WeatherPositionDetailsModal",
    components: {BaseModal, WeatherStationDataDetails},
    mixins: [restApi, timeUtils],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },

        weatherStation: {
            type: Object,
            default() {
                return null
            }
        },

        weatherStationId: {
            type: Number,
            default() {
                return null
            }
        },

        weatherStationName: {
          type: String,
          default() {
              return null
          }
        }
    },
    data() {
        return {
            showWeather: false,
            contractWeatherData: null,
            stationData: null,
            weatherTableSortBy: 'time',
            weatherTableSortDesc: true,
            modalTitle: '',
        }
    },
    watch: {
        searchParams() {
            if (this.contractWeatherData === null) {
                this.contractWeatherData = null
            }
            this.contractWeatherData = this.weatherStation
        },
        weatherStation() {
            if (this.contractWeatherData === null) {
                this.contractWeatherData = null
            }
            this.contractWeatherData = this.weatherStation

        },

        weatherStationId() {
            if (this.weatherStationId === null) {
                this.contractWeatherData = null
            }
            this.fetchContractData()

        }
    },


    methods: {

        async fetchContractData() {
            let contractId = {}
            if (this.weatherStationId !== null) {
                contractId = this.weatherStationId;
            }
            else return

            return this.restFetchWithoutAuthorization(`https://tie.digitraffic.fi/api/weather/v1/stations/${contractId}/data`, this.handleResponse)

        },

        handleResponse: function (response) {
            if (response && response.status === 200) {
                this.contractWeatherData = response.data
            }

        },

    }
}
</script>
