<template>
    <div class="col-sm-12 editor-form">
        <b-row>
            <div class="col-sm-6 nopads">
      <span class="span-title">
        {{ $t('common.name') }}
      </span>
                <b-form-group
                    class="title"
                    label-for="effName"
                >
                    <b-form-input
                        id="effName"
                        v-model="effName"
                        :placeholder="$t('forest_work_eff.enter_eff_name')"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
            </div>
            <div class="col-sm-6 nopads">
        <span class="span-title">
        {{ $t('forest_work_eff.unit') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="effUnit"
                >
                    <b-form-input
                        id="effUnit"
                        v-model="effUnit"
                        :placeholder="$t('forest_work_eff.enter_eff_unit')"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
            </div>
        </b-row>
        <!-- Choose report type and report -->
        <b-row class="pr-1">
            <div class="col-sm-6 nopads pl-2">
        <span class="span-title p-1">
            {{ $t('forest_work_eff.report_type') }}
        </span>
                <b-form-select
                    id="equipment_type"
                    v-model="reportType"
                    size="sm"
                    class="sm-3"
                >
                    <option :value="HARVESTER">
                        {{ $t('forest_work_report.harvester_report') }}
                    </option>
                    <option :value="FORWARDER">
                        {{ $t('forest_work_report.forwarder_report') }}
                    </option>
                </b-form-select>
            </div>
            <div class="col-sm-6 nopads pr-1 pl-1">
        <span class="span-title p-1">
            {{ $t('forest_work_eff.report_field') }}
        </span>
                <b-form-select
                    id="equipment_type"
                    v-model="reportField"
                    size="sm"
                    class="sm-3 ml-1"
                    :options="reportFields"/>
            </div>
        </b-row>

        <!-- Selected task types -->
        <span class="span-title p-1">
        {{ $t('forest_work_eff.tasks') }}
    </span>
        <div class="col-sm-12 p-2">
            <div v-if="selectedTaskTypes.length > 0">
                <div
                    v-for="item in selectedTaskTypes"
                    :key="item.id"
                    :class="{'d-inline' : true}"
                >
                <span
                    class="inspectables inspectables-selected"
                    @click="updateTaskType(item.task_type)"
                >
                  {{ item.task_type.name }} ({{ item.task_type.company.name }})
                  <strong>
                    X
                  </strong>
                </span>
                </div>
            </div>
            <div
                v-else
                class="col-sm-12 small-pad"
            >
                <span>
                  {{ $t('forest_work_eff.no_selected_task_types') }}
                </span>
            </div>
        </div>
        <!-- Task type list -->
        <span class="span-title p-1">
        {{ $t('forest_work_eff.select_task_type') }}
    </span>
        <div class="col-sm-12 inspectable-container p-2">
            <div
                v-for="item in taskTypes"
                :key="item.id"
                :class="{ hidden : isSelected(item.id), 'd-inline' : !isSelected(item.id)}"
            >
          <span
              class="inspectables"
              @click="updateTaskType(item)"
          >
            {{ item.name }} ({{ item.company.name }})
          </span>
            </div>
        </div>
        <div class="col-sm-12 button-container pb-2">
            <b-button
                variant="danger"
                class="form-button "
                :disabled="efficiency.id < 0"
                @click.stop="removeEff"
            >
                {{ $t('forest_work_eff.remove') }}
            </b-button>
            <b-button
                variant="secondary"
                class="form-button"
                @click.stop="$emit('close')"
            >
                {{ $t('forest_work_eff.close_form') }}
            </b-button>
            <b-button
                variant="success"
                class="form-button"
                :disabled="loading || !effName || !reportType || !reportField"
                @click.stop="submitEfficiency"
            >
          <span v-if="efficiency.id > 0">
            {{ $t('forest_work_eff.save_changes') }}
          </span>
                <span v-else>
            {{ $t('forest_work_eff.add') }}
          </span>
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>

</template>

<script>
import {workManagementHelper} from "../mixins/WorkManagementMixin";
import {restApi} from "../mixins/RestApiMixin";
import {EventBus} from "@/event-bus";

export default {
    name: "EfficiencyEditor",
    mixins: [workManagementHelper, restApi],
    props: {
        efficiency: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            HARVESTER: 1,
            FORWARDER: 2,
            loading: false,
            taskTypes: [],
            selectedTaskTypes: this.efficiency.task_types,
            reportType: 1,
            reportField: null,
            reportFields: [],
            effName: this.efficiency.name,
            effUnit: this.efficiency.unit
        }
    },

    mounted: function () {
        this.fetchTaskTypes()
        if (this.efficiency.report_field) {
            this.reportField = this.efficiency.report_field
        }
        if (this.efficiency.report_type) {
            this.reportType = this.efficiency.report_type
        }
        this.updateReportFields()
    },

    watch: {
        reportType: function () {
            this.updateReportFields()
        }
    },

    methods: {
        fetchTaskTypes: function () {
            this.loading = true
            this.restFetch(this.taskTypeAllUrl, this.handleTaskTypes)
        },

        handleTaskTypes: function (response) {
            this.taskTypesLoaded = true
            this.taskTypes = response.data
            this.loading = false
        },

        indexOfTaskType: function (id) {
            return this.selectedTaskTypes.findIndex(function (obj) {
                return obj.task_type.id === id
            })
        },

        isSelected: function (id) {
            var index = this.indexOfTaskType(id)
            return index >= 0
        },

        updateTaskType: function (taskType) {
            var index = this.indexOfTaskType(taskType.id)
            if (index < 0) {
                this.selectedTaskTypes.push({
                    'task_type': taskType,
                })
            } else {
                this.selectedTaskTypes.splice(index, 1)
            }
        },

        submitEfficiency: function () {
            this.efficiency.name = this.effName
            this.efficiency.unit = this.effUnit
            this.efficiency.report_type = this.reportType
            this.efficiency.report_field = this.reportField
            if (this.efficiency.id < 1) {
                this.restAdd(this.efficiencyUrl, this.efficiency, this.success, this.fail)
            } else {
                this.restUpdate(this.efficiencyUrl, this.efficiency, this.success, this.fail)
            }
        },

        success: function (data) {
            this.$emit('closeAndUpdate', data)
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        removeEff: function () {
            var url = this.efficiencyUrl + '/' + this.efficiency.id
            this.restDelete(url, this.success, this.fail)
        },

        updateReportFields: function () {
            this.reportFields = []
            switch (parseInt(this.reportType)) {
                case this.HARVESTER:
                    this.reportFields = [
                        {value: 'totalAmount', text: this.$t('forest_work_report.wood_amount')},
                        {value: 'logCount', text: this.$t('forest_work_report.log_count')}
                    ]
                    break;
                case this.FORWARDER:
                    this.reportFields = [
                        {value: 'totalAmount', text: this.$t('forest_work_report.wood_amount')}
                    ]
                    break;
            }
            // Check if current selection is still valid
            this.updateReportFieldSelection()
        },

        updateReportFieldSelection: function () {
            let field = this.reportFields.find(item => item.value === this.reportField)
            if (field) {
                this.reportField = field.value
            } else {
                this.reportField = undefined
            }
        }
    }
}
</script>

<style scoped>

</style>
