<template>
    <div class="button-container col-sm-12">
        <b-button
            variant="secondary"
            class="result-button"
            @click.stop="$emit('close')"
        >
            {{ $t('common.close') }}
        </b-button>
        <b-button
            variant="danger"
            class="result-button"
            @click.stop="showModal = true"
        >
            {{ $t('common.remove') }}
        </b-button>
        <b-button
            variant="success"
            class="result-button"
            :disabled="disabled"
            @click.stop="handleSubmit(url, item, selectedContract)"
        >
            {{ $t('common.save') }}
        </b-button>
        <b-modal
            ref="modal"
            v-model="showModal"
            id="remove-equipment-modal"
            :title="$t('equipments.remove_title')"
            :item="item"
            :ok-variant="'success'"
            hide-header-close
            centered
            @ok="$emit('removalConfirmed', url, item, selectedContract)"
            @cancel="$emit('close')"
            :cancel-title="$t('common.cancel')"
        >
            <span class="span-title">{{ item.external_system_id }}</span>
            {{ $t('equipments.remove_descr') }}
        </b-modal>
        <b-modal
            ref="replaceModal"
            id="replace-equipments-modal"
            :title="$t('equipments.replace_title')"
            :item="item"
            :ok-variant="'success'"
            hide-header-close
            centered
            @ok="$refs.replaceModal.hide(); $emit('submit', url, item, selectedContract, true)"
            @cancel="$refs.replaceModal.hide()"
            :cancel-title="$t('common.cancel')"
        >
            {{ $t('equipments.replace_descr') }}
        </b-modal>
    </div>
</template>

<script>
import {equipmentActions} from "@/components/mixins/ConversionMixin";

export default {
    name: 'VelhoEditorButtons',
    props: {
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        url: {
            type: String,
            default: null
        },
        item: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        handleSubmit(url, item, selectedContract) {
            if(item.actions.includes(equipmentActions.REPLACE) || item.linked_equipment.some(x => x.hasOwnProperty("actions") && !!x.actions.find(y => y.value === equipmentActions.REPLACE))) {
                this.$refs.replaceModal.show()
            } else {
              this.$emit('submit', url, item, selectedContract, false)
            }
        },
    }
}
</script>

<style scoped>
</style>

