<template>
    <div class="col-sm-12 p-1">
        <div class="col-sm-12 nopads">
            <div v-if="result" class="nopads p-2 pb-4" style="border: 1px #bcbcbc solid; border-radius: .5em; overflow: auto" >
                <h4 class="form-header pt-0">{{ report === 1 ? $t('contracts.external.road_safety_report') : $t('contracts.external.road_inspection_report')}}</h4>
                <b-row class="nopads">
                    <span class="item-details">{{ result.otsikko.lahettaja.jarjestelma}}</span>
                </b-row>
                <b-row class="nopads">
                    <span class="item-details">{{ result.otsikko.lahettaja.organisaatio.nimi}}</span>
                </b-row>
                <b-row class="nopads pb-3">
                    <span class="item-details">{{ result.otsikko.lahettaja.organisaatio.ytunnus}}</span>
                </b-row>
                <!-- Data -->
                <table class="table table-sm">
                    <tr>
                        <th>{{ $t('contracts.external.start_time')}}</th>
                        <th>{{ $t('contracts.external.start_pos')}}</th>
                        <th>{{ $t('contracts.external.end_pos')}}</th>
                        <th>{{ $t('contracts.external.inspector')}}</th>
                        <th>{{ $t('contracts.external.observations')}}</th>
                    </tr>
                    <tr v-for="(item, index) in result.tarkastukset" v-bind:key="index">
                        <td>{{ toLocalTime(item.tarkastus.aika) }}</td>
                        <td>{{ parseFloat(item.tarkastus.alkusijainti.y).toFixed(3) }} {{ parseFloat(item.tarkastus.alkusijainti.x).toFixed(3) }}</td>
                        <td>{{ parseFloat(item.tarkastus.loppusijainti.y).toFixed(3) }} {{ parseFloat(item.tarkastus.loppusijainti.x).toFixed(3) }}</td>
                        <td>{{ item.tarkastus.tarkastaja.etunimi }} {{ item.tarkastus.tarkastaja.sukunimi}}</td>
                        <td>{{ item.tarkastus.havainnot }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'ContractRoadSafety',
    mixins: [restApi, timeUtils],
    props: {
        result: {
            type: Object,
            default: null
        },
        report: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            loading: false
        }
    },
    methods: {
    }
}
</script>
