<template>
        <div class="nopads">
            <span v-if="showTitle" class="span-title">{{ $t('culvert.location') }}</span>
            <b-tooltip v-if="enableCopy" target="roadAddr" triggers="hover" placement="top">
                {{ $t('velho_equipment.copy_road_address') }}
            </b-tooltip>
            <span class="item-detail-text">
                {{ this.roadAddress }} {{ this.roadAddress.length > 0 ? 'm  ' : ''}} {{ this.showStreetName ? this.streetName : ''}}
                <font-awesome-icon id="roadAddr" v-if="enableCopy" icon="copy" class="ml-2 pointer text-disabled" v-on:click.stop="copyRoadAddress"/>
                {{ this.length ? ' (' + this.length + ' m)' : ''}}
            </span>
        </div>
</template>

<script>

export default {
    name: 'RoadAddessView',
    props: {
        roadNumber: {
            type: Number,
            default: null
        },
        sectionNumber: {
            type: Number,
            default: null
        },
        distance: {
            type: Number,
            default: null
        },
        streetName: {
            type: String,
            default: null
        },
        enableCopy: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showStreetName: {
            type: Boolean,
            default: true
        },
        length: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            roadAddress: ''
        }
    },
    created() {
        this.generateRoadText()
    },
    methods: {
        generateRoadText() {
            this.roadAddress = ''
            if (this.roadNumber) {
                this.roadAddress += this.roadNumber
                if (this.sectionNumber) {
                    this.roadAddress += '/' + this.sectionNumber
                }
            }
            if (this.distance) {
                if (this.roadAddress.length > 0) {
                    this.roadAddress += '/'
                }
                this.roadAddress += this.distance
            }

        },
        copyRoadAddress() {
            this.$clipboard(this.roadAddress)
        }
    }
}
</script>
