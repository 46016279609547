<template>
    <div>
  <div class="map-container">
    <map-container
      ref="mapContainer"
      :find-user="false"
      @onMapClicked="mapClicked"
      @onMapChanged="mapChanged"
      @onMarkerTap="passMarkerTap"
      @onPolylineTap="passPolylineTap"
      @onMarkerPointerEnter="onMarkerPointerEnter"
      :menuItems="menuItems"
    />
  </div>
        <div class="map-controls">
            <div class="map-controls__list col-12 col-lg-4 nopads">
    <selection-tool
        v-if="this.polygonDrawing"
        :polygonDrawing="polygonDrawing"
        @showSelectionSelector="showSelectionSelector"
        @clearPolygon="clearPolygon"
        @close="hidePolygonDrawing"
    />

                <map-selection-editor
                    v-if="selectionSelector"
                    :options="selectionOptions"
                    @close="hideSelectionSelector"
                    @onSelectionSelected="onSelectionSelected"/>

                <observation-bulk-editor
                    v-if="editMode"
                    id="bulkEditor"
                    :observations="selectedItems"
                    :user="user"
                    :is-observer="isObserver"
                    @close="closeForm"
                    @closeAndUpdate="closeAndUpdateObservations"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {vehicleHelper} from '../mixins/VehicleMixin'
import {geomTypes} from '../mixins/ObservationMixin'
import MapContainer from "./MapContainer";
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import SelectionTool from "@/components/map/SelectionTool.vue";
import {selectFromMapMixin} from "@/components/mixins/SelectFromMapMixin";
import MapSelectionEditor from "@/components/map/MapSelectionEditor.vue";
import ObservationBulkEditor from "@/components/observation/ObservationBulkEditor.vue";

export default {
    name: 'RoadReportMap',
    components: {ObservationBulkEditor, MapSelectionEditor, SelectionTool, MapContainer},
    mixins: [vehicleHelper, timeUtils, mapHelper, geomTypes, geometryMixin, selectFromMapMixin],
    props: {
        observationResults: {
            type: Array,
            default() {
                return []
            }
        },
        user: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        showSelectionEditor: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
      return {
          selectionReady: false,
          newPolygonId: -1,
          newAreaName: null,
          selectedAreaType: null,
          selectedCompany: null,
          polygonDrawing: false,
          selectedItems: [],
          selectionSelector: false,
          editMode: false,
          icon: null,
          map: null,
          showObservationMapTitle: false,
          GROUP_ID: 0,
          Z_INDEX: {
              BEHIND: 2,
              INFRONT: 5
          },

      }
    },
    mounted: function () {
        this.$nextTick(() => {
            this.map = this.$refs.mapContainer ? this.$refs.mapContainer.getMap() : undefined
            this.initView()
        });
    },
    computed: {
        menuItems() {
            if (this.showSelectionEditor) {
                return [
                    !this.polygonDrawing ? {
                        text: this.$t('map.select_assets'),
                        onClick: this.enablePolygonDrawing
                    } : {
                        text: this.$t('map.end_selection'),
                        onClick: this.showSelectionSelector
                    }
                ]
            } else {
                return []
            }
        },
        selectionOptions() {
            return [
                [this.OBSERVATION, true],
            ].filter(e => e[1]).map(e => e[0])
        }
    },

    methods: {
        enablePolygonDrawing: function () {
            this.selectionReady = false;
            this.newPolygonId = -1
            this.newAreaName = null
            this.selectedAreaType = null
            this.selectedCompany = null
            this.polygonDrawing = true
            this.map.removeAreaEventListeners()
            this.$bvToast.toast(`${this.$t('map.select_assets_info')}`, {
                title: this.$t('map.select_assets'),
                toaster: 'b-toaster-bottom-center',
                id: 'map-selection-toast',
                autoHideDelay: 10000,
                solid: true
            });
        },

        closeForm: function () {
            this.editMode = false
        },

        closeAndUpdateObservations() {
            this.closeForm()
            this.$emit('updateObservations')
        },

        selectAssets(observations){
            this.selectObservationAssets(observations)
        },

        hideSelectionSelector(){
            this.selectionSelector = false
        },


        onSelectionSelected(selection){
            this.selectedItems = []
            if(selection === this.OBSERVATION){
                this.selectObservationAssets(this.observationResults)
                this.observationDialog = this.selectedItems && this.selectedItems.length > 0
            } else {
                this.hideSelectionSelector()
                return
            }
            this.hideSelectionSelector()
            this.editMode = this.selectedItems && this.selectedItems.length > 0
        },

        selectObservationAssets(observations) {
            let polygon = this.getPolygon()
            if(observations.length > 0) {
                observations.map((observation) => {
                    if(observation.geometry && observation.geometry.point) {
                        if(this.isPointInPolygon(observation.geometry.point.y, observation.geometry.point.x, polygon)) {
                            this.selectedItems.push(observation)
                        }
                    } else if(observation.geometry && observation.geometry.line_string &&
                        observation.geometry.line_string.points) {
                        if(this.isPointInPolygon(observation.geometry.line_string.points[0][1], observation.geometry.line_string.points[0][0], polygon)) {
                            this.selectedItems.push(observation)
                        }
                    }
                })
            }
        },


        getPolygon: function () {
            let rings = this.map.getPolygonBoundaries(this.newPolygonId, this.OTHER)
            // We need only outer rings as this is used for map item selection
            if (rings.length > 0) {
                return rings[0].map(item => [item['lng'], item['lat']])
            }
            return []
        },

        showSelectionSelector() {
            if (this.getPolygons()) {
                this.selectAssets(this.observationResults,
                )
                if(this.selectedItems.length > 0) {
                    this.selectionSelector = true
                    this.selectedItems = []
                } else {
                    this.clearPolygon()
                }
            }
        },

        hidePolygonDrawing() {
            this.polygonDrawing = false
            this.clearPolygon()
        },

      mapChanged: function (map) {
          this.map = map
          this.initView()
      },

        getPolygons: function () {
            if (this.newPolygonId < 0) {
                return undefined
            }
            return this.getPolygon()
        },

        mapClicked: function (coord) {
            if (this.polygonDrawing) {
                this.addPointToPolygon(coord)
            }
        },

        addPointToPolygon: function (point) {
            // If not exist yet, create one and add to map
            if (this.newPolygonId < 0) {
                this.newPolygonId = Date.now()
                this.map.newPolygon(this.newPolygonId, point.lat, point.lng, this.OTHER)

            } else {
                this.map.addPointToPolygon(this.newPolygonId, point.lat, point.lng, this.OTHER)
            }
        },

        clearPolygon: function () {
            if (this.newPolygonId > 0) {
                this.map.removePolygon(this.newPolygonId, this.OTHER)
            }
            this.newPolygonId = -1
        },

      passMarkerTap(data) {
          this.$emit('onMarkerTap', data.id)
      },
      passPolylineTap(data) {
            this.$emit('onPolylineTap', data.id)
      },
      initView: function () {
          if (this.map) {
              this.icon = this.map.getMarkerStore().getObservationMarkerIcon()
              //Remove any previous observations
              this.map.removeMapItemsByType(this.OBSERVATION)
              //Draw the new observations
              this.drawObservations()
              //Zoom to the observations
              this.map.zoomToGroup(this.OBSERVATION)
          }
      },

      drawObservations: function () {

        for(let observation of this.observationResults) {
          if (observation.type.geometry_type === geomTypes.POINT) {
            this.map.showMapMarker(observation.id, this.OBSERVATION, observation.geometry.point.y,
                observation.geometry.point.x, this.icon, false, this.GROUP_ID)
          } else if (observation.type.geometry_type === geomTypes.LINESTRING) {
              const color = observation.closed_time ? '#bcbcbc' : '#bb330e';
              const points = observation.geometry.line_string.points.map(point => {
                  return {x: point[0], y: point[1]}
              });
              this.map.drawPolyLine(observation.id, this.OBSERVATION, points, color, false, false, 3, 1, true)
          }
        }
      },
      showObservationTitle: function (data) {
          if (!this.showObservationMapTitle) {
              this.showObservationMapTitle = true
              this.showObservationTitleLabel(data)
              setTimeout(() => {
                  this.hideObservationTitle(data)
              }, 750)
          }
      },
      hideObservationTitle: function (data) {
          // Hide info bubble
          this.map.hideMarkerLabel(data.id, data.type)
          this.showObservationMapTitle = false
      },
      showObservationTitleLabel: function (data) {
          // Show info bubble
          if (data.type === this.OBSERVATION) {
              let item = this.observationResults.find(o => o.id === data.id)
              if (item) {
                  let x, y
                  if (item.geometry.point) {
                      x = item.geometry.point.x
                      y = item.geometry.point.y
                  }
                  if (item.geometry.line_string && item.geometry.line_string.points && item.geometry.line_string.points[0]) {
                      x = item.geometry.line_string.points[0][0]
                      y = item.geometry.line_string.points[0][1]
                  }
                  if (item) {
                      this.map.addMarkerLabel(item.id, item.type.name, y, x, data.type.id)
                  }
              }
          }
      },
      onMarkerPointerEnter: function (data) {
          switch (data.type) {
              case this.OBSERVATION:
                  this.showObservationTitle(data)
                  break
          }
      },
    }
  }
</script>
