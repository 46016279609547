export const stormSewerStatuses = {
    OK: 1,
    DEFECT: 10,
    REMOVED: 20,
}

export const stormSewersMixin = {
    methods: {
        newStormDrain(coord) {
            return {
                id: null,
                material: null,
                position: coord,
                status: null
            }
        },
        convertPositionToXY(drain) {
            if (drain && drain.position && drain.position.lat) {
                return {
                    ...drain,
                    position: {
                        // ...drain.position,
                        x: drain.position.lng, // Assign longitude to x
                        y: drain.position.lat, // Assign latitude to y
                    }
                };
            }
            return drain;
        },
        getStatusOptions() {
            return [
                {value: stormSewerStatuses.OK, text: this.$t('storm_sewers.status_ok')},
                {value: stormSewerStatuses.DEFECT, text: this.$t('storm_sewers.status_defect')},
                {value: stormSewerStatuses.REMOVED, text: this.$t('storm_sewers.status_removed')},
            ]
        },
        getStatusString: function (status) {
            switch (status) {
                case stormSewerStatuses.OK:
                    return this.$t('storm_sewers.status_ok')
                case stormSewerStatuses.DEFECT:
                    return this.$t('storm_sewers.status_defect')
                case stormSewerStatuses.REMOVED:
                    return this.$t('storm_sewers.status_removed')
            }
        },
        jsonToItem: function (item) {
            return JSON.parse(JSON.stringify(item))
        }
    }
}
