<template>
    <b-row class="nopads">
        <div class="col-sm-6 nopads pl-1 pr-1">
            <span class="span-title">{{ $t('equipment_actions.action') }}</span>
            <b-form-select
                v-model="action"
                size="sm"
            >
                <option
                    v-for="item in actions"
                    :key="item.value"
                    :value="item.value"
                >
                    {{ item.name }}
                </option>
            </b-form-select>
        </div>
        <div class="col-sm-6 nopads pl-1 pr-1 text-right pt-4">
            <b-button
                variant="info"
                class="result-button"
                size="sm"
                :disabled="!action"
                @click.stop="emitAction"
            >
                {{ $t('common.add') }}
            </b-button>
        </div>
    </b-row>
</template>

<script>

export default {
    name: 'EquipmentAction',
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            ADDITION: 1,
            INSPECTION: 2,
            CLEANING: 3,
            REPAIR: 4,
            REMOVAL: 5,
            REPLACE: -1,
            actions: [],
            action: null,
            date: null
        }
    },
    watch: {
        inspection: function () {
            this.$emit('input', {action: this.action, date: this.date})
        },
        date: function () {
            this.$emit('input', {action: this.action, date: this.date})
        },
    },
    mounted() {
        if (this.value) {
            this.action = this.value.action
            this.date = this.value.date
        }
        this.actions = this.getActions()
    },
    methods: {
        getActions: function () {
            return [
                {name: this.$t('equipment_actions.select_action'), value: null},
                {name: this.$t('equipment_actions.inspection'), value: this.INSPECTION},
                {name: this.$t('equipment_actions.repair'), value: this.REPAIR},
                // {name: this.$t('equipment_actions.addition'), value: this.ADDITION},
                // {name: this.$t('equipment_actions.removal'), value: this.REMOVAL},
                {name: this.$t('equipment_actions.cleaning'), value: this.CLEANING},
                {name: this.$t('equipment_actions.replace'), value: this.REPLACE}
            ]
        },
        emitAction() {
           this.$emit('addAction', this.action)
           this.action = null
        }
    }
}
</script>
<style>
</style>
