<template>
    <div class="col-sm-12 nopads pt-3 pb-3">
        <b-row class="nopads mb-2">
            <span class="tip-light">{{ $t('contracts.external.equipment_hint')}}</span>
        </b-row>
        <b-row class="nopads">
            <b-form-checkbox-group
                v-model="selected"
                :options="equipments"
                size="sm"
                class="mb-3"
                value-field="value"
                text-field="text"
                disabled-field="notEnabled"
            />
        </b-row>
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";

export default {
    name: 'ContractEquipmentFilter',
    mixins: [mapHelper],
    data: function () {
        return {
            loading: false,
            equipments: [],
            selected: []
        }
    },
    watch: {
        selected: function () {
            this.$emit('equipmentSelected', this.selected)
        }
    },
    mounted() {
        this.equipments = [
            {text: this.$t("external_road_signs.road_signs"), value: this.EXTERNAL_TRAFFIC_SIGNS},
            {text: this.$t("infra.posts"), value: this.EXTERNAL_POSTS},
            {text: this.$t("culvert.title"), value: this.EXTERNAL_CULVERTS},
            {text: this.$t("railing.title"), value: this.EXTERNAL_RAILINGS},
            {text: this.$t("portal.title"), value: this.EXTERNAL_PORTALS}
        ]
    }
}
</script>
