<template>
    <div class="nopads pt-1">
        <hr class="nopads mt-1"/>
        <h4 class="form-header">{{ $t('equipment_actions.title') }}</h4>
        <equipment-action v-model="action" class="pb-3" @addAction="addAction"/>
        <!-- Data -->
        <table class="table table-sm">
            <tr>
                <th>{{ $t('equipment_actions.title')}}</th>
            </tr>
            <tr v-for="(item, index) in actions" v-bind:key="index" style="font-size: .9em">
                <td>{{ getActionString(item) }}</td>
            </tr>
        </table>
        <span v-if="actions.length < 1" class="item-text pl-2 pb-2">{{ $t('equipment_actions.no_actions')}}</span>
        <hr class="nopads mt-1 mb-1"/>
    </div>

</template>

<script>

import EquipmentAction from "@/components/velho/EquipmentAction";
export default {
    name: 'EquipmentActions',
    components: {EquipmentAction},
    props: {
        value: {
           type: Array,
           default() {
               return []
           }
       }
    },
    data: function () {
        return {
            action: {action: null, date: null},
            actions: [],
            actionsVisible: false
        }
    },
    watch: {
        value: function (val) {
            if (val) {
                this.actions = val
            } else {
                this.actions = []
            }
        }
    },
    mounted() {
        this.actions = this.value
    },
    methods: {
        addAction(action) {
            this.actions.push(action)
            this.$emit(('input'), this.actions)
        },
        getActionString(code) {
            switch (code) {
                case 2: return this.$t('equipment_actions.inspection');
                case 4: return this.$t('equipment_actions.repair');
                //case 1: return this.$t('equipment_actions.addition');
                //case 5: return this.$t('equipment_actions.removal');
                case 3: return this.$t('equipment_actions.cleaning');
                case -1: return this.$t('equipment_actions.replace');
            }
            return '-'
        }
    }
}
</script>
<style>
</style>
