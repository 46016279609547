<template>
    <div class="col-12 nopads">
        <transition name="fade">
            <portal-editor
                v-if="selectedItem"
                style="pointer-events: all"
                :item="selectedItem"
                :selected-contract="selectedContract"
                @close="closeEditor"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from '@/components/mixins/MapMixin'
import {restApi} from '@/components/mixins/RestApiMixin'
import PortalEditor from "@/components/velho/PortalEditor";
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import {condition} from "@/components/mixins/RoadSignMixin";

export default {
    name: 'PortalMap',
    mixins: [mapHelper, restApi, geometryMixin],
    components: {PortalEditor},
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContract: {
            type: Object,
            default: null
        },
        selectedConditions: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data: function () {
        return {
            loading: 0,
            results: [],
            bbox: null,
            visibilityZoomLevel: 15,
            selectedItem: null
        }
    },

    computed: {
        visiblePortals() {
            return this.selectedConditions.length === 0 ? this.results : this.results.filter((value) => this.selectedConditions.includes(value.condition) || (this.selectedConditions.includes(condition.UNSET) && value.condition === undefined))
        }
    },

    watch: {
      map() {
        this.$nextTick(function () {
          this.initView()
        })
      },
      boundingBox() {
         this.updateView()
      }
    },
    beforeDestroy() {
        this.hideItems()
    },
    mounted() {
        this.initView()
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideItems()
            } else if (force || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.005
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchItems()
            }
        },

        hideItems: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.EXTERNAL_PORTALS)
            }
            this.posts = []
        },

        closeAndUpdate() {
            this.closeEditor()
            this.fetchItems()
        },

        fetchItems: function () {
            this.loading++
            this.results = []
            let params = {}
            if(this.bbox && this.bbox.length > 0) {
                params.bbox = this.bbox
                this.restFetchParams(this.extPortalUrl, params, this.handleSearch, () => {
                    this.loading--
                })
            }
        },

        handleSearch: function (response) {
            this.hideItems()
            if (response && response.data && response.data.length > 0) {
                this.results = response.data
                this.drawItems()
            }
            this.loading--
        },

        drawItems: function () {
            this.visiblePortals.forEach(function (item, index) {
                let icon = this.map.getMarkerStore().getPortalIcon()
                this.map.showMapMarker(index, this.EXTERNAL_PORTALS, item.position.y, item.position.x,
                    icon, false, 5, false, item.external_system_id)
            }, this)
        },

        editPortal(id) {
            this.fetchItem(id)
        },

        fetchItem: function (id) {
            this.loading++
            this.selectedItem = null
            this.restFetch(this.extPortalUrl + '/' + id, this.handleItem)
        },

        handleItem: function (response) {
            if (response && response.data) {
                // Set Velho oid as contract id
                if (this.selectedContractId) {
                    response.data.contract = {
                        id: this.selectedContractId
                    }
                }
                this.selectedItem = response.data
            }
            this.loading--
        },

        closeEditor() {
            this.selectedItem = null
        }

    }
}
</script>
