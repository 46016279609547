
export const boomBarrierBollardHelper = {
    methods: {
        getBoomBarrierBollardTypes: function () {
            return [
                {name: this.$t('boom_barrier_bollard.types.traffic_booms'), value: boomBarrierBollardTypes.TRAFFIC_BOOMS},
                {name: this.$t('boom_barrier_bollard.types.barrier_booms'), value: boomBarrierBollardTypes.BARRIER_BOOMS},
                {name: this.$t('boom_barrier_bollard.types.barrier_posts'), value: boomBarrierBollardTypes.BARRIER_POSTS},
                {name: this.$t('boom_barrier_bollard.types.bollards'), value: boomBarrierBollardTypes.BOLLARDS},
                {name: this.$t('boom_barrier_bollard.types.barrier_cone'), value: boomBarrierBollardTypes.BARRIER_CONE},
                {name: this.$t('boom_barrier_bollard.types.barrier_fence'), value: boomBarrierBollardTypes.BARRIER_FENCE}
            ]
        },
        getBoomBarrierBollardRedefinedTypes: function () {
            return [
                {name: this.$t('boom_barrier_bollard.redefined_type.railway_level_crossing_boom'), value: boomBarrierBollardRedefinedTypes.RAILWAY_LEVEL_CROSSING_BOOM},
                {name: this.$t('boom_barrier_bollard.redefined_type.boom_on_the_ferry_shore'), value: boomBarrierBollardRedefinedTypes.BOOM_ON_THE_FERRY_SHORE},
                {name: this.$t('boom_barrier_bollard.redefined_type.tunnel_boom'), value: boomBarrierBollardRedefinedTypes.TUNNEL_BOOM},
                {name: this.$t('boom_barrier_bollard.redefined_type.special_transport_route'), value: boomBarrierBollardRedefinedTypes.SPECIAL_TRANSPORT_ROUTE},
                {name: this.$t('boom_barrier_bollard.redefined_type.bridge_boom'), value: boomBarrierBollardRedefinedTypes.BRIDGE_BOOM},
                {name: this.$t('boom_barrier_bollard.redefined_type.border_crossing_point'), value: boomBarrierBollardRedefinedTypes.BORDER_CROSSING_POINT},
                {name: this.$t('boom_barrier_bollard.redefined_type.other_boom'), value: boomBarrierBollardRedefinedTypes.OTHER_BOOM},
                {name: this.$t('boom_barrier_bollard.redefined_type.rigid_barrier_post'), value: boomBarrierBollardRedefinedTypes.RIGID_BARRIER_POST},
                {name: this.$t('boom_barrier_bollard.redefined_type.barrier_post_that_can_be_driven_over'), value: boomBarrierBollardRedefinedTypes.BARRIER_POST_THAT_CAN_BE_DRIVEN_OVER},
                {name: this.$t('boom_barrier_bollard.redefined_type.mechanically_pressed_down_barrier_post'), value: boomBarrierBollardRedefinedTypes.MECHANICALLY_PRESSED_DOWN_BARRIER_POST},
                {name: this.$t('boom_barrier_bollard.redefined_type.service_hatch_boom'), value: boomBarrierBollardRedefinedTypes.SERVICE_HATCH_BOOM},
            ]
        },
        getBoomBarrierBollardTechniques: function () {
            return [
                {name: this.$t('boom_barrier_bollard.technique.electronic_remote_control'), value: boomBarrierBollardTechniqueTypes.ELECTRONIC_REMOTE_CONTROL},
                {name: this.$t('boom_barrier_bollard.technique.electronic_local_controllable'), value: boomBarrierBollardTechniqueTypes.ELECTRONIC_LOCAL_CONTROLLABLE},
                {name: this.$t('boom_barrier_bollard.technique.hand_operated'), value: boomBarrierBollardTechniqueTypes.HAND_OPERATED},
            ]
        },
        getBoomBarrierBollardControlTypes: function () {
            return [
                {name: this.$t('boom_barrier_bollard.control_type.lockless'), value: boomBarrierBollardControlTypes.LOCKLESS},
                {name: this.$t('boom_barrier_bollard.control_type.lock_is_opened_with_a_key'), value: boomBarrierBollardControlTypes.LOCK_IS_OPENED_WITH_A_KEY},
                {name: this.$t('boom_barrier_bollard.control_type.open_with_a_code'), value: boomBarrierBollardControlTypes.OPEN_WITH_A_CODE},
                {name: this.$t('boom_barrier_bollard.control_type.remote_controlled_opening'), value: boomBarrierBollardControlTypes.REMOTE_CONTROLLED_OPENING}
            ]
        },
        getRailwayLevelCrossingBoomAdditionalTypes: function () {
            return [
                {name: this.$t('boom_barrier_bollard.railway_level_crossing_boom_additional_type.half_boom'), value: boomBarrierBollardRailwayLevelCrossingBoomAdditionalTypes.HALF_BOOM},
                {name: this.$t('boom_barrier_bollard.railway_level_crossing_boom_additional_type.full_boom'), value: boomBarrierBollardRailwayLevelCrossingBoomAdditionalTypes.FULL_BOOM},
            ]
        },
    }
}

export const boomBarrierBollardTypes = {
    TRAFFIC_BOOMS: 1,
    BARRIER_BOOMS: 2,
    BARRIER_POSTS: 3,
    BOLLARDS: 4,
    BARRIER_CONE: 5,
    BARRIER_FENCE: 6
}

export const boomBarrierBollardRedefinedTypes = {
    RAILWAY_LEVEL_CROSSING_BOOM: 1,
    BOOM_ON_THE_FERRY_SHORE: 2,
    TUNNEL_BOOM: 3,
    SPECIAL_TRANSPORT_ROUTE: 4,
    BRIDGE_BOOM: 5,
    BORDER_CROSSING_POINT: 6,
    OTHER_BOOM: 7,
    RIGID_BARRIER_POST: 8,
    BARRIER_POST_THAT_CAN_BE_DRIVEN_OVER: 9,
    MECHANICALLY_PRESSED_DOWN_BARRIER_POST: 10,
    SERVICE_HATCH_BOOM: 11
}

export const boomBarrierBollardTechniqueTypes = {
    ELECTRONIC_REMOTE_CONTROL: 1,
    ELECTRONIC_LOCAL_CONTROLLABLE: 2,
    HAND_OPERATED: 3,
}

export const boomBarrierBollardControlTypes = {
    LOCKLESS: 1,
    LOCK_IS_OPENED_WITH_A_KEY: 2,
    OPEN_WITH_A_CODE: 3,
    REMOTE_CONTROLLED_OPENING: 4
}

export const boomBarrierBollardRailwayLevelCrossingBoomAdditionalTypes = {
    HALF_BOOM: 1,
    FULL_BOOM: 2,
}
