<template>
    <table class="table table-sm table-fixed">
        <tbody>
        <tr>
            <td class="item-detail-title">
                {{ $t('ins_report_details.owner') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.company.name">{{ vehicle.company.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_list.make') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.make">{{ vehicle.make }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_editor.model_year') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicleInfo && vehicleInfo.model_year">{{ vehicleInfo.model_year }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_list.model') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicleInfo && vehicleInfo.vehicle_model">{{ vehicleInfo.vehicle_model }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_list.type') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.vehicle_type">{{ getVehicleTypeName(vehicle.vehicle_type) }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_editor.emission_standard') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicleInfo && vehicleInfo.emission_standard">{{ getEmissionStandardName(vehicleInfo.emission_standard) }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_editor.emission_standard_other') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicleInfo &&vehicleInfo.emission_standard_extra_info">{{ vehicleInfo.emission_standard_extra_info }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('ins_report.list_label.license_plate') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.license_plate">{{ vehicle.license_plate }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {vehicleHelper} from '../mixins/VehicleMixin'
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: 'TargetInfoVehicles',
    mixins: [vehicleHelper, restApi],
    props: {
        vehicle: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            vehicleInfo: null,
        }
    },
    mounted() {
        this.fetchVehicleInformation(this.vehicle.id)
    },
    methods: {
        fetchVehicleInformation: function (id) {
            this.loading = true
            this.restFetch(this.vehicleUrl + '?id=' + id, this.handleResponse, this.handleFail)
        },

        handleResponse: function (response) {
            this.loading = false
            this.vehicleInfo = response.data[0]
        },
        handleFail: function () {
            this.loading = false
            this.vehicleInfo = null
        },

    },

}
</script>
