<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 nopads">
        <transition name="fade">
            <div class="list-content result-table">
                <b-table
                    show-empty
                    small
                    striped
                    style="cursor:pointer;"
                    @row-clicked="rowClicked"
                    :items="cables"
                    :fields="fields"
                >
                    <template v-slot:cell(contract)="row">
                        {{ row.value.name }}
                    </template>
                    <template v-slot:cell(station)="row">
                        {{ row.value.location_name }}
                    </template>
                    <template v-slot:cell(purpose)="row">
                        {{ getPurposeString(row.value) }}
                    </template>
                    <template v-slot:cell(type)="row">
                        {{ getTypeString(row.value) }}
                    </template>
                    <template v-slot:cell(status)="row">
                        {{ getStatusString(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>

import {timeUtils} from "@/components/mixins/TimeUtils";
import {cableHelper} from "@/components/mixins/CableMixin";
import {streetLightHelper} from "@/components/mixins/StreetLightMixin";

export default {
    name: 'CableList',
    mixins: [timeUtils, cableHelper, streetLightHelper],
    props: {
        cables: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'id',
            sortDesc: false
        }
    },
    computed: {
        fields() {
            return [
                {key: 'id', label: this.$t('common.id'), sortable: true},
                {key: 'purpose', label: this.$t('cables.purpose'), sortable: true},
                {key: 'type', label: this.$t('cables.type'), sortable: true},
                {key: 'station', label: this.$t('street_lights.station'), sortable: true},
                {key: 'status', label: this.$t('street_lights.status'), sortable: true},
            ]
        }
    },
    methods: {
        rowClicked: function (details) {
            this.$emit('rowClicked', details)
        }
    }
}
</script>
