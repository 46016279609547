<template>
    <div>
        <base-modal v-if="!geometry" @cancel="$emit('close')">
            <div
                class="col-12"
            >
                <h3>{{ $t('observations.add_new') }}</h3>
                <span v-if="coordinates && coordinates.length > 1" class="hint-text">
                    {{ $t('observations.line_only_tip') }}
                </span>
            </div>
            <div class="col-sm-12 nopads">
                <b-form class="col-sm-12 editor-form">
                    <div class="col-sm-12 form-data">
                        <div v-if="!observation">
                            <span class="span-title">{{ $t('observations.observation_group') }}</span>
                            <b-form-select
                                id="group"
                                v-model="group"
                                size="mb"
                                class="mb-3"
                            >
                                <template slot="first">
                                    <option
                                        :value="null"
                                    >
                                        {{ $t('observations.select_group') }}
                                    </option>
                                </template>
                                <option
                                    v-for="item in groups"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    {{ item.name }}
                                </option>
                            </b-form-select>

                            <span class="span-title">{{ $t('observation_filter.observation_type') }}</span>
                            <multiselect
                                v-model="type"
                                :options="types"
                                :close-on-select="true"
                                label="name"
                                track-by="id"
                                :searchable="true"
                                :internal-search="true"
                                :selected-label="$t('common.selected')"
                                :select-label="$t('common.select')"
                                :deselect-label="$t('common.remove_selection')"
                                :placeholder="$t('observations.select_observation_type')"
                                :disabled="!types"
                            >
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                          </span>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                >
                                    <div class="option__desc">
                                        <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                                    </div>
                                </template>
                                <span slot="noResult">{{ $t('common.not_found') }}</span>
                                <span slot="noOptions">{{ $t('common.no_results') }}</span>
                            </multiselect>
                        </div>

                        <div class="col-sm-12 button-container">
                            <b-button
                                variant="danger"
                                class="routa-button"
                                @click.stop="$emit('close')"
                            >
                                <i class="fa fa-times"/>
                                <span class="button-text">
                                    {{ $t('common.cancel') }}
                                </span>
                            </b-button>
                            <b-button
                                variant="success"
                                class="routa-button"
                                :disabled="loading||!group||!type"
                                @click.stop="submit"
                            >
                                <i class="fa fa-save"/>
                                <span class="button-text">{{ $t('common.next') }}</span>
                            </b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </base-modal>
        <base-modal v-if="observation && !skipPhotos" @cancel="$emit('close')">
            <div class="col-12">
                <h3>{{ $t('observations.add_photo') }}</h3>
            </div>
            <div class="col-sm-12">
                <b-form class="col-sm-12 editor-form">
                    <div class="col-sm-12 form-data">
                        <div v-if="observation">
                            <!-- Observation photo -->
                            <div
                                v-for="(path, counter) in photos"
                                :key="counter"
                                class="col-sm-12 nopads"
                            >
                                <b-form-group
                                    class="title"
                                    label-for="counter"
                                    v-if="counter === 0 || isAttachmentSet(photos, counter-1)"
                                >
                                    <b-form-file
                                        v-model="photos[counter]"
                                        accept="image/jpeg, image/jpg, image/png"
                                        :placeholder="$t('equipment.photo_placeholder')"
                                    />
                                </b-form-group>
                            </div>
                        </div>

                        <div class="col-sm-12 button-container">
                            <b-button
                                variant="primary"
                                class="routa-button"
                                @click.stop="$emit('closeAndUpdate', observation)"
                            >
                                <i class="fa fa-times"/>
                                <span class="button-text">
                                    {{ $t('common.skip') }}
                                </span>
                            </b-button>
                            <b-button
                                variant="success"
                                class="routa-button"
                                :disabled="loading||!group||!type"
                                @click.stop="submit"
                            >
                                <i class="fa fa-save"/>
                                <span class="button-text">{{ $t('common.save') }}</span>
                            </b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </base-modal>
        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {attachmentHelper} from '../mixins/AttachmentMixin'

import BaseModal from '../BaseModal'

export default {
    name: 'ObservationWizard',
    components: {BaseModal},
    mixins: [restApi, attachmentHelper],
    props: {
        coordinates: {
            type: Array,
            default() {
                return []
            }
        },
        observationItem: {
            type: Object,
            default: null
        },
        skipPhotos: {
            type: Boolean,
            default() {
                return false
            }
        },
        contract: {
            type: Number,
            default: null
        },
        contractIds: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false,
            groups: [],
            group: null,
            type: null,
            types: [],
            photos: new Array(5),
            observation: null,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            variant: 'danger',
            defineGeometryLine: false,
            geometry: null
        }
    },
    watch: {
        group(val) {
            this.types = this.getObsTypesByGroup(val)
        },
        observationItem() {
            this.observation = this.observationItem
        }
    },
    mounted: function () {
        if (this.observationItem) {
            this.observation = this.observationItem
            this.group = this.observationItem.type.observation_group
            this.type = this.observationItem.type
        }
        this.fetchObsGroups()
        this.fetchAllObsTypes()
    },
    methods: {
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        fetchObsGroups: function () {
            this.group = null
            this.groups = []
            this.loading = true
            this.restFetch(this.observationGroupUrl, this.handleGroupResponse)
        },
        handleGroupResponse: function (response) {
            this.groups = response.data
            this.loading = false
        },
        fetchAllObsTypes: function () {
            this.type = null
            this.allTypes = []
            this.loading = true
            this.restFetch(this.observationTypeUrl, this.handleAllTypeResponse)
        },
        getObsTypesByGroup(group) {
            let filteredTypes = []
            if(this.allTypes && this.allTypes.length > 0) {
                if(group) {
                    this.allTypes.forEach(type => {
                        if(type.observation_group.id === group) {
                            filteredTypes.push(type)
                        }
                    })
                } else {
                    filteredTypes = this.allTypes
                }
            }
            return filteredTypes
        },
        handleAllTypeResponse: function (response) {
            if (this.coordinates.length === 1) {
                // Point
                this.allTypes = response.data
            } else {
                this.allTypes = response.data.filter(item => item.geometry_type !== 1)  // Line
            }
            this.types = this.allTypes
            this.loading = false
        },
        submit: function () {
            if (!this.observation) {
                if (this.type.geometry_type === 1 || (this.coordinates && this.coordinates.length > 1)) {
                    if(this.coordinates.length === 1) {
                        this.geometry = {'point': {'lat': this.coordinates[0].lat, 'lon': this.coordinates[0].lng}}
                    } else {
                        this.geometry = {
                            'line_string': {
                                'points': this.coordinates.map(item => { return [item.lng, item.lat] })
                            }
                        }
                    }
                    this.defineGeometryLine = false
                    this.submitObservation()
                } else {
                    // line without given coordinates
                    if (this.line) {
                        this.defineGeometryLine = false
                        this.submitObservation()
                    } else {
                        // ask for line
                        this.defineGeometryLine = true
                        this.geometry = {'line_string': {'points': [[this.coordinates[0].lng, this.coordinates[0].lat]]}}
                        this.$emit('lineRequest', this.getObservation())
                    }
                }
            } else {
                this.uploadPhoto()
            }
        },
        getObservation: function() {
            let params = {}
            if (this.type) {
                params.type = {'id': this.type.id}
            }
            params.geometry = this.geometry
            params.bearing = 0
            params.road_direction = 1
            params.road_lane = 1
            params.created_time = new Date().toISOString()
            if(this.contractIds && this.contractIds.length === 1) {
                params.contract = {id: this.contractIds[0]}
            } else if(this.contract) {
                params.contract = {id: this.contract}
            } else {
                params.contract = null
            }
            return params
        },
        submitObservation: function () {
            this.restAdd(this.observationUrl, this.getObservation(), this.success, this.fail)
        },
        success: function (response) {
            this.loading = false
            this.observation = response
            if (this.skipPhotos) {
                this.$emit('closeAndUpdate', this.observation)
            }
        },
        fail: function () {
            this.loading = false
            this.$emit('close')
            this.showAlert(this.$t('common.alert_add_failed'))
        },
        uploadPhoto: function () {
            var validPhotos = []
            for (let i = 0; i < this.photos.length; i++) {
                if (this.photos[i] !== undefined && this.photos[i] !== null) {
                    validPhotos.push(this.photos[i])
                }
            }
            if (validPhotos.length > 0) {
                this.loading = true
                let formData = new FormData()
                formData.append('observation_id', this.observation.id)
                validPhotos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                this.restPostWithFile(this.observationPhotoUrl, {}, formData, this.photoSuccess, this.photoFail
                )
            } else {
                this.showAlert(this.$t('observations.select_photo'))
            }
        },
        photoSuccess: function () {
            this.loading = false
            this.$emit('closeAndUpdate', this.observation)
        },
        photoFail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_update_failed'))
        }
    }
}
</script>
<style>
@import "../../styles/modal.css";
</style>
