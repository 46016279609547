<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <span class="vehicle-list-title" @click.stop="toggleList">
              Velho
            </span>
            <div class="vehicle-list-caret" :class="{ caret_open: listOpen }" @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>
            <div
                class="col-sm-12 nopads map-accordion-content"
                v-bind:class="{ 'map-accordion-open': listOpen }"
            >
                <div v-bind:class="{ 'm-2': listOpen }">
                    <div
                        class="map-select-item pt-0"
                    >
                        <b-row v-bind:class="{ 'multiselect-expand': multiselectOpen }">

                        <multiselect
                            v-model="condition"
                            :placeholder="$t('road_sign.select_condition')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="true"
                            :multiple="true"
                            :custom-label="item => getConditionByType(item.value)"
                            label="value"
                            track-by="value"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="conditions"
                            :open-direction="'below'"
                            :max-height="300"
                            @open="multiselectOpen = true"
                            @close="multiselectOpen = false"
                        >
                            <span slot="noResult"> {{ $t('common.not_found') }} </span>
                            <span slot="noOptions"> {{ $t('common.no_results') }} </span>
                        </multiselect>
                        </b-row>

                        <div class="row nopads">
                            <div
                                v-for="option in filters"
                                :key="option.value"
                                class="col-sm-6 nopads pl-1">
                                <b-form-checkbox
                                    v-model="selectedTypes"
                                    :value="option.value"
                                    class="text-light"
                                >
                                    {{ option.text }}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapHelper} from "../mixins/MapMixin";
import {restApi} from '../mixins/RestApiMixin'
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import {condition} from "@/components/mixins/RoadSignMixin";

export default {
    name: "VelhoMapFilter",
    mixins: [restApi, mapHelper, velhoHelper],
    data() {
        return {
            isOpen: true,
            listOpen: false,
            allSelected: false,
            multiselectOpen: false,
            selectedTypes: [],
            selectedFilters: [],
            selectedLocalTypes: [],
            typeEntries: [],
            condition: [],
            conditions: [
                    {value: condition.POOR},
                    {value: condition.MEDIOCRE},
                    {value: condition.SATISFACTORY},
                    {value: condition.GOOD},
                    {value: condition.EXCELLENT},
                    {value: condition.UNSET}
                ]
        };
    },
    watch: {
        selectedTypes(val) {
            this.$emit('typesSelected', val)
        },
        selectedLocalTypes(val) {
            this.$emit('localTypesSelected', val)
        },
        selectedConditions(val) {
            this.$emit('selectedConditions', val)
        }

    },
    mounted() {
        this.initSelected()
    },
    computed: {
        selectedConditions() {
            return this.condition
                .map(condition => condition.value);
        },
        filters() {
            return [
                {
                    text: this.$t("external_road_signs.road_signs"),
                    value: this.EXTERNAL_TRAFFIC_SIGNS,
                    selected: false
                },
                {
                    text: this.$t("infra.posts"),
                    value: this.EXTERNAL_POSTS,
                    selected: false
                },
                {
                    text: this.$t("culvert.title"),
                    value: this.EXTERNAL_CULVERTS,
                    selected: false
                },
                {
                    text: this.$t("railing.title"),
                    value: this.EXTERNAL_RAILINGS,
                    selected: false
                },
                {
                    text: this.$t("portal.title"),
                    value: this.EXTERNAL_PORTALS,
                    selected: false
                },
                {
                    text: this.$t("drain.title"),
                    value: this.EXTERNAL_DRAINS,
                    selected: false
                },
                {
                    text: this.$t("fence.title"),
                    value: this.EXTERNAL_FENCES,
                    selected: false
                },
                {
                    text: this.$t("stair.title"),
                    value: this.EXTERNAL_STAIRS,
                    selected: false
                },
                {
                    text: this.$t("gate.title"),
                    value: this.EXTERNAL_GATES,
                    selected: false
                },
                {
                    text: this.$t("edge_support.title"),
                    value: this.EXTERNAL_EDGE_SUPPORTS,
                    selected: false
                },
                {
                    text: this.$t("edge_pile.title"),
                    value: this.EXTERNAL_EDGE_PILES,
                    selected: false
                },
                {
                    text: this.$t("roadside_furniture.title"),
                    value: this.EXTERNAL_ROADSIDE_FURNITURE,
                    selected: false
                },
                {
                    text: this.$t("roadside_advertisement.title"),
                    value: this.EXTERNAL_ROADSIDE_ADVERTISEMENTS,
                    selected: false
                },
                {
                    text: this.$t("boom_barrier_bollard.title"),
                    value: this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS,
                    selected: false
                },
                {
                    text: this.$t("pipe_wire_cable.title"),
                    value: this.EXTERNAL_PIPES_WIRES_AND_CABLES,
                    selected: false
                },
                {
                    text: this.$t("lighting.title"),
                    value: this.EXTERNAL_LIGHTING,
                    selected: false
                }
            ]
        }
    },
    methods: {
        select(item) {
            item.selected = !item.selected
        },
        selectTimeFilter(val) {
            this.selectContracts(null)
            this.selectedTimeFilter = val
        },
        isActiveTimeFilter(val) {
            return val === this.selectedTimeFilter
        },
        toggleList: function () {
            this.listOpen = !this.listOpen
        },
        initSelected() {
            this.enableInitTypes()
        },
        toggleAll(checked) {
            this.selectedTypes = []
            if (checked) {
                this.filters.forEach(item => {
                    this.selectedTypes.push(item.value)
                })
            }
        },
        enableInitTypes() {
            this.selectedTypes = []
            this.filters.forEach(item => {
                if(item.selected) {
                    this.selectedTypes.push(item.value)
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.multiselect-expand {
    background: #FAA633;
    height: 300px;
}
</style>
