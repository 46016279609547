<template>
    <div v-if="contractWeatherData.length > 0">
        <b-row>
            <span class="section-title">{{ $t('weather_station.data_title') }} ({{ contractWeatherData.length }})</span>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : showWeather}"
                    @click.stop="showWeather = !showWeather"
                />
            </b-col>
        </b-row>

        <div v-if="showWeather">
            <b-table
                small
                :items="weatherTableData"
                :fields="weatherTableFields"
                :sort-by.sync="weatherTableSortBy"
                :sort-desc.sync="weatherTableSortDesc"
                @row-clicked="weatherTableRowClicked"
                class="pointer"
            >
                <template v-slot:cell(time)="row">
                    {{ row.item.localTime }}
                </template>
                <template v-slot:cell(airTemperature)="row">
                    {{ row.value.value }}{{ row.value.unit }}
                </template>
                <template v-slot:cell(stationName)="row">
                    {{ row.value }}
                </template>
            </b-table>
        </div>

        <weather-position-details-modal
            v-model="stationData"
            @cancel="stationData = null"
            :weather-station="stationData"
        />
        <hr class="report-divider nopads"/>

    </div>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import WeatherPositionDetailsModal from "@/components/weather/WeatherPositionDetailsModal.vue";

export default {
    name: "WeatherTrackingPositionDetails",
    components: {WeatherPositionDetailsModal},
    mixins: [restApi, timeUtils],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
    },
    data() {
        return {
            showWeather: false,
            contractWeatherData: [],
            stationData: null,
            weatherTableSortBy: 'time',
            weatherTableSortDesc: true,
            modalTitle: '',
        }
    },
    watch: {
        searchParams() {
            this.fetchContractData()
        },
    },
    mounted() {
        this.fetchContractData()
    },
    computed: {
        weatherTableFields() {
            return [
                {key: 'time', label: this.$t('weather_station.updated_time'), sortable: true},
                {key: 'airTemperature', label: this.$t('weather_station.sensor_data.air_temperature'), sortable: true, sortKey: 'value'},
                {key: 'stationName', label: this.$t('weather_station.name'), sortable: true},
            ]
        },
        weatherTableData() {
            return this.contractWeatherData.map((d, dataIndex) => (
                {
                    dataIndex,
                    time: new Date(d.data_updated_time).getTime(),
                    airTemperature: d.sensor_values.find(v => v.name === "ILMA"),
                    stationName: d.station.name,
                    localTime: this.toLocalTime(d.data_updated_time)
                }
            ))
        },
    },
    methods: {
        weatherTableRowClicked({dataIndex: index}) {
            this.stationData = this.contractWeatherData[index]
            this.modalTitle = this.contractWeatherData[index].station.name
        },
        async fetchContractData() {
            let { contract, ...params } = this.searchParams;
            if (params.dateFrom != null) {
                params.dateFrom = new Date(params.dateFrom);
            }
            if (params.dateTo != null) {
                params.dateTo = new Date(params.dateTo);
            }
            const { data } = await this.fetchContractWeatherData(contract, params);
            this.contractWeatherData = data;
        }
    }
}
</script>
