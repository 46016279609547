<template>
    <div class="col-sm-12">
        <h3>{{ $t('ins_wizard.select_inspection') }}</h3>
        <transition name="fade">
            <!-- Show inspection templates from desired type  -->
            <div v-if="inspections && inspections.length > 0" class="col-12 nopads">
                <div v-for="item in inspections"
                     v-bind:key="item.id"
                     class="inspection-selector"
                     @click.stop="$emit('onInspectionSelected', item)">
                    <span class="title inspection-wizard-selector-text">{{ item.name }}</span>
                </div>
            </div>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'

export default {
    name: 'InspectionSelector',
    mixins: [restApi],
    props: {
        inspectionType: Number,
        vehicleType: Number
    },
    data: function () {
        return {
            inspections: [],
            loading: false
        }
    },
    mounted() {
        this.fetchInspectionTemplates()
    },
    methods: {
        fetchInspectionTemplates: function () {
            this.inspections = []
            this.loading = true
            this.restFetchParams(this.inspectionTemplateUrl, {
                vehicle_type: this.vehicleType,
                type: this.inspectionType
            }, this.handleResults)
        },
        handleResults: function (response) {
            this.loading = false
            if (response != null) {
                this.inspections = response.data
            }
        }
    }
}
</script>
editInspectable
