export const invoiceStates = {
    NOT_INVOICED: 1,
    READY_FOR_INVOICING: 2,
    APPROVED_FOR_INVOICING: 3,
    REJECTED: 4,
    SUBMITTED_FOR_INVOICING: 10,
    INVOICED: 20,
    NO_INVOICING: 30
}

export const invoiceStateHelper = {
    methods: {
        getInvoiceStates: function () {
            return [
                {value: invoiceStates.NOT_INVOICED, text: this.getStatesString(invoiceStates.NOT_INVOICED)},
                {value: invoiceStates.READY_FOR_INVOICING, text: this.getStatesString(invoiceStates.READY_FOR_INVOICING), canBeRestricted: false},
                {value: invoiceStates.APPROVED_FOR_INVOICING, text: this.getStatesString(invoiceStates.APPROVED_FOR_INVOICING), canBeRestricted: true},
                {value: invoiceStates.REJECTED, text: this.getStatesString(invoiceStates.REJECTED), canBeRestricted: true},
                {value: invoiceStates.SUBMITTED_FOR_INVOICING, text: this.getStatesString(invoiceStates.SUBMITTED_FOR_INVOICING), canBeRestricted: true},
                {value: invoiceStates.INVOICED, text: this.getStatesString(invoiceStates.INVOICED)},
                {value: invoiceStates.NO_INVOICING, text: this.getStatesString(invoiceStates.NO_INVOICING)}
            ]
        },

        getStatesString: function (state) {
            switch (state) {
                case invoiceStates.NOT_INVOICED:
                    return this.$t('daily_summary.invoice_state_not_invoiced')
                case invoiceStates.READY_FOR_INVOICING:
                    return this.$t('daily_summary.ready_for_invoicing')
                case invoiceStates.APPROVED_FOR_INVOICING:
                    return this.$t('daily_summary.approved_for_invoicing')
                case invoiceStates.REJECTED:
                    return this.$t('daily_summary.rejected')
                case invoiceStates.SUBMITTED_FOR_INVOICING:
                    return this.$t('daily_summary.submitted_for_invoicing')
                case invoiceStates.INVOICED:
                    return this.$t('daily_summary.invoice_state_invoiced')
                case invoiceStates.NO_INVOICING:
                    return this.$t('daily_summary.no_invoicing')
            }
        },
    }
}
