<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showReportsList"
        >
            <div
                v-if="!detailsMode"
                class="header"
            >
                <span class="title">{{ $t('ins_report.ins_report') }}</span>
            </div>
            <div
                v-else
                class="header col-sm-12 nopads"
                @click="showReportsList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
                <font-awesome-icon
                    icon="print"
                    class="print-icon"
                    @click.stop="print"
                />

                <b-button
                    v-if="reportDetails.location !== undefined"
                    class="m-2 print-icon"
                    variant="info"
                    size="md"

                    @click.stop="showModal"
                >
                    <span class="map-button-text">{{ $t('menu.map') }}</span>
                </b-button>

            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <keep-alive>
                    <inspection-report-list
                        v-if="!detailsMode"
                        :results="results"
                        @search="searchReport"
                        @details="showReportDetails"
                    />
                </keep-alive>
            </transition>
            <transition name="fade">
                <inspection-report-details
                    v-if="detailsMode"
                    id="details"
                    ref="details"
                    :report-details="reportDetails"
                    @close="showReportsList"
                />
            </transition>
            <inspection-map
                :center="reportDetails.location"
                id="InspectionMap"
                ref="InspectionMap"
                />
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import InspectionReportList from './InspectionReportList'
import InspectionReportDetails from './InspectionReportDetails'
import inspectionMap from "@/components/inspections/InspectionMap.vue";

export default {
    name: 'InspectionReports',
    components: {InspectionReportList, InspectionReportDetails, inspectionMap},
    mixins: [restApi],
    data: function () {
        return {
            results: [],
            loading: false,
            detailsMode: false,
            reportDetails: [],
            params: {}
        }
    },
    methods: {
        fetchInspectionReports: function () {
            this.loading = true
            this.restFetchParams(this.inspectionReportGenericUrl, this.params, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response) {
                this.results = response.data
            }
        },

        searchReport: function (params) {
            this.params = params
            this.fetchInspectionReports()
        },
        showReportDetails: function (details) {
            this.reportDetails = details
            this.detailsMode = true
        },
        showReportsList: function () {
            this.detailsMode = false
        },
        print: function () {
            this.$refs.details.print()
        },
        showModal() {
            this.$refs.InspectionMap.showModal()

        }
    }

}
</script>
