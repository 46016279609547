<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="efficiency-list"
        class="col-sm-12 list-content"
    >
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="tableItems"
                    :fields="fields"
                    @row-clicked="emitEdit"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('common.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(name)="row">
                        {{ row.item.name }}
                    </template>
                    <template v-slot:cell(tasks)="row">
                        {{ row.item.tasks }}
                    </template>
                </b-table>
            </div>
        </transition>

    </div>
</template>

<script>
export default {
    name: "EfficiencyList",
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },

    data() {
        return {
            sortBy: 'tasktype',
            sortDesc: false,
            tableItems: [],
            fields: [
                {key: 'name', label: this.$t('common.name'), sortable: true},
                {key: 'tasks', label: this.$t('forest_work_eff.tasks'), sortable: false},
            ],
            loading: false
        }
    },

    mounted: function () {
        this.initTableItems()
    },

    watch: {
        results: function () {
            this.initTableItems()
        }
    },

    methods: {
        initTableItems() {
            this.tableItems = []
            if (this.results) {
                this.results.forEach(item => {
                    let names = ''
                    item.task_types.forEach(type => {
                        if (names.length > 0) {
                            names += ', '
                        }
                        names += type.task_type.name
                    })
                    let toTable = {
                        name: item.name,
                        id: item.id,
                        tasks: names
                    }
                    this.tableItems.push(toTable)
                })
            }
        },

        emitEdit: function (result) {
            let item = this.results.find(item => result.id === item.id)
            if (item) {
                this.$emit('edit', item)
            }
        }
    }
}
</script>

<style scoped>

</style>
