<template>
    <div>
        <span class="span-title pl-1" v-if="title !== ''">{{ title }}</span>
        <b-row class="nopads">
            <b-form-input
                :value="updateCoordinate.y"
                @input="v => {updateCoordinate.y = Number(v); $forceUpdate()}"
                class="col-sm-4 m-1"
                type="number"
                step="0.0000000000001"
                :placeholder="$t('common.latitude')"
                :size="size"
                :disabled="disabled"
            />
            <b-form-input
                :value="updateCoordinate.x"
                @input="v => {updateCoordinate.x = Number(v); $forceUpdate()}"
                class="col-sm-4 m-1"
                type="number"
                step="0.0000000000001"
                :placeholder="$t('common.longitude')"
                :size="size"
                :disabled="disabled"
            />
            <b-button
                v-if="mapAvailable"
                class="m-1 p-1"
                style="height: 2em; width: 2em"
                :variant="!showMap ? 'primary' : ''"
                @click.stop="showMap = !showMap"
            >
                <i class="fa fa-map"/>
            </b-button>
        </b-row>
        <div v-if="mapAvailable && showMap" class="coordinate-map">
            <marker-editor-map
                :lat="updateCoordinate.y"
                :lon="updateCoordinate.x"
                :type="pointType"
                :draggable="!disabled"
                :showButtons="false"
                @onDragMarker="updateLocation"
                @onMapClicked="updateLocation"
            />
        </div>
        <div v-if="showButtons" class="col-sm-12 button-container">
            <b-button
                v-show="showMap"
                variant="danger"
                size="sm"
                class="form-button"
                @click.stop="resetLocation"
            >
                {{ $t('common.reset') }}
            </b-button>
            <b-button
                v-show="showMap"
                variant="success"
                size="sm"
                class="form-button"
                @click.stop="setLocation"
            >
                {{ $t('common.save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import MarkerEditorMap from './map/MarkerEditorMap'

export default {
    name: 'CoordinateEditor',
    components: {MarkerEditorMap},
    props: {
        value: {
            type: Object
        },
        title: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        pointType: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showButtons: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            showMap: false,
            updateCoordinate: {}
        }
    },
    mounted() {
        this.updateCoordinate = this.value
        if (!this.updateCoordinate.x && !this.updateCoordinate.y) {
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude, longitude } }) => {
                    this.updateCoordinate.x = longitude
                    this.updateCoordinate.y = latitude
                    this.$forceUpdate()
                },
                () => {
                    this.updateCoordinate.x = 25.8
                    this.updateCoordinate.y = 62.2
                    this.$forceUpdate()
                },
                { timeout: 30000 }
            )
        }
    },
    computed: {
        mapAvailable() {
            return this.pointType != null && !isNaN(this.pointType)
        }
    },
    methods: {
        setLocation() {
            this.$emit('setLocation', this.updateCoordinate)
        },

        updateLocation(location) {
            if(location.lat && location.lng) {
                this.updateCoordinate.x = location.lng
                this.updateCoordinate.y = location.lat
            } else if(location.x && location.y) {
                this.updateCoordinate.x= location.x
                this.updateCoordinate.y = location.y
            }
            this.$forceUpdate()
        },

        resetLocation: function () {
            this.location = null
            this.updateCoordinate = {}
            this.$emit('close')
        },
    }
}
</script>

<style scoped>

</style>
