<template>
    <table class="table table-sm table-fixed">
        <tbody>
        <tr>
            <th class="item-detail-title">{{ $t('ins_report_details.certificate') }}</th>
            <th class="item-detail-title">{{ $t('ins_report_details.granted') }}</th>
            <th class="item-detail-title">{{ $t('ins_report_details.expires') }}</th>
        </tr>
        <tr v-for="item in certificates" v-bind:key="item.id">
            <td class="item-detail-text">
                {{ item.type.name }}
            </td>
            <td class="item-detail-text">
                {{ toLocalTime(item.granted_day) }}
            </td>
            <td class="item-detail-text">
                {{ toLocalTime(item.expiration_day) }}
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'InspectorCertificates',
    mixins: [timeUtils],
    props: {
        certificates: {
            type: Array,
            default: null
        }
    }
}
</script>
