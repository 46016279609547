<template>
    <!-- Inspection List -->
    <div
        id="inspection-list"
        class="col-sm-12"
    >
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
                <span class="item-title-add">{{ $t('common.add_new') }}</span>
                <font-awesome-icon
                    icon="clipboard-check"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="results && results.length"
                id="accordion"
                class="col-sm-12 panel-group data-container"
            >
                <div
                    v-for="item of results"
                    :key="item.type+'_'+item.id"
                    v-b-toggle="'accordion-'+item.type+''+item.id"
                    class="data-list-item"
                >
                    <div class="col-sm-12 item-header">
                        <div class="item-img-cont">
                            <font-awesome-icon icon="clipboard-check"/>
                        </div>
                        <div
                            v-if="isAdmin"
                            class="item-text-cont"
                        >
                            <span class="item-title">{{ item.name }}</span>
                            <span class="item-text">{{ item.company.name }}</span>
                        </div>
                        <div
                            v-else
                            class="item-text-cont"
                        >
                            <span class="item-title-single">{{ item.name }}</span>
                        </div>
                    </div>
                    <b-collapse
                        :id="'accordion-'+item.type+''+item.id"
                        class="mt-2 item-details"
                    >
                        <div class="row nopads">
                            <div class="col-sm-4 item-detail-container">
                                <div class="item-detail-title">
                                    {{ $t('inspection_template.inspection_type') }}
                                </div>
                                <div class="item-detail-text">
                                    <span>{{ getInspectionType(item.type) }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4 item-detail-container">
                                <div class="item-detail-title">
                                    {{ $t('inspection_editor.inspection_frequency') }}
                                </div>
                                <div class="item-detail-text">
                                    <span>{{ getInspectionFreq(item.frequency) }}</span>
                                </div>
                            </div>
                            <div v-if="item.type === 1" class="col-sm-4 item-detail-container">
                                <div class="item-detail-title">
                                    {{ $t('inspection_template.vehicle_type') }}
                                </div>
                                <div class="item-detail-text">
                                    <span>{{ getVehicleTypeName(item.vehicle_type) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row nopads">
                            <div class="item-detail-container">
                                <div class="item-detail-title">
                                    {{ $t('inspection_template.inspectables') }}
                                </div>
                                <span
                                    v-for="inspectable of item.inspectables"
                                    :key="inspectable.id"
                                    class="inspectables inspectables-selected"
                                >
                  {{ inspectable.name }}
                </span>
                            </div>

                            <div class="col-sm-12 button-container">
                                <b-button
                                    variant="primary"
                                    class="routa-button"
                                    @click.stop="$emit('edit', item)"
                                >
                                    <i class="fa fa-edit"/>
                                    <span class="button-text">
                    {{ $t('common.edit') }}
                  </span>
                                </b-button>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {vehicleHelper} from '../mixins/VehicleMixin'

export default {
    name: 'InspectionList',
    mixins: [vehicleHelper],
    props: {
        results: {
            type: Array,
            default: null
        },
        isAdmin: Boolean
    },
    methods: {
        getInspectionType: function (type) {
            switch (type) {
                case 1:
                    return this.$t('inspection_template.type.vehicle')
                case 2:
                    return this.$t('inspection_template.type.work')
                case 4:
                    return this.$t('inspection_template.type.company')
                case 5:
                case 7:
                    return this.$t('inspection_template.type.bridge')
                case 6:
                    return this.$t('inspection_template.type.culvert')
            }
        },
        getInspectionFreq: function (freq) {
            switch (freq) {
                case 1:
                    return this.$t('inspection_template.frequency.weekly')
                case 2:
                    return this.$t('inspection_template.frequency.monthly')
                case 3:
                    return this.$t('inspection_template.frequency.annually')
                case 4:
                    return this.$t('inspection_template.frequency.deployment')
                case 5:
                    return this.$t('inspection_template.frequency.daily')
                case 6:
                    return this.$t('inspection_template.frequency.other')
            }
        }
    }
}
</script>
