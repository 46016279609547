<template>
    <div class="nopads">
    <b-row class="nopads">
        <b-col cols="6" class="nopads pr-1 pl-1">
            <span class="span-title">{{ $t('road_sign.sign_lane') }}</span>
            <b-form-input
                id="lane"
                :value="getVelhoLaneString(laneData)"
                type="text"
                size="sm"
                disabled
            />
        </b-col>
        <b-col cols="6" class="nopads pr-1 pl-1">
            <span class="span-title">{{ $t('road_sign.side') }}</span>
            <b-form-input
                id="side"
                :value="getVelhoSideString(sideData)"
                type="text"
                size="sm"
                disabled
            />
        </b-col>
    </b-row>
    <b-row class="nopads">
        <b-col cols="6" class="nopads pr-1 pl-1">
            <span class="span-title">{{ $t('velho_conversion.tracks') }}</span>
            <b-form-input
                id="tracks"
                v-model="tracksFormated"
                type="text"
                size="sm"
                disabled
            />
        </b-col>
    </b-row>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";

export default {
    name: 'LocationDetails',
    components: {},
    mixins: [velhoHelper, conversionModelHelper, restApi],
    props: {
        sideData: {
            type: Number,
            default: -1
        },
        laneData: {
            type: Number,
            default: -1
        },
        trackData: {
            type: Array,
            default: Array
        }
    },
    data() {
        return {
        loading: false,
        }
    },

    computed: {
        tracksFormated () { return this.trackData.join(', ') }
    },
    methods: {

    }
}
</script>
