<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12">
        <div
            v-if="canAdd"
            class="data-list-item add mb-2"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="user"
                    class="card-body-icon"
                />
            </div>
        </div>
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table nopads"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                    :sort-by="sortBy"
                    :sort-compare-locale="$i18n.locale"
                    :bordered="true"
                    @row-clicked="emitEdit"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(superuser)="row">
                        {{ row.value}}
                    </template>
                    <template v-slot:cell(observer)="row">
                        {{ row.value }}
                    </template>
                    <template v-slot:cell(worker)="row">
                        {{row.value }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {Roles, userHelper} from '../mixins/UserMixin'

export default {
    name: 'UserList',
    mixins: [userHelper],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        },
        canAdd: Boolean
    },
    data() {
        return {
            sortBy: 'last_name',
            sortDesc: false,
            fields: [
                {key: 'username', label: this.$t('users.user_name'), sortable: true},
                {key: 'last_name', label: this.$t('users.last_name'), sortable: true},
                {key: 'first_name', label: this.$t('users.first_name'), sortable: true},
                {key: 'email', label: this.$t('users.email'), sortable: true},
                {key: 'company.name', label: this.$t('users.company'), sortable: true },
                {key: 'license_holder.name', label: this.$t('users.license_holder_title'), sortable: true},
                {key: 'superUser',
                    formatter: (value, key, item) => {
                        return this.hasSuperuser(item.rights)
                    },
                    label: this.$t('users.company_super_user'),
                    sortable: true,
                    sortByFormatted: true
                },
                {key: 'observer',
                    formatter: (value, key, item) => {
                        return this.hasObserver(item.rights)
                    },
                    label: this.$t('users.observer'),
                    sortable: true,
                    sortByFormatted: true
                },
                {key: 'worker',
                    formatter: (value, key, item) => {
                        return this.hasWorker(item.rights)
                    },
                    label: this.$t('users.worker'),
                    sortable: true,
                    sortByFormatted: true
                },
            ],
            loading: false
        }
    },
    beforeUpdate() {
       this.results.forEach(user => {
           if (!this.hasObserver(user.rights) && !this.hasWorker(user.rights) && !this.hasSuperuser(user.rights)) {
               user._rowVariant = 'danger'
           }
       })
    },
    methods: {
        emitEdit: function (result) {
            let item = this.results.find(item => result.id === item.id)
            if (item) {
                this.$emit('edit', item)
            }
        },
        hasObserver(rights) {
            return rights.find(item => (item.role.role === Roles.OBSERVER && !item.removed_at)) !== undefined ? 'x' : ''
        },
        hasWorker(rights) {
            return rights.find(item => (item.role.role === Roles.WORKER && !item.removed_at)) !== undefined ? 'x' : ''
        },
        hasSuperuser(rights) {
            return rights.find(item => (item.role.role === Roles.COMPANY_SUPER_USER && !item.removed_at)) !== undefined ? 'x' : ''
        },
    }
}
</script>
