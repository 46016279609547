<template>
    <div class="p-1 pl-3 pr-3">
            <b-row>
                <b-col class="nopads report-header" cols="10">
                    <span class="section-title pt-1" >{{ $t('contract_diary.new') }}</span>
                </b-col>
                <b-col v-if="enableCollapse" class="nopads mb-1">
                    <font-awesome-icon
                        icon="arrow-down"
                        class="pointer right no-focus arrow-icon dont-print"
                        v-bind:class="{rotated : formVisible}"
                        @click.stop="formVisible = !formVisible"/>
                </b-col>
            </b-row>
            <b-collapse
                id="contractDiaryForm"
                class="mt-2 mb-2 item-details"
                v-model="formVisible"
            >
                <div v-if="contract < 1" class="col-sm-3 nopads pl-2">
                    <open-contract-filter
                        ref="contracts"
                        :from="now"
                        :show-title="true"
                        :show-company="false"
                        :apply-padding="false"
                        @contractSelected="setContract"/>
                </div>
                <b-row class="nopads">
                    <div class="col-sm-3 pr-2">
                        <span class="span-title">{{ $t('contract_diary.date') }}</span>
                        <b-form-input
                            id="fromDate"
                            v-model="date"
                            type="date"
                            size="sm"
                        />
                        <span v-if="elyEnabled" class="span-title">{{ $t('contract_diary.duty_officer') }}</span>
                        <b-form-group
                            v-if="elyEnabled"
                            label-for="duty_officer"
                        >
                            <b-form-select
                                id="duty_officer"
                                v-model="selectedOfficer"
                                size="sm"
                                :state="selectedOfficer !== null"
                                :disabled="!users || users.length === 0"
                            >
                                <option :value="null">
                                    {{ $t('contract_diary.select_duty_officer') }}
                                </option>
                                <option
                                    v-for="option in users"
                                    :key="option.id"
                                    :value="option"
                                >
                                    {{ option.first_name + ' ' + option.last_name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-sm-5 pr-2">
                        <span class="span-title">{{ $t('contract_diary.events') }}</span>
                        <b-textarea
                            id="events"
                            v-model="events"
                            rows=7
                            :state="(elyEnabled && events.length > 3) || (events.length > 3 && events.length < 500)"
                            size="sm"
                        />
                        <span class="tip-light">
                            {{ events.length }} / 500
                        </span>
                    </div>
                    <div class="col-sm-4 pr-2">
                        <span class="span-title">{{ $t('contract_diary.deviations') }}</span>
                        <b-textarea
                            id="deviations"
                            v-model="deviations"
                            rows=7
                            :state="elyEnabled || (deviations.length > 1 && deviations.length < 500)"
                            size="sm"
                        />
                        <span class="tip-light">
                        {{ deviations.length }} / 500
                        </span>
                    </div>
                </b-row>
                <div v-if="elyEnabled || (selectedContract && selectedContract.ely_contract)" class="nopads">
                    <b-row class="nopads">
                        <span class="span-title">{{ $t('contract_diary.media_contacts_info_title') }}</span>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-3 pr-2">
                            <div class="row">
                                <b-form-radio v-model="mediaContactsReceived" :value="true">{{ $t('common.yes') }}</b-form-radio>
                            </div>
                            <div class="row">
                                <b-form-radio v-model="mediaContactsReceived" :value="false">{{ $t('common.no') }}</b-form-radio>
                            </div>
                        </div>
                        <div class="col-sm-9 pr-2 pb-2">
                            <b-textarea
                                id="mediaContactsInfo"
                                v-model="mediaContactsInfo"
                                rows=3
                                :disabled="!mediaContactsReceived"
                                :state="!mediaContactsReceived || (mediaContactsInfo.length > 1 && events.length < 500)"
                                size="sm"
                            />
                            <span class="tip-light">
                        {{ mediaContactsInfo.length }} / 500
                        </span>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ $t('contract_diary.customer_contacts_info_title') }}</span>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-3 pr-2">
                            <div class="row">
                                <b-form-radio v-model="customerContactsReceived" :value="true">{{ $t('common.yes') }}</b-form-radio>
                            </div>
                            <div class="row">
                                <b-form-radio v-model="customerContactsReceived" :value="false">{{ $t('common.no') }}</b-form-radio>
                            </div>
                        </div>
                        <div class="col-sm-9 pr-2 pb-2">
                            <b-textarea
                                id="customerContactsInfo"
                                v-model="customerContactsInfo"
                                rows=3
                                :disabled="!customerContactsReceived"
                                :state="!customerContactsReceived || (customerContactsInfo.length > 1 && customerContactsInfo.length < 500)"
                                size="sm"
                            />
                            <span class="tip-light">
                        {{ customerContactsInfo.length }} / 500
                        </span>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ $t('contract_diary.citizen_contacts_info_title') }}</span>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-3 pr-2">
                            <div class="row">
                                <b-form-radio v-model="citizenContactsReceived" :value="true">{{ $t('common.yes') }}</b-form-radio>
                            </div>
                            <div class="row">
                                <b-form-radio v-model="citizenContactsReceived" :value="false">{{ $t('common.no') }}</b-form-radio>
                            </div>
                        </div>
                        <div class="col-sm-9 pr-2 pb-2">
                            <b-textarea
                                id="citizenContactsInfo"
                                v-model="citizenContactsInfo"
                                rows=3
                                :disabled="!citizenContactsReceived"
                                :state="!citizenContactsReceived || (citizenContactsInfo.length > 1 && citizenContactsInfo.length < 500)"
                                size="sm"
                            />
                            <span class="tip-light">
                        {{ citizenContactsInfo.length }} / 500
                        </span>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ $t('contract_diary.authorities_assistance') }}</span>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-3 pr-2">
                            <div class="row">
                                <b-form-radio v-model="authoritiesAssistance" :value="true">{{ $t('common.yes') }}</b-form-radio>
                            </div>
                            <div class="row">
                                <b-form-radio v-model="authoritiesAssistance" :value="false">{{ $t('common.no') }}</b-form-radio>
                            </div>
                        </div>
                        <div class="col-sm-9 pr-2 pb-2">
                            <span class="span-title">{{ $t('contract_diary.hours') }}</span>
                            <b-form-input
                                id="authoritiesInfoHours"
                                v-model="authoritiesAssistanceHours"
                                type="number"
                                min="0"
                                :disabled="!authoritiesAssistance"
                                :state="!authoritiesAssistance || authoritiesAssistanceHours > 0"
                                size="sm"
                            />
                            <span class="span-title">{{ $t('contract_diary.description') }}</span>
                            <div class="col-12 nopads">
                                <b-textarea
                                    id="authoritiesInfo"
                                    v-model="authoritiesAssistanceInfo"
                                    rows=3
                                    :disabled="!authoritiesAssistance"
                                    :state="!authoritiesAssistance || (authoritiesAssistanceInfo.length > 1 && events.length < 500)"
                                    size="sm"
                                />
                                <span class="tip-light">
                                    {{ authoritiesAssistanceInfo.length }} / 500
                                </span>
                            </div>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ $t('contract_diary.exceptional_weather') }}</span>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-3 pr-2">
                            <div class="row">
                                <b-form-radio v-model="exceptionalWeather" :value="true">{{ $t('common.yes') }}</b-form-radio>
                            </div>
                            <div class="row">
                                <b-form-radio v-model="exceptionalWeather" :value="false">{{ $t('common.no') }}</b-form-radio>
                            </div>
                        </div>

                        <div class="col-sm-9 pr-2 pb-2">
                            <span class="span-title">{{ $t('contract_diary.time') }}</span>
                            <datetime
                                id="weatherTime"
                                class="col-12 nopads"
                                v-model="exceptionalWeatherTime"
                                type="datetime"
                                format="dd.MM.yyyy HH:mm"
                                :disabled="!exceptionalWeather"
                                v-bind:class="{missing: exceptionalWeather && !exceptionalWeatherTime, complete: !exceptionalWeather || exceptionalWeatherTime}"
                            />

                            <span class="span-title">{{ $t('contract_diary.location') }}</span>
                            <b-form-input
                                id="weatherLocation"
                                class="col-12"
                                v-model="exceptionalWeatherLocation"
                                type="text"
                                :disabled="!exceptionalWeather"
                                :state="!exceptionalWeather || (exceptionalWeatherLocation.length > 1)"
                            />

                            <span class="span-title">{{ $t('contract_diary.description') }}</span>
                            <div class="col-12 nopads">
                                <b-textarea
                                    class="col-12"
                                    id="authoritiesInfo"
                                    v-model="exceptionalWeatherInfo"
                                    rows=3
                                    :disabled="!exceptionalWeather"
                                    :state="!exceptionalWeather || (exceptionalWeatherInfo.length > 1 && exceptionalWeatherInfo.length < 500)"
                                    size="sm"
                                />
                                <span class="tip-light">
                                    {{ exceptionalWeatherInfo.length }} / 500
                                </span>
                            </div>
                        </div>
                    </b-row>
                </div>

                <div class="dont-print button-container text-right">
                    <b-button
                        variant="danger"
                        class="result-button"
                        size="sm"
                        @click.stop="resetForm"
                    >
                        {{ $t('common.clear') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        size="sm"
                        :disabled="(contract < 1 && !selectedContract) || !date || events.length < 4 || (!elyEnabled && events.length < 4) || (!elyEnabled && events.length > 500) ||
                        (!elyEnabled && deviations.length < 1) || (!elyEnabled && deviations.length > 500) || (elyEnabled && !selectedOfficer)"
                        @click.stop="submitRecord"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-collapse>
            <hr class="nopads mt-3"/>
        </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import {EventBus} from "@/event-bus";
import OpenContractFilter from "@/components/contract/OpenContractFilter";

export default {
    name: "contract-diary-form",
    components: {OpenContractFilter},
    mixins: [timeUtils, restApi],
    props: {
        contract: {
            type: Number,
            default() {
                return -1
            }
        },
        elyFields: {
            type: Boolean,
            default() {
                return false;
            }
        },
        enableCollapse: {
            type: Boolean,
            default() {
                return true;
            }
        }
    },
    data: function () {
        return {
            formVisible: false,
            results: [],
            selectedOfficer: null,
            users: [],
            mediaContactsReceived: false,
            mediaContactsInfo: "",
            customerContactsReceived: false,
            customerContactsInfo: "",
            citizenContactsReceived: false,
            citizenContactsInfo: "",
            exceptionalWeather: false,
            exceptionalWeatherTime: null,
            exceptionalWeatherInfo: "",
            exceptionalWeatherLocation: "",
            authoritiesAssistance: false,
            authoritiesAssistanceInfo: "",
            authoritiesAssistanceHours: 0,
            events: "",
            deviations: "",
            date: null,
            selectedContract: null,
        }
    },
    computed: {
        now() {
            return new Date().toISOString();
        },
        elyEnabled() {
            return this.elyFields || (this.selectedContract && this.selectedContract.ely_contract === true)
        }
    },
    mounted() {
        let today = new Date();
        let month = today.getMonth()+1
        let mTxt = month > 9 ? month : '0'+month
        let dTxt = today.getDate() > 9 ? today.getDate() : '0'+today.getDate()
        this.date = today.getFullYear() + '-' + mTxt  + '-' + dTxt
        this.fetchUsers()
        this.formVisible = !this.enableCollapse
    },
    methods: {
        setContract(contract) {
            this.selectedContract = contract
        },
        resetForm() {
            this.events = ""
            this.deviations = ""
            this.mediaContactsInfo = ""
            this.customerContactsInfo = ""
            this.citizenContactsInfo = ""
            this.authoritiesAssistanceInfo = ""
            this.authoritiesAssistanceHours = 0
            this.exceptionalWeatherInfo = ""
            this.exceptionalWeatherLocation = ""
            this.exceptionalWeatherTime = null
            this.mediaContactsReceived = false
            this.customerContactsReceived = false
            this.citizenContactsReceived = false
            this.authoritiesAssistance = false
            this.exceptionalWeather = false
            this.selectedContract = null
            if (this.$refs.contracts) {
                this.$refs.contracts.resetSelection()
            }
        },
        submitRecord: function () {
            if (this.date && (this.contract || this.selectedContract)) {
                let record = {
                    record_date: this.date,
                    events: this.events,
                    deviations: this.deviations,
                    duty_officer: this.selectedOfficer,
                    contract: {id: this.contract > 0 ? this.contract : this.selectedContract},
                    media_contacts_received: this.mediaContactsReceived,
                    customer_contacts_received: this.customerContactsReceived,
                    citizen_contacts_received: this.citizenContactsReceived,
                    exceptional_weather: this.exceptionalWeather,
                    authorities_assistance: this.authoritiesAssistance,
                    media_contacts_info: this.mediaContactsReceived ? this.mediaContactsInfo : "",
                    customer_contacts_info: this.customerContactsReceived ? this.customerContactsInfo : "",
                    citizen_contacts_info: this.citizenContactsReceived ? this.citizenContactsInfo : "",
                    exceptional_weather_info: this.exceptionalWeather ? this.exceptionalWeatherInfo : "",
                    exceptional_weather_location: this.exceptionalWeather ? this.exceptionalWeatherLocation : "",
                    exceptional_weather_time: this.exceptionalWeather ? this.exceptionalWeatherTime : null,
                    authorities_assistance_info: this.authoritiesAssistance ? this.authoritiesAssistanceInfo : "",
                    authorities_assistance_hours: this.authoritiesAssistance ? this.authoritiesAssistanceHours : "",
                }
                this.$emit('loading', true)
                this.restAdd(this.contractDiaryUrl, record, this.success, this.fail)
            }
        },
        fetchUsers: function () {
            this.loading = true;
            this.users = []
            this.restFetchParams(this.userCompanyUrl, {active: 1}, this.handleUserResponse)
        },

        handleUserResponse: function (response) {
            if (response && response.data) {
                response.data.forEach(function (user) {
                    this.users.push({
                        last_name: user.last_name,
                        first_name: user.first_name,
                        id: user.id
                    })
                }, this)
            }
            this.loading = false
        },
        success() {
            EventBus.$emit('show-alert', this.$t('contract_diary.submitted'), 'success')
            this.$emit('loading', false)
            this.$emit('recordSent', false)
            this.resetForm()
        },
        fail() {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.$emit('loading', false)
        }
    }
}
</script>
<style scoped>
    .missing {
        border: 1px solid red;
        border-radius: 5px;
    }
    .complete {
        border: 1px solid green;
        border-radius: 5px;
    }

</style>
