<template>
    <div class="search-form mt-0 mb-0 p-2" style="height: 40vh">
        <span class="report-title mt-0 text-center">{{ $t('harja.notification_type') }}</span>
            <bar-chart
                class="routa-bars dont-print mt-4"
                ref="barReports"
                v-if="data !== null"
                :labels="labels"
                :colors="colors"
                :data="chartData"
            ></bar-chart>
    </div>
</template>

<script>

import {mapHelper} from "@/components/mixins/MapMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import BarChart from "@/components/chart/BarChart.vue";

export default {
    name: 'HarjaMessageChart',
    mixins: [mapHelper,restApi],
    components: {BarChart},
    props: {
        data: {
            type: Array,
            default: null
        },
    },
    data() {
        return {
            searchResults: null,
            editHarja: null,
            labels: [],
            colors: [],
            chartData: []
        }
    },
    watch: {
        data() {
            this.formatData()
        }
    },
    methods: {
        formatData: function () {
            this.chartData = []
            this.labels = ['tiedoitus', 'toimenpidepyyntö', 'kysely']
            this.colors = ['#ccae20', '#10a6d3', '#da1d9e']
            let tiedoitus = 0
            let toimenpidepyynto = 0
            let kysely = 0
            this.data.forEach(item =>  {
                switch (item.ilmoitustyyppi) {
                    case "tiedoitus":
                        tiedoitus++
                        break
                    case "toimenpidepyynto":
                        toimenpidepyynto++
                        break
                    case "kysely":
                        kysely++
                        break
                }
            })
            this.chartData = [{value: tiedoitus}, {value: toimenpidepyynto}, {value: kysely}]
        },
    }
}
</script>

<style scoped>

</style>
