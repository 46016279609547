export default class UserAlert {

    static TYPE_USER_INITIATED = 1
    static TYPE_AUTOMATIC = 2

    id;
    user;
    time;
    location;
    contract;
    company;
    type;
    resolvedBy;
    resolvedTime;
    resolvedNotes;

    async resolveAlert(restApi, notes) {
        await new Promise((resolve, reject) => {
            restApi.restUpdate(`${restApi.userAlertUrl}/${this.id}/resolve`, {notes}, resolve, reject)
        })
    }

    // Fetches UserAlerts from the server
    static async fetch(restApi, resolved = null) {
        let data = (await new Promise((resolve, reject) => {
            restApi.restFetchParams(restApi.userAlertUrl, {resolved}, resolve, reject)
        })).data
        return data.map(o => Object.assign(new UserAlert(), o))
    }
}
