<template>
    <div class="content-container">
        <div
            class="header"
        >
            <span class="title">{{ $t('menu.default_inspections') }}</span>
        </div>
        <contract-inspections
            class="ml-4"
            v-model="inspections"/>

        <div class="col-sm-12 button-container">
            <b-button
                variant="success"
                class="form-button"
                :disabled="loading"
                @click.stop="submitInspections"
            >
        <span>
            {{ $t('common.save') }}
          </span>
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import ContractInspections from "./ContractInspections";

export default {
    name: 'default-inspections',
    mixins: [restApi],
    components: {ContractInspections},
    data: function () {
        return {
            loading: false,
            inspections: []
        }
    },

    methods: {
        submitInspections: function () {
            this.loading = true
            let ids = []
            this.inspections.forEach(item => ids.push(item.id))
            this.restAdd(this.defaultInspectionUrl, {inspections: ids}, this.success, this.fail)
        },

        success: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>
