<template>
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    v-if="items.length > 0"
                    id="resultTable"
                    ref="resultTable"
                    :items="items"
                    :fields="fields"
                    :small="true"
                    :responsive="true"
                    :sort-compare="sortCompare"
                    @row-clicked="showDocument"
                >
                    <template v-slot:cell(added_time)="row">
                        {{ toLocalTime(row.item.added_time) }}
                    </template>
                    <template v-slot:cell(added_by)="row">
                        {{ row.item.added_by.last_name }}, {{ row.item.added_by.first_name }}
                    </template>
                    <template v-slot:cell(company)="row">
                        {{ row.item.company.name }}
                    </template>
                    <template v-slot:cell(category)="row">
                        {{ row.item.category ? row.item.category.name : '' }}
                    </template>
                </b-table>
                <div v-if="fetchFinished && items.length === 0" class="text-center my-2">
                    {{ $t('common.no_results') }}
                </div>
            <div v-if="loading"
                 id="loader"
                 class="spinner">
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'GeneralGuidesList',
    mixins: [restApi, timeUtils],
    data() {
        return {
            fields: [
                { key: 'name', label: this.$t('general_guides.document_title'), sortable: true},
                { key: 'category', label: this.$t('general_guides.category'), sortable: true},
                { key: 'description', label: this.$t('common.description'), sortable: true},
                { key: 'added_time', label: this.$t('general_guides.time_added'), sortable: true},
                { key: 'added_by', label: this.$t('general_guides.added_by'), sortable: true},
                { key: 'company', label: this.$t('common.company'), sortable: true},
            ],
        }
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        user: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        fetchFinished: {
            type: Boolean,
            default: false
        }
    },

    beforeUpdate() {
        this.items.forEach(document => {
            if (!document.attachment) {
                document._rowVariant = 'danger'
            }
        })
    },

    methods : {
        showDocument: function (result) {
            this.$emit('edit', result)
        },

        getAddedByName(added_by) {
            return added_by.last_name + ', ' + added_by.first_name
        },

        sortCompare(aRow, bRow, key) {
            if (key === 'company') {
                const a = aRow['company']
                const b = bRow['company']
                return a.name < b.name ? -1 : b.name < a.name ? 1 : 0
            }
            if (key === 'added_by') {
                const a = this.getAddedByName(aRow['added_by'])
                const b = this.getAddedByName(bRow['added_by'])
                return a < b ? -1 : b < a ? 1 : 0
            }
            return undefined;
        }
    }
}
</script>
