<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2 class="form-title editor-title">
                    {{ (materialTypeUpdate.id > 0) ? $t('materials.edit_type') : $t('materials.add_type') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
        <span class="span-title">
          {{ $t('materials.name') }}
        </span>
                <b-form-group class="title" label-for="name">
                    <b-form-input
                        id="name"
                        v-model="materialTypeUpdate.name"
                        type="text"
                        size="mb"
                    />
                </b-form-group>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')">
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: "MaterialTypeEditor",
    mixins: [restApi],
    props: {
        materialType: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            materialTypeUpdate: this.jsonToItem(this.materialType)
        }
    },
    methods: {
        setDefaultValues: function (data) {
            if (data.name === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return undefined
            }
            return data
        },
        submit: function () {
            let json = this.setDefaultValues(this.materialTypeUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.materialTypeUpdate.id < 1) {
                    this.restAdd(this.materialTypeUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.materialTypeUrl, json, this.success, this.fail)
                }
            }
        },
        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        jsonToItem: function (item) {
            return JSON.parse(JSON.stringify(item))
        }
    }
}
</script>
