<template>
    <div
        class="details-container"
        @click.stop="close"
    >
        <div
            class="details smaller_width"
            @click.stop=""
        >
            <div>
                <h3 class="p-3">{{ $t('orders.order_price_history_title') }}</h3>
            </div>
            <div class="col-sm-12 nopads">
                <transition name="fade">
                    <div
                        class="col-sm-12 pb-2 result-table"
                    >
                        <b-table
                            small
                            id="resultTable"
                            ref="resultTable"
                            :responsive="true"
                            :items="tableItems"
                            :fields="fields"
                            :bordered="true"
                        >
                            <template slot="empty">
                                <div class="text-center my-2">
                                    {{ $t('area_report.no_results') }}
                                </div>
                            </template>
                            <template v-slot:cell(id)="row">
                                {{ row.item.id }}
                            </template>
                            <template v-slot:cell(price_list)="row">
                                {{ row.item.price_list ? row.item.price_list.name : null }}
                            </template>
                            <template v-slot:cell(unit_price)="row">
                                {{ row.item.unit_price ? (row.item.unit_price / 100).toFixed(3) : null }}
                            </template>
                            <template v-slot:cell(pricing_model)="row">
                                {{ pricingModelToText(row.item.pricing_model) }}
                            </template>
                            <template v-slot:cell(created_by)="row">
                                {{ row.item.created_by ? row.item.created_by.first_name + " " + row.item.created_by.last_name : null }}
                            </template>
                            <template v-slot:cell(removed_by)="row">
                                {{ row.item.removed_by ? row.item.removed_by.first_name + " " + row.item.removed_by.last_name : null }}
                            </template>
                            <template v-slot:cell(created_date)="row">
                                {{ row.item.created_date ? timestampToDateInput(row.item.created_date) : null }}
                            </template>
                            <template v-slot:cell(removed_date)="row">
                                {{ row.item.removed_date ? timestampToDateInput(row.item.removed_date) : null }}
                            </template>
                        </b-table>
                    </div>
                </transition>
            </div>
            <div class="button-container">
                <b-button
                    variant="danger"
                    style="margin-top: 5px;"
                    @click.stop="close"
                >
                    {{ $t('common.close') }}
                </b-button>
            </div>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from "@/components/mixins/RestApiMixin";
import {priceHelper} from "@/components/mixins/PricingMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: "OrderPriceHistoryModal",
    components: {},
    mixins: [restApi, priceHelper, timeUtils],
    props: {
        orderId: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            priceHistories: [],
            tableItems: []
        }
    },
    computed: {
        fields() {
            return [
                {key: 'id', label: 'Id', sortable: true},
                {key: 'unit_price', label: this.$t('orders.unit_price'), sortable: true},
                {key: 'pricing_model', label: this.$t('orders.pricing_model'), sortable: true},
                {key: 'price_list', label: this.$t('orders.pricelist'), sortable: true},
                {key: 'created_date',
                    formatter: (value, key, item) => {
                        return item.created_date
                    },
                    label: this.$t('orders.create_time'),
                    sortable: true,
                    sortByFormatted: true
                },
                {key: 'removed_date',
                    formatter: (value, key, item) => {
                        return item.removed_date
                    },
                    label: this.$t('orders.complete_time'),
                    sortable: true,
                    sortByFormatted: true
                },
                {key: 'created_by', label: this.$t('orders.created_by'), sortable: true},
                {key: 'removed_by', label: this.$t('orders.removed_by'), sortable: true},
            ]
        }
    },
    mounted() {
        this.fetchPriceHistory()
    },
    methods: {
        fetchPriceHistory() {
            this.loading = true
            this.priceHistories = []
            this.restFetch(this.orderPriceHistoryUrl + "/" + this.orderId, this.successPriceHistory, this.failPriceHistory)
        },
        successPriceHistory(response) {
            this.loading = false
            if(response && response.data) {
                this.priceHistories = response.data
            }
            this.initTableItems()
        },
        initTableItems() {
            this.tableItems = []
            if (this.priceHistories) {
                this.priceHistories.forEach(item => {
                    this.tableItems.push(item)
                })
            }
        },
        failPriceHistory() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.error'))
        },
        close() {
            this.$emit("close")
        },
    }
}
</script>
<style>
.prop-header {
    font-weight: 800;
    font-size: 0.8em;
    text-transform: uppercase;
}
</style>
