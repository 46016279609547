<template>
    <div class="work-day-records p-3">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2 class="form-title editor-title">
                    {{ $t('work_time.edit') }}
                </h2>
            </div>

            <div class="col-sm-12 form-data">
                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('work_time.start') }}</span>
                        <datetime
                            id="start"
                            v-model="recordUpdate.approved_start_time"
                            type="datetime"
                            size="sm"
                            format="dd:MM:yyyy HH:mm"
                            class="datetime-routa"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                    <div class="col-sm-6 nopads pl-2">
                        <span class="span-title">{{ $t('work_time.end') }}</span>
                        <datetime
                            id="end"
                            v-model="recordUpdate.approved_end_time"
                            type="datetime"
                            format="dd:MM:yyyy HH:mm"
                            class="datetime-routa"
                            size="sm"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </b-row>

                <b-row class="nopads">
                    <!-- Shift -->
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('work_shift.shift') }}</span>
                        <b-form-select
                            id="shift"
                            v-model="shift"
                            :options="shifts"
                            size="sm"
                            class="no-margin"
                        />
                    </div>
                    <!-- Vehicle -->
                    <div class="col-sm-6 nopads pl-2">
                        <span class="span-title">{{ $t('trip_list.vehicle') }}</span>
                        <b-form-select
                            id="vehicle"
                            v-model="vehicle"
                            size="sm"
                            class="mb-1"
                            :disabled="!vehicles || vehicles.length < 1"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('work_time.select_vehicle') }}
                                </option>
                            </template>
                            <option
                                v-for="item in vehicles"
                                :key="item.id"
                                :value="item.id"
                            >
                                {{ item.make }} {{ item.vehicle_model }} - {{ item.license_plate }}
                            </option>
                        </b-form-select>
                    </div>

                </b-row>

                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
            <span class="span-title">{{ $t('work_time.mode') }}
            </span>
                        <b-form-select
                            id="mode"
                            v-model="workTimeMode"
                            :disabled="workTimeModes.length < 1"
                            :options="workTimeModes"
                            size="sm"
                            class="no-margin"
                        />
                    </div>
                    <div class="col-sm-6 nopads pl-2">
                        <span class="span-title">{{ $t('work_time.cost_center') }}</span>
                        <b-form-select
                            id="costCenter"
                            v-model="costCenter"
                            :disabled="costCenters.length < 1"
                            :options="costCenters"
                            size="sm"
                            class="no-margin"
                        />
                    </div>
                </b-row>

                <open-order-filter
                    :to="recordUpdate.approved_end_time"
                    :from="recordUpdate.approved_start_time"
                    :order="recordUpdate.order"
                    @loading="setLoading"
                    @loadingCompleted="loadingDone"
                    @orderSelected="setOrder"/>


                <div class="col-sm-12 nopads">
                    <span class="span-title">{{ $t('work_time.note') }}</span>
                    <b-form-textarea
                        id="info"
                        v-model="recordUpdate.note"
                        :placeholder="$t('work_time.note')"
                        :rows="4"
                        :max-rows="6"
                    />
                </div>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitWorkTimeRecord"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'
import OpenOrderFilter from "../order/OpenOrderFilter";

export default {
    name: 'WorkTimeEditor',
    mixins: [workManagementHelper, restApi],
    components: {OpenOrderFilter},
    props: {
        workTimeRecord: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            workTimeModes: [],
            workTimeMode: null,
            costCenters: [],
            costCenter: null,
            vehicles: [],
            vehicle: null,
            loading: false,
            shift: null,
            shifts: [
                {value: null, text: this.$t('work_shift.select_shift')},
                {value: 1, text: this.$t('work_shift.day_shift')},
                {value: 2, text: this.$t('work_shift.morning_shift')},
                {value: 3, text: this.$t('work_shift.evening_shift')},
                {value: 4, text: this.$t('work_shift.night_shift')}
            ],
            recordUpdate: this.formatItem(this.workTimeRecord)
        }
    },

    watch: {
        workTimeMode: function () {
            if (this.workTimeMode !== this.workTimeRecord.mode.id) {
                this.costCenter = undefined
            }
        }
    },

    mounted: function () {
        this.fetchModes()
        this.fetchCostCenters()
        this.fetchVehicles()
        if (this.workTimeRecord.cost_center) {
            this.costCenter = this.workTimeRecord.cost_center.id
        }
        if (this.workTimeRecord.mode) {
            this.workTimeMode = this.workTimeRecord.mode.id
        }
        if (this.workTimeRecord.shift) {
            this.shift = this.workTimeRecord.shift
        }
        if (this.workTimeRecord.vehicle) {
            this.vehicle = this.workTimeRecord.vehicle.id
        }
    },

    methods: {
        fetchModes: function () {
            this.workTimeModes = []
            this.loading = true
            this.restFetchParams(this.workTimeModeUrl, {company: this.workTimeRecord.mode.company.id}, this.handleModeResponse)
        },

        handleModeResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                response.data.forEach(function (item) {
                    this.workTimeModes.push({value: item.id, text: item.name})
                }, this)
            }
        },

        fetchCostCenters: function () {
            this.costCenters = []
            this.loading = true
            this.restFetchParams(this.costCenterUrl, {company: this.workTimeRecord.mode.company.id}, this.handleCcResponse)
        },

        handleCcResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                response.data.forEach(function (item) {
                    this.costCenters.push({value: item.id, text: item.name})
                }, this)
            }
        },

        fetchVehicles: function () {
            this.loading = true
            this.vehicles = []
            this.restFetch(this.vehicleUrl, this.handleVehicles)
        },

        handleVehicles: function (response) {
            if (response && response.data) {
                this.vehicles = response.data
                if (this.vehicle) {
                    let exists = this.vehicles.find(item => item.id === this.vehicle)
                    if (!exists) {
                        this.vehicle = null
                    }
                }
            }
            this.loading = false
        },

        formatItem: function (item) {
            let copyObj = this.jsonToItem(item)
            copyObj.approved_start_time = item.approved_start_time ? item.approved_start_time + 'Z' : item.approved_start_time // Add 'Z' to show date in local timezone
            copyObj.approved_end_time = item.approved_end_time ? item.approved_end_time + 'Z' : item.approved_end_time
            return copyObj
        },

        submitWorkTimeRecord: function () {
            this.recordUpdate.mode = {id: this.workTimeMode}
            if (this.costCenter) {
                this.recordUpdate.cost_center = {id: this.costCenter}
            } else {
                this.recordUpdate.cost_center = undefined
            }
            if (this.vehicle) {
                this.recordUpdate.vehicle = {id: this.vehicle}
            }
            if (this.shift) {
                this.recordUpdate.shift = this.shift
            }
            var json = this.jsonToItem(this.recordUpdate)
            if (json !== undefined) {
                if (!json.approved_start_time) {
                    EventBus.$emit('show-alert', this.$t('work_time.invalid_start_time'))
                    return undefined
                }
                if (!json.approved_end_time || !this.isValidEndTime(json.approved_start_time, json.approved_end_time)) {
                    EventBus.$emit('show-alert', this.$t('work_time.invalid_end_time'))
                    return undefined
                }
                this.loading = true
                this.restUpdate(this.workTimeUrl, json, this.success, this.fail)
            }
        },
        isValidEndTime: function (startTime, endTime) {
            return new Date(startTime).getTime() < new Date(endTime).getTime()
        },
        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        setOrder: function (order) {
            this.recordUpdate.order = {id: order.id}
        },
        setLoading: function () {
            this.loading = true
        },
        loadingDone: function () {
            this.loading = false
        }
    }
}
</script>
