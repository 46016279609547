<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="modelUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('pricing_model_conversion.edit_mode') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('pricing_model_conversion.add_mode') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <span class="span-title">{{ $t('pricing_model_conversion.code') }}</span>
                <multiselect
                    v-model="code"
                    :options="forestCodeOptions"
                    :multiple="false"
                    :close-on-select="true"
                    label="text"
                    track-by="value"
                    :searchable="true"
                    :selected-label="$t('common.selected')"
                    :select-label="$t('common.select')"
                    :deselect-label="$t('common.remove_selection')"
                    :placeholder="$t('pricing_model_conversion.select_code')"
                    :disabled="loading"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.text }}</span>
                  </span>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.text }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>
                <span class="span-title">{{ $t('tasktypes.conversion.task_type') }}</span>
                <multiselect
                    v-model="taskType"
                    :options="taskTypeOptions"
                    :multiple="false"
                    :close-on-select="true"
                    label="text"
                    track-by="value"
                    :searchable="true"
                    :selected-label="$t('common.selected')"
                    :select-label="$t('common.select')"
                    :deselect-label="$t('common.remove_selection')"
                    :placeholder="$t('tasktypes.conversion.select_task_type')"
                    :disabled="loading"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.text }}</span>
                  </span>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.text }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading || !code || !taskType || !code.value || !taskType.value"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <b-modal
            v-model="deletion"
            size="sm"
            centered
            ok-variant="danger"
            :cancel-title="$t('common.no')"
            :ok-title="$t('common.yes')"
            @ok="deleteConversionModel()"
            @hide="deletion = false"
        >
            <div class="modal-body">
                <span>{{ $t('common.confirm_delete') }}</span>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {conversionModelHelper} from '@/components/mixins/ConversionMixin'
import {restApi} from '@/components/mixins/RestApiMixin'

export default {
    name: 'ConversionModelEditor',
    mixins: [conversionModelHelper, restApi],
    props: {
        model: {
            type: Object,
            default: null
        },
        conversionModels: {
            type: Array,
            default() {
                return []
            }
        },
        codes: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            code: null,
            deletion: false,
            taskType: null,
            taskTypes: [],
            taskTypeOptions: [],
            modelUpdate: this.jsonToItem(this.model),
        }
    },
    mounted: function () {
        this.fetchTaskTypes()
        if (this.modelUpdate.id > 0) {
            if (this.modelUpdate.code && this.modelUpdate.code.id) {
                let foundCode = this.codes.find(item => item.id === this.modelUpdate.code.id)
                if (foundCode) {
                    this.code = {text: foundCode.name + ' ' + '(' + foundCode.code + ')', value: foundCode.code, id: foundCode.id}
                }
            }
        }
    },

    computed: {
        forestCodeOptions() {
            const nullVal = { value: null, text: this.$t('tasktypes.conversion.select_code') }
            if ((!this.codes || !Object.keys(this.codes).length) || this.loading) {
                return [nullVal]
            }
            return [
                nullVal,
                ...this.codes.map(item => ({
                    value: item.code,
                    id: item.id,
                    text: item.name + ' ' + '(' + item.code + ')',
                }))
            ]
        },
    },

    methods: {
        fetchTaskTypes: function () {
            this.loading = true
            this.restFetch(this.taskTypeUrl, this.handleTaskTypesSuccess)
        },

        handleTaskTypesSuccess: function (response) {
            this.taskTypes = response.data
            this.handleTaskTypes()
            this.loading = false
        },

        handleTaskTypes() {
            this.taskTypeOptions = []
            // set empty option value
            this.taskTypeOptions.push({text: this.$t('tasktypes.conversion.select_task_type'), value: null})
            this.taskTypes && this.taskTypes.forEach(taskType => {
                if(this.modelUpdate.task_type && this.modelUpdate.task_type.id && taskType.id === this.modelUpdate.task_type.id) {
                    // set preselected pricing model
                    this.taskType = {text: taskType.name, value: taskType.id}
                }
                this.taskTypeOptions.push({text: taskType.name, value: taskType.id})
            })
        },

        setDefaultValues: function (model) {
            if (!this.code || !this.code.value) {
                EventBus.$emit('show-alert', this.$t('tasktypes.conversion.invalid_code'))
                return undefined
            }
            if (!this.taskType || !this.taskType.value) {
                EventBus.$emit('show-alert', this.$t('tasktypes.conversion.invalid_task_type'))
                return undefined
            }
            if(this.code && this.code.id) {
                model.code = this.codes.find(item => item.id === this.code.id)
            }
            if(this.taskType) {
                model.task_type = this.taskTypes.find(item => item.id === this.taskType.value)
            }
            if(this.checkForDuplicates(model)) {
                EventBus.$emit('show-alert', this.$t('tasktypes.conversion.duplicate_conversion'))
                return undefined
            }
            return model
        },

        submit: function () {
            let json = this.setDefaultValues(this.modelUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.modelUpdate.id < 1) {
                    this.restAdd(this.taskTypeConversionUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.taskTypeConversionUrl, json, this.success, this.fail)
                }
            }
        },

        checkForDuplicates(updateModel) {
            let duplicates = false
            if(this.conversionModels !== null) {
                this.conversionModels.every(cModel => {
                    let codeDefined = cModel.hasOwnProperty('task_type')
                    if((codeDefined && updateModel.task_type && cModel.task_type.id === updateModel.task_type.id) &&
                        // we also want to check if we are updating existing conversion model
                        cModel.id !== updateModel.id)
                    {
                        duplicates = true
                        return false;
                    }
                    return true
                })

            }
            return duplicates
        },

        deleteConversionModel: function () {
            this.deletion = false
            this.loading = true
            var url = this.taskTypeConversionUrl + '/' + this.modelUpdate.id
            this.restDelete(url, this.success, this.fail)
        },

        confirmDeleteConversionModel() {
            if (!this.loading) {
                this.deletion = true
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
