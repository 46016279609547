<template>
    <!-- Inspect-able List -->
    <div
        id="inspectable-list"
        class="col-sm-12 nopads"
    >
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
                <span class="item-title-add">{{ $t('common.add_new') }}</span>
                <font-awesome-icon
                    icon="check-square"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="results && results.length"
                id="accordion"
                class="col-sm-12 panel-group data-container"
            >
                <div
                    v-for="result of results"
                    :key="result.id"
                    v-b-toggle="'accordion-'+result.id"
                    class="data-list-item"
                >
                    <div class="col-sm-12 item-header">
                        <div class="item-img-cont">
                            <font-awesome-icon icon="check-square"/>
                        </div>
                        <div
                            class="item-text-cont"
                        >
                            <span class="item-title-single">{{ result.name }}</span>
                        </div>
                    </div>
                    <b-collapse
                        :id="'accordion-'+result.id"
                        class="mt-2 item-details"
                    >
                        <div class="row nopads">
                            <div class="item-detail-container col-sm-12">
                                <div class="item-detail-title">
                                    {{ $t('inspectable.type') }}
                                </div>
                                <div class="item-detail-text">
                                    {{ $t(`inspectable.types.${types[result.type]}`) }}
                                </div>

                                <template v-if="types[result.type] === 'number' && result.range != null">
                                    <div class="item-detail-title">
                                        {{ $t('inspectable.number.range') }}
                                    </div>
                                    <div
                                        v-if="result.range.min != null"
                                        class="item-detail-text"
                                    >
                                        {{ $t('inspectable.number.min', result.range) }}
                                    </div>
                                    <div
                                        v-if="result.range.max != null"
                                        class="item-detail-text"
                                    >
                                        {{ $t('inspectable.number.max', result.range) }}
                                    </div>
                                </template>

                                <template v-if="['select_multi', 'select_single'].includes(types[result.type])">
                                    <div class="item-detail-title">
                                        {{ $t('inspectable.select.items') }}
                                    </div>
                                    <div class="row">
                                        <div
                                            class="item-detail-list col-sm-6 col-md-4 col-lg-3 col-xl-2 inline nopads"
                                            v-for="(item, index) in result.selectable_items"
                                            :key="index"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="col-sm-12 button-container">
                                <b-button
                                    variant="primary"
                                    class="routa-button"
                                    @click.stop="$emit('edit', result)"
                                >
                                    <i class="fa fa-edit"/>
                                    <span class="button-text">{{ $t('common.edit') }}</span>
                                </b-button>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {types} from "../../../modules/Inspectable";

export default {
    name: 'InspectionList',
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            types
        }
    },
}
</script>
