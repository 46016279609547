import { side as velhoSide, locationDeviationCenterArea, deviations} from "./VelhoMixin"
import {drainTypes} from "./DrainMixin"

export const velhoRequiredMixin = {
    data() {
        return {}
    },
    methods: {
        /**
         * Validates whether the location detail is 'Keskialue' or the side is 'p03'.
         * @param {string} centerArea
         * @param {number} side
         * @returns {boolean}
         */
        roadSignTrackRequired: function(centerArea, side) {
            return centerArea ===  locationDeviationCenterArea.CENTRAL_AREA || side === velhoSide.BETWEEN
        },
        /**
         * Validates whether the side is 'p03'.
         * @param {number} side
         * @returns {boolean}
         */
        roadPostTrackRequired: function(side) {
            return side === velhoSide.BETWEEN
        },
        /**
         * Validates whether side is required for the road sign.
         * @param {number|null} locationDeviation
         * @param {number} roadNumber
         * @returns {boolean}
         */
        postRoadsideRequired: function(locationDeviation, roadNumber) {
            if(locationDeviation === deviations.AT_THE_END_OF_THE_ROAD) { return false }

            if(locationDeviation !== null && locationDeviation !== deviations.AT_THE_END_OF_THE_ROAD) { return true }
            if((roadNumber >= 20000 && roadNumber <= 30000) || roadNumber > 70000) { return true }

            return false
        },
        /**
         * Validates whether side is required for the road sign.
         * @param {number|null} locationDeviation
         * @param {number} roadNumber
         * @returns {boolean}
         */
        roadSignSideRequired: function(locationDeviation, roadNumber) {
            if(locationDeviation === deviations.AT_THE_END_OF_THE_ROAD) { return false }

            if(locationDeviation !== null && locationDeviation !== deviations.AT_THE_END_OF_THE_ROAD) { return true }
            if((roadNumber >= 20000 && roadNumber <= 39999) || roadNumber > 70000) { return true }

            return false
        },
        /**
         * Validates whether color is required for the road sign.
         * @param {{icon: string, name: string, value: string} | null} sign
         * @returns {boolean}
         */
        roadSignColorRequired: function(sign) {
            if(sign && (sign.value.toUpperCase().startsWith("F") || sign.value.toUpperCase().startsWith("G"))) { return true }

            return false
        },
        /**
         * Validates whether side is required (generic).
         * @param {number|null} locationDeviation
         * @returns {boolean}
         */
        sideRequired: function(locationDeviation) {
            return locationDeviation !== deviations.AT_THE_END_OF_THE_ROAD
        },
        /**
         * Validates whether the drain is storm drain.
         * @param {number|null} type
         * @returns {boolean}
         */
        isStormDrain: function(type) {
            return type === drainTypes.COMBINED_STORM_WATER_SEWER_DRAIN || type === drainTypes.STORM_SEWER
        },
    }
}