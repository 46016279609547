<template>
    <div class="col-sm-12 form-data">
        <b-row class="nopads">
            <div class="col-sm-6 nopads pr-1">
                <span class="span-title">
                  {{ $t('traffic_control.start_time') }}
                </span>
                <b-form-input
                    id="start"
                    :value="timestampToDateInput(trafficControlItemUpdate.start_time)"
                    type="date"
                    size="sm"
                    @change="updateStartTime"
                />
            </div>
            <div class="col-sm-6 nopads pr-1">
                <span class="span-title">
                  {{ $t('traffic_control.end_time') }}
                </span>
                <b-form-input
                    id="end"
                    :value="timestampToDateInput(trafficControlItemUpdate.end_time)"
                    type="date"
                    size="sm"
                    @change="updateEndTime"
                />
            </div>
        </b-row>
        <b-row class="nopads">
            <open-contract-filter
                :from="now"
                :showOnlyMyContractsOption="true"
                :contract="contract"
                :show-title="true"
                :show-company="false"
                @contractSelected="setContract"/>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-12 nopads">
                <b-form-group
                    class="nopads"
                    label-for="description"
                >
                    <span class="span-title">{{ $t('common.description') }}</span>
                    <b-form-textarea
                        id="description"
                        v-model="description"
                        rows="4"
                        max-rows="6"
                        size="sm"
                        :placeholder="$t('common.description')"
                        type="text"/>
                </b-form-group>
            </div>
        </b-row>
        <div class="col-sm-12 button-container pt-2 pb-0 mb-0">
            <b-button
                variant="primary"
                class="result-button"
                @click.stop="$emit('close')"
            >
                {{ $t('common.back') }}
            </b-button>
            <b-button
                variant="success"
                class="form-button"
                @click.stop="submitTrafficControl"
            >
                {{ $t('common.save') }}
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>

import {restApi} from '@/components/mixins/RestApiMixin'

import OpenContractFilter from "@/components/contract/OpenContractFilter.vue";
import {EventBus} from "../../event-bus";

export default {
    name: "TrafficControlEditor",
    components: {
        OpenContractFilter
    },
    mixins: [
        restApi,
    ],
    props: {
        trafficControlItem: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            trafficControlItemUpdate: JSON.parse(JSON.stringify(this.trafficControlItem)),
            description: '',
            contract: null
        }
    },
    mounted() {
        this.description = this.trafficControlItemUpdate.description ? this.trafficControlItemUpdate.description : ""
        this.contract = this.trafficControlItemUpdate.contract && this.trafficControlItemUpdate.contract.id ? this.trafficControlItemUpdate.contract : null
    },
    computed: {
        now() {
            return new Date().toISOString();
        }
    },
    methods: {
        submitTrafficControl: function () {
            var json = this.setDefaultValues(this.trafficControlItemUpdate)
            if (json !== undefined) {
                this.loading = true
                if (!json.id || json.id < 1) {
                    this.restAdd(this.trafficControlUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.trafficControlUrl, json, this.success, this.fail)
                }
            }
        },
        setDefaultValues: function (trafficControlItemUpdate) {
            let trafficControlItem = JSON.parse(JSON.stringify(trafficControlItemUpdate))
            trafficControlItem.contract = this.contract
            if (trafficControlItem.start_time && (trafficControlItem.start_time instanceof Date)) {
                trafficControlItem.start_time = trafficControlItem.start_time.toISOString()
            }
            if (trafficControlItem.end_time && (trafficControlItem.end_time instanceof Date)) {
                trafficControlItem.end_time = trafficControlItem.end_time.toISOString()
            }
            trafficControlItem.description = this.description
            return trafficControlItem
        },
        success: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_add_success'), 'success')
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        updateStartTime(event) {
            if (event) {
                this.trafficControlItemUpdate.start_time = new Date(event)
            } else {
                this.trafficControlItemUpdate.start_time = undefined
            }
        },
        updateEndTime(event) {
            if (event) {
                this.trafficControlItemUpdate.end_time = new Date(event)
            } else {
                this.trafficControlItemUpdate.end_time = undefined
            }
        },
        setContract(contract) {
            this.contract = contract ? contract : null
        }
    }
}

</script>

<style>
@import "../../styles/modal.css";
</style>
