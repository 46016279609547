<template>
    <div class="pt-2">
        <transition name="fade">
            <work-time-mode-list
                v-if="!editMode"
                :results="results"
                @add="add"
                @edit="edit"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <b-modal
            v-model="editMode"
            class="mt-4"
            size="lg"
            body-class="fill-modal"
            :hide-header="true"
            :hide-footer="true"
        >
            <work-time-mode-editor
                    :is-admin="isAdmin"
                    :mode="workTimeMode"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
        </b-modal>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import WorkTimeModeList from './WorkTimeModeList'
import WorkTimeModeEditor from './WorkTimeModeEditor'

export default {
    name: 'WorkTimeModes',
    components: {WorkTimeModeList, WorkTimeModeEditor},
    mixins: [restApi, workManagementHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            workTimeMode: null,
            editMode: false,
            loading: false,
            searchCompanyId: null,
            company: null
        }
    },
    watch: {
        company: function () {
            this.fetchItems()
        }
    },
    mounted: function () {
        if (!this.isAdmin) {
            this.fetchItems()
        }
    },
    methods: {
        fetchItems: function (companyId) {
            this.loading = true
            this.results = []
            this.searchCompanyId = companyId
            this.restFetchParams(this.workTimeModeUrl, {company: this.searchCompanyId}, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        add: function () {
            this.workTimeMode = this.newWorkTimeMode()
            this.editMode = true
        },
        edit: function (wtMode) {
            this.workTimeMode = wtMode
            this.editMode = true
        },
        showList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.showList()
            this.fetchItems(this.searchCompanyId)
        }
    }
}
</script>
