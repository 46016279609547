<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="groupUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('area_groups.edit') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('area_groups.add') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <company-filter
                    v-if="isAdmin"
                    v-model="groupUpdate.company.id"
                    v-on:input="onCompanyChanged"
                />

                <span class="span-title">
          {{ $t('area_groups.name') }}
        </span>
                <b-form-group
                    class="span-title"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="groupUpdate.name"
                        type="text"
                        size="mb"
                    />
                </b-form-group>

                <span class="span-title">
          {{ $t('area_groups.selected_areas') }}
        </span>
                <b-form
                    v-if="groupUpdate.areas.length > 0"
                    inline
                >
                    <div class="col-sm-12 inspectable-container">
                        <div
                            v-for="item in groupUpdate.areas"
                            :key="item.id"
                            :class="{'d-inline' : true}"
                        >
              <span
                  class="inspectables inspectables-selected"
                  @click="toggleAreaSelection(item)"
              >
                {{ item.name }}
              </span>
                        </div>
                    </div>
                </b-form>
                <div
                    v-else
                    class="col-sm-12 small-pad"
                >
          <span>
            {{ $t('area_groups.no_areas_selected') }}
          </span>
                </div>

                <span
                    v-if="groupUpdate.areas.length !== areas.length"
                    class="span-title"
                >
          {{ $t('area_groups.available_areas') }}
        </span>
                <div class="col-sm-12 inspectable-container">
                    <div
                        v-for="item in areas"
                        :key="item.id"
                        :class="[ isAreaSelected(item.id) ? 'hidden' : 'd-inline' ]"
                    >
            <span
                class="inspectables"
                @click="toggleAreaSelection(item)"
            >
              {{ item.name }}
            </span>
                    </div>
                </div>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading || groupUpdate.name === '' || groupUpdate.areas.length <= 0"
                        @click.stop="submitAreaGroup"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import CompanyFilter from '../../company/CompanyFilter'

export default {
    name: 'AreaGroupEditor',
    components: {CompanyFilter},
    mixins: [restApi],
    props: {
        areaGroup: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            areas: [],
            groupUpdate: JSON.parse(JSON.stringify(this.areaGroup))
        }
    },
    mounted: function () {
        if (!this.isAdmin || (this.groupUpdate.company !== null && this.groupUpdate.company.id != null)) {
            this.fetchAreas()
        }
    },
    methods: {

        onCompanyChanged: function () {
            this.groupUpdate.areas = []
            this.fetchAreas()
        },

        fetchAreas: function () {
            this.areas = []
            if (this.isAdmin) {
                if (this.groupUpdate.company.id !== null) {
                    this.loading = true
                    this.restFetchParams(this.areaUrl, {company: this.groupUpdate.company.id}, this.handleAreaFetchResponse)
                }
            } else {
                this.loading = true
                this.restFetchParams(this.areaUrl, {no_geom: 1}, this.handleAreaFetchResponse)
            }
        },

        handleAreaFetchResponse: function (response) {
            this.loading = false
            this.areas = response.data
        },

        toggleAreaSelection: function (area) {
            var index = this.groupUpdate.areas.findIndex(function (obj) {
                return obj.id === area.id
            })
            if (index < 0) {
                this.groupUpdate.areas.push(area)
            } else {
                this.groupUpdate.areas.splice(index, 1)
            }
        },

        isAreaSelected: function (id) {
            return this.groupUpdate.areas.some(obj => obj.id === id)
        },

        checkValues: function (group) {
            if (group.name === '') {
                EventBus.$emit('show-alert', this.$t('area_groups.invalid_name'))
                return undefined
            }
            if (group.areas.length <= 0) {
                EventBus.$emit('show-alert', this.$t('area_groups.invalid_areas'))
                return undefined
            }
            return group
        },

        submitAreaGroup: function () {
            let areaGroupJson = JSON.parse(JSON.stringify(this.checkValues(this.groupUpdate)))
            if (areaGroupJson !== undefined) {
                this.loading = true
                areaGroupJson.areas = areaGroupJson.areas.map(obj => {
                    return {id: obj.id}
                })
                if (this.groupUpdate.id < 1) {
                    this.restAdd(this.areaGroupUrl, areaGroupJson, this.areaGroupSubmitSuccess, this.areaGroupSubmitFail)
                } else {
                    this.restUpdate(this.areaGroupUrl, areaGroupJson, this.areaGroupSubmitSuccess, this.areaGroupSubmitFail)
                }
            }
        },

        areaGroupSubmitSuccess: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        areaGroupSubmitFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>
