<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12">
                <div class="col-sm-6">
                    <span class="span-title">
                      {{ $t('certificate.certificate_type') }}
                    </span>
                    <b-form-select
                        id="types"
                        v-model="certificateUpdate.type"
                        :disabled="certificateTypes.length < 1"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('certificate.select_type') }}
                            </option>
                        </template>
                        <option
                            v-for="option in certificateTypes"
                            :key="option.id"
                            :value="option"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </div>
                <div class="col-sm-6">
                    <span class="span-title">
                      {{ $t('certificate.granted_day') }}
                    </span>
                    <b-form-input
                        id="granted_day"
                        v-model="certificateUpdate.granted_day"
                        type="date"
                        size="sm"
                    />
                </div>
                <div class="col-sm-6" v-if="certificateUpdate.type && certificateUpdate.type.is_expiring">
                    <span class="span-title">
                      {{ $t('certificate.expiration_day') }}
                    </span>
                    <b-form-input
                        id="expiration_day"
                        v-model="certificateUpdate.expiration_day"
                        type="date"
                        size="sm"
                    />
                </div>

                <div class="col-sm-6 pt-2">
                    <b-form-group label-for="companies">
                        <b-form-checkbox
                            v-model="planned"
                            size="md"
                            type="text"
                            value=1
                            unchecked-value=0>
                            {{ $t('certificate.certificate_planned') }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>

                <div class="col-sm-6" v-if="user === null">
                    <span class="search-title">{{ $t('certificate.users') }}</span>
                    <multiselect
                        v-model="selectedUsers"
                        :placeholder="$t('certificate.select_users')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="false"
                        :multiple="true"
                        label="name"
                        track-by="value"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="resultsUsers"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>

                <div class="col-sm-12 button-container">
                    <b-button
                        v-if="certificate.id >= 1 "
                        variant="danger"
                        class="routa-button"
                        @click.stop="deleteCertificate"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.remove') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="!canSubmit"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
                <div
                    v-if="loading"
                    id="loader"
                    class="spinner"
                />
            </div>
        </b-form>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '.././mixins/RestApiMixin'
import {timeUtils} from '.././mixins/TimeUtils'

export default {
    name: 'CertificateEditor',
    mixins: [restApi, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        results: {
            type: Array,
            default: function () {
                return []
            }
        },
        certificate: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            planned: 0,
            selectedUsers: [],
            resultsUsers: [],
            certificateTypes: [],
            certificateUpdate: this.formatObj(this.certificate)
        }
    },
    computed: {
        canSubmit: function () {
            if (this.loading || !this.certificateUpdate.type) {
                return false
            }
            if (!this.certificateUpdate.granted_day) {
                return parseInt(this.planned) === 1
            }
            if (!this.certificateUpdate.expiration_day && this.certificateUpdate.type.is_expiring) {
                return false
            }
            if (!this.user && this.selectedUsers.length < 1) {
                return false
            }
            return true
        }
    },
    watch: {
      planned: function () {
        if (parseInt(this.planned) === 1 && !this.certificateUpdate.planned_date) {
            this.certificateUpdate.planned = 1
            this.certificateUpdate.planned_date = this.getDateIsoString(new Date())
        } else {
            this.certificateUpdate.planned = 0
            this.certificateUpdate.planned_date = null
        }
      }
    },
    mounted: function () {
        this.fetchCertificateTypes()
        this.planned = this.certificateUpdate.planned && this.certificateUpdate.planned === true  ? 1 : 0
        //Set up the users to be displayed in the multiselect
        this.resultsUsers = []
        this.results.forEach(user => this.resultsUsers.push({
            value: user.id,
            name: user.last_name + ', ' + user.first_name + ' - ' + user.email
        }), this)
    },
    methods: {
        formatObj: function (object) {
            let obj = JSON.parse(JSON.stringify(object))
            obj.granted_day = this.timestampToDateInput(obj.granted_day)
            obj.expiration_day = obj.expiration_day ? this.timestampToDateInput(obj.expiration_day) : undefined
            return obj
        },
        submit: function () {
            this.loading = true
            if (this.user === null) {
                //Assigning a new certificate to multiple users at once
                //Set certificate id and user to null
                let certificate = JSON.parse(JSON.stringify(this.certificateUpdate))
                certificate.id = null
                certificate.user = null

                //Create array of user ids
                let userIds = []
                this.selectedUsers.forEach(function (item) {
                    userIds.push(item.value)
                })
                //Create DTO containing certificate and users array
                let dto = {}
                dto.certificate = certificate
                dto.users = userIds
                this.restAdd(this.certificateManyUrl, dto, this.success, this.fail)
            } else {
                if (this.certificateUpdate.id < 1) {
                    //Assigning a new certificate to one user
                    let certificate = JSON.parse(JSON.stringify(this.certificateUpdate))
                    certificate.id = null
                    this.restAdd(this.certificateUrl, certificate, this.success, this.fail)
                } else {
                    //Updating one user's certificate
                    this.restUpdate(this.certificateUrl, this.certificateUpdate, this.success, this.fail)
                }
            }
        },
        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        deleteCertificate: function () {
            this.loading = true
            this.restDelete(this.certificateUrl + '?id=' + this.certificateUpdate.id, this.success, this.fail)
        },
        fetchCertificateTypes: function () {
            this.loading = true
            this.restFetch(this.certificateTypeUrl, this.handleTypeResponse)
        },
        handleTypeResponse: function (response) {
            this.certificateTypes = response.data
            this.loading = false
        }
    }
}
</script>
