<template>
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <div
                v-if="results && results.length"
                id="accordion"
                class="col-sm-12 panel-group data-container"
            >
                <div
                    v-for="result of results"
                    :key="result.id"
                    class="data-list-item"
                >
                    <b-progress :max="100" style="border-radius: 0">
                        <b-progress-bar :value="result.progress_value" :label="`${((result.progress_value / 100) * 100).toFixed(0)} %`"></b-progress-bar>
                    </b-progress>
                    <div class="col-sm-12 item-header" v-b-toggle="'accordion-'+result.id">
                        <div class="item-img-cont">
                            <font-awesome-icon icon="tasks"/>
                        </div>
                        <span class="item-title">
                            {{ getTitle(result) }}
                        </span>
                        <span
                            class="item-text"
                            v-bind:class="{new : result.status === 3, warning : result.status === 2}"
                        >
                            {{ getStatusString(result.status) }}
                        </span>
                        <div class="item-text">
                            {{ $t('work_assignment.select_priority') }}:
                            <span
                                v-bind:class="{completed : result.priority_value === 1, warning : result.priority_value === 2, ban : result.priority_value === 3}"
                            >
                                {{ getPriorityString(result.priority_value) }}
                            </span>
                        </div>
                    </div>
                    <b-collapse
                        :id="'accordion-'+result.id"
                        class="mt-2 item-details p-3"
                    >
                        <div class="col-sm-12 nopads">
                            <div class="item-detail-title">
                                {{ $t('work_assignment.info') }}
                            </div>
                            <div class="item-detail-text">
                                <span v-if="result.info">{{ result.info }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                        <!-- Observation data -->
                        <work-assignment-observation
                            v-if="result.observation"
                            :observation="result.observation"/>
                        <!-- Area defect data -->
                        <work-assignment-area-defect
                            v-if="result.area_defect"
                            :defect="result.area_defect"/>
                        <!-- Vehicle defect data -->
                        <work-assignment-vehicle-defect
                            v-if="result.vehicle_defect"
                            :defect="result.vehicle_defect"/>
                        <!-- Buttons -->
                        <div class="col-sm-12 button-container">
                            <div style="display: inline-block" v-if="result.status === IN_PROGRESS">
                                <div class="item-detail-text">
                                    <b-row>
                                        <b-col sm="4" class="nopads">
                                            <label>{{ $t('work_assignment.work_progress') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input @change="updateAssignmentProgress(result)" v-model="result.progress_value" type="range" min="0" max="100"></b-form-input>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>

                            <b-button
                                v-if="result.status === OPEN"
                                variant="outline-primary"
                                class="result-button"
                                @click.stop="assignAssignment(result)"
                            >
                                <span class="button-text">{{ $t('work_assignment.self_assign') }}</span>
                            </b-button>
                            <b-button
                                v-if="result.status === ASSIGNED || result.status === OPEN"
                                variant="primary"
                                class="result-button"
                                @click.stop="startAssignment(result)"
                            >
                                <span class="button-text">{{ $t('work_assignment.start') }}</span>
                            </b-button>
                            <b-button
                                variant="success"
                                class="result-button"
                                @click.stop="completeAssignment(result)"
                            >
                                <span class="button-text">{{ $t('work_assignment.ready') }}</span>
                            </b-button>
                        </div>

                    </b-collapse>
                </div>
            </div>
        </transition>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'
import {workAssignmentHelper} from '../mixins/WorkAssignmentMixin'
import WorkAssignmentObservation from './WorkAssignmentObservation'
import WorkAssignmentAreaDefect from './WorkAssignmentAreaDefect'
import WorkAssignmentVehicleDefect from './WorkAssignmentVehicleDefect'
import {EventBus} from '@/event-bus'

export default {
    name: 'WorkAssignmentUserList',
    components: {WorkAssignmentVehicleDefect, WorkAssignmentAreaDefect, WorkAssignmentObservation},
    mixins: [timeUtils, workAssignmentHelper, restApi],
    data: function () {
        return {
            loading: false,
        }
    },
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        getTitle: function (item) {
            if (item.observation) {
                return item.observation.type.name
            }
            if (item.area_defect) {
                return item.area_defect.area.name + ': ' + item.area_defect.area_equipment.name
            }
            if (item.vehicle_defect) {
                return item.vehicle_defect.vehicle.license_plate + ' - ' + item.vehicle_defect.inspectable_name
            }
            return item.info
        },
        startAssignment: function (assignment) {
            this.loading = true
            this.restUpdate(this.workAssignmentStartUrl + '/' + assignment.id, {}, this.handleResponse, this.handleError)
        },
        completeAssignment: function (assignment) {
            this.loading = true
            this.restUpdate(this.workAssignmentCompleteUrl + '/' + assignment.id, {}, this.handleResponse, this.handleError)
        },
        assignAssignment: function (assignment) {
            this.loading = true
            this.restUpdate(this.workAssignmentAssignUrl + '/' + assignment.id, {}, this.handleResponse, this.handleError)
        },
        updateAssignmentProgress: function (assignment) {
            this.restUpdate(this.workAssignmentProgressUrl + assignment.id,
                {progress: assignment.progress_value}, function () {}, this.handleError)
        },
        handleResponse: function () {
            this.loading = false
            this.$emit('closeAndUpdate')
        },
        handleError: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        }
    }
}
</script>
