<template>
    <div class="content-container">
        <b-navbar variant="faded">
            <div class="header">
        <span
            v-if="isAdmin"
            class="title"
        >
          {{ $t('relationships.admin_title') }}
        </span>
                <span
                    v-else
                    class="title"
                >
          {{ $t('relationships.title') }}
        </span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <company-relationship-list
                    v-if="!editMode"
                    :results="results"
                    @add="addRelationship"
                    @update="fetchCompanyRelationships"
                />
            </transition>
            <transition name="fade">
                <company-relationship-editor
                    v-if="editMode"
                    @close="closeEditor"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../../mixins/RestApiMixin'
import {workManagementHelper} from '../../mixins/WorkManagementMixin'
import companyRelationshipList from './CompanyRelationshipList'
import companyRelationshipEditor from './CompanyRelationshipEditor'

export default {
    name: 'CompanyRelationships',
    components: {companyRelationshipList, companyRelationshipEditor},
    mixins: [restApi, workManagementHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            loading: true,
            editMode: false
        }
    },
    mounted: function () {
        this.fetchCompanyRelationships()
    },
    methods: {

        fetchCompanyRelationships: function () {
            this.loading = true
            this.results = []
            this.restFetch(this.companyRelationshipUrl, this.handleResponse)
        },

        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },

        addRelationship: function () {
            this.loading = false
            this.editMode = true
        },

        closeEditor: function () {
            this.editMode = false
            this.fetchCompanyRelationships()
        },

        deleteRelationship: function () {
            // TODO
        }

    }
}
</script>
