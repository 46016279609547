<template>
    <div class="col-sm-12 nopads">
        <div class="road-info-title">
            <span> {{ $t('road_details.traffic_volumes') }}</span>
        </div>
        <b-table
            v-if="trafficInfo.length > 0"
            small
            responsive
            borderless
            class="border-bottom"
            :items="data"
            :fields="fields"
        />
        <div
            v-else
            class="col-sm-12"
        >
            <div class="name full-width">
                <span> {{ $t('road_details.no_info') }}</span>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'RoadLinkTrafficInfo',
    props: {
        trafficInfo: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            data: [],
            fields: [
                {
                    AnnualDailyAvg: {
                        label: this.$t('road_details.annual_avg'),
                        sortable: false
                    }
                },
                {
                    AnnualWeekDayAvg: {
                        label: this.$t('road_details.week_day_avg'),
                        sortable: false
                    }
                },
                {
                    SummerDailyAvg: {
                        label: this.$t('road_details.summer_day_avg'),
                        sortable: false
                    }
                },
                {
                    SeasonalVariationClass: {
                        label: this.$t('road_details.seasonality'),
                        sortable: false
                    }
                },
                {
                    HourlyVariationClass: {
                        label: this.$t('road_details.hour_variation'),
                        sortable: false
                    }
                },
                {
                    Accuracy: {
                        label: this.$t('road_details.accuracy'),
                        sortable: false
                    }
                },
                {
                    Year: {
                        label: this.$t('road_details.accounting_year'),
                        sortable: false
                    }
                }
            ]
        }
    },

    mounted: function () {
        if (this.trafficInfo && this.trafficInfo.length > 0) {
            this.data = [{
                AnnualDailyAvg: this.getFormattedText(this.trafficInfo[0].annualDailyAvg),
                AnnualWeekDayAvg: this.getFormattedText(this.trafficInfo[0].annualWeekDayAvg),
                SummerDailyAvg: this.getFormattedText(this.trafficInfo[0].summerDailyAvg),
                SeasonalVariationClass: this.getFormattedText(this.trafficInfo[0].seasonalVariationClass),
                HourlyVariationClass: this.getFormattedText(this.trafficInfo[0].hourlyVariationClass),
                Accuracy: this.getFormattedText(this.trafficInfo[0].accuracy),
                Year: this.getFormattedText(this.trafficInfo[0].year)
            }]
        }
    },

    methods: {
        getFormattedText: function (rawText) {
            if (rawText != null && rawText !== 'null') {
                return rawText
            }
            return '-'
        }
    }
}
</script>
