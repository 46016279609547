
export const lightingHelper = {
    methods: {
        getLightingTypes: function () {
            return [
                {name: this.$t('lighting.types.street_lights'), value: lightingTypes.STREET_LIGHTS},
                {name: this.$t('lighting.types.light_traffic_lamps'), value: lightingTypes.LIGHT_TRAFFIC_LAMPS},
                {name: this.$t('lighting.types.spotlights'), value: lightingTypes.SPOTLIGHTS},
                {name: this.$t('lighting.types.bridge_and_tunnel_lighting'), value: lightingTypes.BRIDGE_AND_TUNNEL_LIGHTING},
                {name: this.$t('lighting.types.other_lighting'), value: lightingTypes.OTHER_LIGHTINGS},
            ]
        },
        getLightingLampTypes: function () {
            return [
                {name: this.$t('lighting.lamp_types.unknown'), value: lightingLampTypes.UNKNOWN},
                {name: this.$t('lighting.lamp_types.spna'), value: lightingLampTypes.SPNA},
                {name: this.$t('lighting.lamp_types.led'), value: lightingLampTypes.LED},
                {name: this.$t('lighting.lamp_types.mm'), value: lightingLampTypes.MM},
                {name: this.$t('lighting.lamp_types.other'), value: lightingLampTypes.OTHER},
                {name: this.$t('lighting.lamp_types.hq80'), value: lightingLampTypes.HQ80},
                {name: this.$t('lighting.lamp_types.hq125'), value: lightingLampTypes.HQ125},
                {name: this.$t('lighting.lamp_types.hq250'), value: lightingLampTypes.HQ250},
            ]
        },
        getLightingCableInstallationMethods: function () {
            return [
                {name: this.$t('lighting.cable_installation_method.unknown'), value: cableInstallationMethods.UNKNOWN},
                {name: this.$t('lighting.cable_installation_method.air'), value: cableInstallationMethods.AIR},
                {name: this.$t('lighting.cable_installation_method.ground'), value: cableInstallationMethods.GROUND},
                {name: this.$t('lighting.cable_installation_method.bridge'), value: cableInstallationMethods.BRIDGE},
            ]
        },
        getPostTypes: function () {
            return [
                {name: this.$t('lighting.post_types.unknown'), value: postTypes.UNKNOWN},
                {name: this.$t('lighting.post_types.rigid_wooden_pole'), value: postTypes.RIGID_WOODEN_POLE},
                {name: this.$t('lighting.post_types.breakable_wooden_pole'), value: postTypes.BREAKABLE_WOODEN_POLE},
                {name: this.$t('lighting.post_types.rigid_steel_pole'), value: postTypes.RIGID_STEEL_POLE},
                {name: this.$t('lighting.post_types.steel_post_with_sliding_flange'), value: postTypes.STEEL_POST_WITH_SLIDING_FLANGE},
                {name: this.$t('lighting.post_types.stopping_steel_post'), value: postTypes.STOPPING_STEEL_POST},
                {name: this.$t('lighting.post_types.reinforced_plastic_post'), value: postTypes.REINFORCED_PLASTIC_POST},
            ]
        },
        getPostSafetyClasses: function () {
            return [
                {name: this.$t('lighting.safety_classes.unknown'), value: postSafetyClasses.UNKNOWN},
                {name: this.$t('lighting.safety_classes.rigid_pole'), value: postSafetyClasses.RIGID_POLE},
                {name: this.$t('lighting.safety_classes.stopping_bending_pole'), value: postSafetyClasses.STOPPING_BENDING_POLE},
                {name: this.$t('lighting.safety_classes.post_that_recedes_detaches_from_its_base_or_breaks'), value: postSafetyClasses.POST_THAT_RECEDES_DETACHES_FROM_ITS_BASE_OR_BREAKS},
                {name: this.$t('lighting.safety_classes.receding_completely_breaking_post'), value: postSafetyClasses.RECEDING_COMPLETELY_BREAKING_POST},
                {name: this.$t('lighting.safety_classes.yielding_or_protected_by_a_railing'), value: postSafetyClasses.YIELDING_OR_PROTECTED_BY_A_RAILING},
            ]
        },
    }
}

export const lightingTypes = {
    STREET_LIGHTS: 1,
    LIGHT_TRAFFIC_LAMPS: 2,
    SPOTLIGHTS: 3,
    BRIDGE_AND_TUNNEL_LIGHTING: 4,
    OTHER_LIGHTINGS: 5,
}

export const lightingLampTypes = {
    UNKNOWN: 1,
    SPNA: 2,
    LED: 3,
    MM: 4,
    OTHER: 5,
    HQ80: 6,
    HQ125: 7,
    HQ250: 8
}

export const cableInstallationMethods = {
    UNKNOWN: 1,
    AIR: 2,
    GROUND: 3,
    BRIDGE: 4,
}

export const postTypes = {
    UNKNOWN: 1,
    RIGID_WOODEN_POLE: 2,
    BREAKABLE_WOODEN_POLE: 3,
    RIGID_STEEL_POLE: 4,
    STEEL_POST_WITH_SLIDING_FLANGE: 5,
    STOPPING_STEEL_POST: 6,
    REINFORCED_PLASTIC_POST: 7
}

export const postSafetyClasses = {
    UNKNOWN: 1,
    RIGID_POLE: 2,
    STOPPING_BENDING_POLE: 3,
    POST_THAT_RECEDES_DETACHES_FROM_ITS_BASE_OR_BREAKS: 4,
    RECEDING_COMPLETELY_BREAKING_POST: 5,
    YIELDING_OR_PROTECTED_BY_A_RAILING: 6,
}

