<template>
    <div class="details-container">
        <div class="dynamic_width nopads" style="background-color: #FFFFFF; margin: auto">
            <div style="position: relative; height: 55vh; padding: 1em; text-align: left;">
                <map-container
                    ref="mapContainer"
                    :find-user="false"
                    :center="mapCenter"
                    @onMapChanged="mapChanged"
                />
                <div v-if="addShape" class="col-12 file-dialog" style="z-index: 2999">
                    <shape-import-form
                        @filePicked="shapeFileSelected"
                        @cancel="$emit('close')"/>
                    <div>
                        <span class="span-title pl-2">{{ $t('import_file_wizard.use_coordinate_system') }}: ETRS-TM35FIN (epsg: 3067)</span>
                    </div>
                    <div class="button-container pr-1">
                        <b-button
                            variant="secondary"
                            size="sm"
                            class="form-button"
                            @click.stop="cancel()"
                        >
                            {{ $t('common.cancel') }}
                        </b-button>
                        <b-button
                            variant="success"
                            size="sm"
                            class="form-button"
                            :disabled="!shpFile || !shxFile || !dbfFile || loading"
                            @click.stop="showGeometryOnMap"
                        >
                            {{ $t('orders.show_geometry_on_map') }}
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="button-container pr-1 pb-2">
                <b-button
                    variant="secondary"
                    size="sm"
                    class="form-button"
                    @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    size="sm"
                    class="form-button"
                    @click.stop="addItems"
                >
                    {{ $t('storm_sewers.add_to_system') }}
                </b-button>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from "@/components/mixins/MapMixin";
import ShapeImportForm from "@/components/shapeimport/ShapeImportForm";
import MapContainer from "@/components/map/MapContainer";
import {EventBus} from "@/event-bus";

export default {
    name: 'StormDrainImporter',
    components: {MapContainer, ShapeImportForm},
    mixins: [restApi, mapHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            addShape: true,
            loading: false,
            mapCenter: null,
            shpFile: null,
            shxFile: null,
            dbfFile: null,
            geoJson: null
        }
    },
    mounted() {
        if(this.getMapSettingsIn(['center'])){
            let center = this.getMapSettingsIn(['center'])
            this.mapCenter = {
                y: center.lat,
                x: center.lng
            }
        }
        this.$nextTick(() => {
            this.map = this.$refs.mapContainer.getMap()
        })
    },

    methods: {
        mapChanged(map) {
            this.map = map
        },
        shapeFileSelected: function (shpFile, shxFile, dbfFile) {
            this.shpFile = shpFile
            this.shxFile = shxFile
            this.dbfFile = dbfFile
        },
        showGeometryOnMap: function () {
            let formData = new FormData()
            let files = [this.shpFile, this.shxFile, this.dbfFile]
            files.forEach(file => {
                if (file !== undefined && file !== null) {
                    formData.append('files[]', file)
                }
            })
            this.loading = true
            this.restPostWithFile(this.convertShapeToGeoJsonUrl, {}, formData, this.handleGeoJson, this.onFailedRequest)
        },
        handleGeoJson: function(geoJson){
            this.geoJson = geoJson
            this.map.addGeoJsonObjects(geoJson, true, '#1a6fb9')
            this.addShape = false
            this.loading = false
        },
        onFailedRequest() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('import_file_wizard.import_failed'))
        },
        addItems() {
            if (this.geoJson) {
                this.loading = true
                this.restAdd(this.stormDrainBulkUrl, {geoJson: this.geoJson}, this.handleAdd, this.addFailed)
            }
        },
        handleAdd() {
            this.loading = false
            this.$emit('close')
        },
        addFailed() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('storm_sewer.sewer_add_failed'))
        },
    }
}
</script>
