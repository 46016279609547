<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 nopads">
        <transition name="fade">
            <div class="list-content result-table">
                <b-table
                    show-empty
                    small
                    striped
                    style="cursor:pointer;"
                    @row-clicked="rowClicked"
                    :items="powerStations"
                    :fields="fields"
                >
                    <template v-slot:cell(created_at)="row">
                        {{ getDateString(row.value) }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.value.name }}
                    </template>
                    <template v-slot:cell(status)="row">
                        {{ getStatusString(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>

import {timeUtils} from "@/components/mixins/TimeUtils";
import {streetLightHelper} from "@/components/mixins/StreetLightMixin";

export default {
    name: 'PowerStationList',
    mixins: [timeUtils, streetLightHelper],
    props: {
        powerStations: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'id',
            sortDesc: false
        }
    },
    computed: {
        fields() {
            return [
                {key: 'id', label: this.$t('common.id'), sortable: true},
                {key: 'location_name', label: this.$t('common.name'), sortable: true},
                {key: 'created_at', label: this.$t('road_sign.created_at'), sortable: true},
                {key: 'street', label: this.$t('power_stations.street'), sortable: true},
                {key: 'city', label: this.$t('power_stations.city'), sortable: true},
                {key: 'plate_value', label: this.$t('power_stations.plate_value'), sortable: true},
                {key: 'next_inspection_year', label: this.$t('power_stations.next_inspection_year'), sortable: true},
                {key: 'contract', label: this.$t('orders.contract'), sortable: true},
            ]
        }
    },
    methods: {
        rowClicked: function (details) {
            this.$emit('rowClicked', details)
        },
        getFormattedValue: function (value) {
            if (!value || value < 1) {
                return ""
            }
            return value
        }
    }
}
</script>
