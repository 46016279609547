<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="onReset"
        >
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                        <span class="span-title">{{ $t('orders.status') }}</span>
                        <b-form-select
                            id="status"
                            v-model="status"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="0">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option :value="1">
                                {{ $t('inspection.options.open') }}
                            </option>
                            <option :value="2">
                                {{ $t('inspection.options.okay') }}
                            </option>
                            <option :value="3">
                                {{ $t('inspection.options.defects') }}
                            </option>
                            <option :value="4">
                                {{ $t('inspection.options.ban') }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="area"
                    >
                        <span class="span-title">{{ $t('areas.area') }}</span>
                        <b-form-select
                            id="area"
                            v-model="area"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option
                                v-for="a in areas"
                                :key="a.id"
                                :value="a.id"
                            >
                                {{ a.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="reporter"
                    >
                        <span class="span-title">{{ $t('defects.reporter') }}</span>
                        <b-form-select
                            id="reporter"
                            v-model="reporter"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option
                                v-for="r in reporters"
                                :key="r.id"
                                :value="r.id"
                            >
                                {{ r.first_name }} {{ r.last_name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="start"
                    >
                        <span class="span-title">{{ $t('orders.create_time') }} - {{
                                $t('trip_list.road_sec_from')
                            }}</span>
                        <b-form-input
                            id="start"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="end"
                    >
                        <span class="span-title">{{ $t('orders.create_time') }} - {{
                                $t('trip_list.road_sec_to')
                            }}</span>
                        <b-form-input
                            id="end"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'AreaDefectSearch',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            status: 1,
            area: -1,
            areas: [],
            fromDate: null,
            toDate: null,
            reporter: -1,
            reporters: []
        }
    },
    mounted: function () {
        this.fetchAreas()
        this.fetchReporters()
    },
    methods: {
        fetchAreas: function () {
            this.loading = true
            this.restFetch(this.areaDefectAreasUrl, this.handleAreas)
        },

        handleAreas: function (response) {
            this.loading = false
            this.areas = response.data
        },

        fetchReporters: function () {
            this.loading = true
            this.restFetch(this.areaDefectReporterUrl, this.handleReporters)
        },

        handleReporters: function (response) {
            this.loading = false
            this.reporters = response.data
        },

        onSubmit: function () {
            var params = {}
            params.status = this.status
            if (this.area > 0) {
                params.area = this.area
            }
            if (this.reporter > 0) {
                params.reporter = this.reporter
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.dateFrom = this.localTimeToUtcTime(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.dateTo = this.getEndOfTheDay(this.toDate).getTime()
            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.status = 1
            this.area = -1
            this.reporter = -1
            this.fromDate = null
            this.toDate = null
        }
    }
}
</script>

<style scoped>

</style>
