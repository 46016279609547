<template>
    <base-modal v-if="showMapModal" style="z-index: 50">

        <div class="details-container">
            <div
                class="details dynamic_width"
            >
                <div class="coordinate-map"
                     style="height:80vh;"
                >
                    <marker-editor-map
                        :lat="lat"
                        :lon="lon"
                        :type="USER_LOCATION"
                        :fresh="true"
                        :draggable="false"
                    />
                </div>
                <div class="col-sm-12 button-container pt-2 pb-0 mb-0">

                    <b-button
                        variant="danger"
                        class="result-button"
                        @click.stop="closeModal"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                </div>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />

    </base-modal>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import BaseModal from "@/components/BaseModal.vue";
import MarkerEditorMap from "@/components/map/MarkerEditorMap.vue";
import {mapHelper} from '../mixins/MapMixin'


export default {
    name: 'InspectionMap',
    components: {BaseModal, MarkerEditorMap},
    mixins: [restApi, mapHelper],
    props: {
        center: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            showMapModal: false,
            loading: false,
            lat: null,
            lon: null,
        }
    },

    watch: {
      center(val) {
          this.updateInspectionLocation(val)
      }
    },

    methods: {
        closeModal: function () {
            this.showMapModal = false
        },

        showModal: function () {
            this.showMapModal = true
        },

        updateInspectionLocation: function (val) {
            if (val === undefined) {
                return
            }
            this.lat = val.y
            this.lon = val.x

        }

    }

}
</script>
