<template>
    <b-modal
        ref="coordinateModal"
        centered
        hide-header-close
        hide-footer
        @hidden="$emit('hidden')"
        :title="title"
    >
        <coordinate-editor
            ref="coordinateEditor"
            v-model="value"
            size="sm"
            :point-type="OTHER"
            @close="$emit('hidden')"
            @setLocation="setLocation"
        />
    </b-modal>
</template>

<script>
import CoordinateEditor from "./CoordinateEditor";
import {mapHelper} from "./mixins/MapMixin";

export default {
    name: "CoordinateEditorModal",
    mixins: [mapHelper],
    components: {CoordinateEditor},
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Object
        },
        pointType: {
            type: Number,
            default: null
        },
        title: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        async visible() {
            if (!this.visible) {
                this.$refs.coordinateModal.hide();
            }
            else {
                this.$refs.coordinateModal.show();
                await this.$nextTick();
                this.$refs.coordinateEditor.showMap = true;
            }
        }
    },
    methods: {
        updateLocation(location) {
            this.value.x = location.x
            this.value.y = location.y
            this.$forceUpdate()
        },
        setLocation(location) {
            if(location) {
                this.value.x = location.x
                this.value.y = location.y
                this.$forceUpdate()
                this.$emit('hidden')
            }
        }
    },
}
</script>
