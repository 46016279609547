<template>
    <div class="col-sm-12 nopads">
        <div class="col-sm-12 editor-form">
            <div v-if="showTitle" class="col-sm-12 form-title editor-title">
                <h2
                    v-if="sewerLine && sewerLine.id > 0"
                    class="form-title editor-title"
                >
                    {{ sewerLine.name }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('contracts.add') }}
                </h2>
            </div>

            <b-tabs v-if="sewerLine && user" justified v-model="tabIndex">
                <b-tab :title="$t('contracts.basic_info')" active :title-link-class="linkTabClass(0)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 0}">
                                {{ $t('contracts.basic_info') }}
                            </div>
                        </b-row>
                    </template>
                    <sewer-line-info
                        v-if="sewerLine != null"
                        :storm-line="sewerLine"
                        :user="user"
                        @close="close"
                        @closeAndUpdate="closeAndUpdate"
                    />
                </b-tab>
                <b-tab :title-link-class="linkTabClass(2)" :disabled="sewerLine.id < 1">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 3}">
                                {{ $t('contracts.docs') }}
                            </div>
                        </b-row>
                    </template>
                    <sewer-line-documents
                        v-if="sewerLine && sewerLine.id > 0"
                        v-model="value"/>
                </b-tab>
            </b-tabs>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <div v-if="!showTitle" class="col-12 button-container pl-2 pr-4">
            <hr/>
            <b-button size="sm" class="form-button" variant="primary" @click="closeAndUpdate">
                {{ $t('common.back') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {workManagementHelper} from "@/components/mixins/WorkManagementMixin";
import SewerLineInfo from "@/components/stormsewers/SewerLineInfo";
import SewerLineDocuments from "@/components/stormsewers/SewerLineDocuments";

export default {
    name: 'SewerLineDetails',
    components: {
        SewerLineInfo,
        SewerLineDocuments
    },
    mixins: [restApi, workManagementHelper],
    props: {
        value: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        showTitle: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            loading: false,
            sewerLine: null,
            closeOnCancel: false,
            addMessage: '',
            tabIndex: 0,
        }
    },
    mounted: function () {
        this.sewerLine = this.value
    },
    watch: {
        sewerLine(val) {
            this.$emit('input', val)
        },
    },
    methods: {
        close: function () {
            this.$emit('showList')
        },
        closeAndUpdate: function () {
            this.emitCloseAndUpdate()
        },
        emitCloseAndUpdate() {
            this.$emit('closeAndUpdate', this.sewerLine)
        },

        linkTabClass(index) {
            if (this.tabIndex === index) {
                return ['tab-title', 'tab-title-active']
            } else {
                return ['tab-title']
            }
        },
    }
}
</script>

<style>

.tab-title-text {
    color: #949494 !important;
    font-weight: bold;
}


.tab-title-text-active {
    color: #000000 !important;
    font-weight: bold;
}

.contract-tabs .nav-link {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.contract-tabs .nav-tabs {
    height: 3em;
    padding-top: .55em;
}

</style>
