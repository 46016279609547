<template>
    <div v-if="notes && tableItems.length > 0" class="nopads">
        <b-row>
            <span class="section-title">{{ $t('daily_summary.cost_calculated') }} ({{priceListsShown}})</span>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : tripDeliveriesVisible}"
                    v-b-toggle.tripDeliveriesDetails
                    @click.stop="tripDeliveriesVisible = !tripDeliveriesVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="tripDeliveriesDetails"
            class="mt-2 mb-2 item-details show-print"
        >
        <div v-if="tableItems && tableItems.length > 0">
            <delivery-note-order-price-list
                v-for="(item, index) in tableItems"
                :items="tableItems"
                :key="index"
                :class="'p-1 ' + index"
                :notes="item"
                :search-params="searchParams"
                :summaryItem="item[0]"
                @priceListSuccess="setPriceListSuccess"
            />
        </div>

        </b-collapse>
        <hr class="report-divider nopads"/>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
    </div>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import {timeUtils} from '../mixins/TimeUtils'
import {orderMixin} from "@/components/mixins/OrderMixin";
import DeliveryNoteOrderPriceList from "@/components/materials/DeliveryNoteOrderPriceList";
import {logModes} from "@/components/mixins/VehicleMixin";


export default {
    name: "ContractReportOrderTripDeliveries",
    components: {
        DeliveryNoteOrderPriceList
    },
    mixins: [restApi, timeUtils, orderMixin, logModes],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        summaryItem: {
            type: Object,
            default: null
        },
        trips: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false,
            notes: [],
            tableItems: [],
            editedRows: [],
            priceListsShown: 0,
            tripDeliveriesVisible: false,
            tripSummaries: []
        }
    },
    watch: {
        trips() {
            this.handleTripSummaries()
        }
    },
    methods: {
        fetchDeliveryNotes: function () {
            if (this.tripSummaries && this.tripSummaries.length > 0) {
                this.loading = true
                let orderIds = []
                this.tripSummaries.forEach(tripSummary => {
                    if (tripSummary && tripSummary.order && tripSummary.order.id) {
                        if (!orderIds.includes(tripSummary.order.id)) {
                            orderIds.push(tripSummary.order.id);
                        }
                    }
                })
                let params = this.searchParams
                params.order_ids = orderIds
                params.includeTrips = 1
                this.restFetchParams(this.materialDeliveryUrl , params, this.fetchNotesSuccess, this.fetchFail)
            }
        },
        fetchNotesSuccess: function (response) {
            this.notes = response.data
            if(this.notes && this.notes.length > 0) {
                this.initTableItems()
            }
            this.loading = false
        },
        initTableItems: function () {
            this.tableItems = [];
            const orderMap = {};
            this.notes.forEach(item => {
                const orderId = item.order.id;
                if (!orderMap[orderId]) {
                    orderMap[orderId] = [];
                }
                orderMap[orderId].push(item);
            });
            Object.values(orderMap).forEach(orderArray => {
                this.tableItems.push(orderArray);
            });
        },
        editRow(row) {
            let items = []
            this.notes.forEach(note => {
                if(note.order.id === row.orderId) {
                    items.push(note)
                }
            })
            this.editedRows = items
        },
        close() {
            this.editedRows = []
        },
        handleTripSummaries() {
            if(this.trips && this.trips.length > 0) {
                this.priceListsShown = 0
                this.tripSummaries = []
                this.trips.forEach(trip => {
                    if(trip && trip.delivery_tons > 0) {
                        this.tripSummaries.push(trip)
                    }
                })
                this.fetchDeliveryNotes()
            }
        },
        setPriceListSuccess() {
            this.priceListsShown++
        },
        fetchFail: function () {
            this.loading = false
        }
    }
}
</script>
<style>
.tab-title-class {
    font-weight: bold;
}
</style>
