export const Roles = {
    ADMIN: 1,
    OBSERVER: 2,
    WORKER: 3,
    MAP_VIEWER: 4,
    PRO_APP_USER: 5,
    COMPANY_SUPER_USER: 6
};

const rolePriorities = {
    [Roles.COMPANY_SUPER_USER]: 0,
    [Roles.OBSERVER]: 1,
    'other': 2,
};
export function rolePriority(role) {
    const priority = role != null && rolePriorities[role];
    return priority != null && priority !== false
        ? priority
        : rolePriorities['other'];
}

export const userHelper = {
    methods: {
        newUser: function () {
            return {
                id: null,
                first_name: '',
                last_name: '',
                email: '',
                username: '',
                tax_number: '',
                roles: []
            }
        },

        jsonToUser: function (user) {
            return JSON.parse(JSON.stringify(user)) // deep cloning of the vehicle object
        },

        hasAdmin: function (roles) {
            return roles.find(role => role.role === Roles.ADMIN) !== undefined
        },

        hasObserver: function (roles) {
            return roles.find(role => role.role === Roles.OBSERVER) !== undefined
        },

        hasWorker: function (roles) {
            return roles.find(role => role.role === Roles.WORKER) !== undefined
        },

        hasMapViewer: function (roles) {
            return roles.find(role => role.role === Roles.MAP_VIEWER) !== undefined
        },

        hasCompanySuperUser: function (roles) {
            return roles.find(role => role.role === Roles.COMPANY_SUPER_USER) !== undefined
        },

        hasContractSystem(user) {
            return user.api_key_types ? user.api_key_types.contract_system : false
        },

        getRoleName: function (role) {
            switch (role) {
                case Roles.ADMIN:
                    return this.$t('users.admin')
                case Roles.OBSERVER:
                    return this.$t('users.observer')
                case Roles.WORKER:
                    return this.$t('users.worker')
                case Roles.MAP_VIEWER:
                    return this.$t('users.map_viewer')
                case Roles.PRO_APP_USER:
                    return this.$t('users.pro_app_user')
                case Roles.COMPANY_SUPER_USER:
                    return this.$t('users.company_super_user')
            }
            return this.$t('common.unknown')
        },
        validEmail: function (email) {
            if (email) {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
            return false;
        }

    }
}
