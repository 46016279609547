<template>
    <div ref="container" class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">{{ $t('culvert.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <keep-alive>
                    <culvert-search
                        @search="fetchCulverts"
                    />
                </keep-alive>
            </transition>
            <div
                v-if="results.length > 0"
                class="button-container col-sm-12 trip-buttons"
            >
                <b-button
                    variant="success"
                    class="result-button"
                    @click="mapVisible=!mapVisible"
                >
                    {{ mapVisible ? $t('trip.show_table') : $t('trip.show_map') }}
                </b-button>

            </div>
            <transition name="fade">
                <div class="col-sm-12"
                     v-if="!mapVisible">
                    <culvert-list
                        @rowClicked=rowClicked
                        :culverts=results
                    />
                </div>
            </transition>
            <transition name="fade">
                <div class="col-sm-12 ">
                    <bridge-culvert-map
                        v-if="mapVisible"
                        :type="CULVERT"
                        :items="results"
                        @onMarkerTap="rowClicked"/>
                </div>
            </transition>
            <transition name="fade">
                <culvert-details
                    v-if="item"
                    :item="item"
                    :set-inspection-params="setInspectionParams"
                    @close="item = null"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import BridgeCulvertMap from "@/components/map/BridgeCulvertMap";
import {mapHelper} from "@/components/mixins/MapMixin";
import CulvertDetails from "@/components/culvert/CulvertDetails";
import CulvertList from "@/components/culvert/CulvertList";
import CulvertSearch from "@/components/culvert/CulvertSearch";

export default {
    name: 'culverts',
    components: {CulvertSearch, CulvertList, CulvertDetails, BridgeCulvertMap},
    mixins: [restApi, mapHelper],
    props: {
        setInspectionParams: {
            type: Function,
            default: null
        }
    },

    data: function () {
        return {
            results: [],
            loading: false,
            lastSearchedParams: null,
            mapVisible: false,
            item: null,
            mapHelper
        }
    },

    methods: {
        fetchCulverts: function (params) {
            this.loading = true
            this.mapVisible = false
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.culvertUrl, this.lastSearchedParams, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        },
        rowClicked: function (item) {
            this.item = item
        }
    }
}
</script>
