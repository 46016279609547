export const pipeWireCableHelper = {
    methods: {
        getPipeWireCableTypes: function () {
            return [
                {name: this.$t('pipe_wire_cable.types.fiber_optic_cable'), value: pipeWireCableTypes.FIBER_OPTIC_CABLE},
                {name: this.$t('pipe_wire_cable.types.road_lighting_cable'), value: pipeWireCableTypes.ROAD_LIGHTING_CABLE},
                {name: this.$t('pipe_wire_cable.types.pump_station_cables'), value: pipeWireCableTypes.PUMP_STATION_CABLES},
                {name: this.$t('pipe_wire_cable.types.electric_cable_serving_telematics'), value: pipeWireCableTypes.ELECTRIC_CABLE_SERVING_TELEMATICS},
                {name: this.$t('pipe_wire_cable.types.road_heating_line'), value: pipeWireCableTypes.ROAD_HEATING_LINE},
                {name: this.$t('pipe_wire_cable.types.only_protective_tube'), value: pipeWireCableTypes.ONLY_PROTECTIVE_TUBE},
                {name: this.$t('pipe_wire_cable.types.stormwater_drainage_pipes'), value: pipeWireCableTypes.STORMWATER_DRAINAGE_PIPES},
                {name: this.$t('pipe_wire_cable.types.encryptor_tubes'), value: pipeWireCableTypes.ENCRYPTOR_TUBES},
                {name: this.$t('pipe_wire_cable.types.other_pipe_type'), value: pipeWireCableTypes.OTHER_PIPE_TYPE}
            ]
        },
        getPipeWireCableMaterials: function () {
            return [
                {name: this.$t('pipe_wire_cable.materials.steel'), value: pipeWireCableMaterials.STEEL},
                {name: this.$t('pipe_wire_cable.materials.cast_iron'), value: pipeWireCableMaterials.CAST_IRON},
                {name: this.$t('pipe_wire_cable.materials.other'), value: pipeWireCableMaterials.OTHER},
                {name: this.$t('pipe_wire_cable.materials.concrete'), value: pipeWireCableMaterials.CONCRETE},
                {name: this.$t('pipe_wire_cable.materials.plastic'), value: pipeWireCableMaterials.PLASTIC},
                {name: this.$t('pipe_wire_cable.materials.stone'), value: pipeWireCableMaterials.STONE},
            ]
        },
        getPipeWireCableRefinedMaterials: function () {
            return [
                {name: this.$t('pipe_wire_cable.refined_materials.pp'), value: pipeWireCableRefinedMaterials.PP},
                {name: this.$t('pipe_wire_cable.refined_materials.pvc'), value: pipeWireCableRefinedMaterials.PVC},
                {name: this.$t('pipe_wire_cable.refined_materials.peh'), value: pipeWireCableRefinedMaterials.PEH}
            ]
        }
    }
}

export const pipeWireCableTypes = {
    FIBER_OPTIC_CABLE: 1,
    ROAD_LIGHTING_CABLE: 2,
    PUMP_STATION_CABLES: 3,
    ELECTRIC_CABLE_SERVING_TELEMATICS: 4,
    ROAD_HEATING_LINE: 5,
    ONLY_PROTECTIVE_TUBE: 6,
    STORMWATER_DRAINAGE_PIPES: 7,
    ENCRYPTOR_TUBES: 8,
    OTHER_PIPE_TYPE: 9
}

export const pipeWireCableMaterials = {
    STEEL: 5,
    CAST_IRON: 6,
    OTHER: 8,
    CONCRETE: 9,
    PLASTIC: 10,
    STONE: 12,
}

export const pipeWireCableRefinedMaterials = {
    PP: 1,
    PVC: 2,
    PEH: 3,
}

