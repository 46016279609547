<template>
    <div class="col-12 stat-container pt-3 pb-2">
        <b-row class="nopads">
            <div class="col-2">
                <font-awesome-icon icon="chart-line" class="stat-icon dont-print"/>
            </div>
            <div class="col-2">
                <span class="stat-title">{{ $t('contract_reports.material_name') }}</span>
            </div>
            <div class="col-2">
                <span class="stat-title">{{ $t('contract_reports.amount') }}</span>
            </div>
            <div class="col-2">
                <span class="stat-title">{{ $t('contract_reports.unit') }}</span>
            </div>
        </b-row>
        <b-row class="nopads">
            <div v-for="(material, index) in materialCombined" :key="index" class="col-12">
                <b-row class="nopads">
                    <div class="col-2">
                        <!-- Empty column for alignment -->
                    </div>
                    <div class="col-2">
                        <span>{{ material.material }}</span>
                    </div>
                    <div class="col-2">
                        <span>{{ material.amount }}</span>
                    </div>
                    <div class="col-2">
                        <span>{{ material.unit }}</span>
                    </div>
                </b-row>
            </div>
        </b-row>
    </div>
</template>

<script>

export default {
    name: 'ContractMaterialSummaryValues',
    props: {
        materialCombined: {
            type: Array,
            default: function () {
                return []
            }
        },

    },

}

</script>

<style>
</style>
