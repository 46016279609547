<template>
    <b-modal
        ref="modal"
        centered
        hide-footer
        :title="$t('user_alert.title')"
        @hidden="i => $emit('input', null)"
    >
        <user-alert-editor
            :value="value"
            @input="i => $emit('input', i)"
            @alertResolved="$emit('alertResolved')"
        />
    </b-modal>
</template>

<script>
import UserAlertEditor from './UserAlertEditor'

export default {
    name: "UserAlertEditorModal",
    components: {UserAlertEditor},
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    watch: {
        value() {
            if (this.value != null) {
                this.$refs.modal.show()
            } else {
                this.$refs.modal.hide();
            }
        }
    }
}
</script>

<style scoped>

</style>
