<template>
    <div
        class="map-info overflow-y-scroll"
        style="pointer-events: all;"
        @click.stop="$emit('hideRoadLink')"
    >
        <div class="map-info-container map-info-content">
            <road-link-basic-info
                v-if="roadLinkInfo"
                :road-link-info="roadLinkInfo"
            />
            <road-link-traffic-info
                v-if="roadLinkInfo && roadLinkInfo.trafficAmount && roadLinkInfo.trafficAmount.length > 0"
                :traffic-info="roadLinkInfo.trafficAmount[0]"
            />
            <road-link-surface-info
                v-if="roadLinkInfo"
                :road-link-info="roadLinkInfo"
            />
        </div>
    </div>
</template>

<script>
import RoadLinkBasicInfo from './RoadLinkInfoBasicInfo'
import RoadLinkTrafficInfo from './RoadLinkTrafficInfo'
import RoadLinkSurfaceInfo from './RoadLinkSurfaceInfo'

export default {
    name: 'RoadLinkInfo',
    components: {
        RoadLinkBasicInfo,
        RoadLinkTrafficInfo,
        RoadLinkSurfaceInfo
    },
    props: {
        roadLinkInfo: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            basicData: [],
            basicFields: [
                {
                    street: {
                        label: this.$t('road_details.street'),
                        sortable: true
                    }
                },
                {
                    length: {
                        label: this.$t('road_details.link_length'),
                        sortable: true
                    }
                },
                {
                    city: {
                        label: this.$t('road_details.municipality'),
                        sortable: true
                    }
                },
                {
                    linkId: {
                        label: this.$t('road_details.link_tag'),
                        sortable: true
                    }
                },
                {
                    linkType: {
                        label: this.$t('road_details.link_type'),
                        sortable: true
                    }
                },
                {
                    speedLimit: {
                        label: this.$t('road_details.speed_limit'),
                        sortable: true
                    }
                },
                {
                    winterSpeedLimit: {
                        label: this.$t('road_details.winter_speed_limit'),
                        sortable: true
                    }
                },
                {
                    streetLights: {
                        label: this.$t('road_details.street_lights'),
                        sortable: true
                    }
                },
                {
                    govArea: {
                        label: this.$t('road_details.ely_area'),
                        sortable: true
                    }
                },
                {
                    contractArea: {
                        label: this.$t('road_details.contract_area'),
                        sortable: true
                    }
                },
                {
                    liva: {
                        label: this.$t('road_details.liva'),
                        sortable: true
                    }
                },
                {
                    careAgreement: {
                        label: this.$t('road_details.care_agreement'),
                        sortable: true
                    }
                }
            ]
        }
    },

    mounted: function () {
        this.basicData = [{
            street: this.getRoadTitle(this.roadLinkInfo.roadlink),
            length: parseFloat(this.roadLinkInfo.roadlink.length.toFixed(1)).toLocaleString(),
            city: this.roadLinkInfo.roadlink.city,
            linkId: this.roadLinkInfo.roadlink.linkId,
            linkType: this.roadLinkInfo.roadlink.linkType,
            speedLimit: this.getSpeedLimits(this.roadLinkInfo.speedLimits),
            winterSpeedLimit: this.getWinterSpeedLimits(this.roadLinkInfo.speedLimits),
            streetLights: this.getLights(this.roadLinkInfo.lighting),
            govArea: this.roadLinkInfo.owner.govArea,
            contractArea: this.roadLinkInfo.owner.areaNum + ' ' + this.roadLinkInfo.owner.areaName,
            liva: this.roadLinkInfo.contract,
            careAgreement: this.roadLinkInfo.contract
        }]
    },

    methods: {
        getRoadTitle: function (roadLink) {
            // Info title
            var roadName = ''
            if (roadLink.roadNum != null && roadLink.roadNum > 0) {
                roadName = roadLink.roadNum
            }
            if (roadLink.secNum != null && roadLink.secNum > 0) {
                roadName += ' / ' + roadLink.secNum
            }
            if (roadLink.name != null && roadLink.name.length > 0) {
                if (roadName.length > 0) {
                    roadName += ' '
                }
                roadName += roadLink.name
            }
            if (roadName.length < 1) {
                roadName = this.$t('road_details.no_name')
            }
            return roadName
        },

        getSpeedLimits: function (limits) {
            var info = ''
            var list = []

            for (let item of limits) {
                var hasSpeedLimit = this.hasSpeedLimitRecord(list, item)
                if (item.WinterLimit === 0 && !hasSpeedLimit) {
                    list.push(item)
                    info += this.$t('road_details.km_h_direction', {speed: item.speedLimit, direction: item.direction})
                }
            }

            if (info.length < 1) {
                info = '-'
            }
            return info
        },

        /**
         * Returns winter speed limit info
         */
        getWinterSpeedLimits: function (limits) {
            var info = ''
            var list = []

            for (let item of limits) {
                var hasSpeedLimit = this.hasSpeedLimitRecord(list, item)
                if (item.WinterLimit === 1 && !hasSpeedLimit) {
                    list.push(item)
                    info += this.$t('road_details.km_h_direction', {speed: item.speedLimit, direction: item.direction})
                }
            }

            if (info.length < 1) {
                info = '-'
            }
            return info
        },

        getLights: function (lights) {
            if (lights) {
                return this.$t('road_details.yes')
            } else {
                return '-'
            }
        },

        hasSpeedLimitRecord: function (list, record) {
            var hasLimit = false
            for (let item of list) {
                if (item.speedLimit === record.speedLimit && item.direction === record.direction &&
                    item.winterLimit === record.winterLimit) {
                    hasLimit = true
                    break
                }
            }
            return hasLimit
        },

        getContractInfo: function (contract) {
            var roadInfo

            contract = contract.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.SurfaceClass === item.SurfaceClass
                ))
            )
            roadInfo = contract.reduce(function (accumulator, currentValue) {
                if (accumulator.length > 0) {
                    accumulator += '\n'
                }
                return accumulator + currentValue.ContractTypeName
            }, '')

            if (contract.length < 1) {
                roadInfo += '-'
            }
            return roadInfo
        }
    }
}
</script>
