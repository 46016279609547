<template>
    <b-form-select
        :value="selectedTaskType"
        @input="newVal => $emit('input', newVal)"
        size="sm"
        class="mb-3"
        :disabled="disabled"
    >
        <template slot="first">
            <option
                :value="null"
                :disabled="!nullable"
            >
                {{ $t('orders.select_task_type') }}
            </option>
        </template>
        <option
            v-for="option in taskTypes"
            :key="option.id"
            :value="option"
        >
            {{ option.name }}
        </option>
    </b-form-select>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: "TaskTypeSelector",
    mixins: [restApi],
    props: {
        value: {
            type: Object,
            default: null
        },
        contractId: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        nullable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            taskTypes: [],
            loading: true
        }
    },
    watch: {
        contractId(n, o) {
            if (n !== o) {
                this.updateTypes()
            }
        },
        disabled(n, o) {
            if (n !== o) {
                this.updateTypes()
            }
        }
    },
    computed: {
        selectedTaskType() {
            if (this.value == null || this.value.id == null) {
                return null
            }
            return this.taskTypes.find(obj => obj.id === this.value.id)
        }
    },
    mounted() {
        this.updateTypes()
    },
    methods: {
        updateTypes() {
            if (this.disabled) {
                this.taskTypes = []
                return
            }
            this.loading = true
            let failCallback = () => {
                this.taskTypes = []
                this.loading = false
            }
            if (this.contractId == null) {
                this.restFetch(this.taskTypeUrl, ({data}) => {
                    this.taskTypes = data
                    this.loading = false
                }, failCallback)
            } else {
                this.restFetchParams(this.contractTaskTypeUrl, {contract: this.contractId}, ({data}) => {
                    this.taskTypes = data.map(v => v.task_type)
                    this.loading = false
                }, failCallback)
            }
        }
    }
}
</script>

<style scoped>

</style>
