<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                @submit.prevent="onSubmit"
                @reset="onReset"
            >
                <b-row class="nopads">
                    <div class="col-sm-3">
                        <span class="search-title">{{ $t('contracts.contract_collection.title') }}</span>
                        <b-form-select
                            v-model="contractCollection"
                            size="sm"
                        >
                            <option :value="null">
                                {{ $t('contracts.select_contract_collection') }}
                            </option>
                            <option
                                v-for="option in contractCollectionOptions"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-3">
                        <span class="search-title">{{ $t('contracts.customer') }}</span>
                        <b-form-select
                            v-model="customer"
                            size="sm"
                        >
                            <option :value="-1">
                                {{ $t('contracts.select_customer') }}
                            </option>
                            <option
                                v-for="option in customerOptions"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-3">
                        <span class="search-title">{{ $t('orders.contractor') }}</span>
                        <b-form-select
                            v-model="contractor"
                            size="sm"
                        >
                            <template slot="first">
                                <option :value="-1">
                                    {{ $t('orders.select_contractor') }}
                                </option>
                            </template>
                            <option
                                v-for="option in contractors"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-3">
                        <span class="search-title">{{ $t('contracts.name') }}</span>
                        <b-form-input
                            v-model="contractName"
                            size="sm"
                            type="text"
                        />
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('contracts.number') }}</span>
                        <b-form-input
                            v-model="contractNumber"
                            size="sm"
                            type="text"
                        />
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('contracts.worksite_key') }}</span>
                        <b-form-input
                            v-model="workSiteKey"
                            size="sm"
                            type="text"
                        />
                    </div>
                    <div class="col-sm-2">
                        <span class="search-title">{{ $t('contracts.status') }}</span>
                        <b-form-checkbox
                            v-model="open"
                            size="md"
                            type="text"
                            value=1
                            unchecked-value=0>
                            {{ $t('contracts.only_open') }}
                        </b-form-checkbox>
                    </div>
                    <div class="col-sm-2 mt-4">
                        <b-form-checkbox
                            v-model="onlyUserOwnedContracts"
                            size="md"
                            type="text"
                            value=1
                            unchecked-value=0>
                            {{ $t('contracts.only_user_owned_contracts') }}
                        </b-form-checkbox>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('contracts.start') }} - {{
                                $t('trip_list.road_sec_from')
                            }}</span>
                        <b-form-input
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('contracts.start') }} - {{
                                $t('trip_list.road_sec_to')
                            }}</span>
                        <b-form-input
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-2">
                        <span class="search-title">{{ $t('contracts.user_owned') }}</span>
                        <b-form-select
                            v-model="selectedUser"
                            size="sm"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('contracts.select_user') }}
                                </option>
                            </template>
                            <option
                                v-for="option in users"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.first_name + ' ' + option.last_name }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'ContractSearch',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            loading: false,
            customerOptions: null,
            contractors: [],
            contractCollectionOptions: [],
            users: [],
            selectedUser: null,
            contractName: null,
            contractCollection: null,
            customer: -1,
            contractor: -1,
            open: 1,
            contractNumber: null,
            workSiteKey: null,
            fromDate: null,
            toDate: null,
            onlyUserOwnedContracts: 0
        }
    },
    mounted: function () {
        this.fetchContractCollections()
        this.fetchCustomers()
        this.fetchContractors()
        this.fetchUsers()
    },
    methods: {
        fetchCustomers: function () {
            this.loading++
            this.restFetch(this.customerUrl, this.handleCustomers)
        },
        handleCustomers: function (response) {
            this.loading--
            this.customerOptions = response.data
        },
        fetchUsers: function () {
            this.loading = true
            this.restFetch(this.userUrl, this.handleUsers)
        },
        handleUsers: function (response) {
            this.users = response.data
            this.loading = false
        },
        fetchContractors: function () {
            this.contractors = []
            this.loading++
            let params = {}
            if (this.company && this.company > 0) {
                params.company = this.company
            }
            this.restFetchParams(this.contractorUrl, params, this.handleContractors)
        },

        handleContractors: function (response) {
            this.loading--
            if (response && response.data) {
                this.contractors = response.data
            }
        },
        fetchContractCollections: function () {
            this.loading++
            this.restFetch(this.contractCollectionUrl, this.handleContractCollections)
        },
        handleContractCollections: function (response) {
            this.loading--
            this.contractCollectionOptions = response.data
        },
        onSubmit: function () {
            var params = {}
            if (this.customer > 0) {
                params.customer = this.customer
            }
            if(this.contractCollection != null) {
                params.contract_collection = this.contractCollection
            }
            if (this.contractor > 0) {
                params.contractor = this.contractor
            }
            if (this.contractName != null && this.contractName.trim()) {
                params.name = this.contractName
            }
            if (this.contractNumber != null && this.contractNumber.trim()) {
                params.number = this.contractNumber
            }
            if (this.workSiteKey != null && this.workSiteKey.trim()) {
                params.work_site_key = this.workSiteKey
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.startDate = this.localTimeToUtcTime(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.endDate = this.getEndOfTheDay(this.toDate).getTime()
            }
            if (parseInt(this.open) === 1) {
                params.open = 1
            }
            if (parseInt(this.onlyUserOwnedContracts) === 1) {
                params.onlyUserOwnedContracts = 1
            }
            if(this.selectedUser !== null) {
                params.selected_user = this.selectedUser
            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.contractName = null
            this.contractCollection = null
            this.customer = -1
            this.contractor = -1
            this.contractNumber = null
            this.workSiteKey = null
            this.fromDate = null
            this.toDate = null
        }
    }
}
</script>
