<template>
    <div class="list-container nopads pl-3 pr-3 pt-2">
        <div class="col-12 list-content">
            <b-row>
                <!-- Driving Time -->
                <b-col md="6" class="nopads chart-container">
                    <span class="report-title mt-0">{{ $t('trip_list.duration') }}</span>
                    <span class="report-descr mt-0">{{ $t('trip_list.time_descr') }}</span>
                    <div class="report-total">
                        {{ this.getReportTotal(this.durationData) }}
                    </div>
                    <b-row>
                        <div class="col-md-6 nopads p-4">
                            <pie-chart
                                class="routa-pie dont-print"
                                ref="pieDuration"
                                v-if="durationData.length"
                                :labels="labels"
                                :colors="colors"
                                :data="durationData"
                                :title="titleDuration"
                            ></pie-chart>
                            <img
                                class="print-only routa-pie-print"
                                id="pieDurationImage"
                            />
                        </div>
                        <div class="col-md-6 nopads">
                            <bar-chart
                                class="routa-bars dont-print"
                                ref="barDuration"
                                v-if="durationData.length"
                                :labels="labels"
                                :colors="colors"
                                :data="durationData"
                                :title="titleDuration"
                                unit="km"
                            ></bar-chart>
                            <img
                                class="print-only routa-bars-print"
                                id="barDurationImage"
                            />
                        </div>
                    </b-row>
                </b-col>
                <!-- Vehicle reservation time -->
                <b-col md="6" class="nopads chart-container">
                    <span class="report-title mt-0">{{ $t('trip_list.vehicle_allocation') }}</span>
                    <span class="report-descr mt-0">{{ $t('trip_list.allocation_descr') }}</span>
                    <div class="report-total">
                        {{ this.getReportTotal(reservationData) }}
                    </div>
                    <b-row>
                        <div class="col-md-6 nopads p-4">
                            <pie-chart
                                class="routa-pie dont-print"
                                ref="pieReservation"
                                v-if="reservationData.length"
                                :labels="labels"
                                :colors="colors"
                                :data="reservationData"
                                :title="titleReservation"
                                unit="min"
                            ></pie-chart>
                            <img
                                class="print-only routa-pie-print"
                                id="pieReservationImage"
                            />
                        </div>
                        <div class="col-md-6 nopads">
                            <bar-chart
                                class="routa-bars dont-print"
                                ref="barReservation"
                                v-if="reservationData.length"
                                :labels="labels"
                                :colors="colors"
                                :data="reservationData"
                                :title="titleReservation"
                                unit="min"
                            ></bar-chart>
                            <img
                                class="print-only routa-bars-print"
                                id="barReservationImage"
                            />
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import BarChart from '@/components/chart/BarChart'
import PieChart from '@/components/chart/PieChart'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'vehicle-allocation-charts',
    components: {BarChart, PieChart},
    mixins: [timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            labels: [],
            colors: [],
            durationData: [],
            reservationData: [],
            titleDuration: this.$t('trip_list.duration'),
            titleReservation: this.$t('trip_list.vehicle_allocation'),
        }
    },
    mounted: function () {
        this.formatData()
    },
    watch: {
        results() {
            this.formatData()
        },
    },
    methods: {
        randomColor: function () {
            let color = Math.round(0xffffff * Math.random());
            return 'rgba(' + (color >> 16) + ',' + (color >> 8 & 255) + ',' + (color & 255) + ',1' + ')';
        },
        formatData: function () {
            let labels = []
            let durations = []
            let reservations = []
            let colors = []
            this.results.forEach(item => {
                    let key = null
                    if (item.vehicle_id) {
                        key = item.vehicle_id
                    } else {
                        key = 'no_vehicle'
                    }
                    if (!durations.hasOwnProperty(key)) {
                        durations[key] = {value: 0, unit: 'min', vehicle: ''}
                        reservations[key] = {value: 0, unit: 'min', vehicle: ''}
                    }
                    if (item.vehicle) {
                        labels[key] = item.vehicle
                        colors[key] = this.randomColor()
                    }
                    durations[key].vehicle = item.vehicle
                    durations[key].value += Math.round(item.duration / 60)
                    reservations[key].value += item.vehicle_allocation_time
                }
            )
            this.durationData = []
            this.colors = []
            this.labels = []
            for (let key in durations) {
                this.labels.push(labels[key])
                this.durationData.push(durations[key])
                this.reservationData.push(reservations[key])
                this.colors.push(colors[key])
            }
        },
        getReportTotal: function (reportData) {
            let total = reportData.reduce((acc, dataValue) => parseInt(dataValue.value) + acc, 0);
            let unit = reportData[0] && reportData[0].unit ? reportData[0].unit : '';
            if (unit === 'min') {
                total = this.getWorkTime(total)
                unit = 'h'
            }
            return `${this.$t('daily_summary.report_total')} ${total} ${unit}`;
        },
    }
}

</script>
<style>
.report-total {
    font-weight: bold;
    font-size: 0.9em;
    color: #5b5b5c;
    text-align: center !important;
    margin: .2em;
}
</style>
