<template>
  <b-container fluid id="app">
    <router-view />
  </b-container>
</template>

<script>
  import Vue from 'vue'
  import BootstrapVue from 'bootstrap-vue'
  import Datetime from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'

  Vue.use(BootstrapVue)
  Vue.use(Datetime)

  export default {
    name: 'App'
  }
</script>

<style lang="scss">
  @import "styles/style.scss";
  @import "styles/office.css";
</style>
