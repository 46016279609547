<template>
    <div class="nopads pt-1">
        <hr class="nopads mt-1"/>
        <h4 class="form-header">{{ $t('velho_equipment.damages_title') }}</h4>
        <b-row class="nopads mb-3">
            <div class="col-sm-10 nopads">
                <velho-equipment-damage-editor :item="this.newDamage" :isPercentageVisible="this.isPercentageVisible"/>
            </div>
            <div class="col-sm-2 nopads pl-1 pr-1 text-right pt-4">
                <b-button
                    variant="info"
                    class="result-button"
                    size="sm"
                    :disabled="isAddDisabled"
                    @click.stop="addDamage"
                >
                    {{ $t('common.add') }}
                </b-button>
            </div>
        </b-row>
        <template v-if="items.length === 0">
            <hr class="nopads mt-1 mb-1"/>
            <span class="item-text pl-2 pb-2">{{ $t('velho_equipment.damage.no_damage_info')}}</span>
        </template>
        <hr class="nopads mt-1 mb-1"/>
        <template v-if="items.length > 0">
            <b-row class="nopads">
                <div class="col-sm-12 nopads">
                    <span class="span-title">{{ $t('velho_equipment.damage_title') }}</span>
                </div>
            </b-row>
            <div v-for="(damage, index) in items" :key="index">
                <b-row class="nopads mb-2">
                    <div class="col-sm-10 nopads">
                        <velho-equipment-damage-editor :item="damage" :isPercentageVisible="isPercentageVisible"/>
                    </div>
                    <div class="col-sm-2 nopads pl-1 pr-1 text-right pt-4">
                        <b-button
                            variant="danger"
                            class="result-button"
                            size="sm"
                            @click.stop="removeDamageByIndex(index)"
                        >
                            {{ $t('common.remove') }}
                        </b-button>
                    </div>
                </b-row>
                <hr v-if="index < items.length - 1" class="nopads mt-1 mb-1"/>
            </div>
        </template>
    </div>
</template>

<script>
import VelhoEquipmentDamageEditor from "@/components/velho/VelhoEquipmentDamageEditor";

export default {
    name: 'velho-equipment-damages',
    mixins: [],
    components: {VelhoEquipmentDamageEditor},
    props: {
        items: {
            type: Array
        },
        isPercentageVisible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAddDisabled() {
            return !this.newDamage.damage_type
        }
    },
    mounted() {
        if(!this.items) {
            this.items = []
        }
    },
    data() {
        return {
            newDamage: {
                damage_type: null,
                damage_category: null,
                repair_priority_level: null,
                area: null,
                percentage: null
            }
        }
    },
    methods: {
        addDamage: function() {
            this.items.push(this.newDamage)
            this.newDamage = {
                damage_type: null,
                damage_category: null,
                repair_priority_level: null,
                area: null,
                percentage: null
            }
        },
        removeDamageByIndex: function(index) {
            this.items.splice(index, 1);
        }
    }
}
</script>