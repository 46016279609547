<template>
    <div class="content-container">
        <!--        Top bar-->
        <b-navbar
            variant="faded"
            @click="editMode = false"
        >
            <div
                v-if="!editMode"
                class="header"
            >
        <span class="title">
          {{ $t('work_permission.title') }}
        </span>
            </div>
            <div
                v-else
                class="header"
                @click="editMode = false"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>

        <work-permission-search
            v-if="!editMode"
            @search="fetchWorkPermissionRequests"
        />

        <div class="col-sm-12 list-container"
             v-if="!editMode">

            <!--            Add button-->
            <div
                class="data-list-item add"
                @click.stop="addNew"
            >
                <div class="item-img-cont add-title">
                    <i class="fa fa-plus"/>
                </div>
                <div class="item-text-cont">
                    <span class="item-title-add">{{ $t('common.add_new') }}</span>
                    <font-awesome-icon
                        icon="lock"
                        class="card-body-icon"
                    />
                </div>
            </div>
            <work-permission-list
                v-if="!editMode"
                :work-permissions="workPermissions"
                @rowClicked="editWorkPermission"
            />
        </div>

        <!--     Editor-->
        <work-permission-editor
            v-if="editMode"
            :work-permission="workPermission"
            @close="close()"
            @closeAndUpdate="closeAndUpdate"/>

        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workPermissionHelper} from '../mixins/WorkPermissionMixin'
import WorkPermissionEditor from "./WorkPermissionEditor";
import WorkPermissionList from "./WorkPermissionList";
import WorkPermissionSearch from "./WorkPermissionSearch";

export default {
    name: 'work-permissions',
    mixins: [restApi, workPermissionHelper],
    props: {
        isObserver: {
            type: Boolean,
            default: null
        },
    },
    components: {WorkPermissionEditor, WorkPermissionList, WorkPermissionSearch},
    data: function () {
        return {
            loading: false,
            workPermissions: [],
            editMode: false,
            workPermission: null
        }
    },
    created: function () {
        this.fetchWorkPermissionRequests()
    },
    methods: {
        fetchWorkPermissionRequests: function (params) {
            this.loading = true
            this.restFetchParams(this.workPermissionUrl, params, this.handleResponse, this.onError)
        },
        handleResponse: function (response) {
            this.workPermissions = response.data
            this.loading = false
        },
        onError: function () {
            this.loading = false
            this.results = []
        },
        editWorkPermission: function (workPermission) {
            if (this.isObserver) {
                this.workPermission = workPermission
                this.editMode = true
            }
        },
        close: function () {
            this.workPermission = null
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.workPermission = null
            this.workPermissions = []
            this.fetchWorkPermissionRequests()
            this.editMode = false
        },
        addNew: function () {
            this.workPermission = null
            this.editMode = true
        }
    }
}
</script>
