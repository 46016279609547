<template>
    <div v-if="results && results.length > 0" class="nopads">
        <b-row>
            <b-col class="nopads report-header" cols="10">
                <span class="section-title" style="display: block">{{ $t('material_reports.title') }}</span>
                <b-row class="nopads">
                    <b-col class="nopads">
                        <div>{{ results ? results.length : 0 }} {{ $t('danger_report.reports') }}</div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : materialsVisible}"
                    v-b-toggle.materialsDetails
                    @click.stop="materialsVisible = !materialsVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="materialsDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <!-- Summary -->
            <div class="col-12 text-center">
                <contract-material-summary
                    :results=results
                />
            </div>

            <transition name="fade">
                <div class="list-content result-table">
                    <b-table
                        show-empty
                        small
                        striped
                        :items="results"
                        :fields="fields"
                    >
                        <template v-slot:cell(date)="row">
                            {{ getShortDateString(row.value) }}
                        </template>
                    </b-table>
                </div>
            </transition>
        </b-collapse>
        <hr class="report-divider nopads"/>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import ContractMaterialSummary from "@/components/contractreport/ContractMaterialSummary.vue";

export default {
    name: "ContractMaterialReports",
    components: {ContractMaterialSummary},
    mixins: [timeUtils, restApi],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            loading: false,
            results: [],
            materialData: [],
            labels: [],
            materialsVisible: false,
            materialTitle: this.$t('material_report.chart_title'),
            fields: [
                {
                    date: {
                        label: this.$t('daily_summary.date'),
                        sortable: true
                    }
                },
                {
                    reporter_name: {
                        label: this.$t('material_reports.reporter'),
                        sortable: true
                    }
                },
                {
                    customer_name: {
                        label: this.$t('contracts.customer'),
                        sortable: true
                    }
                },
                {
                    contract_name: {
                        label: this.$t('orders.contract'),
                        sortable: true
                    }
                },
                {
                    material: {
                        label: this.$t('material_reports.material'),
                        sortable: true
                    }
                },
                {
                    amount: {
                        label: this.$t('material_reports.amount'),
                        sortable: true
                    }
                },
                {
                    unit: {
                        label: this.$t('material_reports.unit'),
                        sortable: true
                    }
                },
                {
                    info: {
                        label: this.$t('material_reports.info'),
                        sortable: true
                    }
                }
            ]
        }
    },
    mounted() {
        this.fetchMaterialReports()
    },
    methods: {
        fetchMaterialReports: function () {
            this.results = []
            if (this.searchParams) {
                let mappedParams = {
                    contract: this.searchParams.contract,
                    from: this.searchParams.dateFrom ? this.getDateIsoString(new Date(this.searchParams.dateFrom)) : null,
                    to: this.searchParams.dateTo ? this.getDateIsoString(new Date(this.searchParams.dateTo)) : null,
                }
                this.$emit('loading', true)
                this.restFetchParams(this.materialReportUrl, mappedParams, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response) {
                this.results = response.data
            }
            this.$emit('loading', false)
        }
    }
}
</script>

<style scoped>

</style>
