<template>
    <div class="print-img-margin">
        <b-img
            v-if="src"
            :id="imageId"
            :src="src"
            :fluid="isFluid"
            :height="imgHeight"
            center
            class="m-auto"
            @load="onImageLoaded()"
            @click="emitClick"
            style="background-color: #FFFFFF !important; border: none"
        />
        <span
            v-if="loading"
            style="line-height: 15em"
        >
              {{ $t('common.loading') }}
        </span>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
// import {EXIF} from 'exif-js'
import {rotation} from '../mixins/ImageMixin'
import {utility} from '../mixins/Utils'

export default {
    name: 'ImageView',
    mixins: [restApi, rotation, utility],
    props: {
        file: {
            type: Object,
            default: null
        },
        contain: {
            type: Boolean,
            default: false
        },
        isFluid: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: 'auto'
        },
        url: {
            type: String,
            default() {
                return ''
            }
        },
        imgSize: {
            type: String,
            default() {
                return 'md'
            }
        }
    },
    data() {
        return {
            src: null,
            loading: false,
            placeholderClass: 'img-placeholder',
            initialClass: null,
            loaded: false,
            mounted: false,
            imageId: this.getUniqueId()
        }
    },
    computed: {
       imgHeight() {
           return this.height ? this.height : 'auto'
       }
    },
    watch: {
        file(val) {
            if (this.mounted) {
                this.onFileChanged(val)
            }
        },
        url() {
            if (this.mounted) {
                this.onFileChanged(this.file)
            }
        }
    },
    mounted: function () {
        this.mounted = true
        this.onFileChanged(this.file)
    },
    methods: {
        onImageLoaded: function () {
            if (!this.loaded) {
                let selectedClass = this.contain ? 'image-view image-contain' : 'image-view'
                this.initialClass = selectedClass
                this.setImageClass(selectedClass)
                this.loaded = true
                this.$emit('imageLoaded')
            }
        },
        onFileChanged: function (file) {
            if (!file) {
                if (this.isValidUrl(this.url)) {
                    // Restore the orientation for the file loaded from server
                    this.setImageClass(this.initialClass)
                    this.fetchPhoto(this.url)
                } else {
                    this.src = null
                }
            } else {
                // Restore default orientation
                this.setImageClass('image-view')
                this.src = URL.createObjectURL(file)
            }
        },
        isValidUrl: function (url) {
            try {
                let u = new URL(url) // Gives lint warning, but must be kept
                if (u) {
                    return true
                }
            } catch (ex) {
                return false
            }
            return false
        },
        setImageClass: function (className) {
            var image = document.getElementById(this.imageId)
            if (image && className) {
                image.className = className
            }
        },
        fetchPhoto: function (url) {
            this.loading = true
            this.restFetch(url, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            if (response && response.status === 200) {
                this.src = response.data
            }
        },
        emitClick: function () {
            this.$emit('click', this.src);
        }
    }
}
</script>
<style>
@import "../../styles/rotate.css";
</style>
