<template>
    <div class="col-12 nopads">
        <span class="span-title">{{ $t('observations.select_equipment') }}</span>
        <multiselect
            v-model="selectedItem"
            :placeholder="$t('observations.select_equipment')"
            :deselect-label="$t('common.remove_selection')"
            :select-label="$t('common.select')"
            :close-on-select="true"
            :multiple="false"
            label="name"
            track-by="id"
            :searchable="true"
            :internal-search="true"
            :selected-label="$t('common.selected')"
            :options="equipments"
        >
            <template
                slot="singleLabel"
                slot-scope="props"
            >
                <b-row class="nopads">
                    <img v-if="props.option.type !== EXTERNAL_TRAFFIC_SIGNS" class="option__image"
                         :src="getEquipmentIcon(props.option.type, props.option.code)" height="25px"
                         alt="">
                    <img v-else class="option__image"
                         :src="require(`@/assets/roadsign/`+ getSignIcon(props.option.code))" height="25px"
                         alt="">
                    <b-col style="padding-top: 2px;">
                        <span class="option__title" style="font-weight: 400">
                            {{ props.option.distance}} m -
                            {{ props.option.name }}
                            ({{ props.option.id }})
                        </span>
                    </b-col>
                </b-row>
            </template>

            <template slot="option"
                      slot-scope="props">
                <b-row class="nopads">
                    <b-col cols="auto" class="nopads">
                        <img v-if="props.option.type !== EXTERNAL_TRAFFIC_SIGNS" class="option__image"
                             :src="getEquipmentIcon(props.option.type, props.option.code)" height="25px"
                             alt="">
                        <img v-else class="option__image"
                             :src="require(`@/assets/roadsign/`+ getSignIcon(props.option.code))" height="25px"
                             alt="">
                    </b-col>
                    <b-col class="pt-2">
                        <span class="option__title pt-2">
                                {{ props.option.distance ? props.option.distance + ' m' : '?'}} -
                                {{ props.option.name }}
                                ({{ props.option.id }})
                        </span>
                    </b-col>
                </b-row>
            </template>
            <span slot="noResult">{{ $t('common.not_found') }}</span>
            <span slot="noOptions">{{ $t('common.no_results') }}</span>
        </multiselect>
    </div>
</template>

<script>
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";

export default {
    name: 'ExternalEquipmentSelector',
    mixins: [roadSignHelper, conversionModelHelper],
    props: {
        value: {
            type: Object,
            default: null
        },
        equipments: {
            type: Array,
            default: null
        },
        selected: {
            type: String,
            default: null
        }
    },
    data: function () {
        return {
            selectedItem: null
        }
    },
    watch: {
        selectedItem(val) {
            this.$emit('input', val)
        }
    },
    mounted() {
        if (this.selected) {
            let item = this.equipments.find(item => item.id === this.selected)
            this.selectedItem = item ? item : null
        }
    }
}
</script>
