<template>
  <base-modal @cancel="$emit('close')">
    <div
      class="col-12"
    >
      <h3>{{ $t('map.select_map') }}</h3>
    </div>
    <div class="col-sm-12 nopads">
      <b-form class="col-sm-12 editor-form">
        <div class="col-sm-12 form-data">
          <b-form-select id="map_selector" v-model="selected" :options="options"></b-form-select>
          <div class="col-sm-12 button-container">
            <b-button
              id="close"
              variant="primary"
              class="form-button"
              @click.stop="$emit('close')"
            >
                {{ $t('common.back') }}
            </b-button>
            <b-button
              id="submit"
              variant="success"
              class="form-button"
              @click.stop="submit"
            >
              {{ $t('common.save') }}
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </base-modal>
</template>

<script>
  import { mapState } from 'vuex'
  import BaseModal from '../BaseModal'

  export default {
    name: 'MapLayerSelector',
    components: {BaseModal},
    data: function () {
      return {
        selected: null,
        options: [
          { value: null, text: this.$t('map.select_map') },
          { value: 'HERE', text: 'HERE' },
          { value: 'LEAFLET', text: 'Sitowise Smartlas' },
        ]
      }
    },
    computed: mapState({
      selectedMap: (state) => state.mapSettings.baseMap
    }),
    mounted: function () {
      this.selected = this.selectedMap
    },
    methods: {
      submit: function () {
        if (this.selected) {
          this.$emit('onMapSelected', this.selected)
        } else {
          this.$emit('close')
        }
      }
    }
  }
</script>
<style>
  @import "../../styles/modal.css";
</style>
