<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="observationTypeUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('observations.edit_type') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('observations.add_type') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <span class="span-title">{{ $t('observations.group') }}</span>
                <b-form-select
                    id="group"
                    v-model="group"
                    size="sm"
                >
                    <template slot="first">
                        <option
                            :value="null"
                            disabled
                        >
                            {{ $t('observations.select_group') }}
                        </option>
                    </template>
                    <option
                        v-for="item in groups"
                        :key="item.id"
                        :value="item.id"
                    >
                        {{ item.name }}
                    </option>
                </b-form-select>

                <span class="span-title">{{ $t('observations.name') }}</span>
                <b-form-input
                    id="name"
                    v-model="observationTypeUpdate.name"
                    type="text"
                    size="sm"
                />

                <span class="span-title">{{ $t('observations.type') }}</span>
                <b-form-select
                    id="type"
                    v-model="type"
                    size="sm"
                    :disabled="observationTypeUpdate.id > 0"
                    class="mb-2"
                >
                    <option :value="1">
                        {{ $t('observations.point') }}
                    </option>
                    <option :value="2">
                        {{ $t('observations.line') }}
                    </option>
                </b-form-select>

                <span class="span-title">{{ $t('observations.alert_limit') }}</span>
                <b-form-input
                    id="name"
                    v-model="observationTypeUpdate.alert_limit"
                    type="number"
                    size="sm"
                />

                <b-row class="nopads pb-2">
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('observations.mandatory_info') }}</span>
                            <b-form-checkbox
                                id="mandatory_info"
                                v-model="observationTypeUpdate.mandatory_info"
                                size="sm"
                            />
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('inspection_editor.inactive') }}</span>
                        <b-form-checkbox
                            id="in_active"
                            v-model="observationTypeUpdate.inactive"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('observations.numeric_info') }}</span>
                        <b-form-checkbox
                            id="numeric_info"
                            v-model="observationTypeUpdate.numeric_info"
                            size="sm"
                        />
                        <span class="tip-light">{{ $t('observations.numeric_info_tip') }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('observations.numeric_unit') }}</span>
                        <b-form-input
                            type="text"
                            size="sm"
                            :disabled="!observationTypeUpdate.numeric_info"
                            v-model="observationTypeUpdate.numeric_unit"/>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {geomTypes, observationHelper} from '../../mixins/ObservationMixin'
import {restApi} from '../../mixins/RestApiMixin'

export default {
    name: 'ObservationTypeEditor',
    mixins: [geomTypes, observationHelper, restApi],
    props: {
        observationType: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            group: null,
            groups: [],
            type: 1,
            observationTypeUpdate: this.jsonToItem(this.observationType)
        }
    },

    mounted: function () {
        this.fetchGroups()
        if (this.observationTypeUpdate.id > 0) {
            this.type = this.observationTypeUpdate.geometry_type
        }
    },

    methods: {
        fetchGroups: function () {
            this.loading = true
            this.restFetch(this.observationGroupUrl, this.handleGroupResponse)
        },

        handleGroupResponse: function (response) {
            this.groups = response.data
            this.loading = false
            if (this.observationTypeUpdate.observation_group) {
                this.group = this.observationTypeUpdate.observation_group.id
            }
        },

        setDefaultValues: function (obsType) {
            if (!obsType.name.trim() === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return undefined
            }
            if (!this.group || this.group < 0) {
                EventBus.$emit('show-alert', this.$t('observations.invalid_group'))
                return undefined
            }
            if (this.type !== 1 && this.type !== 2) {
                EventBus.$emit('show-alert', this.$t('observations.invalid_type') + ' ' + this.type)
                return undefined
            }
            obsType.observation_group = {id: this.group}
            obsType.geometry_type = this.type
            return obsType
        },

        submit: function () {
            let json = this.setDefaultValues(this.observationTypeUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.observationTypeUpdate.id < 1) {
                    this.restAdd(this.observationTypeUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.observationTypeUrl, json, this.success, this.fail)
                }
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
