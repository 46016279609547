export const workShift = {
    data: function () {
        return {
            shift_info: [
                this.$t('work_shift.unknown_shift'),
                this.$t('work_shift.day_shift'),
                this.$t('work_shift.morning_shift'),
                this.$t('work_shift.evening_shift'),
                this.$t('work_shift.night_shift')
            ]
        }
    },
    methods: {
        getWorkShiftName: function (index) {
            return this.shift_info[index]
        }
    }
}
