<template>
    <b-container fluid
                 class="details-container"
    >
        <div class="col-sm-12 nopads">
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-title editor-title">
                    <h2
                        v-if="sewerItemUpdate.id > 0 || sewerItems.length === 1"
                        class="form-title editor-title"
                    >
                        {{ $t('storm_sewers.edit_item') }}
                    </h2>
                    <h2
                        v-else-if="sewerItems && sewerItems.length > 1"
                        class="form-title editor-title"
                    >
                        {{ $t('storm_sewers.edit_items') }}
                    </h2>
                    <h2
                        v-else
                        class="form-title editor-title"
                    >
                        {{ $t('storm_sewers.add_to_system') }}
                    </h2>
                </div>
                <div class="col-sm-12 form-data">
                    <!-- Show open contracts  -->
                    <div class="col-12 nopads">
                        <b-form-group
                            class="title nopads"
                            label-for="contract"
                        >
                            <span class="span-title">{{ $t('orders.contract') }}</span>
                            <b-form-select
                                id="contract"
                                v-model="sewerItemUpdate.contract.id"
                                size="sm"
                                class="sm-3"
                                :disabled="sewerItemUpdate.remove === '1'"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('orders.select_contract') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in contracts"
                                    :key="option.id"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-12 nopads">
                        <b-form-group
                            class="title nopads"
                            label-for="contract"
                        >
                            <span class="span-title">{{ $t('storm_sewers.status') }}</span>
                            <b-form-select
                                id="contract"
                                v-model="sewerItemUpdate.status"
                                size="sm"
                                class="sm-3"
                                :disabled="sewerItemUpdate.remove === '1'"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('storm_sewers.select_status') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in getStatusOptions()"
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.text }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-12 nopads">
                        <b-form-group
                            class="title nopads"
                            label-for="contract"
                        >
                            <span class="span-title">{{ $t('storm_sewers.radius') }}</span>
                            <b-form-input
                                id="radius"
                                v-model="sewerItemUpdate.radius"
                                type="number"
                                min="0"
                                size="sm"/>
                        </b-form-group>
                    </div>

                    <div class="col-12 nopads">
                        <b-form-group
                            class="title nopads"
                            label-for="contract"
                        >
                            <span class="span-title">{{ $t('storm_sewers.material') }}</span>
                            <b-form-input
                                id="radius"
                                v-model="sewerItemUpdate.material"
                                type="number"
                                min="0"
                                size="sm"/>
                        </b-form-group>
                    </div>

                    <div class="col-sm-12 button-container">
                        <b-button
                            variant="danger"
                            class="routa-button"
                            @click.stop="$emit('close')"
                        >
                            <i class="fa fa-times"/>
                            <span class="button-text">
                              {{ $t('common.cancel') }}
                            </span>
                        </b-button>
                        <b-button
                            variant="success"
                            class="routa-button"
                            :disabled="loading"
                            @click.stop="submitMultiple"
                        >
                            <i class="fa fa-save"/>
                            <span class="button-text">
                              {{ $t('common.save') }}
                            </span>
                        </b-button>
                    </div>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </b-container>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import {attachmentHelper} from "../mixins/AttachmentMixin";
import {workAssignmentHelper} from "../mixins/WorkAssignmentMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {mapHelper} from "@/components/mixins/MapMixin";
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import {timeUtils} from "../mixins/TimeUtils";
import {EventBus} from "../../event-bus";

export default {
    name: "SewerItemBulkEditorDialog",
    mixins: [workAssignmentHelper, restApi, timeUtils, attachmentHelper, mapHelper, roadSignHelper, stormSewersMixin],
    props: {
        sewerItems: null,
        isAdmin: Boolean,
        isObserver: Boolean,
        updateSewerLines: {
            type: Boolean,
            default: false
        },
        updateSewerDrains: {
            type: Boolean,
            default: false
        },
    },
    data: function (){
        return{
            sewerItemUpdate: this.newSewerItemMultiple(),
            contracts: [],
            loading: false,
        }
    },
    mounted: function() {
        this.fetchOpenContracts()
    },
    methods: {
        newSewerItemMultiple: function (like) {
            return {
                contract: like && like.contract ? like.contract : {id: null},
                status: like && like.status ? like.status : null,
                radius: like && like.radius ? like.radius : null,
                material: like && like.material ? like.material : null,
                remove: like && like.remove ? true : undefined,
                ...like
            }
        },
        submitMultiple:function (){
            let newSewerItem = this.setDefaultValues(this.sewerItemUpdate)
            if (newSewerItem) {
                this.loading = true
                if(!this.sewerItems) this.fail
                var sewerLineIds = []
                var sewerDrainIds = []
                this.sewerItems.forEach((sewerItem) => {
                    if(sewerItem.type === this.SEWERS) {
                        sewerLineIds.push(sewerItem.id)
                    } else if(sewerItem.type === this.DRAINS) {
                        sewerDrainIds.push(sewerItem.id)
                    }
                })
                let params = {
                    update_item: {}
                }
                if(newSewerItem.contract) {
                    params.update_item.contract = newSewerItem.contract
                }
                if(newSewerItem.status) {
                    params.update_item.status = newSewerItem.status
                }
                if(newSewerItem.radius) {
                    params.update_item.radius = newSewerItem.radius
                }
                if(newSewerItem.material) {
                    params.update_item.material = newSewerItem.material
                }
                if(this.updateSewerLines) {
                    params.sewer_line_ids = sewerLineIds
                }
                if(this.updateSewerDrains) {
                    params.sewer_drain_ids = sewerDrainIds
                }
                this.restUpdate(this.stormSewerItems , params, this.success, this.fail)
            }
        },
        success: function () {
            this.loading = false
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        setDefaultValues: function (sewerItem) {
            sewerItem = JSON.parse(JSON.stringify(sewerItem))
            if (sewerItem.contract && sewerItem.contract.id) {
                sewerItem.contract = sewerItem.contract.id
            } else {
                sewerItem.contract = undefined
            }
            if (!sewerItem.status) {
                sewerItem.status = undefined
            }
            if (!sewerItem.radius) {
                sewerItem.radius = undefined
            }
            if (!sewerItem.material) {
                sewerItem.material = undefined
            }
            if (sewerItem.remove) {
                sewerItem.remove = true
            } else {
                sewerItem.remove = undefined
            }
            return sewerItem
        },
    }
}
</script>

<style scoped>

</style>
