<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <contract-filter
                ref="filter"
                :user="user"
                :showOnlyMyContractsOption="true"
                :is-admin="isAdmin"
                @contractSet="setContract"
                @companySet="companySet"
            />
            <b-row class="nopads">
                <div class="col-sm-3">
                    <span class="span-title">{{ $t('storm_sewers.material') }}</span>
                    <b-form-group
                        class="title nopads"
                        label-for="status"
                    >
                        <b-form-input
                            type="number"
                            id="nextInspection"
                            v-model="material"
                            size="sm"
                            min="0"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-3">
                    <span class="span-title">{{ $t('storm_sewers.status') }}</span>
                    <b-form-group
                        class="title nopads"
                        label-for="status"
                    >
                        <b-form-select
                            id="area"
                            v-model="status"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('storm_sewers.select_status') }}
                            </option>
                            <option
                                v-for="item in getStatusOptions()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.text }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
            <b-row>
                <b-row>
                    <div class="col-sm-3">
                        <span class="span-title">{{ $t('storm_sewers.radius_min') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="minRadius"
                        >
                            <b-form-input v-model="minRadius" type="number" id="searchMinRadius"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm-3">
                        <span class="span-title">{{ $t('storm_sewers.radius_max') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="maxRadius"
                        >
                            <b-form-input v-model="maxRadius" type="number" id="searchMaxRadius"></b-form-input>
                        </b-form-group>
                    </div>
                </b-row>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import ContractFilter from '../contract/ContractFilter'
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";

export default {
    name: 'StormSewerDrainSearch',
    components: {ContractFilter},
    mixins: [stormSewersMixin],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    data: function () {
        return {
            contract: null,
            company: null,
            material: null,
            radius: null,
            minRadius: null,
            maxRadius: null,
            status: null
        }
    },
    mounted() {
        this.status = -1
    },
    methods: {
        setContract: function (contract) {
            this.contract = contract
        },
        companySet: function (company) {
            this.company = company
        },
        onSubmit: function () {
            let params = {}
            if (this.contract) {
                params.contract = this.contract
            }
            if(this.material) {
                params.material = this.material
            }
            if(this.minRadius) {
                params.min_radius = this.minRadius
            }
            if(this.maxRadius) {
                params.max_radius = this.maxRadius
            }
            if(this.status && this.status > 0) {
                params.status = this.status
            }
            this.$emit('search', params)
        },
        onReset: function () {
            this.contract = null
            this.company = null
            this.material = null
            this.radius = null
            this.status = null
            this.$refs.filter.resetData()
        }
    }
}
</script>
