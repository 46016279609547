<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="equipment.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('areas.equipment_edit') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('areas.equipment_add') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <h4 class="form-header">{{ $t('power_stations.basic_info')}}</h4>
                <area-filter
                    class="ml-1"
                    ref="areaFilter"
                    :is-admin="isAdmin"
                    :equipment="jsonToEquipment(equipmentUpdate)"
                    @areaSet="setArea"
                />
                <!-- Equipment name -->
                <span class="span-title">
                  {{ $t('equipment.name') }} ( {{ equipmentUpdate.name ? equipmentUpdate.name.length : '0'}} / 100)
                </span>
                <b-form-group
                    class="title"
                    label-for="equipment_name"
                >
                    <b-form-input
                        id="equipment_name"
                        v-model="equipmentUpdate.name"
                        type="text"
                        :maxlength="100"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title">
                      {{ $t('equipment.type') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="equipment_type"
                >
                    <b-form-select
                        id="equipment_type"
                        v-model="equipmentUpdate.type"
                        size="sm"
                        class="sm-3"
                    >
                        <option value="null">
                            {{ $t('equipment.select_equipment_type') }}
                        </option>
                        <option value="1">
                            {{ getEquipmentType(1) }}
                        </option>
                        <option value="2">
                            {{ getEquipmentType(2) }}
                        </option>
                        <option value="3">
                            {{ getEquipmentType(3) }}
                        </option>
                        <option value="4">
                            {{ getEquipmentType(4) }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <!-- Equipment description -->
                <span class="span-title">
                      {{ $t('equipment.description') }} ( {{ equipmentUpdate.description ? equipmentUpdate.description.length : '0'}} / 250)
                </span>
                <b-form-group
                    class="title"
                    label-for="equipment_description"
                >
                    <b-form-input
                        id="equipment_description"
                        v-model="equipmentUpdate.description"
                        type="text"
                        :maxlength="250"
                        size="sm"
                    />
                </b-form-group>

                <!-- Equipment manufacturer -->
                <span class="span-title">
                      {{ $t('equipment.manufacturer') }} ( {{ equipmentUpdate.manufacturer ? equipmentUpdate.manufacturer.length : '0'}} / 100)
                </span>
                <b-form-group
                    class="title"
                    label-for="equipment_manufacturer"
                >
                    <b-form-input
                        id="equipment_manufacturer"
                        v-model="equipmentUpdate.manufacturer"
                        type="text"
                        :maxlength="100"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">{{ $t('inspection_editor.inactive') }}</span>
                <b-form-group
                    class="title"
                    label-for="in_active"
                >
                    <b-form-checkbox
                        id="in_active"
                        v-model="equipmentUpdate.is_inactive"
                        size="mb"
                    />
                </b-form-group>
                <hr/>
                <!-- Equipment location -->
                <h4 class="form-header">{{ $t('areas.location')}}</h4>
                <b-row>
                    <div class="col-sm-6 nopads">
                        <b-form-group
                            class="title"
                            label-for="latitude"
                        >
                            <b-form-input
                                id="latitude"
                                v-model="equipmentUpdate.location.y"
                                type="number"
                                step="0.0000000000001"
                                placeholder="Latitude"
                                size="sm"
                            />
                        </b-form-group>
                        <b-form-group
                            class="title"
                            label-for="longitude"
                        >
                            <b-form-input
                                id="longitude"
                                v-model="equipmentUpdate.location.x"
                                type="number"
                                step="0.0000000000001"
                                placeholder="Longitude"
                                size="sm"
                            />
                        </b-form-group>
                        <div class="col-sm-6 nopads">
                            <b-button
                                :disabled="fetchingLocation"
                                variant="info"
                                class="form-button"
                                @click.stop="setCurrentLocation"
                            >
                                <span class="map-button-text">
                                  {{ $t('common.get_location') }}
                                </span>
                            </b-button>
                            <span
                                v-if="fetchingLocation"
                                class="span-title blink"
                            >
                                {{ $t('common.fetching_location') }}
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-6 area-map-row item-map">
                        <div class="map-container" style="min-height: 15em;">
                            <map-container
                                ref="equipmentMap"
                                :find-user="equipmentUpdate.id < 1"
                                @onDragEnd="updateLocation"
                                @onMapClicked="setLocation"
                                @onMapChanged="init"
                            />
                        </div>
                    </div>
                </b-row>
                <hr/>
                <!-- Equipment photo -->
                <h4 class="form-header">{{ $t('area_report_details.photos')}}</h4>
                <b-row>
                    <div class="col-sm-6 nopads">
                       <span class="span-title">
                          {{ $t('equipment.photo') }}
                       </span>
                        <b-form-group
                            class="title"
                            label-for="equipment_photo"
                        >
                            <b-form-file
                                v-model="equipmentPhotoFile"
                                accept="image/jpeg, image/jpg, image/png"
                                :placeholder="$t('equipment.photo_placeholder')"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-6 area-img-container text-center">
                        <image-view
                            :url="equipmentPhotoUrl"
                            :file="equipmentPhotoFile"
                            :img-size="'lg'"
                        />
                    </div>
                </b-row>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="form-button"
                        @click.stop="$emit('close')"
                    >
                      {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="form-button"
                        :disabled="loading"
                        @click.stop="submitEquipment"
                    >
                          {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {areaHelper} from '../../mixins/AreaMixin'
import {restApi} from '../../mixins/RestApiMixin'
import AreaFilter from '../AreaFilter'
import ImageView from '../../view/ImageView'
import MapContainer from "../../map/MapContainer";
import {mapHelper} from "@/components/mixins/MapMixin";
import {isPointInPolygon} from "geolib";
import {areaReportHelper} from "@/components/mixins/AreaReportMixin";

export default {
    name: 'EquipmentEditor',
    components: {MapContainer, AreaFilter, ImageView},
    mixins: [areaHelper, restApi, mapHelper, areaReportHelper],
    props: {
        equipment: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            area: null,
            fetchingLocation: false,
            areaId: '',
            equipmentPhotoFile: null,
            equipmentPhotoUrl: null,
            equipmentUpdate: this.jsonToEquipment(this.equipment),
            selectedArea: null
        }
    },
    watch: {
        'equipmentUpdate.location': {
            handler: function () {
                this.$refs.equipmentMap.getMap().removeMapMarker(this.equipmentUpdate.id, this.EQUIPMENT)
                this.$refs.equipmentMap.getMap().addDraggableEquipmentMarker(this.equipmentUpdate)
            },
            deep: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
        })
    },
    methods: {
        async init() {
            if (!this.$refs.equipmentMap.getMap()) return;
            if (this.equipmentUpdate !== null && this.equipmentUpdate.photos && this.equipmentUpdate.photos.length) {
                var photoId = this.equipmentUpdate.photos[this.equipmentUpdate.photos.length - 1].id
                if (photoId !== null) {
                    this.equipmentPhotoUrl = this.areaEquipmentPhotoUrl + this.equipmentUpdate.id + '/' + photoId
                }
            }
            if (this.equipmentUpdate && this.equipmentUpdate.location && this.equipmentUpdate.location.y && this.equipmentUpdate.location.x) {
                this.$refs.equipmentMap.getMap().addDraggableEquipmentMarker(this.equipmentUpdate)
            }
        },
        setDefaultValues: function (equipment) {
            if (!this.areaId) {
                EventBus.$emit('show-alert', this.$t('areas.invalid_area'))
                return undefined
            } else {
                equipment.area = {id: this.areaId}
            }
            if (equipment.name === '') {
                EventBus.$emit('show-alert', this.$t('equipment.invalid_name'))
                return undefined
            }
            if (!equipment.type) {
                EventBus.$emit('show-alert', this.$t('equipment.invalid_type'))
                return undefined
            }
            const areaPolygon = this.getAreaPolygon(this.selectedArea)
            const polygon = isPointInPolygon({latitude: equipment.location.y, longitude: equipment.location.x}, areaPolygon)
            if (!polygon) {
                EventBus.$emit('show-alert', this.$t('equipment.inside_polygon'))
                return undefined
            }
            if (!equipment.location || !equipment.location.x || !equipment.location.y) {
                EventBus.$emit('show-alert', this.$t('areas.invalid_location'))
                return undefined
            } else {
                equipment.location = {lat: equipment.location.y, lon: equipment.location.x}
            }
            return equipment
        },
        updateLocation: function (data) {
            this.equipmentUpdate.location.x = data.position.x
            this.equipmentUpdate.location.y = data.position.y
        },
        setLocation: function (data) {
            this.equipmentUpdate.location.x = data.lng
            this.equipmentUpdate.location.y = data.lat
            this.$refs.equipmentMap.getMap().removeMapMarker(this.equipmentUpdate.id, this.EQUIPMENT)
            this.$refs.equipmentMap.getMap().addDraggableEquipmentMarker(this.equipmentUpdate)
        },
        submitEquipment: function () {
            const jsonEquipment = this.setDefaultValues(this.jsonToEquipment(this.equipmentUpdate))
            if (jsonEquipment !== undefined) {
                this.loading = true
                var url = this.areaEditEquipmentUrl
                if (this.equipmentUpdate.id < 1) {
                    url = this.areaEquipmentUrl
                }
                let formData = new FormData()
                if (this.equipmentPhotoFile !== null) {
                    formData.append('photo', this.equipmentPhotoFile)
                }
                this.restPostWithFile(url, jsonEquipment, formData, this.success, this.fail)
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        setCurrentLocation: function () {
            if (navigator.geolocation) {
                this.fetchingLocation = true
                navigator.geolocation.getCurrentPosition(pos => {
                    this.equipmentUpdate.location.y = parseFloat(pos.coords.latitude).toFixed(6)
                    this.equipmentUpdate.location.x = parseFloat(pos.coords.longitude).toFixed(6)
                    this.fetchingLocation = false
                }, () => {
                    this.fetchingLocation = false
                    EventBus.$emit('show-alert', this.$t('common.alert_location_disabled'))
                }, {timeout: 30000, enableHighAccuracy: true})
            }
        },


        setArea: function (area) {
            if (this.selectedArea && this.selectedArea.geometry) {
                this.$refs.equipmentMap.getMap().removePolygon(this.selectedArea.geometry.id)
            }
            if (!area) {
                return
            }
            this.areaId = area.id
            this.selectedArea = area
            if (area.geometry) {
                this.drawArea(area.geometry)
            }
        },

        drawArea: function (data) {
            const geometry = data.rings
            for (const index in geometry) {
                this.$refs.equipmentMap.getMap().drawPolygon(data.id, geometry[index])
            }
        }

    }
}
</script>
<style scoped>
</style>
