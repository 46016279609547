<template>
    <div class="col-12 light-border">
    <span class="span-title">
      {{ $t('contracts.inspections') }}
    </span>
        <b-form v-if="selectedInspections.length > 0" inline>
            <div class="col-sm-12 inspectable-container p-2">
                <div
                    v-for="item in selectedInspections"
                    :key="item.id"
                    :class="{'d-inline' : true}">
                <span class="inspectables inspectables-selected"
                      @click="updateInspectionSelection(item)">
                  {{ item.name }}
                  <strong>
                    X
                  </strong>
                </span>
                </div>
            </div>
        </b-form>
        <div v-else
             class="col-sm-12 p-2">
        <span>
          {{ $t('contracts.no_inspections') }}
        </span>
        </div>
        <span
            v-if="selectedInspections.length !== inspections.length"
            class="span-title p-2">
        {{ $t('contracts.add_inspection') }}
    </span>
        <div class="col-sm-12 inspectable-container p-2">
            <!-- All inspection templates -->
            <div v-for="item in inspections"
                 :key="item.id"
                 :class="{ hidden : isSelected(item.id), 'd-inline' : !isSelected(item.id)}">
                <span class="inspectables"
                      @click="updateInspectionSelection(item)">
                  {{ item.name }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'

export default {
    name: 'TaskTypeInspections',
    mixins: [workManagementHelper, restApi],
    props: {
        taskType: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            inspections: [],
            selectedInspections: []
        }
    },

    mounted: function () {
        if (this.taskType.inspections) {
            this.selectedInspections = this.taskType.inspections
        }
        this.fetchInspectionTemplates()
    },
    methods: {
        fetchInspectionTemplates: function () {
            this.inspections = []
            this.loading = true
            this.restFetchParams(this.inspectionTemplateUrl, {type: 2}, this.handleResults)
        },
        handleResults: function (response) {
            this.loading = false
            if (response != null) {
                this.inspections = response.data
            }
        },
        isSelected: function (id) {
            return this.selectedInspections.find(item => item.id === id) !== undefined
        },
        updateInspectionSelection: function (inspection) {
            var index = this.selectedInspections.findIndex(item => item.id === inspection.id)
            if (index >= 0) {
                this.selectedInspections.splice(index, 1)
            } else {
                this.selectedInspections.push(inspection)
            }
            this.$emit('onInspectionsUpdated', this.selectedInspections)
        },
        getInspectionIds() {
            let results = []
            this.selectedInspections.forEach(item => results.push(item.id))
            return results
        }
    }

}
</script>
