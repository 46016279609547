<template>
    <div class="col-sm-12 nopads">
        <b-form v-if="pricingModelUpdate" class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    class="form-title editor-title"
                >
                    {{ $t('pricing_models.add') }}
                </h2>
            </div>
            <span class="span-title p-2">
                {{ $t('pricing_models.unit1') }}
            </span>
            <b-form-group
                 class="title p-2"
                 label-for="name"
            >
                <b-form-select
                    v-model="pricingModelUpdate.unit1"
                    :options="unit1Options"
                    size="mb"
                />
            </b-form-group>
            <span class="span-title p-2">
                {{ $t('pricing_models.unit2') }}
            </span>
            <b-form-group
                class="title p-2"
                label-for="name"
            >
                <b-form-select
                    v-model="pricingModelUpdate.unit2"
                    :options="unit2Options"
                    size="mb"
                />
            </b-form-group>
            <!-- Buttons -->
            <div class="col-sm-12 button-container">
                <b-button
                    variant="danger"
                    class="form-button"
                    @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="form-button"
                    :disabled="loading"
                    @click.stop="submit"
                >
                  {{ $t('common.save') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'PricingModelEditor',
    mixins: [restApi],
    props: {
        pricingModel: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            pricingModelUpdate: this.pricingModel,
            unit1Options: [
                { value: null, text: this.$t('pricing_models.unit_select')},
                { value: 1, text: this.$t('pricing_models.unit_km')},
                { value: 2, text: this.$t('pricing_models.unit_h')},
                { value: 3, text: this.$t('pricing_models.unit_pcs')},
                { value: 4, text: this.$t('pricing_models.unit_road_km')},
                { value: 5, text: this.$t('pricing_models.unit_kg')},
                { value: 6, text: this.$t('pricing_models.unit_tons')},
                { value: 7, text: this.$t('pricing_models.unit_square_meters')},
                { value: 8, text: this.$t('pricing_models.unit_cubic_meters')},
                { value: 9, text: this.$t('pricing_models.unit_meters')},
                { value: 10, text: this.$t('pricing_models.unit_road_meters')},
                { value: 11, text: this.$t('pricing_models.unit_material_report')},
            ],
            unit2Options: [
                { value: null, text: this.$t('pricing_models.unit_select')},
                { value: 101, text: this.$t('pricing_models.unit_duration')},
                { value: 102, text: this.$t('pricing_models.unit_reserve')},
                { value: 103, text: this.$t('pricing_models.unit_km')},
                { value: 104, text: this.$t('pricing_models.unit_area')},
                { value: 105, text: this.$t('pricing_models.unit_vehicle_plow')},
                { value: 106, text: this.$t('pricing_models.unit_vehicle_spread')},
                { value: 107, text: this.$t('pricing_models.unit_work_distance')},
                { value: 108, text: this.$t('pricing_models.unit_order')}
            ]
        }
    },

    methods: {
        setDefaultValues: function (pricingModel) {
            if (!pricingModel.unit1) {
                EventBus.$emit('show-alert', this.$t('pricing_models.unit_missing'))
                return undefined
            }
            return pricingModel
        },

        submit: function () {
            var json = this.setDefaultValues(this.pricingModelUpdate)
            if (json !== undefined) {
                this.loading = true
                this.restAdd(this.pricingModeUrl, json, this.success, this.fail)
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
