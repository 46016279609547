<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header">
        <span class="title">
          {{ $t('contractor_reports.title') }}</span>
            </div>
        </b-navbar>
        <contract-report-filter
            :user="user"
            @search="fetchReports"
        />
        <div class="header col-sm-12 button-container pt-1">
            <font-awesome-icon
                v-if="persons.length"
                icon="print"
                class="print-icon"
                @click.stop="print"
            />
        </div>
        <div v-if="noResults" class="col-sm-12">
            <span slot="noOptions">{{ $t('common.no_results') }}</span>
        </div>
        <div id="printContainer" class="report" v-if="company">
            <div class="header">
                <span class="title">{{ company.name }}</span>
            </div>
            <hr class="report-divider"/>
            <!-- Persons -->
            <div class="col-sm-12 list-container" v-if="persons && persons.length > 0">
                <h3>{{ $t('contractor_reports.persons') }}</h3>
                <contractor-person-results
                    :results="persons"
                />
            </div>
            <!-- Vehicles -->
            <div class="col-sm-12 list-container" v-if="vehicles && vehicles.length > 0">
                <h3>{{ $t('contractor_reports.vehicles') }}</h3>
                <contractor-vehicle-results
                    :results="vehicles"
                />
            </div>
            <div class="row print-only">
                <span class="item-detail-text">{{ $t('common.printed') }} {{ $d(new Date()) }}</span>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {EventBus} from '@/event-bus'
import {downloadHelper} from '../mixins/DownloadMixin'
import {printHelper} from '../mixins/PrintMixin'
import {vehicleHelper} from '../mixins/VehicleMixin'
import {Printd} from 'printd'
import ContractReportFilter from "@/components/contractorreport/ContractorReportFilter";
import ContractorPersonResults from "@/components/contractorreport/ContractorPersonResults";
import ContractorVehicleResults from "@/components/contractorreport/ContractorVehiclesResults";

export default {
    name: 'contractor-reports',
    components: {ContractorVehicleResults, ContractorPersonResults, ContractReportFilter},
    mixins: [restApi, timeUtils, downloadHelper, printHelper, vehicleHelper],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            company: null,
            persons: [],
            vehicles: [],
            loading: false,
            noResults: false
        }
    },
    mounted() {
        this.d = new Printd()
    },
    methods: {
        fetchReports: function (params) {
            this.noResults = false
            this.persons = []
            this.vehicles = []
            this.company = null
            if (params.contractorId) {
                this.loading = true
                if (parseInt(params.activeUsers) !== 1) {
                    this.restFetch(this.contractorReportUrl + "/" + params.contractorId, this.handleResponse, this.fail)
                } else {
                    this.restFetchParams(this.contractorReportUrl + "/" + params.contractorId, {activeUsers: params.activeUsers},
                        this.handleResponse, this.fail)
                }
            }
        },
        handleResponse: function (response) {
            this.loading = false
            if (!response.data || response.data.length < 1) {
                this.noResults = true
            } else {
                this.company = response.data.company
                this.persons = response.data.persons
                this.vehicles = response.data.vehicles
            }
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
        },
        print: function () {
            if (!this.loading) {
                this.d.print(document.getElementById('printContainer'), this.printStyle)
            }
        }
    }
}
</script>
