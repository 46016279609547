<template>
    <div class="col-sm-12 editor-form">
        <div
            v-if="!showContractorSelection && !showCustomerSelection"
            class="col-sm-12 p-3"
        >
            <h2 class="text-center">
                {{ $t('contracts.role_question') }}
            </h2>
            <div class="wizard-button-container">
                <b-button
                    class="wizard-button"
                    variant="outline-success"
                    @click.stop="setAsCustomer"
                >
                    <span>{{ $t('contracts.i_am_customer') }}</span>
                </b-button>
                <b-button
                    class="wizard-button"
                    variant="outline-primary"
                    @click.stop="setAsContractor"
                >
                    <span>{{ $t('contracts.i_am_contractor') }}</span>
                </b-button>
            </div>
        </div>

        <div
            v-else-if="showContractorSelection"
            class="col-sm-12"
        >
            <h2>
                {{ $t('contracts.select_contractor') }}
            </h2>
            <b-form-group
                class="title"
                label-for="contractorContract"
            >
                <b-form-select
                    id="contractorContracts"
                    v-model="selectedContractor"
                    size="mb"
                >
                    <template slot="first">
                        <option
                            :value="null"
                            disabled
                        >
                            {{ $t('contracts.select_contractor') }}
                        </option>
                    </template>
                    <option
                        v-for="option in companies"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </b-form-select>
            </b-form-group>
            <div class="wizard-button-container">
                <b-button
                    class="wizard-button"
                    variant="danger"
                    @click.stop="resetCustomer"
                >
                    <span>{{ $t('common.cancel') }}</span>
                </b-button>
                <b-button
                    class="wizard-button"
                    variant="success"
                    @click.stop="selectContractor"
                >
                    <span>{{ $t('common.continue') }}</span>
                </b-button>
            </div>
        </div>

        <div
            v-else-if="showCustomerSelection"
            class="col-sm-12"
        >
            <h2>{{ $t('contracts.select_customer') }}</h2>
            <b-form-group
                class="title"
                label-for="customerContracts"
            >
                <b-form-select
                    id="customerContracts"
                    v-model="selectedCustomer"
                    size="mb"
                >
                    <template slot="first">
                        <option
                            :value="null"
                            disabled
                        >
                            {{ $t('contracts.select_customer') }}
                        </option>
                    </template>
                    <option
                        v-for="option in companies"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </b-form-select>
            </b-form-group>
            <div class="wizard-button-container">
                <b-button
                    class="wizard-button"
                    variant="danger"
                    @click.stop="resetContractor"
                >
                    <span>{{ $t('common.cancel') }}</span>
                </b-button>
                <b-button
                    class="wizard-button"
                    variant="success"
                    @click.stop="selectCustomer"
                >
                    <span>{{ $t('common.continue') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'

export default {
    name: 'ContractWizard',
    mixins: [workManagementHelper, restApi],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            companiesLoaded: false,
            companies: [],
            showCustomerSelection: false,
            showContractorSelection: false,
            selectedCustomer: null,
            selectedContractor: null
        }
    },

    methods: {

        fetchCustomers: function () {
            this.loading = true
            this.restFetch(this.customerUrl, this.handleCustomers)
        },

        handleCustomers: function (response) {
            this.companiesLoaded = true
            this.companies = response.data
            this.loading = !this.companiesLoaded && !this.taskTypesLoaded
        },

        fetchContractors: function () {
            this.loading = true
            this.restFetch(this.contractorUrl, this.handleContractors)
        },

        handleContractors: function (response) {
            this.companiesLoaded = true
            this.companies = response.data
            this.loading = !this.companiesLoaded && !this.taskTypesLoaded
        },

        setAsCustomer: function () {
            this.fetchContractors()
            // Emit user's company as customer
            this.selectedCustomer = this.user.company.id
            this.showContractorSelection = true
            var customer = {id: this.user.company.id, name: this.user.company.name}
            this.$emit('customerSet', customer)
        },

        setAsContractor: function () {
            this.fetchCustomers()
            this.selectedContractor = this.user.company.id
            this.showCustomerSelection = true
            var contractor = {id: this.user.company.id, name: this.user.company.name}
            this.$emit('contractorSet', contractor)
        },

        selectContractor: function () {
            // emit contractor selected with company id and name
            var contractor = this.companies.find(company => company.id === this.selectedContractor)
            if (contractor) {
                this.$emit('contractorSet', contractor)
            }
        },

        resetCustomer: function () {
            this.selectedCustomer = null
            this.$emit('closeWizard')
        },

        selectCustomer: function () {
            // emit customer selected with company id and name
            var customer = this.companies.find(company => company.id === this.selectedCustomer)
            if (customer) {
                this.$emit('customerSet', customer)
            }
        },

        resetContractor: function () {
            this.selectedContractor = null
            this.$emit('closeWizard')
        }
    }

}
</script>
