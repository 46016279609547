/* global RoutaJs */
export default class RoutaMobile {
    static getInstance() {
        if (typeof RoutaMobile.instance == 'undefined') {
            RoutaMobile.instance = new RoutaMobile()
        }
        return RoutaMobile.instance
    }

    constructor() {
        if (typeof RoutaJs === 'undefined') {
            throw new Error('RoutaJs mobile interface not found!')
        }
        this.features = JSON.parse(RoutaJs.getFeatures())

        Object.defineProperty(window, 'RoutaWeb', {
            get() {
                return RoutaMobile.getInstance().routaWeb
            }
        })
        this.routaWeb = {
            mapZoomToLocation() {
            },
            // eslint-disable-next-line no-unused-vars
            updateContract(contract) {
            }
        }
    }

    getFeatureVersion(feature) {
        return this.features[feature]
    }

    getToken() {
        switch (this.getFeatureVersion('getToken')) {
            case 1:
                return RoutaJs.getToken()
        }
    }

    getVehicle() {
        switch (this.getFeatureVersion('getVehicle')) {
            case 1:
                return JSON.parse(RoutaJs.getVehicle())
        }
    }

    close() {
        switch (this.getFeatureVersion('close')) {
            case 1:
                return RoutaJs.close()
        }
    }

    onClick() {
        switch (this.getFeatureVersion('onClick')) {
            case 1:
                return RoutaJs.onClick()
        }
    }

    getContract() {
        switch (this.getFeatureVersion('getContract')) {
            case 1:
                return JSON.parse(RoutaJs.getContract())
        }
    }
}
