<template>
    <div class="col-sm-12 nopads">
        <h3 class="pl-3 pr-2" v-if="!editMode && !showWizard">{{ $t('ins_wizard.select_contract') }}</h3>
        <!-- Contract search -->
        <div class="search-form">
            <b-row class="pt-2 pb-2">
                <div class="col-sm-4">
                    <span class="search-title">{{ $t('contracts.name') }}</span>
                    <b-form-input
                        v-model="name"
                        size="sm"
                        type="text"
                    />
                </div>
                <div class="col-sm-4">
                    <span class="search-title">{{ $t('contracts.number') }}</span>
                    <b-form-input
                        v-model="number"
                        size="sm"
                        type="text"
                    />
                </div>
                <div class="col-sm-4">
                    <span class="search-title">{{ $t('contracts.status') }}</span>
                    <b-form-checkbox
                        v-model="open"
                        size="md"
                        class="mt-1"
                        type="text"
                        value=1
                        unchecked-value=0>
                        {{ $t('contracts.only_open') }}
                    </b-form-checkbox>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    variant="primary"
                    class="result-button"
                    size="sm"
                    v-on:click.stop="fetchContracts"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
            </div>
        </div>
        <transition name="fade">
            <div class="col-sm-12 nopads mt-3">
                <!-- Show open contracts  -->
                <div v-if="!editMode && !showWizard && contracts && contracts.length > 0" class="col-12">
                    <div v-for="item in contracts"
                         v-bind:key="item.id"
                         class="inspection-selector"
                         @click.stop="$emit('onContractSelected', item)">
                        <span class="title inspection-wizard-selector-text nopads">{{ getTitle(item) }}</span>
                        {{ item.customer.name }} - {{ item.contractor.name }}
                    </div>
                </div>
            </div>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'
import {workManagementHelper} from '../../mixins/WorkManagementMixin'

export default {
    name: 'InspectionContractSelector',
    mixins: [restApi, timeUtils, workManagementHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        onlyElyContracts: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            editMode: false,
            showWizard: false,
            contracts: [],
            loading: false,
            open: 1,
            name: null,
            number: null,
            contract: this.newContract()
        }
    },
    mounted() {
        this.fetchContracts()
    },
    methods: {
        fetchContracts: function () {
            this.contracts = []
            this.loading = true
            let params = {
                open: this.open,
                name: this.name,
                number: this.number
            }
            this.restFetchParams(this.contractUrl, params, this.handleResults)
        },
        handleResults: function (response) {
            this.loading = false
            if (response != null) {
                const data = response.data
                this.contracts = this.onlyElyContracts ? data.filter(x => x.ely_contract) : data
            }
        },
        getTitle(item) {
            let result = item.name
            if (item.contract_number && item.contract_number.length > 0) {
                result += ' - ' + item.contract_number
            }
            return result
        },
        showList() {
            this.editMode = false
            this.fetchOpenContracts();
        }
    }
}
</script>
