<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pt-2">
        <div class="col-sm-12 nopads">
            <!-- Search criteria -->
            <keep-alive>
                <transition
                    v-if="!editMode"
                    name="fade"
                >
                    <work-time-search ref="searchView" @search="searchWithParams"/>
                </transition>
            </keep-alive>

            <div
                v-if="!editMode"
                class="button-container col-sm-12 trip-buttons"
            >
                <b-button
                    variant="success"
                    size="sm"
                    class="result-button"
                    @click="addItem"
                >
                    {{ $t('common.add_new') }}
                </b-button>
            </div>

            <b-modal
                v-model="editMode"
                body-class="fill-modal"
                :hide-header="true"
                size="lg"
                :hide-footer="true"
            >
                <meal-allowance-editor
                    :allowance="result"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    @closeAndUpdate="updateList"
                    @close="showList"
                />
            </b-modal>

            <div
                v-if="!editMode && results"
                id="accordion"
                class="col-sm-12 list-container"
            >
                <div class=" list-content result-table">
                    <b-table
                        small
                        :items="results"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        @row-clicked="editItem"
                    >
                        <template
                            v-slot:cell(date)="row"
                        >
                            <span>{{ getShortDateString(row.value) }}</span>
                        </template>
                        <template
                            v-slot:cell(user)="row"
                        >
                            <span>{{ row.value.first_name + ' ' + row.value.last_name }}</span>
                        </template>
                        <template
                            v-slot:cell(approver)="row"
                        >
                            <span v-if="row.value">{{ row.value.first_name + ' ' + row.value.last_name }}</span>
                        </template>
                        <template
                            v-slot:cell(increased_allowance)="row"
                        >
                            <span>{{ getTypeString(row.value) }}</span>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import WorkTimeSearch from '../worktime/WorkTimeSearch'
import {timeUtils} from '../mixins/TimeUtils'
import {allowanceHelper} from '../mixins/AllowanceMixin'
import MealAllowanceEditor from "./MealAllowanceEditor";

export default {
    name: 'MealAllowances',
    components: {MealAllowanceEditor, WorkTimeSearch},
    mixins: [restApi, timeUtils, allowanceHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            loading: false,
            searchParams: null,
            editMode: false,
            results: [],
            result: null,
            sortBy: 'start_time',
            sortDesc: false,
            fields: [
                {key: 'user', label: this.$t('daily_allowance.user'), sortable: true},
                {key: 'date', label: this.$t('work_report.start_time'), sortable: true},
                {key: 'approver', label: this.$t('meal_allowance.approver'), sortable: true},
                {key: 'increased_allowance', label: this.$t('meal_allowance.type'), sortable: true}
            ]
        }
    },
    mounted() {
        this.searchParams = this.$refs.searchView.getSearchParams()
    },
    methods: {
        updateList: function () {
            this.searchWithParams(this.searchParams)
        },
        searchWithParams: function (params) {
            this.results = []
            this.loading = true
            this.searchParams = params
            this.restFetchParams(this.mealAllowanceUrl, params, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
            this.showList()
        },
        showList: function () {
            this.editMode = false
        },
        editItem: function (item) {
            this.result = item
            this.editMode = true
        },
        addItem: function () {
            this.result = null
            this.editMode = true
        },
        getTypeString: function (value) {
            if (value) {
                return this.$t('meal_allowance.increased')
            }
            return this.$t('meal_allowance.normal')
        }
    }
}
</script>

