<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showInspectionList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('inspection_template.inspection_title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showInspectionList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <inspection-list
                    v-if="!editMode"
                    :is-admin="isAdmin"
                    :results="results"
                    @add="addInspection"
                    @edit="editInspection"
                />
            </transition>

            <transition name="fade">
                <inspection-editor
                    v-if="editMode"
                    :is-admin="isAdmin"
                    :inspection="inspection"
                    @close="showInspectionList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import InspectionList from './InspectionList'
import InspectionEditor from './InspectionEditor'
import {inspectionHelper} from '../mixins/InspectionMixin'

export default {
    name: 'Inspections',
    components: {InspectionEditor, InspectionList},
    mixins: [restApi, inspectionHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            inspection: null,
            editMode: false,
            loading: true
        }
    },
    mounted: function () {
        this.fetchInspections()
    },
    methods: {
        fetchInspections: function () {
            this.loading = true
            this.restFetch(this.inspectionTemplateUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        addInspection: function () {
            this.inspection = this.newInspection()
            this.editMode = true
        },
        editInspection: function (inspection) {
            this.inspection = inspection
            this.editMode = true
        },
        showInspectionList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchInspections()
            this.showInspectionList()
        }

    }
}
</script>
editInspectable
