<template>
    <div class="search-form p-1 pb-3">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <b-row class="p-1">
                <div class="col-sm-6 pr-1">
                    <b-form-group
                        class="nopads"
                        label-for="start"
                    >
                        <span class="span-title">
                          {{ $t('trip_list.road_sec_from') }}
                        </span>
                        <b-form-input
                            id="start"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-6 pl-1 pr-3">
                    <b-form-group
                        class="nopads"
                        label-for="end"
                    >
                        <span class="span-title">
                            {{ $t('trip_list.road_sec_to') }}
                        </span>
                        <b-form-input
                            id="end"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads pr-2 pl-1">
                <div class="col-sm-12">
                    <open-contract-filter-multi-select
                        :to="toDate"
                        :from="fromDate"
                        :show-only-my-contracts-option="true"
                        @loading="loading=true"
                        @loadingCompleted="loading=false"
                        @companySelected="setCompany"
                        @contractsSelected="setContracts"
                    />
                </div>
            </b-row>
            <b-row class="nopads ml-2">
                <div class="col-sm-6 pr-2">
                    <b-form-group
                        class="nopads"
                        label-for="vehicle"
                    >
                        <span class="span-title mt-0">
                          {{ $t('trip_list.vehicle') }}
                        </span>
                        <b-form-select
                            id="vehicle"
                            v-model="vehicle"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option
                                v-for="v in vehicles"
                                :key="v.id"
                                :value="v.id"
                            >
                                {{ v.make }} {{ v.vehicle_model }} {{ v.license_plate }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>

            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                    :disabled="!fromDate || !toDate"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'
import OpenContractFilterMultiSelect from "../../contract/OpenContractFilterMultiSelect";

export default {
    name: 'VehicleAllocationSearch',
    components: {OpenContractFilterMultiSelect},
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            loading: false,
            vehicle: -1,
            company: null,
            vehicles: [],
            contracts: [],
            contractIds: [],
            fromDate: null,
            toDate: null,
        }
    },
    mounted: function () {
        this.fetchVehicles()
        this.toDate = this.getYesterday()
    },
    methods: {
        fetchVehicles: function () {
            this.loading = true
            this.restFetch(this.vehicleUrl, this.handleVehicles)
        },

        handleVehicles: function (response) {
            this.loading = false
            response.data && response.data.length > 0 && response.data.sort(function (a, b) {
                const nameA = a.make + a.vehicle_model + a.license_plate;
                const nameB = b.make + b.vehicle_model + b.license_plate;
                if (nameA.toUpperCase() < nameB.toUpperCase()) {
                    return -1;
                }
                if (nameA.toUpperCase() > nameB.toUpperCase()) {
                    return 1;
                }
                return 0;
            })

            this.vehicles = response.data
        },

        onSubmit: function () {
            var params = {}
            if (this.vehicle > 0) {
                params.vehicle = this.vehicle
            }
            if(this.contractIds && this.contractIds.length > 0) {
                params.contractIds = this.contractIds
            }
            if(this.company && this.company.id) {
                params.customer = this.company.id
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.from = this.fromDate
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.to = this.toDate
            }
            this.$emit('search', params)
        },

        setCompany: function (company) {
            this.company = company
        },

        setContracts: function (contracts) {
            this.contracts = contracts
            this.contractIds = []
            contracts.map(contract => {
                this.contractIds.push(contract.id)
            })
        },

        onReset: function () {
            this.vehicle = -1
            this.fromDate = null
            this.toDate = null
            this.company = null
            this.contracts = []
            this.contractIds = []
        }
    }
}
</script>

<style scoped>

</style>
