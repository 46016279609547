<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-content nopads">
        <transition name="fade">
            <div class="list-content result-table pointer">
                <b-table
                    show-empty
                    small
                    striped
                    @row-clicked="rowClicked"
                    :items="culverts"
                    :fields="fields"
                />
            </div>
        </transition>
    </div>
</template>

<script>


export default {
    name: 'culvert-list',
    props: {
        culverts: {
            type: Array,
            default() {
                return []
            }
        }
    },
    computed: {
        fields() {
            return [
                {key: 'digiroad_id', label: this.$t('culvert.digiroad_id'), sortable: true},
                {key: 'type', label: this.$t('bridge.type'), sortable: true},
                {key: 'material', label: this.$t('culvert.material'), sortable: true},
                {key: 'size', label: this.$t('culvert.size'), sortable: true},
                {key: 'length', label: this.$t('bridge.length'), sortable: true},
                {key: 'start_distance', label: this.$t('bridge.start_distance'), sortable: true},
                {key: 'city', label: this.$t('bridge.city'), sortable: true},
                {key: 'road_num', label: this.$t('bridge.road_num'), sortable: true},
                {key: 'sec_num', label: this.$t('bridge.sec_num'), sortable: true},
                {key: 'owner', label: this.$t('culvert.owner'), sortable: true},
                {key: 'job_area', label: this.$t('bridge.job'), sortable: true}]
        }
    },
    methods: {
        rowClicked: function (details) {
            this.$emit('rowClicked', details)
        }
    }
}
</script>
