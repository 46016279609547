<template>
    <div v-if="inspections && inspections.length > 0" class="nopads">
        <b-row>
            <span class="section-title">{{ $t('contract_reports.area_inspections') }} ({{inspections.length}})</span>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : inspectionsVisible}"
                    v-b-toggle.areaInspectionDetails
                    @click.stop="inspectionsVisible = !inspectionsVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="areaInspectionDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <!-- Summary -->
            <div class="col-12 text-center">
                <b-card-group deck style="margin-bottom: .5em">
                    <b-card
                        header-bg-variant="dark"
                        header-text-variant="white"
                        border-variant="dark"
                        align="center"
                        :header="$t('contract_reports.inspections_equipment_result_options.total')">
                        <b-card-text class="summary-number total text-center">{{ resultTotal }}</b-card-text>
                    </b-card>
                    <b-card header-bg-variant="success"
                            header-text-variant="white"
                            border-variant="success"
                            align="center"
                            :header="$t('contract_reports.inspections_equipment_result_options.ok')">
                        <b-card-text class="summary-number completed text-center">{{ resultOk }}</b-card-text>
                    </b-card>
                    <b-card header-bg-variant="warning"
                            border-variant="warning"
                            align="center"
                            :header="$t('contract_reports.inspections_equipment_result_options.defects')">
                        <b-card-text class="summary-number open text-center">{{ resultDefect }}</b-card-text>
                    </b-card>
                    <b-card header-bg-variant="danger"
                            header-text-variant="white"
                            border-variant="danger"
                            align="center"
                            :header="$t('contract_reports.inspections_equipment_result_options.ban')">
                        <b-card-text class="summary-number ban text-center">{{ resultBan }}</b-card-text>
                    </b-card>
                    <b-card header-bg-variant="secondary"
                            header-text-variant="white"
                            border-variant="secondary"
                            align="center"
                            :header="$t('contract_reports.inspections_equipment_result_options.skipped')">
                        <b-card-text class="summary-number text-center">{{ resultSkip }}</b-card-text>
                    </b-card>
                </b-card-group>
            </div>
            <area-contract-report-list
                :results="inspections"
                @inspectionSelected="showInspectionReport"/>
        </b-collapse>

        <b-modal
            ref="inspectionAreaReportDetails"
            body-class="fill-modal"
            size="lg"
            :hide-header="true"
            :hide-footer="true"
        >
            <area-report-details
                id="details"
                ref="details"
                :report-details="inspection"
                :is-observer="isObserver"
                @close="inspection = null"
                @closeAndUpdate="closeAndUpdate"
            />
        </b-modal>
        <hr class="report-divider nopads"/>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {inspectionState} from "@/components/mixins/AreaReportMixin";
import AreaReportDetails from "@/components/area/areareport/AreaReportDetails";
import AreaContractReportList from "@/components/area/areareport/AreaContractReportList";

export default {
    name: "ContractReportAreaInspections",
    components: {AreaReportDetails, AreaContractReportList},
    mixins: [restApi, inspectionState],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        isObserver: Boolean
    },
    data: function () {
        return {
            inspections: [],
            inspection: null,
            resultTotal: 0,
            resultOk: 0,
            resultDefect: 0,
            resultBan: 0,
            resultSkip: 0,
            inspectionsVisible: false
        }
    },
    watch: {
        searchParams: function () {
            this.fetchInspections()
        }
    },
    mounted() {
        this.fetchInspections()
    },
    methods: {
        fetchInspections: function () {
            if (this.searchParams && this.searchParams.dateTo && this.searchParams.dateFrom && this.searchParams.contract) {
                let params = {
                    from: new Date(this.searchParams.dateFrom).toISOString(),
                    to: new Date(this.searchParams.dateTo).toISOString(),
                    contract: this.searchParams.contract
                }
                this.inspections = []
                this.$emit('loading', true)
                this.restFetchParams(this.areaReportUrl, params, this.handleResponse, this.handleFail)
            }
        },
        handleResponse: function (response) {
            this.$emit('loading', false)
            this.inspections = response.data
            this.setSummaryData()
        },
        handleFail: function () {
            this.$emit('loading', false)
        },
        showInspectionReport(item) {
            this.inspection = item
            this.$refs.inspectionAreaReportDetails.show()
        },
        closeAndUpdate() {
            this.$refs.inspectionAreaReportDetails.hide()
            this.inspection = null
            this.inspectionsVisible = false
            this.fetchInspections()
        },
        setSummaryData() {
            if (this.inspections) {
                this.inspections.forEach(item => {
                    item.equipment_inspection_results.forEach(inspection_result => {
                        this.resultTotal++
                        switch (inspection_result.result) {
                            case inspectionState.resultOk:
                                this.resultOk++
                                break
                            case inspectionState.resultDefect:
                                this.resultDefect++
                                break
                            case inspectionState.resultBan:
                                this.resultBan++
                                break
                            case inspectionState.resultSkip:
                                this.resultSkip++
                                break
                        }
                    })
                })
            }
        }
    }
}
</script>
