<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <contract-filter
                ref="filter"
                :user="user"
                :showOnlyMyContractsOption="true"
                :is-admin="isAdmin"
                @contractSet="setContract"
                @companySet="companySet"
            />
            <b-row class="nopads">
                <div class="col-sm-3">
                    <span class="span-title">{{ $t('power_stations.next_inspection_year') }}</span>
                    <b-form-group
                        class="title nopads"
                        label-for="status"
                    >
                        <b-form-input
                            type="number"
                            id="nextInspection"
                            v-model="nextInspectionYear"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import ContractFilter from '../contract/ContractFilter'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'PowerStationSearch',
    components: {ContractFilter},
    mixins: [timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    data: function () {
        return {
            contract: null,
            company: null,
            nextInspectionYear: null
        }
    },
    methods: {
        setContract: function (contract) {
            this.contract = contract
        },
        companySet: function (company) {
            this.company = company
        },
        onSubmit: function () {
            let params = {}
            if (this.contract) {
                params.contract = this.contract
            }
            if (this.nextInspectionYear) {
                params.next_inspection_year = this.nextInspectionYear
            }
            this.$emit('search', params)
        },
        onReset: function () {
            this.contract = null
            this.nextInspectionYear = null
            this.company = null
            this.$refs.filter.resetData()
        }
    }
}
</script>
