<template>
    <div class="mobile_app">
        <div
            v-if="error"
            class="col-sm-12"
        >
            <h2 class="warning">{{ error }}</h2>
        </div>

        <!-- Assignments -->
        <work-assignment-mobile-list
            v-if="user"
            :results="results"
            @closeAndUpdate="fetchAssignments"
        />
        <div class="col-sm-12 mobile-button-panel">
            <b-row class="nopads">
                <div
                    class="col-sm-6 mobile-button"
                    @click.stop="fetchUserAssignments"
                    :class="{pressed : userAssignments}"
                >
                    {{ $t('work_assignment.user_assignments') }}
                </div>
                <div
                    class="col-sm-6 mobile-button"
                    @click.stop="fetchUnassigned"
                    :class="{pressed : !userAssignments}"
                >
                    {{ $t('work_assignment.open_assignments') }}
                </div>
            </b-row>
        </div>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import WorkAssignmentMobileList from './WorkAssignmentMobileList'
import {userHelper} from '../mixins/UserMixin'
import RoutaMobile from '../../modules/RoutaMobile'

export default {
    name: 'WorkAssignmentsMobile',
    components: {WorkAssignmentMobileList},
    mixins: [restApi, userHelper],
    data: function () {
        return {
            routaMobile: null,
            loading: true,
            isAdmin: Boolean,
            isObserver: Boolean,
            user: null,
            error: null,
            userAssignments: true,
            results: []
        }
    },
    mounted: function () {
        this.routaMobile = new RoutaMobile()
        let token = this.routaMobile.getToken()
        if (token) {
            this.$store.commit('storeToken', token)
            this.initUser()
        } else {
            this.error = 'No access'
            this.loading = false
        }
    },
    methods: {
        initUser: function () {
            this.loading = true
            this.restFetch(this.userInfoUrl, this.handleUserResponse)
        },
        handleUserResponse: function (response) {
            if (response && response.status === 200) {
                this.user = response.data
                if (this.user && this.user.roles) {
                    this.isAdmin = this.hasAdmin(this.user.roles)
                    this.isObserver = this.hasObserver(this.user.roles)
                }
                this.fetchAssignments()
            } else {
                this.error = 'Error'
            }
            this.loading = false
        },
        fetchAssignments: function () {
            this.results = []
            if (this.userAssignments) {
                this.fetchUserAssignments()
            } else {
                this.fetchUnassigned()
            }
        },
        fetchUserAssignments: function () {
            this.loading = true
            this.userAssignments = true
            this.restFetch(this.workAssignmentUserUrl, this.handleResponse)
        },
        fetchUnassigned: function () {
            this.loading = true
            this.userAssignments = false
            this.restFetch(this.workAssignmentUnassignedUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            if (response && response.status === 200) {
                this.results = response.data
            } else {
                this.error = 'Error'
            }
            this.loading = false
        }
    }
}
</script>
