<template>
    <div class="col-sm-12 nopads">
        <b-form
            v-if="!assignmentMode"
            class="col-sm-12 editor-form"
        >
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="defectUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('defects.edit') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('defects.new') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
        <span class="span-title">
          {{ $t('areas.area') }}
        </span>
                <b-form-select
                    id="area"
                    v-model="defectUpdate.area.id"
                    size="sm"
                    class="sm-3"
                    :disabled="!isNew"
                    @change="onAreaChange"
                >
                    <option
                        v-for="area in areas"
                        :key="area.id"
                        :value="area.id"
                    >
                        {{ area.name }}
                    </option>
                </b-form-select>

                <span class="span-title">
          {{ $t('area_defects.equipment') }}
        </span>
                <b-form-select
                    id="equipment"
                    v-model="defectUpdate.area_equipment"
                    size="sm"
                    class="sm-3"
                >
                    <option :value="null">
                        {{ $t('area_defects.select_equipment') }}
                    </option>
                    <option
                        v-for="item in equipment"
                        :key="item.id"
                        :value="item"
                    >
                        {{ item.name }}
                    </option>
                </b-form-select>

                <span class="span-title">
          {{ $t('defects.description') }}
        </span>
                <b-form-group class="title">
                    <b-form-input
                        v-model="defectUpdate.description"
                        type="text"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title">
          {{ $t('defects.status') }}
        </span>
                <b-form-group class="nopads">
                    <b-form-select
                        id="status"
                        v-model="defectUpdate.status"
                        size="sm"
                        class="sm-3"
                    >
                        <option
                            v-if="!isNew"
                            :value="2"
                        >
                            {{ $t('inspection.options.okay') }}
                        </option>
                        <option :value="resultDefect">
                            {{ $t('inspection.options.defects') }}
                        </option>
                        <option :value="resultBan">
                            {{ $t('inspection.options.ban') }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <work-assignment-view
                    v-if="!isNew"
                    :type="1"
                    :id="defect.id"
                    :can-edit="true"
                    :can-add="isAdmin || isObserver"
                    @editAssignment="editAssignment"
                />

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading || !validateFields()"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="!isNew && assignmentMode">
            <transition name="fade">
                <work-assignment-editor
                    :area-defect="defect"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    :work-assignment="workAssignment"
                    @close="setAssignmentEditorVisibility(false)"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {areaHelper as AreaHelper} from '../../mixins/AreaMixin'
import {EventBus} from '@/event-bus'
import WorkAssignmentEditor from '../../workassignment/WorkAssignmentEditor'
import {workAssignmentHelper} from '../../mixins/WorkAssignmentMixin'
import WorkAssignmentView from '../../workassignment/WorkAssignmentView'

export default {
    name: 'AreaDefectEditor',
    components: {WorkAssignmentEditor, WorkAssignmentView},
    mixins: [restApi, AreaHelper, workAssignmentHelper],
    props: {
        defect: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            areas: [],
            equipment: [],
            loading: false,
            defectUpdate: this.defect,
            isNew: this.defect.id < 1,
            workAssignment: null,
            assignmentMode: false
        }
    },
    mounted: function () {
        if (this.isNew) {
            this.fetchAreas()
        } else {
            this.areas = [this.defectUpdate.area]
            this.fetchEquipment()
        }
    },
    methods: {
        fetchAreas: function () {
            this.loading = true
            this.restFetchParams(this.areaUrl, {no_geom: 1}, this.handleAreas)
        },

        handleAreas: function (response) {
            this.loading = false
            this.areas = response.data
        },

        onAreaChange: function () {
            this.defectUpdate.area_equipment = null
            this.fetchEquipment()
        },

        fetchEquipment: function () {
            if (this.defectUpdate.area.id != null) {
                this.loading = true
                this.restFetchParams(this.areaEquipmentUrl, {area: this.defectUpdate.area.id}, this.handleEquipment)
            }
        },

        handleEquipment: function (response) {
            this.loading = false
            this.equipment = response.data
        },

        checkValues: function (defect) {
            if (defect.status < 1 ||
                !defect.area || defect.area.id < 1) {
                return undefined
            }
            if (this.isNew) {
                defect.fixer = null
                defect.fixed_date = null
            }
            defect.area = {'id': defect.area.id}
            defect.reporter = {'id': defect.reporter.id}
            if (defect.fixer) defect.fixer = {'id': defect.fixer.id}
            if (defect.area_equipment) defect.area_equipment = {'id': defect.area_equipment.id}
            return defect
        },

        submit: function () {
            let json = this.checkValues(this.defectUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.isNew) {
                    this.restAdd(this.areaDefectUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.areaDefectUrl, json, this.success, this.fail)
                }
            } else {
                EventBus.$emit('show-alert', this.$t('defects.missing_fields'))
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        validateFields: function () {
            return this.defectUpdate.area.id !== -1 && this.defectUpdate.status !== -1
        },

        setAssignmentEditorVisibility: function (visible) {
            this.assignmentMode = visible
        },

        closeAndUpdate: function (assignment) {
            this.setAssignmentEditorVisibility(false)
            this.workAssignment = assignment
        },

        editAssignment: function (assignment) {
            this.workAssignment = assignment
            this.setAssignmentEditorVisibility(true)
        }
    }

}
</script>
