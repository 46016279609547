import { rgb } from 'pdf-lib'

export class PdfGeneratorService {
  // Static variable to cache the image bytes
  static routaLogoBytes = null

  constructor() {
    this.defaultVerticalMargin = 70
    this.defaultHorizontalMargin = 40
    this.footerZone = 30 + this.defaultVerticalMargin
  }

  /**
   * @param {PDFDocument} pdfDoc PDF document object
   * @param {string} text Text to add
   * @param {PDFFont} font Font object
   * @param {number} fontSize Font size
   * @param {number} pageY Y position to start drawing the title
   * @param {number} verticalMargin Vertical margin from the top of the page
   * @param {number} horizontalMargin Horizontal margin from the left of the page
   * @param {number} assumedContentZone Assumed min height of the content zone
   * @param {PDFImage} footerImage Footer image object
   * @returns {number} Y position after drawing the title
   */
  addText(pdfDoc, text, font, fontSize, pageY = undefined, verticalMargin = this.defaultVerticalMargin, horizontalMargin = this.defaultHorizontalMargin, assumedContentZone = 70, footerImage) {
    const pages = pdfDoc.getPages()
    let page = pages[pages.length - 1]

    // Start new page if content doesn't fit to current page
    if (pageY && pageY < this.footerZone + assumedContentZone) {
      page = pdfDoc.addPage([595, 842])
      pageY = undefined
      this.addRoutaLogoToFooter(pdfDoc, page, footerImage)
    }

    const width = page.getWidth() - 2 * horizontalMargin
    const x = horizontalMargin
    let y = pageY || page.getHeight() - verticalMargin

    const lines = this.getWrappedText(font, text.toString(), fontSize, width)
    lines.forEach((lineText) => {
      page.drawText(lineText, {
        x: x,
        y: y,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      })

      y -= fontSize + 2
    })

    return y - fontSize
  }

  /**
   * @param {Array} tableData Multi-dimensional array containing the table data
   * @param {PDFDocument} pdfDoc PDF document object
   * @param {{textFont: PDFFont, headerFont: PDFFont, textFontSize: Number, headerFontSize: number} } font Font styles
   * @param {number} pageY Y position to start drawing the table
   * @param {number} verticalMargin Vertical margin from the top of the page
   * @param {number} horizontalMargin Horizontal margin from the left of the page
   * @param {PDFImage} footerImage object
   */
  addVerticalTable(tableData, pdfDoc, fontStyles, pageY = undefined, verticalMargin = this.defaultVerticalMargin, horizontalMargin = this.defaultHorizontalMargin, footerImage) {
    const pages = pdfDoc.getPages()
    let page = pages[pages.length - 1]

    // Table styling and layout
    const pageWidth = page.getWidth()
    const tableWidth = pageWidth - 2 * horizontalMargin
    const numColumns = 2
    const columnWidth = tableWidth / numColumns
    const rowPadding = 10 // Padding above and below each row
    const font = fontStyles.textFont
    const headerFont = fontStyles.headerFont || font
    const defaultFontSize = fontStyles.textFontSize || 12
    const defaultHeaderFontSize = fontStyles.headerFontSize || defaultFontSize
    const textColor = rgb(0, 0, 0)

    // Y position to start drawing the table
    let y = pageY || page.getHeight() - verticalMargin

    const addText = (lineText, x, y, textFont, fontSize) => {
      page.drawText(lineText, {
        x: x,
        y: y,
        size: fontSize,
        font: textFont,
        color: textColor,
      })
    }

    for (const row of tableData) {
      let x = horizontalMargin
      let rowHeight = 20

      // Check that the text fit to the current page
      if (y - rowHeight < this.footerZone) {
        page = pdfDoc.addPage([595, 842])
        this.addRoutaLogoToFooter(pdfDoc, page, footerImage)
        y = page.getHeight() - verticalMargin
      }

      // Row should only have two columns, "header" and "value"
      row.forEach((cellValue, index) => {
        let cellText = cellValue !== null && cellValue !== undefined ? cellValue.toString() : '-'
        const lines = this.getWrappedText(font, cellText, defaultFontSize, columnWidth)
        rowHeight = Math.max(rowHeight, lines.length * (defaultFontSize + 2) + 2 * rowPadding)

        let textY = y - rowPadding
        lines.forEach((lineText) => {
          const lineFontSize = index === 0 ? defaultHeaderFontSize : defaultFontSize
          const lineFont = index === 0 ? headerFont : font
          addText(lineText, x + 5, textY - defaultFontSize, lineFont, lineFontSize)
          textY -= defaultFontSize + 2
        })

        x += columnWidth
      })

      // Draw a horizontal line at the end of the row
      page.drawLine({
        start: { x: horizontalMargin, y: y - rowHeight },
        end: { x: page.getWidth() - horizontalMargin, y: y - rowHeight },
        thickness: 1,
        color: textColor,
      })

      y -= rowHeight
    }

    return y
  }

  /**
   * @param {Array} tableData Multi-dimensional array containing the table data
   * @param {PDFDocument} pdfDoc PDF document object
   * @param {{textFont: PDFFont, headerFont: PDFFont, textFontSize: Number, headerFontSize: number} } font Font styles
   * @param {number} pageY Y position to start drawing the table
   * @param {number} verticalMargin Vertical margin from the top of the page
   * @param {number} horizontalMargin Horizontal margin from the left of the page
   * @param {PDFImage} footerImage object
   */
  addTable(tableData, pdfDoc, fontStyles, pageY = undefined, verticalMargin = this.defaultVerticalMargin, horizontalMargin = this.defaultHorizontalMargin, footerImage) {
    const pages = pdfDoc.getPages()
    let page = pages[pages.length - 1]

    // Table styling and layout
    const pageWidth = page.getWidth()
    const tableWidth = pageWidth - 2 * horizontalMargin
    const numColumns = tableData.length > 1 ? tableData[1].length : tableData[0].length // Header can be empty
    const columnWidth = tableWidth / numColumns
    const rowPadding = 10 // Padding above and below each row
    const font = fontStyles.textFont
    const headerFont = fontStyles.headerFont || font
    const defaultFontSize = fontStyles.textFontSize || 12
    const defaultHeaderFontSize = fontStyles.headerFontSize || defaultFontSize
    const textColor = rgb(0, 0, 0)

    // Y position to start drawing the table
    let y = pageY || page.getHeight() - verticalMargin

    // Slice the first row of the table data to get the headers
    const headerData = tableData.length > 0 ? tableData.slice(0, 1)[0] : []
    const bodyData = tableData.length > 1 ? tableData.slice(1) : []

    const addText = (lineText, x, y, textFont, fontSize) => {
      page.drawText(lineText, {
        x: x,
        y: y,
        size: fontSize,
        font: textFont,
        color: textColor,
      })
    }

    const addHeaders = (headers, font, x, y) => {
      let headerX = x
      let headerY = y
      let maxHeaderLines = 1

      headers.forEach((text) => {
        let headerText = text !== null && text !== undefined ? text.toString() : '-'
        const lines = this.getWrappedText(font, headerText, defaultFontSize, columnWidth)
        maxHeaderLines = Math.max(maxHeaderLines, lines.length)

        lines.forEach((lineText, lineIndex) => {
          addText(lineText, headerX + 5, headerY - (defaultFontSize + 2) * lineIndex - 5, headerFont, defaultHeaderFontSize)
        })
        headerX += columnWidth
      })

      // Adjust Y position after drawing the headers based on the tallest header cell
      return y - (maxHeaderLines * (defaultFontSize + 2)) - 10
    }

    // Draw headers on the first page
    if (headerData.length > 0) {
      y = addHeaders(headerData, font, horizontalMargin, y)
    }
    for (const row of bodyData) {
      let x = horizontalMargin
      let rowHeight = 20

      // Check that the text fit to the current page
      if (y - rowHeight < this.footerZone) {
        page = pdfDoc.addPage([595, 842])
        this.addRoutaLogoToFooter(pdfDoc, page, footerImage)

        y = page.getHeight() - verticalMargin
        if (headerData.length > 0) {
          y = addHeaders(headerData, font, horizontalMargin, y)
        }
      }

      for (const cellValue of row) {
        let cellText = cellValue !== null && cellValue !== undefined ? cellValue.toString() : '-'
        const lines = this.getWrappedText(font, cellText, defaultFontSize, columnWidth)
        rowHeight = Math.max(rowHeight, lines.length * (defaultFontSize + 2) + 2 * rowPadding)

        let textY = y - rowPadding
        lines.forEach((lineText) => {
          addText(lineText, x + 5, textY - defaultFontSize, font, defaultFontSize)
          textY -= defaultFontSize + 2
        })

        x += columnWidth
      }

      // Draw a horizontal line at the end of the row
      page.drawLine({
        start: { x: horizontalMargin, y: y - rowHeight },
        end: { x: page.getWidth() - horizontalMargin, y: y - rowHeight },
        thickness: 1,
        color: textColor,
      })

      y -= rowHeight
    }

    return y
  }

  /*
  * Calculate text width and wrap text
  * @param {PDFFont} font Font object
  * @param {string} text Text to wrap
  * @param {number} fontSize Font size
  * @param {number} maxWidth Maximum width of the text
  * @returns {Array} Array of wrapped text lines
  */
  getWrappedText(font, text, fontSize, maxWidth) {
    const words = text.split(' ')
    let lines = []
    let line = ''

    // Split the text into lines that fit within the column width
    for (const word of words) {
      const testLine = line + word + ' '
      const textWidth = font.widthOfTextAtSize(testLine, fontSize)
      if (textWidth > maxWidth - 10) { // If the word does not fit
        if (line.length > 0) {
          lines.push(line.trim()) // Add the current line to the lines array
        }
        line = word + ' ' // Start a new line with the current word
      } else {
        line = testLine // Continue the current line with the word
      }
    }

    // Handle single long words without spaces
    if (line.length > 0) {
      const wordWidth = font.widthOfTextAtSize(line, fontSize)
      if (wordWidth > maxWidth - 10) {
        // Word is too long, break it into smaller chunks
        let start = 0
        while (start < line.length) {
          let end = start + Math.floor(maxWidth / font.widthOfTextAtSize('A', fontSize))
          lines.push(line.slice(start, end))
          start = end
        }
      } else {
        lines.push(line.trim())
      }
    }

    return lines
  }

  static async cacheRoutaFooterLogo() {
    // Check if we already have the image bytes cached
    if (!PdfGeneratorService.routaLogoBytes) {
      const imagePath = require('@/assets/routa_dark.jpg')
      // Fetch image bytes and store in the static variable if not already cached
      // We can also use browser cache to store the image bytes
      PdfGeneratorService.routaLogoBytes = await fetch(imagePath)
        .then((res) => res.arrayBuffer())
        .catch(() => null)
    }
  }

  /**
  * @param {PDFDocument} pdfDoc document object
  * @param {ArrayBuffer} footerImageBytes Image bytes for the footer logo
  * @returns {PDFImage} Image object
  */
  static async embedImageToDoc(pdfDoc, footerImageBytes) {
    if (!footerImageBytes) {
      return
    }

    const image = await pdfDoc.embedJpg(footerImageBytes)
    return image
  }

  /** 
   * 
   * @param {PDFDocument} pdfDoc PDF document object
   * @param {ArrayBuffer} fontBytes Font bytes
   * @returns {PDFFont} Font object
  */
  async embedFontToDoc(pdfDoc, fontBytes) { // 
    const font = await pdfDoc.embedFont(fontBytes)
    return font
  }

  /**
  * @param {PDFDocument} pdfDoc document object
  * @param {PDFImage} embededImage Image object
  */
  addRoutaLogoToFooter(pdfDoc, page = null, embededImage) {
    if (!embededImage) {
      return
    }

    let currenPage = page
    if (!currenPage) {
      const pages = pdfDoc.getPages()
      currenPage = pages[pages.length - 1]
    }

    const imageWidth = 50
    const imageHeight = (embededImage.height / embededImage.width) * imageWidth
    const pageWidth = page.getWidth()

    page.drawImage(embededImage, {
      x: pageWidth - imageWidth - 50, // Align to the right with a margin
      y: 30,
      width: imageWidth,
      height: imageHeight,
    })
  }

}