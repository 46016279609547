<template>
    <!-- Persons -->
    <div class="nopads">
        <certificate-table
            :users = "results"
            :certificates="certificates"/>
    </div>

</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import CertificateTable from "../certificate/CertificateTable";

export default {
    name: 'contractor-person-results',
    components: {CertificateTable},
    mixins: [restApi, timeUtils],
    props: {
        results: {
            type: Array
        }
    },
    data: function () {
        return {
            certificates: [],
            fields: [],
            tableData: [],
            lastModified: null
        }
    },
    mounted() {
        this.fetchCertificates()
    },
    methods: {
        fetchCertificates: function () {
            this.certificates = []
            this.restFetch(this.certificateTypeUrl, this.handleResponse, this.fail)
        },
        handleResponse: function (response) {
            if (response.data) {
                this.certificates = response.data
            }
        },
        fail: function () {
            // TODO - Do something?
        }
    },

}
</script>
