<template>
    <div class="nopads">
        <b-row>
            <b-col class="nopads report-header" cols="10">
                <div class="section-title">{{ $t('contract_reports.unload_records.title_notes') }} ({{tableItems.length}})</div>
            </b-col>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : deliveriesVisible}"
                    v-b-toggle.deliveryDetails
                    @click.stop="deliveriesVisible = !deliveriesVisible"/>
            </b-col>
        </b-row>
        <trip-modal
            v-if="showMap"
            ref="tripModal"
            :trips="trips"
            :loading="loading"
            :map="map"
            @close="closeTripModal"
        />
        <b-collapse
            id="deliveryDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <b-row class="nopads pl-2 pr-2" v-if="materials.station.length > 0">
                <b-col class="nopads pr-3 report-info" cols="auto">
                    <div>{{ unloadedAmount.toFixed(1) }} / {{ totalAmount.toFixed(1) }} {{ unit }}</div>
                </b-col>
                <b-col class="no-pads pl-0 report-info" cols="auto">
                    <div>{{ Math.round(totalDistance) }} m</div>
                </b-col>
                <b-col class="no-pads pl-0 report-info" cols="auto">
                    <div>{{ Math.round(totalArea) }} m2</div>
                </b-col>
                <b-col class="no-pads pl-0 report-info" cols="auto">
                    <div>{{ averageDensity }} kg/m2</div>
                </b-col>
            </b-row>
            <b-row>
                <div class="col-sm-4 nopads pl-1 pb-2  dont-print">
                    <div class="nopads" v-if="materials.station.length > 0">
                        <span class="item-detail-title">{{ $t('delivery_note.material') }}</span>
                        <b-form-select
                            v-model="material"
                            size="sm"
                        >
                            <option :value="null">
                                {{ $t('observations.search_all') }}
                            </option>
                            <option
                                v-for="material in materials.station"
                                :key="material.id"
                                :value="material.id"
                            >
                                {{ material.material.name + ' (' + material.type_test_code + ')' }}
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="col-sm-8 nopads pt-2 text-right dont-print">
                    <b-button
                        variant="outline-success"
                        :disabled="tableItems.length < 1"
                        class="result-button"
                        @click="exportExcel"
                    >
                        {{ $t('trip.download_excel') }}
                    </b-button>
                </div>
            </b-row>

            <div class="list-content result-table">
                <b-table
                    show-empty
                    small
                    :items="tableItems"
                    :fields="fields"
                    class="report-table"
                >
                    <template v-slot:cell(date)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                    <template v-slot:cell(show_unload_records)="row">
                        <b-button
                            size="sm"
                            v-b-toggle="'accordion-delivery-'+ row.item.id"
                        >
                            {{ $t('contract_reports.unload_records.title') }}
                        </b-button>
                    </template>
                    <template v-slot:row-details="row">
                        <b-collapse
                            :id="'accordion-delivery-'+ row.item.id"
                            :class="{'show-print': row.item.line_unload_records.length > 0 || row.item.point_unload_records.length > 0}"
                        >
                            <b-card
                                bg-variant="light"
                                no-body
                                class="my-2"
                            >
                                <div
                                    class="nopads"
                                    v-if="row.item.line_unload_records.length > 0 || row.item.point_unload_records.length > 0"
                                >
                                    <span class="span-title show-print" hidden v-if="row.item.line_unload_records.length > 0">
                                      {{ $t('contract_reports.unload_records.title') }}
                                    </span>
                                    <div v-if="row.item.line_unload_records.length > 0" class="col-12 nopads text-right pr-2">
                                        <span class="map-link" @click="toggleMap(null, row.index)">
                                            {{ $t('contract_reports.unload_records.show_map') }}
                                        </span>
                                    </div>

                                    <line-unload-record-table
                                        v-if="row.item.line_unload_records.length > 0"
                                        :records="row.item.line_unload_records"
                                        :dark-header="true"
                                    />
                                    <span class="span-title" v-if="row.item.point_unload_records.length > 0">
                                        {{ $t('contract_reports.unload_records.point_unload_records') }}
                                    </span>
                                    <point-unload-record-table
                                        v-if="row.item.point_unload_records.length > 0"
                                        :records="row.item.point_unload_records"
                                        :dark-header="true"
                                    />
                                </div>
                                <div v-else class="p-1">
                    <span class="italic">
                      {{ $t('contract_reports.unload_records.no_records') }}
                    </span>
                                </div>
                            </b-card>
                        </b-collapse>
                    </template>
                </b-table>
            </div>
        </b-collapse>
        <hr class="report-divider nopads"/>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>

</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import LineUnloadRecordTable from "@/components/contractreport/LineUnloadRecordTable";
import PointUnloadRecordTable from "@/components/contractreport/PointUnloadRecordTable";
import {restApi} from "@/components/mixins/RestApiMixin";
import TripModal from "@/components/trip/TripModal";
import {downloadHelper} from "@/components/mixins/DownloadMixin";
import {EventBus} from "@/event-bus";

export default {
    name: "contract-report-delivery-notes",
    mixins: [timeUtils, restApi, downloadHelper],
    components: {
        TripModal,
        LineUnloadRecordTable, PointUnloadRecordTable,
    },
    props: {
        deliveryNotes: {
            type: Array,
            default() {
                return []
            }
        },
        searchParams: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data: function () {
        return {
            deliveriesVisible: false,
            totalAmount: 0,
            unloadedAmount: 0,
            totalArea: 0,
            totalDistance: 0,
            unit: '',
            showMap: false,
            map: null,
            trips: [],
            tableItems: [],
            fields: [
                {date: {label: this.$t('delivery_note.date'), sortable: true}},
                {id: {label: this.$t('common.id'), sortable: true}},
                {vehicle: {label: this.$t('delivery_note.vehicle'), sortable: true}},
                {company: {label: this.$t('daily_summary.company'), sortable: true}},
                {material: {label: this.$t('delivery_note.material'), sortable: true}},
                {type_test_code: {label: this.$t('material_station.material.type_test_code'), sortable: true}},
                {station: {label: this.$t('material_station.title'), sortable: true}},
                {station_company: {label: this.$t('material_station.station_owner'), sortable: true}},
                {amount: {label: this.$t('delivery_note.amount'), sortable: true}},
                {unit: {label: this.$t('delivery_note.unit')}},
                {show_unload_records: {label: this.$t('contract_reports.unload_records.title'), class: 'hide-print'}}
            ],
            material: null,
            loading: false
        }
    },
    computed: {
        averageDensity() {
            let avg = 0
            if (this.totalArea > 0) {
                let totalKg = this.unloadedAmount * 1000
                avg = totalKg / this.totalArea
            }
            return avg.toFixed(2)
        },
        materials() {
            let all = {"station": [], "storage": [], "manual": []}
            this.deliveryNotes.forEach(item => {
                if (item.station_material) {
                    all.station.push(item.station_material)
                }
                if (item.storage_material) {
                    all.storage.push(item.storage_material)
                }
                if (item.manually_added_material) {
                    all.storage.push(item.manually_added_material)
                }

            })
            return {
                "station": [...new Map(all.station.map(item => [item.id, item])).values()],
                "storage": [...new Map(all.storage.map(item => [item.id, item])).values()],
                "manual": [...new Map(all.manual.map(item => [item.id, item])).values()],
            }
        }
    },
    mounted() {
        this.setTableItems()
    },
    watch: {
        material: function () {
            this.setTableItems()
        }
    },
    methods: {
        getMaterialName(item) {
            return item.station_material ?
                item.station_material.material.name + " (" + (item.station_material.type_test_code) + ")" :
                item.storage_material ? item.storage_material.name :
                    item.manually_added_material ? item.manually_added_material.material : ''
        },
        getStationName(item) {
            return item.station_material ?
                item.station_material.station.name :
                item.storage_material ? item.storage_material.storage.name : ''
        },
        setTableItems: function () {
            this.totalAmount = 0
            this.unloadedAmount = 0
            this.totalArea = 0
            this.totalDistance = 0
            this.unit = ''
            this.tableItems = []
            if (this.deliveryNotes.length > 0) {
                this.loading = true;
                this.deliveryNotes.forEach(item => {
                        if (this.material === null || item.station_material.id === this.material) {
                            if (!this.unit) {
                                this.unit = item.unit
                            }
                            this.totalAmount += item.amount
                            this.tableItems.push({
                                date: item.created,
                                id: item.id,
                                vehicle: item.vehicle.license_plate,
                                company: item.vehicle.company.name,
                                material: this.getMaterialName(item),
                                type_test_code: item.station_material ? item.station_material.type_test_code : '',
                                station: this.getStationName(item),
                                station_company: item.station_material ? item.station_material.station.company.name : '',
                                amount: item.amount,
                                unit: item.unit,
                                line_unload_records: item.line_unload_records,
                                point_unload_records: item.point_unload_records,
                                _showDetails: true
                            })
                            this.increaseTotals(item.line_unload_records, item.point_unload_records)
                        }
                    }
                )
                this.loading = false
            }
        },
        async toggleMap(state, row) {
            if (this.showMap === state){
                return;
            }
            // Toggle state if none given
            if (typeof state !== "boolean") {
                state = !this.showMap;
            }
            if (state) {
                await this.loadTrips(row);
                await this.$nextTick();
                this.map = this.$refs.tripModal.$refs.mapContainer.getMap()
            } else {
                this.map = null
            }
        },

        fetchLineUnloadRecordTrips(params) {
            return this.axios.get(this.tripUrl + '/' + 'unloadrecords', {params})
        },

        async loadTrips(rowIndex) {
            this.loading = true
            if (this.$refs.tripMap) {
                this.$refs.tripModal.$refs.tripMap.hideTrips();
            }
            let date = {}
            let dates = []
            if(this.deliveryNotes && rowIndex > -1 && this.deliveryNotes[rowIndex].line_unload_records.length > 0) {
                this.deliveryNotes[rowIndex].line_unload_records.forEach(record => {
                    if(record.start_time && record.end_time) {
                        let startDate = Date.parse(record.start_time);
                        let endDate = Date.parse(record.end_time);
                        if(endDate < startDate) {
                            dates.push({start_time: record.end_time, end_time: record.start_time})
                        } else {
                            dates.push({start_time: record.start_time, end_time: record.end_time})
                        }
                    }
                })
                date.vehicle_id = this.deliveryNotes[rowIndex].vehicle.id
            }
            date.dates = dates
            this.loading++;
            const { data, err } = await this.fetchLineUnloadRecordTrips(date).catch((err) => ({err}));
            this.loading--;
            if (err) {
                this.trips = []
                if (err.response.status && err.response.status === 500) {
                    EventBus.$emit('show-alert', this.$t('trip_list.err_too_much'))
                } else {
                    EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
                }
                return;
            }
            this.trips = data ? data.map((trip) => {
                // Ensure times will have timezone information. If not, then set as UTC!
                trip.start_time = this.setTime(trip.start_time)
                trip.end_time = this.setTime(trip.end_time)
                return trip;
            }) : []
            if (this.trips && this.trips.length > 0) {
                this.showMap = true
            } else {
                EventBus.$emit('show-alert', this.$t('trip_list.no_trips_found'))
            }
            this.loading = false
        },
        closeTripModal() {
            this.showMap = false
        },
        increaseTotals(lineRecords, pointRecords) {
            if (lineRecords) {
                lineRecords.forEach(record => {
                    if (record.travel_distance && record.travel_distance > 0) {
                        this.totalDistance += record.travel_distance
                        this.totalArea += record.travel_distance * record.width
                        this.unloadedAmount += record.amount
                    }
                })
            }
            if (pointRecords) {
                pointRecords.forEach(record => {
                    if (record.area_size && record.area_size > 0) {
                        this.totalArea += record.area_size
                        this.unloadedAmount += record.amount
                    }
                })
            }
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.materialDeliveryReportUrl, this.getCommonReportParams(this.searchParams), this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('contract_reports.delivery_report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        }
    }
}
</script>
<style scoped>
/deep/ .b-table-details > td:first-child {
    padding-top: 0;
    padding-bottom: 0;
}

.map-link {
    padding: 0;
    margin: 0;
    color: #007BFF;
    font-size: .9em;
    cursor: pointer;
}

.map-link:hover {
    text-decoration: underline;
    font-weight: bold;
}

</style>
