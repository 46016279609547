<!--Provides options to select area-->
<template>
    <div class="col-sm-12 nopads">
        <!--Area selection-->
        <div class="col-sm-6 nopads">
            <b-form-group
                class="nopads"
                label-for="area"
            >
                <span class="span-title">{{ $t('areas.area') }}</span>
                <b-form-select
                    id="area"
                    v-model="area"
                    size="sm"
                    class="sm-3"
                    :disabled="results.length < 1"
                >
                    <template slot="first">
                        <option :value="null">
                            {{ $t('areas.select_area') }}
                        </option>
                    </template>
                    <option
                        v-for="option in results"
                        :key="option.id"
                        :value="option"
                    >
                        {{ option.name }}
                    </option>
                </b-form-select>
            </b-form-group>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {areaHelper} from '../mixins/AreaMixin'

export default {
    name: 'AreaFilter',
    mixins: [restApi, areaHelper],
    props: {
        noGeom: {
            type: Boolean,
            default: false
        },
        areas: {
            type: Array,
            default: null
        },
        equipment: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            companies: [],
            results: [],
            area: null
        }
    },
    watch: {
        areas: function () {
           this.results = this.areas
        },
        area: function () {
            this.$emit('areaSet', this.area)
        }
    },
    mounted: function () {
        if (this.areas) {
            this.results = this.areas
        } else {
            this.fetchAreas()
        }
    },
    methods: {
        resetData: function () {
            this.company = null
            this.area = null
        },

        fetchAreas: function () {
            this.loading = true
            if (this.noGeom) {
                this.restFetchParams(this.areaUrl, {no_geom: 1}, this.handleAreaResults)
            } else {
                this.restFetch(this.areaUrl, this.handleAreaResults)
            }
        },

        handleAreaResults: function (response) {
            this.loading = false
            this.areas = response.data
            this.initArea()
        },
        initCompany: function () {
            if (this.equipment && this.equipment.id !== null) {
                let company = this.companies.find(company => company.id === this.equipment.area.company.id)
                if (company !== undefined) {
                    this.company = company.id
                }
            }
        },
        initArea: function () {
            if (this.equipment && this.equipment.id !== null) {
                let equipmentArea = this.areas.find(area => area.id === this.equipment.area.id)
                if (equipmentArea !== undefined) {
                    this.area = equipmentArea
                }
            }
        }
    }
}
</script>
