<template>
    <div class="col-sm-12 nopads">
        <b-row>
            <div class="col-sm-4 nopads pr-2 mb-1">
                <b-form-select
                    id="customer"
                    v-model="roleId"
                    size="sm"
                    class="m1-3"
                >
                    <template slot="first">
                        <option
                            :value="-1"
                            disabled
                        >
                            {{ $t('users.select_profile') }}
                        </option>
                    </template>
                    <option
                        v-for="r in roles"
                        :key="r.id"
                        :value="r.id"
                    >
                        {{ getRoleName(r.role) }}
                    </option>
                </b-form-select>
            </div>
            <div v-if="isAdmin" class="col-sm-4 nopads pr-2 mb-1">
                <b-form-select
                    id="customer"
                    v-model="companyId"
                    size="sm"
                    class="mb-1"
                >
                    <template slot="first">
                        <option
                            :value="-1"
                            disabled
                        >
                            {{ $t('users.select_company') }}
                        </option>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </template>
                </b-form-select>
            </div>
            <div class="col-sm-3">
                <b-form-input
                    id="info"
                    v-model="info"
                    size="sm"
                    class="mb-1"
                    :placeholder="$t('users.role_assign_info')"
                />
            </div>
            <div class="col-sm-1 nopads pl-2">
                <b-button
                    v-if="userRightUpdate.id < 1"
                    size="sm"
                    variant="success"
                    class="mr-1"
                    @click.stop="addUserRight"
                    :disabled="roleId < 0 || (companyId < 0 && isAdmin)"
                >
                    <i class="fa fa-plus"/>
                </b-button>
            </div>
        </b-row>
    </div>
</template>

<script>
import {userHelper} from '../mixins/UserMixin'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'UserRightSelector',
    mixins: [userHelper, restApi],
    props: {
        companies: {
            type: Array,
            default: function () {
                return []
            }
        },
        roles: {
            type: Array,
            default: function () {
                return []
            }
        },
        userRight: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false,
        }
    },
    data: function () {
        return {
            userRightUpdate: {id: -1, role: {id: -1}, company: {id: -1}},
            roleId: -1,
            companyId: -1,
            info: ""
        }
    },

    mounted: function () {
        if (this.userRight) {
            this.userRightUpdate = this.userRight
            this.roleId = this.userRightUpdate.role.id
            this.companyId = this.userRightUpdate.company.id
            this.info = this.userRightUpdate.info
        }
    },

    methods: {
        addUserRight: function () {
            this.userRightUpdate.role = this.roles.find(item => item.id === this.roleId)
            this.userRightUpdate.company = this.companies.find(item => item.id === this.companyId)
            this.userRightUpdate.info = this.info
            this.$emit('addUserRight', this.userRightUpdate)
        },
        reset: function () {
            this.userRightUpdate = {id: -1, role: {id: -1}, company: {id: -1}, info: ""}
        }
    }
}
</script>
