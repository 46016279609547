<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
        >
            <div
                class="header"
            >
                <span class="title">{{ $t('menu.certificate_report') }}</span>
            </div>
        </b-navbar>
        <b-form-checkbox
            v-model="activeUsers"
            class="ml-3"
            size="md"
            type="text"
            value=1
            unchecked-value=0
            @change="fetchCertificates">
            {{ $t('contractor_reports.only_active_users') }}
        </b-form-checkbox>
        <certificate-table
            class="m-2"
            v-if="!loading"
            :users="results"
            :certificates="certificates"/>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>

</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import CertificateTable from "./CertificateTable";

export default {
    name: 'certificate-report',
    components: {CertificateTable},
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            results: [],
            certificates: [],
            fields: [],
            tableData: [],
            lastModified: null,
            loading: true,
            activeUsers: 1
        }
    },
    mounted() {
        this.fetchCertificates()
    },
    methods: {
        fetchCertificates: function () {
            this.loading = true
            this.certificates = []
            this.restFetch(this.certificateTypeUrl, this.handleResponse, this.fail)
        },
        handleResponse: function (response) {
            this.loading = false
            if (response.data) {
                this.certificates = response.data
                // Fetch user certificate data
                this.fetchUserCertificates();
            }
        },
        fetchUserCertificates: function () {
            this.loading = true
            this.results = []
            if (parseInt(this.activeUsers) !== 1) {
                this.restFetch(this.certificateUrl, this.handleCertificateResponse, this.fail)
            } else {
                this.restFetchParams(this.certificateUrl, {activeUsers: this.activeUsers}, this.handleCertificateResponse, this.fail)
            }
        },
        handleCertificateResponse: function (response) {
            this.loading = false
            if (response.data) {
                this.results = response.data
            }
        },
        fail: function () {
            this.loading = false
        },
    },

}
</script>
