<template>
    <div class="col-sm-12 nopads">
        <div>
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                class="no-margin"
                @submit.prevent="onSubmit"
                @reset.prevent="onReset"
            >
                <b-row class="nopads pl-2 pr-2">
                    <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">
                          {{ $t('ins_report.from_date') }}
                        </span>
                        <datetime
                            v-model="fromDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>

                    <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">
                          {{ $t('ins_report.to_date') }}
                        </span>
                        <datetime
                            v-model="toDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </b-row>

                <div class="col-sm-12 button-container pt-3">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>


<script>
import {vehicleHelper} from '../../mixins/VehicleMixin'
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'VehicleTrips',
    components: {},
    mixins: [vehicleHelper, restApi, timeUtils],
    props: {
        isAdmin: Boolean,
        vehicleId: Number
    },
    data: function () {
        return {
            vehicle: null,
            fromDate: null,
            toDate: null,
            loading: false,
        }
    },


    mounted: function () {
        this.fromDate = this.getMonthStart()
    },

    methods: {

        onSubmit: function () {
            var params = {}

            if (this.fromDate) {
                params.from = this.fromDate
            }
            if (this.toDate) {
                params.to = this.toDate
            }

            if (this.vehicleId) {
                params.vehicle = this.vehicleId
            }
            params.trip_notes = 1
            this.$emit('search', params)
        },

        onReset: function () {
            this.toDate = null
            this.fromDate = this.getMonthStart()
        }

    }

}
</script>
