<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('orders.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <keep-alive>
                        <station-order-search
                            v-if="!editMode"
                            :user="user"
                            @search="fetchOrders"
                        />
                </keep-alive>
            </transition>
            <transition name="fade">
                    <station-order-list
                        class="p-2"
                        :results="results"
                        :user="user"
                    />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'
import StationOrderSearch from "@/components/order/StationOrderSearch";
import StationOrderList from "@/components/order/StationOrderList";

export default {
    name: 'StationOrders',
    components: {StationOrderList, StationOrderSearch},
    mixins: [workManagementHelper, restApi],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            results: [],
            order: null,
            editMode: false,
            loading: false,
            lastSearchedParams: null
        }
    },

    methods: {

        fetchOrders: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.orders = []
            this.restFetchParams(this.orderOpenUrl, params, this.handleOrders)
        },

        handleOrders: function (response) {
            this.results = response.data
            this.loading = false
        },

    }
}
</script>
