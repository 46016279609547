<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div
                v-if="editMode"
                class="header"
                @click="closeEditor"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
            <div
                v-else
                class="header"
            >
                <span class="title">{{ $t('area_groups.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">

            <!-- company selection, admin only -->
            <transition name="fade" v-if="isAdmin">
                <div
                    v-if="!editMode"
                    class="col-sm-12"
                >
                    <company-filter
                        v-model="fetchCompany"
                        v-on:input="fetchAreaGroups"
                    />
                </div>
            </transition>

            <!-- AreaGroup list -->
            <transition name="fade">
                <area-group-list
                    v-if="!editMode"
                    :area-groups="areaGroups"
                    @add="addAreaGroup"
                    @edit="editAreaGroup"
                />
            </transition>

            <!-- Editor -->
            <transition name="fade">
                <area-group-editor
                    v-if="editMode"
                    :area-group="selectedAreaGroup"
                    :is-admin="isAdmin"
                    @close="closeEditor"
                    @closeAndUpdate="closeEditorAndUpdate"
                />
            </transition>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import AreaGroupList from './AreaGroupList'
import AreaGroupEditor from './AreaGroupEditor'
import CompanyFilter from '../../company/CompanyFilter'

export default {
    name: 'AreaGroups',
    components: {CompanyFilter, AreaGroupList, AreaGroupEditor},
    mixins: [restApi],
    props: {
        isAdmin: Boolean,
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            areaGroups: [],
            selectedAreaGroup: null,
            fetchCompany: null,
            editMode: false,
            loading: false
        }
    },
    mounted: function () {
        this.fetchAreaGroups()
    },
    methods: {
        fetchAreaGroups: function () {
            this.areaGroups = []
            this.loading = true
            let params = {}
            params.no_geom = 1
            if (this.isAdmin && this.fetchCompany !== null) {
                params.company = this.fetchCompany
                this.restFetchParams(this.areaGroupUrl, params, this.handleAreaFetchResponse)
            } else {
                this.restFetchParams(this.areaGroupUrl, params, this.handleAreaFetchResponse)
            }
        },
        handleAreaFetchResponse: function (response) {
            this.loading = false
            if (response != null) {
                // It is ok to get all area groups for normal users, but just filter the result in this view to show only the ones that belong to the user's company
                let groups = response.data
                if (!!this.user && !this.isAdmin) {
                    groups = response.data.filter(group => group.company_area === this.user.company.id)
                }
                this.areaGroups = groups
            }
        },
        addAreaGroup: function () {
            this.selectedAreaGroup = {id: -1, name: '', company: {}, areas: []}
            if (this.isAdmin && this.fetchCompany !== null) {
                this.selectedAreaGroup.company.id = this.fetchCompany
            }
            this.editMode = true
        },
        editAreaGroup: function (group) {
            this.selectedAreaGroup = group
            this.editMode = true
        },
        closeEditor: function () {
            this.editMode = false
        },
        closeEditorAndUpdate: function () {
            this.fetchAreaGroups()
            this.closeEditor()
        }
    }
}
</script>
