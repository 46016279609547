<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('menu.contract_collections') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
                <font-awesome-icon
                    icon="trash"
                    class="print-icon right mt-2"
                    @click.stop="removeContractCollection"
                />
            </div>
        </b-navbar>

        <transition name="fade">
            <contract-collection-list
                v-if="!editMode"
                :results="results"
                :contracts="contracts"
                @add="addNew"
                @edit="edit"
            />
        </transition>

        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <contract-collection-editor
                    ref="contractCollectionEditor"
                    :contract-collection="contractCollection"
                    :contract-collections="results"
                    :contracts="contracts"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import ContractCollectionList from "@/components/contract/collection/ContractCollectionList";
import ContractCollectionEditor from "@/components/contract/collection/ContractCollectionEditor";

export default {
    name: 'ContractCollections',
    components: {ContractCollectionEditor, ContractCollectionList},
    mixins: [restApi],
    data: function () {
        return {
            results: [],
            contractCollection: null,
            contracts: [],
            editMode: false,
            loading: false
        }
    },
    mounted: function () {
        this.fetchContracts()
        this.fetchContractCollections()
    },
    methods: {
        fetchContractCollections: function () {
            this.loading = true
            this.results = []
            this.restFetch(this.contractCollectionUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        fetchContracts: function () {
            this.loading = true
            this.contracts = []
            this.restFetch(this.contractUrl, this.handleContracts)
        },
        handleContracts: function (response) {
            this.contracts = response.data
            this.loading = false
        },
        addNew: function () {
            this.contractCollection = {id: null, name: null, contracts: null, description: null}
            this.editMode = true
        },
        edit: function (contractCollection) {
            this.contractCollection = contractCollection
            this.editMode = true
        },
        removeContractCollection: function () {
            this.$refs["contractCollectionEditor"].confirmDeleteContractCollection()
        },
        showList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchContractCollections()
            this.showList()
        }

    }
}
</script>
