<template>
    <div class="col-12 file-dialog" style="z-index: 9999">
        <div>
            <h5 class="span-title">
                {{ isEditing ? $t('orders.edit_geometry') : $t('orders.add_empty_geometry') }}
            </h5>
        </div>
        <div class="row">
            <div class="col-1 mt-2">
                <span>{{ $t('orders.geometry_name') }}</span>
            </div>
            <div class="col-11">
                <b-form-group class="span-title" label-for="name">
                    <b-form-input id="name" type="text" size="mb" v-model="localName" />
                </b-form-group>
            </div>
        </div>
        <div v-if="!isEditing" class="row">
            <div class="col-1 mt-2">
                <span>{{ $t('orders.geometry_color') }}</span>
            </div>
            <div class="col-11 mt-2">
                <color-picker ref="color-picker" @selectColor="setColor" :noRedColor="true" :initialColor="localColor" />
            </div>
        </div>
        <div v-if="!isEditing" class="row">
            <div class="col-2 mt-2">
                <span>{{ $t('orders.geometry_type') }}</span>
            </div>
            <div class="col-10 mt-2">
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        id="geometry-radio-slots"
                        v-model="localGeometryType"
                        :aria-describedby="ariaDescribedby"
                        name="geometry-type-slots"
                    >
                        <b-form-radio value="Point">{{ $t('orders.point_type') }}</b-form-radio>
                        <b-form-radio value="LineString">{{ $t('orders.linestring_type') }}</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <div>
            <hr>
            <div class="col-2 mt-2">
                <span>{{ $t('orders.geometry_properties') }}</span>
            </div>
            <order-geometry-property-editor
                ref="orderGeometryPropertyEditor"
                :newProperties="localProperties"
                @addProperty="addProperty"
                @removeProperty="removeProperty"
            />
        </div>
        <div class="button-container pr-1">
            <b-button
                variant="secondary"
                size="sm"
                class="form-button"
                @click.stop="close"
            >
                {{ $t('common.cancel') }}
            </b-button>
            <b-button
                variant="success"
                size="sm"
                class="form-button"
                :disabled="!localName"
                @click.stop="submit"
            >
                {{ isEditing ? $t('common.save') : $t('orders.add_empty_geometry') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import ColorPicker from '@/components/ColorPicker.vue';
import OrderGeometryPropertyEditor from '@/components/order/OrderGeometryPropertyEditor.vue';

export default {
    name: 'OrderGeometryLayerEditor',
    components: {
        ColorPicker,
        OrderGeometryPropertyEditor,
    },
    props: {
        isEditing: {
            type: Boolean,
            default: false,
        },
        geometryData: {
            type: Object,
            default: null
        },
        excludedFields: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            localName: this.geometryData.name,
            localColor: this.geometryData.color,
            localGeometryType: this.geometryData && this.geometryData.type ? this.geometryData.type : 'Point',
            localProperties: [],
            loadedGeometry: this.geometryData
        };
    },
    mounted() {
        if (this.geometryData && this.geometryData.geometry && this.geometryData.geometry.features && this.geometryData.geometry.features.length > 0) {
            this.geometryData.geometry.features.forEach(feature => {
                if (feature.properties && typeof feature.properties === 'object') {
                    Object.entries(feature.properties).forEach(([key]) => {
                        if (!this.excludedFields.includes(key) && !this.localProperties.includes(key)) {
                            this.localProperties.push(key);
                        }
                    });
                }
            });
        }
    },
    methods: {
        setColor(color) {
            this.localColor = color;
        },
        addProperty(property) {
            this.localProperties.push(property);
        },
        removeProperty(index) {
            if (index > -1) {
                this.localProperties.splice(index, 1);
            }
        },
        close() {
            this.$emit('close');
        },
        submit() {
            if (this.isEditing) {
                // Update the loaded geometry with the new properties
                if (this.loadedGeometry) {
                    if(this.loadedGeometry.geometry && this.loadedGeometry.geometry.features && this.loadedGeometry.geometry.features.length > 0) {
                        this.loadedGeometry.geometry.features.forEach(feature => {
                            if (feature.properties) {
                                // Delete properties that are not in localProperties
                                Object.keys(feature.properties).forEach(key => {
                                    if (!this.excludedFields.includes(key) &&
                                        !this.localProperties.includes(key)) {
                                        delete feature.properties[key];
                                    }
                                });

                                // Add properties from localProperties that are not in feature.properties
                                this.localProperties.forEach(property => {
                                    if (!feature.properties.hasOwnProperty(property)) {
                                        feature.properties[property] = null;
                                    }
                                });
                            }
                        });

                    }
                    this.loadedGeometry.name = this.localName
                    this.loadedGeometry.color = this.localColor
                }
                this.$emit('submit', this.loadedGeometry)
            } else {
                this.$emit('submit', {
                    name: this.localName,
                    color: this.localColor,
                    type: this.localGeometryType,
                    properties: this.localProperties,
                });
            }

        },
    },
};
</script>

<style scoped>

</style>
