<template>
    <div class="col-sm-12 list-content result-table">
        <b-table
            show-empty
            small
            :items="records"
            :fields="fields"
            @row-clicked="editItem"
        >
            <template slot="empty">
                <div class="text-center my-2">
                    {{ $t('common.no_results') }}
                </div>
            </template>
            <template
                v-slot:cell(time)="row"
            >
                <span>{{ toLocalTime(row.value) }}</span>
            </template>
        </b-table>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils"

export default {
    name: 'external-scale-record-list',
    mixins: [timeUtils],
    props: {
        records: {
            type: Array,
            default: function () {
                return []
            }
        },
    },
    data: function () {
        return {
            fields: [
                {key: 'time', label: this.$t('material_station.ext_records.time'), sortable: true},
                {key: 'receipt_number', label: this.$t('material_station.ext_records.receipt'), sortable: true},
                {key: 'scale_id', label: this.$t('material_station.ext_records.scale'), sortable: true},
                {key: 'vehicle_id', label: this.$t('material_station.ext_records.vehicle'), sortable: true},
                {key: 'material_id', label: this.$t('material_station.ext_records.material'), sortable: true},
                {key: 'order_id', label: this.$t('material_station.ext_records.order'), sortable: true},
                {key: 'truck_net_weight', label: this.$t('material_station.ext_records.truck_net'), sortable: true},
                {key: 'trailer_net_weight', label: this.$t('material_station.ext_records.trailer_net'), sortable: true},
                {key: 'unit', label: this.$t('material_station.ext_records.unit'), sortable: true}
            ]
        }
    },
    methods: {
        editItem: function (record) {
            let r = this.records.find(item => item.id === record.id)
            if (r) {
                this.$emit('rowClicked', r)
            }
        }
    }
}
</script>
