<template>
    <div class="col-sm-12 nopads">
        <div v-show="!order && !taskType && !costItem" class="col-sm-12 editor-form">
            <div v-if="showTitle" class="col-sm-12 form-title editor-title">
                <h2
                    v-if="contract && contract.id > 0"
                    class="form-title editor-title"
                >
                    {{ contract.name }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('contracts.add') }}
                </h2>
            </div>

            <b-tabs v-if="contract && user" justified v-model="tabIndex">
                <b-tab :title="$t('contracts.basic_info')" active :title-link-class="linkTabClass(0)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-2 nopads" v-if="!hasTaskTypes && contract.is_contract_party">
                                <b-tooltip target="basicTitle" triggers="hover" placement="right">
                                    {{ $t('contracts.warning_task_types') }}
                                </b-tooltip>
                                <font-awesome-icon
                                    id="basicTitle"
                                    icon="exclamation-circle"
                                    class="defect mr-2"
                                />
                            </div>
                            <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 0}">
                                {{ $t('contracts.basic_info') }}
                            </div>
                        </b-row>
                    </template>
                    <contract-editor
                        ref="contractEditor"
                        v-if="contract && contract.is_contract_party"
                        :user="user"
                        :contract-trip-list="contractTripList"
                        v-model="contract"
                        @close="emitCloseAndUpdate"
                        @closeAndUpdate="closeAndUpdate"
                        @addTaskType="showTaskTypeEditor"
                    />
                    <contract-simple-info v-else-if="contract"
                        :contract="contract"/>
                </b-tab>
                <b-tab :title-link-class="linkTabClass(1)" :disabled="contract.id < 1">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-2 nopads" v-if="!loading && !hasActiveOrders">
                                <b-tooltip target="orderTitle" triggers="hover" placement="right">
                                    {{ $t('contracts.no_ongoing_orders') }}
                                </b-tooltip>
                                <font-awesome-icon
                                    id="orderTitle"
                                    icon="exclamation-circle"
                                    class="defect mr-2"
                                />
                            </div>
                            <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 1}">
                                {{ $t('menu.orders') }}
                            </div>
                        </b-row>
                    </template>
                    <contract-orders
                        ref="contractOrders"
                        v-if="contract && contract.id > 0 && !order"
                        :contract="contract"
                        :order-filter="orderFilter"
                        :task-type-filter="taskTypeFilter"
                        @setOrderFilter="setOrderFilter"
                        @setTaskTypeFilter="setTaskTypeFilter"
                        @addOrder="addOrder"
                        @editOrder="editOrder"
                        @ordersUpdated="onOrdersUpdated"/>
                </b-tab>
                <b-tab :title-link-class="linkTabClass(2)"  :disabled="contract.id < 1">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 2}">
                                {{ $t('contracts.schedule') }}
                            </div>
                        </b-row>
                    </template>
                    <contract-calendar
                        ref="calendar"
                        v-if="contract && contract.id > 0"
                        :contract="contract"
                        :auto-expand="true"
                        :user="user"
                        :disable-contract-actions="true"
                        @orderUpdated="onCalendarOrderUpdated"/>
                </b-tab>
                <b-tab :title-link-class="linkTabClass(3)" :disabled="!contract.is_contract_party || contract.id < 1">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 3}">
                                <font-awesome-icon
                                    v-if="!contract.is_contract_party"
                                    icon="lock"
                                    class="mr-2"/>
                                {{ $t('contracts.docs') }}
                            </div>
                        </b-row>
                    </template>
                    <contract-documents
                        v-if="contract && contract.id > 0"
                        v-model="value"/>
                </b-tab>
                <b-tab :title-link-class="linkTabClass(4)" :disabled="!contract.is_contract_party || contract.id < 1">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 4}">
                                <font-awesome-icon
                                    v-if="!contract.is_contract_party"
                                    icon="lock" class="mr-2"/>
                                {{ $t('contracts.reports') }}
                            </div>
                        </b-row>
                    </template>
                    <contract-reports
                        v-if="contract && contract.id > 0"
                        :contract="contract"
                        :show-title="false"
                        :is-observer="isObserver"
                        :user="user"
                    />
                </b-tab>
                <b-tab v-if="contract.ely_contract" :title-link-class="linkTabClass(5)" :disabled="!contract.is_contract_party || contract.id < 1">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 5}">
                                <font-awesome-icon
                                    v-if="!contract.is_contract_party"
                                    icon="lock" class="mr-2"/>
                                {{ $t('contracts.ely_reports') }}
                            </div>
                        </b-row>
                    </template>
                    <contract-ely-reports
                        :contract="contract"
                        :user="user"/>
                </b-tab>
                <b-tab v-if="contract.harja_contract_id" :title-link-class="linkTabClass(6)" :disabled="contract.id < 1">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 6}">
                                {{ $t('harja.harja_messages') }}
                            </div>
                        </b-row>
                    </template>
                    <keep-alive>
                        <harja-messages
                            v-if="tabIndex === 6"
                            :contract-id="contract.id"
                        />
                    </keep-alive>
                </b-tab>
            </b-tabs>
        </div>
        <transition name="fade">
            <order-editor
                ref="editor"
                v-if="order"
                :contract="value"
                :order="order"
                :user="user"
                :isObserver="isObserver"
                @close="closeOrderEditor"
                @closeAndUpdate="closeOrderAndUpdate"
            />
        </transition>
        <transition name="fade">
            <task-type-editor
                v-if="taskType"
                :task-type="newTaskType()"
                @close="hideTaskTypeEditor"
                @closeAndUpdate="hideTaskTypeEditor"/>
        </transition>
        <transition
            name="fade"
        >
            <additional-cost-editor
                v-if="costItem"
                :additional-cost="costItem"
                @closeAndUpdate="updateCosts"
                @close="costItem = null"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <div v-if="!showTitle" class="col-12 button-container pl-2 pr-4">
            <hr/>
            <b-button size="sm" class="form-button" variant="primary" @click="closeAndUpdate">
                {{ $t('common.back') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {orderStatus, workManagementHelper} from "@/components/mixins/WorkManagementMixin";
import OrderEditor from '../order/OrderEditor'
import TaskTypeEditor from '../tasktype/TaskTypeEditor'
import ContractOrders from "@/components/contract/ContractOrders";
import ContractDocuments from "@/components/contract/ContractDocuments";
import ContractEditor from "@/components/contract/ContractEditor";
import ContractCalendar from "@/components/contractcalendar/ContractCalendar";
import ContractReports from "@/components/contractreport/ContractReports";
import ContractSimpleInfo from "@/components/contract/ContractSimpleInfo";
import AdditionalCostEditor from "@/components/additionalcost/AdditionalCostEditor";
import ContractElyReports from "@/components/contract/external/ContractElyReports";
import HarjaMessages from "@/components/harja/HarjaMessages.vue";

export default {
    name: 'ContractDetails',
    components: {
        HarjaMessages,
        ContractElyReports,
        AdditionalCostEditor,
        ContractSimpleInfo,
        ContractReports,
        ContractCalendar, ContractEditor, ContractDocuments, ContractOrders, OrderEditor, TaskTypeEditor},
    mixins: [restApi, workManagementHelper, orderStatus],
    props: {
        value: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        contractTripList: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        activeOrderEditor: Boolean,
        showTitle: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            loading: false,
            contract: null,
            orders: [],
            ordersToAdd: [],
            order: null,
            closeOnCancel: false,
            orderFilter: [],
            taskTypeFilter: [],
            addMessage: '',
            taskType: false,
            tabIndex: 0,
            costItem: null,
            hasActiveOrders: true
        }
    },
    computed: {
        hasTaskTypes() {
            return this.contract.task_types && this.contract.task_types.length > 0
        }
    },
    mounted: function () {
        this.contract = this.value
        this.orderFilter=[orderStatus.OPEN, orderStatus.PLAN, orderStatus.IN_PROGRESS, orderStatus.STORED, orderStatus.COMPLETED]
        this.updateActiveOrders()
    },
    watch: {
        contract(val) {
            this.$emit('input', val)
            this.updateActiveOrders()
        },
        order: function() {
            this.order ? this.$emit('orderActiveChange', true) : this.$emit('orderActiveChange', false)
        },
        activeOrderEditor: function () {
            if(!this.activeOrderEditor) this.closeOrderEditor()
        }
    },
    methods: {
        softUpdateContract(data) {
            this.contract = data
            this.$emit('input', data)
            this.$emit('scrollTop')
        },
        editOrder: function (order) {
            this.order = order
            this.$emit('scrollTop')
        },
        close: function () {
            this.$emit('showList')
        },
        closeAndUpdate: function (data) {
            if (this.value.id < 1 && data.id > 0) {
                // If we just added a contract successfully, ask to create orders as well
                this.contract = data
                this.addOpenOrders()
                this.closeOnCancel = true
                this.updateActiveOrders()
            }
            if (this.contractTripList) {
                this.emitCloseAndUpdate()
            }
        },
        emitCloseAndUpdate() {
            this.$emit('closeAndUpdate', this.contract)
            this.updateActiveOrders()
        },
        addOrder: function () {
            this.order = this.createEditorOrder()
            this.$emit('scrollTop')
        },
        closeOrderEditor: function () {
            this.order = null
        },
        closeOrderAndUpdate: function () {
            this.closeOrderEditor()
            this.$nextTick(() => {
                this.$refs.contractOrders.fetchContractOrders()
                this.$refs.calendar.refresh()
            })
        },
        setOrderFilter(orderFilter) {
            this.orderFilter = orderFilter
        },
        setTaskTypeFilter(taskTypeFilter) {
            this.taskTypeFilter = taskTypeFilter
        },
        showTaskTypeEditor: function () {
            this.taskType = true
            this.$emit('scrollTop')
        },

        hideTaskTypeEditor: function () {
            this.taskType = false
            this.$nextTick(() => this.$refs.contractEditor.refreshTaskTypes())
        },

        linkTabClass(index) {
            if (this.tabIndex === index) {
                return ['tab-title', 'tab-title-active']
            } else {
                return ['tab-title']
            }
        },
        editCost(costItem) {
            this.costItem = costItem
        },
        updateCosts() {
            this.costItem = null
            this.$refs.costs.updateCosts()
        },
        onOrdersUpdated() {
            // Refresh non-visible tab
            if (this.tabIndex === 1) {
                this.$refs.calendar.refresh()
            }
            this.updateActiveOrders()
        },
        onCalendarOrderUpdated() {
            // Refresh non-visible tab
            this.$refs.contractOrders.fetchContractOrders()
        },
        updateActiveOrders() {
            this.hasActiveOrders = this.contract.id < 0 || this.$refs.contractOrders && this.$refs.contractOrders.hasActiveOrders()
        }

    }
}
</script>

<style>
.tab-title {
    font-size: .9em;
    border: none !important;
    margin-right: .5em;
    margin-left: .5em;
    color: #949494 !important;
    font-weight: bold;
}

.tab-title:hover {
    color: #007BFF;
}

.tab-title-active {
    border-bottom: 2px solid #7FC000 !important;
}

.tab-title-text {
    color: #949494 !important;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.tab-title-text-active {
    color: #000000 !important;
    font-weight: bold;
}


.tab-title-cont {
    height: 2.5em !important;
    padding-top: 1em;
    padding-bottom: 1em;
}

.contract-tabs .nav-link {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.contract-tabs .nav-tabs {
    height: 3em;
    padding-top: .55em;
}

</style>
