<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showMaterialTypes"
        >
            <div
                v-if="!editMode"
                class="header"
            >
        <span class="title">
          {{ $t('materials.title') }}
        </span>
            </div>
            <div
                v-else class="header"
                @click="showMaterialTypes"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>

        <div
            v-if="!editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <material-type-list
                    :is-admin="isAdmin"
                    :materials="results"
                    @add="addMaterialType"
                    @edit="editMaterialType"
                />
            </transition>
        </div>

        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <material-type-editor
                    :is-admin="isAdmin"
                    :material-type="materialType"
                    @close="showMaterialTypes"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import MaterialTypeList from "@/components/materials/type/MaterialTypeList";
import MaterialTypeEditor from "@/components/materials/type/MaterialTypeEditor";

export default {
    name: "MaterialTypes",
    components: {MaterialTypeEditor, MaterialTypeList},
    mixins: [restApi],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            editMode: false,
            results: [],
            materialType: null,
            loading: false
        }
    },
    mounted() {
        this.fetchMaterialTypes()
    },
    methods: {
        fetchMaterialTypes: function () {
            this.loading = true
            this.restFetch(this.materialTypeUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        addMaterialType: function () {
            this.materialType = {
                id: -1,
                name: null
            }
            this.editMode = true
        },
        editMaterialType: function (type) {
            this.materialType = type
            this.editMode = true
        },
        showMaterialTypes: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchMaterialTypes()
            this.showMaterialTypes()
        }
    }
}
</script>
