<template>
    <div class="col-12 light-border mb-2 mt-2">
    <span class="span-title">
      {{ $t('tasktypes.vehicle_types') }}
    </span>
        <b-form v-if="selectedTypes.length > 0" inline>
            <div class="col-sm-12 inspectable-container p-2">
                <div
                    v-for="item in selectedTypes"
                    :key="item"
                    :class="{'d-inline' : true}">
                <span class="inspectables inspectables-selected"
                      @click="updateVehicleTypeSelection(item)">
                  {{ getVehicleTypeName(item) }}
                  <strong>
                    X
                  </strong>
                </span>
                </div>
            </div>
        </b-form>
        <div v-else
             class="col-sm-12 p-2">
        <span>
          {{ $t('tasktypes.no_vehicle_type') }}
        </span>
        </div>
        <span
            v-if="selectedTypes.length !== vehicleTypes.length"
            class="span-title p-2">
        {{ $t('tasktypes.add_vehicle_type') }}
    </span>
        <div class="col-sm-12 inspectable-container p-2">
            <!-- All inspection templates -->
            <div v-for="item in vehicleTypes"
                 :key="item"
                 :class="{hidden: isSelected(item), 'd-inline': !isSelected(item)}">
                <span class="inspectables"
                      @click="updateVehicleTypeSelection(item)">
                  {{ getVehicleTypeName(item) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {vehicleHelper} from '../mixins/VehicleMixin'

export default {
    name: 'TaskTypeVehicleTypes',
    mixins: [vehicleHelper],
    props: {
        taskType: {
            type: Object,
            default: null
        }
    },

    data: function () {
        return {
            loading: false,
            vehicleTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            selectedTypes: []
        }
    },

    mounted: function () {
        if (this.taskType.vehicle_types) {
            this.taskType.vehicle_types.forEach(item => {
                this.selectedTypes.push(item.vehicle_type)
            })
        }
    },
    methods: {
        isSelected: function (id) {
            return this.selectedTypes.find(item => item === id) !== undefined
        },
        updateVehicleTypeSelection: function (id) {
            var index = this.selectedTypes.findIndex(item => item === id)
            if (index >= 0) {
                this.selectedTypes.splice(index, 1)
            } else {
                this.selectedTypes.push(id)
            }
            this.$emit('onVehicleTypesUpdated', this.selectedTypes)
        }
    }

}
</script>
