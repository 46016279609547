<template>
    <div class="col-12 nopads">
        <transition name="fade">
            <road-sign-wizard
                v-if="selectedRoadSign"
                style="pointer-events: all"
                :road-sign="selectedRoadSign"
                :selected-contract="selectedContract"
                @close="closeRoadSignWizard"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '@/components/mixins/TimeUtils'
import {mapHelper} from '@/components/mixins/MapMixin'
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {restApi} from '@/components/mixins/RestApiMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import MapSvgMarkers from "@/components/map/MapSvgMarkerStore";
import RoadSignWizard from "@/components/roadsign/RoadSignWizard";
import Vue from "vue";

const IMAGES = require.context('@/assets/roadsign/', true, /\.(?:jpg|png|svg|)$/)

export default {
    name: 'ExternalTrafficSignsMap',
    components: {
        RoadSignWizard
    },
    mixins: [timeUtils, mapHelper, restApi, geometryMixin, roadSignHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContract: {
            type: Object,
            default: null
        },
        selectedConditions: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data: function () {
        return {
            loading: 0,
            roadSigns: [],
            bbox: null,
            visibilityZoomLevel: 13,
            selectedRoadSign: null,
        }
    },

    watch: {
        map() {
            this.$nextTick(function () {
                this.initView()
            })
        },
        boundingBox() {
            this.updateView()
        },
        selectedConditions() {
            this.clearDrawnItems()
            this.drawSigns()
        }
    },
    beforeDestroy() {
        this.hideItems()
    },
    mounted() {
        this.initView()
    },
    computed: {
      visibleRoadSigns() {
          return this.selectedConditions.length === 0 ? this.roadSigns : this.roadSigns.filter((value) => this.selectedConditions.includes(value.sign_condition))
      }
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideItems()
            } else if (force || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.005
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchRoadSigns()
            }
        },

        clearDrawnItems() {
            if (this.map) {
                this.map.removeMapItemsByType(this.EXTERNAL_TRAFFIC_SIGNS)
            }
        },

        hideItems: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.EXTERNAL_TRAFFIC_SIGNS)
            }
            this.roadSigns = []
        },

        editTrafficSign(id) {
            this.fetchSign(id)
        },

        fetchSign: function (id) {
            this.loading++
            this.selectedRoadSign = null
            this.restFetch(this.roadSignExtUrl + '/' + id, this.handleSign)
        },

        handleSign: function (response) {
            if (response && response.data) {
                // Set Velho oid as contract id
                if (this.selectedContractId) {
                    response.data.contract = {
                        id: this.selectedContractId
                    }
                }
                this.selectedRoadSign = response.data
            }
            this.loading--
        },

        fetchRoadSigns: function () {
            this.selectedRoadSign = null
            let params = {}
            if(this.bbox && this.bbox.length > 0) {
                this.loading++
                params.bbox = this.bbox
                this.restFetchParams(this.roadSignExtUrl, params, this.handleRoadSignsSearch, () => {
                    this.loading--
                })
            } else {
                this.hideItems()
            }
        },

        handleRoadSignsSearch: function (response) {
            this.hideItems()
            if (response && response.data && response.data.length > 0) {
                this.roadSigns = response.data
                this.drawSigns()
            }
            this.loading--
        },

        drawSigns: function () {
            this.visibleRoadSigns.forEach(function (sign, index) {
                this.drawSign(sign, index)
            }, this)
        },

        drawSign: function (sign, index) {
            let mapIconName = this.getSignIcon(sign.sign)
            if (mapIconName) {
                var ComponentClass = Vue.extend(MapSvgMarkers)
                var markerStore = new ComponentClass()
                markerStore.$mount()
                let svgUrl = IMAGES('./' + mapIconName)
                markerStore.setSvgIcon(svgUrl, false);
                if (sign.position) {
                    this.$nextTick(function () {
                        this.map.showMapMarker(index, this.EXTERNAL_TRAFFIC_SIGNS, sign.position.y, sign.position.x, markerStore.getSvgIcon(),
                            false, 5, false, sign.external_system_id)
                    })
                }
            }
        },

        closeRoadSignWizard() {
            this.selectedRoadSign = null
        },

        closeAndUpdate() {
            this.closeRoadSignWizard()
            this.fetchRoadSigns()
        }

    }
}
</script>
