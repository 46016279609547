<template>
    <div class="content-container">
        <!-- Topbar-->
        <b-navbar
            variant="faded"
            @click="editMode = false"
        >
            <div
                v-if="!editMode"
                class="header"
            >
        <span class="title">
          {{ $t('notification_list.title') }}
        </span>
            </div>
            <div
                v-else
                class="header"
                @click="editMode = false"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>

        <div class="col-sm-12 list-container"
             v-if="!editMode">
            <!-- Lists-->
            <transition name="fade">
                <div
                    v-if="lists"
                    id="accordion"
                    class="col-sm-12 list-content result-table"
                >
                    <b-table
                        hover
                        show-empty
                        small
                        style="cursor:pointer;"
                        :items="lists"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        @row-clicked="editList"
                    >
                        <template slot="empty">
                            <div class="text-center my-2">
                                {{ $t('common.no_results') }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </transition>
        </div>

        <!--     Editor-->
        <notification-list-editor
            v-if="editMode"
            :list="list"
            @close="close()"
            @closeAndUpdate="closeAndUpdate"/>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import NotificationListEditor from '@/components/notifications/NotificationListEditor'

export default {
    name: 'notification-lists',
    mixins: [restApi],
    components: {NotificationListEditor},
    data: function () {
        return {
            EMERGENCY: 1,
            SAFETY: 2,
            WORK: 3,
            loading: false,
            lists: [],
            editMode: false,
            list: null,
            sortBy: 'topic',
            fields: [
                {key: 'topic', label: this.$t('notification_list.id'), sortable: true},
                {key: 'label', label: this.$t('notification_list.topic'), sortable: true},
            ]
        }
    },
    mounted() {
        this.lists = [
            {topic: this.EMERGENCY, label: this.$t('notification_list.topic_emergency')},
            {topic: this.SAFETY, label: this.$t('notification_list.topic_safety')},
            {topic: this.WORK, label: this.$t('notification_list.topic_work')}
        ]
    },
    methods: {
        editList: function (list) {
            this.list = list
            this.fetchSubscribers()
        },
        close: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.editMode = false
        },
        fetchSubscribers() {
            this.loading = true
            this.restFetch(this.notificationSubsUrl + '/' + this.list.topic, this.handleSubs, this.handleError)
        },
        handleSubs: function (response) {
            if (response.data) {
                this.list.users = []
                response.data.forEach(item => this.list.users.push(item.user))
                this.editMode = true
            }
            this.loading = false
        },
        handleError() {
            this.loading = false
        }
    }
}
</script>
