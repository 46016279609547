import {equipmentOidTypes} from '@/components/mixins/ConversionMixin'

export default class VelhoPostModel {
    // Private properties
    /** @type {string} */
    #external_system_id = equipmentOidTypes.EXTERNAL_POSTS

    constructor(
        type = null, condition = null, material = null, position = { x: null, y: null }, roadNum = null, roadSec = null, distance = null,  code = "",
        standType = null, standMaterial = null, length = null, height = null, thickness = null, productName = "", crashSafety = false, detachable = false, electrified = false,
        equipmentDamages = [], locationDeviation = null, locationSpecification = { lane: [], side: null, tracks: null, central_area: null }, externalSystemId = ""
    ) {
        /** @type {number | null} */
        this.type = type

        /** @type {number | null} */
        this.condition = condition
        
        /** @type {number | null} */
         this.material = material

        /** @type {{ x: number, y: number }} */
        this.position = position

        /** @type {number | null} */
        this.road_num = roadNum

        /** @type {number | null} */
        this.road_sec = roadSec

        /** @type {number | null} */
        this.distance = distance

        /** @type {string} */
        this.code = code
       
        /** @type {number | null} */
        this.stand_type = standType

        /** @type {number | null} */
        this.stand_material = standMaterial

        /** @type {number | null} */
        this.length = length

        /** @type {number | null} */
        this.height = height

        /** @type {number | null} */
        this.thickness = thickness

        /** @type {string} */
        this.product_name = productName

        /** @type {boolean} */
        this.crash_safety = crashSafety

        /** @type {boolean} */
        this.detachable = detachable

        /** @type {boolean} */
        this.electrified = electrified

        /** @type {Array} */
        this.equipment_damages = equipmentDamages

        /** @type {number | null} */
        this.location_deviation = locationDeviation

        /** @type {{ lane: number[], side: number | null, tracks: number | null, central_area: number | null }} */
        this.location_specification = locationSpecification

        if(externalSystemId) {
            this.#external_system_id = externalSystemId
        }
    }

    /**
    * Gets the unique identifier.
    * @returns {string}
    */
    get external_system_id() {
        return this.#external_system_id
    }

    /**
    * Get the json object.
    * @returns {object}
    */
    toJSON() {
        return JSON.parse(
            JSON.stringify({
            external_system_id: this.#external_system_id,
            type: this.type,
            condition: this.condition,
            material: this.material,
            position: this.position,
            road_num: this.road_num,
            road_sec: this.road_sec,
            distance: this.distance,
            code: this.code,
            stand_type: this.stand_type,
            stand_material: this.stand_material,
            length: this.length,
            height: this.height,
            thickness: this.thickness,
            product_name: this.product_name,
            crash_safety: this.crash_safety,
            detachable: this.detachable,
            electrified: this.electrified,
            equipment_damages: this.equipment_damages,
            location_deviation: this.location_deviation,
            location_specification: {
                ...this.location_specification,
                tracks: this.location_specification.tracks !== null ? [this.location_specification.tracks] : []
            }
        }))
    }

    /**
    * Create default instance with position data.
    * @param {{ x: number, y: number }} position - coordinates.
    * @param {number | null} roadNum - Road number.
    * @param {number | null} roadSec - road section.
    * @param {number | null} distance - distance (road geometry).
    * @returns {VelhoPostModel}
    */
    static instanceWithPosition(position, roadNum, roadSec, distance) {
        const instance = new VelhoPostModel()
        instance.position = position
        instance.road_num = roadNum
        instance.road_sec = roadSec
        instance.distance = distance
        return instance
    }
}