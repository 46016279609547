<template>
    <div class="col-sm-12 nopads player-container">
        <div class="row nopads">
            <div class="col-sm-6 nopads">
                <video
                    id="frontVideo"
                    ref="frontVideo"
                    :key="frontVideoTrackId"
                    class="video"
                    controls
                    muted
                    @timeupdate="onTimeUpdate"
                    @canplay="onCanPlay"
                    @play="onPlay"
                    @ended="onEnded"
                    @onError="handleError"
                    @loadeddata="onLoadedData"
                >
                    <source
                        :src="frontVideo"
                        type="video/mp4"
                    >
                </video>
            </div>
            <div class="col-sm-6 nopads">
                <video
                    id="rearVideo"
                    :key="rearVideoTrackId"
                    class="video"
                    controls
                    muted
                    @timeupdate="onTimeUpdateRear"
                    @play="onPlay"
                    @ended="onEndedRear"
                    @loadeddata="onLoadedDataRear"
                    @error="onEndedRear"
                >
                    <source
                        :src="rearVideo"
                        type="video/mp4"
                    >
                </video>
            </div>
        </div>
    </div>
</template>

<script>
import {getDistance} from 'geolib'
import {restApi} from '../../mixins/RestApiMixin'

export default {
    name: 'ObservationVideo',
    mixins: [restApi],
    props: {
        frontVideos: {
            type: Array,
            default() {
                return []
            }
        },
        rearVideos: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            frontCurrentTime: '',
            rearCurrentTime: '',
            frontVideoTime: 0,
            rearVideoTime: 0,
            frontVideo: null,
            frontVideoTracks: [],
            frontVideoTrackId: null,
            rearVideo: null,
            rearVideoTracks: [],
            rearVideoTrackId: null,
            loading: false,
            shouldPlay: false
        }
    },
    watch: {
        frontCurrentTime(currentTime) {
            let track = this.frontVideoTracks.findIndex(track => track.data.id === this.frontVideoTrackId)
            let points = JSON.parse(this.frontVideoTracks[track].data.geometry)
            let point = 0
            for (let i = 0; i < points.length; i++) {
                if (points[i].time > currentTime) {
                    break
                }
                point = i
            }
            this.$emit('routeProgress', point, this.frontVideoTrackId)
        },
        rearCurrentTime(currentTime) {
            let track = this.rearVideoTracks.findIndex(track => track.data.id === this.rearVideoTrackId)
            let points = JSON.parse(this.rearVideoTracks[track].data.geometry)
            let point = 0
            for (let i = 0; i < points.length; i++) {
                if (points[i].time > currentTime) {
                    break
                }
                point = i
            }
            this.$emit('routeProgress', point, this.rearVideoTrackId)
        }
    },
    mounted: function () {
        if (this.frontVideos.length > 0) {
            this.frontVideos = this.sortVideosByStartingTime(this.frontVideos)
            this.frontVideoTracks = this.frontVideos
            this.frontVideo = this.frontVideos[0].video
            this.frontVideoTrackId = this.frontVideos[0].data.id
        }

        if (this.rearVideos.length > 0) {
            this.rearVideos = this.sortVideosByStartingTime(this.rearVideos)
            this.rearVideoTracks = this.rearVideos
            this.rearVideo = this.rearVideos[0].video
            this.rearVideoTrackId = this.rearVideos[0].data.id
        }
    },
    methods: {
        handleError: function () {
            this.onEnded()
        },
        sortVideosByStartingTime: function (videos) {
            videos.sort((a, b) => {
                return new Date(a.data.start_time) - new Date(b.data.start_time)
            })
            return videos
        },

        onTimeUpdate: function () {
            let video = document.getElementById('frontVideo')
            let videoCurrentTime = video.currentTime * 1000
            if (videoCurrentTime !== this.frontCurrentTime) {
                this.frontCurrentTime = videoCurrentTime
            }
        },

        onTimeUpdateRear: function () {
            let video = document.getElementById('rearVideo')
            let videoCurrentTime = video.currentTime * 1000
            if (videoCurrentTime !== this.rearCurrentTime) {
                this.rearCurrentTime = videoCurrentTime
            }
        },

        onCanPlay: function () {
            if (this.shouldPlay) {
                let frontVideo = this.$refs.frontVideo
                frontVideo.play()
            }
        },

        onPlay: function () {
            this.shouldPlay = true
        },

        onEnded: function () {
            this.$emit('routeProgress', 0, this.frontVideoTrackId, true)
            let track = this.frontVideoTracks.findIndex(track => track.video === this.frontVideo)
            if (track < this.frontVideoTracks.length - 1) {
                this.frontVideoTime = 0
                this.changeVideo(this.frontVideoTracks[track + 1], true)
            }
        },

        onEndedRear: function () {
            this.$emit('routeProgress', 0, this.rearVideoTrackId, true)
            let track = this.rearVideoTracks.findIndex(track => track.video === this.rearVideo)
            if (track < this.rearVideoTracks.length - 1) {
                this.rearVideoTime = 0
                this.changeVideo(this.rearVideoTracks[track + 1], false)
            }
        },

        onLoadedData: function () {
            let track = this.frontVideoTracks.findIndex(track => track.video === this.frontVideo)
            if (track > 0) {
                let frontVideo = document.getElementById('frontVideo')
                frontVideo.currentTime = this.frontVideoTime
            }
        },

        onLoadedDataRear: function () {
            let track = this.rearVideoTracks.findIndex(track => track.video === this.rearVideo)
            if (track > 0) {
                let rearVideo = document.getElementById('rearVideo')
                rearVideo.currentTime = this.rearVideoTime
                if (this.shouldPlay) {
                    rearVideo.play()
                }
            }
        },

        changeVideo: function (track, isFrontVideo) {
            if (isFrontVideo) {
                this.frontVideo = track.video
                this.frontVideoTrackId = track.data.id
            } else {
                this.rearVideo = track.video
                this.rearVideoTrackId = track.data.id
            }
        },

        videoTimeOffset: function (point) {
            let frontTrack = this.findNearestVideoPoint(this.frontVideoTracks, point)
            if (frontTrack) {
                this.offsetFrontVideoProgress(frontTrack.point, frontTrack.track)
            }
            let rearTrack = this.findNearestVideoPoint(this.rearVideoTracks, point)
            if (rearTrack) {
                this.offsetRearVideoProgress(rearTrack.point, rearTrack.track)
            }
        },

        offsetFrontVideoProgress: function (point, track) {
            if (this.frontVideoTracks.length > 0) {
                let videosIds = []
                this.frontVideoTracks.forEach(video => {
                    videosIds.push(video.data.id)
                })
                this.$emit('clearRouteProgress', videosIds)
                this.redrawRouteProgress(this.frontVideoTracks, track)
                let points = this.parseCoordinates(this.frontVideoTracks[track])
                if (this.frontVideoTracks[track].data.id !== this.frontVideoTrackId) {
                    this.frontVideo = this.frontVideoTracks[track].video
                    this.frontVideoTrackId = this.frontVideoTracks[track].data.id
                    this.frontVideoTime = points[point].time / 1000
                } else {
                    let frontVideo = document.getElementById('frontVideo')
                    frontVideo.currentTime = points[point].time / 1000
                }
            }
        },

        offsetRearVideoProgress: function (point, track) {
            if (this.rearVideoTracks.length > 0) {
                let videosIds = []
                this.rearVideoTracks.forEach(video => {
                    videosIds.push(video.data.id)
                })
                this.$emit('clearRouteProgress', videosIds)
                this.redrawRouteProgress(this.rearVideoTracks, track)
                let points = this.parseCoordinates(this.rearVideoTracks[track])
                if (this.rearVideoTracks[track].data.id !== this.rearVideoTrackId) {
                    this.rearVideo = this.rearVideoTracks[track].video
                    this.rearVideoTrackId = this.rearVideoTracks[track].data.id
                    this.rearVideoTime = points[point].time / 1000
                } else {
                    let rearVideo = document.getElementById('rearVideo')
                    rearVideo.currentTime = points[point].time / 1000
                }
            }
        },

        redrawRouteProgress: function (videos, trackIndex) {
            for (let i = 0; i < trackIndex; i++) {
                let lastPoint = this.parseCoordinates(videos[i])
                this.$emit('routeProgress', lastPoint.length - 1, videos[i].data.id)
            }
        },

        parseCoordinates: function (video) {
            return JSON.parse(video.data.geometry)
        },

        findNearestVideoPoint: function (videos, point) {
            const distanceLimit = 50 // clicks with 50 meters to geometry taken into account
            let points = []
            videos.forEach(video => {
                points.push(...this.parseCoordinates(video))
            })
            let distFromCurrent = function (coord) {
                return {coord: coord, dist: getDistance(point, coord)}
            }
            let coordinate = points.map(distFromCurrent).sort(function (a, b) {
                return a.dist - b.dist
            })[0]
            let trackIndex = null
            let trackPoint = null
            for (let i = 0; i < videos.length; i++) {
                let videoPoints = this.parseCoordinates(videos[i])
                let resultPoint = videoPoints.findIndex(vPoint => vPoint.lat === coordinate.coord.lat && vPoint.lon === coordinate.coord.lon)
                if (resultPoint > -1 && getDistance(videoPoints[resultPoint], point) < distanceLimit) {
                    trackPoint = resultPoint
                    trackIndex = i
                    break
                }
            }
            return {track: trackIndex, point: trackPoint}
        }
    }
}
</script>

<style scoped>
.video {
    width: 100%;
    height: 100%;
}

div {
    height: 100%;
}
</style>
