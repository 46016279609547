<template>
    <div class="col-12 stat-container">
        <div class="stat-header">{{ header }}</div>
        <b-row class="nopads">
            <div class="col-2">
                <font-awesome-icon icon="times" class="stat-icon"/>
            </div>
            <div class="col-3">
                <span class="stat-title">{{ $t('storm_sewers.status_defect') }}</span>
                <span class="stat-text"> {{  defect }} </span>
            </div>
            <div class="col-3">
                <span class="stat-title">{{ $t('equipment_report.options.ban') }}</span>
                <span class="stat-text"> {{  ban }} </span>
            </div>
            <div class="col-3">
                <span class="stat-title">{{ $t('common.total') }}</span>
                <span class="stat-text"> {{ (defect + ban) }} </span>
            </div>
        </b-row>

        <b-row class="nopads pt-1 pb-1">
            <div class="col-2">
                <font-awesome-icon icon="clock" class="stat-icon"/>
            </div>
            <div class="col-3">
                <span class="stat-title">{{ $t('defects.average_age') }} </span>
                <span class="stat-text"> {{ averageAge }} </span>
            </div>
        </b-row>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'VehicleStatisticValues',
    mixins: [timeUtils],
    props: {
        header:  {
            type: String,
            default: null
        },
        defect: Number,
        ban: Number,
        averageAge: String,
    }
}
</script>

<style>
</style>
