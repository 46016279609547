<template>
    <div v-if="results.length > 0" class="nopads">
        <b-row>
            <b-col class="nopads report-header" cols="10">
                <span class="section-title" style="display: block">{{ $t('menu.work_assignment') }} ({{results.length}})</span>
            </b-col>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : workAssignmentsVisible}"
                    v-b-toggle.workAssignmentsDetails
                    @click.stop="workAssignmentsVisible = !workAssignmentsVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="workAssignmentsDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <!-- Summary -->
            <div class="col-12 text-center">
                <work-assignment-summary
                    :results=results
                    />
            </div>

            <transition name="fade">
                <work-assignment-list
                    :results=results
                    @edit="editDetails"
                />
            </transition>

            <b-modal
                :visible="workAssignment !== null"
                @hidden="workAssignment = null"
                body-class="fill-modal"
                size="lg"
                :hide-header="true"
                :hide-footer="true"
            >
                <work-assignment-editor
                    v-if="workAssignment"
                    :no-margin="true"
                    :area-defect="workAssignment.areaDefect"
                    :vehicle-defect="workAssignment.vehicleDefect"
                    :observation="workAssignment.observation"
                    :work-permission="workAssignment.workPermission"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    :work-assignment="workAssignment"
                    @close="closeEditDetails"
                    @closeAndUpdate="closeAndUpdateEditDetails"
                />
            </b-modal>
        </b-collapse>
        <hr class="report-divider nopads"/>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import WorkAssignmentList from "@/components/workassignment/WorkAssignmentList";
import WorkAssignmentEditor from "@/components/workassignment/WorkAssignmentEditor";
import {workAssignmentHelper} from "../mixins/WorkAssignmentMixin";
import WorkAssignmentSummary from "@/components/contractreport/WorkAssignmentSummaryValues.vue";

export default {
    name: "ContractReportWorkAssignments",
    mixins: [timeUtils, restApi, workAssignmentHelper],
    components: {WorkAssignmentSummary, WorkAssignmentList, WorkAssignmentEditor },
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            loading: false,
            results: [],
            workAssignmentsVisible: false,
            workAssignment: null
        }
    },
    created() {
        this.fetchMaterialReports()
    },
    watch: {
        searchParams: function () {
            this.fetchMaterialReports()
        }
    },
    methods: {
        fetchMaterialReports: function () {
            if (this.searchParams) {
                let mappedParams = {
                    contract: this.searchParams.contract,
                    created_from: this.searchParams.dateFrom ? this.getDateIsoString(new Date(this.searchParams.dateFrom)) : null,
                    created_to: this.searchParams.dateTo ? this.getDateIsoString(new Date(this.searchParams.dateTo)) : null
                }
                this.$emit('loading', true)
                this.restFetchParams(this.workAssignmentUrl, mappedParams, this.handleResponse)
            }
        },
        editDetails: function (id) {
            this.workAssignment = this.results.find(item => item.id === id)
        },
        closeEditDetails: function () {
            this.workAssignment = null
        },
        closeAndUpdateEditDetails: function () {
            this.fetchMaterialReports()
            this.workAssignment = null
        },
        handleResponse: function (response) {
            if (response) {
                this.results = response.data
            }
            this.$emit('loading', false)
        },
    }
}
</script>

