<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <table class="table table-sm"
               v-if="bridge">
            <tbody>
            <tr>
                <td class="item-detail-title">
                    {{ $t('culvert.digiroad_id') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.digiroad_id">{{ bridge.digiroad_id }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.name') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.name">{{ bridge.name }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.type') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.type">{{ bridge.type }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.bridge_num') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.bridgeNumber">{{ bridge.bridgeNumber }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.city') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.city">{{ bridge.city }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.road_num') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.roadNum">{{ bridge.roadNum }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.sec_num') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.secNum">{{ bridge.secNum }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.start_distance') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.startDistance">{{ bridge.startDistance }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.length') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="bridge.length">{{ bridge.length }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            </tbody>
        </table>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>

</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: 'TargetInfoBridge',
    mixins: [restApi],
    props: {
        bridgeId: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            bridge: null,
            loading: false
        }
    },
    created() {
        this.fetchBridgeInfo()
    },
    methods: {
        fetchBridgeInfo: function () {
            this.loading = true
            this.restFetchParams(this.bridgeUrl + "/" + this.bridgeId, this.params, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            if (response) {
                this.bridge = response.data
            }
        }
    }
}
</script>
