<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header">
                <span class="title">{{ $t('menu.area_work') }}</span>
            </div>
        </b-navbar>
        <!-- Equipment filter -->
        <transition name="fade">
            <keep-alive>
                <area-work-filter
                    v-if="!editMode"
                    :user="user"
                    :is-admin="false"
                    @search="fetchAreaWork"
                />
            </keep-alive>
        </transition>
        <!-- Add new one -->
        <div class="col-12 pl-3 pr-3">
            <div
                class="data-list-item add"
                @click.stop="addWork"
            >
                <div class="item-img-cont add-title">
                    <i class="fa fa-plus"/>
                </div>
                <div class="item-text-cont">
                    <span class="item-title-add">{{ $t('common.add_new') }}</span>
                    <font-awesome-icon
                        icon="barcode"
                        class="card-body-icon"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="results.length > 0"
            class="button-container col-sm-12 trip-buttons"
        >
            <b-button
                variant="outline-success"
                :disabled="results.length < 1"
                class="result-button"
                @click="exportExcel"
            >
                {{ $t('trip.download_excel') }}
            </b-button>

        </div>
        <!-- Work record list -->
        <div class="pl-2 pr-2">
            <transition name="fade">
                <area-work-list
                    v-if="!editMode"
                    :results="results"
                    @add="addWork"
                    @edit="editWork"
                />
            </transition>
        </div>
        <!-- Editor -->
        <transition
            v-if="areaWorkItem"
            name="fade"
        >
            <area-work-editor
                v-if="areaWorkItem"
                :user="user"
                :area-id="areaWorkItem.id"
                @close="areaWorkItem = undefined"
                @closeAndUpdate="workUpdated"/>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import {areaHelper} from '../../mixins/AreaMixin'
import AreaWorkEditor from "./AreaWorkEditor";
import AreaWorkFilter from "./AreaWorkFilter";
import AreaWorkList from "./AreaWorkList";
import {downloadHelper} from "../../mixins/DownloadMixin";

export default {
    name: 'AreaWork',
    components: {
        AreaWorkList,
        AreaWorkFilter,
        AreaWorkEditor
    },
    mixins: [restApi, areaHelper, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: true,
            error: false,
            editMode: false,
            areaWorkItem: null,
            results: [],
            lastSearchedParams: null
        }
    },
    mounted: function () {
        this.loading = false
    },
    methods: {
        fetchAreaWork: function (params) {
            this.loading = true
            this.results = []
            this.lastSearchedParams = params
            this.restFetchParams(this.areaWorkUrl, this.lastSearchedParams, this.handleResponse, this.handleError)
        },

        handleError: function (error) {
            this.loading = false
            if (error.response.status && error.response.status !== 200) {
                EventBus.$emit('show-alert', this.$t('equipment.err_general'))
            }
        },

        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
        },

        addWork: function () {
            this.areaWorkItem = {id: null}
        },

        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.areaWorkReportXlsxUrl, this.getCommonReportParams(this.lastSearchedParams), this.excelDownloadSuccess, this.fileDownloadFail)
        },

        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('areas.report_file_name'))
        },

        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },

        editWork: function (item) {
            this.areaWorkItem = item
        },

        closeAndUpdate: function () {
            this.fetchAreaWork(this.lastSearchedParams)
        },

        workUpdated: function () {
          this.areaWorkItem = undefined
          if ((this.results && this.results.length > 0) || this.lastSearchedParams.length > 0) {
              this.fetchAreaWork(this.lastSearchedParams)
          }
        }

    }
}
</script>

<style scoped>
.result-info {
    margin-left: 1em;
    margin-bottom: 10px;
}
</style>
