<template>
    <div class="col-12 nopads">
      <span class="span-title">
            {{ $t('contracts.inspections') }}
      </span>

        <b-form v-if="selectedInspections.length > 0" inline>
            <div class="col-sm-12 inspectable-container p-2">
                <div
                    v-for="item in selectedInspections"
                    :key="item.id"
                    :class="{'d-inline' : true}">
                <span class="inspectables inspectables-selected"
                      @click="removeFromSelection(item)">
                  {{ item.name }}
                  <strong>
                    X
                  </strong>
                </span>
                </div>
            </div>
        </b-form>
        <div v-else
             class="col-sm-12 p-2">
        <span>
          {{ $t('contracts.no_inspections') }}
        </span>
        </div>
        <span
            v-if="selectedInspections.length !== inspections.length"
            class="span-title p-2">
        {{ $t('contracts.add_inspection') }}
    </span>
        <div class="col-sm-12 row inspectable-container nopads">
            <div
                v-for="item in inspections"
                :key="item.id"
                class="col-sm-6 col-md-4 col-lg-3 col-xl-2 inline inspectables nopads"
                :class="{ 'inspectables-disabled' : isSelected(item.id) }"
                @click="addToSelection(item)">
                  {{ item.name }}
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'

export default {
    name: 'contract-inspections',
    mixins: [workManagementHelper, restApi],
    props: {
        useDefault: {
            type: Boolean,
            default: true
        },
        value: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false,
            templatesLoading: false,
            defaultTemplatesLoading: false,
            inspections: [],
            selectedInspections: this.value
        }
    },
    watch: {
        selectedInspections: function () {
            this.$emit('input', this.selectedInspections)
        }
    },
    created: function () {
        this.fetchInspectionTemplates()
        if (this.useDefault) {
            this.fetchDefaultInspections()
        }
    },
    methods: {
        fetchDefaultInspections: function () {
            this.defaultTemplatesLoading = true
            this.isLoading()
            this.restFetch(this.defaultInspectionUrl, this.handleDefaultInspections, this.onDefaultInspectionLoaded)
        },
        handleDefaultInspections: function (results) {
            this.onDefaultInspectionLoaded()
            results.data.forEach(item => {
                this.selectedInspections.push(item)
            })
        },
        onDefaultInspectionLoaded: function () {
            this.defaultTemplatesLoading = false
            this.isLoading()
        },
        fetchInspectionTemplates: function () {
            this.inspections = []
            this.templatesLoading = true
            this.isLoading()
            this.restFetchParams(this.inspectionTemplateUrl, {type: 2}, this.fetchInspectionSuccess, this.onInspectionLoaded)
        },
        fetchInspectionSuccess: function (response) {
            this.onInspectionLoaded()
            if (response != null) {
                this.inspections = response.data
            }
        },
        onInspectionLoaded: function () {
            this.templatesLoading = false
            this.isLoading()
        },
        isSelected: function (id) {
            return this.selectedInspections.find(item => item.id === id) !== undefined
        },
        addToSelection(item) {
            let index = this.selectedInspections.findIndex(selectedItem => selectedItem.id === item.id)
            if (index < 0) {
                this.selectedInspections.push(item)
            }
        },
        removeFromSelection (item) {
            let index = this.selectedInspections.findIndex(selecteditem => selecteditem.id === item.id)
            if (index >= 0) {
                this.selectedInspections.splice(index, 1)
            }
        },
        isLoading: function () {
            this.loading = this.templatesLoading || this.defaultTemplatesLoading
        }

    }

}
</script>
