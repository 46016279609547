<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 p-2 pt-4">
                <h3
                    v-if="costCenterUpdate.id > 0"
                    class="nopads"
                >
                    {{ $t('costcenters.edit') }}
                </h3>
                <h3
                    v-else
                    class="nopads"
                >
                    {{ $t('costcenters.add') }}
                </h3>
            </div>
            <div class="col-sm-12 form-data">
                <div
                    v-if="isAdmin"
                    class="nopads"
                >
          <span class="span-title">
            {{ $t('users.company') }}
          </span>
                    <b-form-select
                        v-if="costCenterUpdate.id < 1"
                        id="company"
                        v-model="company"
                        size="sm"
                        class="mb-3"
                        :disabled="!companies || companies.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('users.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </b-form-select>
                    <span v-else>
            {{ costCenter.company.name }}
          </span>
                </div>
                <span class="span-title">
          {{ $t('costcenters.name') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="costCenterUpdate.name"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitCostCenter"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'CostCenterEditor',
    mixins: [workManagementHelper, restApi],
    props: {
        costCenter: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            company: null,
            companies: [],
            costCenterUpdate: this.jsonToItem(this.costCenter)
        }
    },

    mounted: function () {
        if (this.isAdmin) {
            this.fetchCompanies()
            if (this.costCenterUpdate.id > 0) {
                this.company = this.costCenterUpdate.company.id
            }
        }
    },

    methods: {
        fetchCompanies: function () {
            if (this.isAdmin) {
                this.loading = true
                this.restFetch(this.companyUrl, this.handleCompanies)
            }
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        setDefaultValues: function (costCenter) {
            if (costCenter.name === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return undefined
            }
            if (this.isAdmin) {
                costCenter.company = {id: this.company}
            }
            return costCenter
        },

        submitCostCenter: function () {
            var json = this.setDefaultValues(this.costCenterUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.costCenter.id < 1) {
                    this.restAdd(this.costCenterUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.costCenterUrl, json, this.success, this.fail)
                }
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
