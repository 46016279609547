<template>
    <div style="height: 100%">
        <!-- Login view -->
        <login
            v-if="!loggedIn && !passwordReset"
            ref="login"
            @resetPassword="resetPassword"
            @closeLoginForm="closeLoginForm"
        />

        <!-- Reset password view -->
        <reset-password-request
            v-if="!loggedIn && passwordReset"
            @resetPasswordDone="resetPasswordDone"
            @resetPasswordClose="resetPasswordClose"
        />

        <!-- Top Bar -->
        <b-navbar
            variant="dark"
            type="dark"
            class="md static-top"
        >
            <b-navbar-brand v-if="loggedIn">
                <span>Routa</span>
                <button
                    class="top-menu-btn"
                    @click.stop="toggleSidebar"
                >
                    <font-awesome-icon icon="bars"/>
                </button>
            </b-navbar-brand>

            <b-nav class="ml-auto">
                <b-nav-item
                    v-if="loggedIn"
                    @click.stop="setActiveView('help')"
                >
          <span slot="button-content">
            <font-awesome-icon icon="question-circle"/>
          </span>
                </b-nav-item>
                <b-nav-form>
                    <b-form-select
                        v-model="locale"
                        :options="options"
                    />
                </b-nav-form>
                <b-nav-item-dropdown
                    v-if="loggedIn"
                    variant="link"
                    right
                    no-caret
                    class="logout"
                >
          <span slot="button-content">
            <font-awesome-icon icon="user"/>
          </span>
                    <b-dropdown-item
                        href="#"
                        @click="setActiveView('user-account')"
                    >
                        {{ $t('menu.user_account') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        href="#"
                        @click="logout"
                    >
                        {{ $t('menu.logout') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="userCompanies && userCompanies.length > 0"
                        href="#"
                        @click="changeCompany"
                    >
                        {{ $t('menu.change_company') }}
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-nav>
        </b-navbar>

        <transition name="fade">
            <div
                v-if="loggedIn && isInitialized"
                class="col-sm-12 nopads"
                style="position: absolute; top: 56px; left: 0; right: 0; bottom: 0"
            >
                <!-- Sidemenu -->
                <div class="sidebar-container nopads">
                    <b-nav
                        vertical
                        class="sidebar"
                        :class="{ toggled: sidebarShrink }"
                    >
                        <b-nav-item
                            @click="setActiveView('map')">
                             <span
                                 slot="button-content"
                                 class="menu-title main-item"
                                 :class="{ 'selected-menu-item' : isView('map') }"
                             >
                                 <font-awesome-icon
                                     fixed-width
                                     icon="map"
                                 />
                                 <span style="font-size: .95em">{{ $t('menu.map') }}</span>
                             </span>
                        </b-nav-item>
                        <b-nav-item-dropdown
                            v-if="isAdmin || isObserver || isWorker"
                            variant="link"
                            no-caret
                            dropright
                            class="active"
                        >
            <span
                slot="button-content"
                class="menu-title"
                :class="{ 'selected-menu-item' : isGroupOpen(vehicleViews) }"
            >
              <font-awesome-icon
                  fixed-width
                  icon="truck"
              />
              <span>{{ $t('menu.fleet') }}</span>
            </span>
                            <b-dropdown-item
                                href="#"
                                @click="setActiveView('fleet')"
                            >
                                {{ $t('menu.fleet_register') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="isAdmin || isObserver"
                                href="#"
                                @click="setActiveView('trackingDevices')"
                            >
                                {{ $t('menu.tracking_devices') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <!-- Material stations -->
                        <b-nav-item-dropdown
                            v-if="isAdmin || isObserver || isWorker"
                            variant="link"
                            dropright
                            no-caret
                        >

            <span
                slot="button-content"
                class="menu-title"
                :class="{ 'selected-menu-item' : isGroupOpen(stationViews) }"
            >
              <font-awesome-icon
                  fixed-width
                  icon="industry"
              />
              <span>{{ $t('menu.stations') }}</span>
            </span>
                            <b-dropdown-item
                                href="#"
                                @click="setActiveView('deliveryNotes')"
                            >
                                {{ $t('menu.delivery_notes') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                href="#"
                                @click="setActiveView('unknownNotes')"
                            >
                                {{ $t('menu.unknown_notes') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <!-- Orders -->
                        <b-nav-item
                            @click="setActiveView('orders')">
                             <span
                                 slot="button-content"
                                 class="menu-title main-item"
                                 :class="{ 'selected-menu-item' : isView('orders') }"
                             >
                                 <font-awesome-icon
                                     fixed-width
                                     icon="clipboard-list"
                                 />
                                 <span style="font-size: .95em">{{ $t('menu.orders') }}</span>
                             </span>
                        </b-nav-item>

                    </b-nav>
                </div>

                <!-- Content placeholder -->
                <div
                    class="content"
                    :class="{ toggled: sidebarShrink }"
                >
                    <transition name="fade">
                        <my-account
                            v-if="isView('user-account')"
                            :user="user"
                        />
                    </transition>
                    <transition name="fade">
                        <map-view
                            v-if="isView('map')"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                            :is-worker="isWorker"
                            :center="station.location"
                            :find-user="false"
                            :menu-enabled="false"
                            :stations-visible="true"
                        />
                    </transition>

                    <transition name="fade">
                        <vehicles
                            v-if="isView('fleet')"
                            :is-admin="isAdmin"
                        />
                    </transition>
                    <transition name="fade">
                        <tracking-devices
                            v-if="isView('trackingDevices')"
                            :is-admin="isAdmin"
                        />
                    </transition>

                    <transition name="fade">
                        <material-delivery-notes
                            v-if="isView('deliveryNotes')"
                            :user="user"
                            :is-admin="isAdmin"
                            :is-observer="isObserver"
                            :selected-station="station"
                            :login-time="loginTime"
                        />
                    </transition>
                    <transition name="fade">
                        <external-scale-records
                            v-if="isView('unknownNotes')"
                            :is-observer="isObserver"
                        />
                    </transition>

                    <transition name="fade">
                        <station-orders
                            v-if="isView('orders')"
                            :user="user"
                            :menu-enabled="false"
                        />
                    </transition>

                    <transition name="fade">
                        <about v-if="isView('help')"/>
                    </transition>

                    <b-alert
                        class="alert"
                        style="z-index: 2000;"
                        :show="alertDismissCountDown"
                        dismissible
                        fade
                        :variant="alertVariant"
                        @dismissed="alertDismissCountDown=0"
                        @dismiss-count-down="alertCountDownChanged"
                    >
                        {{ alertMessage }}
                    </b-alert>
                </div>
            </div>
        </transition>

        <b-modal
            ref="companyModal"
            centered
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            hide-footer
            :title="$t('common.select_company')">
            <div class="col-sm-12">
                <div
                    v-for="company in userCompanies"
                    :key="company.id"
                    class="company_selector"
                    @click.stop="fetchCompanyToken(company.id)">
                    {{ company.name }}
                </div>
            </div>
        </b-modal>

        <div>
            <b-modal
                ref="stationModal"
                centered
                no-close-on-esc
                no-close-on-backdrop
                hide-header-close
                hide-footer
                :title="$t('material_station.select_station')">
                <div class="col-sm-12">
                    <div
                        v-for="item in stations"
                        :key="item.id"
                        class="company_selector"
                        @click.stop="initStation(item)">
                        {{ item.name }}
                    </div>
                </div>
            </b-modal>
        </div>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {app} from '@/main'
import MapView from './map/MapView'
import Vehicles from './vehicle/Vehicles'
import Login from './user/Login'
import ResetPasswordRequest from './user/ResetPasswordRequest'
import {restApi} from './mixins/RestApiMixin'
import {userHelper} from './mixins/UserMixin'
import MyAccount from './user/MyAccount'
import About from './About'
import {utility} from './mixins/Utils'
import TrackingDevices from "./trackingdevices/TrackingDevices";
import ExternalScaleRecords from "@/components/materials/station/ExternalScaleRecords";
import MaterialDeliveryNotes from "@/components/materials/MaterialDeliveryNotes";
import StationOrders from "@/components/order/StationOrders";

export default {
    name: 'Station',
    components: {
        StationOrders,
        ExternalScaleRecords,
        MaterialDeliveryNotes,
        TrackingDevices,
        MapView,
        Vehicles,
        Login,
        ResetPasswordRequest,
        MyAccount,
        About
    },
    mixins: [userHelper, restApi, utility],
    data() {
        return {
            locale: this.getLanguage(),
            loggedIn: false,
            passwordReset: false,
            sidebarShrink: false,
            user: null,
            userCompanies: [],
            isAdmin: false,
            isObserver: false,
            isWorker: false,
            isMapViewer: false,
            isInitialized: false,
            activeView: null,
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            alertVariant: 'danger',
            mapCenter: null,
            options: [
                {value: 'fi', text: 'FI'},
                {value: 'en', text: 'EN'},
                {value: 'fr', text: 'FR'}
            ],
            vehicleViews: ['fleet', 'fleetInspections', 'trackingDevices'],
            stationViews: ['stations', 'scales', 'deliveryNotes', 'unknownNotes'],
            station: null,
            stations: [],
            loginTime: null
        }
    },

    watch: {
        locale(val) {
            app.$i18n.locale = val
        },

        loggedIn: function (value) {
            if (value === true) {
                this.initUser()
            } else {
                this.loading = false
            }
        }
    },

    created: function () {
        EventBus.$on('show-alert', (message, variant = 'danger') => this.showAlert(message, variant))
    },

    beforeDestroy: function () {
        EventBus.$off('show-alert', (message) => this.showAlert(message))
    },

    methods: {
        resetPassword: function () {
            this.passwordReset = true
        },

        resetPasswordClose: function () {
            this.passwordReset = false
        },

        resetPasswordDone: function () {
            this.resetPasswordClose()
            this.$nextTick(() => this.$refs.login.showResetTip())
        },

        initUser: function () {
            this.loading = true
            this.restFetch(this.userInfoUrl, this.handleUserResponse)
        },

        handleUserResponse: function (response) {
            if (response && response.status === 200) {
                this.user = response.data
                // Check for user rights and prompt for company if necessary
                if (this.user.rights && this.user.rights.length > 0) {
                    // Resolve companies, for which user has roles
                    this.userCompanies = this.getUserCompanies()
                    if (this.userCompanies.length > 1) {
                        this.loading = false
                        this.$refs.companyModal.show()
                    } else if (this.userCompanies.length === 1) {
                        // Get token for the only company we have here
                        this.fetchCompanyToken(this.userCompanies[0].id)
                    }
                } else {
                    // Legacy roles
                    // TODO - When legacy is dropped, throw back to login screen if there are no user companies
                    this.initRoles()
                    if (!this.hasAdmin(this.user.roles) && !this.hasObserver(this.user.roles) && !this.hasWorker(this.user.roles)) {
                        this.toggleSidebar()
                    }
                    this.loading = false
                    this.centerToCompany()
                }
            } else {
                // User info initialization failed - throw the user out with an error message
                this.loading = false
                this.loginFailed()
            }
        },

        getUserCompanies: function () {
            let result = []
            if (this.user.rights && this.user.rights.length > 0) {
                this.user.rights.forEach(function (item) {
                    let existing = result.find(cmp => cmp.id === item.company.id)
                    if (!existing) {
                        result.push(item.company)
                    }
                })
            }
            return result
        },

        async fetchCompanyToken(companyId) {
            this.loading = true
            this.$refs.companyModal.hide()
            this.restFetch(this.companyTokenUrl + '/' + companyId, this.handleCompanyTokenResponse)
        },

        handleCompanyTokenResponse: function (response) {
            if (response.data.token) {
                this.$store.commit('storeToken', response.data.token)
                this.user = response.data.user
                this.resetRoles()
                this.fetchStations()
            } else {
                this.loginFailed()
            }
        },

        fetchCompanyFailed: function () {
            this.loginFailed()
        },

        changeCompany: function () {
            this.$refs.companyModal.show()
        },
        fetchStations: function () {
            this.loading = true
            this.restFetch(this.materialStationUrl, this.stationFetchSuccess, this.stationFetchFailed)
        },
        stationFetchSuccess: function (response) {
            this.loading = false
            if (response != null) {
                this.stations = response.data
                if (this.stations.length === 1) {
                    this.initStation(this.stations[0])
                } else if (this.stations.length > 1) {
                    this.$refs.stationModal.show()
                } else {
                    this.stationFetchFailed()
                }
            } else {
                this.stationFetchFailed()
            }
        },
        stationFetchFailed: function () {
            this.loading = false
            this.showAlert(this.$t('material_station.no_station'), 'danger')
            setTimeout(() => {
                this.logout()
            }, 5000)
        },
        initStation: function (station) {
            this.station = station
            this.$refs.stationModal.hide()
            this.initializeUI()
        },
        initializeUI: function () {
            this.initRoles()
            if (this.activeView !== 'map') {
                this.activeView = 'map'
            } else {
                this.activeView = null
                this.$nextTick(function () {
                    this.activeView = 'map'
                })
            }

            this.centerToCompany()
        },

        centerToCompany: function () {
            if (this.user.company && this.user.company.location) {
                this.mapCenter = {
                    lat: this.user.company.location.y,
                    lng: this.user.company.location.x
                }
            }
        },

        loginFailed: function () {
            this.logout()
            this.showAlert(this.$t('login.init_fail'))
        },

        resetRoles: function () {
            this.isAdmin = false
            this.isObserver = false
            this.isWorker = false
            this.isMapViewer = false
            this.hasAccess = false
        },

        initRoles: function () {
            if (this.user && this.user.roles) {
                if (this.hasAdmin(this.user.roles)) {
                    this.isAdmin = true
                    this.activeView = 'map'
                }
                if (this.hasObserver(this.user.roles)) {
                    this.isObserver = true
                    if (!this.activeView) {
                        this.activeView = 'map'
                    }
                }
                if (this.hasWorker(this.user.roles)) {
                    this.isWorker = true
                    if (!this.activeView) {
                        this.activeView = 'map'
                    }
                }
                if (this.hasMapViewer(this.user.roles)) {
                    this.isMapViewer = true
                    if (!this.activeView) {
                        this.activeView = 'map'
                    }
                }
                this.isInitialized = true
            }
        },

        toggleSidebar: function () {
            this.sidebarShrink = !this.sidebarShrink
        },

        showAlert: function (message, variant) {
            this.alertVariant = variant
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
        },

        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },

        setActiveView: function (viewName) {
            if (viewName !== this.activeView) {
                this.activeView = viewName
            }
        },

        isView: function (viewName) {
            return viewName === this.activeView
        },

        isGroupOpen: function (menu) {
            var result = false
            for (var value of menu) {
                if (value === this.activeView) {
                    result = true
                    break
                }
            }
            return result
        },

        closeLoginForm: function () {
            this.loggedIn = true
            this.loginTime = new Date()
        },

        async logout() {
            this.loading = true
            this.$store.commit('storeToken', null)
            this.loggedIn = false
            this.loading = false
            this.resetUserData()
        },

        resetUserData: function () {
            this.activeView = null
            this.user = null
            this.userCompanies = []
            this.isInitialized = false
            this.resetRoles()
        }

    }
}
</script>

<style lang="scss">
a:link, a:visited {
    color: #BCBCBC;
}

a:hover {
    color: #f0f0f0;
}

a:active {
    color: #faa633;
}

.logout {
    margin-left: 1em;
}

.dropdown .dropdown-menu .dropdown-item, .dropdown-item:focus {
    outline: none;
    color: #404041;
}

.menu-title {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 5em;
    text-align: center;
    padding-left: .25em;
}
</style>
