<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background nopads"
            type="dark"
        >
            <div v-if="!editMode" class="header nopads">
        <span class="title p-3">
           {{ $t('delivery_note.title') }}
        </span>
            </div>
            <div
                v-else
                @click="closeEdit"
                class="header"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>
        <div class="col-sm-12 nopads">
            <div class="nopads pb-1" v-if="!editMode ">
                <div v-if="!selectedStation" class="search-form pb-3">
                    <b-form>
                        <div class="col-sm-5">
                            <h5>{{ $t('delivery_note.station') }}</h5>
                            <b-form-select
                                v-model="station"
                                @change="selectStation"
                                size="sm"
                            >
                                <option :value="null">
                                    {{ $t('delivery_note.form.select_station') }}
                                </option>
                                <option
                                    v-for="station in stations"
                                    :key="station.id"
                                    :value="station.id"
                                >
                                    {{ station.name }}
                                </option>
                            </b-form-select>
                        </div>
                    </b-form>
                </div>
                <div class="col-sm-6 pl-3 ml-1 pb-0" v-else>
                    <h3>{{ selectedStation.name }}</h3>
                </div>
            </div>
            <delivery-note-editor
                :note="deliveryNote"
                :station="station"
                :contracts="editorContracts"
                :show-only-my-contracts-option="true"
                :materials="materials"
                @onlyUserOwnedContractSelected="onlyUserOwnedContractSet"
                @update="update"
                @close="closeEdit"
            />
            <keep-alive>
                <delivery-note-search
                    ref="noteSearch"
                    v-if="!editMode"
                    :showOnlyMyContractsOption="true"
                    :station="station"
                    :contracts="searchContracts"
                    :materials="materials"
                    :login-time="loginTime"
                    @search="fetchDeliveryNotes"
                    @onlyUserOwnedContractSelected="onlyUserOwnedContractSet"
                />
            </keep-alive>
            <delivery-note-list
                v-if="!editMode"
                :notes="notes"
                :station="station"
                :isAdmin="isAdmin"
                :isObserver="isObserver"
                @edit="editNote"
            />
        </div>
        <div
            v-if="!!loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";
import {deliveryNoteHelper} from "@/components/mixins/DeliveryNoteMixin";
import DeliveryNoteEditor from "@/components/materials/DeliveryNoteEditor";
import DeliveryNoteList from "@/components/materials/DeliveryNoteList";
import DeliveryNoteSearch from "@/components/materials/DeliveryNoteSearch";

export default {
    name: "MaterialDeliveryNotes",
    components: {DeliveryNoteSearch, DeliveryNoteList, DeliveryNoteEditor},
    mixins: [restApi, deliveryNoteHelper, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        selectedStation: {
            type: Object,
            default: null
        },
        loginTime: {
            type: Date,
            default: null
        },
    },
    data() {
        return {
            notes: [],
            stations: [],
            editorContracts: [],
            searchContracts: [],
            materials: [],
            station: null,
            deliveryNote: null,
            editMode: false,
            lastSearchParams: null,
            loading: 0,
            onlyUserOwnedContracts: 0
        }
    },
    created: function () {
        this.fetchMaterialStations()
        this.deliveryNote = this.newDeliveryNote()
    },
    methods: {
        fetchDeliveryNotes: function (params) {
            this.loading++
            if (!params) {
                params = {}
            } else {
                this.lastSearchParams = params
            }
            if (this.selectedStation) {
                params.station = this.selectedStation.id
            } else {
                params.station = this.station
            }
            this.restFetchParams(this.materialDeliveryUrl, this.lastSearchParams, this.fetchNotesSuccess, this.fetchFail)
        },
        fetchMaterialStations: function () {
            this.loading++
            this.restFetch(this.materialStationUrl, this.fetchStationsSuccess, this.fetchFail)
        },
        fetchContracts: function (type=null) {
            this.loading++
            let params = {}
            if (this.station) {
                let station = this.stations.find(x => x.id === this.station)
                if (station) {
                    params.contractor = station.company.id
                }
            }
            if(parseInt(this.onlyUserOwnedContracts) === 1) {
                params.onlyUserOwnedContracts = 1
            }
            params.startDate = this.getStartOfTheDay(new Date())
            params.endDate = this.getEndOfTheDay(new Date())
            if(type=="search") {
                this.restFetchParams(this.contractOpenUrl, params, this.searchContractsSuccess, this.fetchFail)
            } else if(type=="editor") {
                this.restFetchParams(this.contractOpenUrl, params, this.editorContractsSuccess, this.fetchFail)
            } else {
                this.restFetchParams(this.contractOpenUrl, params, this.contractsSuccess, this.fetchFail)
            }
        },
        selectStation: function () {
            if (this.selectedStation) {
                this.station = this.selectedStation.id
            }
            this.getMaterials()
            this.fetchContracts()
        },
        getMaterials: function () {
            this.materials = []
            if (this.stations && this.station === null) {
                this.stations.forEach(station => {
                    if (station.station_materials) {
                        station.station_materials.forEach(material => {
                            this.materials.push(material)
                        })
                    }
                })
            } else {
                let station = this.stations.find(x => x.id === this.station)
                if (station) {
                    this.materials = station.station_materials
                }
            }
        },
        fetchNotesSuccess: function (response) {
            this.notes = response.data
            this.loading--
        },
        fetchStationsSuccess: function (response) {
            this.stations = response.data
            this.selectStation()
            this.loading--
        },
        contractsSuccess: function (response) {
            this.searchContracts = response.data
            this.editorContracts = response.data
            this.loading--
        },
        searchContractsSuccess: function (response) {
            this.searchContracts = response.data
            this.loading--
        },
        editorContractsSuccess: function (response) {
            this.editorContracts = response.data
            this.loading--
        },
        fetchFail: function () {
            this.loading--
        },
        update: function (newNote = false) {
            if (!newNote) {
                this.closeEdit()
            }
            if (this.lastSearchParams) {
                this.fetchDeliveryNotes(this.lastSearchParams)
            } else {
                this.$nextTick(() => this.$refs.noteSearch.refreshParams())
            }

        },
        closeEdit: function () {
            this.editMode = false
            this.station = null
            this.selectStation()
            this.deliveryNote = this.newDeliveryNote()
        },
        editNote: function (note) {
            this.deliveryNote = note
            this.editMode = true
            this.station = note.station_material ? note.station_material.station.id : undefined
            this.selectStation()
        },
        onlyUserOwnedContractSet: function(value, type) {
            this.onlyUserOwnedContracts = value
            this.fetchContracts(type)
        }
    }
}
</script>
