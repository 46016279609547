<template>
    <div class="work-day-records">
        <div class="col-sm-11 work-record-container">
            <h3>
                {{ name }}
            </h3>
            <span class="item-detail-text">
        {{ timeRange }}
      </span>
            <div
                id="time-list"
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="tableItems"
                    :fields="fields"
                    @row-clicked="emitEdit"
                >
                    <template v-slot:cell(start)="row">
                        {{ row.value ? toLocalTime(row.value) : '-' }}
                    </template>
                    <template v-slot:cell(approved_start)="row">
                        {{ row.value ? toLocalTime(row.value) : '-' }}
                    </template>
                    <template v-slot:cell(end)="row">
                        {{ row.value ? toLocalTime(row.value) : '-' }}
                    </template>
                    <template v-slot:cell(approved_end)="row">
                        {{ row.value ? toLocalTime(row.value) : '-' }}
                    </template>
                </b-table>
            </div>
            <div class="col-sm-12 button-container">
                <b-button
                    v-if="isObserver"
                    variant="success"
                    class="result-button"
                    size="sm"
                    @click.stop="addRecord"
                >
                    {{ $t('work_time.add_record') }}
                </b-button>
                <b-button
                    v-if="isObserver && showApproval"
                    variant="primary"
                    class="result-button"
                    size="sm"
                    @click.stop="approveRecords"
                >
                    {{ $t('work_time.approve_records') }}
                </b-button>
                <b-button
                    variant="secondary"
                    class="result-button"
                    size="sm"
                    @click.stop="$emit('closeRecords')"
                >
                    {{ $t('common.close') }}
                </b-button>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from '@/event-bus'
import {workShift} from "../mixins/WorkShift";

export default {
    name: 'WorktimeList',
    mixins: [timeUtils, restApi, workShift],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        showApproval: {
            type: Boolean,
            default: true
        },
        name: {
            type: String,
            default: ''
        },
        userId: {
            type: Number,
            default: -1
        },
        timeRange: {
            type: String,
            default: ''
        },
    },
    data: function () {
        return {
            loading: false,
            tableItems: [],
            fields: [
                {
                    mode: {
                        label: this.$t('work_time.mode'),
                        sortable: true
                    }
                },
                {
                    costCenter: {
                        label: this.$t('work_time.cost_center'),
                        sortable: true
                    }
                },
                {
                    shift: {
                        label: this.$t('work_shift.shift'),
                        sortable: false
                    }
                },
                {
                    contractName: {
                        label: this.$t('work_time.contract'),
                        sortable: false
                    }
                },
                {
                    taskName: {
                        label: this.$t('work_time.task'),
                        sortable: false
                    }
                },
                {
                    start: {
                        label: this.$t('work_time.start'),
                        sortable: true
                    }
                },
                {
                    approved_start: {
                        label: this.$t('work_time.approved_start'),
                        sortable: true
                    }
                },
                {
                    startLocation: {
                        label: this.$t('work_time.start_location'),
                        sortable: true
                    }
                },
                {
                    end: {
                        label: this.$t('work_time.end'),
                        sortable: true
                    }
                },
                {
                    approved_end: {
                        label: this.$t('work_time.approved_end'),
                        sortable: true
                    }
                },
                {
                    endLocation: {
                        label: this.$t('work_time.end_location'),
                        sortable: true
                    }
                },
                {
                    duration: {
                        label: this.$t('work_time.duration'),
                        sortable: true
                    }
                },
                {
                    approver: {
                        label: this.$t('work_time.approver'),
                        sortable: true
                    }
                },
                {
                    note: {
                        label: this.$t('work_time.note'),
                        sortable: true
                    }
                }
            ]
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        addRecord: function () {
            this.$emit('add', this.userId)
        },
        emitEdit: function (item) {
            let p = this.results.find(result => result.id === item.id)
            if (p) {
                this.$emit('edit', p)
            }
        },
        approveRecords: function () {
            let ids = []
            this.loading = true
            if (this.results) {
                this.results.forEach(function (record) {
                    ids.push(record.id)
                })
                this.restAdd(this.workTimeApproveUrl, {records: ids}, this.handleApproval, this.handleApprovalFail)
            } else {
                EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            }
        },

        handleApproval: function () {
            this.loading = false
            this.$emit('closeRecords')
        },

        handleApprovalFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },

        hasGap: function (first, second) {
            if (first && second) {
                let duration = this.getDurationInMinutes(first, second)
                return duration > 0 && duration <= 4 * 60
            }
            return false
        },

        initTableItems: function () {
            this.tableItems = []
            if (this.results) {
                let sorted = this.results.sort((a, b) => {
                    return a.approved_start_time > b.approved_start_time
                })
                let gapBack, gapFwd = false
                for (let i = 0; i < sorted.length; i++) {
                    let record = sorted[i]
                    let nextRecord = sorted[i + 1]
                    gapBack = !record.approved_start_time || gapFwd
                    gapFwd = !record.approved_end_time || nextRecord && this.hasGap(record.approved_end_time, nextRecord.approved_start_time)
                    this.tableItems.push({
                        id: record.id,
                        mode: record.mode && record.mode.name ? record.mode.name : '-',
                        costCenter: record.cost_center !== undefined ? record.cost_center.name : '-',
                        shift: this.getWorkShiftName(record.shift),
                        contractName: record.order ? record.order.contract.name : '-',
                        taskName: record.order ? record.order.task_type.name : '-',
                        start: record.start_time ? record.start_time : '',
                        approved_start: record.approved_start_time ? record.approved_start_time : '',
                        startLocation: this.getStartLocation(record),
                        end: record.end_time ? record.end_time : '',
                        approved_end: record.approved_end_time ? record.approved_end_time : '',
                        endLocation: this.getEndLocation(record),
                        duration: record.approved_end_time !== undefined ? this.getDuration(record.approved_start_time, record.approved_end_time) : '',
                        approver: record.approver ? record.approver.last_name + ', ' + record.approver.first_name : '-',
                        note: record.note !== undefined ? record.note : '',
                        _cellVariants: {
                            approved_start: gapBack ? 'warning' : null,
                            approved_end: gapFwd ? 'warning' : null
                        },
                        _rowVariant: this.getWorkRowVariant(record.mode)
                    })
                }
            }
        },
        getStartLocation: function (record) {
            let startLocation = '-'
            if (record.start_road_name) {
                startLocation = record.start_road_name
                if (record.start_city) {
                    startLocation += ', ' + record.start_city.name
                }
            }
            return startLocation
        },
        getEndLocation: function (record) {
            let endLocation = '-'
            if (record.end_road_name) {
                endLocation = record.end_road_name
                if (record.end_city) {
                    endLocation += ', ' + record.end_city.name
                }
            }
            return endLocation
        }
    }
}
</script>
