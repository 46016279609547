<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="report">
        <!-- Basic info -->
        <table class="table table-sm">
            <tbody>
            <tr>
                <td colspan="2">
                    <span class="span-header">{{ $t('menu.work_report') }}</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('work_report.customer') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.order.contract.customer.name }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('work_report.contract') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.order.contract.name }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('work_report.task_type') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.order.task_type.name }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('work_report.start_time') }}
                </td>
                <td class="item-detail-text">
                    {{ toLocalTime(reportDetails.start_time) }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('work_report.end_time') }}
                </td>
                <td class="item-detail-text">
                    {{ toLocalTime(reportDetails.end_time) }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('work_report.reporter') }}
                </td>
                <td v-if="reportDetails.reporter"
                    class="item-detail-text">
                    {{ reportDetails.reporter.first_name + ' ' + reportDetails.reporter.last_name }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('work_report.info') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.info }}
                </td>
            </tr>
            </tbody>
        </table>

        <!--Work team-->
        <span v-if="reportDetails.persons && reportDetails.persons.length"
              class="span-header">
      {{ $t('work_report.work_team') }}
    </span>
        <div
            v-if="reportDetails.persons && reportDetails.persons.length"
            id="persons"
            class="col-sm-12 panel-group data-container">
            <b-table
                show-empty
                small
                stacked="md"
                :items="reportDetails.persons"
                :fields="teamFields"
                :sort-by.sync="teamSortBy"
                :sort-desc.sync="sortDesc"
            >
                <template v-slot:cell(certificates)="row">
                    <div>
                        {{ getCertificates(row.value) }}
                    </div>
                </template>
            </b-table>
        </div>

        <!--Trip logs-->
        <span
            v-if="tripLogs && tripLogs.length"
            class="span-header"
        >
      {{ $t('work_report.trips') }}
    </span>
        <div
            v-if="tripLogs && tripLogs.length"
            id="tripLogs"
            class="col-sm-12 panel-group data-container"
        >
            <b-table
                show-empty
                small
                stacked="md"
                :items="tripLogs"
                :fields="tripLogFields"
                :sort-by.sync="tripLogSortBy"
                :sort-desc.sync="sortDesc">
                <template v-slot:cell(vehicle)="row">
                    <div>
                        {{ row.value }}
                    </div>
                </template>
                <template v-slot:cell(start_time)="row">
                    <div>
                        {{ toLocalTime(row.value) }}
                    </div>
                </template>
                <template v-slot:cell(end_time)="row">
                    <div>
                        {{ toLocalTime(row.value) }}
                    </div>
                </template>
                <template v-slot:cell(mode)="row">
                    <div>
                        {{ getTripModeString(row.value) }}
                    </div>
                </template>
            </b-table>
        </div>

        <!--Materials-->
        <span v-if="reportDetails.material_reports && reportDetails.material_reports.length"
              class="span-header">
      {{ $t('work_report.materials') }}
    </span>
        <div v-if="reportDetails.material_reports && reportDetails.material_reports.length"
             id="materials"
             class="col-sm-12 panel-group data-container">
            <b-table
                show-empty
                small
                stacked="md"
                :items="reportDetails.material_reports"
                :fields="materialFields"
                :sort-by.sync="materialSortBy"
                :sort-desc.sync="sortDesc"
            />
        </div>

        <!--Consignment notes-->
        <span
            v-if="reportDetails.consignment_notes && reportDetails.consignment_notes.length"
            class="span-header"
        >
      {{ $t('work_report.consignment_notes') }}
    </span>
        <div
            v-if="reportDetails.consignment_notes && reportDetails.consignment_notes.length"
            id="consignmentNotes"
            class="col-sm-12 panel-group data-container"
        >
            <b-table
                show-empty
                small
                stacked="md"
                :items="reportDetails.consignment_notes"
                :fields="consignmentNotesFields"
                :sort-by.sync="consignmentNotesSortBy"
                :sort-desc.sync="sortDesc"
            >
                <template v-slot:cell(time)="row">
                    <div>
                        {{ toLocalTime(row.value) }}
                    </div>
                </template>
                <template v-slot:cell(vehicle)="row">
                    <div>
                        {{ getVehicleString(row.value) }}
                    </div>
                </template>
                <template v-slot:cell(photo)="row">
                    <font-awesome-icon
                        icon="paperclip"
                        class="attachment-icon"
                        @click.stop="downloadNoteAttachment(row.value)"
                    />
                </template>
            </b-table>
        </div>

        <!--Recipes-->
        <span
            v-if="reportDetails.recipes && reportDetails.recipes.length > 0"
            class="span-header"
        >
      {{ $t('work_report.attachments') }}
    </span>
        <div
            v-for="recipe in reportDetails.recipes"
            :key="recipe.id"
        >
            <div>
                <font-awesome-icon
                    icon="paperclip"
                    class="attachment-icon"
                    @click.stop="downloadRecipe(recipe)"
                />
            </div>
        </div>
        <div v-if="loading"
             id="loader"
             class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {printHelper} from '../mixins/PrintMixin'
import {restApi} from '../mixins/RestApiMixin'
import {Printd} from 'printd'
import {vehicleHelper} from '../mixins/VehicleMixin'

export default {
    name: 'WorkReportDetails',
    mixins: [timeUtils, printHelper, restApi, vehicleHelper],
    props: {
        reportDetails: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            attachment: null,
            sortDesc: false,
            loading: false,
            teamSortBy: 'last_name',
            teamFields: [
                {key: 'first_name', label: this.$t('work_report.first_name'), sortable: false},
                {key: 'last_name', label: this.$t('work_report.last_name'), sortable: false},
                {key: 'tax_number', label: this.$t('users.tax_number'), sortable: false},
                {key: 'certificates', label: this.$t('menu.certificates'), sortable: false}
            ],
            materialSortBy: 'road_num',
            materialFields: [
                {key: 'road_num', label: this.$t('work_report.road_no'), sortable: false},
                {key: 'start_sec_num', label: this.$t('work_report.road_section_from'), sortable: false},
                {key: 'end_sec_num', label: this.$t('work_report.road_section_to'), sortable: false},
                {key: 'location_info', label: this.$t('work_report.location_info'), sortable: false},
                {key: 'amount', label: this.$t('work_report.amount'), sortable: false},
                {key: 'unit', label: this.$t('work_report.unit'), sortable: false}
            ],
            consignmentNotesSortBy: 'road_num',
            consignmentNotesFields: [
                {key: 'note_number', label: this.$t('work_report.booking_no'), sortable: false},
                {key: 'time', label: this.$t('work_report.time'), sortable: false},
                {key: 'station', label: this.$t('work_report.station'), sortable: false},
                {key: 'vehicle', label: this.$t('work_report.vehicle'), sortable: false},
                {key: 'amount', label: this.$t('work_report.amount'), sortable: false},
                {key: 'photo', label: this.$t('work_report.attachment'), sortable: false}
            ],
            tripLogSortBy: 'vehicle',
            tripLogFields: [
                {key: 'vehicle', label: this.$t('work_report.vehicle'), sortable: false},
                {key: 'mode', label: this.$t('work_report.mode'), sortable: false},
                {key: 'start_time', label: this.$t('work_report.start'), sortable: false},
                {key: 'end_time', label: this.$t('work_report.end'), sortable: false},
                {key: 'length', label: this.$t('work_report.distance'), sortable: false}
            ],
            tripLogs: []
        }
    },
    mounted() {
        this.d = new Printd()
        this.getTripLogs()
    },
    methods: {
        getCertificates: function (certificates) {
            var string = ''
            certificates.forEach(function (certificate) {
                if (string.length !== 0) {
                    string += ', '
                }
                string += certificate.type.name
                if (certificate.expiration_day) {
                    string += ' - '
                    string += this.getShortDateString(certificate.expiration_day)
                }
            }, this)
            return string
        },
        print() {
            if (!this.loading) {
                this.d.print(this.$el, this.printStyle)
            }
        },
        downloadNoteAttachment: function (photo) {
            let consignmentNote = this.reportDetails.consignment_notes.find(function (note) {
                return note.photo.id === photo.id
            }, this)
            if (consignmentNote) {
                this.download(this.consignmentNoteUrl + consignmentNote.id + '/' + photo.id, photo)
            }
        },
        downloadRecipe: function (recipe) {
            this.download(this.recipeUrl + recipe.id + '/' + recipe.photo.id, recipe.photo)
        },
        download: function (url, photo) {
            if (!this.loading) {
                this.loading = true
                this.attachment = photo
                this.restFetch(url, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            this.loading = false
            if (response && response.status === 200) {
                this.saveFile(response.data)
            }
        },
        saveFile: function (base64Image) {
            let a = document.createElement('a')
            document.body.appendChild(a)
            a.style = 'display: none'
            a.href = base64Image
            if (this.attachment.file_type === 'image/png') {
                a.download = this.attachment.id + '.png'
            } else {
                a.download = this.attachment.id + '.jpeg'
            }
            a.click()
        },
        getTripLogs: function () {
            this.loading = true
            this.restFetch(this.workReportsTripLogUrl + '/' + this.reportDetails.id, this.handleTripLogResponse)
        },
        handleTripLogResponse: function (response) {
            this.tripLogs = response.data
            this.loading = false
        },
        getVehicleString: function (vehicle) {
            let result = '-'
            if (vehicle && vehicle.make) {
                result = vehicle.make
                if (vehicle.vehicle_model) {
                    result += ' ' + vehicle.vehicle_model
                }
            }
            return result
        }
    }
}
</script>
