<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showInspectablesList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('inspectable.inspectable') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showInspectablesList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>

        <div class="col-sm-12">
            <transition name="fade">
                <inspectable-list
                    v-if="!editMode"
                    :results="results"
                    @add="addInspectable"
                    @edit="editInspectable"
                />
            </transition>
            <transition name="fade">
                <inspectable-editor
                    v-if="editMode"
                    :inspectable="inspectable"
                    @close="showInspectablesList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {inspectionHelper} from '../../mixins/InspectionMixin'
import InspectableList from './InspectableList'
import InspectableEditor from './InspectableEditor'

export default {
    name: 'Inspectables',
    components: {InspectableList, InspectableEditor},
    mixins: [restApi, inspectionHelper],
    data: function () {
        return {
            results: [],
            inspectable: null,
            editMode: false,
            loading: true
        }
    },
    mounted: function () {
        this.fetchInspectables()
    },
    methods: {
        fetchInspectables: function () {
            this.loading = true
            this.restFetch(this.inspectableUrl, this.handleResponse)
        },

        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },

        addInspectable: function () {
            this.inspectable = this.newInspectable()
            this.editMode = true
        },
        editInspectable: function (inspectable) {
            this.inspectable = inspectable
            this.editMode = true
        },
        showInspectablesList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchInspectables()
            this.showInspectablesList()
        }

    }
}
</script>
