<template>
    <div style="user-select: none" v-show="assignmentsShow">
        <div class="order-text sub-item"  :style="{width: textColWidthPercentage + '%', marginTop: '-2px', marginBottom: '-2px'}">
            <b-row class="col-12 nopads">
                <div class="col-9 nopads pr-1" >
                        <span :id="'a_t'+assignmentObject.id" class="single-line" @click.stop="$emit('showAssignment', assignmentObject)">
                           {{ assignmentObject.id }} - {{ getTitle(assignmentObject) }} ({{assignmentObject.assigned_to ?
                            assignmentObject.assigned_to.last_name + ', ' + assignmentObject.assigned_to.first_name : '-' }})
                        </span>
                </div>
                <div class="col-3 nopads pl-3 text-right">
                    <assignment-status-badge
                        :item-id="assignmentObject.id"
                        :status="assignmentObject.status"
                        @assignmentStatusChanged="updateAssignmentStatus"
                    />
                </div>
            </b-row>
        </div>
        <div style="display: inline-block; user-select: none">
            <div id="timeline-container" style="display: flex;  position: relative">
                <div class="week-item"
                     :style="{width: weekColWidth + 'px'}"
                     v-for="(week, index) in weeks"
                     v-bind:key="index">{{ '-' }}</div>
                <!-- Draggable and resizable time line -->
                <time-line-item
                    :item-id="assignmentObject.id"
                    :time-col-width="weekColWidth"
                    :timeline-start-date="new Date(calendarStartDate)"
                    :calendar-start-date="new Date(calendarStartDate)"
                    :calendar-start-week="calendarStartWeek"
                    :visible-weeks-limit="visibleWeeksLimit"
                    :color="statusColor"
                    :startDate="assignmentObject.planned_start ? getDateFromString(assignmentObject.planned_start) : null"
                    :endDate="assignmentObject.planned_ready ? getDateFromString(assignmentObject.planned_ready) : null"
                    @datesChanged="updateAssignmentDates"/>
            </div>
        </div>
    </div>
</template>


<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import TimeLineItem from "@/components/contractcalendar/TimeLineItem";
import {workAssignmentHelper} from "@/components/mixins/WorkAssignmentMixin";
import AssignmentStatusBadge from "@/components/contractcalendar/AssignmentStatusBadge";
export default {
    name: 'AssignmentCalendarItem',
    components: {AssignmentStatusBadge, TimeLineItem},
    mixins: [timeUtils, restApi, workAssignmentHelper],
    data: function () {
        return {
            start: null,
            assignmentsVisible: false,
            loading: 0,
            assignmentObject: null,
            statusColor: null,
            weeks: [],
            assignmentsShow: false,
        }
    },
    props: {
        assignment: {
            type: Object,
            default: null
        },
        assignmentsSelectedLimiter: {
            type: Array,
            default: null
        },
        textColWidthPercentage: {
            type: Number,
            default: 40
        },
        timeColWidth: {
            type: Number,
            default: 100  // px
        },
        calendarStartWeek: {
            type: Number,
            default: 20
        },
        visibleWeeksLimit: {
            type: Number,
            default: 30
        },
        calendarStartDate: {
            type: Date,
            default: null
        }
    },
    computed: {
        weekColWidth() {
            return Math.round(this.timeColWidth / 7) * 7
        }
    },
    watch: {
        calendarStartDate: function () {
            this.initTable()
        },
        assignment: function () {
            this.assignmentObject = this.assignment
        },
        assignmentsSelectedLimiter: function () {

            this.assignmentsToggleShown()
        }
    },
    created: function () {
        this.assignmentObject = this.assignment
        this.statusColor = this.getStatusColor(this.assignmentObject.status)
        this.initTable();
        this.assignmentsToggleShown()
    },
    methods: {
        initTable() {
            this.weeks = []
            let startWeek = this.calendarStartWeek
            for (let i = 0; i < this.visibleWeeksLimit; i++) {
                this.weeks.push(this.$t('calendar.wk_prefix') + (startWeek+i))
            }
        },
        assignmentsToggleShown() {
            if (this.assignmentsSelectedLimiter === null || this.assignmentsSelectedLimiter.length === 0) this.assignmentsShow = true
            else {
                for (let i = 0; i < this.assignmentsSelectedLimiter.length; i++) {
                    this.assignmentsSelectedLimiter.includes(this.assignmentObject.status) ? this.assignmentsShow = true : this.assignmentsShow = false
                }
            }
        },
        updateAssignmentDates(id, startDate, endDate) {
            this.assignmentObject.planned_start = startDate.toISOString()
            this.assignmentObject.planned_ready = endDate.toISOString()
            this.restUpdate(this.workAssignmentUrl, this.assignmentObject, this.assignmentUpdated, this.assignmentUpdateFailed)
        },
        updateAssignmentStatus(assignmentId, status) {
            this.assignmentObject.status = status
            this.statusColor = this.getStatusColor(status)
            this.restUpdate(this.workAssignmentUrl, this.assignmentObject, this.assignmentUpdated, this.assignmentUpdateFailed)
            this.assignmentsToggleShown()
        },
        assignmentUpdated() {
            // TODO - Indicate success??
        },
        assignmentUpdateFailed() {
            // TODO - Show error message
        },
    }
}
</script>
<style scoped>
.week-item, .order-text, .fill-item {
    display: inline-block;
    background: #ffffff;
    border-top: 1px #BCBCBC solid;
    border-right: 1px #BCBCBC solid;
    margin: 0;
    padding: .1em;
    font-size: .9em;
    user-select: none;
    min-height: 2em;
}
.order-text {
    color: #404041;
    padding-left: 2.5em;
}
.sub-item {
    padding-left: 7em;
    color: #636364;
    font-size: .9em;
    font-weight: bold;
}
.week-item, .fill-item {
    color: #FFFFFF;
    user-select: none;
    padding: .1em;
}
.single-line {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.order-text:hover, .single-line:hover {
    color: #007BFF;
    font-weight: bold;
    cursor: pointer;
}
</style>
