<template>
    <div ref="container" class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">{{ $t('road_sign.road_sign') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <keep-alive>
                    <road-sign-search
                        :is-admin="isAdmin"
                        :user="user"
                        @search="fetchRoadSign"
                    />
                </keep-alive>
            </transition>
            <transition name="fade">
                <div class="col-sm-12">
                    <div
                        v-if="results.length > 0"
                        class="button-container col-sm-12 trip-buttons"
                    >
                        <b-button
                            variant="outline-success"
                            :disabled="results.length < 1"
                            class="result-button"
                            @click="exportExcel"
                        >
                            {{ $t('trip.download_excel') }}
                        </b-button>
                    </div>
                    <road-sign-list
                        :road-signs="results"
                        @rowClicked="editRoadSign"
                    />
                </div>
            </transition>

            <road-sign-wizard
                v-if=" roadSign != null"
                :road-sign="roadSign"
                :is-admin="isAdmin"
                :user="user"
                @close="roadSign=null"
                @closeAndUpdate="closeAndUpdate"
            />

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import RoadSignSearch from "@/components/roadsign/RoadSignSearch";
import RoadSignList from "@/components/roadsign/RoadSignList";
import RoadSignWizard from "@/components/roadsign/RoadSignWizard";
import {EventBus} from "@/event-bus";
import {downloadHelper} from "../mixins/DownloadMixin";

export default {
    name: 'road-signs',
    components: {RoadSignWizard, RoadSignList, RoadSignSearch},
    mixins: [restApi, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    data: function () {
        return {
            results: [],
            roadSign: null,
            loading: false,
            lastSearchedParams: null
        }
    },

    methods: {
        fetchRoadSign: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.roadSignUrl, this.lastSearchedParams, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        },
        editRoadSign: function (roadSign) {
            this.roadSign = roadSign
        },
        closeAndUpdate: function () {
            this.roadSign = null
            this.fetchRoadSign(this.lastSearchedParams)
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.roadSignXlsxUrl, this.getCommonReportParams(this.lastSearchedParams), this.excelDownloadSuccess, this.fileDownloadFail)
        },

        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('signs.report_file_name'))
        },

        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
    }
}
</script>
