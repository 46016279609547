<template>
    <div class="nopads pb-3">
        <b-form
            @submit.prevent="fetchHarjaNotifications"
            @reset="onReset"
        >
            <b-row class="nopads">
                <div class="col-sm-6">
                    <span class="search-title">{{ $t('delivery_note.date') }} - {{ $t('trip_list.road_sec_from') }}</span>
                    <datetime
                        v-model="startDate"
                        type="date"
                        format="dd.MM.yyyy"
                        class="routa-input"
                    />
                </div>
                <div class="col-sm-6">
                    <span class="search-title">{{ $t('delivery_note.date') }} - {{ $t('trip_list.road_sec_to') }}</span>
                    <datetime
                        v-model="endDate"
                        type="date"
                        format="dd.MM.yyyy"
                        class="routa-input"
                    />
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>

import {mapHelper} from "@/components/mixins/MapMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'harjaSearch',
    mixins: [mapHelper,restApi, timeUtils],
    components: {},
    props: {
        contractId: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            loading: false,
            startDate: this.getMonthStart() + ":00+03",
            endDate: this.getToday() + ":00+03"
        }
    },
    methods: {

        onReset: function () {
            this.startDate = this.getMonthStart() + ":00+03"
            this.endDate = this.getToday() + ":00+03"
        },

        async fetchHarjaNotifications() {
            let params = {}
            params.start_date = this.startDate.substring(0, this.startDate.length - 2) + "+03"
            params.end_date = this.endDate.substring(0, this.endDate.length - 2) + "+03"
            if (this.contractId) {
                params.contract = this.contractId
            }
            try {
                this.loading = true
                this.restFetchParams(this.harjaAlertUrl, params, this.fetchSuccess, this.fetchFailed);
            } catch (e) {
                return null
            }
        },
        close: function () {
            this.$emit('close')
        },
        fetchSuccess: function (data) {
            this.loading = false
            this.$emit('search', data.data)
        },
        fetchFailed: function () {
            this.loading = false
        },
    }
}
</script>

<style scoped>

</style>
