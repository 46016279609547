import {equipmentOidTypes} from '@/components/mixins/ConversionMixin'

export default class VelhoRoadsideFurnitureModel {
    // Private properties
    /** @type {string} */
    #external_system_id = equipmentOidTypes.EXTERNAL_ROADSIDE_FURNITURE

    constructor(
        type = null, material = null, position = { x: null, y: null }, roadNum = null, roadSec = null, distance = null, condition = null, wcDrainage = null, invaWc = false, wcHouseholdWater = false, equipmentAmount = null, standardConstruction = false,
        code = "", wcHeating = false, transferable = false, bussStopTransparency = false, lighting = false, info = "", bathRoom = false, bussStopWallAmount = null, bussStopCode = "",
        serviceAreaCode = "", equipmentDamages = [], locationDeviation = null, locationSpecification = { lane: [], side: null, tracks: null, central_area: null }, externalSystemId = ""
    ) {
        /** @type {number | null} */
        this.type = type

        /** @type {number | null} */
        this.material = material

        /** @type {{ x: number, y: number }} */
        this.position = position

        /** @type {number | null} */
        this.road_num = roadNum

        /** @type {number | null} */
        this.road_sec = roadSec

        /** @type {number | null} */
        this.distance = distance

        /** @type {number | null} */
         this.condition = condition

        /** @type {number | null} */
        this.wc_drainage = wcDrainage

        /** @type {boolean} */
        this.inva_wc = invaWc

        /** @type {boolean} */
        this.wc_household_water = wcHouseholdWater

        /** @type {number | null} */
        this.equipment_amount = equipmentAmount

        /** @type {boolean} */
        this.standard_construction = standardConstruction

        /** @type {string} */
        this.code = code

        /** @type {boolean} */
        this.wc_heating = wcHeating

        /** @type {boolean} */
        this.transferable = transferable

        /** @type {boolean} */
        this.buss_stop_transparency = bussStopTransparency

        /** @type {boolean} */
        this.lighting = lighting

        /** @type {string} */
        this.info = info

        /** @type {boolean} */
        this.bath_room = bathRoom
        
        /** @type {number | null} */
        this.buss_stop_wall_amount = bussStopWallAmount

        /** @type {string} */
        this.buss_stop_code = bussStopCode

        /** @type {string} */
        this.service_area_code = serviceAreaCode
        
        /** @type {Array} */
        this.equipment_damages = equipmentDamages

        /** @type {number | null} */
        this.location_deviation = locationDeviation

        /** @type {{ lane: number[], side: number | null, tracks: number | null, central_area: number | null }} */
        this.location_specification = locationSpecification

        if(externalSystemId) {
            this.#external_system_id = externalSystemId
        }
    }

    /**
    * Gets the unique identifier.
    * @returns {string}
    */
    get external_system_id() {
        return this.#external_system_id
    }

    /**
    * Get the culvert json object.
    * @returns {object}
    */
    toJSON() {
        return JSON.parse(
            JSON.stringify({
            external_system_id: this.#external_system_id,
            type: this.type,
            material: this.material,
            condition: this.condition,
            position: this.position,
            road_num: this.road_num,
            road_sec: this.road_sec,
            distance: this.distance,
            wc_drainage: this.wc_drainage,
            inva_wc: this.inva_wc,
            wc_household_water: this.wc_household_water,
            equipment_amount: this.equipment_amount,
            standard_construction: this.standard_construction,
            code: this.code,
            wc_heating: this.wc_heating,
            transferable: this.transferable,
            buss_stop_transparency: this.buss_stop_transparency,
            lighting: this.lighting,
            info: this.info,
            bath_room: this.bath_room,
            buss_stop_wall_amount: this.buss_stop_wall_amount,
            buss_stop_code: this.buss_stop_code,
            service_area_code: this.service_area_code,
            equipment_damages: this.equipment_damages,
            location_deviation: this.location_deviation,
            location_specification: {
                ...this.location_specification,
                tracks: this.location_specification.tracks !== null ? [this.location_specification.tracks] : []
            }
        }))
    }

    /**
    * Create default instance with position data.
    * @param {{ x: number, y: number }} position - coordinates.
    * @param {number | null} roadNum - Road number.
    * @param {number | null} roadSec - road section.
    * @param {number | null} distance - distance (road geometry).
    * @returns {VelhoRoadsideFurnitureModel}
    */
    static instanceWithPosition(position, roadNum, roadSec, distance) {
        const instance = new VelhoRoadsideFurnitureModel()
        instance.position = position
        instance.road_num = roadNum
        instance.road_sec = roadSec
        instance.distance = distance
        return instance
    }
}