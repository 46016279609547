<template>
    <div v-if="results && results.length"
         id="materials"
         class="nopads">
        <b-table
            hover
            show-empty
            small
            :items="results"
            :fields="materialFields"
            :sort-by.sync="materialSortBy"
            :sort-desc.sync="sortDesc"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {printHelper} from '../mixins/PrintMixin'
import {restApi} from '../mixins/RestApiMixin'
import {vehicleHelper} from '../mixins/VehicleMixin'

export default {
    name: 'ManualMaterialReportDetails',
    mixins: [timeUtils, printHelper, restApi, vehicleHelper],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            attachment: null,
            sortDesc: false,
            materialSortBy: 'road_num',
            materialFields: [
                {key: 'road_num', label: this.$t('work_report.road_no'), sortable: false},
                {key: 'start_sec_num', label: this.$t('work_report.road_section_from'), sortable: false},
                {key: 'end_sec_num', label: this.$t('work_report.road_section_to'), sortable: false},
                {key: 'location_info', label: this.$t('work_report.location_info'), sortable: false},
                {key: 'amount', label: this.$t('work_report.amount'), sortable: false},
                {key: 'unit', label: this.$t('work_report.unit'), sortable: false}
            ]
        }
    },
    methods: {}
}
</script>
