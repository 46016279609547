<template>
    <div>
        <transition name="fade">
            <road-link-info
                v-if="roadLinkInfo"
                :road-link-info="roadLinkInfo"
                @hideRoadLink="hideRoadLink"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import RoadLinkInfo from './RoadLinkInfo'
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from '../mixins/MapMixin'

export default {
    name: 'RoadLinks',
    components: {RoadLinkInfo},
    mixins: [restApi, mapHelper],
    props: {
        map: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            lat: 62.2,
            lng: 25.2,
            visibleLinkId: null,
            roadLinkInfo: null
        }
    },
    beforeDestroy: function () {
        this.hideRoadLink()
    },

    methods: {
        mapClicked: function (coord) {
            if (this.visibleLinkId) {
                this.hideRoadLink()
            } else {
                this.fetchRoadLinkInfo(coord.lat, coord.lng)
            }
        },

        onMapInfoClosed: function () {
            if (this.visibleLinkId) {
                this.hideRoadLink()
            }
        },

        fetchRoadLinkInfo: function (lat, lon) {
            this.lat = lat
            this.lon = lon
            this.loading = true
            var url = this.roadLinkUrl + '?lat=' + lat + '&lon=' + lon
            this.restFetch(url, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response && response.data && response.data.roadlink) {
                this.roadLinkInfo = response.data
                this.visibleLinkId = this.roadLinkInfo.roadlink.link_id
                this.showRoadLinkOnMap(this.roadLinkInfo.roadlink.link_id, this.roadLinkInfo.roadlink.geometry)
                this.map.zoomToPosition(this.lat, this.lon)
            }
        },

        hideRoadLink: function () {
            if (this.visibleLinkId) {
                this.map.removePolyline(this.visibleLinkId, this.ROAD)
                this.roadLinkInfo = null
                this.visibleLinkId = null
            }
        },

        showRoadLinkOnMap: function (id, geometry) {
            var points = geometry.map(point => {
                return {x: point.lon, y: point.lat}
            })
            var color = '#FF00DC'
            this.visibleLinkId = id
            this.map.drawPolyLine(id, this.ROAD, points, color)
        },

    }
}
</script>
