<template>
    <transition name="modal">
        <div class="modal-mask" @click.self.stop="$emit('cancel')">
            <div
                class="modal-container col-sm-6"
                :class="{'no-top-pad': noTopPadding, 'min-width': minWidth}"
            >
                <slot/>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'BaseModal',
    props: {
        noTopPadding: {
            type: Boolean,
            default: false
        },
        minWidth: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.no-top-pad {
    padding-top: 0 !important;
}

.min-width {
    min-width: 70% !important;
}
</style>
