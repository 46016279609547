<template>
    <div
        id="editor"
        class="col-sm-12 nopads"
    >
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="inspectableUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('inspectable_editor.edit_inspectable') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('inspectable_editor.add_inspectable') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <span class="span-title">
                      {{ $t('inspectable_editor.name') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="inspectableUpdate.name"
                        type="text"
                        size="mb"
                    />
                    <span class="span-title">{{ $t('inspection_editor.inactive') }}</span>
                    <b-form-group
                        class="title"
                        label-for="in_active"
                    >
                        <b-form-checkbox
                            id="in_active"
                            v-model="inspectableUpdate.in_active"
                            size="mb"
                        />
                    </b-form-group>
                </b-form-group>

                <span class="span-title">
                  {{ $t('inspectable_editor.type') }}
                </span>
                <b-form-group
                    class="title"
                >
                    <b-form-select
                        v-model="inspectableUpdate.type"
                    >
                        <option
                            v-for="(type, index) in inspectableTypes"
                            :key="type"
                            :value="index"
                        >
                            {{ $t(`inspectable.types.${type}`) }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <template v-if="isInspectableType('number')">
                    <b-form-checkbox v-model="useMinNumberValue">
                        {{ $t('inspectable.number.use_min') }}
                    </b-form-checkbox>
                    <b-form-group
                        v-if="inspectableUpdate.range && inspectableUpdate.range.min != null"
                        class="title"
                    >
                        <b-form-input
                            type="number"
                            :value="inspectableUpdate.range.min"
                            @change="val => inspectableUpdate.range.min = Number(val)"
                        />
                    </b-form-group>
                    <b-form-checkbox v-model="useMaxNumberValue">
                        {{ $t('inspectable.number.use_max') }}
                    </b-form-checkbox>
                    <b-form-group
                        v-if="inspectableUpdate.range && inspectableUpdate.range.max != null"
                        class="title"
                    >
                        <b-form-input
                            type="number"
                            :value="inspectableUpdate.range.max"
                            @change="val => inspectableUpdate.range.max = Number(val)"
                        />
                    </b-form-group>
                </template>

                <template v-if="isInspectableType('select_multi') || isInspectableType('select_single')">
                    <span class="span-title">
                      {{ $t('inspectable.select.items') }}
                    </span>
                    <div
                        class="d-flex title"
                        v-for="(item, index) in inspectableUpdate.selectable_items"
                        :key="index"
                    >
                        <b-form-input v-model="item.name" />
                        <b-button
                            variant="danger"
                            @click="removeSelectableItem(index)"
                        >
                            <i class="fa fa-times"/>
                        </b-button>
                    </div>
                    <div class="title">
                        <b-button
                            variant="success"
                            @click="addSelectableItem"
                        >
                            <i class="fa fa-plus" />
                        </b-button>
                    </div>
                </template>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitInspectable"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {inspectionHelper} from '../../mixins/InspectionMixin'
import {restApi} from '../../mixins/RestApiMixin'
import {types} from "../../../modules/Inspectable";

export default {
    name: 'InspectableEditor',
    mixins: [inspectionHelper, restApi],
    props: {
        inspectable: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            company: null,
            companies: [],
            useMinNumberValue: this.inspectable.range != null && this.inspectable.range.min !== null,
            useMaxNumberValue: this.inspectable.range != null && this.inspectable.range.max !== null,
            prevMinNumberValue: 0,
            prevMaxNumberValue: 0,
            prevSelectableItems: [],
            inspectableTypes: types,
            inspectableUpdate: this.jsonToInspectable(this.inspectable)
        }
    },
    watch: {
        useMinNumberValue(useMinNumberValue) {
            if (useMinNumberValue) {
                this.$set(this.inspectableUpdate, 'range', {...this.inspectableUpdate.range, min: this.prevMinNumberValue})
            }
            else {
                this.prevMinNumberValue = this.inspectableUpdate.range.min;
                if (this.useMaxNumberValue) {
                    this.inspectableUpdate.range.min = undefined;
                }
                else {
                    this.$set(this.inspectableUpdate, 'range', undefined);
                }
            }
        },
        useMaxNumberValue(useMaxNumberValue) {
            if (useMaxNumberValue) {
                this.$set(this.inspectableUpdate, 'range', {...this.inspectableUpdate.range, max: this.prevMaxNumberValue})
            }
            else {
                this.prevMaxNumberValue = this.inspectableUpdate.range.max;
                if (this.useMinNumberValue) {
                    this.inspectableUpdate.range.max = undefined;
                }
                else {
                    this.$set(this.inspectableUpdate, 'range', undefined);
                }
            }
        },
        'inspectableUpdate.type': function (type) {
            if (['select_single', 'select_multi'].includes(types[type])) {
                if (this.inspectableUpdate.selectable_items != null) this.prevSelectableItems = this.inspectableUpdate.selectable_items;
                this.$set(this.inspectableUpdate, 'selectable_items', [...this.prevSelectableItems]);
            }
            else if (this.inspectableUpdate.selectable_items != null) {
                this.prevSelectableItems = this.inspectableUpdate.selectable_items;
                this.$set(this.inspectableUpdate, 'selectable_items', undefined);
            }
        },
    },
    methods: {
        isInspectableType(type) {
            return types[this.inspectableUpdate.type] === type
        },

        addSelectableItem() {
            this.inspectableUpdate.selectable_items.push({name: ""})
        },

        removeSelectableItem(index) {
            this.inspectableUpdate.selectable_items.splice(index, 1)
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        setDefaultValues: function (inspectable) {
            if (inspectable.name === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return undefined
            }
            return inspectable
        },

        submitInspectable: function () {
            var jsonInspectable = this.setDefaultValues(this.inspectableUpdate)
            if (jsonInspectable !== undefined) {
                this.loading = true
                if (this.inspectable.id < 1) {
                    this.restAdd(this.inspectableUrl, jsonInspectable, this.success, this.fail)
                } else {
                    this.restUpdate(this.inspectableUrl, jsonInspectable, this.success, this.fail)
                }
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>
