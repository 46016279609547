import {equipmentOidTypes} from '@/components/mixins/ConversionMixin'

export default class VelhoRoadSignModel {
    // Private properties
    /** @type {string} */
    #external_system_id = equipmentOidTypes.EXTERNAL_TRAFFIC_SIGNS

    /**
     * Creates an instance of Velho road sign model.
     * Editor will transform the number to a string, because of html input field. We should convert those values back to numbers before sending the data to the server
     * @param {{icon: string, name: string, value: string} | null} sign
     * @param {string} value
     * @param {number} direction
     * @param {number} additionalType
     * @param {number} signColor
     * @param {number} material
     * @param {number} signDirection
     * @param {number} installation
     * @param {number} signCondition
     * @param {number} orderNumber
     * @param {number} width
     * @param {number} height
     * @param {string} info
     * @param {{ x: number, y: number }} position
     * @param {number} roadNum
     * @param {number} roadSec
     * @param {number} distance
     * @param {{lane: number, side: number, tracks: number[], central_area: number}} locationSpecification
     * @param {Array} equipmentDamages
     */
    constructor(
        sign = null, value = "", type = "", side = null, lane = null, direction = null, additionalType = null, signColor = null, material = null, signDirection = null,
        installation = null, signCondition = null, orderNumber = null, width = null, height = null, info = "", position = { x: null, y: null },
        roadNum = null, roadSec = null, distance = null, locationSpecification = { lanes: [], side: null, tracks: [], central_area: null }, locationDeviation = null,
        equipmentDamages = []
    ) {
        /** @type {{icon: string, name: string, value: string} | null} */
        this.sign = sign

        /** @type {string} */
        this.value = value

        /** @type {string} */
        this.type = type

        /** @type {number | null} */
        this.direction = direction

        /** @type {string} */
        this.additional_type = additionalType

        /** @type {number | null} */
        this.sign_color = signColor

        /** @type {number | null} */
        this.material = material

        /** @type {number | null} */
        this.sign_direction = signDirection
        
        /** @type {number | null} */
        this.installation = installation

        /** @type {number | null} */
        this.sign_condition = signCondition

        /** @type {number | null} */
        this.order_number = orderNumber

        /** @type {number | null} */
        this.width = width

        /** @type {number | null} */
        this.height = height

        /** @type {string} */
        this.info = info

        /** @type {{ x: number, y: number }} */
        this.position = position

        /** @type {number | null} */
        this.road_num = roadNum

        /** @type {number | null} */
        this.road_sec = roadSec

        /** @type {number | null} */
        this.distance = distance

        /** @type {Array} */
        this.equipment_damages = equipmentDamages

        /** @type {number | null} */
        this.location_deviation = locationDeviation

        const _locationSpecification = {...locationSpecification, side}
        /** @type {{ lane: number[], side: number | null, tracks: number[], central_area: number | null }} */
        this.location_specification = _locationSpecification
        
        // Road sign lane and side are defined in the root level of the object instead of "location specification"
        /** @type {number | null} */
        this.side = side
        
        /** @type {number | null} */
        this.lane = lane
    }

    /**
    * Gets the unique identifier of the Velho road sign type.
    * @returns {string} The Velho road sign type ID.
    */
    get external_system_id() {
        return this.#external_system_id
    }

    /**
    * Get the road sign json object.
    * @returns {object}
    */
    toJSON() {
        return {
            external_system_id: this.#external_system_id,
            sign: this.sign ? this.sign.value : null,
            value: this.value,
            type: this.type,
            direction: this.direction,
            additional_type: this.additional_type,
            sign_color: this.sign_color,
            material: this.material,
            sign_direction: this.sign_direction,
            installation: this.installation,
            sign_condition: this.sign_condition,
            order_number: this.order_number,
            width: this.width,
            height: this.height,
            info: this.info,
            position: this.position,
            road_num: this.road_num,
            sec_num: this.road_sec,
            distance: this.distance,
            location_specification: this.location_specification,
            location_deviation: this.location_deviation,
            equipment_damages: this.equipment_damages
        }
    }

    /**
    * Create default Velho road sign instance with position data.
    * @param {{ x: number, y: number }} position - coordinates.
    * @param {number | null} roadNum - Road number.
    * @param {number | null} roadSec - road section.
    * @param {number | null} distance - distance (road geometry).
    * @returns {VelhoRoadsideFurnitureModel}
    */
    static instanceWithPosition(position, roadNum, roadSec, distance) {
        const instance = new VelhoRoadSignModel()
        instance.position = position
        instance.road_num = roadNum
        instance.road_sec = roadSec
        instance.distance = distance
        return instance
    }
}