<template>
    <div class="col-sm-12">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="tablet"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="results && results.length"
                class="col-sm-12 data-container"
            >
                <div
                    v-for="result of results"
                    :key="result.id"
                    class="data-list-item"
                    @click.stop="$emit('edit', result)"
                >
                    <div class="col-sm-12 item-header">
                        <div class="item-img-cont">
                            <font-awesome-icon icon="tablet"/>
                        </div>
                        <div
                            class="item-text-cont"
                        >
              <span class="item-title">
                {{ result.vehicle.license_plate }} - {{ result.vehicle.make }} {{ result.vehicle.vehicle_model }}
              </span>
                            <span v-if="isAdmin" class="item-text">
                {{ result.vehicle.company.name }} - {{ result.imei }}
              </span>
                            <span v-else class="item-text">
                {{ result.imei }}
              </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'TrackingDeviceList',
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            },
        },
        isAdmin: Boolean
    }
}
</script>
