
export const portalHelper = {
    methods: {
        getPortalTypes: function () {
            return [
                {name: this.$t('portal.select_type'), value: null},
                {name: this.$t('portal.type_ring'), value: portalTypes.RING},
                {name: this.$t('portal.type_outer'), value: portalTypes.OUTER}
            ]
        },
        getPortalAdditionalTypes: function () {
            return [
                {name: this.$t('portal.select_add_type'), value: null},
                {name: "R1", value: 2},
                {name: "R2", value: 3},
                {name: "R3", value: 4},
                {name: "R4", value: 5},
                {name: "R5", value: 6},
                {name: "R6", value: 7},
                {name: "R7", value: 8},
                {name: "R8", value: 9},
                {name: "I", value: 10},
                {name: "II", value: 11},
                {name: "III", value: 12},
                {name: "IV", value: 13},
                {name: "V", value: 14},
                {name: "VI", value: 15},
                {name: "VII", value: 16},
                {name: "VIII", value: 17},
                {name: "IX", value: 18},
                {name: "Muu", value: 19},
                {name: "RU1", value: 101},
                {name: "RU2", value: 102},
                {name: "RU3", value: 103},
                {name: "RU4", value: 104},
                {name: "RU5", value: 105},
                {name: "RU6", value: 106},
                {name: "UI", value: 107},
                {name: "UII", value: 108},
                {name: "UIII", value: 109},
                {name: "UIV", value: 110},
                {name: "UV", value: 111},
                {name: "UKI", value: 112},
                {name: "UKII", value: 113},
                {name: "UKIII", value: 114},
                {name: "UKIV", value: 115},
                {name: "UKV", value: 116},
            ]
        },

        getPortalFoundations: function () {
            return [
                {name: this.$t('portal.select_foundation'), value: null},
                {name: this.$t('portal.foundation_ground_pilar'), value: portalFoundationTypes.GROUND_PILAR},
                {name: this.$t('portal.foundation_ground_plate'), value: portalFoundationTypes.GROUND_PLATE},
                {name: this.$t('portal.foundation_rock'), value: portalFoundationTypes.ROCK},
                {name: this.$t('portal.foundation_pole'), value: portalFoundationTypes.POLE},
                {name: this.$t('portal.foundation_other'), value: portalFoundationTypes.OTHER}
            ]
        },

        getPortalStructures: function () {
            return [
                {name: this.$t('portal.select_structure'), value: null},
                {name: this.$t('portal.structure_cross'), value: portalStructure.CROSS},
                {name: this.$t('portal.structure_pipe'), value: portalStructure.PIPE},
            ]
        },
    }
}

export const portalTypes = {
    RING: 1,
    OUTER: 2
}

export const portalFoundationTypes = {
    GROUND_PILAR: 1,
    GROUND_PLATE: 2,
    ROCK: 3,
    POLE: 4,
    OTHER: 5
}

export const portalStructure = {
    CROSS: 1,
    PIPE: 2
}

