<template>
    <div
        id="editor"
        class="col-sm-12 nopads"
    >
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2 class="form-title editor-title">
                    {{ listUpdate.label }}
                </h2>
            </div>

            <div class="col-sm-12 form-data">
                <div v-if="users.length > 0">
                    <!--Users-->
                    <span class="span-title">
                        {{ $t('notification_list.select_users') }}
                     </span>
                    <multiselect
                        v-model="listUpdate.users"
                        track-by="id"
                        :multiple="true"
                        :hide-selected="true"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :placeholder="$t('notification_list.select_users')"
                        :selected-label="$t('common.selected')"
                        :custom-label="getName"
                        :searchable="true"
                        :internal-search="false"
                        :options="filteredUser"
                        :loading="loading"
                        @search-change="searchList"
                        :close-on-select="true"
                    >
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
                            {{ $t('common.cancel') }}
                         </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitlist"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
                            {{ $t('common.save') }}
                        </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'notification-list-editor',
    mixins: [restApi],
    props: {
        list: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            users: [],
            filteredUser: [],
            listUpdate: JSON.parse(JSON.stringify(this.list))
        }
    },
    mounted: function () {
        this.fetchUsers()
    },
    methods: {
        searchList(query) {
            this.filteredUser = this.users.filter(function (user) {
                return user.first_name.toLowerCase().startsWith(query.toLowerCase()) ||
                    user.last_name.toLowerCase().startsWith(query.toLowerCase())
            });
        },
        getName(customer) {
            return customer.first_name + ' ' + customer.last_name
        },
        fetchUsers: function () {
            this.loading = true
            this.restFetch(this.userUrl, this.handleUsers)
        },
        handleUsers: function (response) {
            this.users = response.data
            this.filteredUser.push(...this.users)
            this.loading = false
        },
        getParams: function () {
            let users = []
            this.listUpdate.users.forEach(function (user) {
                users.push(user.id)
            })
            return {
                users: users,
                topic: this.listUpdate.topic
            }
        },
        submitlist: function () {
            let list = this.getParams()
            if (list) {
                this.loading = true
                this.restAdd(this.notificationSubsUrl, list, this.submitListSuccess, this.submitListFail)
            }
        },
        submitListSuccess: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },
        submitListFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>
