<template>
    <div v-if="roads && roads.length > 0" class="col-sm-12 nopads" >
        <h4 class="form-header">{{ $t('contracts.external.road_list')}}</h4>
        <span class="tip-light">{{ $t('contracts.external.road_list_hint')}}</span>
        <div class="p-2" style="border: 1px solid #BCBCBC; max-height: 25vh; overflow: auto">
            <table v-if="roads" class="table table-sm table-fixed">
            <tbody>
            <tr>
                <th>{{ $t('map.road_number') }}</th>
                <th>{{ $t('map.road_section') }}</th>
                <th>{{ $t('map.road_name') }}</th>
            </tr>
            <tr v-for="(road, index) in roads" v-bind:key="index">
                <td class="item-detail-text">
                    {{ road.road_number }}
                </td>
                <td class="item-detail-text">
                    {{ road.road_section }}
                </td>
                <td class="item-detail-text">
                    {{ road.road_name }}
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'ContractRoadListPreview',
    mixins: [restApi, timeUtils],
    props: {
        roads: {
            type: Array,
            default: null
        }
    }
}
</script>
