export const additionalCostTypes = {
    WORK: 1,
    MATERIAL: 2,
    OTHER: 10
}

export const additionalCostStatus = {
    NEW: 1,
    APPROVED: 2,
    REJECTED: 3
}

export const additionalCostHelper = {
    methods: {
        getAdditionalCostTypeName: function (type) {
            switch (type) {
                case additionalCostTypes.WORK:
                    return this.$t('additional_cost.type_work')
                case additionalCostTypes.MATERIAL:
                    return this.$t('additional_cost.type_material')
            }
            return this.$t('common.other')
        },
        getAdditionalCostType: function () {
            return [
                {name: this.$t('additional_cost.type_work'), value: additionalCostTypes.WORK},
                {name: this.$t('additional_cost.type_material'), value: additionalCostTypes.MATERIAL},
                {name: this.$t('common.other'), value: additionalCostTypes.OTHER}
            ]
        },
        getAdditionalCostStatusName: function (status) {
            switch (status) {
                case additionalCostStatus.NEW:
                    return this.$t('additional_cost.status_new')
                case additionalCostStatus.APPROVED:
                    return this.$t('additional_cost.status_approved')
                case additionalCostStatus.REJECTED:
                    return this.$t('additional_cost.status_rejected')
            }
            return this.$t('common.other')
        },
        getAdditionalCostStatus: function () {
            return [
                {name: this.$t('additional_cost.status_new'), value: additionalCostStatus.NEW},
                {name: this.$t('additional_cost.status_approved'), value: additionalCostStatus.APPROVED},
                {name: this.$t('additional_cost.status_rejected'), value: additionalCostStatus.REJECTED}
            ]
        },
        newAdditionalCost: function (like) {
            return {
                id: -1,
                user: {
                    id: null
                },
                type: null,
                amount: null,
                unit: "",
                unit_price: null,
                work_order: {
                    id: null
                },
                contract: {
                    id: null
                },
                company: {
                    id: null
                },
                status: 1,
                info: null,
                ...like
            }
        },

    }

}
