<template>
    <div class="map-container" style="height: 25vh">
        <map-container
            ref="map"
            :find-user="false"
            @onDragEnd="onDragMarker"
            @onMapChanged="onMapChanged"
            @onMapClicked="onMapClicked"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {vehicleHelper} from "../mixins/VehicleMixin";
import MapContainer from "./MapContainer";

export default {
    name: 'MarkerEditorMap',
    components: {MapContainer},
    mixins: [vehicleHelper, timeUtils, mapHelper],
    props: {
        lat: {
            type: Number,
            default: 0
        },
        lon: {
            type: Number,
            default: 0
        },
        type: {
            type: Number,
            default: 0
        },
        vehicleType: {
            type: Number,
            default: 1
        },
        draggable: Boolean,
        observationMode: {
            type: Boolean,
            default: false
        },
        showButtons: {
            type: Boolean,
            default: false
        },
        points: {
            type: Array,
            default: function () {
                return []
            }
        },
        fresh: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: 0
        }
    },
    data: function () {
        return {
            icon: null,
            map: null
        }
    },
    watch: {
        lat() {
            this.draw()
        },
        lon() {
            this.draw()
        },
        points() {
            this.draw()
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (!this.map) {
                this.map = this.$refs.map ? this.$refs.map.getMap() : undefined
            }
        })
    },
    methods: {
        onMapChanged(map) {
            if (!map) {
               return
            }
            this.map = this.$refs.map ? this.$refs.map.getMap() : undefined
            this.initView()
            this.$emit('mounted', this.$refs.map)
        },
        initView: function () {
            if (this.map) {
                if (this.points && this.points.length > 0) {
                    this.drawLineString()
                    if (this.points.length > 0) {
                        let firstPoint = this.points[0]
                        this.$refs.map.getMap().zoomToPosition(firstPoint.y, firstPoint.x, 16)
                    }
                } else {
                    this.drawPoint()
                    this.$refs.map.getMap().zoomToPosition(this.lat, this.lon, 16)
                }
            }
        },

        draw() {
            if (this.map) {
                if (this.points && this.points.length > 0) {
                    if (this.observationMode) {
                        this.map.removeMapMarker(this.id, this.type)
                        if (this.points.length > 0) {
                            let firstPoint = this.points[0]
                            this.map.zoomToPosition(firstPoint.y, firstPoint.x, 16)
                        }
                    }
                    this.drawLineString()
                } else {
                    if (this.observationMode) {
                        this.map.removePolyline(this.id, this.type, 16)
                        this.map.zoomToPosition(this.lat, this.lon, 16)
                    }
                    this.drawPoint()
                }
            }
        },

        drawPoint: function () {
            if (this.type > 0){
                this.map.showMapMarker(this.id, this.type, this.lat, this.lon, this.getMarker(), this.draggable)
            }
        },

        drawLineString: function () {
            this.map.drawPolyLine(this.id, this.type, this.points, '#bb330e')
        },

        getMarker: function () {
            if (this.type) {
                let markerStore = this.map.getMarkerStore()
                switch (this.type) {
                    case this.OBSERVATION:
                        return markerStore.getObservationMarkerIcon()
                    case this.EQUIPMENT:
                        return markerStore.getEquipmentMarkerIcon()
                    case this.MATERIAL_STORAGE:
                        return markerStore.getStorageMarkerGenericIcon()
                    case this.BRIDGE:
                        return markerStore.getBridgeIcon()
                    case this.CULVERT:
                        return markerStore.getCulvertIcon()
                    case this.VEHICLE:
                        return this.getVehicleMarker(this.vehicleType, this.fresh, markerStore)
                    default:
                        return markerStore.getDefaultMarkerIcon()
                }
            }
            return undefined
        },

        onDragMarker: function (location) {
            this.$emit('onDragMarker', location.position)
        },
        onMapClicked: function (location) {
            this.$emit('onMapClicked', location)
        },
        addLayer: function (layer){
            this.map.addLayer(layer);
        },

    }
}
</script>
