<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="vehicle-list"
        class="col-sm-12 pl-3 pr-3 pb-4"
    >
        <div class="nopads"
             v-for="(contract, key) in results"
             :key="key">
            <InvoicingContractTable
                :contract="contract"
                :invoice-state-options="invoiceStateOptions"
                :user="user"
                @showOrder="emitShowOrder"
                @showTripOrder="emitTripOrder"
                @showContractDetails="emitContractDetails"
                @showTripDetails="emitTripDetails"
                @showTripDeliveries="emitTripDeliveries"
                @updateSummaries="emitSummaries"
                @acceptInvoicing="acceptInvoicing"
            />
            <hr class="report-divider ml-2 mr-2">
        </div>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import InvoicingContractTable from "@/components/invoice/InvoicingContractTable.vue";
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'InvoicingList',
    mixins: [timeUtils, workManagementHelper],
    components: {InvoicingContractTable},
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        invoiceStateOptions: {
            type: Array,
            default() {
                return []
            }
        },
        user: {
            type: Object,
            default: null
        },
    },
    methods: {
        acceptInvoicing: function (item) {
            this.$emit('acceptInvoicing', item)
        },
        emitShowOrder(order, contract) {
            this.$emit('showOrder', order, contract)
        },
        emitTripOrder(row) {
            this.$emit('showTripOrder', row)
        },
        emitContractDetails(row) {
            this.$emit('showContractDetails', row)
        },
        emitSummaries() {
            this.$emit('updateSummaries')
        },
        emitTripDetails(row) {
            this.$emit('showTripDetails', row)
        },
        emitTripDeliveries(row) {
            this.$emit('showTripDeliveries', row)
        },
    }
}
</script>
