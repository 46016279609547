<template>
    <div class="col-sm-12 nopads">
        <keep-alive>
            <vehicle-inspection-search
                @search="fetchInspections"/>
        </keep-alive>
        <transition name="fade">
            <vehicle-inspection-list
                :results="results"
                @details="showReportDetails"
            />
        </transition>
        <transition name="fade">
            <b-modal
                v-model="showReport"
                size="xl"
                centered
                :title="vehicle.license_plate"
                :hide-footer="true"
            >
                <inspection-report-details
                    v-if="inspection"
                    id="details"
                    ref="details"
                    :report-details="inspection"
                    @close="inspection = null"
                />
            </b-modal>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import VehicleInspectionSearch from "@/components/vehicle/tabbed/VehicleInspectionSearch";
import VehicleInspectionList from "@/components/vehicle/tabbed/VehicleInspectionList";
import InspectionReportDetails from "@/components/inspections/InspectionReportDetails";
import {inspectionHelper} from "@/components/mixins/InspectionMixin";

export default {
    name: 'VehicleInspections',
    components: {
        InspectionReportDetails,
        VehicleInspectionList,
        VehicleInspectionSearch
    },
    mixins: [restApi, inspectionHelper],
    props: {
        vehicle: {
            type: Object
        }
    },
    data: function () {
        return {
            results: [],
            lastSearchParams: [],
            inspection: null,
            loading: false,
            showReport: false
        }
    },
    methods: {
        fetchInspections: function (params) {
            this.loading = true
            this.lastSearchParams = params
            this.lastSearchParams["vehicle"] = this.vehicle.id
            this.lastSearchParams["type"] = 1
            this.restFetchParams(this.inspectionReportGenericUrl, this.lastSearchParams, this.handleResponse)
        },
        handleResponse: function (response) {
            if (this.loading) {
                this.results = response.data
                this.loading = false
            }
        },
        showReportDetails(item) {
            this.inspection = item
            this.showReport = true
        },
        hideDetails() {
            this.inspection = null
            this.showReport = false
        }
    }
}
</script>
editInspectable
