<template>
    <div class="col-sm-12 p-1" style="max-width: 80em">
        <h3 class="text-center">{{  name  }}</h3>
        {{ toLocalTime(data.data_updated_time) }}
        <div v-if="data.station && data.station.position" class="coordinate-map">
            <marker-editor-map
                :lat="data.station.position.y"
                :lon="data.station.position.x"
                :type="WEATHER_STATION"
                :draggable="false"
            />
        </div>

        <b-table
            small
            :items="sensorData"
            :fields="sensorFields"
        >
            <template v-slot:cell(name)="row">
                {{ row.value }}
            </template>
            <template v-slot:cell(value)="row">
                {{ row.item.value }} {{ row.item.unit }}
            </template>
        </b-table>
    </div>
</template>

<script>
import {mapHelper} from "../mixins/MapMixin";
import {utility} from "@/components/mixins/Utils";
import {timeUtils} from "@/components/mixins/TimeUtils";
import MarkerEditorMap from "@/components/map/MarkerEditorMap";

export default {
    name: "WeatherStationDataDetails",
    components: {MarkerEditorMap},
    mixins: [mapHelper, utility, timeUtils],
    props: {
        data: {
            type: Object,
            default: null
        },
        name: {
            type: String,
            default: null
        }
    },
    computed: {
        sensorFields() {
            return [
                {key: 'name', label: this.$t('weather_station.sensor_data.name'), sortable: true},
                {key: 'value', label: this.$t('weather_station.sensor_data.value'), sortable: true},
            ]
        },
        sensorData() {
            let lang = this.getLanguage()
            const value = this.data.sensorValues === undefined ? this.data.sensor_values : this.data.sensorValues;
            return value.map(d => (
                {
                    name: d.name,
                    measuredTime: new Date(d.measured_time),
                    value: (d.sensor_value_description_fi && lang === "fi") ?
                        d.sensor_value_description_fi :
                        d.sensor_value_description_en ? d.sensor_value_description_en : d.value,
                    unit: d.sensor_value_description_en ? '' : d.unit
                }
            ))
        },
    },
}
</script>
