<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
            <span class="span-title">
              {{ $t('orders.status') }}
            </span>
                        <b-form-select
                            id="status"
                            v-model="status"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option :value="0">
                                {{ $t('inspection.options.open') }}
                            </option>
                            <option :value="2">
                                {{ $t('inspection.options.okay') }}
                            </option>
                            <option :value="3">
                                {{ $t('inspection.options.defects') }}
                            </option>
                            <option :value="4">
                                {{ $t('inspection.options.ban') }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="vehicle"
                    >
            <span class="span-title">
              {{ $t('trip_list.vehicle') }}
            </span>
                        <b-form-select
                            id="vehicle"
                            v-model="vehicle"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option
                                v-for="v in vehicles"
                                :key="v.id"
                                :value="v.id"
                            >
                                {{ v.make }} {{ v.vehicle_model }} {{ v.license_plate }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="reporter"
                    >
            <span class="span-title">
              {{ $t('defects.reporter') }}
            </span>
                        <b-form-select
                            id="reporter"
                            v-model="reporter"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option
                                v-for="r in reporters"
                                :key="r.id"
                                :value="r.id"
                            >
                                {{ r.first_name }} {{ r.last_name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="start"
                    >
            <span class="span-title">
              {{ $t('orders.create_time') }} - {{ $t('trip_list.road_sec_from') }}
            </span>
                        <b-form-input
                            id="start"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="end"
                    >
            <span class="span-title">
              {{ $t('orders.create_time') }} - {{ $t('trip_list.road_sec_to') }}
            </span>
                        <b-form-input
                            id="end"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'VehicleDefectSearch',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            status: 0,
            vehicle: -1,
            vehicles: [],
            fromDate: null,
            toDate: null,
            reporter: -1,
            reporters: []
        }
    },
    mounted: function () {
        this.fetchVehicles()
        this.fetchReporters()
    },
    methods: {
        fetchVehicles: function () {
            this.loading = true
            this.restFetch(this.vehicleDefectVehiclesUrl, this.handleVehicles)
        },

        handleVehicles: function (response) {
            this.loading = false
            this.vehicles = response.data
        },

        fetchReporters: function () {
            this.loading = true
            this.restFetch(this.vehicleDefectReporterUrl, this.handleReporters)
        },

        handleReporters: function (response) {
            this.loading = false
            this.reporters = response.data
        },

        onSubmit: function () {
            var params = {}
            if (this.status >= 0) {
                params.status = this.status
            }
            if (this.vehicle > 0) {
                params.vehicle = this.vehicle
            }
            if (this.reporter > 0) {
                params.reporter = this.reporter
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.dateFrom = this.localTimeToUtcTime(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.dateTo = this.getEndOfTheDay(this.toDate).getTime()

            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.status = 0
            this.vehicle = -1
            this.reporter = -1
            this.fromDate = null
            this.toDate = null
        }
    }
}
</script>

<style scoped>

</style>
