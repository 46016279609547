<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="content-container" ref="container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showApplicationsList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('menu.subcontractor_approvals') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="update"
            >
                <span class="pointer">
                  <i class="fa fa-arrow-left"/>
                </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
            </div>
        </b-navbar>
        <keep-alive>
            <application-search
                v-if="!editMode"
                @search="fetchApplications"
            />
        </keep-alive>
        <div class="col-sm-12 pl-3 pr-3 pt-1" v-if="!editMode">
            <div
                class="data-list-item add"
                @click.stop="addApplication"
            >
                <div class="item-img-cont add-title">
                    <i class="fa fa-plus"/>
                </div>
                <div class="item-text-cont">
                    <span class="item-title-add">{{ $t('common.add_new') }}</span>
                    <font-awesome-icon
                        icon="file-contract"
                        class="card-body-icon"
                    />
                </div>
            </div>
        </div>
        <keep-alive>
            <application-list
                class="pl-3 pr-3"
                v-if="!editMode"
                :applications="applications"
                @edit="editApplication"/>
        </keep-alive>
        <transition name="fade">
            <application-editor
                v-if="editMode"
                :application="application"
                @update="update"
                @close="showApplicationsList"/>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import ApplicationSearch from "@/components/subcontractors/ApplicationSearch";
import ApplicationList from "@/components/subcontractors/ApplicationList";
import ApplicationEditor from "@/components/subcontractors/ApplicationEditor";

export default {
    name: 'SubcontractorApplications',
    components: {
        ApplicationEditor,
        ApplicationList,
        ApplicationSearch
    },
    mixins: [timeUtils, restApi],
    data: function () {
        return {
            loading: 0,
            applications: [],
            editMode: false,
            application: null
        }
    },
    methods: {
        fetchApplications: function (params) {
            this.loading++
            this.applications = []
            if (!params) {
                params = {}
            }
            this.lastSearchParams = params
            this.restFetchParams(this.subcontractorApplicationUrl, this.lastSearchParams, this.fetchSuccess, this.fetchFail)
        },
        fetchSuccess: function (response) {
            this.applications = response.data
            this.loading--
        },
        fetchFail: function () {
            this.loading--
        },
        update: function () {
            this.showApplicationsList()
            this.fetchApplications(this.lastSearchParams)
        },
        editApplication: function (application) {
            this.application = application
            this.editMode = true
        },
        showApplicationsList: function () {
            this.editMode = false
            this.application = null
        },
        addApplication() {
            this.application = {
                id: null,
                subcontractor_name: null,
                subcontractor_business_id: null,
                customer: null,
                time_from: null,
                time_to: null
            }
            this.editMode = true
        }
    }
}
</script>
