<template>
<base-modal :min-width="true" @cancel="$emit('close')" style="z-index: 50;">
    <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
    <div class="col-12">
        <h3>{{ $t('velho_equipment.add_new') }}</h3>
    </div>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.select_equipment_type') }}</span>
                <b-form-group label-for="value">
                    <b-form-select size="sm" v-model="selectedEquipment" :disabled="this.loading">
                        <template slot="first">
                            <option :value="null">
                                {{ $t('velho_equipment.select_equipment_type') }}
                            </option>
                        </template>
                        <option
                            v-for="option in velhoEquipmentsSelectors"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.text }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <hr>
            <div class="col-sm-12">
                <transition name="fade">
                    <template>
                        <culvert-editor
                            v-if="selectedEquipment === this.EXTERNAL_CULVERTS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <drain-editor
                            v-if="selectedEquipment === this.EXTERNAL_DRAINS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <portal-editor
                            v-if="selectedEquipment === this.EXTERNAL_PORTALS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <gate-editor
                            v-if="selectedEquipment === this.EXTERNAL_GATES"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <roadside-advertisement-editor
                            v-if="selectedEquipment === this.EXTERNAL_ROADSIDE_ADVERTISEMENTS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <post-editor
                            v-if="selectedEquipment === this.EXTERNAL_POSTS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <stair-editor
                            v-if="selectedEquipment === this.EXTERNAL_STAIRS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <roadside-furniture-editor
                            v-if="selectedEquipment === this.EXTERNAL_ROADSIDE_FURNITURE"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <railing-editor
                            v-if="selectedEquipment === this.EXTERNAL_RAILINGS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <booms-barriers-bollards-editor
                            v-if="selectedEquipment === this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <fence-editor
                            v-if="selectedEquipment === this.EXTERNAL_FENCES"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <edge-support-editor
                            v-if="selectedEquipment === this.EXTERNAL_EDGE_SUPPORTS"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <edge-pile-editor
                            v-if="selectedEquipment === this.EXTERNAL_EDGE_PILES"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <lighting-editor
                            v-if="selectedEquipment === this.EXTERNAL_LIGHTING"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <pipes-wires-cables-editor
                            v-if="selectedEquipment === this.EXTERNAL_PIPES_WIRES_AND_CABLES"
                            ref="velhoEquipmentEditor"
                            style="pointer-events: all"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                        <road-sign-editor
                            v-if="selectedEquipment === this.EXTERNAL_TRAFFIC_SIGNS"
                            style="pointer-events: all"
                            ref="velhoEquipmentEditor"
                            :addEquipmentEditor="true"
                            :item="equipment"
                        />
                    </template>
                </transition>
            </div>
            <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                <b-button
                    variant="secondary"
                    class="result-button"
                    @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="result-button"
                    @click.stop="submit"
                    :disabled="this.loading || this.selectedEquipment === null || !this.isEquipmentFormValid"
                >
                    {{ $t('common.save') }}
                </b-button>
            </div>
        </b-form>
    </div>
    <div v-if="loading > 0" id="loader" class="spinner"></div>
</base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import {mapHelper} from "@/components/mixins/MapMixin";
import {restApi} from '@/components/mixins/RestApiMixin'
import CulvertEditor from "@/components/velho/CulvertEditor";
import DrainEditor from "@/components/velho/DrainEditor";
import PortalEditor from "@/components/velho/PortalEditor";
import GateEditor from "@/components/velho/GateEditor";
import RoadsideAdvertisementEditor from "@/components/velho/RoadsideAdvertisementEditor";
import PostEditor from "@/components/velho/PostEditor";
import StairEditor from "@/components/velho/StairEditor";
import RoadsideFurnitureEditor from "@/components/velho/RoadsideFurnitureEditor";
import RailingEditor from "@/components/velho/RailingEditor";
import BoomsBarriersBollardsEditor from "@/components/velho/BoomsBarriersBollardsEditor";
import FenceEditor from "@/components/velho/FenceEditor";
import EdgeSupportEditor from "@/components/velho/EdgeSupportEditor";
import EdgePileEditor from "@/components/velho/EdgePileEditor";
import LightingEditor from "@/components/velho/LightingEditor";
import PipesWiresCablesEditor from "@/components/velho/PipesWiresCablesEditor";
import RoadSignEditor from "@/components/velho/RoadSignEditor";
import {EventBus} from "@/event-bus";
import VelhoRoadSignModel from "@/models/integrations/velho/RoadSignModel";
import VelhoCulvertModel from "@/models/integrations/velho/CulvertModel";
import VelhoDrainModel from "@/models/integrations/velho/DrainModel";
import VelhoPortalModel from "@/models/integrations/velho/PortalModel";
import VelhoGateModel from "@/models/integrations/velho/GateModel";
import VelhoRoadsideAdvertisementModel from "@/models/integrations/velho/RoadsideAdvertisementModel";
import VelhoPostModel from "@/models/integrations/velho/PostModel";
import VelhoStairsModel from "@/models/integrations/velho/StairsModel";
import VelhoRoadsideFurnitureModel from "@/models/integrations/velho/RoadsideFurnitureModel";
import VelhoBoomsBarriersBollardsModel from "@/models/integrations/velho/BoomsBarriersBollardsModel";
import VelhoFenceModel from "@/models/integrations/velho/FenceModel";
import VelhoPipesWiresCablesModel from "@/models/integrations/velho/PipesWiresCablesModel";
import VelhoEdgePileMode from "@/models/integrations/velho/EdgePileMode";
import VelhoEdgeSupportsModel from "@/models/integrations/velho/EdgeSupportsModel";
import VelhoRailingModel from "@/models/integrations/velho/RailingModel";
import VelhoLightingModel from "@/models/integrations/velho/LightingModel";

export default {
    name: 'velho-equipment-wizard',
    components: {
        BaseModal,
        CulvertEditor,
        DrainEditor,
        PortalEditor,
        GateEditor,
        RoadsideAdvertisementEditor,
        PostEditor,
        StairEditor,
        RoadsideFurnitureEditor,
        RailingEditor,
        BoomsBarriersBollardsEditor,
        FenceEditor,
        EdgeSupportEditor,
        EdgePileEditor,
        LightingEditor,
        PipesWiresCablesEditor,
        RoadSignEditor
    },
    mixins: [mapHelper, restApi],
    props: {
        coordinate: {
            type: Object
        },
        selectedContract: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            roadData: {
                roadNum: null,
                roadSec: null,
                distance: null
            },
            velhoCoordinate: this.coordinate,
            selectedEquipment: null,
            equipment: null,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            variant: 'danger',
            alertMessage: '',
            isEquipmentFormValid: false
        }
    },
    watch: {
        selectedEquipment(equipmentId) {
            const coordinates = {
                y: this.velhoCoordinate.lat,
                x: this.velhoCoordinate.lng
            }
            // Initialize equipment model
            switch (equipmentId) {
                case this.EXTERNAL_CULVERTS:
                    this.equipment = VelhoCulvertModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_DRAINS:
                this.equipment = VelhoDrainModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_PORTALS:
                    this.equipment = VelhoPortalModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_GATES:
                    this.equipment = VelhoGateModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_ROADSIDE_ADVERTISEMENTS:
                    this.equipment = VelhoRoadsideAdvertisementModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_POSTS:
                    this.equipment = VelhoPostModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_STAIRS:
                    this.equipment = VelhoStairsModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_ROADSIDE_FURNITURE:
                    this.equipment = VelhoRoadsideFurnitureModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS:
                    this.equipment = VelhoBoomsBarriersBollardsModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_FENCES:
                    this.equipment = VelhoFenceModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_EDGE_SUPPORTS:
                    this.equipment = VelhoEdgeSupportsModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_LIGHTING:
                    this.equipment = VelhoLightingModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_PIPES_WIRES_AND_CABLES:
                    this.equipment = VelhoPipesWiresCablesModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                case this.EXTERNAL_TRAFFIC_SIGNS:
                    this.equipment = VelhoRoadSignModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                 case this.EXTERNAL_EDGE_PILES:
                    this.equipment = VelhoEdgePileMode.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                 case this.EXTERNAL_RAILINGS:
                    this.equipment = VelhoRailingModel.instanceWithPosition(coordinates, this.roadData.roadNum, this.roadData.roadSec, this.roadData.distance)
                    break;
                default:
                    break;
            }
        },
        'equipment': {
            handler() {
                this.$nextTick(() => {
                    if(!this.$refs.velhoEquipmentEditor) { return this.isEquipmentFormValid = false }
                    if(!this.$refs.velhoEquipmentEditor.isValidForm) { return this.isEquipmentFormValid = false }
                    this.isEquipmentFormValid = this.$refs.velhoEquipmentEditor.isValidForm()
                });
            },
            deep: true
        }
    },
    computed: {
        velhoEquipmentsSelectors() {
            return [
                {
                    text: this.$t("external_road_signs.road_signs"),
                    value: this.EXTERNAL_TRAFFIC_SIGNS
                },
                {
                    text: this.$t("infra.posts"),
                    value: this.EXTERNAL_POSTS
                },
                {
                    text: this.$t("culvert.title"),
                    value: this.EXTERNAL_CULVERTS,
                },
                {
                    text: this.$t("railing.title"),
                    value: this.EXTERNAL_RAILINGS,
                },
                {
                    text: this.$t("portal.title"),
                    value: this.EXTERNAL_PORTALS,
                },
                {
                    text: this.$t("drain.title"),
                    value: this.EXTERNAL_DRAINS,
                },
                {
                    text: this.$t("fence.title"),
                    value: this.EXTERNAL_FENCES,
                },
                {
                    text: this.$t("stair.title"),
                    value: this.EXTERNAL_STAIRS,
                },
                {
                    text: this.$t("gate.title"),
                    value: this.EXTERNAL_GATES,
                },
                {
                    text: this.$t("edge_support.title"),
                    value: this.EXTERNAL_EDGE_SUPPORTS,
                },
                {
                    text: this.$t("edge_pile.title"),
                    value: this.EXTERNAL_EDGE_PILES,
                },
                {
                    text: this.$t("roadside_furniture.title"),
                    value: this.EXTERNAL_ROADSIDE_FURNITURE,
                },
                {
                    text: this.$t("roadside_advertisement.title"),
                    value: this.EXTERNAL_ROADSIDE_ADVERTISEMENTS,
                },
                {
                    text: this.$t("boom_barrier_bollard.title"),
                    value: this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS,
                },
                {
                    text: this.$t("pipe_wire_cable.title"),
                    value: this.EXTERNAL_PIPES_WIRES_AND_CABLES,
                },
                {
                    text: this.$t("lighting.title"),
                    value: this.EXTERNAL_LIGHTING,
                }
            ]
        }
    },
    mounted() {
        this.fetchRoadDataByCoordinates(this.velhoCoordinate)
    },
    methods: {
        fetchRoadDataByCoordinates(coord) {
            try {
                this.loading = true
                this.restFetchParams(this.roadAddressUrl, {lat: coord.lat, lon: coord.lng}, (response) => {
                    this.loading = false
                    if(response.data) {
                        this.roadData = {
                            roadNum: response.data.roadNum,
                            roadSec: response.data.secNum,
                            distance: response.data.distanceSecStart
                        }
                    }
                }, () => { this.loading = false })
            } catch (error) {
                this.loading = false
            }
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        submit() {
            let data = {
                ...this.equipment.toJSON(),
                contract: {
                    'id': this.selectedContract ? this.selectedContract.id : null,
                    'external_contract_id': this.selectedContract && this.selectedContract.external_contract ? this.selectedContract.external_contract.external_id : null
                }
            }

            this.loading = true
            try {
                this.restAdd(this.extEquipmentUrl, data, () => {
                    this.loading = false
                    EventBus.$emit('show-alert', this.$t('common.alert_add_success'), 'success')
                    this.$emit('close')
                }, () => {
                    this.loading = false
                    this.showAlert(this.$t('common.alert_add_failed'))
                })
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>
<style>
 .modal-container {
    min-width: 70%;
}
@import "../../styles/modal.css";
</style>