<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="onReset">
                <div class="col-sm-12 span-header">
                    1. {{ $t('forest_work_report.choose_time') }}
                </div>
                <b-row class="mb-3">
                    <div class="col-sm-6">
                        <b-form-group
                            class="nopads"
                            label-for="fromDate"
                        >
                            <span class="span-title">{{ $t('forest_work_report.from') }}</span>
                            <b-form-input
                                id="fromDate"
                                size="sm"
                                type="date"
                                v-model="fromDate"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-6">
                        <b-form-group
                            class="nopads"
                            label-for="toDate"
                        >
                            <span class="span-title">{{ $t('forest_work_report.to') }}</span>
                            <b-form-input
                                id="date"
                                size="sm"
                                type="date"
                                v-model="toDate"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <div class="col-sm-12 span-header">
                    2. {{ $t('forest_work_report.choose_contract') }}
                </div>
                <b-row class="nopads">
                    <div class="col-sm-5">
                        <span class="span-title"></span>
                        <multiselect
                            v-model="selectedContracts"
                            track-by="name"
                            label="name"
                            :multiple="true"
                            :hide-selected="true"
                            :placeholder="$t('forest_work_report.select_contract')"
                            :options="openContracts"
                            :custom-label="customerAndName"
                            :searchable="true"
                            :internal-search="true"
                            :close-on-select="true"
                        />
                    </div>
                    <b-form-group
                        class="col-sm-2">
                        <b-form-radio-group
                            v-model="vehicleType"
                            :options="vehicleOptions"
                            stacked
                            name="selected-vehicle"
                            @input="fetchVehicles"
                        ></b-form-radio-group>
                    </b-form-group>
                    <div class="col-sm-5">
                        <span class="span-title"></span>
                        <multiselect
                            v-model="selectedVehicles"
                            track-by="make"
                            label="make"
                            :multiple="true"
                            :hide-selected="true"
                            :placeholder="$t('forest_work_report.all_vehicles')"
                            :options="allVehicles"
                            :custom-label="makeAndLicense"
                            :searchable="true"
                            :internal-search="true"
                            :close-on-select="true"
                        />
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('forest_work_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            class="spinner"
            id="loader"
            v-if="loading"/>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'
import Multiselect from 'vue-multiselect'
import {vehicleTypes} from '../mixins/VehicleMixin'

export default {
    name: 'contract-vehicle-filter',
    components: {Multiselect},
    mixins: [timeUtils, restApi],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            fromDate: null,
            toDate: null,
            openContracts: [],
            selectedContracts: [],
            selectedVehicles: [],
            allVehicles: [],
            vehicleType: 0,
            vehicleOptions: [
                {text: this.$t('forest_work_report.all_vehicles'), value: 0},
                {text: this.$t('forest_work_report.forwarder'), value: vehicleTypes.FORWARDER},
                {text: this.$t('forest_work_report.harvester'), value: vehicleTypes.HARVESTER}
            ]
        }
    },
    watch: {
        fromDate: function () {
            this.fetchContracts()
        },
        toDate: function () {
            this.fetchContracts()
        }
    },

    mounted: function () {
        this.fromDate = this.getYesterday()
        this.toDate = this.getYesterday()
        // get all vehicles
        this.fetchVehicles()
    },

    methods: {
        customerAndName({customer, name}) {
            return `${customer.name} — ${name}`
        },

        makeAndLicense({make, license_plate}) {
            return `${make} ${license_plate}`
        },

        fetchVehicles: function () {
            this.loading = true
            this.selectedVehicles = []
            this.allVehicles = []
            let typeArray = [vehicleTypes.FORWARDER, vehicleTypes.HARVESTER]
            if (this.vehicleType > 0) {
                typeArray = [this.vehicleType]
            }
            this.restFetchParams(this.vehicleUrl, {type: typeArray}, this.handleVehicleResponse)
        },

        handleVehicleResponse: function (response) {
            this.loading = false
            if (response != null) {
                this.allVehicles = response.data
            }
        },

        onReset: function () {
            this.fromDate = this.getYesterday()
            this.toDate = this.getYesterday()
            this.selectedContracts = []
            this.vehicleType = 0
            this.selectedVehicles = []
        },

        onSubmit: function () {
            let idList = []
            if (this.selectedContracts.length > 0) {
                this.selectedContracts.forEach(function (item) {
                    idList.push(item.id)
                }, this)
            } else {
                this.openContracts.forEach(function (item) {
                    idList.push(item.id)
                }, this)
            }

            let vehicleIdList = []
            if (this.selectedVehicles.length > 0) {
                this.selectedVehicles.forEach(function (item) {
                    vehicleIdList.push(item.id)
                }, this)
            }

            this.$emit('searchReport', {
                    from: this.localTimeToUtcTime(this.fromDate).toISOString(),
                    to: this.localTimeToUtcTime(this.toDate).toISOString(),
                    contracts: idList,
                    vehicleType: this.vehicleType,
                    vehicles: vehicleIdList
                }
            )
        },

        getYesterday: function () {
            let d = new Date();
            d.setDate(d.getDate() - 1)
            let month = d.getMonth() + 1
            if (month < 10) {
                month = '0' + month;
            }
            let day = d.getDate()
            if (day < 10) {
                day = '0' + day;
            }
            return d.getFullYear() + '-' + month + '-' + day
        },

        fetchContracts: function () {
            this.loading = true
            this.openContracts = []
            if (this.toDate && this.fromDate) {
                this.restFetchParams(this.contractOpenUrl,
                    {
                        startDate: this.localTimeToUtcTime(this.fromDate).toISOString(),
                        endDate: this.getEndOfTheDay(this.toDate).toISOString()
                    },
                    this.handleContracts)
            }
        },
        handleContracts: function (response) {
            this.loading = false
            if (response != null) {
                this.openContracts = response.data
            }
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
