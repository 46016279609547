<template>
    <div
        class="details-container"
        @click.stop="$emit('cancel')"
    >
        <div
            class="details dynamic_width"
            @click.stop=""
        >
            <h3 class="pl-1">
                {{ this.value.otsikko }}
            </h3>

        <div class="editor-form form-data">
            <b-row>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('common.id') }}</span>
                    <span class="item-detail-text">{{ format(this.value.ilmoitusid) }}</span>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.notification_type') }}</span>
                    <span class="item-detail-text">{{ format(this.value.ilmoitustyyppi) }}</span>
                </div>
            </b-row>

            <b-row>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('notification_list.topic') }}</span>
                    <span class="item-detail-text">{{ this.value.aihe.nimi }} - {{ this.value.aihe.id }}</span>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('street_lights.pole_additional_info') }}</span>
                    <span class="item-detail-text">{{ this.value.tarkenne.nimi }} - {{ this.value.tarkenne.id }}</span>
                </div>
            </b-row>

            <b-row>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.forwarded_harja') }}</span>
                    <span class="item-detail-text">{{ format(this.getDate(this.value.valitettyH)) }}</span>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.forwarded_inspection') }}</span>
                    <span class="item-detail-text">{{ format(this.getDate(this.value.valitettyUrakkaan)) }}</span>
                </div>
            </b-row>

            <b-row>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.notified') }}</span>
                    <span class="item-detail-text">{{ format(this.getDate(this.value.ilmoitettu)) }}</span>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.received_harja') }}</span>
                    <span class="item-detail-text">{{ format(this.getDate(this.value.vastaanotettuHarjaan)) }}</span>
                </div>
            </b-row>


            <b-row>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.sender') }}</span>
                    <span class="item-detail-text"> {{ format(this.value.lahettaja.etunimi) + ' ' + format(this.value.lahettaja.sukunimi) + ' ' + format(this.value.lahettaja.email) }}</span>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('defects.reporter') }}</span>
                    <span class="item-detail-text">{{ format(this.value.ilmoittaja.etunimi) + ' ' + format(this.value.ilmoittaja.sukunimi) + ' ' + format(this.value.ilmoittaja.email) + ' ' + format(this.value.ilmoittaja.matkapuhelin) }}</span>
                </div>
            </b-row>

            <b-row>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.notification_type') }}</span>
                    <span class="item-detail-text">{{  format(this.value.ilmoitustyyppi) }}</span>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('material_station.scale_id') }}</span>
                    <span class="item-detail-text">{{  format(this.value.tunniste) }}</span>
                </div>
            </b-row>

            <b-row>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('contracts.status') }}</span>
                    <span class="item-detail-text">{{  format(this.value.tila) }}</span>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('equipment.description') }}</span>
                    <span class="item-detail-text">{{ format(this.value.lisatieto) }}</span>
                </div>
            </b-row>

            <b-row>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.site_description') }}</span>
                    <span class="item-detail-text">{{ format(this.value.paikankuvaus) }}</span>
                </div>
                <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('harja.area_inspection_number') }}</span>
                    <span class="item-detail-text">{{ format(this.value.alueurakkanumero) }}</span>
                </div>
            </b-row>

            <b-table
                small
                striped
                bordered
                fixed
                hover
                :items="this.value.selitteet"
                :fields="tableFields"
            >

            </b-table>

            <b-table
                small
                striped
                bordered
                fixed
                hover
                :items="results"
                :fields="fields"
            >
            </b-table>

            <div class="button-container pt-3 mb-0">
                <b-button
                    variant="danger"
                    class="form-button"
                    @click.stop="$emit('cancel')"
                >
                    <span>{{ $t('common.close') }}</span>
                </b-button>
            </div>
            </div>
            </div>
        </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: "HarjaNotificationModal",
    components: {},
    mixins: [restApi, timeUtils],
    props: {
        value: {
            type: Object,
            return: null
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        isWorker: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            tableFields: [],
            fields: [],
            results: [],
            harjaData: []
        }
    },

    mounted() {
        this.initTableItems()

        this.tableFields = [
            {key: 'selite', label: this.$t('road_details.definition'), sortable: false}
        ]
            this.fields = [
            {
                key: 'kuittaustyyppi',
                label: this.$t('harja.acknowledgement_type'),
                sortable: true
            },
            {
                key: 'kuitattu',
                label: this.$t('harja.acknowledgement'),
                sortable: true
            },
            {
                key: 'ytunnus',
                label: this.$t('harja.business_id'),
                sortable: true
            },
            {
                key: 'fullname',
                label: this.$t('common.name'),
                sortable: true
            },
            {
                key: 'vakiofraasi',
                label: this.$t('harja.stantard_question'),
                sortable: false
            },
            {
                key: 'vapaateksti',
                label: this.$t('harja.free_text'),
                sortable: false
            }
        ]
    },

    methods: {

        initTableItems: function () {
            this.results = []
            this.value.kuittaukset.forEach(item => {
                this.results.push({
                    kuittaustyyppi: this.format(item.kuittaus.kuittaustyyppi, ' '),
                    kuitattu: this.format(this.getDate(item.kuittaus.kuitattu), ' '),
                    ytunnus: this.format(item.kuittaus.kuittaajaorganisaatio.ytunnus, ' '),
                    fullname: this.format(item.kuittaus.kuittaaja.etunimi) + ' ' + this.format(item.kuittaus.kuittaaja.sukunimi),
                    vakiofraasi: this.format(item.kuittaus.vakiofraasi, ' '),
                    vapaateksti: this.format(item.kuittaus.vapaateksti, ' '),
                })
            })
        },

        format(message, fallBackText) {
            if (!message || message === '' || message === '[]') {
                if (!fallBackText) {
                    fallBackText = '-'
                }
                return fallBackText
            }
            return message
        },

    }
}
</script>
