<template>
    <div class="col-sm-12">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
                <span class="item-title-add">{{ $t('common.add_new') }}</span>
                <font-awesome-icon
                    icon="wrench"
                    class="card-body-icon"
                />
            </div>
        </div>
        <transition name="fade">
            <!-- Main table element -->
            <div class="col-sm-12 list-content result-table">
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    small
                    :items="defects"
                    :fields="fields"
                    @row-clicked="emitDetails"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(created)="row">
                        {{ row.value ? toLocalTime(row.value) : '' }}
                    </template>
                    <template v-slot:cell(fixed)="row">
                        {{ row.value ? toLocalTime(row.value) : '' }}
                    </template>
                    <template
                        slot="status"
                        slot-scope="row"
                    >
                        {{ row.value === 1 ? $t('area_report.status_open') : $t('area_report.completed') }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'AreaDefectList',
    mixins: [timeUtils],
    props: {
        items: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            sortBy: 'created',
            sortDesc: true,
            sortDirection: 'asc',
            fields: [
                {key: 'created', label: this.$t('defects.created'), sortable: true},
                {key: 'area_name', label: this.$t('area_defects.area_name'), sortable: true},
                {key: 'area_equipment', label: this.$t('area_defects.equipment'), sortable: true},
                {key: 'reporter', label: this.$t('defects.reporter'), sortable: true},
                {key: 'description', label: this.$t('defects.description'), sortable: true},
                {key: 'defect_status', label: this.$t('defects.status'), sortable: true},
                {key: 'fixed', label: this.$t('defects.fixed'), sortable: true},
                {key: 'fixed_by', label: this.$t('defects.fixed_by'), sortable: true}
            ],
            defects: []
        }
    },
    watch: {
        items: function () {
            this.initTableItems()
        }
    },
    methods: {
        initTableItems: function () {
            this.defects = []
            this.items.forEach(item => {
                this.defects.push({
                    id: item.id,
                    area_name: item.area.name,
                    area_equipment: item.area_equipment ? item.area_equipment.name : '',
                    reporter: item.reporter.last_name + ', ' + item.reporter.first_name,
                    created: item.created_date,
                    description: item.description,
                    defect_status: this.getDefectStatusString(item.status),
                    fixed: item.fixed_date,
                    fixed_by: item.fixer ? item.fixer.last_name + ', ' + item.fixer.first_name : '',
                    _rowVariant: this.getDefectRowVariant(item.status)
                })
            })
        },
        emitDetails: function (result) {
            // Find defect item from original items and emit request to edit
            let p = this.items.find(item => item.id === result.id)
            if (p) {
                this.$emit('edit', p)
            }
        },
        getDefectRowVariant: function (status) {
            switch (status) {
                case 3:
                    return 'warning'
                case 4:
                    return 'danger'
            }
            return ''
        },
        getDefectStatusString: function (status) {
            switch (status) {
                case 2:
                    return this.$t('inspection.options.okay')
                case 3:
                    return this.$t('inspection.options.defects')
                case 4:
                    return this.$t('inspection.options.ban')
            }
            return '-'
        }
    }
}
</script>

<style scoped>

</style>
