<template>
    <div>
        <div class="badge" :style="{background: statusColor}" @click.stop="toggleStatusSelector">
            {{ getStatusString(statusValue) }}
        </div>
        <b-modal
            v-model="statusSelectorVisible"
            centered
            size="sm"
            hide-footer
            :title="$t('calendar.change_order_status')">
            <div class="col-sm-12 nopads text-center">
                <div
                    v-for="status in statusArray"
                    v-bind:key="status"
                    class="col-sm-5 status-selector"
                    :style="{background: getStatusColor(status)}"
                    @click.stop="changeStatus(status)"
                >
                    {{ getStatusString(status)}}
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>

import {workAssignmentHelper} from "@/components/mixins/WorkAssignmentMixin";

export default {
    name: 'AssignmentStatusBadge',
    mixins: [workAssignmentHelper],
    data() {
        return {
            statusSelectorVisible: false,
            statusColor: null,
            statusValue: null,
        }
    },
    props: {
        itemId: {
            type: Number,
            default: -1
        },
        status: {
            type: Number,
            default: -1
        }
    },
    computed: {
        statusArray() {
           return [this.OPEN, this.ASSIGNED, this.IN_PROGRESS, this.COMPLETED, this.CANCELLED]
        }
    },
    watch: {
        status: function () {
            this.statusColor = this.getStatusColor(this.status)
            this.statusValue = this.status
        }
    },
    created() {
        this.statusValue = this.status
        this.statusColor = this.getStatusColor(this.status)
    },
    methods: {
        toggleStatusSelector() {
            this.statusSelectorVisible = !this.statusSelectorVisible
        },
        changeStatus(status) {
            this.$emit('assignmentStatusChanged', this.itemId, status)
            this.statusSelectorVisible = false
            this.statusValue = status
            this.statusColor = this.getStatusColor(status)
        }
    }
}
</script>
<style scoped>
.badge {
    font-size: .8em;
    font-weight: bold;
    text-align: center;
    border-radius: .3em;
    background: #404041;
    color: #FFFFFF;
    width: 6em;
    margin: auto;
    margin-top: 4px;
    margin-bottom: -4px;
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    cursor: pointer;
    border: transparent 1px solid;
}

.badge:hover, .status-selector:hover {
    border: #BCBCBC 1px solid;
}

.status-selector {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    margin: .3em;
    border-radius: 5px;
    font-size: .9em;
    cursor: pointer;
    border: transparent 1px solid;
}

</style>
