<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div v-if="results && results.length"
         id="materials"
         class="nopads">
        <b-table
            show-empty
            small
            stacked="md"
            :items="results"
            :fields="teamFields"
            :sort-by.sync="teamSortBy"
            :sort-desc.sync="sortDesc"
        >
            <template v-slot:cell(certificates)="row">
                <div>
                    {{ getCertificates(row.value) }}
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {printHelper} from '../mixins/PrintMixin'
import {restApi} from '../mixins/RestApiMixin'
import {vehicleHelper} from '../mixins/VehicleMixin'

export default {
    name: 'TeamReportDetails',
    mixins: [timeUtils, printHelper, restApi, vehicleHelper],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            attachment: null,
            sortDesc: false,
            teamSortBy: 'last_name',
            teamFields: [
                {key: 'first_name', label: this.$t('work_report.first_name'), sortable: false},
                {key: 'last_name', label: this.$t('work_report.last_name'), sortable: false},
                {key: 'tax_number', label: this.$t('users.tax_number'), sortable: false},
                {key: 'certificates', label: this.$t('menu.certificates'), sortable: false}
            ],
        }
    },
    methods: {
        getCertificates: function (certificates) {
            var string = ''
            certificates.forEach(function (certificate) {
                if (string.length !== 0) {
                    string += ', '
                }
                string += certificate.type.name
                if (certificate.expiration_day) {
                    string += ' - '
                    string += this.getShortDateString(certificate.expiration_day)
                }
            }, this)
            return string
        }
    }
}
</script>
