<template>
    <div class="search-form mt-0 mb-0 p-2" style="height: 40vh">
        <span class="report-title mt-0 text-center">{{ title }}</span>
        <bar-chart
            class="routa-bars"
            ref="observationValues"
            v-if="this.results.length"
            :labels="labels"
            :colors="colors"
            :data="chartData"
            :upperLimit="upperLimit"
            :lowerLimit="lowerLimit"
            :title="title"
            @onBarClick="handleBarClick"
        ></bar-chart>
    </div>
</template>

<script>
import BarChart from '../../chart/BarChart'
import {timeUtils} from "../../mixins/TimeUtils";

export default {
    name: 'observation-value-charts',
    components: {BarChart},
    mixins: [timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            type: String,
            default() {
                return '';
            }
        },
        upperLimit: {
            type: Number,
            default: null
        },
        lowerLimit: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            labels: [],
            colors: [],
            chartData: []
        }
    },
    mounted: function () {
        this.formatData()
    },
    watch: {
        results() {
            this.formatData()
        },
    },
    methods: {
        formatData: function () {
            this.results.forEach(item =>  {
                this.chartData.push({value: item.value})
                this.labels.push(this.toLocalTime(item.created_time))
                this.colors.push(this.beyondLimit(item.value) ? '#bd7c7c' : '#81a464')
            })
        },
        beyondLimit(value) {
            return (this.upperLimit && value > this.upperLimit) || (this.lowerLimit && value < this.lowerLimit)
        },
        handleBarClick(index) {
            this.$emit('itemClicked', index)
        },
        setSelected(index) {
            this.$refs.observationValues.setSelected(index)
        }
    }
}
</script>
<style>
</style>
