
export const gateHelper = {
    methods: {
        getOpeningMechanisms: function () {
            return [
                {name: this.$t('gate.opening_mechanism.select_mechanism'), value: null},
                {name: this.$t('gate.opening_mechanism.hinged_gate'), value: gateOpeningMechanisms.HINGED_GATE},
                {name: this.$t('gate.opening_mechanism.other'), value: gateOpeningMechanisms.OTHER},
            ]
        },
        getGateMaterials: function () {
            return [
                {name: this.$t('materials.select_material'), value: null},
                {name: this.getMaterialString(gateMaterials.STEEL), value: gateMaterials.STEEL},
                {name: this.getMaterialString(gateMaterials.OTHER), value: gateMaterials.OTHER},
                {name: this.getMaterialString(gateMaterials.PLASTIC), value: gateMaterials.PLASTIC},
                {name: this.getMaterialString(gateMaterials.METAL), value: gateMaterials.METAL},
                {name: this.getMaterialString(gateMaterials.COMPOSITE), value: gateMaterials.COMPOSITE}
            ]
        },
    }
}

export const gateOpeningMechanisms = {
    HINGED_GATE: 1,
    OTHER: 2,
}

export const gateMaterials = {
    WOOD: 4,
    STEEL: 5,
    OTHER: 8,
    PLASTIC: 10,
    METAL: 17,
    COMPOSITE: 19
}

