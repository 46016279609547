<template>
    <div class="content-container">
        <!-- Top bar-->
        <b-navbar
            variant="faded"
        >
            <div
                class="header"
            >
              <span class="title">
                {{ $t('menu.unknown_notes') }}
              </span>
            </div>
        </b-navbar>
        <div class="col-sm-12 list-container">
            <external-scale-record-list
                :records="records"
                @rowClicked="editRecord"
            />
        </div>

        <!-- Editor-->
        <external-scale-record-modal
            v-if="record && isObserver"
            v-model="record"
            @cancel="record = null"
            @closeAndUpdate="closeAndUpdate"
        />

        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import ExternalScaleRecordList from "@/components/materials/station/ExternalScaleRecordList";
import ExternalScaleRecordModal from "@/components/materials/station/ExternalScaleRecordModal";

export default {
    name: 'external-scale-records',
    mixins: [restApi],
    props: {
        isObserver: {
            type: Boolean,
            default: null
        },
    },
    components: {
        ExternalScaleRecordModal,
        ExternalScaleRecordList
    },
    data: function () {
        return {
            loading: false,
            records: [],
            record: null
        }
    },
    created: function () {
        this.fetchRecords()
    },
    methods: {
        fetchRecords: function () {
            this.loading = true
            this.records = []
            this.restFetchParams(this.scaleRecordUrl, {status: 0}, this.handleResponse, this.onError)
        },
        handleResponse: function (response) {
            this.records = response.data
            this.loading = false
        },
        onError: function () {
            this.loading = false
        },
        editRecord: function (record) {
            this.record = record
        },
        closeAndUpdate: function () {
            this.record = null
            this.fetchRecords()
        }
    }
}
</script>
