<template>
    <div style="display: block; height: auto; overflow-y: auto">
        <b-table
            ref="table"
            :items="value"
            :responsive="true"
            :borderless="true"
            :small="true"
            :fields="fields"
        >
            <template v-slot:cell(name)="row">
                <b-form-input
                    v-if="editable"
                    v-model="row.item.name"
                    type="text"
                    class="m-0"
                    size="sm"
                    :state="isValidInput(row.item.name)"
                />
                <span v-else>{{ row.value }}</span>
            </template>
            <template v-slot:cell(scale_id)="row">
                <b-form-input
                    v-if="editable"
                    v-model="row.item.scale_id"
                    type="text"
                    class="m-0"
                    size="sm"
                    :state="isValidInput(row.item.scale_id)"
                />
                <span v-else>{{ row.value }}</span>
            </template>
            <template v-slot:cell(edit)="row">
                <font-awesome-icon icon="trash" @click.stop="value.splice(value.indexOf(row.item), 1)"
                                   style="cursor: pointer; margin-top: .5em"/>
            </template>
        </b-table>
        <div class="button-container">
            <b-button
                v-if="editable"
                variant="primary"
                class="form-button"
                @click.stop="value.push(newScale())"
            >
                <span>{{ $t('material_station.add_scale') }}</span>
            </b-button>
        </div>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: "MaterialList",
    mixins: [restApi],
    props: {
        value: {
            type: Array,
            default() {
                return [];
            }
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        fields() {
            return [
                {
                    key: 'name',
                    label: this.$t('material_station.name'),
                    sortable: false
                },
                {
                    key: 'scale_id',
                    label: this.$t('material_station.scale_id'),
                    sortable: false
                },
                !this.editable ? undefined : {
                    key: 'edit',
                    label: ''
                }
            ]
        }
    },
    methods: {
        isValidInput(input) {
            return input !== null && input.length > 0
        },
        newScale: function () {
            return {
                name: null,
                scale_id: null
            }
        }
    }
}
</script>


<style scoped lang="scss">

</style>
