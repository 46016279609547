<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            @click="closeAndUpdate"
        >
            <div
                v-if="!editMode"
                class="header"
            >
        <span class="title">
          {{ $t('vehicle.vehicle') }}
        </span>
            </div>
            <div
                v-else
                class="header"
                @click="closeAndUpdate"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>
        <keep-alive>
            <vehicle-search
                v-if="!editMode"
                @search="fetchVehicles"
            />
        </keep-alive>
        <div
            v-if="!editMode"
            class="ml-3 mr-3 data-list-item add"
            @click.stop="addVehicle"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
                <span class="item-title-add">{{ $t('common.add_new') }}</span>
                <font-awesome-icon
                    icon="file-contract"
                    class="card-body-icon"
                />
            </div>
        </div>
        <transition name="fade" >
            <vehicle-tabbed
                v-if="editMode && vehicle"
                :vehicle="vehicle"
                :editMode="vehicle.id > 0"
                @close="showList"
                @closeAndUpdate="closeAndUpdate"
            />

            <vehicle-list
                v-if="!editMode"
                :results="results"
                @edit="editVehicle"
            />

        </transition>

        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >

        </div>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>



<script>
import {vehicleHelper} from '../mixins/VehicleMixin'
import {restApi} from '../mixins/RestApiMixin'
import VehicleSearch from "@/components/vehicle/tabbed/VehicleSearch.vue";
import VehicleList from './VehicleList'
import VehicleTabbed from "@/components/vehicle/tabbed/VehicleTabbed.vue";

export default {
    name: 'Vehicles',
    components: {VehicleTabbed, VehicleList, VehicleSearch},
    mixins: [vehicleHelper, restApi],
    props: {
        result: Object,
    },
    data: function () {
        return {
            selected: [],
            results: [],
            lastSearchParams: [],
            vehicle: null,
            editMode: false,
            loading: false,

        }
    },
    methods: {
        fetchVehicles: function (params) {
            this.lastSearchParams = params
            this.loading = true
            this.results = []
            this.restFetchParams(this.vehicleUrl, params, this.handleResponse)
        },

        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },

        editVehicle: function (vehicle) {
            this.vehicle = vehicle
            this.editMode = true
        },

        addVehicle: function () {
            this.vehicle = this.newVehicle()
            this.editMode = true
        },

        showList: function () {
            this.vehicle = null
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.fetchVehicles(this.lastSearchParams)
            this.showList()
        }
    }
}
</script>
