<template>
    <b-form-group class="title">
        <div>
            <span class="span-title">
            </span>
        <span v-if="hint" class="col-12 note p-1">{{ hint}}</span>
        <b-form-file
            ref="file-input"
            :value="value"
            :disabled="disabled"
            v-on:input="updateValue($event)"
            :accept="extension"
            :placeholder="label +' '+ $t('import_file_wizard.max_shapefile_size')"
        />
        </div>
        <div>
            <span v-if="invalidFile"
                  class="subtitle"
                  style="color: red">
                  {{  $t('import_file_wizard.shape_file_too_big')}}
             </span>
        </div>
    </b-form-group>
</template>
<script>
export default {
    name: 'shape-file-picker',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: File,
            default: null
        },
        extension: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            required: false,
            default: null
        }},
    data: function () {
        return {
            invalidFile: false
        }
    },
    methods: {
        updateValue: function (value) {
            if (value !== null) {
                let maxFileSize = 6 * 1024 * 1024 // 2 MB
                this.invalidFile = value.size > maxFileSize
                if (this.invalidFile) {
                    value = null
                    this.$refs['file-input'].reset()
                }
            }
            this.$emit('input', value)
        }
    }
}
</script>


