<template>
    <div
        id="equipment-list"
        class="col-sm-12"
    >
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
                <span class="item-title-add">{{ $t('common.add_new') }}</span>
                <font-awesome-icon
                    icon="barcode"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table nopads"
            >
                <b-table
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                    :sort-by="sortBy"
                    :sort-compare-locale="$i18n.locale"
                    :bordered="true"
                    class="pointer"
                    @row-clicked="emitEdit"
                >
                    <template v-slot:cell(area)="row">
                        {{row.value.name }}
                    </template>
                    <template v-slot:cell(type)="row">
                        {{ getEquipmentType(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {areaHelper} from '../../mixins/AreaMixin'

export default {
    name: 'EquipmentList',
    mixins: [restApi, areaHelper],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            sortBy: 'area',
            sortDesc: false,
        }
    },
    computed: {
        fields() {
            return [
                {key: 'area', label: this.$t('common.area'), sortable: true},
                {key: 'name', label: this.$t('equipment.name'), sortable: true},
                {key: 'type', label: this.$t('equipment.type'), sortable: true}
            ]
        }
    },

    methods: {
        emitEdit(areaEquipment) {
            this.$emit('edit', areaEquipment)
        }
    }
}
</script>

<style scoped>
.map-container {
    height: 250px;
    margin-bottom: -0.2em;
}

.collapse {
    cursor: default;
}
</style>
