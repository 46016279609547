
export const roadsideFurnitureHelper = {
    methods: {
        getRoadsideFurnitureTypes: function () {
            return [
                // {name: this.$t('drain.select_type'), value: null},
                {name: this.$t('roadside_furniture.types.buss_stop_canopy'), value: roadsideFurnitureTypes.BUSS_STOP_CANOPY},
                {name: this.$t('roadside_furniture.types.canopy'), value: roadsideFurnitureTypes.CANOPY},
                {name: this.$t('roadside_furniture.types.table_and_bench'), value: roadsideFurnitureTypes.TABLE_AND_BENCH},
                {name: this.$t('roadside_furniture.types.bench'), value: roadsideFurnitureTypes.BENCH},
                {name: this.$t('roadside_furniture.types.trash_bin'), value: roadsideFurnitureTypes.TRASH_BIN},
                {name: this.$t('roadside_furniture.types.eco_recycling_point'), value: roadsideFurnitureTypes.ECO_RECYCLING_POINT},
                {name: this.$t('roadside_furniture.types.emptying_point_for_a_chemical_toilet'), value: roadsideFurnitureTypes.EMPTYING_POINT_FOR_A_CHEMICAL_TOILET},
                {name: this.$t('roadside_furniture.types.above_ground_trash_can'), value: roadsideFurnitureTypes.ABOVE_GROUND_TRASH_CAN},
                {name: this.$t('roadside_furniture.types.above_ground_waste_container'), value: roadsideFurnitureTypes.ABOVE_GROUND_WASTE_CONTAINER},
                {name: this.$t('roadside_furniture.types.embedded_waste_container'), value: roadsideFurnitureTypes.EMBEDDED_WASTE_CONTAINER},
                {name: this.$t('roadside_furniture.types.wc'), value: roadsideFurnitureTypes.WC},
                {name: this.$t('roadside_furniture.types.playground'), value: roadsideFurnitureTypes.PLAYGROUND},
                {name: this.$t('roadside_furniture.types.fitness_equipment'), value: roadsideFurnitureTypes.FITNESS_EQUIPMENT},
                {name: this.$t('roadside_furniture.types.dressing_room'), value: roadsideFurnitureTypes.DRESSING_ROOM},
                {name: this.$t('roadside_furniture.types.guidance_map'), value: roadsideFurnitureTypes.GUIDANCE_MAP},
                {name: this.$t('roadside_furniture.types.fire_place'), value: roadsideFurnitureTypes.FIRE_PLACE},
                {name: this.$t('roadside_furniture.types.bike_rack'), value: roadsideFurnitureTypes.BIKE_RACK},
                {name: this.$t('roadside_furniture.types.sandbox'), value: roadsideFurnitureTypes.SANDBOX},
                {name: this.$t('roadside_furniture.types.pier'), value: roadsideFurnitureTypes.PIER},
                {name: this.$t('roadside_furniture.types.time_frame'), value: roadsideFurnitureTypes.TIME_FRAME},
                {name: this.$t('roadside_furniture.types.other_furniture'), value: roadsideFurnitureTypes.OTHER_FURNITURE},
                {name: this.$t('roadside_furniture.types.reserves'), value: roadsideFurnitureTypes.RESERVES},
                {name: this.$t('roadside_furniture.types.works_of_art'), value: roadsideFurnitureTypes.WORKS_OF_ART},
                {name: this.$t('roadside_furniture.types.urbanization_gate'), value: roadsideFurnitureTypes.URBANIZATION_GATE},
                {name: this.$t('roadside_furniture.types.bicycle_shed'), value: roadsideFurnitureTypes.BICYCLE_SHED},
            ]
        },
        getRoadsideFurnitureWCDrainageTypes: function () {
            return [
                {name: this.$t('roadside_furniture.wc_drainage_types.dry_toilet'), value: WCDrainageTypes.DRY_TOILET},
                {name: this.$t('roadside_furniture.wc_drainage_types.drained_into_the_network'), value: WCDrainageTypes.DRAINED_INTO_THE_NETWORK},
                {name: this.$t('roadside_furniture.wc_drainage_types.drained_into_a_well'), value: WCDrainageTypes.DRAINED_INTO_A_WELL},
            ]
        },
    }
}


export const roadsideFurnitureTypes = {
    BUSS_STOP_CANOPY: 1,
    CANOPY: 2,
    TABLE_AND_BENCH: 3,
    BENCH: 4,
    TRASH_BIN: 5,
    ECO_RECYCLING_POINT: 6,
    EMPTYING_POINT_FOR_A_CHEMICAL_TOILET: 7,
    ABOVE_GROUND_TRASH_CAN: 8,
    ABOVE_GROUND_WASTE_CONTAINER: 9,
    EMBEDDED_WASTE_CONTAINER: 10,
    WC: 11,
    PLAYGROUND: 12,
    FITNESS_EQUIPMENT: 13,
    DRESSING_ROOM: 14,
    GUIDANCE_MAP: 15,
    FIRE_PLACE: 16,
    BIKE_RACK: 17,
    SANDBOX: 18,
    PIER: 19,
    TIME_FRAME: 20,
    OTHER_FURNITURE: 22,
    RESERVES: 23,
    WORKS_OF_ART: 24,
    URBANIZATION_GATE: 25,
    BICYCLE_SHED: 26
}

export const WCDrainageTypes = {
    DRY_TOILET: 1,
    DRAINED_INTO_THE_NETWORK: 2,
    DRAINED_INTO_A_WELL: 3,
}
