export const sportHelper = {
    data: function () {
        return {
            SKI_CLASSIC: 17262,
            SKI_FREESTYLE: 17261,
            SKI_LIGHTS: 17260,
            SKI_COMPETITION: 17267,
            TRACK_SKIING: 4402,
            TRACK_CYCLING: 4412,
            TRACK_CYCLING_OFFROAD: 4411,
            TRACK_SNOWMOBILE: 4421,
            TRACK_WALKING: 4401
        }
    }
}
