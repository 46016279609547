<template>
    <div class="nopads">
        <b-form-group label-for="companies">
            <span class="span-title">{{ $t('observation_filter.company') }}</span>
            <b-form-select
                id="companies"
                v-bind:value="value"
                v-on:input="newVal => $emit('input', newVal)"
                size="sm"
                class="sm"
                :disabled="companies.length < 1"
            >
                <template slot="first">
                    <option :value="null">
                        {{ $t('observation_filter.select_company') }}
                    </option>
                </template>
                <option
                    v-for="option in companies"
                    :key="option.id"
                    :value="option.id"
                >
                    {{ option.name }}
                </option>
            </b-form-select>
        </b-form-group>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'CompanyFilter',
    mixins: [restApi],
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            companies: [],
            loading: true
        }
    },
    mounted: function () {
        this.fetchCompanies()
    },
    methods: {
        fetchCompanies: function () {
            this.loading = true
            this.restFetch(this.companyUrl, this.handleCompanies)
        },
        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        }
    }
}
</script>
