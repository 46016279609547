<template>
    <div>
        <b-navbar
            variant="faded"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('api_keys.title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <api-keys-list
                    v-if="!editMode"
                    :can-add="true"
                    :results="results"
                    @add="addKey"
                    @edit="editKey"
                />
            </transition>
            <transition name="fade">
                <api-key-editor
                    v-if="editMode"
                    :is-admin="isAdmin"
                    :key-to-edit="key"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {apiKeyHelper} from '../mixins/ApiKeyMixin'
import ApiKeysList from "./ApiKeysList";
import ApiKeyEditor from "./ApiKeyEditor";

export default {
    name: 'ApiKeys',
    components: {ApiKeyEditor, ApiKeysList},
    mixins: [restApi, apiKeyHelper],
    data: function () {
        return {
            results: [],
            key: null,
            editMode: false,
            loading: true
        }
    },
    props: {
        userAccount: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isSuperUser: Boolean
    },
    mounted: function () {
        this.fetchKeys()
    },
    methods: {
        fetchKeys: function () {
            this.loading = true
            this.restFetch(this.apiKeyUrl, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
        },

        editKey: function (key) {
            this.key = key
            this.editMode = true
        },

        addKey: function () {
            this.key = this.newApiKey()
            this.editMode = true
        },

        showList: function () {
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.fetchKeys()
            this.showList()
        }
    }
}
</script>
