<template v-slot:modal-footer>
    <div class="col-sm-12 nopads">
        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <b-row class="p-2">
            <div class="col-sm-3 p-2">
                <span class="span-title">
                    {{ $t('trip_list.vehicle') }}
                </span>
                <span v-if="item.vehicle">
                    {{ item.vehicle.license_plate }} {{ item.vehicle.make }} {{ item.vehicle.vehicle_model }}
                </span>
            </div>
            <div class="col-sm-3 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.spreader') }}
                </span>
                <span>
                    {{ item.name }}
                </span>
            </div>
            <div class="col-sm-3 p-2">
                <span class="span-title">
                    {{ $t('orders.contract') }}
                </span>
                <span>
                    {{ getMaterialName(item.material_dry) }}
                </span>
            </div>
            <div class="col-sm-3 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.dry_material') }}
                </span>
                <span>
                    {{ item.total_dry_amount }} {{ item.material_dry_unit }}
                </span>
            </div>
            <div class="col-sm-3 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.liquid_material') }}
                </span>
                <span>
                    {{ getMaterialName(item.material_liquid) }}
                </span>
            </div>
            <div class="col-sm-3 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.liquid_amount') }}
                </span>
                <span>
                    {{ item.total_liquid_amount }} {{ item.material_liquid_unit }}
                </span>
            </div>
            <div class="col-sm-3 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.time') }}
                </span>
                <span>
                    {{ toLocalTime(item.start_time) }} -  {{ toLocalTime(item.end_time) }}
                </span>
            </div>
        </b-row>
        <hr class="p-2 mb-1 mt-1"/>
        <b-row class="nopads mb-2">
            <div class="col-sm-12">
                <span class="span-title">{{ $t('spreader_data.edit_spreader_data') }}</span>
            </div>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-12">
                <span class="span-title">{{ $t('spreader_data.edit_spreader_order') }}</span>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads pr-1" style="margin-top: -3px">
                        <open-contract-filter
                            ref="contracts"
                            :to="item.end_time"
                            :from="item.start_time"
                            :show-company="false"
                            :showOnlyMyContractsOption="true"
                            :show-title="false"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                        />
                    </div>

                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <contract-order-filter
                            v-model="order"
                            :contract-id="contract ? contract.id : undefined"
                            :show-executor-text="true"
                            :show-title="false"
                        />
                    </div>
                </b-row>
            </div>
        </b-row>
        <b-row class="nopads mt-2">
            <div class="col-sm-12">
                <span class="span-title">{{ $t('spreader_data.edit_spreader_vehicle') }}</span>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('trip_list.vehicle') }}</span>
                        <vehicle-selector-dropdown :vehicleId="this.vehicle" @onVehicleIdSelected="setVehicle" />
                    </div>
                </b-row>
            </div>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-12 button-container">
                <b-button
                variant="danger"
                class="result-button"
                @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="result-button"
                    :disabled="loading"
                    @click.stop="sendDataToHarja"
                >
                    {{ $t('common.send') }}
                </b-button>
            </div>
        </b-row>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        ></div>
    </div>
</template>
<script>
import {EventBus} from '@/event-bus'
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from '@/components/mixins/TimeUtils'
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";
import VehicleSelectorDropdown from "@/components/vehicle/VehicleSelectorDropdown"
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import ContractOrderFilter from "@/components/order/ContractOrderFilter";

export default {
    name: 'SpreaderDataHarjaSender',
    props: {
        item: {
            type: Object,
            default() {
                return ({})
            }
        },
        user: {
            type: Object,
            default: null
        },
        vehicleId: {
            type: Number,
            default: null
        },
        isAdmin: Boolean,
    },
    components: {ContractOrderFilter, OpenContractFilter, VehicleSelectorDropdown},
    mixins: [timeUtils, restApi, spreaderDataMixin],
    data: function () {
        return {
            loading: false,
            customer: null,
            contractor: null,
            contractName: null,
            company: null,
            vehicle: this.vehicleId,
            orders: [],
            order: null,
            contract: null,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            variant: 'danger',
            alertMessage: '',
        }
    },
    methods: {
        setCustomer: function (customer) {
            this.customer = customer
        },
        setCompany: function (company) {
            this.company = company
        },
        setContractor: function (contractor) {
            this.contractor = contractor
        },
        setContract: function (contract) {
            this.contract = contract
        },
        setVehicle: function (vehicle) {
            this.vehicle = vehicle
        },
        sendDataToHarja: function () {
            this.loading = true
            if(this.item.id) {
                this.restAdd(this.spreaderDataToHarjaUrl, {id: this.item.id, order: this.order, vehicle: this.vehicle}, () => {
                this.loading = false
                this.$emit('close')
                EventBus.$emit('show-alert', this.$t('spreader_data.alert_send_success'), 'success')
                }, () => {
                    this.loading = false
                    this.showAlert(this.$t('spreader_data.alert_send_fail'))
                })
            }
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
    }
}
</script>
