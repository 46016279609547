<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                        <span class="span-title">{{ $t('general_guides.document_title') }}</span>
                        <b-input
                            id="name"
                            v-model="name"
                            size="sm"
                            :placeholder="$t('general_guides.document_title')"
                            type="text"/>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                        <span class="span-title">{{ $t('common.description') }}</span>
                        <b-input
                            id="description"
                            v-model="description"
                            size="sm"
                            :placeholder="$t('common.description')"
                            type="text"/>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                        <span class="span-title">{{ $t('general_guides.added_by') }}</span>
                        <b-input
                            id="added_by"
                            v-model="added_by"
                            size="sm"
                            :placeholder="$t('general_guides.added_by')"
                            type="text"/>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                        <span class="span-title">{{ $t('general_guides.added_by_company') }}</span>
                        <b-input
                            id="company"
                            v-model="companyName"
                            size="sm"
                            :placeholder="$t('general_guides.added_by_company')"
                            type="text"/>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="start"
                    >
                        <span class="span-title">{{ $t('common.from') }}</span>
                        <b-form-input
                            id="start"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="end"
                    >
                        <span class="span-title">{{ $t('common.to') }}</span>
                        <b-form-input
                            id="end"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                        <span class="span-title">{{ $t('general_guides.search_mode') }}</span>
                        <b-form-radio-group
                            id="radio-group-1"
                            v-model="searchMode"
                            :options="modeOptions"
                            name="radio-options"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    id="submit"
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    id="reset"
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"

                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    mixins: [timeUtils],
    name: "GeneralGuidesSearch",
    data: function () {
        return {
            name: '',
            description: '',
            added_time: null,
            added_by: '',
            fromDate: null,
            toDate: null,
            companyName: '',
            searchMode: 1,
            modeOptions: [
                { text: this.$t('general_guides.shared_to'), value: 1 },
                { text: this.$t('general_guides.shared_by'), value: 2 },
            ]
        }
    },

    methods: {
        onSubmit: function () {
            let params = {searchMode: this.searchMode}
            if (this.name) {
                params.name = this.name
            }
            if (this.description) {
                params.description = this.description
            }
            if (this.added_by) {
                params.added_by = this.added_by
            }
            if (this.companyName) {
                params.companyName = this.companyName
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.from = this.getStartOfTheDay(this.fromDate)
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.to = this.getEndOfTheDay(this.toDate)
            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.name = ''
            this.description = ''
            this.added_by = ''
            this.fromDate = null
            this.toDate = null
        }
    }
}
</script>

