<template>
    <div>
        <span class="item-title pl-3 pt-2">{{ $t('import_file_wizard.choose_file_to_import') }}</span>
        <b-form-group class="title">
            <shape-file-picker
                :label="$t('import_file_wizard.shp_file_attachment_placeholder')"
                :extension="getShpFileExtension()"
                @input="shpFilePicked">
            </shape-file-picker>
            <shape-file-picker
                :label="$t('import_file_wizard.shx_file_attachment_placeholder')"
                :extension="getShxFileExtension()"
                @input="shxFilePicked">
            </shape-file-picker>
            <shape-file-picker
                :label="$t('import_file_wizard.dbf_file_attachment_placeholder')"
                :extension="getDbfFileExtension()"
                @input="dbfFilePicked">
            </shape-file-picker>
        </b-form-group>
    </div>
</template>

<script>
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import ShapeFilePicker from "./ShapefilePicker";

// From: https://epsg.io/?q=Finland
export const CRSTypes = {
    Epsg3067: {EpsgCode: 3067, title: 'ETRS89 / TM35FIN(E,N)'},
    Epsg4326: {EpsgCode: 4326, title: 'WGS 84 - WGS84'},
}

export default {
    name: 'shape-import-form' ,
    components: {ShapeFilePicker},
    mixins: [
        attachmentHelper,
        mapHelper,
        restApi
    ],
    props: {
        coordinate: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            allFilesGiven: false,
            fileReady: false,
            shpFile: null,
            shxFile: null,
            dbfFile: null,
            crs: '',
            crs_selection_visible: false
        }
    },
    watch: {
        'shpFile': {
            handler: function (newValue) {
                this.allFilesGiven = (newValue !== null && this.dbfFile !== null && this.shxFile !== null)
           }
        },
        'shxFile': {
            handler: function (newValue) {
                this.allFilesGiven = (this.shpFile !== null && this.dbfFile !== null && newValue !== null)
            }
        },
        'dbfFile': {
            handler: function (newValue) {
                this.allFilesGiven = (this.shpFile !== null && newValue !== null && this.shxFile !== null)
            }
        }
    },
    methods: {
        getCRSTypes: function () {
            return [
                {name: CRSTypes.Epsg3067.title, value: CRSTypes.Epsg3067.EpsgCode},
                {name: CRSTypes.Epsg4326.title, value: CRSTypes.Epsg4326.EpsgCode}
            ]
        },
        getShpFileExtension:function (){
            return ".shp"
        },
        getShxFileExtension: function (){
            return ".shx"
        },
        getDbfFileExtension: function (){
            return ".dbf"
        },
        shpFilePicked: function(value){
            this.shpFile = value;
            this.allFilesGiven = (this.shpFile !== null && this.shxFile !== null && this.dbfFile !== null)
            this.$emit('filePicked', this.shpFile, this.shxFile, this.dbfFile)
        },
        shxFilePicked: function(value){
            this.shxFile = value;
            this.allFilesGiven = (this.shpFile !== null && this.shxFile !== null && this.dbfFile !== null)
            this.$emit('filePicked', this.shpFile, this.shxFile, this.dbfFile)
        },
        dbfFilePicked: function(value){
            this.dbfFile = value;
            this.allFilesGiven = (this.shpFile !== null && this.shxFile !== null && this.dbfFile !== null)
            this.$emit('filePicked', this.shpFile, this.shxFile, this.dbfFile)
        }
    }
}
</script>
<style>
@import "../../styles/modal.css";
</style>
