<template>
    <div
        id="editor"
        class="col-sm-12 nopads"
    >
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-data">
                <h4 class="form-header">{{ $t('contracts.basic_info')}}</h4>

                <coordinate-editor
                    v-if="showLocation"
                    v-model="location"
                    size="sm"
                    @setLocation="saveLocation"
                    @close="saveLocation(null)"
                    :point-type="OTHER"
                    :title="$t('material_station.location')"
                />

                <b-row class="nopads">
                <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">
                          {{ $t('contract_user_reports.choose_date') }}
                        </span>
                    <datetime
                        v-model="dangerDate"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>

                <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">
                          {{ $t('danger_report.select_event_type') }}
                        </span>
                    <b-form-select
                        id="danger_type"
                        v-model="danger_type"
                        size="sm"
                        >
                        <option :value="null" :disabled="true">
                            {{ $t('danger_report.danger_type') }}
                        </option>
                        <option
                            v-for="result in danger_types"
                            :key="result"
                            :value="result"
                        >
                            {{ getDangerTypeName(result) }}
                        </option>
                    </b-form-select>

                </div>
                </b-row>

                <open-contract-filter
                    v-if="!selectedContract"
                    :from="fromDate"
                    :to="toDate"
                    :contract="contract"
                    :showOnlyMyContractsOption="true"
                    :show-title="true"
                    :show-company="false"
                    @contractSelected="setContract"/>

                <span class="span-title">{{ $t('common.description') }}</span>
                <b-form-textarea
                    id="info"
                    v-model="description"
                    :rows="3"
                    :max-rows="6"
                />

                <span class="span-title">{{ $t('danger_report.description_conditions') }}</span>
                <b-form-textarea
                    id="info"
                    v-model="cause"
                    :rows="3"
                    :max-rows="6"
                />

                <span class="span-title">{{ $t('danger_report.proposal_for_preventive_actions') }}</span>
                <b-form-textarea
                    id="info"
                    v-model="prevent"
                    :rows="3"
                    :max-rows="6"
                />

                <div class="col-md-12 nopads p-1">
                        <span class="span-title">
                          {{ $t('danger_report.consequences.title') }}
                        </span>
                    <b-form-select
                        id="consenquence_type"
                        v-model="consenquence_type"
                        size="sm"
                    >
                        <option :value="null" :disabled="true">
                            {{ $t('danger_report.consequences.select') }}
                        </option>
                        <option
                            v-for="result in consenquence_types"
                            :key="result"
                            :value="result"
                        >
                            {{ getDangerConsequencesName(result) }}
                        </option>
                    </b-form-select>
                </div>

                <span class="span-title">{{ $t('danger_report.consequences.info') }}</span>
                <b-form-textarea
                    id="info"
                    v-model="conse_description"
                    :rows="3"
                    :max-rows="6"
                    :placeholder="$t('')"
                />

                <!--Todo allow easily remove photos-->
                <b-row class="nopads">
                    <span class="span-title pl-1">{{ $t('vehicle_editor.add_photo') }}</span>
                    <div
                        v-for="(path, counter) in photos"
                        :key="counter"
                        class="col-sm-12 nopads"
                    >
                        <b-form-group
                            class="title"
                            label-for="counter"
                            v-if="counter === 0 || isAttachmentSet(photos, counter-1)"
                        >
                            <b-form-file
                                v-model="photos[counter]"
                                size="sm"

                                accept="image/jpeg, image/jpg, image/png"
                                :placeholder="$t('common.photo_placeholder')"
                            />
                        </b-form-group>
                    </div>
                </b-row>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
                          {{ $t('common.cancel') }}
                        </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitReport"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>

            </div>

        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from "@/components/mixins/MapMixin";
import CoordinateEditor from "@/components/CoordinateEditor.vue";
import {dangerHelper} from "@/components/mixins/DangerMixin";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin"
import OpenContractFilter from "@/components/contract/OpenContractFilter.vue";

export default {
    name: 'DangerReportForm',
    components: {OpenContractFilter, CoordinateEditor},
    mixins: [restApi, timeUtils, mapHelper, attachmentHelper, dangerHelper],
    props: {
        coordinates: {
            type: Object,
            default: null
        },
        showLocation: {
            type: Boolean,
            default() {
                return false
            }
        },
        selectedContract: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        showReport: Boolean
    },
    data: function () {
        return {
            fromDate: null,
            toDate: null,
            contract: null,
            location: this.coordinates === null ? {"y": 63.6, "x": 26.5} : this.coordinates,
            loading: false,
            photos: new Array(5),
            dangerDate: null,
            prevent: null,
            cause: null,
            conse_description: null,
            description: null,
            danger_types: [
                1,
                2,
                3
            ],
            danger_type: null,
            consenquence_types: [
                1,
                2,
                3,
                4,
                5
            ],
            consenquence_type: null,
            dangerReportUpdate: null
        }
    },

    mounted: function () {
        if (!this.fromDate) {
            this.fromDate = this.getMonthStart()
        }
        if (this.selectedContract) {
            this.contract = this.selectedContract
        } else {
            this.fetchContracts()
        }
        this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
    },

    methods: {

        setContract: function (contract) {
            this.contract = contract ? contract : null
            this.getContractCompanies()
        },

        getContractCompanies: function () {
            this.companyOptions = []
            this.companyParam = null
            this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
            if (this.contract) {
                this.restFetchParams(this.contractCompanyUrl, {contract: this.contract.id}, this.handleContractCompanies)
            }
        },

        handleContractCompanies: function (response) {
            if (response && response.data) {
                this.companyPlaceholder = this.$t('')
                response.data.forEach(company => {
                    this.companyOptions.push({name: company.name, value: company.id})
                })
                this.companyPlaceholder = this.$t('users.select_company')
            }
            this.loading = false
        },

        fetchContracts: function () {
            this.loading = true
            this.restFetch(this.contractOpenUrl , this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            this.contracts = response.data
        },

        saveLocation: function (loc) {
            const location = {}
            location.lat = loc.y
            location.lon = loc.x
            this.location = location
        },

        getDefaultValues: function () {
            const dangerReport = {}
            if (this.location == null) {
                EventBus.$emit('show-alert', this.$t('power_stations.error_position'))
                return undefined
            }
            if (this.dangerDate === null || !this.dangerDate) {
                EventBus.$emit('show-alert', this.$t('meal_allowance.error_date_missing'))
                return undefined
            }
            if (this.danger_type === null) {
                EventBus.$emit('show-alert', this.$t('danger_report.select_event_type'))
                return undefined
            }
            if (this.contract === null) {
                EventBus.$emit('show-alert', this.$t('ins_wizard.select_contract'))
                return undefined
            }
            if (this.description === null) {
                EventBus.$emit('show-alert', this.$t('danger_report.description_missing'))
                return undefined
            }
            if (this.location) {
                dangerReport.location = this.location
            }
            if (this.dangerDate) {
                dangerReport.event_time = this.dangerDate
            }
            if (this.danger_type) {
                dangerReport.event_type = this.danger_type
            }
            if (this.description) {
                dangerReport.description = this.description
            }
            if (this.conse_description) {
                dangerReport.description_conditions = this.conse_description
            }
            if (this.prevent) {
                dangerReport.proposal_for_preventive_actions = this.prevent
            }
            if (this.cause) {
                dangerReport.cause = this.cause
            }
            if (this.consenquence_type) {
                dangerReport.consenquence_type = this.consenquence_type
            }
            if (this.contract) {
                dangerReport.contract = {id: this.contract.id}
            }
            return dangerReport
        },

        submitReport: function () {
            const jsonDangerReport = this.getDefaultValues();
            if (jsonDangerReport) {
                this.loading = true
                this.restAdd(this.dangerReportUrl, jsonDangerReport, this.success, this.fail)
            }
        },

        success: function (response) {
            let photos = this.getValidAttachments(this.photos)
            if (photos.length > 0) {
                this.uploadPhoto(photos, response.id)
            } else {
                this.uploadCompleted()
            }
        },

        resetData: function () {
                this.contract = null
                this.location = null
                this.photos = new Array(5)
                this.dangerDate = null
                this.prevent = null
                this.cause = null
                this.conse_description = null
                this.description = null
                this.danger_type = null
                this.consenquence_type = null
                this.dangerReportUpdate = null
                this.$emit('close')
        },

        uploadCompleted: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_add_success'), 'success')
            this.resetData()
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        uploadPhoto: function (photos, id) {
            if (photos.length > 0) {
                this.loading = true
                let formData = new FormData()
                photos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                let url = this.dangerReportUrl + "/" + id + "/photo"
                this.restPostWithFile(url, {}, formData, this.photoSuccess, this.fail)
            }
        },

        photoSuccess: function () {
            this.loading = false
            this.resetData()
            EventBus.$emit('show-alert', this.$t('common.alert_add_success'), 'success')
        }
    }
}
</script>
