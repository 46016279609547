<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
        <span class="title">
          {{ $t('vehicle_allocation.title') }}
        </span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <keep-alive>
                <vehicle-allocation-search
                    v-if="!editMode"
                    @search="fetchVehicleAllocationReports"
                />
            </keep-alive>
                <div class="button-container">
                    <b-button
                        variant="outline-primary"
                        class="result-button dont-print"
                        @click="showCharts = !showCharts"
                    >
                        {{ showCharts ? $t('daily_summary.hide_graphs') : $t('daily_summary.show_graphs') }}
                    </b-button>
                </div>
                <vehicle-allocation-charts
                    v-show="showCharts"
                    ref="charts"
                    :results="results"
                />
            <transition name="fade">
                <keep-alive key="charts-alive">
                    <vehicle-allocation-list
                        v-if="!editMode"
                        :items="results"
                        key="list"
                        :params="lastSearchedParams"
                        :is-admin="isAdmin"
                    />
                </keep-alive>
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import VehicleAllocationList from './VehicleAllocationList'
import VehicleAllocationSearch from './VehicleAllocationSearch'
import VehicleAllocationCharts from './VehicleAllocationCharts'
import {restApi} from '../../mixins/RestApiMixin'
import {vehicleHelper} from '../../mixins/VehicleMixin'

export default {
    name: 'VehicleAllocation',
    components: {VehicleAllocationList, VehicleAllocationSearch, VehicleAllocationCharts},
    mixins: [restApi, vehicleHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            results: [],
            defect: null,
            editMode: false,
            showCharts: true,
            loading: false,
            vehicles: [],
            lastSearchedParams: null
        }
    },
    methods: {
        fetchVehicleAllocationReports: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.vehicleAllocationSummaryUrl, this.lastSearchedParams, this.handleResponse, this.handleError)
        },

        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },

        handleError: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
        },

        showList: function () {
            this.editMode = false
        },

    }
}
</script>

<style scoped>

</style>
