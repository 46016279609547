<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <open-contract-filter
                :to="toDate"
                :from="fromDate"
                :show-company="false"
                @loading="loading=true"
                @loadingCompleted="loading=false"
                @contractSelected="setContract"
            />
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'StationOrderSearch',
    components: {OpenContractFilter},
    mixins: [timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            customer: null,
            contractor: null,
            contract: null,
            toDate: null,
            fromDate: null
        }
    },
    mounted: function () {
        this.toDate = this.getStartOfTheDay(new Date()).toISOString()
        this.fromDate = this.getEndOfTheDay(new Date()).toISOString()
    },

    methods: {

        setCustomer: function (customer) {
            this.customer = customer
        },

        setContractor: function (contractor) {
            this.contractor = contractor
        },

        setContract: function (contract) {
            this.contract = contract
        },

        onSubmit: function () {
            var params = {}
            if (this.contract) {
                params.contract = this.contract.id
            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.customer = null
            this.contract = null
            this.contracts = null
            this.$refs.filter.resetData()
        }
    }
}
</script>
