<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-row class="nopads">
                <div class="col-sm-6">
      <span class="search-title">
        {{ $t('ins_report.from_date') }}
      </span>
                    <datetime
                        v-model="fromDate"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>

                <div class="col-sm-6">
      <span class="search-title">
        {{ $t('ins_report.to_date') }}
      </span>
                    <datetime
                        v-model="toDate"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>
            </b-row>
            <b-row class="nopads">
                <div class="col-sm-6">
                    <span class="search-title">{{ $t('trip_list.search_with_road_num') }}</span>
                    <b-form-group
                        class="title nopads"
                        label-for="license_plate"
                    >
                        <b-form-input
                            id="roadInput"
                            v-model="roadNumber"
                            type="number"
                            size="sm"
                            class="no-margin"
                            :placeholder="$t('trip_list.search_with_road_num')"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <span class="search-title">{{ $t('trip_list.search_with_road_name') }}</span>
                    <b-form-group
                        class="title nopads"
                        label-for="roadName"
                    >
                        <b-form-input
                            id="roadNameInput"
                            v-model="roadName"
                            type="text"
                            size="sm"
                            class="no-margin"
                            :placeholder="$t('trip_list.search_with_road_name')"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    variant="primary"
                    class="result-button"
                    @click="onSubmit"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    @click="onReset"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'TripSearchWorker',
    mixins: [restApi, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            fromDate: '',
            toDate: '',
            roadNumber: '',
            roadName: '',
            loading: false
        }
    },

    mounted: function () {
        if (!this.fromDate) {
            this.fromDate = this.getMonthStart()
        }
    },

    methods: {

        onReset: function () {
            this.roadNumber = ''
            this.roadName = ''
            this.fromDate = ''
            this.toDate = ''
        },

        onSubmit: function () {
            var params = {}
            params.user = this.user.id
            if (this.roadNumber > 0) {
                params.road_num = this.roadNumber
            }
            if (this.roadName.trim()) {
                params.road_name = this.roadName
            }
            if (this.fromDate) {
                params.from = this.fromDate
            }
            if (this.toDate) {
                params.to = this.toDate
            }
            this.$emit('search', params)
        }
    }
}
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
