<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="vehicle-list"
        class="col-sm-12"
    >
        <div
            class="col-sm-12 invoice-container"
        >
            <h5 class="ml-2 mt-1 mb-1"><b>{{ contract ? contract.contract_name : "" }}</b></h5>
            <b-row class="nopads">
                <b-col cols="2" class="header-title"> {{ $t('orders.contractor') }}</b-col>
                <b-col class="nopads" style="font-size: .9em">{{ contract ? contract.contractor_name : "" }}</b-col>
            </b-row>
            <b-row class="nopads mb-2">
                <b-col cols="2" class="header-title"> {{ $t('contracts.number') }}</b-col>
                <b-col class="nopads" style="font-size: .9em">{{ contract ? contract.contract_number : "" }}</b-col>
            </b-row>
            <b-row class="nopads mb-2">
                <b-col cols="2" class="header-title"> {{ $t('contracts.worksite_key') }}</b-col>
                <b-col class="nopads" style="font-size: .9em">{{ contract ? contract.contract_work_key : "" }}</b-col>
            </b-row>
            <!-- Header Row -->
            <b-row class="col-12 nopads">
                <div class="col-4 nopads single-line text-center text-bold pr-4">
                    <span>{{ $t('orders.task_type') }}</span>
                </div>
                <div class="col-3 nopads single-line text-center text-bold">
                    <span>{{ $t('areas.time') }}</span>
                </div>
                <div class="col-2 nopads single-line text-center text-bold pl-1">
                    <span>{{ $t('contracts.unit') }}</span>
                </div>
                <div class="col-1 nopads single-line text-center text-bold pl-2">
                    <span>{{ $t('additional_cost.unit_price') }}</span>
                </div>
                <div class="col-2 nopads single-line text-center text-bold pl-2">
                    <span>{{ $t('daily_summary.cost') }}</span>
                </div>
            </b-row>
            <!-- Orders -->
            <invoicing-order-item
                v-for="order in contract.orders"
                v-bind:key="order.id"
                :order="order"
                :show-all="false"
                :invoice-state-options="invoiceStateOptions"
                :user="user"
                :text-col-width-percentage="100"
                @showOrder="emitShowOrder"
                @showTripOrder="emitTripOrder"
                @showContractDetails="emitContractDetails"
                @showTripDetails="emitTripDetails"
                @updateSummaries="emitSummaries"
                @showTripDeliveries="emitTripDeliveries"
                @orderUpdated="$emit('orderUpdated')"
            />
            <!-- Total sum row -->
            <b-row class="col-12 nopads" style="border-top: 1px solid #7FC000">
                <div class="col-10" style="padding-left: 3.8em; text-transform: uppercase">{{ $t('common.total')}}</div>
                <div  class="col-2 pr-1 nopads text-center" style="text-transform: uppercase">{{ totalCost.toFixed(2) }}</div>
            </b-row>
        </div>
        <div class="text-right mt-3">
            <button
                class="btn btn-success large"
                @click.stop="acceptInvoicing"
            >
                {{ $t('work_permission.accept') }}
            </button>
        </div>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {priceHelper} from '@/components/mixins/PricingMixin'
import {timeUtils} from '../mixins/TimeUtils'
import InvoicingOrderItem from "@/components/invoice/InvoicingOrderItem.vue";

export default {
    name: 'InvoicingContractTable',
    components: {InvoicingOrderItem},
    mixins: [timeUtils, workManagementHelper, priceHelper],
    props: {
        contract: {
            type: Object,
            default: null
        },
        invoiceStateOptions: {
            type: Array,
            default() {
                return []
            }
        },
        user: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            sortBy: 'task_type',
            sortDesc: false,
            tableItems: [],
            loading: false
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    watch: {
        contract: function () {
            this.initTableItems()
        }
    },
    computed: {
        totalCost: function () {
            let result = 0
            if(this.contract && this.contract.orders && this.contract.orders.length > 0) {
                this.contract.orders.forEach(order => {
                    result += order.cost
                })
            }
            return result
        },
        fields() {
            return [
                {key: 'task_type', label: this.$t('orders.task_type'), sortable: false},
                {key: 'executors', label: this.$t('orders.executor'), sortable: false},
                {key: 'time', label: this.$t('areas.time'), sortable: false},
                {key: 'info', label: this.$t('common.info'), sortable: false},
                {key: 'amount', label: this.$t('contracts.amount'), sortable: false},
                {key: 'pricing_model', label: this.$t('contracts.unit'), sortable: false},
                {key: 'unit_price', label: this.$t('additional_cost.unit_price'), sortable: false},
                {key: 'cost', label: this.$t('daily_summary.cost'), sortable: false},
            ]
        },
    },
    methods: {
        initTableItems() {
            this.tableItems = []
            if (this.contract && this.contract.orders && this.contract.orders.length > 0) {
                this.contract.orders.forEach(item => {
                    this.tableItems.push(item)
                })
            }
        },
        acceptInvoicing() {
            if(this.contract && this.contract.orders && this.contract.orders.length > 0) {
                this.$emit('acceptInvoicing', this.contract)
            }
        },
        emitShowOrder(order) {
            this.$emit('showOrder', order, this.contract)
        },
        emitTripOrder(row) {
            this.$emit('showTripOrder', row)
        },
        emitContractDetails(row) {
            this.$emit('showContractDetails', row)
        },
        emitTripDetails(row) {
            this.$emit('showTripDetails', row)
        },
        emitTripDeliveries(row) {
            this.$emit('showTripDeliveries', row)
        },
        emitSummaries() {
            this.$emit('updateSummaries')
        }
    }
}
</script>

<style>
.header-title {
    font-weight: bold;
    font-size: .9em;
    color: #90b677;
    padding: 0;
    margin: 0;
    margin-right: 1em;
    padding-left: .5em;
}


.invoice-table td,
.invoice-table th {
    font-size: .9em;
    border: none;
    border-bottom: 1px solid #dee2e6;
}

.invoice-table thead th div {
    font-weight: bold;
}

.invoice-container {
    background: #FFFFFF;
    border-radius: .5em;
    border: 1px #d9d9d9;
    padding: .5em;
    box-shadow: #d9d9d9 1px 1px 4px;
}

</style>
