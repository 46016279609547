<template>
    <div
        class="details-container"
    >
        <div
            class="details dynamic_width">
            <h3 class="pl-1 pt-2">{{ $t('traffic_control.report_title') }}</h3>
            <div>
                <!-- Details -->
                <b-row>
                    <div class="col-sm-4 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.contract') }}</span>
                        <span
                            v-if="report.contract"
                            class="item-detail-text"
                        >
                            {{ report.contract.name }}
                        </span>
                        <span
                            v-else
                            class="item-detail-text"
                        >
                            -
                        </span>
                    </div>
                    <div class="col-sm-4 nopads">
                        <span class="item-detail-title">{{ $t('common.start_date') }}</span>
                        <span class="item-detail-text">{{ toLocalTime(report.start_time) }}</span>
                    </div>
                    <div class="col-sm-4 nopads">
                        <span class="item-detail-title">{{ $t('common.end_date') }}</span>
                        <span class="item-detail-text">{{ toLocalTime(report.end_time) }}</span>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-4 nopads">
                        <span class="item-detail-title">{{ $t('map.road_number') }}</span>
                        <span class="item-detail-text">{{ report.road_number ? report.road_number : "-" }}</span>
                    </div>
                    <div class="col-sm-4 nopads">
                        <span class="item-detail-title">{{ $t('road_sign.sec_num') }}</span>
                        <span class="item-detail-text">{{ report.road_section ? report.road_section : "-" }}</span>
                    </div>
                    <div class="col-sm-4 nopads">
                        <span class="item-detail-title">{{ $t('map.road_name') }}</span>
                        <span class="item-detail-text">{{ report.road_name ? report.road_name : "-" }}</span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.description') }}</span>
                        <span class="item-detail-text">{{ report.description ? report.description : '-' }}</span>
                    </div>
                </b-row>

                <div class="button-container col-sm-12 trip-buttons">
                    <b-button
                        variant="secondary"
                        class="form-button"
                        @click.stop="close"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                </div>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from "@/event-bus"
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'TrafficControlReportDetails',
    components: {},
    mixins: [restApi, timeUtils],
    props: {
        report: {
            type: Object,
            default: null
        },
        isObserver: Boolean
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        close: function () {
            this.$emit('close')
        },

        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
    }
}
</script>

<style scoped>
</style>

