<template>
    <div ref="container" class="content-container">
        <contract-calendar :user="user"/>
    </div>
</template>


<script>
import ContractCalendar from "@/components/contractcalendar/ContractCalendar";

export default {
    name: 'ContractCalendarContainer',
    components: {ContractCalendar},
    props: {
        user: {
            type: Object,
            default: null
        }
    }
}
</script>
