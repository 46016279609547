<template>
    <div class="col-sm-12 editor pt-2">
        <div class="col-sm-12 editor-form pb-4">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    class="form-title editor-title"
                >
                    {{ $t('menu.price_lists') }}
                </h2>
            </div>
            <b-tabs justified v-model="tabIndex">
                <b-tab active :title-link-class="linkTabClass(0)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 0}">
                                {{ $t('pricing_models.weight_distance_price_lists') }}
                            </div>
                        </b-row>
                    </template>
                    <weight-distance-price-lists
                        ref="weightRangeLists"
                        @add="addPriceList"
                        @edit="editPriceList"
                    />
                </b-tab>
                <b-tab :title="$t('price_lists.area_prices')" :title-link-class="linkTabClass(1)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 1}">
                                {{ $t('pricing_models.area_price_lists') }}
                            </div>
                        </b-row>
                    </template>
                    <area-price-lists
                        ref="areaLists"
                        @add="addAreaPriceList"
                        @edit="editAreaPriceList"
                    />
                </b-tab>
            </b-tabs>
            <transition name="fade">
                <b-modal
                    v-model="wdEdit"
                    body-class="fill-modal"
                    size="xl"
                    :hide-header="true"
                    :hide-footer="true"
                    :cancel-disabled="true"
                    @cancel="priceList=null"
                >
                    <price-list-editor
                        v-if="priceList"
                        :price-list="priceList"
                        @close="priceList = null"
                        @closeAndUpdate="updateWeightDistanceLists()"
                    />
                </b-modal>
            </transition>
            <transition name="fade">
                <b-modal
                    v-model="areaEdit"
                    body-class="fill-modal"
                    size="xl"
                    :hide-header="true"
                    :hide-footer="true"
                    :cancel-disabled="true"
                    @cancel="areaPriceList=null"
                >
                    <area-price-list-editor
                        v-if="areaPriceList"
                        :price-list="areaPriceList"
                        @close="areaPriceList = null"
                        @closeAndUpdate="updateAreaLists()"
                    />
                </b-modal>
            </transition>
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {priceHelper} from "../../mixins/PricingMixin";
import WeightDistancePriceLists from "@/components/pricingmodels/pricelists/WeightDistancePriceLists";
import AreaPriceLists from "@/components/pricingmodels/pricelists/AreaPriceLists";
import PriceListEditor from "@/components/pricingmodels/pricelists/PriceListEditor";
import AreaPriceListEditor from "@/components/pricingmodels/pricelists/AreaPriceListEditor";

export default {
    name: 'PriceLists',
    components: {AreaPriceListEditor, PriceListEditor, AreaPriceLists, WeightDistancePriceLists},
    mixins: [restApi, priceHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            priceList: null,
            areaPriceList: null,
            tabIndex: 0,
        }
    },
    computed: {
        wdEdit() {
            return this.priceList != null
        },
        areaEdit() {
            return this.areaPriceList != null
        }
    },
    methods: {
        linkTabClass(index) {
            if (this.tabIndex === index) {
                return ['tab-title', 'tab-title-active']
            } else {
                return ['tab-title']
            }
        },
        updateWeightDistanceLists() {
            this.priceList = null
            this.$refs.weightRangeLists.update()
        },
        addPriceList() {
            this.priceList = this.newPriceList()
        },
        editPriceList(priceList) {
            this.priceList = priceList
        },
        updateAreaLists() {
            this.areaPriceList = null
            this.$refs.areaLists.update()
        },
        addAreaPriceList() {
            this.areaPriceList = this.newAreaPriceList()
        },
        editAreaPriceList(priceList) {
            this.areaPriceList = priceList
        }
    }
}
</script>

<style scoped>
.tab-title {
    font-size: .9em;
    border: none !important;
    margin-right: .5em;
    margin-left: .5em;
    color: #949494 !important;
    font-weight: bold;
}

.tab-title:hover {
    color: #007BFF;
}

.tab-title-active {
    border-bottom: 2px solid #7FC000 !important;
}

.tab-title-text {
    color: #949494 !important;
    font-weight: bold;
}


.tab-title-text-active {
    color: #000000 !important;
    font-weight: bold;
}


.tab-title-cont {
    height: 2.5em !important;
    padding-top: 1em;
    padding-bottom: 1em;
}

</style>
