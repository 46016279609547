<template>
    <div class="mobile_app">
        <div
            v-if="error"
            class="col-sm-12"
        >
            <h2 class="warning">{{ error }}</h2>
        </div>
        <div
            v-else
            class="col-sm-12 nopads"
        >
            <div
                v-if="vehicle && !inspectMode"
                class="col-sm-12 form-title editor-title"
            >
                <span class="form-sub-title">{{ vehicle.make }} {{ vehicle.vehicle_model }} - {{
                        vehicle.license_plate
                    }}</span>
            </div>
            <vehicle-inspector
                v-if="inspectMode && vehicle && inspectionTemplate"
                :vehicle="vehicle"
                :inspection-template="inspectionTemplate"
                @close="showTemplateList"
                @closeAndUpdate="closeAndUpdate"
            />
            <form v-else>
        <span
            v-if="this.inspectionTemplateList && this.inspectionTemplateList.length < 1"
            class="form-sub-title warning"
        >
          {{ $t('ins_report.no_templates') }}
        </span>
                <div
                    v-else
                    class="ins-list-item"
                    v-for="item in inspectionTemplateList"
                    :key="item.id"
                    @click.stop="openInspectionView(item)"
                >
        <span>
          {{ item.name }}
        </span>
                </div>
            </form>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import VehicleInspector from './VehicleInspector'
import RoutaMobile from '../../modules/RoutaMobile'


export default {
    name: 'InspectVehicle',
    components: {VehicleInspector},
    mixins: [restApi],
    data() {
        return {
            routaMobile: null,
            vehicle: null,
            inspectionTemplateList: [],
            inspectionTemplate: null,
            inspectMode: false,
            error: null,
            loading: true
        }
    },
    mounted() {
        this.routaMobile = new RoutaMobile()
        let token = this.routaMobile.getToken();
        if (token) {
            this.$store.commit('storeToken', token)
            this.vehicle = this.routaMobile.getVehicle()
            if (this.vehicle) {
                this.fetchInspectionTemplate()
            } else {
                this.error = 'No vehicle'
                this.loading = false
            }
        } else {
            this.error = 'No token'
            this.loading = false
        }
    },
    methods: {
        fetchInspectionTemplate: function () {
            this.loading = true
            this.restFetch(this.inspectionTemplateUrl + '?vehicle_type=' + this.vehicle.vehicle_type, this.handleInsTemplateResponse)
        },
        handleInsTemplateResponse: function (response) {
            let data = response.data
            this.loading = false
            if (data !== undefined && data.length > 0) {
                this.inspectionTemplateList = data
            } else {
                this.inspectionTemplate = null
            }
        },
        openInspectionView: function (template) {
            this.routaMobile.onClick()
            this.inspectMode = true
            this.inspectionTemplate = template
        },
        showTemplateList: function () {
            this.routaMobile.onClick()
            this.inspectMode = false
        },
        closeAndUpdate: function () {
            this.routaMobile.onClick()
            this.inspectMode = false
            this.routaMobile.close()
        }
    }
}
</script>

<style>
@import "../../styles/mobile_app.css";
</style>
