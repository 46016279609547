<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >

            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="in_use"
                    >
            <span class="span-title">
              {{ $t('vehicle_list.in_use') }}
            </span>
                        <b-form-select
                            id="in_use"
                            v-model="in_use"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option :value="0">
                                {{ $t('vehicle_list.not_in_use') }}
                            </option>
                            <option :value="1">
                                {{ $t('vehicle_list.in_use') }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="vehicle_type"
                    >
            <span class="span-title">
              {{ $t('vehicle_list.type') }}
            </span>
                        <b-form-select
                            id="vehicle_type"
                            v-model="vehicle_type"
                            size="sm"
                            class="sm-3"
                        >
                            <option :value="-1">
                                {{ $t('orders.select_status') }}
                            </option>
                            <option
                                v-for="item in vehicle_types"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.text }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="license_plate"
                    >
            <span class="span-title">
              {{ $t('ins_report.list_label.license_plate') }}
            </span>
                        <b-form-input
                            id="license_plate"
                            type="text"
                            v-model="license_plate"
                            size="sm"
                            class="sm-3"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>

import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'VehicleSearch',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            vehicle_type: -1,
            in_use: 1,
            license_plate: null,
            vehicle_types: [
                {value: 1, text: this.$t('vehicle_list.vehicle_type.passenger_or_van')},
                {value: 2, text: this.$t('vehicle_list.vehicle_type.truck')},
                {value: 3, text: this.$t('vehicle_list.vehicle_type.trailer')},
                {value: 4, text: this.$t('vehicle_list.vehicle_type.trailer1')},
                {value: 5, text: this.$t('vehicle_list.vehicle_type.loader')},
                {value: 6, text: this.$t('vehicle_list.vehicle_type.tractor')},
                {value: 11, text: this.$t('vehicle_list.vehicle_type.snowplow')},
                {value: 7, text: this.$t('vehicle_list.vehicle_type.spreader')},
                {value: 8, text: this.$t('vehicle_list.vehicle_type.harvester')},
                {value: 9, text: this.$t('vehicle_list.vehicle_type.forwarder')},
                {value: 12, text: this.$t('vehicle_list.vehicle_type.helicopter')},
                {value: 13, text: this.$t('vehicle_list.vehicle_type.paver')},
                {value: 14, text: this.$t('vehicle_list.vehicle_type.road_fence')},
                {value: 15, text: this.$t('vehicle_list.vehicle_type.shock_absorber')},
                {value: 16, text: this.$t('vehicle_list.vehicle_type.digger')},
                {value: 10, text: this.$t('vehicle_list.vehicle_type.other')}
            ]
        }
    },
    methods: {
        onSubmit: function () {
            var params = {}
            if (this.in_use !== -1) {
                params.in_use = this.in_use
            }
            if (this.vehicle_type !== -1) {
                params.type = [this.vehicle_type]  //Backend requires array
            }
            if (this.license_plate !== -1) {
                params.license_plate = this.license_plate
            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.vehicle_type = -1
            this.in_use = -1
            this.license_plate = null
        }
    }
}
</script>

<style scoped>

</style>
