<template>
    <div class="col-sm-12 list-content">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="box-open"
                    class="card-body-icon"
                />
            </div>
        </div>
        <transition name="fade">
            <div
                v-if="materials && materials.length"
                class="col-sm-12 data-container"
            >
                <div
                    v-for="material of materials"
                    :key="material.id"
                    class="data-list-item"
                    @click.stop="$emit('edit', material)"
                >
                    <div class="col-sm-12 item-header">
                        <div class="item-img-cont">
                            <font-awesome-icon icon="box-open"/>
                        </div>
                        <div class="item-text-cont">
              <span class="item-title-single">
                {{ material.name }}
              </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "MaterialTypeList",
    props: {
        materials: {
            type: Array,
            default: function () {
                return []
            }
        },
        isAdmin: Boolean
    }
}
</script>
