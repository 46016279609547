<template>
    <div v-if="trafficControlItems && trafficControlItems.length > 0" class="nopads">
        <b-row>
            <b-col class="nopads report-header" cols="10">
                <span class="section-title" style="display: block">{{ $t('traffic_control.report_title') }} ({{trafficControlItems.length}})</span>
            </b-col>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : reportsVisible}"
                    v-b-toggle.trafficControlDetails
                    @click.stop="reportsVisible = !reportsVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            v-if="reportsVisible"
            id="trafficControlDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <traffic-control-report-list
                :results="trafficControlItems"
                @details="showDetails"
            />
        </b-collapse>

        <b-modal
            :visible="trafficControlItem !== null"
            @hidden="trafficControlItem = null"
            body-class="fill-modal"
            size="lg"
            :hide-header="true"
            :hide-footer="true"
        >
            <traffic-control-report-details
                v-if="trafficControlItem !== null"
                id="details"
                ref="details"
                :report="trafficControlItem"
                :is-observer="isObserver"
                @close="trafficControlItem = null"
                @closeAndUpdate="closeAndUpdate"
            />
        </b-modal>
        <hr class="report-divider nopads"/>
    </div>
</template>


<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
// import DangerReportList from "@/components/dangerreports/DangerReportList";
import TrafficControlReportList from "@/components/trafficcontrol/TrafficControlReportList";
import TrafficControlReportDetails from "@/components/trafficcontrol/TrafficControlReportDetails";

export default {
    name: "traffic-control-reports",
    mixins: [timeUtils, restApi],
    components: {
        TrafficControlReportDetails,
        TrafficControlReportList
    },
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        isObserver: Boolean,
    },
    data: function () {
        return {
            trafficControlItems: [],
            trafficControlItem: null,
            reportsVisible: false,
        }
    },
    mounted() {
        this.fetchTrafficControlReports()
    },
    watch: {
      searchParams () {
          this.fetchTrafficControlReports()
      }
    },
    methods: {
        fetchTrafficControlReports: function () {
            this.trafficControlItems = []
            if (this.searchParams) {
                let mappedParams = {
                    contract: this.searchParams.contract,
                    start_time: this.searchParams.dateFrom ? this.getDateIsoString(new Date(this.searchParams.dateFrom)) : null,
                    end_time: this.searchParams.dateTo ? this.getDateIsoString(new Date(this.searchParams.dateTo)) : null,
                }
                this.$emit('loading', true)
                this.restFetchParams(this.trafficControlUrl, mappedParams, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response) {
                this.trafficControlItems = response.data
            }
            this.$emit('loading', false)
        },
        showDetails(data) {
            this.trafficControlItem = data
        },
        closeAndUpdate() {
            this.trafficControlItem = null
            this.reportsVisible = false
            this.fetchTrafficControlReports()
        }
    }
}
</script>
