<template>
    <div class="col-12 nopads">
        <div v-if="selectedStorages.length < 1" class="col-sm-12 nopads" style="font-style: italic">
            <span>
                {{ $t('contracts.no_material_storages') }}
            </span>
        </div>
        <div class="col-sm-12 nopads">
            <multiselect
                v-model="selectedStorages"
                class="multiselect_routa"
                :placeholder="$t('contracts.select_storage')"
                :deselect-label="$t('common.remove_selection')"
                :select-label="$t('common.select')"
                :close-on-select="false"
                :multiple="true"
                :disabled="!editable"
                open-direction="bottom"
                label="name"
                track-by="name"
                :searchable="true"
                :internal-search="true"
                :clear-on-select="false"
                :selected-label="$t('common.selected')"
                :options="storages"
            >
                <template
                    slot="singleLabel"
                    slot-scope="props"
                >
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
                </template>
                <template
                    slot="option"
                    slot-scope="props"
                >
                    <div class="option__desc">
                        <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                    </div>
                </template>
                <span slot="noResult">{{ $t('common.not_found') }}</span>
                <span slot="noOptions">{{ $t('common.no_results') }}</span>
            </multiselect>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from "../mixins/TimeUtils";

export default {
    name: 'ContractMaterialStorages',
    mixins: [restApi, timeUtils],
    props: {
        value: {
            type: Array,
            default: function () {
                return []
            }
        },
        contract: {
            type: Object,
            default: null
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            loading: false,
            storages: [],
            selectedStorages: this.value ? this.value : []
        }
    },
    watch: {
        selectedStorages: function () {
            this.$emit('input', this.selectedStorages)
        }
    },
    mounted: function () {
        this.fetchMaterialStorages()
        this.fetchContractMaterialStorages()
    },
    methods: {
        fetchMaterialStorages: function () {
            this.loading = true
            this.storages = []
            let now = new Date()
            this.restFetchParams(this.materialStorageUrl,
                {
                    startDate: now.toISOString()
                },
                this.handleMaterialStorages)
        },
        handleMaterialStorages: function (results) {
            this.loading = false
            if (results.data) {
                this.storages = results.data
            }
        },
        fetchContractMaterialStorages: function () {
            this.loading = true
            this.selectedStorages = []
            this.restFetchParams(this.materialStorageContractsUrl, {
                contract: this.contract.id
            }, this.handleContractMaterialStoragesResponse)
        },
        handleContractMaterialStoragesResponse: function (response) {
            if (response != null) {
                this.selectedStorages = response.data
                this.loading = false
            }
        },
        isSelected: function (id) {
            return this.selectedStorages.find(item => item.id === id) !== undefined
        },
        updateSelection: function (contract) {
            if (this.editable) {
                let index = this.selectedStorages.findIndex(item => item.id === contract.id)
                if (index >= 0) {
                    this.selectedStorages.splice(index, 1)
                } else {
                    this.selectedStorages.push(contract)
                }
            }
        }
    }

}
</script>
