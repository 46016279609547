<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('menu.additional_cost') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
                <span class="pointer">
                  <i class="fa fa-arrow-left"/>
                </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <keep-alive>
                <transition
                    v-if="!editMode"
                    name="fade">
                    <additional-cost-search
                        :user="user"
                        :is-admin="isAdmin"
                        @search="searchWithParams"/>
                </transition>
            </keep-alive>

            <div
                v-if="!editMode"
                class="button-container pr-3"
            >
                <b-button
                    variant="success"
                    class="result-button"
                    @click="addItem"
                >
                    {{ $t('common.add_new') }}
                </b-button>
            </div>
            <transition
                name="fade"
                v-if="!editMode"
            >
                <div class="pl-3 pr-3">
                    <div class="list-content result-table">
                        <additional-cost-list
                            :results="results"
                            @rowClicked="editItem"/>
                    </div>
                </div>
            </transition>
            <transition
                name="fade"
            >
                <additional-cost-editor
                    v-if="editMode"
                    :additional-cost="result"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    @closeAndUpdate="searchWithParams"
                    @close="showList"
                />
            </transition>

        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import AdditionalCostSearch from "@/components/additionalcost/AdditionalCostSearch";
import {additionalCostHelper} from "@/components/mixins/AdditionalCostMixin";
import AdditionalCostEditor from "@/components/additionalcost/AdditionalCostEditor";
import AdditionalCostList from "@/components/additionalcost/AdditionalCostList";

export default {
    name: 'additional-costs',
    components: {AdditionalCostList, AdditionalCostEditor, AdditionalCostSearch},
    mixins: [restApi, timeUtils, additionalCostHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean,
        isWorker: Boolean,
    },
    data: function () {
        return {
            loading: false,
            searchParams: null,
            editMode: false,
            results: [],
            result: null
        }
    },
    methods: {
        searchWithParams: function (params) {
            this.results = []
            this.loading = true
            this.searchParams = params || []
            this.restFetchParams(this.additionalCostUrl, params, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
            this.showList()
        },
        showList: function () {
            this.editMode = false
        },
        editItem: function (item) {
            this.result = item
            this.editMode = true
        },
        addItem: function () {
            this.result = null
            this.editMode = true
        }
    }
}
</script>
