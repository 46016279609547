<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
        >
            <div class="header">
                <span class="title">{{ $t('user_alert.alerts_title') }}</span>
            </div>
        </b-navbar>

        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-row>
                <div class="col-sm-4">
                    <span class="search-title">{{ $t('contracts.status') }}</span>
                    <b-form-checkbox
                        v-model="open"
                        size="md"
                        type="text"
                        value=0
                        unchecked-value=1>
                        {{ $t('contracts.only_open') }}
                    </b-form-checkbox>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                    @click.stop="fetchAlerts"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
            </div>
        </div>


        <div class="col-sm-12 nopads">
            <transition name="fade">
                <user-alert-list
                    :results="results"
                    @show="showAlert"
                />
            </transition>
            <user-alert-editor-modal
                v-model="alert"
                @alertResolved="alertResolved"
            />
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../../mixins/RestApiMixin'
import {userHelper} from '../../mixins/UserMixin'
import UserAlertList from './UserAlertList'
import UserAlertEditorModal from './UserAlertEditorModal'
import UserAlert from '@/components/user/alert/UserAlert'
import {EventBus} from "@/event-bus";

export default {
    name: 'UserAlerts',
    components: {UserAlertEditorModal, UserAlertList},
    mixins: [restApi, userHelper],
    data: function () {
        return {
            open: 0,
            results: [],
            loading: true,
            alert: null
        }
    },
    mounted: function () {
        this.fetchAlerts()
    },
    methods: {
        async fetchAlerts() {
            this.loading = true
            try {
                this.results = await UserAlert.fetch(this, parseInt(this.open) === 1)
            } catch (e) {
                // Fetch failed
            }
            this.loading = false
        },
        showAlert: function (alert) {
            if (!alert.resolved_time) {
                this.alert = alert
            } else {
                EventBus.$emit('show-alert', this.$t('user_alert.resolved_edit_error'))
            }
        },
        alertResolved() {
            this.alert = null
            this.fetchAlerts()
        }
    }
}
</script>
