<template>
    <div :class="!oneDayMode ? 'content-container' : ''">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div v-if="!oneDayMode" class="header">
        <span class="title">
          {{ $t('daily_summary.generation_title') }}</span>
            </div>
        </b-navbar>
        <b-form
            class="no-margin"
            @submit.prevent="generateDailySummaries"
            @reset.prevent="onReset"
        >
            <b-row v-if="!oneDayMode">
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="fromDate"
                    >
                        <span class="span-title">{{ $t('trip_list.road_sec_from') }} - {{
                                $t('daily_summary.date')
                            }}</span>
                        <b-form-input
                            id="fromDate"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="toDate"
                    >
                        <span class="span-title">{{ $t('trip_list.road_sec_to') }} - {{
                                $t('daily_summary.date')
                            }}</span>
                        <b-form-input
                            id="date"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <b-row v-if="oneDayMode">
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="fromDate"
                    >
                        <span class="span-title">{{ $t('daily_summary.date') }}</span>
                        <b-form-input
                            id="date"
                            v-model="singleDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <b-row v-if="!oneDayMode">
                <div class="col-sm-6">
                    <open-contract-filter
                        :from="fromDate"
                        :to="toDate"
                        :show-title="true"
                        :show-company="false"
                        @contractSelected="setContract"/>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    v-if="!oneDayMode"
                    type="submit"
                    variant="primary"
                    class="result-button"
                    :disabled="!fromDate||!toDate"
                >
                    {{ $t('daily_summary.generate') }}
                </b-button>
                <b-button
                    v-if="oneDayMode"
                    type="submit"
                    variant="primary"
                    class="result-button"
                    :disabled="!singleDate"
                >
                    {{ $t('daily_summary.generate') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {EventBus} from '@/event-bus'
import OpenContractFilter from "../contract/OpenContractFilter";

export default {
    name: 'contract-trip-summary-generator',
    components: {OpenContractFilter},
    mixins: [restApi, timeUtils],
    props: {
        oneDayMode: {
            type: Boolean,
            default: false
        },
        contractObject: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            fromDate: null,
            toDate: null,
            singleDate: null,
            contract: null
        }
    },

    mounted() {
        if(this.contractObject && this.contractObject.id) {
            this.setContract(this.contractObject)
        }
    },

    methods: {
        generateDailySummaries: function () {
            let params = {}
            if (this.fromDate) {
                params.from = this.fromDate
            }
            if (this.toDate) {
                params.to = this.toDate
            }
            if (this.fromDate > this.toDate) {
                EventBus.$emit('show-alert', this.$t('daily_summary.invalid_date'))
                return undefined
            }
            if (this.contract) {
                params.contract = this.contract.id
            }
            if(this.singleDate) {
                params.single_date = this.singleDate
            }
            this.loading = true
            this.restFetchParams(this.oneDayMode ? this.generateContractDailyTripSummaryUrl + '/single' : this.generateContractDailyTripSummaryUrl, params, this.success, this.fail)
        },
        success: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('daily_summary.generation_success'), 'success')
            this.$emit("closeModal")
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('daily_summary.generation_failed'))
        },
        onReset: function () {
            this.fromDate = null
            this.toDate = null
            this.singleDate = null
        },
        setContract: function (contract) {
            this.contract = contract
        }
    }
}
</script>
