<template>
    <div class="col-12 nopads">
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from '@/components/mixins/MapMixin'

export default {
    name: 'SpreaderDataMap',
    mixins: [mapHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        spreaderData: {
            type: Object,
            default: null
        },
        spreaderAllData: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    watch: {
      map() {
        this.$nextTick(function () {
          this.initView()
        })
      }
    },
    beforeDestroy() {
        this.hideItems()
    },
    mounted() {
        this.initView()
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView() {
            this.map.resizeMap()
            if (this.spreaderData && this.spreaderData.point_data && this.spreaderData.point_data.length > 0) {
                this.spreaderData.point_data.forEach(item => {
                    let icon = this.map.getMarkerStore().getSolidCircleIcon()
                    this.map.showMapMarker(item.id, this.SPREADER_DATA, item.position.y, item.position.x, icon)
                })
                this.map.zoomToPosition(this.spreaderData.point_data[0].position.y, this.spreaderData.point_data[0].position.x, 13)
            } else if(this.spreaderAllData) {
                this.spreaderAllData.forEach(item => {
                    if(item.point_data) {
                        item.point_data.forEach(point => {
                            let icon = this.map.getMarkerStore().getSolidCircleIcon()
                            this.map.showMapMarker(point.id, this.SPREADER_DATA, point.position.y, point.position.x, icon)
                        })
                    }
                })
                this.map.zoomToPosition(this.spreaderAllData[0].point_data[0].position.y, this.spreaderAllData[0].point_data[0].position.x, 13)
            }
        },

        hideItems: function () {
          if (this.map) {
            this.map.removeMapItemsByType(this.SPREADER_DATA)
            this.$emit('close')
          }
        }

    }
}
</script>
