<template>
    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {EventBus} from "@/event-bus";
import {logModes} from "@/components/mixins/VehicleMixin";

export default {
    name: 'MapWorkRoadSnapshot',
    mixins: [restApi, timeUtils, mapHelper, logModes],
    props: {
        roadOwner: null,
        selectedTaskType: {
            type: Number,
            default: null
        },
        selectedTaskTypes: {
            type: Array,
            default() {
                return []
            }
        },
        selectedOrder: {
            type: Number,
            default: null
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        fromDate: {
            type: String,
            default: null
        },
        toDate: {
            type: String,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
        map: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            results: [],
            polyLines: [],
            oldLines: [],
            fromTime: null
        }
    },
    watch: {
       map() {
           this.updateSnapshot()
       },
       selectedContractId() {
          this.updateSnapshot()
       },
       selectedContractIds() {
          this.updateSnapshot()
       },
       selectedTaskType() {
           this.updateSnapshot()
       },
       selectedTaskTypes() {
           this.updateSnapshot()
       },
       selectedOrder() {
           this.updateSnapshot()
       }
    },
    mounted: function () {
        this.updateSnapshot()
    },
    beforeDestroy: function () {
        this.hideSnapshot()
    },

    methods: {
        updateSnapshot() {
            if (this.selectedContract || (this.selectedContractIds && this.selectedContractIds.length > 0) || this.fromDate) {
                this.fetchRecords()
            }
        },

        fetchRecords: function () {
            if (!this.fetching) {
                this.loading = true
                let searchParams = {}
                searchParams.from = this.fromDate
                searchParams.to = this.toDate
                if(this.selectedContractIds) {
                    searchParams.contract_id = this.selectedContractIds
                } else if(this.selectedContractId) {
                    searchParams.contract_id = this.selectedContractId
                }
                if (this.roadOwner) {
                    searchParams.owner = this.roadOwner
                }
                if(this.selectedTaskType) {
                    searchParams.task_type = this.selectedTaskType
                } else if(this.selectedTaskTypes && this.selectedTaskTypes.length > 0) {
                    searchParams.task_types = this.selectedTaskTypes.map(taskType => taskType.value);
                }
                if(this.selectedOrder) {
                    searchParams.order = this.selectedOrder
                }
                this.restFetchParams(this.roadWorkRecordsRoadUrl, searchParams, this.handleResponse, this.handleErrorResponse)
            }
        },

        handleResponse: function (response) {
            this.loading = false
            this.hideSnapshot()
            if (response) {
                this.results = response.data
                this.showSnapshot()
            }
        },

        handleErrorResponse(error) {
            this.loading = false
            this.fetching = false
            if (error.response && error.response.status && error.response.status === 500) {
                EventBus.$emit('show-alert', this.$t('observations.err_too_much'))
            }
            this.timer = setTimeout(this.fetchVehicles, 10 * 60 * 1000); // Update every ten minutes
        },

        hideSnapshot: function () {
            this.polyLines.forEach(line => {
                this.map.removePolyline(line, this.WORK_TRACE)
            })
        },

        showSnapshot: function () {
            this.results.forEach(item => {
                // Decide color by the latest task
                let latestTask
                item.tasks.forEach((task) => {
                    if (!latestTask || latestTask.age > task.age) {
                        latestTask = task
                    }
                })
                // Draw road
                if (latestTask && item.geometry && item.geometry.length > 1) {
                    this.map.drawPolyLine(item.id, this.WORK_TRACE, item.geometry, latestTask.color, false, false,
                        3, this.withoutTimeLimit ? 0.85 : this.getTransparency(latestTask.age))
                    this.polyLines.push(item.id)
                }
            })
        },

        getTransparency: function (age) {
            let maxAge = Math.round((Date.now() - this.fromTime) / 1000 / 60)
            if (age > maxAge) {
                return 0.1;
            }
            // Scale age to 0-1
            return 1 - (age / maxAge)
        },

        // eslint-disable-next-line
        showLabel: function (data) {
            // Find hovered geometry
            let streetName = ''
            let foundGeometry
            for (let i=0; i < this.results.length; i++) {
                let street = this.results.find(street => street.id === data.id)
                if (street) {
                    streetName = street.street_name
                    foundGeometry = street.geometry
                    let label = `<div class="nopads pl-2" style="font-style: italic; color: #949494">${streetName}</div>`
                    // Add task data to map label
                    street.tasks.forEach(task =>  {
                        label += `<div class="task-type-circle" style="background-color: ${this.getTraceColor(logModes.WORK, task.color)}"></div>
                          <span class="nopads">${task.name} - ${this.timestampToLocalTime(task.time*1000)}</span><br>`

                    })
                    let index = Math.floor(foundGeometry.length / 2)
                    this.map.addGeoLabel(foundGeometry[index].y, foundGeometry[index].x, label, 'taskTypeLabel')
                    break
                }
            }
        },

        getTraceColor: function (mode, color) {
            if (mode === logModes.WORK) {
                return color
            }
            return '#bcbcbc'
        },

        hideLabel: function () {
            this.map.removeGeoLabels('taskTypeLabel')
        }
    }
}
</script>
