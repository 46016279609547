<template>
    <div v-if="additionalCosts && additionalCosts.length > 0" class="nopads pt-3">
        <span class="section-title">{{ $t('contract_reports.additional_costs') }}</span>
        <!-- Summary -->
        <b-row class="nopads">
            <div class="col-12 text-center nopads">
                <table class="table table-sm">
                  <tr>
                      <td class="table-title">{{$t('additional_cost.status_new')}}</td>
                      <td class="table-value">{{ newCost.toFixed(2) }} €</td>
                  </tr>
                    <tr>
                        <td class="table-title">{{$t('additional_cost.status_approved')}}</td>
                        <td class="table-value">{{ approvedCost.toFixed(2) }} €</td>
                    </tr>
                    <tr class="border-horizontal">
                        <td class="table-title table-caps">{{$t('common.total')}}</td>
                        <td class="table-value table-caps">{{ totalCost.toFixed(2) }} €</td>
                    </tr>
                    <tr>
                        <td class="table-title">{{$t('additional_cost.status_rejected')}}</td>
                        <td class="table-value">{{ rejectedCost.toFixed(2) }} €</td>
                    </tr>
                </table>
            </div>
        </b-row>
        <additional-cost-list
            :results="additionalCosts"
            :no-padding="true"
            :table-sortable="false"
            @rowClicked="editCost"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import AdditionalCostList from "@/components/additionalcost/AdditionalCostList";

export default {
    name: "ContractReportAdditionalCosts",
    components: {AdditionalCostList},
    mixins: [restApi],
    props: {
        value: {
            type: Number,
            default: null
        },
        searchParams: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            NEW: 1,
            APPROVED: 2,
            REJECTED: 3,
            costsVisible: false,
            additionalCosts: [],
            newCost: 0,
            approvedCost: 0,
            rejectedCost: 0
        }
    },
    computed: {
        totalCost() {
            return this.newCost + this.approvedCost
        }
    },
    watch: {
        searchParams: function () {
            this.fetchAdditionalCosts()
        }
    },
    mounted() {
        this.fetchAdditionalCosts()
    },
    methods: {
        fetchAdditionalCosts: function () {
            this.approvedCost = 0
            this.newCost = 0
            this.additionalCosts = []
            if (this.searchParams && this.searchParams.dateTo && this.searchParams.dateFrom && this.searchParams.contract) {
                this.$emit('loading', true)
                let params = {
                    created_from: this.searchParams.dateFrom,
                    created_to: this.searchParams.dateTo,
                    contract: this.searchParams.contract
                }
                this.restFetchParams(this.additionalCostUrl, params, this.handleSuccess, this.handleFail)
            }
        },
        handleSuccess: function (response) {
            this.$emit('loading', false)
            this.additionalCosts = response.data
            this.calculateSummaries()
        },
        handleFail: function () {
            this.$emit('loading', false)
        },
        calculateSummaries: function () {
            this.additionalCosts.forEach(cost => {
                let sum = cost.amount * cost.unit_price
                switch (cost.status) {
                    case this.NEW:
                        this.newCost += sum
                        break
                    case this.APPROVED:
                        this.approvedCost += sum
                        break
                    case this.REJECTED:
                        this.rejectedCost += sum
                        break
                }
            })
            this.$emit('input', this.totalCost)
        },
        editCost(item) {
            this.$emit('editCost', item)
        }
    }
}
</script>

<style scoped>

.table-title {
    font-size: .9em;
    text-align: left;
    font-weight: bold;
}

.table-value {
    text-align: right;
}

.table-caps {
    background: #ececec;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
}

/deep/ th {
    font-style: italic;
}


</style>
