<template>
    <div class="col-sm-12 list-container">
        <div class="col-sm-12 list-content result-table">
            <transition name="fade">
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    small
                    :items="tableItems"
                    :fields="fields"
                    @row-clicked="showDetails"
                />
            </transition>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from '@/event-bus'
import {downloadHelper} from '../mixins/DownloadMixin'
import {workShift} from '../mixins/WorkShift'

export default {
    name: 'WorkdayDetailList',
    mixins: [timeUtils, restApi, downloadHelper, workShift],
    props: {
        results: {
            type: Object,
            default() {
                return null
            }
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            tableItems: [],
            fields: [],
            NOT_APPROVED: 'NOK'
        }
    },
    mounted: function () {
        this.initFields()
        this.initTableItems()
    },
    methods: {
        initFields: function () {
            this.fields = [
                {
                    date: {
                        label: this.$t('area_report.created'),
                        sortable: false
                    }
                },
                {
                    weekDay: {
                        label: this.$t('work_time.weekday'),
                        sortable: false
                    }
                },
                {
                    person: {
                        label: this.$t('work_time.person'),
                        sortable: false
                    }
                },
                {
                    start: {
                        label: this.$t('work_time.start'),
                        sortable: false
                    }
                },
                {
                    end: {
                        label: this.$t('work_time.end'),
                        sortable: false
                    }
                },
                {
                    shift: {
                        label: this.$t('work_shift.shift'),
                        sortable: false
                    }
                },
                {
                    workTime: {
                        label: this.$t('work_time.work_time'),
                        sortable: false
                    }
                },
                {
                    cumulativeWorkTime: {
                        label: this.$t('work_time.work_time_cumulative'),
                        sortable: false
                    }
                },
                {
                    cumulativeWeekWorkTime: {
                        label: this.$t('work_time.work_time_week_cumulative'),
                        sortable: false
                    }
                },
                {
                    cumulativeMonthWorkTime: {
                        label: this.$t('work_time.work_time_month_cumulative'),
                        sortable: false
                    }
                },
                {
                    normalWorkTime: {
                        label: this.$t('work_time.normal_work_time'),
                        sortable: true
                    }
                },
                {
                    overTime50WorkTime: {
                        label: this.$t('work_time.over_time_50'),
                        sortable: true
                    }
                },
                {
                    overTime100WorkTime: {
                        label: this.$t('work_time.over_time_100'),
                        sortable: true
                    }
                },
                {
                    workTimeApproval: {
                        label: this.$t('work_time.approval'),
                        sortable: true
                    }
                }
            ]
            if (this.results.modes) {
                let modes = this.results.modes
                modes.forEach(function (mode) {
                    this.fields[mode] = {
                        label: mode,
                        sortable: false
                    }
                }, this)
            }
            this.fields['dailyAllowance'] = {
                label: this.$t('daily_allowance.full'),
                sortable: true
            }
            this.fields['partialDailyAllowance'] = {
                label: this.$t('daily_allowance.partial'),
                sortable: true
            }
            this.fields['dailyAllowanceApproval'] = {
                label: this.$t('daily_allowance.approval'),
                sortable: true
            }
            this.fields['info'] = {
                label: this.$t('work_time.note'),
                sortable: true
            }
        },

        initTableItems: function () {
            this.tableItems = []
            if (this.results && this.results.records) {
                this.results.records.forEach(function (record) {
                    let hasValidData = record.mode_hours.length !== 0
                    var row = {
                        date: this.getShortDateString(record.start_time),
                        weekDay: this.getWeekDay(record.start_time),
                        person: record.last_name + ', ' + record.first_name,
                        start: hasValidData ? this.toLocalTime(record.start_time) : '',
                        end: (hasValidData && record.end_time) ? this.toLocalTime(record.end_time) : '',
                        shift: this.getShiftsStr(record.shifts),
                        workTime: this.getWorkTime(record.work_time),
                        cumulativeWorkTime: this.getWorkTime(record.cumulative_work_time),
                        cumulativeWeekWorkTime: this.getWorkTime(record.cumulative_week_work_time),
                        cumulativeMonthWorkTime: this.getWorkTime(record.cumulative_month_work_time),
                        normalWorkTime: this.getWorkTime(record.normal_work_time),
                        overTime50WorkTime: this.getWorkTime(record.over_time_50),
                        overTime100WorkTime: this.getWorkTime(record.over_time_100),
                        workTimeApproval: record.work_time_approval
                    }
                    if (this.results.modes && record.mode_hours) {
                        for (var i = 0; i < this.results.modes.length; i++) {
                            var key = this.results.modes[i]
                            let value = record.mode_hours[key]
                            row[key] = this.getWorkTime(value)
                        }
                    }
                    row['dailyAllowance'] = record.daily_allowance
                    row['partialDailyAllowance'] = record.partial_daily_allowance
                    row['dailyAllowanceApproval'] = record.daily_allowance_approval
                    row['info'] = record.info
                    // Highlight unapproved working hours and daily allowances
                    if ((record.work_time_approval && record.work_time_approval === this.NOT_APPROVED) ||
                        (record.daily_allowance_approval && record.daily_allowance_approval === this.NOT_APPROVED)) {
                        row['_rowVariant'] = 'danger'
                    }

                    this.tableItems.push(row)
                }, this)
            }
        },

        showDetails: function (details, index) {
            this.$emit('showDetails', this.results.records[index])
        },

        exportExcel: function (params) {
            this.loading = true
            this.restPostDownloadFile(this.workDayDetailsReportUrl, this.getCommonReportParams(params), this.success, this.fail)
        },
        success: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('work_time.work_day_report_file_name'))
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
        getShiftsStr: function (shifts) {
            let result = ''
            if (shifts) {
                for (let i = 1; i <= 4; i++) {
                    if (shifts[i]) {
                        if (result.length > 0) {
                            result += '\n'
                        }
                        result += this.getWorkShiftName(i)
                    }
                }
            }
            return result
        }
    }
}
</script>
