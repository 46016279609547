<template>
    <base-modal @cancel="$emit('close')" style="z-index: 50">
        <div class="details-container">
            <div
                class="details dynamic_width"
            >
                <div class="coordinate-map"
                     style="height:80vh;"
                >
                    <map-container
                        ref="mapContainer"
                        :find-user="false"
                    />
                    <trip-map
                        v-if="map"
                        ref="tripMap"
                        :trip-results="trips"
                        :map="map"
                    />
                </div>
                <div class="col-sm-12 button-container pt-2 pb-0 mb-0">
                    <b-button
                        variant="primary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.back') }}
                    </b-button>
                </div>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />

    </base-modal>
</template>

<script>

import BaseModal from '../BaseModal'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import MapContainer from "../map/MapContainer";
import TripMap from "@/components/trip/TripMap";

export default {
    name: "TripModal",
    components: {MapContainer, BaseModal, TripMap},
    mixins: [
        mapHelper,
        restApi,
    ],
    props: {
        loading: {
            type: Number,
            default: 0
        },
        map: {
            type: Object,
            default: null
        },
        trips: {
            type: Array,
            default() {
                return []
            }
        },
    },
}

</script>

<style>
@import "../../styles/modal.css";
</style>
