<template>
    <div class="col-sm-12 nopads">
        <b-table
            small
            responsive
            borderless
            class="border-bottom"
            :items="data"
            :fields="fields"
        />
    </div>
</template>

<script>
export default {
    name: 'RoadLinkBasicInfo',
    props: {
        roadLinkInfo: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            data: [],
            fields: [
                {
                    street: {
                        label: this.$t('road_details.street'),
                        sortable: false
                    }
                },
                {
                    length: {
                        label: this.$t('road_details.link_length'),
                        sortable: false
                    }
                },
                {
                    city: {
                        label: this.$t('road_details.municipality'),
                        sortable: false
                    }
                },
                {
                    linkId: {
                        label: this.$t('road_details.link_tag'),
                        sortable: false
                    }
                },
                {
                    linkType: {
                        label: this.$t('road_details.link_type'),
                        sortable: false
                    }
                },
                {
                    speedLimit: {
                        label: this.$t('road_details.speed_limit'),
                        sortable: false
                    }
                },
                {
                    winterSpeedLimit: {
                        label: this.$t('road_details.winter_speed_limit'),
                        sortable: false
                    }
                },
                {
                    streetLights: {
                        label: this.$t('road_details.street_lights'),
                        sortable: false
                    }
                },
                {
                    ely: {
                        label: this.$t('road_details.ely_area'),
                        sortable: false
                    }
                },
                {
                    contractArea: {
                        label: this.$t('road_details.contract_area'),
                        sortable: false
                    }
                },
                {
                    govArea: {
                        label: this.$t('road_details.liva'),
                        sortable: false
                    }
                }
            ]
        }
    },

    mounted: function () {
        this.data = [{
            street: this.getRoadTitle(this.roadLinkInfo.roadlink),
            length: parseFloat(this.roadLinkInfo.roadlink.length.toFixed(1)).toLocaleString() + ' m',
            city: this.roadLinkInfo.roadlink.city,
            linkId: this.roadLinkInfo.roadlink.linkId,
            linkType: this.roadLinkInfo.roadlink.linkType,
            speedLimit: this.getSpeedLimits(this.roadLinkInfo.speedLimits),
            winterSpeedLimit: this.getWinterSpeedLimits(this.roadLinkInfo.speedLimits),
            streetLights: this.getLights(this.roadLinkInfo.lighting && this.roadLinkInfo.lighting.length > 0),
            ely: this.getEly(),
            contractArea: this.getContractArea(),
            govArea: this.getGovArea()
        }]
    },

    methods: {

        getEly: function () {
            if (this.roadLinkInfo && this.roadLinkInfo.owner.length > 0) {
                return this.roadLinkInfo.owner[0].govArea
            }
            return '-'
        },

        getContractArea: function () {
            if (this.roadLinkInfo && this.roadLinkInfo.owner.length > 0 && this.roadLinkInfo.owner[0].areaName) {
                return this.roadLinkInfo.owner[0].areaName + ' (' + this.roadLinkInfo.owner[0].legacyAreaName + ')'
            }
            return '-'
        },

        getGovArea: function () {
            if (this.roadLinkInfo && this.roadLinkInfo.owner.length > 0) {
                return this.roadLinkInfo.owner[0].region
            }
            return '-'
        },

        getRoadTitle: function (roadLink) {
            // Info title
            var roadName = ''
            if (roadLink.roadNum != null && roadLink.roadNum > 0) {
                roadName = roadLink.roadNum
            }
            if (roadLink.secNum != null && roadLink.secNum > 0) {
                roadName += ' / ' + roadLink.secNum
            }
            if (roadLink.name != null && roadLink.name.length > 0) {
                if (roadName.length > 0) {
                    roadName += ' '
                }
                roadName += roadLink.name
            }
            if (roadName.length < 1) {
                roadName = this.$t('road_details.no_name')
            }
            return roadName
        },

        getSpeedLimits: function (limits) {
            var info = ''
            var list = []

            for (let item of limits) {
                var hasSpeedLimit = this.hasSpeedLimitRecord(list, item)
                if (item.winterLimit === 0 && !hasSpeedLimit) {
                    list.push(item)
                    info += this.$t('road_details.km_h_direction', {speed: item.speedLimit, direction: item.direction})
                }
            }

            if (info.length < 1) {
                info = '-'
            }
            return info
        },

        /**
         * Returns winter speed limit info
         */
        getWinterSpeedLimits: function (limits) {
            var info = ''
            var list = []

            for (let item of limits) {
                var hasSpeedLimit = this.hasSpeedLimitRecord(list, item)
                if (item.winterLimit === 1 && !hasSpeedLimit) {
                    list.push(item)
                    info += this.$t('road_details.km_h_direction', {speed: item.speedLimit, direction: item.direction})
                }
            }

            if (info.length < 1) {
                info = '-'
            }
            return info
        },

        getLights: function (lights) {
            if (lights) {
                // TODO - We can also show more information of the lighting, e.g. light and pole type as well as cabling
                return this.$t('road_details.yes')
            } else {
                return '-'
            }
        },

        hasSpeedLimitRecord: function (list, record) {
            var hasLimit = false
            for (let item of list) {
                if (item.speedLimit === record.speedLimit && item.direction === record.direction &&
                    item.WinterLimit === record.WinterLimit) {
                    hasLimit = true
                    break
                }
            }
            return hasLimit
        }
    }
}
</script>
