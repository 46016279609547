<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showReportsList">
            <div v-if="!detailsMode"
                 class="header">
                <span class="title">{{ $t('menu.work_report') }}</span>
            </div>
            <div v-else
                 @click="showReportsList"
                 class="header col-sm-12 nopads">
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
                <font-awesome-icon
                    icon="print"
                    class="print-icon"
                    @click.stop="print"/>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <keep-alive>
                <search-filter
                    v-if="!detailsMode"
                    :is-admin="isAdmin"
                    :user="user"
                    @search="fetchWorkReports"/>
            </keep-alive>
            <transition name="fade">
                <work-report-list
                    v-if="!detailsMode"
                    :results="results"
                    @details="showReportDetails"/>
            </transition>
            <transition name="fade">
                <work-report-details
                    v-if="detailsMode"
                    id="details"
                    ref="details"
                    :report-details="reportDetails"/>
            </transition>
        </div>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import SearchFilter from '../search/SearchFilter'
import WorkReportList from './WorkReportList'
import WorkReportDetails from './WorkReportDetails'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'WorkReports',
    components: {WorkReportList, SearchFilter, WorkReportDetails},
    mixins: [restApi],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            results: [],
            detailsMode: false,
            reportDetails: null
        }
    },
    methods: {
        fetchWorkReports: function (params) {
            if (params) {
                this.loading = true
                this.restFetchParams(this.workReportUrl, params, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response) {
                this.results = response.data
            }
            this.loading = false
        },
        showReportDetails: function (details) {
            this.reportDetails = details
            this.detailsMode = true
        },
        showReportsList: function () {
            this.detailsMode = false
        },
        print: function () {
            this.$refs.details.print()
        }
    }
}
</script>
