
export const drainHelper = {
    methods: {
        getDrainTypes: function () {
            return [
                {name: this.$t('drain.select_type'), value: null},
                {name: this.$t('drain.type_storm_sewer'), value: drainTypes.STORM_SEWER},
                {name: this.$t('drain.type_underground_drain'), value: drainTypes.UNDERGROUND_DRAIN},
                {name: this.$t('drain.type_combined_storm_water_sewer_drain'), value: drainTypes.COMBINED_STORM_WATER_SEWER_DRAIN},
                {name: this.$t('drain.type_inspection_pipe'), value: drainTypes.INSPECTION_PIPE},
                {name: this.$t('drain.type_absorption_drain'), value: drainTypes.ABSORPTION_DRAIN}
            ]
        },
        getDrainCoverTypes: function () {
            return [
                {name: this.$t('drain.cover_type.select_type'), value: null},
                {name: this.$t('drain.cover_type.closed'), value: drainCoverTypes.CLOSED},
                {name: this.$t('drain.cover_type.grid'), value: drainCoverTypes.GRID},
                {name: this.$t('drain.cover_type.hood'), value: drainCoverTypes.HOOD},
                {name: this.$t('drain.cover_type.jaw'), value: drainCoverTypes.JAW},
            ]
        },
        getDrainMaterials: function () {
            return [
                {name: this.$t('materials.select_material'), value: null},
                {name: this.getMaterialString(drainMaterials.STEEL), value: drainMaterials.STEEL},
                {name: this.getMaterialString(drainMaterials.OTHER), value: drainMaterials.OTHER},
                {name: this.getMaterialString(drainMaterials.CONCRETE), value: drainMaterials.CONCRETE},
                {name: this.getMaterialString(drainMaterials.PLASTIC), value: drainMaterials.PLASTIC},
            ]
        },
        getDrainCoverMaterials: function () {
            return [
                {name: this.$t('materials.select_material'), value: null},
                {name: this.getMaterialString(drainCoverMaterials.STEEL), value: drainCoverMaterials.STEEL},
                {name: this.getMaterialString(drainCoverMaterials.CAST_IRON), value: drainCoverMaterials.CAST_IRON},
                {name: this.getMaterialString(drainCoverMaterials.OTHER), value: drainCoverMaterials.OTHER},
                {name: this.getMaterialString(drainCoverMaterials.CONCRETE), value: drainCoverMaterials.CONCRETE},
                {name: this.getMaterialString(drainCoverMaterials.PLASTIC), value: drainCoverMaterials.PLASTIC},
            ]
        },
        getDrainCoverAdjustmentMechanisms: function () {
            return [
                {name: this.$t('drain.cover_adjustment_mechanism.select_cover_adjustment_mechanism'), value: null},
                {name: this.$t('drain.cover_adjustment_mechanism.floating'), value: drainCoverAjustmentMechanisms.FLOATING},
                {name: this.$t('drain.cover_adjustment_mechanism.old_adjustment_mechanism'), value: drainCoverAjustmentMechanisms.OLD_ADJUSTMENT_MECHANISM},
                {name: this.$t('drain.cover_adjustment_mechanism.other'), value: drainCoverAjustmentMechanisms.OTHER},
            ]
        },
    }
}


export const drainTypes = {
    STORM_SEWER: 1,
    UNDERGROUND_DRAIN: 2,
    COMBINED_STORM_WATER_SEWER_DRAIN: 3,
    INSPECTION_PIPE: 5,
    ABSORPTION_DRAIN: 6
}

export const drainCoverTypes = {
    CLOSED: 1,
    GRID: 2,
    HOOD: 3,
    JAW: 4,
}

export const drainMaterials = {
    STEEL: 5,
    OTHER: 8,
    CONCRETE: 9,
    PLASTIC: 10
}

export const drainCoverMaterials = {
    STEEL: 5,
    CAST_IRON: 6,
    OTHER: 8,
    CONCRETE: 9,
    PLASTIC: 10
}

export const drainCoverAjustmentMechanisms = {
    FLOATING: 1,
    OLD_ADJUSTMENT_MECHANISM: 2,
    OTHER: 3
}

