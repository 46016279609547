<template>
    <div
        class="details-container"
        @click.stop="close"
    >
        <div
            class="details dynamic_width"
            @click.stop=""
        >
            <div>
                <h3 class="p-3">{{ $t('orders.add_property_value') }}</h3>
            </div>
            <div v-if="properties && Object.keys(properties) && hasValidProperties(properties)" class="col-12">
                <table class="table">
                    <tr v-for="(geometryProperty, index) in Object.keys(formatedProperties)" :key="'geometry-property'+index" class="col-12 nopads pb-2">
                        <td v-if="!excludedFields.includes(geometryProperty)" class="prop-header">{{ geometryProperty }}</td>
                        <td v-if="!excludedFields.includes(geometryProperty)">{{ formatedProperties[geometryProperty] ? formatedProperties[geometryProperty] : '-' }}</td>
                    </tr>
                </table>
            </div>
            <div v-else class="col-12">
                {{ $t('orders.no_properties_found') }}
            </div>
            <div class="button-container">
                <b-button
                    variant="danger"
                    style="margin-top: 5px;"
                    @click.stop="close"
                >
                    {{ $t('common.close') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "OrderGeometryPropertyModal",
    components: {},
    props: {
        properties: {
            type: Object,
            default: null
        },
        excludedFields: {
            type: Array,
            default() {
                return []
            }
        },
    },

    computed: {
        formatedProperties() {
            const processObject = (obj) => {
                return Object.keys(obj).reduce((acc, key) => {
                    const value = obj[key];
                    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
                        Object.assign(acc, processObject(value));
                    } else if (value !== null && value !== '') {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
            };
            return processObject(this.properties);
        },
    },

    methods: {
        hasValidProperties(properties) {
            for (const key in properties) {
                if (!this.excludedFields.includes(key)) {
                    return true;
                }
            }
            return false;
        },
        close() {
            this.$emit("close")
        },
    }
}
</script>
<style>
.prop-header {
    font-weight: 800;
    font-size: 0.8em;
    text-transform: uppercase;
}
</style>
