<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
        >
            <div
                class="header"
            >
                <span class="title">{{ $t('menu.invoicing_accepting') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition-group name="fade">
                <div key="search-contract-list">
                    <keep-alive>
                        <invoicing-list
                            :results="results"
                            :invoice-state-options="invoiceStateOptions"
                            :user="user"
                            @updateSummaries="fetchInvoiceSummaries"
                            @acceptInvoicing="acceptInvoicing"
                            @showOrder="showOrder"
                            @showTripOrder="showTripOrder"
                            @showContractDetails="showContractDetails"
                            @showTripDetails="showTripDetails"
                            @showTripDeliveries="showTripDeliveries"
                        />
                    </keep-alive>
                </div>
            </transition-group>
            <transition name="fade">
                <b-modal
                    v-model="showRowDetails"
                    size="lg"
                    body-class="fill-modal"
                    ref="contractTripModal"
                    centered
                    no-close-on-esc
                    no-close-on-backdrop
                    hide-header-close
                    hide-footer
                >
                    <contract-trip-details
                        v-if="tripDetailsRow && showRowDetails"
                        @close="showRowDetails = false"
                        :order-item="order"
                        :summaryItem="tripDetailsRow"
                        :tripTable="trips"
                    />
                </b-modal>
            </transition>
            <transition name="fade">
                <b-modal
                    v-model="showDeliveries"
                    size="lg"
                    body-class="fill-modal"
                    ref="companyModal"
                    centered
                    no-close-on-esc
                    no-close-on-backdrop
                    hide-header-close
                    hide-footer
                >
                    <contract-trip-deliveries
                        v-if="tripDetailsRow && showDeliveries"
                        @close="showDeliveries = false"
                        :summary-item="tripDetailsRow"
                    />
                </b-modal>
            </transition>
            <transition name="fade">
                <b-modal
                    v-model="showContract"
                    size="lg"
                    body-class="fill-modal"
                    ref="contractModal"
                    centered
                    no-close-on-esc
                    no-close-on-backdrop
                    hide-header-close
                    hide-footer
                >
                    <div v-if="showContract" class="details-container">
                        <div class="details dynamic_width">
                            <contract-editor
                                v-if="showContract"
                                v-model="contract"
                                :active-order-editor="activeOrderEditor"
                                :contract-trip-list="true"
                                :user="user"
                                :show-title="false"
                                @close="showContract = false"
                            />
                        </div>
                    </div>
                </b-modal>
            </transition>
            <transition name="fade">
                <b-modal
                    v-model="showOrderDetails"
                    size="lg"
                    body-class="fill-modal"
                    ref="orderModal"
                    centered
                    no-close-on-esc
                    no-close-on-backdrop
                    hide-header-close
                    hide-footer
                >
                    <div v-if="showOrderDetails" class="details-container">
                        <div class="details dynamic_width">
                            <order-editor
                                v-if="showOrderDetails"
                                @close="closeOrder"
                                @closeAndUpdate="closeOrder"
                                :order="order"
                                :user="user"
                                :show-title="false"
                            />
                        </div>
                    </div>
                </b-modal>
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '@/components/mixins/TimeUtils'
import {invoiceStates, invoiceStateHelper} from "@/components/invoice/InvoiceMixin";
import InvoicingList from "@/components/invoice/InvoicingList.vue";
import {EventBus} from "@/event-bus";
import ContractTripDeliveries from "@/components/tripsummary/ContractTripDeliveries.vue";
import ContractTripDetails from "@/components/tripsummary/ContractTripDetails.vue";
import OrderEditor from "@/components/order/OrderEditor.vue";
import ContractEditor from "@/components/contract/ContractEditor.vue";

export default {
    name: 'Invoicing',
    components: {
        ContractEditor, OrderEditor,
        ContractTripDetails, ContractTripDeliveries, InvoicingList
    },
    mixins: [workManagementHelper, restApi, timeUtils, invoiceStateHelper, invoiceStates],
    props: {
        user: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            results: [],
            showContract: false,
            tripDetailsRow: null,
            showRowDetails: false,
            showDeliveries: false,
            showOrderDetails: false,
            activeOrderEditor: false,
            skiWorkDetailsRow: null,
            showSkiWorkDetails: false,
            order: null,
            contract: null,
            trips: [],
            loading: false,
            lastSearchedParams: null,
        }
    },
    mounted: function () {
        this.fetchInvoiceSummaries()
    },
    computed: {
        invoiceStateOptions() {
            let invoiceStates = this.getInvoiceStates();
            return [...invoiceStates]
        }
    },
    methods: {
        fetchInvoiceSummaries: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.invoiceSummaryUrl, this.lastSearchedParams, this.handleSummariesResponse)
        },

        handleSummariesResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
            }
        },

        showTripDetails: function (selectedRow) {
            this.showTripOrder(selectedRow, false);
            this.tripDetailsRow = selectedRow;
            this.showRowDetails = true;
        },
        showTripDeliveries: function (selectedRow){
            this.tripDetailsRow = selectedRow
            this.showDeliveries = true
        },
        showContractDetails: function (selectedRow){
            this.tripDetailsRow = selectedRow
            let params = {}
            params.id = selectedRow.contract_id ? selectedRow.contract_id : null
            this.fetchContracts(params)
        },

        fetchOrders: function (params, showDetailsEditor) {
            this.handleOrders(params, showDetailsEditor);
        },

        handleOrders: function(params, showDetailsEditor) {
            this.loading = true;
            this.restFetch(this.orderUrl + "/" + params.id, (response) => {
                let fetchedOrder = response ? response.data : {};
                this.order = this.createEditorOrder(fetchedOrder);
                this.loading = false;
                if (showDetailsEditor) {
                    this.showOrderDetails = true;
                }
            }, this.orderFail);
        },

        fetchContracts: function(params) {
            this.loading = true
            this.restFetchParams(this.contractUrl, params, this.handleContracts, this.notAuthorized)
        },
        handleContracts: function (response) {
            let fetchedContract = response ? response.data[0] : {}
            this.loading = false
            if (fetchedContract && fetchedContract.is_contract_party) {
                this.contract = this.jsonToItem(fetchedContract)
                this.showContract = true
            }
        },

        notAuthorized: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('daily_summary.contract_access_error'))
        },

        acceptInvoicing(item) {
            if(item && item.orders && item.orders.length > 0) {
                this.loading = true
                this.restUpdate(this.contractDailyTripSummaryApprovalUrl, {rows: item.orders}, this.acceptInvoicingSuccess, this.acceptInvoicingFail)
            }
        },

        acceptInvoicingSuccess() {
            this.loading = false
            this.fetchInvoiceSummaries()
        },

        acceptInvoicingFail() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('daily_summary.invoice_accepting_failed'))
        },

        closeOrder: function() {
            this.showOrderDetails = false
            this.order = null
        },

        showOrder(order) {
            if (order && order.id) {
                let params = {}
                params.id = order.id ? order.id : null
                this.fetchOrders(params, true)
            }
        },

        showTripOrder: function (selectedRow, showDetailsEditor = true){
            this.tripDetailsRow = selectedRow
            let params = {}
            params.id = selectedRow.order.id ? selectedRow.order.id : null
            this.fetchOrders(params, showDetailsEditor)
        },
    }
}
</script>
