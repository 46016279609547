<template>
    <div class="col-sm-12">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2 class="form-title editor-title">
                    {{ $t('relationships.add') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
        <span class="span-title">
          {{ $t('relationships.customer') }}
        </span>
                <b-form-select
                    id="customer"
                    v-model="customer"
                    size="mb"
                    class="mb-3"
                    :disabled="!companies || companies.length < 1"
                >
                    <template slot="first">
                        <option
                            :value="null"
                            disabled
                        >
                            {{ $t('relationships.select_customer') }}
                        </option>
                    </template>
                    <option
                        v-for="c in companies"
                        :key="c.id"
                        :value="c.id"
                    >
                        {{ c.name }}
                    </option>
                </b-form-select>

                <span class="span-title">
          {{ $t('relationships.contractor') }}
        </span>
                <b-form-select
                    id="contractor"
                    v-model="contractor"
                    size="mb"
                    class="mb-3"
                    :disabled="!companies || companies.length < 1"
                >
                    <template slot="first">
                        <option
                            :value="null"
                            disabled
                        >
                            {{ $t('relationships.select_contractor') }}
                        </option>
                    </template>
                    <option
                        v-for="com in companies"
                        :key="com.id"
                        :value="com.id"
                    >
                        {{ com.name }}
                    </option>
                </b-form-select>
            </div>
            <div class="col-sm-12 button-container">
                <b-button
                    variant="danger"
                    class="routa-button"
                    @click.stop="$emit('close')"
                >
                    <i class="fa fa-times"/>
                    <span class="button-text">
            {{ $t('common.cancel') }}
          </span>
                </b-button>
                <b-button
                    variant="success"
                    class="routa-button"
                    :disabled="loading"
                    @click.stop="submitRelationship"
                >
                    <i class="fa fa-save"/>
                    <span class="button-text">
            {{ $t('common.save') }}
          </span>
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import {workManagementHelper} from '../../mixins/WorkManagementMixin'

export default {
    name: 'CompanyRelationshipEditor',
    mixins: [workManagementHelper, restApi],
    props: {
        company: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            companies: [],
            customer: null,
            contractor: null
        }
    },

    mounted: function () {
        this.fetchCompanies()
    },

    methods: {

        fetchCompanies: function () {
            this.loading = true
            this.restFetch(this.companyUrl, this.handleCompanies)
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        submitRelationship: function () {
            if (this.customer && this.contractor) {
                var relationship = {customer: this.customer, contractor: this.contractor}
                this.loading = true
                this.restAdd(this.companyRelationshipUrl, relationship, this.success, this.fail)
            } else {
                EventBus.$emit('show-alert', this.$t('relationships.err_select_both'))
            }
        },

        success: function () {
            this.$emit('close')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>
