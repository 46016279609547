<template>
    <div class="nopads">
        <b-row class="nopads">
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.damage.area') }}</span>
                <b-form-input
                    v-model="item.area"
                    type="number"
                    min="0"
                    size="sm"
                />
            </div>
            <div v-if="isPercentageVisible" class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.damage.percentage') }}</span>
                <b-form-input
                    v-model="item.percentage"
                    type="number"
                    min="0"
                    size="sm"
                />
            </div>
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.damage.type') }}</span>
                <b-form-select size="sm" v-model="item.damage_type">
                    <template slot="first">
                        <option :value="null">
                            {{ $t('velho_equipment.damage.select_type') }}
                        </option>
                    </template>
                    <option
                        v-for="option in damageTypeSelector"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
            </div>
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.damage.category') }}</span>
                <b-form-select size="sm" v-model="item.damage_category">
                    <template slot="first">
                        <option :value="null">
                            {{ $t('velho_equipment.damage.select_category') }}
                        </option>
                    </template>
                    <option
                        v-for="option in damageCategorySelector"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
            </div>
            <div class="col-sm-4 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.damage.repair_priority_level') }}</span>
                <b-form-select size="sm" v-model="item.repair_priority_level">
                    <template slot="first">
                        <option :value="null">
                            {{ $t('velho_equipment.damage.select_repair_priority_level') }}
                        </option>
                    </template>
                    <option
                        v-for="option in repairPriorityLevelSelector"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
            </div>
        </b-row>
    </div>
</template>

<script>
import {velhoHelper, repairPriorityLevels, damageCategories, damageTypes} from "@/components/mixins/VelhoMixin";

export default {
    name: 'velho-equipment-damage-editor',
    mixins: [velhoHelper],
    components: {},
    props: {
        item: {
            type: Object
        },
        isPercentageVisible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        damageTypeSelector() {
            return  Object.values(damageTypes).map(value => {
                return {
                    text: this.getVelhoDamageTypeString(value),
                    value: value
                }
            });
        },
        damageCategorySelector() {
            return  Object.values(damageCategories).map(value => {
                return {
                    text: this.getVelhoDamageCategoryString(value),
                    value: value
                }
            });
        },
        repairPriorityLevelSelector() {
            return  Object.values(repairPriorityLevels).map(value => {
                return {
                    text: this.getVelhoRepairPriorityLevelString(value),
                    value: value
                }
            });
        },
    },
    mounted() {
        if(!this.item.damage_type) { this.item.damage_type = null }
        if(!this.item.damage_category) { this.item.damage_category = null }
        if(!this.item.repair_priority_level) { this.item.repair_priority_level = null }
    },
    data() {
        return {}
    },
    methods: {}
}
</script>