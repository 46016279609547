<template>
    <div
        id="editor"
        class="col-sm-12 nopads"
    >
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    class="form-title editor-title"
                >
                    {{ permissionUpdate.id > 0 ? $t('work_permission.edit') : $t('work_permission.add') }}
                </h2>
            </div>

            <div class="col-sm-12 form-data">
                <h3 class="span-title" v-if="permissionUpdate.id > 0">
                    {{ permissionUpdate.requested_by.last_name }}, {{ permissionUpdate.requested_by.first_name }}
                </h3>

                <!-- Contract-->
                <span class="span-title">
                     {{ $t('orders.select_contract') }}
                 </span>
                <b-form-group
                    class="title"
                    label-for="contracts"
                >
                    <b-form-select
                        id="contracts"
                        v-model="permissionUpdate.contract.id"
                        size="mb"
                        :disabled="contracts.length < 1||permissionUpdate.id>0"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                            >
                                {{ $t('orders.select_contract') }}
                            </option>
                        </template>
                        <option
                            v-for="option in contracts"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <!-- Filter only user created contracts -->
                <b-form-group class="ml-1"
                    v-if="permissionUpdate.id < 1"
                >
                    <b-form-checkbox
                        v-model="onlyUserOwnedContracts"
                        size="md"
                        type="text"
                        value=1
                        unchecked-value=0>
                        {{ $t('contracts.only_user_owned_contracts') }}
                    </b-form-checkbox>
                </b-form-group>

                <!-- Type-->
                <span class="span-title">
                     {{ $t('work_permission.select_type') }}
                 </span>
                <b-form-group
                    class="title"
                    label-for="type"
                >
                    <b-form-select
                        id="contracts"
                        v-model="permissionUpdate.type"
                        size="mb"
                        :disabled="permissionUpdate.id>0"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                            >
                                {{ $t('work_permission.select_type') }}
                            </option>
                        </template>
                        <option
                            v-for="option in getTypes()"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <!--        Info-->
                <span class="span-title">
                     {{ $t('work_permission.info') }}
                 </span>
                <b-form-group label-for="name">
                    <b-form-input
                        id="name"
                        :disabled="permissionUpdate.id>0"
                        v-model="permissionUpdate.info"
                        type="text"
                        size="mb"
                    />
                </b-form-group>

                <!-- Type-->
                <div v-if="permissionUpdate.id>0 && permissionUpdate.status !== this.REQUESTED"
                     class="col-sm-12 nopads">
                    <span class="span-title">
                         {{ $t('work_permission.handled_at') }}
                    </span>
                    <span class="italic">
                        {{ toLocalTime(permissionUpdate.handling_time) }}
                    </span>
                    <span class="span-title">
                         {{ $t('work_permission.handled_by') }}
                    </span>
                    <span class="italic">
                        {{ permissionUpdate.handled_by.last_name }}, {{ permissionUpdate.handled_by.first_name }}
                    </span>
                    <span class="span-title">
                         {{ $t('work_permission.handlers_note') }}
                    </span>
                    <span class="italic">
                        {{ permissionUpdate.handlers_note }}
                    </span>
                </div>


                <div class="col-sm-12 button-container">
                    <b-button
                        v-if="permissionUpdate.id<1"
                        variant="success"
                        class="form-button"
                        :disabled="loading"
                        @click.stop="submitPermissionRequest"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                    <b-button
                        v-if="permissionUpdate.id>0 && permissionUpdate.status === this.REQUESTED"
                        variant="primary"
                        class="form-button"
                        :disabled="loading"
                        @click.stop="handleRequestModal=true"
                    >
                        {{ $t('work_permission.handle_request') }}
                    </b-button>

                    <b-button
                        variant="secondary"
                        class="form-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                </div>
            </div>
        </b-form>
        <b-modal
            v-model="handleRequestModal"
            :title="$t('work_permission.handle_request')"
            centered
            no-close-on-esc
            no-close-on-backdrop
            hide-footer
        >
            <span>{{ $t('work_time.note') }}</span>
            <b-form-input
                id="note-input"
                v-model="handlersNote"
            ></b-form-input>
            <div class="button-container top-margin">
                <b-button
                    class="mr-1"
                    variant="danger"
                    size="lg"
                    @click.stop="handleRequest(3)"
                    :disabled="handlersNote===null||handlersNote.trim().length===0">
                    <span>{{ $t('work_permission.reject') }}</span>
                </b-button>
                <b-button
                    variant="success"
                    size="lg"
                    @click.stop="handleRequest(2)"
                    :disabled="handlersNote===null||handlersNote.trim().length===0">
                    <span>{{ $t('work_permission.accept') }}</span>
                </b-button>
            </div>
        </b-modal>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '../../event-bus.js'
import {restApi} from '../mixins/RestApiMixin'
import {workPermissionHelper} from '../mixins/WorkPermissionMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'work-permission-editor',
    mixins: [restApi, workPermissionHelper, timeUtils],
    props: {
        workPermission: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            contracts: [],
            permissionUpdate: this.newWorkPermission(this.workPermission),
            handleRequestModal: false,
            handlersNote: null,
            onlyUserOwnedContracts: 0
        }
    },
    created: function () {
        this.fetchContracts()
    },
    watch: {
        onlyUserOwnedContracts: function() {
            this.fetchContracts()
        }
    },
    methods: {
        fetchContracts: function () {
            this.loading = true
            let params = {}
            if(parseInt(this.onlyUserOwnedContracts) === 1) {
                params.onlyUserOwnedContracts = 1
            }
            this.restFetchParams(this.contractUrl, params, this.handleResponse)
        },
        handleResponse: function (response) {
            this.contracts = response.data
            this.loading = false
        },
        setDefaultValues: function () {
            let permission = JSON.parse(JSON.stringify(this.permissionUpdate))
            if (!permission.type) {
                EventBus.$emit('show-alert', this.$t('work_permission.select_type'))
                return undefined
            }
            if (permission.contract && permission.contract.id) {
                permission.contract = {id: permission.contract.id}
            } else {
                permission.contract = undefined
            }
            return permission
        },
        submitPermissionRequest: function () {
            let permissionRequest = this.setDefaultValues()
            if (permissionRequest) {
                this.loading = true
                this.restAdd(this.workPermissionUrl, permissionRequest, this.updateSuccess,
                    this.updateFail)
            }
        },
        updateSuccess: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },
        updateFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        handleRequest: function (status) {
            this.loading = true
            this.handleRequestModal = false
            this.restUpdate(this.workPermissionUrl + '/' + this.permissionUpdate.id,
                {status: status, note: this.handlersNote},
                this.updateSuccess, this.updateFail)
        }
    }
}
</script>
