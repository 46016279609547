<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 list-content pt-1">
        <b-table
            hover
            show-empty
            small
            style="cursor:pointer;"
            :items="results"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @row-clicked="emitDetails"
        >
            <template slot="empty">
                <div class="text-center my-2">
                    {{ $t('area_report.no_results') }}
                </div>
            </template>
            <template v-slot:cell(completed_time)=row>
                <span>{{ toLocalTime(row.value) }}</span>
            </template>
            <template v-slot:cell(status)="row">
                {{ row.value === 1 ? $t('area_report.status_open') : $t('area_report.completed') }}
            </template>
        </b-table>
    </div>
</template>

<script>
import {areaReportHelper} from '../../mixins/AreaReportMixin'
import {timeUtils} from '../../mixins/TimeUtils'

export default {
    name: 'AreaContractReportList',
    mixins: [areaReportHelper, timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data() {
        return {
            sortBy: 'completed_time',
            sortDesc: true,
            fields: [
                {key: 'area.name', label: this.$t('area_report.area_name'), sortable: true},
                {key: 'completed_time', label: this.$t('area_report.completed'), sortable: true},
                {key: 'status', label: this.$t('area_report.status'), sortable: true}
            ]
        }
    },
    methods: {
        emitDetails: function (details) {
            this.$emit('inspectionSelected', details)
        }
    }
}
</script>
