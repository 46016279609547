<template>
    <div class="col-sm-12 nopads">
        <b-form v-if="contractCollectionUpdate" class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    class="form-title editor-title"
                >
                    {{ contractCollectionUpdate.id && contractCollectionUpdate.id > 0 ? $t('contracts.contract_collection.edit') : $t('contracts.contract_collection.add')}}
                </h2>
            </div>
            <span class="span-title p-2">
                {{ $t('common.name') }}
            </span>
            <b-form-group
                 class="title p-2"
                 label-for="name"
            >
                <b-form-input
                    id="name"
                    v-model="contractCollectionUpdate.name"
                    type="text"
                    size="mb"
                />
            </b-form-group>
            <span class="span-title p-2">
                {{ $t('common.description') }}
            </span>
            <b-form-group
                class="title p-2"
                label-for="description"
            >
                <b-form-input
                    id="description"
                    v-model="contractCollectionUpdate.description"
                    type="text"
                    size="mb"
                />
            </b-form-group>
            <span class="span-title p-2">
                {{ $t('menu.contracts') }}
            </span>
            <b-form-group
                class="p-2"
                label-for="contracts"
            >
                <multiselect
                    v-model="contractsSelected"
                    :options="contractOptions"
                    :multiple="true"
                    :close-on-select="false"
                    label="text"
                    track-by="value"
                    :searchable="true"
                    :selected-label="$t('common.selected')"
                    :select-label="$t('common.select')"
                    :deselect-label="$t('common.remove_selection')"
                    :placeholder="$t('contracts.contract_collection.select_contracts')"
                    :disabled="true"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.text }}</span>
                  </span>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.text }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>
            </b-form-group>
            <!-- Buttons -->
            <div class="col-sm-12 button-container">
                <b-button
                    variant="danger"
                    class="form-button"
                    @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="form-button"
                    :disabled="loading"
                    @click.stop="submit()"
                >
                  {{ $t('common.save') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <b-modal
            v-model="deletion"
            size="sm"
            centered
            ok-variant="danger"
            :cancel-title="$t('common.no')"
            :ok-title="$t('common.yes')"
            @ok="deleteContractCollection()"
            @hide="deletion = false"
        >
            <div class="modal-body">
                <span>{{ $t('common.confirm_delete') }}</span>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '@/components/mixins/RestApiMixin'

export default {
    name: 'ContractCollectionEditor',
    mixins: [restApi],
    props: {
        contractCollection: {
            type: Object,
            default: null
        },
        contracts: {
            type: Array,
            default() {
                return []
            }
        },
        contractCollections: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false,
            contractCollectionUpdate: JSON.parse(JSON.stringify(this.contractCollection)),
            contractOptions: [],
            deletion: false,
            contractsSelected: [],
        }
    },
    mounted: function () {
        if(this.contracts && this.contracts.length > 0) {
            this.contractOptions.push({
                value: null,
                text: this.$t('observation_collections.observation_type_select')
            })
            this.contracts.forEach(contract => {
                if(contract.id && contract.name) {
                    this.contractOptions.push({
                        value: contract.id,
                        text: contract.name
                    })
                }
            })
        }
        if(this.contractCollection && this.contractCollection.contracts && this.contractCollection.contracts.length > 0) {
            this.contractCollection.contracts.forEach(contract => {
                this.contractsSelected.push({
                    value: contract.id,
                    text: contract.name
                })
            })
        }
    },
    methods: {
        setDefaultValues: function (contractCollection) {
            if (!contractCollection.name) {
                EventBus.$emit('show-alert', this.$t('observation_collections.name_missing'))
                return undefined
            }
            return contractCollection
        },

        submit: function () {
            var json = this.setDefaultValues(this.contractCollectionUpdate)
            if (json !== undefined) {
                this.loading = true
                if(json.id && json.id > 0) {
                    this.restUpdate(this.contractCollectionUrl, json, this.success, this.fail)
                } else {
                    this.restAdd(this.contractCollectionUrl, json, this.success, this.fail)
                }
            }
        },

        deleteContractCollection: function () {
            this.deletion = false
            this.loading = true
            var url = this.contractCollectionUrl + '/' + this.contractCollectionUpdate.id
            this.restDelete(url, this.success, this.fail)
        },

        confirmDeleteContractCollection() {
            if (!this.loading) {
                this.deletion = true
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
