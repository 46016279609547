<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('orders.title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <keep-alive>
                    <order-search
                        v-if="!editMode"
                        :user="user"
                        @search="fetchOrders"
                    />
                </keep-alive>
            </transition>
            <transition name="fade">
                <div class="col-sm-12" v-bind:class="{hidden : editMode}">
                    <div
                        class="data-list-item add"
                        @click.stop="addOrder"
                    >
                        <div class="item-img-cont add-title">
                            <i class="fa fa-plus"/>
                        </div>
                        <div
                            class="item-text-cont"
                        >
                            <span
                                class="item-title-add"
                            >
                                {{ $t('common.add_new') }}
                            </span>
                            <font-awesome-icon
                                icon="money-check"
                                class="card-body-icon"
                            />
                        </div>
                    </div>
                    <keep-alive>
                        <order-list
                            :results="results"
                            :user="user"
                            @edit="editOrder"
                            @copy="copyOrder"
                        />
                    </keep-alive>
                </div>
            </transition>
            <transition name="fade">
                <order-editor
                    v-if="editMode"
                    :order="order"
                    :user="user"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'
import OrderEditor from './OrderEditor'
import OrderList from './OrderList'
import OrderSearch from './OrderSearch'

export default {
    name: 'Orders',
    components: {OrderList, OrderEditor, OrderSearch},
    mixins: [workManagementHelper, restApi],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            results: [],
            order: null,
            editMode: false,
            loading: false,
            lastSearchedParams: null
        }
    },

    methods: {
        fetchOrders: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.orderUrl, this.lastSearchedParams, this.handleOrders)
        },

        handleOrders: function (response) {
            this.results = response ? response.data : []
            this.loading = false
        },

        editOrder: function (order) {
            this.order = this.createEditorOrder(order)
            this.editMode = true
            this.scrollTop()
        },

        addOrder: function () {
            this.order = this.createEditorOrder()
            this.editMode = true
            this.scrollTop()
        },

        copyOrder: function (order) {
            let orderCopy = this.createEditorOrder(order)
            orderCopy.id = -1
            this.order = orderCopy
            this.editMode = true
            this.scrollTop()
        },

        showList: function () {
            this.editMode = false
        },

        closeAndUpdate: function () {
            this.showList()
            this.fetchOrders(this.lastSearchedParams)
        },

        scrollTop: function () {
            this.$nextTick(function () {
                this.$refs.container.scrollTop = 0
            })
        },
    }
}
</script>
