import Vue from 'vue'
import Router from 'vue-router'
import Office from '@/components/Office'
import ResetPassword from '@/components/user/ResetPassword'
import InspectVehicle from '@/components/vehicle/InspectVehicle'
import WorkAssignmentsMobile from '@/components/workassignment/WorkAssignmentsMobile'
import Station from "@/components/Station";

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Office',
            component: Office
        },
        {
            path: '/station',
            name: 'Station',
            component: Station
        },
        {
            path: '/resetPassword',
            name: 'ResetPassword',
            component: ResetPassword
        },
        {
            path: '/inspectVehicle',
            name: 'InspectVehicle',
            component: InspectVehicle
        },
        {
            path: '/workAssignment',
            name: 'WorkAssignmentsMobile',
            component: WorkAssignmentsMobile
        }
    ]
})
