<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 span-header pb-2">
                {{ $t('contractor_reports.choose_contractor') }}
            </div>
            <b-row>
                <div class="col-sm-6 pb-3">
                    <!-- Contractor selection -->
                    <b-form-group
                        class="nopads"
                        label-for="contractor"
                    >
                        <b-form-select
                            id="contractorOrder"
                            v-model="contractor"
                            size="sm"
                            class="sm-3"
                            :disabled="contractors.length < 1"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('orders.select_contractor') }}
                                </option>
                            </template>
                            <option
                                v-for="option in contractors"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-6 pb-3">
                    <b-form-checkbox
                        v-model="activeUsers"
                        size="md"
                        type="text"
                        value=1
                        unchecked-value=0
                        @change="emitSearch">
                        {{ $t('contractor_reports.only_active_users') }}
                    </b-form-checkbox>
                </div>
            </b-row>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'contract-report-filter',
    mixins: [timeUtils, restApi],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            activeUsers: 1,
            contractor: null,
            contractors: []
        }
    },
    watch: {
        contractor: function () {
            this.emitSearch()
        }
    },
    mounted: function () {
        this.fetchContractors()
    },
    methods: {
        emitSearch: function () {
            this.$emit('search', {contractorId: this.contractor, activeUsers: this.activeUsers})
        },
        fetchContractors: function () {
            this.contractorsLoaded = false
            this.loading = true
            let params = {}
            if (this.company && this.company > 0) {
                params.company = this.company
            }
            this.restFetchParams(this.contractorUrl, params, this.handleContractors)
        },
        handleContractors: function (response) {
            this.loading = false
            if (response && response.data) {
                this.contractors = response.data
            }
        },
    }
}
</script>
