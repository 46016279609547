<template>
    <div class="pt-3">
        <transition name="fade">
            <cost-center-list
                v-if="!editMode"
                :is-admin="isAdmin"
                :results="results"
                @add="addCostCenter"
                @edit="editCostCenter"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <b-modal
            v-model="editMode"
            body-class="fill-modal"
            size="lg"
            :hide-header="true"
            :hide-footer="true"
        >
                <cost-center-editor
                    :is-admin="isAdmin"
                    :cost-center="costCenter"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
        </b-modal>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import CostCenterList from './CostCenterList'
import CostCenterEditor from './CostCenterEditor'

export default {
    name: 'CostCenters',
    components: {CostCenterList, CostCenterEditor},
    mixins: [restApi, workManagementHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            costCenter: null,
            editMode: false,
            loading: false,
            searchCompanyId: null,
            company: null
        }
    },
    watch: {
        company: function () {
            this.fetchCostCenters(this.company)
        }
    },
    mounted: function () {
        if (!this.isAdmin) {
            this.fetchCostCenters()
        }
    },
    methods: {
        fetchCostCenters: function (companyId) {
            this.loading = true
            this.searchCompanyId = companyId
            this.results = []
            this.restFetchParams(this.costCenterUrl, {company: this.searchCompanyId}, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        addCostCenter: function () {
            this.costCenter = this.newCostCenter()
            this.editMode = true
        },
        editCostCenter: function (costCenter) {
            this.costCenter = costCenter
            this.editMode = true
        },
        showList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchCostCenters(this.searchCompanyId)
            this.showList()
        }
    }
}
</script>
