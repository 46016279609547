<template>
    <div
        v-bind:class="{ 'details-container': !this.isAddEquipmentMode }"
        @click.stop="close"
    >
        <div
            v-bind:class="{ 'details dynamic_width': !this.isAddEquipmentMode }"
            @click.stop=""
        >
            <h3 v-if="!this.isAddEquipmentMode" class="pl-1">
                {{ $t('railing.editor_title')  }}
            </h3>
            <location-deviation v-if="!this.isAddEquipmentMode" :location-deviation="item.location_deviation"/>
            <div class="pl-2 pr-2">
                <b-row v-if="!this.isAddEquipmentMode">
                    <div class="col-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('common.id') }}</span>
                        <span class="item-detail-text">{{ item.external_system_id }}</span>
                    </div>
                    <div class="col-6 nopads pl-1 pr-1">
                        <road-addess-view :road-number="item.road_num" :section-number="item.road_sec" :distance="item.distance" :show-street-name="false"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('post.type') }}</span>
                        <b-form-select
                            v-model="item.type"
                            :state="!!item.type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('post.select_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getRailingTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('railing.post_type.title') }}</span>
                        <b-form-select
                            v-model="item.post_type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('railing.post_type.select_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getRailingPostTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <condition-selector v-model="item.condition" :required="true"/>
                    </div>
                </b-row>

                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('railing.material') }}</span>
                        <b-form-group
                            class="title"
                            label-for="material"
                        >
                            <b-form-select
                                v-model="item.material"
                                size="sm"
                            >
                                <template slot="first">
                                    <option
                                        :value="null"
                                        disabled
                                    >
                                        {{ $t('railing.select_material') }}
                                    </option>
                                </template>
                                <option
                                    v-for="item in getMaterials()"
                                    :key="item.value"
                                    :value="item.value"
                                >
                                    {{ item.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('post.height') }}</span>
                        <b-form-group
                            class="title"
                            label-for="height"
                        >
                            <b-form-input
                                id="height"
                                min="1"
                                v-model="item.height"
                                type="number"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('post.code') }}</span>
                        <b-form-group
                            class="title"
                            label-for="code"
                        >
                            <b-form-input
                                id="code"
                                v-model="item.code"
                                type="text"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <location-details
                    v-if="!this.isAddEquipmentMode"
                    :lane-data="this.item.location_specification.lane"
                    :side-data="this.item.location_specification.side"
                    :track-data="this.item.location_specification.tracks"
                />
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('post.product_name') }}</span>
                        <b-form-group
                            class="title"
                            label-for="productName"
                        >
                            <b-form-input
                                id="productName"
                                v-model="item.product_name"
                                type="text"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <!-- TODO - Railings don't have point based actions(?)
                    <equipment-actions ref="actions" v-model="item.actions" />
                 -->
                <template v-if="this.isAddEquipmentMode">
                    <b-row class="nopads mt-2">
                        <div class="col-sm-4 nopads pr-1">
                            <span class="span-title">{{ $t('velho_equipment.starting_position') }}</span>
                        </div>
                        <div class="col-sm-12 nopads">
                            <velho-equipment-location-details-editor
                            :roadInfo="item"
                            :locationSpecification="item.location_specification"
                            :tracksRequired="false"
                            :hideTracksSelector="true"
                            />
                        </div>
                    </b-row>
                    <b-row class="nopads mt-2">
                        <div class="col-sm-4 nopads pr-1">
                            <span class="span-title">{{ $t('velho_equipment.ending_position') }}</span>
                        </div>
                        <div class="col-sm-12 nopads">
                            <velho-equipment-location-details-editor
                            :roadInfo="item.location_ending"
                            :locationSpecification="item.location_specification_ending"
                            :tracksRequired="false"
                            :hideTracksSelector="true"
                            :sideRequired="false"
                            :hideSideSelector="true"
                            />
                        </div>
                    </b-row>
                </template>
                <linked-equipment
                    v-if="item.linked_equipment && item.linked_equipment.length > 0"
                    :linked-equipments="item.linked_equipment"
                />
            </div>
            <velho-editor-buttons
                v-if="!this.isAddEquipmentMode"
                :url="extRailingsUrl"
                :selected-contract="selectedContract"
                :item="item"
                :disabled="loading"
                @close="close"
                @submit="handleSubmit"
                @removalConfirmed="removalConfirmed"
            />
        </div>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";
import {railingHelper} from "@/components/mixins/RailingMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import ConditionSelector from "@/components/velho/ConditionSelector";
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import VelhoEditorButtons from "@/components/velho/VelhoEditorButtons";
import LinkedEquipment from "@/components/velho/LinkedEquipment";
import LocationDetails from "@/components/velho/LocationDetails.vue";
import LocationDeviation from "@/components/velho/LocationDeviation";
import VelhoEquipmentLocationDetailsEditor from "@/components/velho/VelhoEquipmentLocationDetailsEditor";
import RoadAddessView from "@/components/velho/RoadAddressView";
import {velhoValidationMixin} from "@/components/mixins/VelhoValidationMixin";

export default {
    name: 'RailingEditor',
    components: {
        RoadAddessView,
        LocationDeviation, LocationDetails, VelhoEditorButtons, ConditionSelector, LinkedEquipment, VelhoEquipmentLocationDetailsEditor},
    mixins: [velhoHelper, mapHelper, railingHelper, conversionModelHelper, restApi, velhoValidationMixin],
    props: {
        item: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        },
        addEquipmentEditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAddEquipmentMode() {
            return this.addEquipmentMode
        }
    },
    data() {
        return {
            addEquipmentMode: this.addEquipmentEditor,
            map: null,
            loading: false,
        }
    },
    methods: {
        handleSubmit: function(url, item, selectedContract, replace = false) {
            if(replace) {
                this.submitEquipmentsreplace(this.extEquipmentsReplaceUrl, [item], selectedContract)
            } else {
                this.submitEquipment(url, item, selectedContract);
            }
        },
        close: function () {
            this.$emit('close')
        },
        isValidForm: function() {
            if(!this.item) { return false }
            if(!this.validateIsNumber(this.item.condition)) { return false }
            if(!this.validateRoadNumber(this.item.road_num)) { return false }
            if(!this.validateRoadSection(this.item.road_sec)) { return false }
            if(!this.validateRoadDistance(this.item.distance)) { return false }
            if(!this.validateRoadNumber(this.item.location_ending.road_num)) { return false }
            if(!this.validateRoadSection(this.item.location_ending.road_sec)) { return false }
            if(!this.validateRoadDistance(this.item.location_ending.distance)) { return false }
            if(!this.validateRoadSide(this.item.location_specification.side)) { return false }

            return true;
        }
    }
}
</script>

<style scoped>
.map-container {
    height: 30em;
    position: relative;
}

</style>

