export const inspectionHelper = {
    data: function () {
        return {
            resultSkip: 1,
            resultOk: 2,
            resultDefect: 3,
            resultBan: 4
        }
    },

    methods: {
        newInspectable: function () {
            return {
                id: -1,
                name: ''
            }
        },

        jsonToInspectable: function (inspectable) {
            return JSON.parse(JSON.stringify(inspectable))
        },

        newInspection: function () {
            return {
                id: -1,
                name: '',
                vehicle_type: '',
                type: '',
                inspectables: []
            }
        },

        jsonToInspection: function (inspection) {
            return JSON.parse(JSON.stringify(inspection))
        },

        getInspectionTypeName: function (inspectionType) {
            switch (inspectionType) {
                case 1:
                    return this.$t('inspection_template.type.vehicle')
                case 2:
                    return this.$t('inspection_template.type.work')
                case 3:
                    return this.$t('inspection_template.type.task')
                case 4:
                    return this.$t('inspection_template.type.company')
                case 5:
                case 7:
                    return this.$t('inspection_template.type.bridge')
                case 6:
                    return this.$t('inspection_template.type.culvert')
            }
        },

        getInspectionFrequency: function (frequency) {
            switch (frequency) {
                case 1:
                    return this.$t('inspection_template.frequency.weekly')
                case 2:
                    return this.$t('inspection_template.frequency.monthly')
                case 3:
                    return this.$t('inspection_template.frequency.annually')
                case 4:
                    return this.$t('inspection_template.frequency.deployment')
                case 5:
                    return this.$t('inspection_template.frequency.daily')
                case 6:
                    return this.$t('inspection_template.frequency.other')
            }
        },

        getInspector(user) {
            return user.last_name + ', ' + user.first_name
        },

        getInspectionTarget(result) {
            if (result.vehicle) {
                return result.vehicle.license_plate + ' - ' + result.vehicle.make + ' ' + result.vehicle.vehicle_model
            }
            if (result.order) {
                return result.order.task_type.name + ' (' + result.order.contract.name + ')'
            }
            if (result.contract) {
                let str = result.contract.name
                return result.contract.contract_number ? str + ' ' + result.contract.contract_number : str
            }
            if (result.area) {
                return result.area.name
            }
            if (result.inspected_company) {
                return result.inspected_company.name
            }
            if (result.bridge_id) {
                return this.$t('ins_report.bridge') + "-" + result.bridge_id
            }
            if (result.culvert_id) {
                return this.$t('ins_report.culvert') + "-" + result.culvert_id
            }
        },

        getInspectionResultName: function (resultType) {
            switch (resultType) {
                case 1:
                    return this.$t('inspection.options.not_tested')
                case 2:
                    return this.$t('inspection.options.okay')
                case 3:
                    return this.$t('inspection.options.defects')
                case 4:
                    return this.$t('inspection.options.ban')
            }
        }
    }
}
