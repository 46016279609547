<template>
    <div class="col-12 nopads">
        <infra-map-filter
            @typesSelected="updateFilters"
        />
        <storm-sewers-map
            ref="sewers"
            :map="map"
            :filters="filters"
            :bounding-box="boundingBox"
        />
        <road-sign-map
            ref="roadSigns"
            v-if="roadSignsEnabled"
            :selectedContractIds="selectedContractIds"
            :map="map"
            :bounding-box="boundingBox"
        />
        <street-light-map
            ref="streetLightMap"
            v-if="streetLightsEnabled"
            :bounding-box="boundingBox"
            :map="map"
            :selectedContractId="selectedContractId"
            :selectedContractIds="selectedContractIds"
        />
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '@/components/mixins/TimeUtils'
import {mapHelper} from '@/components/mixins/MapMixin'
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {restApi} from '@/components/mixins/RestApiMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import StormSewersMap from "@/components/stormsewers/StormSewersMap";
import InfraMapFilter from "@/components/infra/InfraMapFilter";
import RoadSignMap from "@/components/roadsign/RoadSignMap";
import StreetLightMap from "@/components/streetlights/StreetLightMap";

export default {
    name: 'InfraMap',
    components: {
        StreetLightMap,
        RoadSignMap,
        StormSewersMap,
        InfraMapFilter,
    },
    mixins: [timeUtils, mapHelper, restApi, geometryMixin, stormSewersMixin, roadSignHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: 0,
            filters: [],
            streetLightsEnabled: false,
            roadSignsEnabled: false
        }
    },
    beforeDestroy() {
        this.hideAll()
    },
    methods: {
        isVisible(type) {
            let pref = this.filters.find(item => item === type)
            return pref !== undefined
        },

        updateFilters(filters) {
            this.filters = filters
            this.streetLightsEnabled = filters.find(item => item === this.STREET_LIGHT)
            this.roadSignsEnabled = filters.find(item => item === this.TRAFFIC_SIGN)
        },

        hideAll() {
            if (this.$refs.sewers) {
                this.$refs.sewers.hideAll()
            }
            if (this.$refs.streetLightMap) {
                this.$refs.streetLightMap.hideAll()
            }
            if (this.$refs.roadSigns) {
                this.$refs.roadSigns.hideSigns()
            }
        },

        getSewerLines() {
            return this.$refs.sewers ? this.$refs.sewers.getSewerLines() : []
        },

        getSewerDrains() {
            return this.$refs.sewers ? this.$refs.sewers.getSewerDrains() : []
        },

        getVisibleSigns() {
            return this.$refs.roadSigns ? this.$refs.roadSigns.getVisibleSigns() : []
        },

        getStreetLights() {
            return this.$refs.streetLightMap ? this.$refs.streetLightMap.getStreetLights() : []
        },

        addPowerStation(coords) {
            this.streetLightsEnabled = true
            this.$nextTick(() => this.$refs.streetLightMap.addPowerStation(coords))
        },

        addStreetLight(coords) {
            this.streetLightsEnabled = true
            this.$nextTick(() => this.$refs.streetLightMap.addStreetLight(coords))
        },

        addDrain(coords) {
            this.$refs.sewers.newDrain(coords)
        },
    }
}
</script>
