<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <contract-filter
                ref="filter"
                :user="user"
                :show-only-my-contracts-option="true"
                :no-contractor-option="true"
                @contractSet="setContract"
                @customerSet="setCustomer"
                @contractorSet="setContractor"
            />
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                    <span class="span-title">{{ $t('contracts.name') }}</span>
                    <b-input
                        v-model="contractName"
                        size="sm"
                        :placeholder="$t('contracts.name')"
                        type="text"/>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="status"
                    >
                        <span class="span-title">{{ $t('orders.status') }}</span>
                        <multiselect
                            v-model="status"
                            :options="orderStatusValues"
                            :custom-label="value => orderStatusNames[value]"
                            :multiple="true"
                            :close-on-select="false"
                            :searchable="false"
                            :selected-label="$t('common.selected')"
                            :select-label="$t('common.select')"
                            :deselect-label="$t('common.remove_selection')"
                            :placeholder="$t('orders.select_status')"
                        ></multiselect>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <span class="span-title">{{ $t('orders.task_type') }}</span>
                    <task-type-selector v-model="taskType" :contract-id="contract" nullable/>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="start"
                    >
                        <span class="span-title">{{ $t('orders.create_time') }} - {{
                                $t('trip_list.road_sec_from')
                            }}</span>
                        <b-form-input
                            id="start"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="end"
                    >
                        <span class="span-title">{{ $t('orders.create_time') }} - {{
                                $t('trip_list.road_sec_to')
                            }}</span>
                        <b-form-input
                            id="end"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import ContractFilter from '../contract/ContractFilter'
import {timeUtils} from '../mixins/TimeUtils'
import TaskTypeSelector from '../tasktype/TaskTypeSelector'
import Multiselect from 'vue-multiselect/src/Multiselect'
import {orderStatus} from '../mixins/WorkManagementMixin'

export default {
    name: 'OrderSearch',
    components: {Multiselect, TaskTypeSelector, ContractFilter},
    mixins: [timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            customer: null,
            contractor: null,
            contractName: null,
            contract: null,
            taskType: null,
            status: [],
            fromDate: null,
            toDate: null
        }
    },
    computed: {
        orderStatusNames() {
            return {
                [orderStatus.STORED]: this.$t('orders.status_stored'),
                [orderStatus.PLAN]: this.$t('orders.status_plan'),
                [orderStatus.OPEN]: this.$t('orders.status_open'),
                [orderStatus.IN_PROGRESS]: this.$t('orders.status_in_progress'),
                [orderStatus.COMPLETED]: this.$t('orders.status_completed'),
                [orderStatus.CANCELLED]: this.$t('orders.status_cancelled'),
                [orderStatus.ABORTED]: this.$t('orders.status_aborted'),
                [orderStatus.APPROVED]: this.$t('orders.status_approved'),
                [orderStatus.INVOICED]: this.$t('orders.status_invoiced'),
                [orderStatus.CLOSED]: this.$t('orders.status_closed'),
            }
        },
        orderStatusValues() {
            return Object.values(orderStatus)
        }
    },

    methods: {

        setCustomer: function (customer) {
            this.customer = customer
        },

        setContractor: function (contractor) {
            this.contractor = contractor
        },

        setContract: function (contract) {
            this.contract = contract
        },

        onSubmit: function () {
            var params = {}
            if (this.customer) {
                params.customer = this.customer
            }
            if (this.contractor) {
                params.contractor = this.contractor
            }
            if (this.contract) {
                params.contract = this.contract
            }
            if (this.status.length > 0) {
                params.status = this.status
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.from = this.localTimeToUtcTime(this.fromDate).getTime()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.to = this.getEndOfTheDay(this.toDate).getTime()
            }
            if (this.taskType != null) {
                params.taskType = this.taskType.id
            }
            if (this.contractName != null) {
                params.contractName = this.contractName
            }
            this.$emit('search', params)
        },

        onReset: function () {
            this.customer = null
            this.contract = null
            this.contracts = null
            this.status = []
            this.fromDate = null
            this.toDate = null
            this.taskType = null
            this.$refs.filter.resetData()
            this.contractName = null
        }
    }
}
</script>
