<template>
    <div class="col-sm-12">
        <h3>{{ $t('ins_wizard.select_order') }}</h3>
        <transition name="fade">
            <!-- Show open contracts  -->
            <div v-if="orders && orders.length > 0" class="col-12 nopads">
                <div v-for="item in orders"
                     v-bind:key="item.id"
                     class="inspection-selector"
                     @click.stop="$emit('onOrderSelected', item)">
            <span class="title inspection-wizard-selector-text">
               {{ item.contract_task_type.task_type.name }} - {{ item.contract.name }}
            </span>
                </div>
            </div>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'

export default {
    name: 'InspectionOrderSelector',
    mixins: [restApi],
    data: function () {
        return {
            orders: [],
            loading: false
        }
    },
    mounted() {
        this.fetchOpenContracts()
    },
    methods: {
        fetchOpenContracts: function () {
            this.orders = []
            this.loading = true
            this.restFetch(this.orderOpenUrl, this.handleResults)
        },
        handleResults: function (response) {
            this.loading = false
            if (response != null) {
                this.orders = response.data
            }
        }
    }
}
</script>
