<template>
    <div class="content-container" style="overflow: hidden">
        <iframe
            v-if="initialized"
            ref="ticketFrame"
            :src="url"
            style="width:100%; height:100%; border:0;"
        />
        <!-- Work assignment editor -->
        <div
            class="details-container"
            v-if="assignmentEditor"
            @click.stop=""
        >
            <div
                class="details dynamic_width"
                style="background: transparent"
                @click.stop=""
            >
                <work-assignment-editor
                    :is-admin="false"
                    :is-observer="isObserver"
                    :work-assignment="workAssignment"
                    @close="cancel"
                    @closeAndUpdate="assignmentAdded"
                />
            </div>
        </div>
        <!-- Loading overlay -->
        <transition name="fade">
            <div
                class="content-container"
                style="background: #f0f0f0"
                v-if="louhiInitializing"
                @click.stop=""
            >
                <div class="spinner"/>
            </div>
        </transition>
        <!-- Work assignment editor -->
        <transition name="fade">
            <div
                class="details-container"
                v-if="observationEditor && !observation"
                @click.stop=""
            >
                <div
                    class="details dynamic_width"
                    style="background: transparent"
                    @click.stop=""
                >
                    <observation-wizard
                        :coordinates="ticketCoordinates"
                        :skip-photos="true"
                        @close="cancel"
                        @closeAndUpdate="observationAdded"
                    />
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div
                class="details-container"
                v-if="observationEditor && observation && observation.id"
                @click.stop=""
            >
                <div
                    class="details dynamic_width"
                    style="background: transparent"
                    @click.stop=""
                >
                    <observation-editor
                        style="pointer-events: all"
                        id="editor"
                        :observations="editObservations"
                        :user="user"
                        :is-admin="false"
                        :is-observer="isObserver"
                        :allow-cancel="false"
                        :allow-assign="false"
                        @close="cancelAdding"
                        @closeAndUpdate="observationUpdated"
                    />
                </div>
            </div>
        </transition>

        <!-- Modal for asking to generate observation or work assignment -->
        <b-modal
            v-model="showQuery"
            centered
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            hide-footer
            :title="$t('tickets.select_operation')">
            <div class="col-12 pb-4">
                <span>{{ $t('tickets.handling_hint')}}</span>
            </div>
            <div v-if="polygonGeometry" class="col-12 pb-4">
                <span>{{ $t('tickets.polygon_geometry')}}</span>
            </div>
            <b-button
                variant="outline-primary"
                class="form-button"
                @click.stop="onAssignmentSelected"
            >
                {{ $t('work_assignment.title') }}
            </b-button>
            <b-button
                variant="outline-success"
                class="form-button"
                :disabled="polygonGeometry"
                @click.stop="onObservationSelected"
            >
                {{ $t('work_assignment.observation') }}
            </b-button>
            <b-button
                variant="secondary"
                class="form-button"
                @click.stop="cancel"
            >
                {{ $t('common.cancel') }}
            </b-button>

        </b-modal>
    </div>
</template>

<script>

import WorkAssignmentEditor from "../workassignment/WorkAssignmentEditor";
import ObservationWizard from "../observation/ObservationWizard";
import ObservationEditor from "../observation/ObservationEditor";
import {restApi} from "../mixins/RestApiMixin";
import {coordinateConverter} from "../mixins/CoordinateConversionMixin";

export default {
    name: 'TicketSystem',
    components: {ObservationEditor, ObservationWizard, WorkAssignmentEditor},
    mixins: [restApi, coordinateConverter],
    props: {
        user: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        feedbackGuid: {
            type: String,
            default: null
        },
        hasCompanyTicketUrl: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            OBSERVATION: 1,
            ASSIGNMENT: 2,
            url: '',
            initialized: false,
            workAssignment: null,
            observation: null,
            showQuery: false,
            assignmentEditor: false,
            observationEditor: false,
            editObservations: [],
            ticket: null,
            louhiInitializing: true
        }
    },
    computed: {
        ticketCoordinates: function () {
            return this.wktToGeomArray(this.ticket.data.feedbackGeom)
        },
        polygonGeometry: function () {
            if (this.ticket) {
                return this.ticket.data.feedbackGeom.indexOf('POLYGON') >= 0
            }
            return false
        }
    },
    created () {
        if (this.hasCompanyTicketUrl) {
            this.fetchTicketSystemUrl()
        } else {
            this.initTicketSystem()
        }
    },
    beforeDestroy () {
        window.removeEventListener('message', this.handleMessage)
    },
    methods: {
        fetchTicketSystemUrl() {
                this.restFetchParams(this.apiUrl, {type: 4}, this.handleResponse)
        },

        handleResponse: function (response) {
            if (response && response.data) {
                this.url = response.data
            }
            this.initTicketSystem()
        },
        initTicketSystem() {
            const baseUrl = this.url ? this.url + "/Account/Login?ReturnUrl="
                : process.env.VUE_APP_GLOBAL_TICKET_URL + "/Account/Login?ReturnUrl=" // TODO - USE ENV VARIABLE FOR SYSTEM WIDE URL!
            if (this.feedbackGuid) {
                this.url = baseUrl + encodeURIComponent("/Applications/Feedback/HandlingUI/#/select/?time=" + (new Date()).getTime() + "&guid=" + this.feedbackGuid)
            } else {
                this.url = baseUrl + encodeURIComponent("/Applications/Feedback/HandlingUI/?time=" + (new Date()).getTime())
            }
            window.addEventListener('message', this.handleMessage)
            this.initialized = true
            // TODO - Remove time out when Louhi sends init complete message!
            setTimeout(() =>  {
                this.louhiInitializing = false}, 5000 )
        },
        handleMessage(event) {
            if (this.initialized) {
                if (event.data && event.data.operation) {
                    if (event.data.operation === 'requestToken') {
                        this.handleAuthRequest()
                    } else if (event.data.operation === 'Feedback-initialized') {
                        this.louhiInitializing = false
                    } else if (event.data.operation === 'FeedbackRouta') {
                        this.onTicketReceived(event.data)
                    }
                }
            }
        },
        handleAuthRequest() {
            this.$refs.ticketFrame.contentWindow.postMessage({
                operation: 'RoutaLogin',
                parameters: {
                    RoutaToken: this.$store.state.token
                }
            }, "*")
        },
        showQueryModal: function () {
            this.showQuery = true
        },
        onTicketReceived: function (ticket) {
          this.ticket = ticket;
          this.showQueryModal()
        },
        onObservationSelected: function () {
            this.showQuery = false
            this.observationEditor = true
        },
        getTicketInfo: function () {
            let info = ''
            if (this.ticket) {
                info = this.ticket.data.feedbackSubject + ': ' + this.ticket.data.feedbackText;
                if (this.ticket.data.feedbackCompany) {
                    info += ' ('+this.ticket.data.feedbackCompany+')'
                }
            }
            return info
        },
        initializeObservation: function  () {
            this.observation.info = this.getTicketInfo()
            this.observation.geometry = this.wktToGeom(this.ticket.data.feedbackGeom)
        },
        onAssignmentSelected: function () {
            this.showQuery = false
            this.initializeAssignment()
            this.assignmentEditor = true
        },
        initializeAssignment: function  () {
            this.workAssignment = {
                info: this.getTicketInfo(),
                email: this.ticket.data.feedbackEmail,
                geometry: this.wktToGeom(this.ticket.data.feedbackGeom)
            }
        },
        wktToGeomArray: function (wkt) {
            // Called as observation editor parameters!
            let geom = this.wktToGeom(wkt)
            if (geom.point) {
                return [{lat: geom.point.y, lng: geom.point.x}]
            }
            if (geom.line_string) {
                let coordinates = []
                geom.line_string.points.forEach(point => coordinates.push({lat: point[1], lng: point[0]}))
                return coordinates
            }
            return []
        },

        wktToGeom: function (wkt) {
          if (wkt.indexOf('POINT') === 0) {
              let bracketOpen = wkt.indexOf('(')
              let cutStr = wkt.substr(bracketOpen+1)
              cutStr = cutStr.trim()
              cutStr = cutStr.substr(0, cutStr.length-1)
              let separator = cutStr.indexOf(' ')
              let lon = cutStr.substr(0, separator)
              let lat = cutStr.substr(separator+1)
              const convertedCoordinates = this.tm35FinTowgs84(lat, lon)
              return {point: {x: convertedCoordinates[0], y: convertedCoordinates[1]}}
          }
          if (wkt.indexOf('LINESTRING') === 0) {
                let bracketOpen = wkt.indexOf('(')
                let cutStr = wkt.substr(bracketOpen+1)
                cutStr = cutStr.trim()
                cutStr = cutStr.substr(0, cutStr.length-1)
                let coordinates = []
                let splits = cutStr.split(',')
                splits.forEach(coordStr => {
                    coordStr = coordStr.trim()
                    let separator = coordStr.indexOf(' ')
                    let lon = coordStr.substr(0, separator)
                    let lat = coordStr.substr(separator+1)
                    coordinates.push(this.tm35FinTowgs84(lat, lon))
                })
                return {line_string: {points: coordinates}}
          }
          if (wkt.indexOf('POLYGON') === 0) {
              let bracketOpen = wkt.indexOf('(')
              let cutStr = wkt.substr(bracketOpen + 1)
              // let rings = [] - needed when many rings are supported
              cutStr = cutStr.trim()
              // Replace brackets around rings
              cutStr = cutStr.replaceAll('(', '')
              cutStr = cutStr.replaceAll(')', ';')
              cutStr = cutStr.replaceAll(';;', ';')
              // Go through all rings
              let ringsArray = cutStr.split(';')
              let coordinates = []
              ringsArray.forEach(ring => {
                  // let coordinates = [] - many rings
                  let ringSplit = ring.split(',')
                  ringSplit.forEach(coordStr => {
                      coordStr = coordStr.trim()
                      if (coordStr) {
                          let separator = coordStr.indexOf(' ')
                          let lon = coordStr.substr(0, separator)
                          let lat = coordStr.substr(separator+1)
                          coordinates.push(this.tm35FinTowgs84(lat, lon))
                      }
                  })
                  // rings.push(coordinates) - many rings
              })
              // return { polygon: {points: rings}} - many rings
              return { polygon: {points: coordinates}}
          }
          return null
        },
        assignmentAdded: function () {
            // Inform Louhi about added assignment
            let message = {
                operation: 'RoutaFeedback',
                data: {
                    feedbackId: this.ticket.data.feedbackId,
                    statusId: 3, // 3 = handled!
                }
            }
            this.$refs.ticketFrame.contentWindow.postMessage(message, "*")
            this.cancelAdding()
        },
        observationAdded: function (observation) {
            // Inform Louhi about added observation
            this.$refs.ticketFrame.contentWindow.postMessage({
                operation: 'RoutaFeedback',
                data: {
                    feedbackId: this.ticket.data.feedbackId,
                    statusId: 3, // 3 = handled!
                }
            }, "*")
            // Fetch obs details and show in editor
            if (observation) {
                this.loading = true
                this.restFetch(this.observationDetailsUrl + '/' + observation.id, this.handleObservationDetails, this.handleDetailsError)
            }
        },
        handleObservationDetails: function (response) {
            if (response && response.data) {
                this.observation = response.data
                this.initializeObservation()
                this.editObservations = [this.observation]
            }
        },
        handleDetailsError: function () {
            // TODO - Show error message!
        },
        observationUpdated: function () {
            // All done
            this.cancelAdding()
        },
        handleObservationUpdateError: function () {
          // TODO - Show error message!
        },
        cancel: function () {
            // Inform Louhi about not handling feedback
            this.$refs.ticketFrame.contentWindow.postMessage({
                operation: 'RoutaFeedback',
                data: {
                    feedbackId: this.ticket.id,
                    statusId: 1, // 1 =  not handled!
                }
            }, "*")
            this.cancelAdding()
        },
        cancelAdding: function () {
            this.ticket = null
            this.showQuery = false
            this.observation = null
            this.workAssignment = null
            this.assignmentEditor = false
            this.observationEditor = false
        }
    }
}
</script>
