<template>
    <div v-if="defect" class="col-sm-12 nopads">
        <b-row class="area-map-row">
            <div class="col-sm-6 nopads">
                    <span class="item-detail-title">{{ $t('area_report_details.area_name') }}</span>
                    <span class="item-detail-text">
                    {{ defect.area ? defect.area.name : '-' }}
                </span>
                    <span class="item-detail-title">{{ $t('areas.equipment_name') }}</span>
                    <span class="item-detail-text">{{ defect.area_equipment.name }}</span>
                        <span class="item-detail-title">{{ $t('work_assignment.info') }}</span>
                        <span
                            v-if="defect.description"
                            class="item-detail-text"
                        >
                    {{ defect.description }}
                    </span>
            </div>

            <div class="col-6 item-map">
                <div class="map-container" v-if="initialized">
                    <map-container
                        ref="equipmentMap"
                        :find-user="false"
                        @onMapChanged="init"
                    />
                </div>
            </div>
        </b-row>

        <!--
        <b-row v-if="defect.area_equipment" class="editor-map-container">
            <div class="map-container">
                <map-container
                    ref="equipmentMap"
                    :find-user="false"
                />
            </div>
        </b-row>
        -->
        <!-- Details -->
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {observationHelper} from '../mixins/ObservationMixin'
import MapContainer from "../map/MapContainer";

export default {
    name: 'WorkAssignmentAreaDefect',
    mixins: [timeUtils, observationHelper],
    components: {MapContainer},
    props: {
        defect: {
            type: Object,
            default: function () {
                return null
            }
        }
    },
    data() {
        return {
            initialized: false
        }
    },
    mounted() {
        this.initialized = true
    },
    methods: {
        async init() {
            if (this.$refs.equipmentMap.getMap()) {
                this.$refs.equipmentMap.getMap().addEquipmentMarker(this.defect.area_equipment)
            }
        }
    }
}
</script>
<style scoped>
.map-container {
    height: 250px;
    margin-bottom: 0.5em;
}
</style>
