<template>
    <div class="col-sm-12">
        <culvert-search
            @search="fetchCulverts"
        />

        <h3>{{ $t('ins_wizard.select_culvert') }}</h3>
        <transition name="fade">
            <!-- Show open contracts  -->
            <div v-if="results && results.length > 0" class="col-12 nopads">
                <div v-for="item in results"
                     v-bind:key="item.digiroad_id"
                     class="inspection-selector"
                     @click.stop="$emit('onCulvertSelected', item)">
                    <span class="title inspection-wizard-selector-text">{{ item.type }} - {{ item.digiroad_id }}</span>
                </div>
            </div>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import CulvertSearch from "@/components/culvert/CulvertSearch";

export default {
    name: 'inspection-culvert-selector',
    components: {CulvertSearch},
    mixins: [restApi],
    data: function () {
        return {
            results: [],
            loading: false
        }
    },
    mounted() {
        this.fetchCulverts()
    },
    methods: {
        fetchCulverts: function (params) {
            this.loading = true
            this.results = []
            this.restFetchParams(this.culvertUrl, params, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        }
    }
}
</script>
