<template>
    <div class="col-sm-12 nopads">
        <div :class="{'search-form' : showTitles}">
            <div v-if="showTitles" class="col-sm-12 span-header nopads pl-3 pr-3">
                <span>1. {{ $t('daily_summary.choose_time') }}</span>
            </div>
            <b-row class="nopads">
                <div class="col-sm-6 nopads pl-3 pr-3 pt-1">
                    <b-form-group
                        class="nopads"
                        label-for="fromDate"
                    >
                        <span class="span-title">
                          {{ $t('contract_reports.time_from') }} - {{ $t('daily_summary.date') }}
                        </span>
                        <b-form-input
                            id="fromDate"
                            v-model="from"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-6 nopads pl-3 pl-sm-1 pr-3 p-1">
                    <b-form-group
                        class="nopads"
                        label-for="toDate"
                    >
                        <span class="span-title">
                          {{ $t('contract_reports.time_to') }} - {{ $t('daily_summary.date') }}
                        </span>
                        <b-form-input
                            id="toDate"
                            v-model="to"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div v-if="!hideContractSelection" class="col-sm-12 span-header nopads pl-3 pr-3">
                <span  v-if="showTitles">2. {{ $t('contract_reports.choose_contract') }}</span>
            </div>
            <b-row class="nopads p-2">
                <div v-if="!hideContractSelection" class="col-sm-12 nopads" style="padding-left: .3em; padding-right: .3em">
                    <open-contract-filter
                        :to="to"
                        :from="from"
                        :showOnlyMyContractsOption="true"
                        :show-company="false"
                        @loading="loading=true"
                        @loadingCompleted="loading=false"
                        @contractSelected="setContract"
                    />
                </div>
                <div class="col-sm-12 nopads" style="padding-left: .3em; padding-right: .3em">
                    <span class="span-title">
                      {{ $t('contract_reports.sub_reports') }}
                    </span>
                    <b-form-group>
                        <b-form-checkbox
                            v-for="(text, key) in options"
                            class="col-sm-2"
                            :checked="reportSections"
                            @change="$emit('update:report-sections', $event)"
                            :key="key"
                            :value="key"
                            size="sm"
                            inline
                        >
                            {{ text }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="nopads p-2">
                <div class="col-sm-12 nopads" style="padding-left: .3em; padding-right: .3em">
                    <b-form-group>
                        <b-form-checkbox
                            :checked="isAllChecked"
                            @change="toggleAll"
                            size="sm"
                            inline
                        >
                            {{ $t('observations.select_all') }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="text-right">
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="primary"
                        class="form-button"
                        @click.stop="emitSearch"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                </div>
            </b-row>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: "contract-report-filter",
    components: {OpenContractFilter},
    mixins: [timeUtils],
    props: {
        reportSections: {
            type: Array,
            default() {
                return [];
            }
        },
        hideContractSelection: {
            type: Boolean,
            default: false
        },
        showTitles: {
            type: Boolean,
            default: true
        },
        fromDate: {
            type: String,
            default: null
        },
        toDate: {
            type: String,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            to: null,
            from: null,
            contract: null,
        }
    },
    mounted: function () {
        this.to = this.toDate ? this.toDate : this.getYesterday()
        this.from = this.fromDate ? this.fromDate : this.getYesterday()
    },
    computed: {
        options() {
            return {
                contractCosts: this.$t('contracts.costs'),
                deliveryNotes: this.$t('contract_reports.unload_records.title_notes'),
                tripDeliveries: this.$t('daily_summary.cost_calculated'),
                pavingMethods: this.$t('contract_reports.paving_methods'),
                materials: this.$t('material_reports.title'),
                workReports: this.$t('work_report.materials'),
                workAssignments: this.$t('menu.work_assignment'),
                trips: this.$t('daily_summary.title'),
                trafficControl: this.$t('menu.traffic_control'),
                areaWorkReports: this.$t('contract_reports.area_work_reports'),
                skiTrackReports: this.$t('sports.ski_report_title'),
                observationReport: this.$t('observations.observations'),
                dangerReports: this.$t('danger_report.title'),
                roadSigns: this.$t('contract_reports.road_signs'),
                inspections: this.$t('contract_reports.other_inspections'),
                areaInspections: this.$t('contract_reports.area_inspections'),
                weatherData: this.$t('weather_station.data_title'),
            }
        },
        isAllChecked() {
            return this.reportSections.length === Object.keys(this.options).length;
        },
    },
    watch: {
        toDate() {
            this.to = this.toDate ? this.toDate : this.getYesterday()
        },
        fromDate() {
            this.from = this.fromDate ? this.fromDate : this.getYesterday()
        }
    },
    methods: {
        emitSearch: function () {
            if ((this.contract || this.hideContractSelection) && this.from && this.to) {
                this.$emit('search', {
                        dateTo: this.getEndOfTheDay(this.to).getTime(),
                        dateFrom: new Date(this.from).getTime(),
                        contract: this.contract ? this.contract.id : undefined,
                    },
                    this.contract ? this.contract : undefined
                )
            }
        },
        setContract: function (contract) {
            this.contract = contract
        },
        toggleAll() {
            const allKeys = Object.keys(this.options);
            if (!this.isAllChecked) {
                this.$emit('update:report-sections', allKeys);
            } else {
                this.$emit('update:report-sections', []);
            }
        }
    }
}
</script>
