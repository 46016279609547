import axios from "axios";

export const hereService = {
    data: function () {
      return {
          geocodeUrl: 'https://geocoder.api.here.com/6.2/geocode.json',
          isLoadingSuggestions: false
      }
    },
    methods: {
        addressToCoordinates: function (address, successCallback, errorCallback) {
            if (!address) {
                return null
            }
            axios.get(this.geocodeUrl, { params: {
                    searchtext: address,
                    app_id: 'uGdKACWRCkFTYxvG2s5U',
                    app_code: 'rmGEXinKXqAHOIR5cnVdwQ',
                    useHTTPS: true
                }})
                .then(result => {
                    let returnValue = null;
                    if (result && result.data && result.data.Response && result.data.Response.View && result.data.Response.View[0]) {
                        returnValue = {
                            x: result.data.Response.View[0].Result[0].Location.DisplayPosition.Longitude,
                            y: result.data.Response.View[0].Result[0].Location.DisplayPosition.Latitude
                        }
                    }
                    successCallback(returnValue);
                })
                .catch(e => {
                    if (errorCallback) {
                        errorCallback(e)
                    } else {
                        successCallback(null)
                    }
                })
        },
        searchByAddress: function (address, successCallback, errorCallback) {
            if (!address) {
                return null
            }
            this.isLoadingSuggestions = true
            axios.get(this.geocodeUrl, { params: {
                    searchtext: address,
                    app_id: 'uGdKACWRCkFTYxvG2s5U',
                    app_code: 'rmGEXinKXqAHOIR5cnVdwQ',
                    useHTTPS: true
                }})
                .then(result => {
                    if (result && result.data && result.data.Response && result.data.Response.View && result.data.Response.View[0]) {
                        const resultsInFinland = result.data.Response.View[0].Result.filter(item => item.Location.Address.Country === "FIN")

                        if (resultsInFinland.length === 0) return successCallback([])

                        let formattedResults = []
                        resultsInFinland.forEach(result => {
                            formattedResults.push({
                                id:     result.Location.LocationId,
                                lat:    result.Location.DisplayPosition.Latitude,
                                lon:    result.Location.DisplayPosition.Longitude,
                                text:   this.getAddressText(result)
                            })
                        })

                        successCallback(formattedResults)
                        return
                    }
                    successCallback([])
                })
                .catch(e => {
                    if (errorCallback) {
                        errorCallback(e)
                    } else {
                        successCallback(null)
                    }
                }).finally(() => this.isLoadingSuggestions = false)
        },

        getAddressText(item) {
            if (item.Location.Address) {
                let text = item.Location.Address.Street
                text += ' ' + item.Location.Address.HouseNumber
                text += ', ' + item.Location.Address.PostalCode
                text += ' ' + item.Location.Address.City
                return text
            }
            return '-'
        }
    }
}
