<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-row>
            <div class="col-sm-6">
                <b-form-group
                    class="title"
                    label-for="type"
                >
                    <b-form-select
                        id="contracts"
                        v-model="type"
                        size="mb"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                            >
                                {{ $t('work_permission.select_type') }}
                            </option>
                        </template>
                        <option
                            v-for="option in getTypes()"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col-sm-6">
                <b-form-group
                    class="title"
                    label-for="type"
                >
                    <b-form-select
                        id="contracts"
                        v-model="status"
                        size="mb"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                            >
                                {{ $t('work_permission.select_status') }}
                            </option>
                        </template>
                        <option
                            v-for="option in getStatus()"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
        </b-row>
        <div class="col-sm-12 button-container">
            <b-button type="submit"
                      variant="primary"
                      class="result-button"
                      size="sm"
                      @click.stop="emitSearch">
                {{ $t('ins_report.search') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import {workPermissionHelper} from '../mixins/WorkPermissionMixin'

export default {
    name: 'work-permission-search',
    mixins: [workPermissionHelper],
    data: function () {
        return {
            type: null,
            status: null
        }
    },
    methods: {
        emitSearch: function () {
            this.$emit('search', {type: this.type, status: this.status})
        }
    }
}
</script>
