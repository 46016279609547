<template>
    <div class="col-sm-12 pl-3 pr-3 pb-2">
        <div class="list-content">
            <div class="col-sm-12 form-title editor-title">
                {{ editOld ? $t('delivery_note.edit') : $t('delivery_note.add_new') }}
            </div>
            <b-form
                id="delivery-note-editor"
                @submit.prevent="onSubmit"
                @reset="onReset"
            >
                <b-row v-if="(!noteUpdate.id || noteUpdate.id < 0) && setCreated">
                    <div class="col-sm-5">
                        <span class="search-title">
                          {{ $t('delivery_note.date') }}
                        </span>
                            <datetime
                                v-model="createdTime"
                                type="datetime"
                                format="dd.MM.yyyy HH:mm"
                                class="routa-input"
                                :placeholder="$t('observations_videos.search_input_format')"
                            />
                    </div>
                </b-row>

                <b-row class="nopads">
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.contract') }}</span>
                        <b-form-select
                            v-model="contract"
                            size="sm"
                            @change="selectContract"
                            :disabled="!!loading"
                        >
                            <option :value="null">
                                {{ $t('delivery_note.form.select_contract') }}
                            </option>
                            <option
                                v-for="contract in contracts"
                                :key="contract.id"
                                :value="contract.id"
                            >
                                {{ contract.name }}
                            </option>
                        </b-form-select>
                        <b-form-group v-if="showOnlyMyContractsOption">
                            <b-form-checkbox
                                v-model="onlyUserOwnedContracts"
                                size="md"
                                type="text"
                                value=1
                                unchecked-value=0>
                                {{ $t('contracts.only_user_owned_contracts') }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                    <div class="col-sm-5">
                        <contract-order-filter
                            v-model="order"
                            :pre-selected-order="noteUpdate.order ? noteUpdate.order.id : null"
                            :contract-id="contract"
                            :show-executor-text="true"/>
                    </div>
                </b-row>
                <!-- TODO - If no station is set, add row for storage selection? -->
                <b-row class="nopads" v-if="noteUpdate.station_material && noteUpdate.station_material.id">
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.material') }}</span>
                        <b-form-select
                            v-model="noteUpdate.station_material.id"
                            size="sm"
                            :disabled="!station || materials.length < 1 || !!loading"
                        >
                            <option :value="-1">
                                {{ $t('delivery_note.form.select_material') }}
                            </option>
                            <option
                                v-if="materials.length < 1 && (noteUpdate.station_material.id > 0)"
                                :value="noteUpdate.station_material.id"
                            >
                                {{
                                    noteUpdate.station_material.material.name + ' (' + noteUpdate.station_material.type_test_code + ')'
                                }}
                            </option>
                            <option
                                v-for="material in materials"
                                :key="material.id"
                                :value="material.id"
                            >
                                {{ material.material.name + ' (' + material.type_test_code + ')' }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.amount') }}</span>
                        <b-row class="nopads">
                            <div class="col-7 nopads pr-1">
                                <b-form-input
                                    :value="noteUpdate.amount"
                                    @input="i => noteUpdate.amount = Number(i)"
                                    type="number"
                                    step="0.01"
                                    size="sm"
                                    :disabled="!!loading"
                                />
                            </div>
                            <div class="col-5 nopads">
                                <b-form-select
                                    v-model="unit"
                                    size="sm"
                                    :disabled="!!loading"
                                >
                                    <option :value="-1">
                                        {{ $t('delivery_note.form.select_unit') }}
                                    </option>
                                    <option
                                        v-for="unit in units"
                                        :key="unit.value"
                                        :value="unit.value"
                                        :disabled="!!loading"
                                    >
                                        {{ unit.value }}
                                    </option>
                                </b-form-select>
                            </div>
                        </b-row>
                    </div>
                </b-row>
                <b-row class="nopads" v-if="noteUpdate.storage_material && noteUpdate.storage_material.id">
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.material') }}</span>
                        <span>{{ noteUpdate.storage_material.name }}</span>
                        <!-- TODO - Storage material changing possible??? -->
                    </div>
                    <div class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.amount') }}</span>
                        <b-row class="nopads">
                            <div class="col-7 nopads pr-1">
                                <b-form-input
                                    :value="noteUpdate.amount"
                                    @input="i => noteUpdate.amount = Number(i)"
                                    type="number"
                                    step="0.01"
                                    size="sm"
                                    :disabled="!!loading"
                                />
                            </div>
                            <div class="col-5 nopads">
                                <b-form-select
                                    v-model="unit"
                                    size="sm"
                                    :disabled="!!loading"
                                >
                                    <option :value="-1">
                                        {{ $t('delivery_note.form.select_unit') }}
                                    </option>
                                    <option
                                        v-for="unit in units"
                                        :key="unit.value"
                                        :value="unit.value"
                                        :disabled="!!loading"
                                    >
                                        {{ unit.value }}
                                    </option>
                                </b-form-select>
                            </div>
                        </b-row>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <!-- Vehicle dropdown -->
                    <div v-if="!note.vehicle || !note.vehicle.license_plate" class="col-sm-5">
                        <span class="search-title">{{ $t('trip_list.vehicle') }}</span>
                        <multiselect
                            v-model="vehicle"
                            class="multiselect_routa"
                            :placeholder="$t('trip_list.select_vehicle')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="true"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="vehicleOptions"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                              <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                              </span>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <div class="option__desc">
                                    <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </div>
                    <!-- License plate for outside organization vehicles -->
                    <div v-else class="col-sm-5">
                        <span class="span-title">{{ $t('delivery_note.vehicle') }}</span>
                        <b-form-input
                            v-model="noteUpdate.vehicle.license_plate"
                            placeholder="ABC-123"
                            size="sm"
                            :disabled="!!loading"
                        />
                    </div>
                    <div class="col-sm-5" v-if="editOld">
                        <span class="span-title">{{ $t('delivery_note.status') }}</span>
                        <b-form-select
                            v-model="noteUpdate.status"
                            size="sm"
                            :disabled="!!loading"
                        >
                            <option
                                v-for="status in getStatus()"
                                :key="status.value"
                                :value="status.value"
                            >
                                {{ getStatusString(status.value) }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container pb-2">
                    <b-button
                        type="reset"
                        variant="primary"
                        class="result-button"
                        size="sm"
                    >
                        {{ editOld ? $t('common.back') : $t('delivery_note.clear') }}
                    </b-button>
                    <b-button
                        type="submit"
                        variant="success"
                        class="result-button"
                        size="sm"
                        :disabled="!!loading"
                    >
                        {{ editOld ? $t('common.save') : $t('delivery_note.add') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="!!loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {deliveryNoteHelper, units} from "@/components/mixins/DeliveryNoteMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import {EventBus} from "@/event-bus";
import {orderMixin} from "@/components/mixins/OrderMixin";
import {vehicleTypes} from "@/components/mixins/VehicleMixin";
import ContractOrderFilter from "../order/ContractOrderFilter";

export default {
    name: "DeliveryNoteEditor",
    components: {ContractOrderFilter},
    mixins: [deliveryNoteHelper, restApi, orderMixin, vehicleTypes],
    props: {
        note: {
            type: Object,
            default: null
        },
        station: {
            type: Number,
            default: null
        },
        contracts: {
            type: Array,
            default() {
                return []
            }
        },
        materials: {
            type: Array,
            default() {
                return []
            }
        },
        setCreated: {
            type: Boolean,
            default: true
        },
        showOnlyMyContractsOption: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            noteUpdate: this.jsonToItem(this.note),
            contract: null,
            order: null,
            vehicleOptions: [],
            vehicle: undefined, // Dropdown selection
            units: [
                {value: units.TONS},
                {value: units.CUBIC_METERS}
            ],
            unit: null,
            loading: 0,
            createdTime: null,
            onlyUserOwnedContracts: 0
        }
    },
    mounted() {
        this.initValues();
        this.fetchVehicles()
    },
    computed: {
        editOld: function () {
            return this.noteUpdate && this.noteUpdate.id > 0
        }
    },
    watch: {
        note: function () {
            this.noteUpdate = this.jsonToItem(this.note)
            this.initValues()
        },
        onlyUserOwnedContracts: function() {
            this.$emit('onlyUserOwnedContractSelected', this.onlyUserOwnedContracts, "editor")
        },
        station: function () {
            if (!this.editOld) {
                this.onReset()
            }
        },
        order: function () {
            this.noteUpdate.order = this.order ?  {id: this.order.id} : {id: -1}
        }
    },
    methods: {

        initValues() {
            if (this.noteUpdate.unit) {
                this.unit = this.noteUpdate.unit
            }
            if (this.noteUpdate.order) {
                this.order = this.noteUpdate.order
                this.contract = this.noteUpdate.order && this.noteUpdate.contract ? this.noteUpdate.order.contract.id : null
            }
            if (this.noteUpdate.vehicle.license_plate !== '') {
                this.vehicle = this.noteUpdate.vehicle
            }
            if (this.noteUpdate.created) {
                this.createdTime = this.noteUpdate.created
            }
            if (this.editOld) {
                this.contract = this.note.order.contract.id
                this.selectContract(this.contract, false)
            } else {
                if (this.contract) {
                    this.noteUpdate.contract = {id: this.contract}
                }
                if (this.order) {
                    this.noteUpdate.order = {id: this.order}
                }
                if (this.vehicle) {
                    this.noteUpdate.vehicle = {id: this.vehicle}
                }
            }
        },

        // Get vehicles for dropdown menu
        fetchVehicles: function () {
            this.vehicleOptions = []
            this.restFetch(this.vehicleUrl, this.handleVehicles)
        },

        handleVehicles: function (response) {
            if (response && response.data) {
                response.data.forEach(item => {
                    if (item.vehicle_type === vehicleTypes.TRUCK) {
                        this.vehicleOptions.push({
                            value: item.id,
                            name: item.make + ' ' + item.vehicle_model + ' - ' + item.license_plate,
                            license_plate: item.license_plate
                        })
                    }
                })
            }
        },

        onReset: function () {
            this.noteUpdate = this.jsonToItem(this.note)
            this.contract = null
            this.onlyUserOwnedContracts = 0
            this.order = null
            this.createdTime = null
            if (this.editOld) {
                this.$emit('close')
            }
        },
        onSubmit: function () {
            this.noteUpdate.unit = this.unit
            let jsonNote = this.validateInput(this.noteUpdate)
            if (jsonNote !== undefined) {
                this.loading++
                if (!this.editOld) {
                    this.restAdd(this.materialDeliveryUrl, jsonNote, this.addSuccess, this.fail)
                } else {
                    this.restUpdate(this.materialDeliveryUrl, jsonNote, this.addSuccess, this.fail)
                }
            }
        },
        validateInput: function (note) {
            if (note.order.id < 0) {
                EventBus.$emit('show-alert', this.$t('delivery_note.form_validation.order'))
                return undefined
            }
            // Must be either station or storage material linked!
            if ((!note.station_material || note.station_material.id < 0) && (
                !note.storage_material || note.storage_material.id < 0 )) {
                EventBus.$emit('show-alert', this.$t('delivery_note.form_validation.material'))
                return undefined
            }
            if (note.amount === '' || note.unit < 0) {
                EventBus.$emit('show-alert', this.$t('delivery_note.form_validation.amount_unit'))
                return undefined
            }
            if (this.vehicle && this.vehicle.license_plate && this.vehicle.license_plate !== '') {
                note.vehicle = this.vehicle
            } else {
                EventBus.$emit('show-alert', this.$t('delivery_note.form_validation.vehicle'))
                return undefined
            }
            if (this.setCreated && !this.createdTime) {
                EventBus.$emit('show-alert', this.$t('delivery_note.form_validation.created'))
                return undefined
            } else if (this.setCreated) {
                note.created = this.createdTime
            }
            return note
        },
        addSuccess: function () {
            this.loading--
            if (!this.editOld) {
                EventBus.$emit('show-alert', this.$t('common.alert_add_success'), 'success')
                // Keep form info (except vehicle and amount) for following notes
                this.vehicle = null
                this.noteUpdate.vehicle = null
                this.noteUpdate.amount = ''
                this.createdTime = null
                this.$emit('update', true)
            } else {
                EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
                this.$emit('update')
                this.onReset()
            }
        },
        fail: function () {
            if (!this.editOld) {
                EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
            } else {
                EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            }
            this.loading--
        },
        selectContract: function (id, update = true) {
            if (!this.editOld || (this.editOld && update)) {
                this.noteUpdate.order.id = -1
            }
        }
    }
}
</script>
