<template>
    <div class="col-sm-12 list-content">
        <div class="col-sm-12 list-content result-table mb-3  p-1">
            <b-table
                v-if="tableItems"
                size="sm"
                class="mt-3"
                show-empty
                :small="true"
                :responsive="true"
                :fields="fields"
                :items="tableItems"
                style="cursor: pointer"
                @row-clicked="emitEdit"
            >
                <template slot="empty">
                    <div class="text-center my-2">
                        {{ $t('common.no_results') }}
                    </div>
                </template>
                <template v-slot:cell(created_time)="row">
                    {{ toLocalTime(row.value) }}
                </template>
                <template v-slot:cell(date_from)="row">
                    {{ toLocalTime(row.value) }}
                </template>
                <template v-slot:cell(date_to)="row">
                    {{ toLocalTime(row.value) }}
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: "WeighingNoteList",
    mixins: [timeUtils],
    props: {
        notes: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fields: [
                {key: 'created_time', label: this.$t('weighing_notes.date_created'), sortable: true},
                {key: 'contract', label: this.$t('delivery_note.contract'), sortable: true},
                {key: 'order', label: this.$t('delivery_note.order'), sortable: true},
                {key: 'amount', label: this.$t('delivery_note.amount'), sortable: true},
                {key: 'date_from', label: this.$t('trip_list.road_sec_from'), sortable: true},
                {key: 'date_to', label: this.$t('trip_list.road_sec_to'), sortable: true},
            ],
            tableItems: [],
        }
    },
    watch: {
        notes: function () {
            this.initTableItems()
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        initTableItems: function () {
            this.tableItems = []
            this.notes.forEach(item => {
                this.tableItems.push(
                    {
                        created_time: item.created_time,
                        contract: item.contract.name,
                        order: item.order ? item.order.task_type.name : '',
                        amount: item.amount + " " + item.unit,
                        id: item.id,
                        date_from: item.date_from,
                        date_to: item.date_to
                    }
                )
            })
        },
        emitEdit: function (note) {
            if (this.isObserver) {
                let item = this.notes.find(item => item.id === note.id)
                if (item) {
                    this.$emit('edit', item)
                }
            }
        },
    }
}
</script>
<style scoped lang="scss">
.transport {
    color: #ffffff;
    background: #1F7F35;
    padding: .2em;
}

.unloading {
    color: #ffffff;
    background: #1E88E5;
    padding: .2em;
}

</style>
