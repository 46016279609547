<template>
    <div v-if="dangerReports && dangerReports.length > 0" class="nopads">
        <b-row>
            <b-col class="nopads report-header" cols="10">
                <span class="section-title" style="display: block">{{ $t('danger_report.title') }} ({{dangerReports.length}})</span>
            </b-col>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : reportsVisible}"
                    v-b-toggle.dangerDetails
                    @click.stop="reportsVisible = !reportsVisible"/>
            </b-col>
        </b-row>
        <b-row
            cols="12"
            class="show-print"
            v-if="reportsVisible"
        >
            <div class="col-6 nopads chart-container">
                    <pie-chart
                        class="routa-pie dont-print p-4"
                        ref="pieHandledDanger"
                        :labels="pieLabels"
                        :colors="pieColors"
                        :data="pieData"
                        title="Käsitelty"
                    />
                    <img
                        class="print-only routa-pie-print"
                        id="pieHandledDangerImage"
                        alt="danger-handled pie chart"
                    />
            </div>

            <div class="col-6 nopads chart-container pt-4">
                <bar-chart
                    class="routa-bars dont-print mt-4"
                    ref="barReports"
                    :labels="chartLabels"
                    :data="chartData"
                    :colors="chartColors"
                />
                <img
                    class="print-only routa-charts-print"
                    id="barDangerReportsImage"
                    alt="Danger reports image"
                />
            </div>
        </b-row>
        <b-collapse
            v-if="reportsVisible"
            id="dangerDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <danger-report-list
                :results="dangerReports"
                @details="showDetails"
            />
        </b-collapse>

        <b-modal
            :visible="detailsDangerReport !== null"
            @hidden="detailsDangerReport = null"
            body-class="fill-modal"
            size="lg"
            :hide-header="true"
            :hide-footer="true"
        >
            <danger-report-details
                v-if="detailsDangerReport !== null"
                id="details"
                ref="details"
                :report="detailsDangerReport"
                :is-observer="isObserver"
                @close="detailsDangerReport = null"
                @closeAndUpdate="closeAndUpdate"
            />
        </b-modal>
        <hr class="report-divider nopads"/>
    </div>
</template>


<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import DangerReportList from "@/components/dangerreports/DangerReportList";
import BarChart from "@/components/chart/BarChart";
import {dangerTypes} from '../mixins/DangerMixin'
import PieChart from "../chart/PieChart";
import DangerReportDetails from "../dangerreports/DangerReportDetails";

export default {
    name: "contract-danger-reports",
    mixins: [timeUtils, restApi],
    components: {DangerReportDetails, PieChart, BarChart, DangerReportList},
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        isObserver: Boolean,
    },
    data: function () {
        return {
            dangerReports: [],
            detailsDangerReport: null,
            reportsVisible: false,
            chartColors: ["#336677", '#faa633', "#992612"],
            chartLabels: [
                this.$t('danger_report.event_type_list.unknown'),
                this.$t('danger_report.event_type_list.close_call'),
                this.$t('danger_report.event_type_list.accident'),
            ],
            pieLabels: [
                this.$t('danger_report.handled'),
                this.$t('danger_report.unhandled'),
            ],
            pieColors: ["#b7e89e", "#db7564"]
        }
    },
    computed: {
        pieData() {
            let handled = 0
            let unhandled = 0
            this.dangerReports.forEach(item => {
                if (item.handled) {
                    handled++
                } else {
                    unhandled++
                }
            })
            return [
                {value: handled},
                {value: unhandled}
            ]
        },
        chartData() {
            let other = 0
            let closeCalls = 0
            let accidents = 0
            this.dangerReports.forEach(item => {
                switch (item.event_type) {
                    case dangerTypes.ACCIDENT:
                        accidents++
                        break
                    case dangerTypes.CLOSE_CALL:
                        closeCalls++
                        break
                    case dangerTypes.OTHER:
                        other++
                        break
                }
            })
            return [
                {value: other},
                {value: closeCalls},
                {value: accidents}
            ]
        }
    },
    mounted() {
        this.fetchDangerReports()
    },
    watch: {
      searchParams () {
          this.fetchDangerReports()
      }
    },
    methods: {
        fetchDangerReports: function () {
            this.dangerReports = []
            if (this.searchParams) {
                let mappedParams = {
                    contract: this.searchParams.contract,
                    startTime: this.searchParams.dateFrom ? this.getDateIsoString(new Date(this.searchParams.dateFrom)) : null,
                    endTime: this.searchParams.dateTo ? this.getDateIsoString(new Date(this.searchParams.dateTo)) : null,
                }
                this.$emit('loading', true)
                this.restFetchParams(this.dangerReportUrl, mappedParams, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response) {
                this.dangerReports = response.data
            }
            this.$emit('loading', false)
        },
        generateImages: function () {
            if (this.dangerReports && this.dangerReports.length > 0) {
                if (this.$refs.barReports) {
                    document.getElementById("barDangerReportsImage").src = this.$refs.barReports.getChartAsImage();
                }
                if (this.$refs.pieHandledDanger) {
                    document.getElementById("pieHandledDangerImage").src = this.$refs.pieHandledDanger.getChartAsImage();
                }
            }
        },
        showDetails(data) {
            this.detailsDangerReport = data
        },
        closeAndUpdate() {
            this.detailsDangerReport = null
            this.reportsVisible = false
            this.fetchDangerReports()
        }
    }
}
</script>
