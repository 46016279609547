<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="inspectionUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('inspection_editor.edit_inspection') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('inspection_editor.add_inspection') }}
                </h2>
            </div>
            <h4 class="form-header pl-3 pr-4">{{ $t('contracts.basic_info')}}</h4>
            <div class="col-sm-12 form-data pl-3 pr-3">
                <span class="span-title">{{ $t('inspectable_editor.name') }}</span>
                <b-form-group
                    class="span-title"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="inspectionUpdate.name"
                        type="text"
                        size="mb"
                    />
                </b-form-group>
                <span class="span-title">{{ $t('inspection_editor.inspection_type') }}</span>
                <b-form-group
                    class="span-title"
                    label-for="type"
                >
                    <b-form-select
                        id="type"
                        v-model="inspectionUpdate.type"
                        :options="types"
                        size="mb"
                    />
                </b-form-group>
                <div v-if="inspectionUpdate.type === VEHICLE" class="nopads">
                    <span class="span-title">{{ $t('inspection_editor.vehicle_type') }}</span>
                    <b-form-group
                        class="span-title"
                        label-for="vehicle_type"
                    >
                        <b-form-select
                            id="vehicle_type"
                            v-model="inspectionUpdate.vehicle_type"
                            :options="vehicle_types"
                            size="mb"
                        />
                    </b-form-group>
                </div>
                <span class="span-title">{{ $t('inspection_editor.inspection_frequency') }}</span>
                <b-form-group
                    class="span-title"
                    label-for="inspection_freq"
                >
                    <b-form-select
                        id="inspection_freq"
                        v-model="inspectionUpdate.frequency"
                        :options="inspection_frequencies"
                        size="mb"
                    />
                </b-form-group>
                <span class="span-title">{{ $t('inspection_editor.inactive') }}</span>
                <b-form-group
                    class="title"
                    label-for="in_active"
                >
                    <b-form-checkbox
                        id="in_active"
                        v-model="inspectionUpdate.in_active"
                        size="mb"
                    />
                </b-form-group>
                <hr/>
                <h4 class="form-header pl-3 pr-4">{{ $t('inspection_template.inspectables')}}</h4>
                <draggable
                    v-if="inspectionUpdate.inspectables.length > 0"
                    v-model="inspectionUpdate.inspectables"
                    class="col-sm-12 inspectable-container"
                >
                    <transition-group>
                        <div
                            v-for="item in inspectionUpdate.inspectables"
                            :key="item.id"
                            class="inspectables-ordered"
                        >
                            <i class="fa fa-bars"/>
                            <span class="inspectable-name">{{ item.name }}</span>
                            <b-button
                                variant="danger"
                                @click="updateInspectablesSelection(item)"
                            >
                                <i class="fa fa-times"/>
                            </b-button>
                        </div>
                    </transition-group>
                </draggable>
                <div
                    v-else
                    class="col-sm-12 small-pad"
                >
                    <span>{{ $t('inspection_editor.no_selected_inspectables') }}</span>
                </div>
                <hr/>
                <span
                    v-if="inspectionUpdate.inspectables.length !==inspectables.length"
                    class="span-title"
                >
                    {{ $t('inspection_editor.select_inspectables') }}
                </span>
                <div class="col-sm-12 row inspectable-container nopads">
                    <div
                        v-for="item in inspectables"
                        :key="item.id"
                        class="col-sm-6 col-md-4 col-lg-3 col-xl-2 inline inspectables nopads"
                        :class="{ 'inspectables-disabled' : isSelected(item.id) }"
                        @click="updateInspectablesSelection(item)"
                    >
                            {{ item.name }}
                    </div>
                </div>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitInspection"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import draggable from 'vuedraggable'
import {restApi} from '../mixins/RestApiMixin'
import {inspectionHelper} from '../mixins/InspectionMixin'

export default {
    name: 'InspectionEditor',
    mixins: [inspectionHelper, restApi],
    components: {
        draggable,
    },
    props: {
        inspection: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            VEHICLE: 1,
            WORK: 2,
            loading: false,
            companies: [],
            inspectables: [],
            inspectionUpdate: this.jsonToInspection(this.inspection),
            types: [
                {value: 1, text: this.$t('inspection_template.type.vehicle')},
                {value: 2, text: this.$t('inspection_template.type.work')},
                {value: 4, text: this.$t('inspection_template.type.company')},
                {value: 5, text: this.$t('inspection_template.type.bridge')},
                {value: 6, text: this.$t('inspection_template.type.culvert')}
            ],
            vehicle_types: [
                {value: 1, text: this.$t('vehicle_list.vehicle_type.passenger_or_van')},
                {value: 2, text: this.$t('vehicle_list.vehicle_type.truck')},
                {value: 3, text: this.$t('vehicle_list.vehicle_type.trailer')},
                {value: 4, text: this.$t('vehicle_list.vehicle_type.trailer1')},
                {value: 5, text: this.$t('vehicle_list.vehicle_type.loader')},
                {value: 6, text: this.$t('vehicle_list.vehicle_type.tractor')},
                {value: 11, text: this.$t('vehicle_list.vehicle_type.snowplow')},
                {value: 7, text: this.$t('vehicle_list.vehicle_type.spreader')},
                {value: 8, text: this.$t('vehicle_list.vehicle_type.harvester')},
                {value: 9, text: this.$t('vehicle_list.vehicle_type.forwarder')},
                {value: 12, text: this.$t('vehicle_list.vehicle_type.helicopter')},
                {value: 13, text: this.$t('vehicle_list.vehicle_type.paver')},
                {value: 14, text: this.$t('vehicle_list.vehicle_type.road_fence')},
                {value: 15, text: this.$t('vehicle_list.vehicle_type.shock_absorber')},
                {value: 10, text: this.$t('vehicle_list.vehicle_type.other')}
            ],
            inspection_frequencies: [
                {value: 1, text: this.$t('inspection_template.frequency.weekly')},
                {value: 2, text: this.$t('inspection_template.frequency.monthly')},
                {value: 3, text: this.$t('inspection_template.frequency.annually')},
                {value: 4, text: this.$t('inspection_template.frequency.deployment')},
                {value: 5, text: this.$t('inspection_template.frequency.daily')},
                {value: 6, text: this.$t('inspection_template.frequency.other')}
            ]
        }
    },
    watch: {
        company: function () {
            this.fetchInspectables()
        }
    },
    mounted: function () {
        this.fetchInspectables()
    },
    methods: {

        fetchCompanies: function () {
            if (this.isAdmin) {
                this.loading = true
                this.restFetch(this.companyUrl, this.handleCompanies)
            }
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        fetchInspectables: function () {
            this.loading = true
            this.restFetch(this.inspectableUrl, this.handleResult)
        },

        updateInspectablesSelection: function (inspectable) {
            var index = this.indexOfInspectable(inspectable.id)
            if (index < 0) {
                this.inspectionUpdate.inspectables.push(inspectable)
            } else {
                this.inspectionUpdate.inspectables.splice(index, 1)
            }
        },

        indexOfInspectable: function (id) {
            return this.inspectionUpdate.inspectables.findIndex(function (obj) {
                return obj.id === id
            })
        },

        isSelected: function (id) {
            var index = this.indexOfInspectable(id)
            return index >= 0
        },

        handleResult: function (response) {
            this.inspectables = response.data
            this.loading = false
        },

        checkValues: function (inspection) {
            if (inspection.name === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return undefined
            }
            if (inspection.type === this.VEHICLE && inspection.vehicle_type === '') {
                EventBus.$emit('show-alert', this.$t('inspection_editor.invalid_vehicle_type'))
                return undefined
            }
            if (inspection.type === '') {
                EventBus.$emit('show-alert', this.$t('inspection_editor.invalid_inspection_type'))
                return undefined
            }
            if (!inspection.frequency || inspection.frequency === '') {
                EventBus.$emit('show-alert', this.$t('inspection_editor.invalid_frequency'))
                return undefined
            }
            if (!inspection.inspectables.length) {
                EventBus.$emit('show-alert', this.$t('inspection_editor.invalid_inspectables'))
                return undefined
            }
            return inspection
        },

        submitInspection: function () {
            var jsonInspectable = this.checkValues(this.inspectionUpdate)
            if (jsonInspectable !== undefined) {
                this.loading = true
                if (this.inspectionUpdate.id < 1) {
                    this.restAdd(this.inspectionTemplateUrl, jsonInspectable, this.success, this.fail)
                } else {
                    this.restUpdate(this.inspectionTemplateUrl, jsonInspectable, this.success, this.fail)
                }
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }

    }
}
</script>
