<template>
    <div class="col-sm-12 nopads">
        <!-- Details -->
        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('vehicle.vehicle') }}</span>
                <span class="item-detail-text">
                    {{ defect.vehicle.make }} {{ defect.vehicle.vehicle_model }}
                </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('vehicle_defects.license_plate') }}</span>
                <span class="item-detail-text">
                    {{ defect.vehicle.license_plate }}
                </span>
            </div>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_assignment.inspectable') }}</span>
                <span class="item-detail-text">
                    {{ defect.inspectable_name }}
                </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_assignment.description') }}</span>
                <span class="item-detail-text">
                    {{ defect.description }}
                </span>
            </div>
        </b-row>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {observationHelper} from '../mixins/ObservationMixin'

export default {
    name: 'WorkAssignmentVehicleDefect',
    mixins: [timeUtils, observationHelper],
    props: {
        defect: {
            type: Object,
            default: null
        }
    }
}
</script>
