<template>
    <div v-if="workReports.length > 0" class="nopads">
        <b-row>
            <span class="section-title">{{ $t('contract_reports.area_work_reports') }} ({{workReports.length}})</span>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : reportsVisible}"
                    v-b-toggle.areaWorkReports
                    @click.stop="reportsVisible = !reportsVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="areaWorkReports"
            class="mt-2 mb-2 item-details show-print"
        >
            <b-table
                small
                :items="workReports"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @row-clicked="emitRowClicked"
            >
                <template v-slot:cell(area)="row">
                    {{ row.value.name }}
                </template>
                <template v-slot:cell(task_type)="row">
                    {{ row.value.name }}
                </template>
                <template v-slot:cell(time)="row">
                    {{ $d(row.value, 'long') }}
                </template>
            </b-table>
        </b-collapse>
        <hr class="report-divider nopads"/>
    </div>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import {timeUtils} from "../mixins/TimeUtils";

export default {
    name: "ContractAreaWorkReports",
    mixins: [restApi, timeUtils],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data() {
        return {
            sortBy: 'time',
            sortDesc: true,
            workReports: [],
            reportsVisible: false,
        }
    },
    computed: {
        fields() {
            return [
                {key: 'area', label: this.$t('work_report.area'), sortable: true},
                {key: 'task_type', label: this.$t('work_report.task_type'), sortable: true},
                {key: 'time', label: this.$t('work_report.time'), sortable: true}
            ]
        }
    },
    watch: {
        searchParams() {
            this.fetchAreaWorkReports()
        }
    },
    mounted() {
        this.fetchAreaWorkReports()
    },
    methods: {
        async fetchAreaWorkReports() {
            this.$emit('loading', true)
            const { data } = await this.axios.get('area/work', {
                params: {
                    from: new Date(this.searchParams.dateFrom).toISOString(),
                    to: new Date(this.searchParams.dateTo).toISOString(),
                    contract: this.searchParams.contract
                }
            }).catch(() => {});
            this.$emit('loading', false);
            this.workReports = data.map((report) => ({
                id: report.id,
                area: { id: report.area_id, name: report.area_name },
                task_type: { id: report.task_type_id, name: report.task_type_name },
                age: report.age,// Seems to be in minutes, not sure
                time: new Date(this.setTime(report.time))
            }));
        },
        emitRowClicked(item) {
            this.$emit("rowClicked", item)
        }
    }
}
</script>
