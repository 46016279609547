<template>
    <div class="picker-container">
        <span class="span-title">
            {{ $t('orders.external_task_id') }}
        </span>
        <div class="nopads" v-if="this.assignedTasks.length > 0">
            <b-row v-for="(eTask, index) in this.assignedTasks" v-bind:key="eTask.id"
                style="border-bottom: #d9d9d9 1px solid" class="nopads pt-1 pb-1">
                <div class="col-10 nopads">
                    <span class="nopads col-10 pr-2" style="font-size: .9em">
                        {{ eTask.external_task_id }} - {{ eTask.external_task_descr }}
                    </span>
                </div>
                <div class="col-2 nopads text-right">
                    <b-button :id="'removeExternalTask' + eTask.id" variant="danger" size="sm" class="pt-1 pb-1 mx-1"
                        @click.stop="removeExtTask(index)">
                        <i class="fa fa-trash" style="font-size: .8em; padding: 0; line-height: 1em;" />
                    </b-button>
                </div>
            </b-row>
        </div>
        <b-row class="nopads mt-2">
            <span class="col-9 nopads pr-2 tip-light">{{ $t('orders.external_task_hint') }}</span>
            <div class="col-3 text-right pt-2 nopads">
                <b-button variant="info" size="sm" @click.stop="fetchExtTasks">
                    {{ $t('common.add') }}
                </b-button>
            </div>
        </b-row>

        <!-- External task selection modal -->
        <b-modal
            ref="extTaskModal"
            centered
            hide-footer
            class="nopads"
            :title="$t('orders.select_task')">
            <external-task-selector
                ref="extTaskSelector"
                @notifyIsTaskSelected="handleIsTaskSelected"
                :tasks="extTasks"
            />
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="$refs.extTaskModal.hide()">
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="setExtTask()" :disabled="!this.isTaskSelected">
                    {{ $t('common.save') }}
                </b-button>
            </div>
        </b-modal>
        <div
            v-if="!!loading"
            class="spinner"></div>
    </div>
</template>

<script>

import { mapHelper } from "@/components/mixins/MapMixin";
import { restApi } from "@/components/mixins/RestApiMixin";
import ExternalTaskSelector from "@/components/velho/ExternalTaskSelector";

export default {
    name: 'HarjaTaskPicker',
    mixins: [mapHelper, restApi],
    components: {ExternalTaskSelector},
    props: {
        assignedTasks: {
            type: Array,
            default: () => []
        },
        contractId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            loading: false,
            isTaskSelected: false,
            extTasks: []
        }
    },
    methods: {
        fetchExtTasks() {
            this.loading = true
            this.restFetchParams(this.extTasksUrl, {contract: this.contractId}, this.handleTasks)
        },
        handleTasks(response) {
            this.loading = false
            if (response && response.data) {
                this.extTasks = response.data
                this.$refs.extTaskModal.show()
            }
        },
        setExtTask() {
            let et = this.$refs.extTaskSelector.getSelectedTask()
            this.assignedTasks.push({
                'external_task_id': et.id,
                'external_task_descr': et.selite,
                'external_type': et.type
            })
            this.handleIsTaskSelected(false)
            this.$refs.extTaskModal.hide()
        },
        removeExtTask(index) {
            this.assignedTasks.splice(index, 1)
        },
        /**
         * Handle is task selected
         * @param {boolean} isSelected
         */
        handleIsTaskSelected(isSelected = false) {
            this.isTaskSelected = isSelected
        }
    }
}
</script>

<style scoped>
.picker-container {width: 100%;}
</style>