<template>
    <table class="table table-sm">
        <tbody>
        <tr>
            <td class="item-detail-title">
                {{ $t('users.company') }}
            </td>
            <td class="item-detail-text">
                <span v-if="company.name">{{ company.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'TargetInfoCompany',
    props: {
        company: {
            type: Object,
            default: null
        }
    }
}
</script>
