<template>
    <div class="col-sm-12">
        <h2>{{ $t('orders.select_contract') }}</h2>
        <b-form-group
            class="title"
            label-for="contracts"
        >
            <b-form-select
                id="contracts"
                v-model="selectedContract"
                size="mb"
                :disabled="contracts.length < 1"
            >
                <template slot="first">
                    <option
                        :value="null"
                        disabled
                    >
                        {{ $t('orders.select_contract') }}
                    </option>
                </template>
                <option
                    v-for="option in contracts"
                    :key="option.id"
                    :value="option.id"
                >
                    {{ option.name }}
                </option>
            </b-form-select>
        </b-form-group>
        <div class="wizard-button-container">
            <b-button
                class="wizard-button"
                variant="success"
                @click.stop="selectContract"
            >
                <span>{{ $t('common.continue') }}</span>
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'OrderWizard',
    mixins: [timeUtils, workManagementHelper, restApi],
    props: {
        user: {
            type: Object,
            default: null
        },
        initialContractId: {
            type: Number,
            default() {
                return -1
            }
        }
    },
    data: function () {
        return {
            loading: false,
            contracts: [],
            selectedContract: null
        }
    },

    mounted: function () {
        if (this.initialContractId !== undefined) {
            this.selectedContract = this.initialContractId
        }
        this.fetchContracts()
    },

    methods: {

        fetchContracts: function () {
            this.loading = true
            this.restFetchParams(this.contractOpenUrl, {startDate: this.getToday()}, this.handleContracts)
        },

        handleContracts: function (response) {
            this.contracts = response.data
            this.loading = false
        },

        selectContract: function () {
            // emit contractor selected with company id and name
            var contract = this.contracts.find(contract => contract.id === this.selectedContract)
            if (contract) {
                this.$emit('contractSet', contract)
            }
        }
    }
}
</script>
