<template>
    <div class="col-sm-12 editor-form pb-4">
        <h4 class="form-header">{{ $t('contracts.basic_info')}}</h4>
        <b-row>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                   {{ $t('contracts.customer') }}
                </span>
                <span>
                  <strong>
                    {{ contract.customer.name }}
                  </strong>
                </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                    {{ $t('contracts.contractor') }}
                </span>
                <span>
                  <strong>
                    {{ contract.contractor.name }}
                  </strong>
                </span>
            </div>
        </b-row>
        <b-row>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                    {{ $t('contracts.name') }}
                </span>
                <span>{{ contract.name }}</span>
            </div>
        </b-row>
        <b-row>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                    {{ $t('contracts.start') }}
                </span>
                <span>{{ timestampToDateInput(contract.start_date) }}</span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="span-title">
                  {{ $t('contracts.end') }}
                </span>
                <span>{{ timestampToDateInput(contract.end_date) }}</span>
            </div>
        </b-row>
    </div>
</template>

<script>

import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'ContractSimpleInfo',
    mixins: [timeUtils],
    props: {
        contract: {
            type: Object,
            default: null
        }
    }
}
</script>
