<template>
    <div class="col-sm-12 editor pt-2">
        <div class="col-sm-12 editor-form pb-4">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    class="form-title editor-title"
                >
                    {{ $t('street_lights.title') }}
                </h2>
            </div>
            <b-tabs justified v-model="tabIndex">
                <b-tab :title="$t('work_time.reports')" active :title-link-class="linkTabClass(0)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 0}">
                                {{ $t('street_lights.title') }}
                            </div>
                        </b-row>
                    </template>
                    <street-lights :user="user"/>
                </b-tab>
                <b-tab :title="$t('power_stations.title')" :title-link-class="linkTabClass(1)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 1}">
                                {{ $t('power_stations.title') }}
                            </div>
                        </b-row>
                    </template>
                    <power-stations :user="user"/>
                </b-tab>
                <b-tab :title="$t('cables.title')" :title-link-class="linkTabClass(2)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-12 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 2}">
                                {{ $t('cables.title') }}
                            </div>
                        </b-row>
                    </template>
                    <cables :user="user"/>
                </b-tab>
            </b-tabs>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        </div>
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {userHelper} from "@/components/mixins/UserMixin";
import StreetLights from "@/components/streetlights/StreetLights";
import PowerStations from "@/components/streetlights/PowerStations";
import Cables from "@/components/streetlights/Cables";

export default {
    name: 'StreetLightsView',
    components: {
        Cables,
        PowerStations,
        StreetLights
    },
    mixins: [restApi,userHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            tabIndex: 0
        }
    },
    methods: {
        linkTabClass(index) {
            if (this.tabIndex === index) {
                return ['tab-title', 'tab-title-active']
            } else {
                return ['tab-title']
            }
        }
    }
}
</script>

<style>
.tab-title {
    font-size: .9em;
    border: none !important;
    margin-right: .5em;
    margin-left: .5em;
    color: #949494 !important;
    font-weight: bold;
}

.tab-title:hover {
    color: #007BFF;
}

.tab-title-active {
    border-bottom: 2px solid #7FC000 !important;
}

.tab-title-text {
    color: #949494 !important;
    font-weight: bold;
}


.tab-title-text-active {
    color: #000000 !important;
    font-weight: bold;
}


.tab-title-cont {
    height: 2.5em !important;
    padding-top: 1em;
    padding-bottom: 1em;
}

.contract-tabs .nav-link {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.contract-tabs .nav-tabs {
    height: 3em;
    padding-top: .55em;
}

</style>
