<template>
    <div
        class="details-container"
        @click.stop="close"
    >
        <div
            class="details dynamic_width"
            @click.stop=""
        >
            <h3 class="text-center">
                {{ item.type }}
            </h3>
            <marker-editor-map
                v-if="item"
                :id="item.id"
                class="item-map"
                :lat="parseFloat(item.lat)"
                :lon="parseFloat(item.lon)"
                :type="this.CULVERT"
                :draggable="false"
            />

            <div class="pl-2 pr-2">
                <b-row>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('common.id') }}</span>
                        <span class="item-detail-text">{{ item.id }}</span>
                    </div>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('culvert.digiroad_id') }}</span>
                        <span class="item-detail-text">{{ item.digiroad_id }}</span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('bridge.type') }}</span>
                        <span class="item-detail-text">{{ item.type }}</span>
                    </div>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('culvert.material') }}</span>
                        <span class="item-detail-text">{{ item.material }}</span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('culvert.size') }}</span>
                        <span class="item-detail-text">{{ item.size }}</span>
                    </div>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('bridge.length') }}</span>
                        <span class="item-detail-text">{{ item.length }}</span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('bridge.city') }}</span>
                        <span class="item-detail-text">{{ item.city }}</span>
                    </div>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('bridge.start_distance') }}</span>
                        <span class="item-detail-text">{{ item.start_distance }}</span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('bridge.road_num') }}</span>
                        <span class="item-detail-text">{{ item.road_num }}</span>
                    </div>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('bridge.sec_num') }}</span>
                        <span class="item-detail-text">{{ item.sec_num }}</span>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('culvert.owner') }}</span>
                        <span class="item-detail-text">{{ item.owner }}</span>
                    </div>
                    <div class="col-6 nopads">
                        <span class="item-detail-title">{{ $t('bridge.job') }}</span>
                        <span class="item-detail-text">{{ item.job_area }}</span>
                    </div>
                </b-row>
            </div>

            <div class="button-container col-sm-12">
                <b-button
                    variant="success"
                    class="result-button"
                    @click.stop="changeView">
                    {{ $t('menu.new_inspection') }}
                </b-button>
                <b-button
                    variant="primary"
                    class="result-button"
                    @click.stop="close"
                >
                    {{ $t('common.close') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import MarkerEditorMap from '../map/MarkerEditorMap'
import {mapHelper} from "@/components/mixins/MapMixin";

export default {
    name: 'culvert-details',
    mixins: [mapHelper],
    components: {MarkerEditorMap},
    props: {
        item: {
            type: Object,
            default: null
        },
        setInspectionParams: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            map: null,
            mapHelper
        }
    },
    methods: {
        close: function () {
            this.$emit('close')
        },
        changeView: function () {
            if (this.setInspectionParams) {
                this.setInspectionParams('inspectionWizard', 'culvert', this.item);
            }
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.map-container {
    height: 30em;
    position: relative;
}

</style>
