<template>
    <div
        class="details-container"
    >
        <div
            class="details dynamic_width">
            <h3 class="pl-1 pt-2">{{ $t('danger_report.title') }}</h3>
            <div>
                <marker-editor-map
                    v-if="report.location"
                    :id="report.id"
                    class="item-map"
                    :lat="report.location.y"
                    :lon="report.location.x"
                    :type="OBSERVATION"
                    :draggable="false"
                />
                <div class="col-sm-12 carousel-container">
                    <Flicking ref="flicking" :options="options" :plugins="plugins" v-if="report.photos.length > 0" class="pointer nopads text-center"
                              style="height: 60vh">
                        <div v-for="photo in report.photos" :key="photo.id" class="relative nopads slide-img-container">
                            <ImageView
                                ref="image"
                                :url="getUrlForPhoto(photo)"
                                @click="openImage"
                            />
                        </div>
                        <span slot="viewport" class="flicking-arrow-prev"></span>
                        <span slot="viewport" class="flicking-arrow-next"></span>
                    </Flicking>
                </div>

                <!-- Details -->
                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.report_id') }}</span>
                        <span class="item-detail-text">{{ report.id }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.reporter') }}</span>
                        <span class="item-detail-text">{{ report.reporter.last_name }}, {{
                                report.reporter.first_name
                            }}</span>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.event_time') }}</span>
                        <span class="item-detail-text">{{ toLocalTime(report.event_time) }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.event_type') }}</span>
                        <span class="item-detail-text">{{ getDangerTypeName(report.event_type) }}</span>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.reporting_company') }}</span>
                        <span class="item-detail-text">{{ report.reporting_company.name }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.contract') }}</span>
                        <span
                            v-if="report.contract"
                            class="item-detail-text"
                        >
            {{ report.contract.name }}
          </span>
                        <span
                            v-else
                            class="item-detail-text"
                        >
            -
          </span>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.description') }}</span>
                        <span class="item-detail-text">{{ report.description ? report.description : '-' }}</span>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.description_conditions') }}</span>
                        <span class="item-detail-text">{{ report.description_conditions ? report.description_conditions : '-' }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.proposal_for_preventive_actions') }}</span>
                        <span class="item-detail-text">{{ report.proposal_for_preventive_actions ? report.proposal_for_preventive_actions : '-' }}</span>
                    </div>
                </b-row>

                <b-row>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.consequences.title') }}</span>
                        <span class="item-detail-text">{{ report.consequences ? getDangerConsequencesName(report.consequences) : '-' }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.consequences.info') }}</span>
                        <span class="item-detail-text">{{ report.consequences_info ? report.consequences_info : '-' }}</span>
                    </div>
                </b-row>

                <b-row v-if="report.handled">
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.handled_by') }}</span>
                        <span class="item-detail-text">{{
                                report.handled_by.last_name
                            }}, {{ report.handled_by.first_name }}</span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="item-detail-title">{{ $t('danger_report.note') }}</span>
                        <span class="item-detail-text">{{ report.handling_note }}</span>
                    </div>
                </b-row>


                <!-- Danger photo -->
                <div v-if="report">
                    <span class="span-title">{{ $t('danger_report.add_photo') }}</span>
                    <div
                        v-for="(path, counter) in photos"
                        :key="counter"
                        class="col-sm-12 nopads"
                    >
                        <b-form-group
                            class="title"
                            label-for="counter"
                            v-if="counter === 0 || isAttachmentSet(photos, counter-1)"
                        >
                            <b-form-file
                                v-model="photos[counter]"
                                accept="image/jpeg, image/jpg, image/png"
                                :placeholder="$t('common.photo_placeholder')"
                            />
                        </b-form-group>
                    </div>
                </div>

                <div class="button-container col-sm-12 trip-buttons">
                    <b-button
                        variant="secondary"
                        class="form-button"
                        @click.stop="close"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                    <b-button
                        variant="primary"
                        class="form-button"
                        @click.stop="handleReportModal=true"
                        :disabled="loading || !isObserver || report.handled !== false"
                    >
                        {{ $t('danger_report.handle') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="form-button"
                        :disabled="getValidAttachments(photos).length === 0||loading"
                        @click.stop="addPhotos"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>

            <b-modal
                v-model="handleReportModal"
                :title="$t('work_permission.handle_request')"
                centered
                no-close-on-esc
                no-close-on-backdrop
                hide-footer
            >
                <span>{{ $t('work_permission.handlers_note') }}</span>
                <b-form-textarea
                    id="info"
                    v-model="handlingNote"
                    :rows="3"
                    :max-rows="6"
                />
                <div class="button-container top-margin">
                    <b-button
                        class="mr-1"
                        variant="secondary"
                        size="lg"
                        @click.stop="handleReportModal=false">
                        {{ $t('common.close') }}
                    </b-button>
                    <b-button
                        variant="success"
                        size="lg"
                        @click.stop="handleReport"
                        :disabled="handlingNote===null||handlingNote.trim().length===0">
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-modal>


            <!--
            <div v-if="isObserver && report.handled===false">
              <b-form-group
                      :label="$t('orders.info')"
                      class="span-title"
                      label-for="info"
              >
              </b-form-group>
            </div>
            -->
        </div>
        <!-- Fullscreen photo -->
        <transition name="fade">
            <fullscreen-image
                v-if="fullScreenPhotoData"
                :largeImages="fullScreenPhotoData"
                :panelIndex="panelIndex"
                @close="closePhoto"/>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import ImageView from '../view/ImageView'
import {restApi} from '../mixins/RestApiMixin'
import MarkerEditorMap from '../map/MarkerEditorMap'
import {mapHelper} from '../mixins/MapMixin'
import {dangerHelper} from "../mixins/DangerMixin";
import {EventBus} from "@/event-bus";
import {attachmentHelper} from '../mixins/AttachmentMixin'
import FullscreenImage from "@/components/view/FullscreenImage";
import {Flicking} from "@egjs/vue-flicking";
import {Arrow} from "@egjs/flicking-plugins";

export default {
    name: 'DangerReportDetails',
    components: {FullscreenImage, MarkerEditorMap, ImageView, Flicking},
    mixins: [timeUtils, restApi, mapHelper, dangerHelper, attachmentHelper],
    props: {
        report: {
            type: Object,
            default: null
        },
        isObserver: Boolean
    },
    data() {
        return {
            map: null,
            photos: new Array(5),
            loading: false,
            handlingNote: null,
            handleReportModal: false,
            fullScreenPhotoData: null,
            plugins: [new Arrow()],
            options: {
                preventDefaultOnDrag: true,
                bound: true,
            },
            panelIndex: 0
        }
    },
    methods: {
        close: function () {
            this.$emit('close')
        },

        addPhotos: function () {
            var photos = this.getValidAttachments(this.photos)
            if (photos.length > 0 && this.report) {
                this.uploadPhoto(photos)
            } else {
                this.fail()
            }
        },

        uploadPhoto: function (photos) {
            if (photos.length > 0) {
                this.loading = true
                let formData = new FormData()
                photos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                let url = this.dangerReportUrl + "/" + this.report.id + "/photo"
                this.restPostWithFile(url, {}, formData, this.photoSuccess, this.fail)
            }
        },

        photoSuccess: function () {
            this.loading = false
            this.$emit('closeAndUpdate')
        },

        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },

        getUrlForPhoto(photo) {
            return this.dangerReportUrl + '/' + this.report.id + '/photo/' + photo.id
        },

        handleReport: function () {
            this.loading = true
            let url = this.dangerReportUrl + "/" + this.report.id + "/handle"
            this.restUpdate(url, {note: this.handlingNote}, this.handleReportSuccess, this.fail)
        },

        handleReportSuccess: function () {
            this.loading = true
            this.$emit('closeAndUpdate')
        },

        openImage: function () {
            const flicking = this.$refs.flicking
            const imageData = this.$refs.image
            this.fullScreenPhotoData = []
            imageData.forEach(src => {
                this.fullScreenPhotoData.push(src)
            })
            this.panelIndex = flicking.index

        },

        closePhoto() {
            this.fullScreenPhotoData = undefined
        }

    }
}
</script>

<style scoped>
.map-container {
    height: 30em;
    position: relative;
}
</style>

