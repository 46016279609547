<template>
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <div
                v-if="results"
                id="accordion"
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    hover
                    show-empty
                    small
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @row-clicked="emitDetails"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(start_time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                    <template v-slot:cell(end_time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'WorkReportList',
    mixins: [timeUtils],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'start_time',
            sortDesc: true,
            fields: [
                {key: 'id', label: this.$t('work_report.report_id'), sortable: true},
                {key: 'start_time', label: this.$t('work_report.start_time'), sortable: true},
                {key: 'end_time', label: this.$t('work_report.end_time'), sortable: true},
                {key: 'customer', label: this.$t('work_report.customer'), sortable: true},
                {key: 'contract', label: this.$t('work_report.contract'), sortable: true},
                {key: 'task_type', label: this.$t('work_report.task_type'), sortable: true},
                {key: 'reporter', label: this.$t('work_report.reporter'), sortable: true}
            ],
            items: []
        }
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        initTableItems: function () {
            this.items = []
            this.results.forEach(function (item) {
                this.items.push(
                    {
                        id: item.id,
                        start_time: item.start_time,
                        end_time: item.end_time,
                        customer: item.order.contract.customer.name,
                        contract: item.order.contract.name,
                        task_type: item.order.task_type.name,
                        reporter: item.reporter.first_name + ' ' + item.reporter.last_name,
                    }
                )
            }, this)
        },
        emitDetails: function (details) {
            let report = this.results.find(item => item.id === details.id)
            this.$emit('details', report)
        }
    }
}
</script>
