<template>
    <div class="login-container">
        <form
            class="form-signin"
            @submit.prevent="resetPassword"
        >
            <b-row>
                <img src="../../assets/routa_light_S.png" style="display: block; margin-left: auto; margin-right: auto; width: auto; height: 6em; margin-bottom: 1em" alt="logo"/>
            </b-row>
            <h2 class="form-signin-heading">
                Routa - {{ $t('login.title_reset') }}
            </h2>
            <b-row class="mb-2">
                <b-form-input
                    id="inputUsername"
                    v-model="username"
                    type="text"
                    :placeholder="$t('login.username')"
                    required
                    autocomplete="username"
                    autofocus
                />
            </b-row>
            <b-row>
                <b-button
                    type="submit"
                    size="lg"
                    variant="primary col-sm-12"
                >
                    {{ $t('login.reset_password') }}
                </b-button>
            </b-row>
            <b-row>
                <div
                    class="forgot"
                    @click.stop="$emit('resetPasswordClose')"
                >
                    {{ $t('login.back_to_login') }}
                </div>
            </b-row>
            <b-alert
                class="alert"
                :show="alertDismissCountDown"
                dismissible
                fade
                variant="danger"
                @dismissed="alertDismissCountDown=0"
                @dismiss-count-down="alertCountDownChanged"
            >
                {{ alertMessage }}
            </b-alert>
        </form>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'ResetPassword',
    mixins: [restApi],
    data() {
        return {
            username: '',
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: ''
        }
    },
    methods: {
        resetPassword: function () {
            this.loading = true
            this.restFetchParams(this.resetPasswordUrl, {username: this.username}, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response && response.status === 200) {
                this.$emit('resetPasswordDone')
            }
        },

        showAlert: function (message) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
        },

        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        }
    }
}
</script>
<style>
@import "../../styles/login.css";
</style>
