<template>
    <div class="col-sm-12 list-content result-table">
        <b-table
            hover
            show-empty
            small
            :items="workPermissions"
            :fields="fields"
            :sort-by.sync="sortBy"
            sort-desc
            @row-clicked="editItem"
        >
            <template slot="empty">
                <div class="text-center my-2">
                    {{ $t('common.no_results') }}
                </div>
            </template>
            <template
                v-slot:cell(created)="row"
            >
                <span>{{ toLocalTime(row.value) }}</span>
            </template>
            <template
                v-slot:cell(handling_time)="row"
            >
                <span>{{ toLocalTime(row.value) }}</span>
            </template>
            <template
                v-slot:cell(contract)="row"
            >
                <span>{{ row.value ? row.value.name : '-' }}</span>
            </template>
            <template
                v-slot:cell(requested_by)="row"
            >
                <span>{{ getName(row.value) }}</span>
            </template>
            <template
                v-slot:cell(status)="row"
            >
                <span>{{ getPermissionStatusString(row.value) }}</span>
            </template>
            <template
                v-slot:cell(type)="row"
            >
                <span>{{ getTypeString(row.value) }}</span>
            </template>
            <template
                v-slot:cell(handled_by)="row"
            >
                <span>{{ getName(row.value) }}</span>
            </template>

        </b-table>
    </div>
</template>

<script>
import {workPermissionHelper} from '../mixins/WorkPermissionMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'work-permission-list',
    mixins: [workPermissionHelper, timeUtils],
    props: {
        workPermissions: {
            type: Array,
            default: function () {
                return []
            }
        },
    },
    data: function () {
        return {
            sortBy: 'id',
            fields: [
                {key: 'id', label: this.$t('work_permission.id'), sortable: true},
                {key: 'created', label: this.$t('work_permission.created'), sortable: true},
                {key: 'requested_by', label: this.$t('work_permission.requested_by'), sortable: true},
                {key: 'status', label: this.$t('work_permission.status'), sortable: true},
                {key: 'type', label: this.$t('work_permission.type'), sortable: true},
                {key: 'contract', label: this.$t('work_permission.contract'), sortable: true},
                {key: 'info', label: this.$t('work_permission.info'), sortable: false},
                {key: 'handled_by', label: this.$t('work_permission.handled_by'), sortable: true},
                {key: 'handling_time', label: this.$t('work_permission.handled_at'), sortable: true},
                {key: 'handlers_note', label: this.$t('work_permission.handlers_note'), sortable: false}
            ]
        }
    },
    methods: {
        editItem: function (workPermission) {
            let r = this.workPermissions.find(item => item.id === workPermission.id)
            if (r) {
                this.$emit('rowClicked', r)
            }
        }
    }
}
</script>
