<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div v-if="results && results.length > 0" class="nopads">
        <b-row>
            <b-col class="nopads report-header">
                <b-row class="nopads">
                    <b-col class="nopads">
                        <span class="section-title" style="display: block">{{ $t('material_reports.title') }}</span>
                        <div>{{ results ? results.length : 0 }} {{ $t('danger_report.reports') }}</div>
                    </b-col>
                    <b-col>
                        <div class="button-container">
                            <b-button
                                variant="outline-success"
                                class="result-button float-right dont-print"
                                :disabled="results.length < 1"
                                @click="exportExcel"
                            >
                                {{ $t('trip.download_excel') }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>
        <b-row>
            <div class="list-content result-table">
                <b-table
                    show-empty
                    small
                    striped
                    :items="results"
                    :fields="fields"
                >
                    <template v-slot:cell(date)="row">
                        {{ getShortDateString(row.value) }}
                    </template>
                </b-table>
            </div>
        </b-row>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {downloadHelper} from '../mixins/DownloadMixin'

export default {
    name: 'MaterialReport',
    mixins: [restApi, timeUtils, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        params: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            results: [],
            materialData: [],
            labels: [],
            materialTitle: this.$t('material_report.chart_title'),
            fields: [
                {
                    date: {
                        label: this.$t('daily_summary.date'),
                        sortable: true
                    }
                },
                {
                    reporter_name: {
                        label: this.$t('material_reports.reporter'),
                        sortable: true
                    }
                },
                {
                    customer_name: {
                        label: this.$t('contracts.customer'),
                        sortable: true
                    }
                },
                {
                    contract_name: {
                        label: this.$t('orders.contract'),
                        sortable: true
                    }
                },
                {
                    order_name: {
                        label: this.$t('orders.order'),
                        sortable: true
                    }
                },
                {
                    material: {
                        label: this.$t('material_reports.material'),
                        sortable: true
                    }
                },
                {
                    amount: {
                        label: this.$t('material_reports.amount'),
                        sortable: true
                    }
                },
                {
                    unit: {
                        label: this.$t('material_reports.unit'),
                        sortable: true
                    }
                },
                {
                    info: {
                        label: this.$t('material_reports.info'),
                        sortable: true
                    }
                }
            ]
        }
    },
    mounted: function () {
        this.fetchReports()
    },
    watch: {
        params: function () {
            this.fetchReports()
        }
    },
    methods: {
        fetchReports: function () {
            this.results = []
            if (this.params) {
                this.loading = true
                this.restFetchParams(this.materialReportUrl, this.params, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response && response.data) {
                this.results = response.data
            }
            this.loading = false
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.materialReportExcelUrl, this.getCommonReportParams(this.params), this.fileDownloadSuccess, this.fileDownloadFail)
        },
        fileDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('material_reports.file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
    }
}
</script>
