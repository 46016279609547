<template>
    <div class="col-12 nopads">
        <div>
            <external-traffic-signs-map
                v-if="isVisible(this.EXTERNAL_TRAFFIC_SIGNS)"
                ref="signsExt"
                :map="map"
                :bounding-box="boundingBox"
                :selected-conditions="selectedConditions"
                :selected-contract="selectedContract"
            />
            <post-map
                v-if="isVisible(this.EXTERNAL_POSTS)"
                ref="postsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox"/>
            <culvert-map
                v-if="isVisible(this.EXTERNAL_CULVERTS)"
                ref="culvertsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox"/>
            <railing-map
                v-if="isVisible(this.EXTERNAL_RAILINGS)"
                ref="railingsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <portal-map
                v-if="isVisible(this.EXTERNAL_PORTALS)"
                ref="portalsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <drain-map
                v-if="isVisible(this.EXTERNAL_DRAINS)"
                ref="drainsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <fence-map
                v-if="isVisible(this.EXTERNAL_FENCES)"
                ref="fencesExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <stair-map
                v-if="isVisible(this.EXTERNAL_STAIRS)"
                ref="stairsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <gate-map
                v-if="isVisible(this.EXTERNAL_GATES)"
                ref="gatesExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <edge-support-map
                v-if="isVisible(this.EXTERNAL_EDGE_SUPPORTS)"
                ref="edgeSupportsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <edge-pile-map
                v-if="isVisible(this.EXTERNAL_EDGE_PILES)"
                ref="edgePilesExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <roadside-furniture-map
                v-if="isVisible(this.EXTERNAL_ROADSIDE_FURNITURE)"
                ref="roadsideFurnitureExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <roadside-advertisement-map
                v-if="isVisible(this.EXTERNAL_ROADSIDE_ADVERTISEMENTS)"
                ref="roadsideAdvertisementsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <booms-barriers-bollards-map
                v-if="isVisible(this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS)"
                ref="boomsBarriersBollardsExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <pipes-wires-cables-map
                v-if="isVisible(this.EXTERNAL_PIPES_WIRES_AND_CABLES)"
                ref="pipesWiresCablesExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
            <lighting-map
                v-if="isVisible(this.EXTERNAL_LIGHTING)"
                ref="lightingExt"
                :map="map"
                :selected-contract="selectedContract"
                :selected-conditions="selectedConditions"
                :bounding-box="boundingBox" />
        </div>
        <velho-map-filter
            @typesSelected="updateFilters"
            @selectedConditions="setConditions"
        />
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '@/components/mixins/TimeUtils'
import {mapHelper} from '@/components/mixins/MapMixin'
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {restApi} from '@/components/mixins/RestApiMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import ExternalTrafficSignsMap from "@/components/roadsign/external/ExternalTrafficSignsMap";
import PostMap from "@/components/velho/PostMap";
import CulvertMap from "@/components/velho/CulvertMap";
import RailingMap from "@/components/velho/RailingMap";
import EdgeSupportMap from "@/components/velho/EdgeSupportMap";
import EdgePileMap from "@/components/velho/EdgePileMap";
import FenceMap from "@/components/velho/FenceMap";
import VelhoMapFilter from "@/components/velho/VelhoMapFilter";
import PortalMap from "@/components/velho/PortalMap";
import StairMap from "@/components/velho/StairMap";
import DrainMap from "@/components/velho/DrainMap";
import PipesWiresCablesMap from "@/components/velho/PipesWiresCablesMap";
import GateMap from "@/components/velho/GateMap";
import RoadsideFurnitureMap from "@/components/velho/RoadsideFurnitureMap";
import RoadsideAdvertisementMap from "@/components/velho/RoadsideAdvertisementMap";
import BoomsBarriersBollardsMap from "@/components/velho/BoomsBarriersBollardsMap";
import LightingMap from "@/components/velho/LightingMap";

export default {
    name: 'VelhoMap',
    components: {
        DrainMap,
        PipesWiresCablesMap,
        PortalMap,
        FenceMap,
        StairMap,
        EdgeSupportMap,
        EdgePileMap,
        GateMap,
        RoadsideFurnitureMap,
        RoadsideAdvertisementMap,
        BoomsBarriersBollardsMap,
        LightingMap,
        VelhoMapFilter,
        RailingMap,
        CulvertMap,
        PostMap,
        ExternalTrafficSignsMap,
    },
    mixins: [timeUtils, mapHelper, restApi, geometryMixin, stormSewersMixin, roadSignHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContract: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: 0,
            filters: [],
            selectedConditions: [],
        }
    },
    beforeDestroy() {
        this.hideAll()
    },
    methods: {
        setConditions(conditions) {
          this.selectedConditions = conditions
        },
        isVisible(type) {
            let pref = this.filters.find(item => item === type)
            return pref !== undefined
        },

        updateFilters(filters) {
            this.filters = filters
        },

        hideAll() {
            if (this.$refs.signsExt) {
                this.$refs.signsExt.hideItems()
            }
            if (this.$refs.postsExt) {
                this.$refs.postsExt.hideItems()
            }
            if (this.$refs.culvertsExt) {
                this.$refs.culvertsExt.hideItems()
            }
            if (this.$refs.railingsExt) {
                this.$refs.railingsExt.hideAll()
            }
            if (this.$refs.drainsExt) {
                this.$refs.drainsExt.hideItems()
            }
            if (this.$refs.fencesExt) {
                this.$refs.fencesExt.hideAll()
            }
            if (this.$refs.stairsExt) {
                this.$refs.stairsExt.hideItems()
            }
            if (this.$refs.gatesExt) {
                this.$refs.gatesExt.hideItems()
            }
            if (this.$refs.edgeSupportsExt) {
                this.$refs.edgeSupportsExt.hideAll()
            }
            if (this.$refs.edgePilesExt) {
                this.$refs.edgePilesExt.hideAll()
            }
            if (this.$refs.roadsideFurnitureExt) {
                this.$refs.roadsideFurnitureExt.hideAll()
            }
            if (this.$refs.roadsideAdvertisementsExt) {
                this.$refs.roadsideAdvertisementsExt.hideAll()
            }
            if (this.$refs.boomsBarriersBollardsExt) {
                this.$refs.boomsBarriersBollardsExt.hideItems()
            }
            if (this.$refs.pipesWiresCablesExt) {
                this.$refs.pipesWiresCablesExt.hideItems()
            }
            if (this.$refs.lightingExt) {
                this.$refs.lightingExt.hideItems()
            }
            if (this.$refs.harja) {
                this.$refs.harja.hideItems()
            }
        },

        editTrafficSign(id) {
            this.$refs.signsExt.editTrafficSign(id)
        },

        editCulvert(id) {
            this.$refs.culvertsExt.editCulvert(id)
        },

        editPost(id) {
            this.$refs.postsExt.editPost(id)
        },

        editRailing(id) {
            this.$refs.railingsExt.editRailing(id)
        },

        editPortal(id) {
            this.$refs.portalsExt.editPortal(id)
        },

        editFence(id) {
            this.$refs.fencesExt.editFence(id)
        },
        editDrain(id) {
            this.$refs.drainsExt.editDrain(id)
        },
        editStair(id) {
            this.$refs.stairsExt.editStair(id)
        },
        editGate(id) {
            this.$refs.gatesExt.editGate(id)
        },
        editEdgeSupport(id) {
            this.$refs.edgeSupportsExt.editEdgeSupport(id)
        },
        editEdgePile(id) {
            this.$refs.edgePilesExt.editEdgePile(id)
        },
        editRoadsideFurniture(id) {
            this.$refs.roadsideFurnitureExt.editRoadsideFurniture(id)
        },
        editRoadsideAdvertisement(id) {
            this.$refs.roadsideAdvertisementsExt.editRoadsideAdvertisement(id)
        },
        editBoomOrBarrierOrBollard(id) {
            this.$refs.boomsBarriersBollardsExt.editBoomOrBarrierOrBollard(id)
        },
        editPipeWireCable(id) {
            this.$refs.pipesWiresCablesExt.editPipeWireCable(id)
        },
        editLighting(id) {
            this.$refs.lightingExt.editLighting(id)
        },
        editHarja(id) {
            this.$refs.harja.getHarjaAlertId(id)
        }
    }
}
</script>
