<template>
    <div class="nopads">
        <b-row v-if="lastModified" class="hint-text">
            {{ $t('contractor_reports.modified') }} : {{ toLocalTime(lastModified) }}
        </b-row>
        <div class="result-table">
            <b-table
                show-empty
                small
                :items="tableData"
                :fields="fields"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'certificateTable',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            fields: [],
            tableData: [],
            lastModified: ''
        }
    },
    props: {
        users: {
            type: Array
        },
        certificates: {
            type: Array
        }
    },
    watch: {
        certificates: function () {
            this.initializeData()
            this.initializeFields()
        }
    },
    mounted() {
        this.initializeData()
        this.initializeFields()
    },
    methods: {
        initializeFields() {
            this.fields = [];
            this.fields.push(
                { key: 'name', label: this.$t('contractor_reports.person_name'), sortable: false },
                { key: 'email', label: this.$t('users.email'), sortable: false},
                { key: 'phone', label: this.$t('users.phone'), sortable: false},
            )
            this.certificates.forEach(item => {
                this.fields.push({key: 'cert_id_' + item.id, label: item.name, sortable: false})
            });
        },
        initializeData() {
            this.tableData = []
            let latestEntry, latestEntryStr
            this.users.forEach(person => {
                let tableRow = []
                let cellVariants = {}
                tableRow['name'] = person.last_name + ', ' + person.first_name
                tableRow['email'] = person.email
                tableRow['phone'] = person.phone
                // Check which certificates the user has from all the available
                this.certificates.forEach(item => {
                    let existingCertificate = person.certificates.find(cert => cert.type.id === item.id)
                    let problem = !existingCertificate ||
                        (existingCertificate.expiration_day && this.isInPast(existingCertificate.expiration_day) && !existingCertificate.planned)
                    tableRow['cert_id_' + item.id] = problem ? '' :
                        existingCertificate.planned && !existingCertificate.granted_day ? this.$t('certificate.certificate_planned') :
                        existingCertificate.expiration_day ? this.getShortDateString(existingCertificate.expiration_day) : 'Ok'
                    if (problem) {
                        cellVariants['cert_id_' + item.id] = 'danger'
                    } else if ((existingCertificate.planned && !existingCertificate.granted_day) || (this.withinMonth(existingCertificate.expiration_day))) {
                        if (existingCertificate.planned && !this.withinYear(existingCertificate.planned_date)) {
                            cellVariants['cert_id_' + item.id] = 'danger'
                        } else {
                            cellVariants['cert_id_' + item.id] = 'warning'
                        }
                    }
                    if (existingCertificate && existingCertificate.last_modified) {
                        let lastMod = new Date(existingCertificate.last_modified)
                        if (!latestEntry || latestEntry.getTime() < lastMod.getTime()) {
                            latestEntry = lastMod
                            latestEntryStr = existingCertificate.last_modified
                        }
                    }
                })
                tableRow['_cellVariants'] = cellVariants
                this.tableData.push(tableRow)
            })
           this.lastModified = latestEntryStr? latestEntryStr : ''
        }
    },

}
</script>
