<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="content-container" ref="container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header">
                <span class="title">{{ $t('materialstorage.title_plural') }}</span>
            </div>
        </b-navbar>
        <keep-alive>
            <div class="search-form">
                <div class="col-sm-12 search-form-title">
                    <font-awesome-icon icon="search"/>
                    {{ $t('common.search') }}
                </div>
                <b-form
                    @submit.prevent="onSubmit"
                    @reset.prevent="onReset"
                >
                    <b-row class="nopads">
                        <div class="col-sm-6">
                            <b-form-group
                                class="nopads"
                                label-for="storage_name"
                            >
                                <span class="span-title">{{ $t('materialstorage.name') }}</span>
                                <b-form-input
                                    id="storage_name"
                                    v-model="storageName"
                                    type="text"
                                    size="sm"
                                />
                            </b-form-group>
                        </div>

                        <div class="col-sm-6">
                            <b-form-group class="nopads" label-for="storageCreatedBy">
                                <span class="span-title">{{ $t('materialstorage.user') }}</span>
                                <b-form-select
                                    id="storageCreatedBy"
                                    v-model="storageCreatedBy"
                                    size="sm"
                                    :disabled="!users||!users.length"
                                >
                                    <template slot="first">
                                        <option :value="null">
                                            {{ $t('materialstorage.select_user') }}
                                        </option>
                                    </template>
                                    <option
                                        v-for="option in users"
                                        size="sm"
                                        :key="option.id"
                                        :value="option.id"
                                    >
                                        {{ option.first_name + ' ' + option.last_name }}
                                    </option>
                                </b-form-select>
                            </b-form-group>
                        </div>

                    </b-row>
                    <div class="col-sm-12 button-container">
                        <b-button
                            type="submit"
                            variant="primary"
                            class="result-button"
                            size="sm"
                        >
                            {{ $t('common.search_verb') }}
                        </b-button>
                        <b-button
                            type="reset"
                            variant="danger"
                            class="result-button"
                            size="sm"
                        >
                            {{ $t('common.clear') }}
                        </b-button>
                    </div>
                </b-form>
            </div>
        </keep-alive>

        <!--Material storage search results-->
        <transition name="fade">
            <material-storage-list
                :storages=results
                @rowClicked=rowClicked
            />
        </transition>
        <transition name="fade">
            <material-storage-editor-modal
                v-model="editStorage"
                @cancel="editStorage = null"
                @closeAndUpdate="closeAndUpdate"
                :user="user"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import MaterialStorageList from "./MaterialStorageList";
import MaterialStorageEditorModal from "./MaterialStorageEditorModal";

export default {
    name: 'MaterialStorageSearch',
    components: {
        MaterialStorageList,
        MaterialStorageEditorModal
    },
    mixins: [restApi],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            storageName: null,
            storageCreatedBy: null,
            loading: true,
            storages: [],
            users: [],
            results: [],
            editStorage: null,
            lastSearchedParams: null
        }
    },
    mounted: function () {
        if (this.isObserver) {
            this.fetchUsers()
        }
        if (!this.isAdmin && !this.isObserver && this.user) {
            this.users.push(this.user)
        }
    },
    methods: {
        fetchUsers: function () {
            this.loading = true
            this.restFetch(this.userUrl, this.handleUsers)
        },
        handleUsers: function (response) {
            this.users = response.data
            this.loading = false
        },
        searchStorages: function (params) {
            this.loading = true
            this.mapVisible = false
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.materialStorageUrl, this.lastSearchedParams, this.handleSearch, () => {
                this.loading = false
            })
        },
        onSubmit: function () {
            let params = {}
            if (this.storageName) {
                params.name = this.storageName
            }
            if (this.storageCreatedBy) {
                params.modifiedBy = this.storageCreatedBy
            }
            this.searchStorages(params)
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        },
        rowClicked: function (item) {
            this.editStorage = item
        },
        onReset: function () {
            this.storageName = null
            this.storageCreatedBy = null
        },
        closeAndUpdate: function () {
            this.editStorage = null
            this.searchStorages(this.lastSearchedParams)
        }
    }
}
</script>
