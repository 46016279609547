<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
        <span class="title">
          {{ $t('observations.type_title') }}
        </span>
            </div>
            <div
                v-else
                @click="showList"
                class="header"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">
          {{ $t('common.back') }}
        </span>
            </div>
        </b-navbar>

        <div
            v-if="!editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <observation-type-list
                    :results="results"
                    @add="add"
                    @edit="edit"
                />
            </transition>
        </div>
        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <observation-type-editor
                    :observation-type="observationType"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {observationHelper} from '../../mixins/ObservationMixin'
import ObservationTypeList from './ObservationTypeList'
import ObservationTypeEditor from './ObservationTypeEditor'

export default {
    name: 'ObservationTypes',
    components: {ObservationTypeList, ObservationTypeEditor},
    mixins: [restApi, observationHelper],
    data: function () {
        return {
            results: [],
            observationType: null,
            editMode: false,
            loading: false
        }
    },
    mounted: function () {
        this.fetchObservationTypes()
    },
    methods: {
        fetchObservationTypes: function () {
            this.loading = true
            this.restFetch(this.observationTypeUrl, this.handleResponse)
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        add: function () {
            this.observationType = this.newObservationType()
            this.editMode = true
        },
        edit: function (obsType) {
            this.observationType = obsType
            this.editMode = true
        },
        showList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.fetchObservationTypes()
            this.showList()
        }
    }
}
</script>
