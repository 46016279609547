<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <b-row class="nopads">
                <b-col cols="10" class="nopads">
                    <span class="vehicle-list-title ml-2">
                    {{ measureDistanceTargetItem ? $t('map.draw_line') : $t('map.measure_distance') }}
                    </span>
                </b-col>
                <b-col cols="2" class="nopads">
                    <div
                        class="vehicle-list-caret"
                        @click.stop="handleRemoveMeasureDistances">
                        <font-awesome-icon icon="times-circle"/>
                    </div>
                </b-col>
            </b-row>
            <div class="col-sm-12 nopads vehicle-list-container">
                <b-row class="nopads">
                    <div class="task-type-circle" v-bind:style="{ backgroundColor: '#ffffff', borderColor: '#0000000', border: '5px solid' }"/>
                    <span class="text-light">{{ $t('map.total_distance') }}: {{this.getTotalDistance(this.visibleMeasureDistances)}} km</span>
                </b-row>
                <b-row v-if="measureDistanceTargetItem">
                    <b-button
                        variant="info"
                        size="lg"
                        @click.stop="measureDistanceTargetItemCallback(visibleMeasureDistances)"
                    >
                        <span class="map-button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </b-row>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'MeasureDistanceMap',
    mixins: [timeUtils, mapHelper, restApi],
    props: {
        measureDistanceResults: {
            type: Array,
            default() {
                return []
            }
        },
        measureDistanceItem: {
            type: Object,
            default: null
        },
        map: {
            type: Object,
            default: null
        },
        infoVisible: {
            type: Boolean,
            default: true
        },
        zoomOnDrawMarker: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        measureDistanceTargetItem: {
            type: Object,
            default: null
        },
        measureDistanceTargetItemCallback: {
            type: Function,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            detailsMode: false,
            editMode: false,
            measureDistances: [],
            visibleMeasureDistances: [],
            editMeasureDistances: [],
            typeEntries: [],
            selectedTypes: [],
            measureDistance: null,
            listOpen: true,
            allSelected: false,
            indeterminate: false,
            TYPE: {
                POINT: 1,
                POLY_LINE: 2
            },
            Z_INDEX: {
                BEHIND: 2,
                INFRONT: 5
            }
        }
    },

    watch: {
        measureDistanceResults: function () {
            this.initView(false)
        },
        selectedTypes(newVal) {
            if (newVal.length === 0) {
                this.indeterminate = false
                this.allSelected = false
            } else if (newVal.length === this.typeEntries.length) {
                this.indeterminate = false
                this.allSelected = true
            } else {
                this.indeterminate = true
                this.allSelected = false
            }
            this.filterByTypes()
        },
        'measureDistance.geometry': {
            handler: function () {
                if (this.measureDistance) {
                    this.drawMeasureDistance(this.measureDistance)
                }
            },
            deep: true
        }
    },
    mounted: function () {
        this.initView(false)
    },
    methods: {
        async updateMeasureDistances() {
            this.loading = true
            this.initView(true)
            this.loading = false
        },
        initView: function (skipAssign) {
            if (this.measureDistances || this.measureDistance) {
                this.hideDistances()
            }
            if (!skipAssign) {
                this.measureDistances = this.measureDistanceResults
                this.measureDistance = this.measureDistanceItem
            }
            if (this.measureDistance) {
                this.drawMeasureDistance(this.measureDistance, this.infoVisible)
            } else if (this.measureDistances && this.measureDistances.length > 0) {
                this.visibleMeasureDistances = this.measureDistances
                this.initTypeEntries()
                this.filterByTypes()
            }
        },

        drawMeasureDistances: function () {
            this.visibleMeasureDistances.forEach(function (measureDistance) {
                this.drawMeasureDistance(measureDistance)
            }, this)

            const linesToDraw = this.visibleMeasureDistances.map((visibleMeasureDistance) => {
                return visibleMeasureDistance.position
            })
            if (linesToDraw.length > 1) {
                this.map.drawPolyLine(this.visibleMeasureDistances.length, this.MEASURE_DISTANCE, linesToDraw, "#404041", false, true)
            }
        },

        drawMeasureDistance: function (measureDistance) {
            let icon = this.map.getMarkerStore().getMeasureDistanceMarkerIcon(this.map.getMapType() === 'LEAFLET')
            this.map.showMapMarker(measureDistance.id, this.MEASURE_DISTANCE, measureDistance.position.y,
                measureDistance.position.x, icon, this.draggable, this.Z_INDEX.INFRONT)
        },

        handleRemoveMeasureDistances: function () {
            this.$emit("onRemoveMeasureDistances", true)
        },

        hideDistances: function () {
            this.map.removeMapItemsByType(this.MEASURE_DISTANCE)
            this.visibleMeasureDistances = []
            this.measureDistance = null
            this.showDetails = false
        },

        showMeasureDistanceTitle: function (data) {
            // Show info bubble
            if (data.type === this.MEASURE_DISTANCE) {
                let item = this.measureDistances.find(o => o.id === data.id)
                if (item) {
                    let x, y
                    if (item.geometry.point) {
                        x = item.geometry.point.x
                        y = item.geometry.point.y
                    }
                    if (item.geometry.line_string) {
                        x = item.geometry.line_string[0].x
                        y = item.geometry.line_string[0].y
                    }
                    if (item) {
                        this.map.addMarkerLabel(item.id, item.name, y, x)
                    }
                }
            }
        },

        hideMeasureDistanceTitle: function (data) {
            // Hide info bubble
            this.map.hideMarkerLabel(data.id)
        },

        showMeasureDistanceDetails: function (measureDistance) {
            this.measureDistance = measureDistance
            this.editMeasureDistances = [this.measureDistance]
            if (this.measureDistance) {
                this.detailsMode = true
            }
        },

        hideMeasureDistanceDetails: function () {
            this.detailsMode = false
        },

        hideMeasureDistanceEditor: function () {
            this.editMode = false
        },

        editMeasureDistance: function () {
            this.detailsMode = false
            this.editMode = true
            this.$emit('scrollTop')
        },

        closeAndUpdate: function () {
            this.editMode = false
            this.$emit('updateMeasureDistances')
        },

        initTypeEntries: function () {
            this.typeEntries = []
            if (this.measureDistances && this.measureDistances.length > 0) {
                this.measureDistances.forEach(item => {
                    let existingType = this.typeEntries.find(element => element.value === item.type_id)
                    if (!existingType) {
                        this.typeEntries.push({text: item.name, value: item.type_id})
                    }
                })
                this.sortTypeEntries()
            }
            // By default, select all types
            if (!this.selectedTypes || this.selectedTypes.length < 1) {
                this.toggleAll(true)
            }
        },

        sortTypeEntries: function () {
            this.typeEntries.sort(function (a, b) {
                if (a.text < b.text) {
                    return -1;
                }
                if (a.text > b.text) {
                    return 1;
                }
                return 0;
            });
        },


        filterByTypes: function () {
            this.hideDistances()
            this.visibleMeasureDistances = []
            if (this.allSelected) {
                this.visibleMeasureDistances = this.measureDistances
            } else {
                this.measureDistances.forEach(item => {
                    let selectedType = this.selectedTypes.find(element => element === item.type_id)
                    if (selectedType) {
                        this.visibleMeasureDistances.push(item)
                    }
                })
            }
            this.drawMeasureDistances()
        },

        toggleAll(checked) {
            this.selectedTypes = []
            if (checked) {
                this.typeEntries.forEach(item => {
                    this.selectedTypes.push(item.value)
                })
            }
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        getDistanceBetweenTwoPoints: function (cord1, cord2) {
            if (cord1.position.y === cord2.position.x && cord1.position.x === cord2.position.x) {
                return 0;
            }

            const radlat1 = (Math.PI * cord1.position.y) / 180;
            const radlat2 = (Math.PI * cord2.position.y) / 180;

            const theta = cord1.position.x - cord2.position.x;
            const radtheta = (Math.PI * theta) / 180;

            let dist =
                Math.sin(radlat1) * Math.sin(radlat2) +
                Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

            if (dist > 1) {
                dist = 1;
            }

            dist = Math.acos(dist);
            dist = (dist * 180) / Math.PI;
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344; //convert miles to km

            return dist;
        },


        getTotalDistance: function(coordinates) {
            coordinates = coordinates.filter((cord) => {
                if (cord.position.y && cord.position.x) {
                    return true;
                }
            });

            let totalDistance = 0;

            if (!coordinates) {
                return 0;
            }

            if (coordinates.length < 1) {
                return 0;
            }

            for (let i = 0; i < coordinates.length - 1; i++) {
                totalDistance =
                    totalDistance +
                    this.getDistanceBetweenTwoPoints(coordinates[i], coordinates[i + 1]);
            }

            return totalDistance.toFixed(2);
        },
    }
}
</script>
