<template>
    <div class="nopads calendar-item-container" v-if="contractObject">
        <div class="contract-header-text" :style="{width: textColWidthPercentage + '%'}" :class="{expanded: ordersVisible}">
            <font-awesome-icon
                icon="caret-right"
                class="pointer ml-1 mr-1"
                style="outline: none"
                :class="{ 'fa-rotate-90': ordersVisible }"
                @click.stop="toggleOrders(false)"/>
            <span class="ml-2" :class="{'disabled-contract' : disableContractSelection}" :id="'c_t_'+contractObject.id" @click.stop="$emit('showContract', contractObject)">
                {{ contractObject.id}} - {{ contractObject.name }} ({{ contractObject.customer ? contractObject.customer.name : null }})
            </span>
            <span class="mr-2 pointer" style="float:right;" :id="'c_t_'+contractObject.id" @click.stop="toggleOrders(!ordersVisible)">
                <div v-if="!ordersVisible">
                    <i class="fa fa-plus"/>
                </div>
                <div v-else>
                    <i class="fa fa-minus"/>
                </div>
            </span>
        </div>

        <div style="display: inline-block; user-select: none">
            <div id="timeline-container" style="display: flex;  position: relative">
                <div class="week-item"
                     :style="{width: weekColWidth + 'px'}"
                     v-for="(week, index) in weeks"
                     v-bind:key="index">{{ '-' }}</div>
                <!-- Draggable and resizable time line -->
                <time-line-item
                    :time-col-width="weekColWidth"
                    :timeline-start-date="new Date(calendarStartDate)"
                    :calendar-start-date="new Date(calendarStartDate)"
                    :visible-weeks-limit="visibleWeeksLimit"
                    :calendar-start-week="calendarStartWeek"
                    :startDate="contractObject.start_date ? getDateFromString(contractObject.start_date) : null"
                    :endDate="contractObject.end_date ? getDateFromString(contractObject.end_date) : null"
                    :static="true"/>
            </div>
        </div>
        <b-collapse :id="'accordion-'+contractObject.id" v-model="ordersVisible">
            <div v-if="loading > 0" class="loading-container">{{ $t('common.loading')}}</div>
            <div v-else>
                <div class="order-text" :style="{width: textColWidthPercentage + '%', borderTop: 'none'}">
                    <b-row class="col-12 nopads">
                        <div class="col-8 single-line" style="border: none; padding-left: 2em">
                            {{ $t('calendar.customer') }}: {{ contractObject.customer.name }}
                        </div>
                        <div class="col-4 nopads text-right pr-2">
                            <b-dropdown
                                class="dropdown-adder"
                                variant="outline-secondary"
                                size="sm">
                                <b-dropdown-item @click.stop="emitAddOrder">{{ $t('calendar.add_order') }}</b-dropdown-item>
                                <b-dropdown-item @click.stop="emitAddAssignment">{{ $t('calendar.add_work_assignment_contract') }}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </b-row>
                </div>
                <br>
                <!-- Orders -->
                <order-calendar-item
                    v-for="order in orders"
                    v-bind:key="order.id"
                    :order="order"
                    :assignments="getOrderAssignments(order.id)"
                    :show-all="showAll"
                    :time-col-width="weekColWidth"
                    :visible-weeks-limit="visibleWeeksLimit"
                    :calendar-start-date="calendarStartDate"
                    :text-col-width-percentage="textColWidthPercentage"
                    :selected-limiter="filter"
                    :assignment-filter="assignmentFilter"
                    :calendar-start-week="calendarStartWeek"
                    @showOrder="emitShowOrder"
                    @showAssignment="emitShowAssignment"
                    @addAssignment="emitAddAssignment"
                    @orderUpdated="$emit('orderUpdated')"
                />
                <!-- Assignments without order -->
                <div class="order-text single-line" :style="{width: textColWidthPercentage + '%', fontWeight: 'bold', color: '#636364', paddingLeft: '3.5em'}">
                    <font-awesome-icon
                        icon="caret-right"
                        class="pointer ml-1"
                        style="outline: none"
                        :class="{ 'fa-rotate-90': assignmentsVisible }"
                        @click.stop="toggleAssignments"/>
                    <span class="ml-4">
                        {{ $t('calendar.assignments_no_order') }} ({{ orderlessAssignments.length }})
                    </span>
                </div>
                <b-collapse id="accordion-orderless-assignments" v-model="assignmentsVisible">
                    <assignment-calendar-item
                        v-for="assignment in orderlessAssignments"
                        v-bind:key="assignment.id"
                        :assignment="assignment"
                        :time-col-width="weekColWidth"
                        :calendar-start-date="calendarStartDate"
                        :visible-weeks-limit="visibleWeeksLimit"
                        :calendar-start-week="calendarStartWeek"
                        :text-col-width-percentage="textColWidthPercentage"
                        :assignments-selected-limiter="assignmentFilter"
                        @showAssignment="emitShowAssignment"/>
                </b-collapse>
            </div>
        </b-collapse>
    </div>
</template>


<script>

import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import TimeLineItem from "@/components/contractcalendar/TimeLineItem";
import {orderMixin} from "@/components/mixins/OrderMixin";
import {workAssignmentHelper} from "@/components/mixins/WorkAssignmentMixin";
import OrderCalendarItem from "@/components/contractcalendar/OrderCalendarItem";
import AssignmentCalendarItem from "@/components/contractcalendar/AssignmentCalendarItem";

export default {
    name: 'ContractCalendarItem',
    components: {AssignmentCalendarItem, OrderCalendarItem, TimeLineItem},
    mixins: [timeUtils, restApi, orderMixin, workAssignmentHelper],
    data: function () {
        return {
            start: null,
            ordersVisible: false,
            assignmentsVisible: false,
            loading: 0,
            contractObject: null,
            showAll: false,
            weeks: [],
            orders: [],
            assignments: [],
        }
    },
    props: {
        contract: {
            type: Object,
            default: null
        },
        textColWidthPercentage: {
            type: Number,
            default: 40
        },
        timeColWidth: {
            type: Number,
            default: 100  // px
        },
        calendarStartDate: {
            type: Date,
            default: null
        },
        visibleWeeksLimit: {
            type: Number,
            default: 20
        },
        calendarStartWeek: {
            type: Number,
            default: 10
        },
        filter: {
            type: Array,
            default: null
        },
        assignmentFilter: {
            type: Array,
            default: null
        },
        autoExpand: {
            type: Boolean,
            default: false
        },
        disableContractSelection: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        weekColWidth() {
            return Math.round(this.timeColWidth / 7) * 7
        },
        isResizeMode() {
            return this.hoverMode === this.RESIZE_END || this.hoverMode === this.RESIZE_START
        },
        orderlessAssignments() {
            this.sortByDate(this.assignments)
            return this.assignments.filter(item => item.work_order === null || item.work_order === undefined);
        },

    },
    watch: {
        calendarStartDate: function () {
            this.initTable()
        },
        calendarStartWeek: function () {
            this.initTable()
        },
    },
    mounted: function () {
        this.contractObject = this.contract
        this.initTable();
        if (this.autoExpand) {
            this.toggleOrders();
        }
    },
    methods: {
        sortByDate(arr) {
            arr.sort((a, b) => {
                if (a.planned_start === undefined) {
                    return 1;
                }
                if (b.planned_start === undefined) {
                    return -1;
                }
                return new Date(b.planned_start).getTime() - new Date(a.planned_start).getTime() ;
            });
        },
        initTable() {
            this.weeks = []
            this.start = this.calendarStartWeek;
            let startWeek = this.start
            for (let i = 0; i < this.visibleWeeksLimit; i++) {
                this.weeks.push(this.$t('calendar.wk_prefix') + (startWeek+i))
            }
        },
        toggleAssignments() {
            this.assignmentsVisible = !this.assignmentsVisible
        },
        toggleOrders(showAll = false) {
            this.ordersVisible = !this.ordersVisible
            this.showAll = showAll
            if (this.ordersVisible) {
                if (this.contract.orders) {
                    this.orders = this.contract.orders
                } else {
                    this.fetchOrders()
                }
                this.fetchAssignments()
            }
        },
        fetchOrders: function () {
            this.loading++
            this.orders = []
            this.restFetchParams(this.orderUrl, {contract: this.contractObject.id}, this.handleOrders)
        },
        handleOrders: function (response) {
            this.loading--
            if (response != null) {
                this.orders = response.data
            }
        },
        refreshContract(contract) {
            this.contractObject = contract
            this.fetchOrders()
        },
        fetchAssignments() {
            this.loading++
            this.assignments = []
            this.restFetchParams(this.workAssignmentUrl, {contract: this.contractObject.id}, this.handleAssignments)
        },
        handleAssignments(response) {
            this.loading--
            if (response != null && response.data) {
                this.assignments = response.data
                // Restore collapse/ expand state
                if (this.assignmentsVisible) {
                    this.$refs('assignmentsContainer').collapse('show')
                }
            }
        },
        emitShowOrder(order) {
            this.$emit('showOrder', order, this.contract)
        },
        emitAddOrder() {
            this.$emit('addOrder', this.contractObject ? this.contractObject : null)
        },
        emitAddAssignment(orderObject) {
            let contract = this.contractObject ? this.contractObject : null
            if(contract) {
                contract.work_order = orderObject ? orderObject : null
            }
            this.$emit('addAssignment', contract)
        },
        getOrderAssignments(orderId) {
            return this.assignments.filter(item => item.work_order && item.work_order.id === orderId)
        },
        emitShowAssignment(assignment) {
            this.$emit('showAssignment', assignment)
        }
    }
}
</script>
<style scoped>
.calendar-item-container {
    user-select: none;
}

.expanded {
    background: #FFFFFF !important;
}

.contract-header-text, .week-item, .order-text {
    display: inline-block;
    background: #ffffff;
    border-top: 1px #BCBCBC solid;
    border-right: 1px #BCBCBC solid;
    margin: 0;
    padding: .1em;
    font-size: .9em;
    user-select: none;
    min-height: 2em;
}

.contract-header-text {
    font-weight: bold;
    background: #f6f5f1;
}

.order-text {
    color: #404041;
    padding-left: 2.2em;
}

.contract-header-text:hover {
    color: #007BFF;
    font-weight: bold;
    cursor: pointer;
}

.disabled-contract {
    color: #404041 !important;
    cursor: default;
}

.week-item {
    color: #FFFFFF;
    user-select: none;
    padding: .1em;
}

.loading-container {
    padding: .5em;
    background: #ffffff;
    margin: 0;
}

.dropdown-adder {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 1.4em;
}

.btn-sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.single-line {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


</style>
