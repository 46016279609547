<template>
    <div
        class="map-info-container"
        @click.stop="$emit('click')"
    >
        <b-table
            small
            responsive
            borderless
            :items="data"
            :fields="fields"
            class="info-table-top"
        >
            <template
                v-if="title"
                slot="table-caption"
                :label-for="title"
            >
                <span class="map-title">{{ title }}</span>
            </template>
        </b-table>
        <span class="tip" style="color: #00eaff !important;">{{ $t('common.click_to_hide') }}</span>
    </div>
</template>

<script>
export default {
    name: 'MapInfoTable',
    props: {
        fields: {
            type: Array,
            default() {
                return []
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            title: null
        }
    }
}
</script>
