<template>
    <div class="col-sm-12">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="clock"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="results && results.length"
                class="col-sm-12 data-container"
            >
                <div
                    v-for="result of results"
                    :key="result.id"
                    class="data-list-item"
                    @click.stop="$emit('edit', result)"
                >
                    <div class="col-sm-12 item-header">
                        <div class="item-img-cont">
                            <font-awesome-icon
                                v-if="result.tracked"
                                icon="map-marker"
                            />
                            <font-awesome-icon
                                v-else
                                icon="clock"
                            />
                        </div>
                        <div
                            v-if="isAdmin"
                            class="item-text-cont"
                        >
              <span class="item-title">
                {{ result.name }}
              </span>
                            <span class="item-text">
                {{ result.company.name }}
              </span>
                        </div>
                        <div
                            v-else
                            class="item-text-cont"
                        >
              <span class="item-title-single">
                {{ result.name }}
              </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'WorkTimeModeList',
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        },
        isAdmin: Boolean
    }
}
</script>
