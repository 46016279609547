<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="certificateTypeUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('certificate.edit_type') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('certificate.add_type') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <span class="span-title pl-3">
                  {{ $t('observations.name') }}
                </span>
                <b-row class="nopads">
                    <div class="col-sm-6">
                            <b-form-input
                                id="name"
                                v-model="certificateTypeUpdate.name"
                                type="text"
                                size="mb"
                            />
                    </div>
                    <div class="col-sm-6">
                        <b-form-checkbox
                            v-model="certificateTypeUpdate.is_expiring">
                            {{ $t('certificate.expiring')}}
                        </b-form-checkbox>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '.././mixins/RestApiMixin'

export default {
    name: 'CertificateTypeEditor',
    mixins: [restApi],
    props: {
        certificateType: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            certificateTypeUpdate: this.copyObj(this.certificateType)
        }
    },
    methods: {
        copyObj: function (obj) {
            return JSON.parse(JSON.stringify(obj))
        },
        submit: function () {
            if (this.certificateTypeUpdate.name.trim() === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return
            }
            this.loading = true
            if (this.certificateTypeUpdate.id < 1) {
                var certificateType = this.copyObj(this.certificateTypeUpdate)
                certificateType.id = null
                this.restAdd(this.certificateTypeUrl, certificateType, this.success, this.fail)
            } else {
                this.restUpdate(this.certificateTypeUrl, this.certificateTypeUpdate, this.success, this.fail)
            }
        },
        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>
