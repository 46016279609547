<template>
    <base-modal style="z-index: 50" :min-width="true">
        <div class="col-12">
            <h3 v-if="!light || !light.id">{{ $t('street_lights.add') }}</h3>
            <h3 v-else>{{ $t('street_lights.edit') }}</h3>
        </div>

        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div class="col-sm-12">
            <b-form v-if="light" class="col-sm-12 editor-form">
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.photos')}} ({{light && light.photos ? light.photos.length : 0}})</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : photosVisible}"
                            v-b-toggle.photoSection
                            @click.stop="photosVisible = !photosVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="photoSection" visible>
                    <div class="col-12 carousel-container">
                        <Flicking ref="flicking" :options="options" :plugins="plugins" v-if="light.photos && light.photos.length > 0" class="pointer nopads text-center"
                                  style="height: 60vh">
                            <div v-for="photo in light.photos" :key="photo.id" class="pointer p-1">
                                <ImageView
                                    ref="image"
                                    :url="getPhotoUrl(streetLightPhotoUrl, photo)"
                                    @click="openImage"
                                />
                            </div>
                            <span slot="viewport" class="flicking-arrow-prev"></span>
                            <span slot="viewport" class="flicking-arrow-next"></span>
                        </Flicking>
                        <span v-else-if="loading" class="title">{{ $t('common.loading')}}</span>
                        <span v-else class="title">{{ $t('power_stations.no_photos')}}</span>
                    </div>
                    <!--   Photo editor-->
                    <b-row class="nopads pt-2">
                        <span class="span-title">{{ $t('power_stations.add_photo') }}</span>
                        <div
                            v-for="(path, counter) in photos"
                            :key="counter"
                            class="col-sm-12 nopads"
                        >
                            <b-form-group
                                class="title"
                                label-for="counter"
                                v-if="counter === 0 || isAttachmentSet(photos, counter-1)"
                            >
                                <b-form-file
                                    v-model="photos[counter]"
                                    accept="image/jpeg, image/jpg, image/png"
                                    :placeholder="$t('equipment.photo_placeholder')"
                                />
                            </b-form-group>
                        </div>
                    </b-row>
                </b-collapse>
                <hr/>
                <h4 class="form-header">{{ $t('street_lights.basic_info')}}</h4>
                <b-row class="nopads">
                    <div class="col-12 p-1">
                        <span class="span-title">{{ $t('street_lights.position') }}</span>
                        <div v-if="light.id && light.id > 0" class="nopads">
                            <b-row v-if="light.road_number">{{ light.road_number }} / {{ light.road_section }}</b-row>
                            <b-row>{{ light.street }} {{ light.city }}</b-row>
                            <b-row>{{ light.distance}} m</b-row>
                        </div>
                        <span v-else style="font-size: .85em">{{ coordinate.lat.toFixed(6) + ' ' + coordinate.lng.toFixed(6)}}</span>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6 p-1">
                        <span class="span-title">{{ $t('street_lights.location_name') }}</span>
                        <b-form-input
                            id="lightLocation"
                            v-model="light.location_name"
                            type="text"
                            size="sm"/>
                    </div>
                    <div class="col-sm-6 p-1">
                        <span class="span-title">{{ $t('street_lights.station') }}</span>
                        <b-form-select id="lightStation" size="sm" v-model="selectedStation" :options="stations">
                            <template #first>
                                <option :value="null">{{ $t('street_lights.select_station') }}</option>
                            </template>
                        </b-form-select>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.status') }}</span>
                        <b-form-select id="lightStatus" size="sm" v-model="light.status" :options="statusOptions">
                            <template #first>
                                <option :value="null">{{ $t('street_lights.select_status') }}</option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.lighting_class') }}</span>
                        <b-form-select id="lightClass"  size="sm" v-model="light.lighting_class" :options="lightingClassOptions"></b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.construction_year') }}</span>
                        <b-form-input
                            id="lightYear"
                            v-model="light.construction_year"
                            type="number"
                            min="0"
                            size="sm"/>
                    </div>
                </b-row>
                <hr/>
                <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.contract')}}</h4>
                <span class="tip-light">{{ $t('power_stations.contract_tip')}}</span>
                <open-contract-filter
                    :to="toDate"
                    :from="fromDate"
                    :contract="preselectedContract"
                    :show-company="false"
                    :show-title="false"
                    @loading="loading=true"
                    @loadingCompleted="loading=false"
                    @contractSelected="setContract"
                    style="margin-left: -2px; margin-right: -3px;"/>
                <hr/>
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('street_lights.light_info')}}</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : lightInfoVisible}"
                            v-b-toggle.lightSection
                            @click.stop="lightInfoVisible = !lightInfoVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="lightSection">
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.light_type') }}</span>
                            <b-form-select id="lightType" size="sm" v-model="light.light_type" :options="lightTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.power_rating') }}</span>
                            <b-form-input
                                id="powerRating"
                                v-model="light.power_rating"
                                type="number"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.light_manufacturer') }}</span>
                            <b-form-input
                                id="lightManufacturer"
                                v-model="light.light_manufacturer"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.model') }}</span>
                            <b-form-input
                                id="lightModel"
                                v-model="light.model"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.installation') }}</span>
                            <b-form-select id="lightInstallation" size="sm" v-model="light.installation" :options="installOptions"></b-form-select>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.optics') }}</span>
                            <b-form-input
                                id="optics"
                                v-model="light.optics"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.dimming_profile') }}</span>
                            <b-form-checkbox
                                id="dimmingProfile"
                                size="lg"
                                v-model="light.dimming_profile">
                            </b-form-checkbox>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.bulb_type') }}</span>
                            <b-form-select id="lightLampType" size="sm" v-model="light.bulb_type" :options="bulbTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.bulb_count') }}</span>
                            <b-form-input
                                id="bulbCount"
                                v-model="light.bulb_count"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.bulb_manufacturer') }}</span>
                            <b-form-input
                                id="bulbManufacturer"
                                v-model="light.bulb_manufacturer"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.guarantee_basis') }}</span>
                            <b-form-select id="guaranteeBasis" size="sm" v-model="light.guarantee_basis" :options="guaranteeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.guarantee_expiration') }}</span>
                            <b-form-input
                                id="guaranteeExp"
                                v-model="light.guarantee_expiration"
                                type="date"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.guarantor') }}</span>
                            <b-form-input
                                id="guarantor"
                                v-model="light.guarantor"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                </b-collapse>
                <hr/>
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('street_lights.pole_info')}}</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : poleVisible}"
                            v-b-toggle.poleSection
                            @click.stop="poleVisible = !poleVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="poleSection">
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.pole_type') }}</span>
                            <b-form-select id="poleType" size="sm" v-model="light.pole_type" :options="poleTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.pole_manufacturer') }}</span>
                            <b-form-input
                                id="lightManufacturer"
                                v-model="light.pole_manufacturer"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-12 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.pole_additional_info') }}</span>
                            <b-form-textarea
                                id="info"
                                v-model="light.pole_info"
                                size="sm"
                                :placeholder="$t('street_lights.info')"
                                :rows="4"
                                :max-rows="8"
                            />
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.stand') }}</span>
                            <b-form-input
                                id="stand"
                                v-model="light.stand"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.installation_height') }}</span>
                            <b-form-input
                                id="installHght"
                                v-model="light.installation_height"
                                type="number"
                                min="0"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.supporting_structure') }}</span>
                            <b-form-select id="supportStruct" size="sm" v-model="light.supporting_structure" :options="poleSupportOptions"></b-form-select>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.arm_type') }}</span>
                            <b-form-select id="armType" size="sm" v-model="light.arm_type" :options="armTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.arm_length') }}</span>
                            <b-form-input
                                id="armLength"
                                v-model="light.arm_length"
                                type="number"
                                min="0"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.cabling') }}</span>
                            <b-form-select id="lightCabling" size="sm" v-model="light.cabling" :options="cablingOptions"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.earthing') }}</span>
                            <b-form-select id="lightCabling" size="sm" v-model="light.earthing" :options="earthingOptions"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.pole_grounding_value') }}</span>
                            <b-form-input
                                id="armLength"
                                v-model="light.ground_value"
                                type="number"
                                min="0"
                                size="sm"/>
                        </div>
                    </b-row>
                </b-collapse>
                <hr/>
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('street_lights.maintenance_info')}}</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : maintenanceVisible}"
                            v-b-toggle.maintenanceSection
                            @click.stop="maintenanceVisible = !maintenanceVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="maintenanceSection">
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.bulb_replaced_on') }}</span>
                            <b-form-input
                                id="bulbReplace"
                                v-model="light.bulb_replaced_on"
                                type="date"
                                size="sm"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.last_maintenance') }}</span>
                            <b-form-input
                                id="bulbReplace"
                                v-model="light.last_maintenance"
                                type="date"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.short_circuit_current') }}</span>
                            <b-form-input
                                v-model="light.short_circuit_current"
                                type="number"
                                size="sm"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.short_circuit_current_measure_date') }}</span>
                            <b-form-input
                                v-model="light.short_circuit_current_measure_date"
                                type="date"
                                format="dd.MM.yyyy"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                            <span class="span-title">{{ $t('street_lights.other_usage') }}</span>
                            <b-form-textarea
                                id="otherUsage"
                                v-model="light.other_usage"
                                size="sm"
                                :placeholder="$t('street_lights.other_usage')"
                                :rows="4"
                                :max-rows="8"
                            />
                    </b-row>
                    <b-row class="nopads">
                            <span class="span-title">{{ $t('street_lights.hazards') }}</span>
                            <b-form-textarea
                                id="hazards"
                                v-model="light.hazards"
                                size="sm"
                                :placeholder="$t('street_lights.hazards')"
                                :rows="4"
                                :max-rows="8"
                            />
                    </b-row>
                </b-collapse>
                <hr/>
                <h4 class="form-header mt-0 pt-0">{{ $t('street_lights.info') }}</h4>
                <b-form-textarea
                    id="info"
                    v-model="light.info"
                    :placeholder="$t('street_lights.info')"
                    :rows="4"
                    :max-rows="8"
                />
                <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                    <b-button
                        variant="secondary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        :disabled="loading||this.light===null"
                        @click.stop="submit"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
        <!-- Fullscreen photo -->
        <transition name="fade">
            <fullscreen-image
                v-if="fullScreenPhotoData"
                :largeImages="fullScreenPhotoData"
                :panelIndex="panelIndex"
                @close="closePhoto"/>
        </transition>
    </base-modal>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import BaseModal from '../BaseModal'
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {timeUtils} from "@/components/mixins/TimeUtils";
import FullscreenImage from "@/components/view/FullscreenImage";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import {streetLightHelper} from "@/components/mixins/StreetLightMixin";
import {Arrow} from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/vue-flicking/dist/flicking.css";
import {Flicking} from "@egjs/vue-flicking";
import ImageView from "@/components/view/ImageView.vue";

export default {
    name: 'StreetLightWizard',
    components: {FullscreenImage, Flicking, OpenContractFilter, BaseModal, ImageView},
    mixins: [restApi, timeUtils, attachmentHelper, streetLightHelper],
    props: {
        selectedContract: {
            type: Object,
            default: null
        },
        coordinate: {
            type: Object,
            default: null
        },
        streetLight: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            light: null,
            contract: null,
            fullScreenPhotoData: null,
            photosVisible: true,
            lightInfoVisible: false,
            poleVisible: false,
            maintenanceVisible: false,
            photos: new Array(5),
            selectedStation: null,
            stations: [],
            variant: 'danger',
            plugins: [new Arrow()],
            options: {
                preventDefaultOnDrag: true,
                bound: true,
                panelsPerView: 3,
            },
            panelIndex: 0
        }
    },
    computed: {
        fromDate() {
            if (this.powerStation) {
                return this.powerStation.created_at
            }
            let d = new Date()
            return d.toISOString()
        },
        toDate() {
            let d = new Date()
            return d.toISOString()
        },
        preselectedContract() {
            return this.light && this.light.contract ? this.light.contract : null
        }
    },
    mounted() {
        this.fetchStations()
        if (!this.streetLight) {
            this.light = {
                id: null,
                street: null,
                city: null,
                location_name: null,
                road_number: null,
                road_section: null,
                distance: null,
                construction_year: null,
                last_maintenance: null,
                lighting_class: null,
                light_manufacturer: null,
                light_type: null,
                arm_length: null,
                arm_type: null,
                bulb_count: null,
                bulb_manufacturer: null,
                bulb_replaced_on: null,
                bulb_type: null,
                guarantee_basis: null,
                guarantee_expiration: null,
                guarantor: null,
                hatch_count: null,
                installation_height: null,
                pole_model: null,
                pole_type: null,
                pole_structure: null,
                supporting_structure: null,
                stand: null,
                power_station: null,
                status: null,
                contract: null,
                info: '',
                short_circuit_current: null,
                short_circuit_current_measure_date: null,
                position: this.coordinate,
                earthing: null,
                cabling: null,
                hazards: null,
                otherUsage: null
            }
        } else {
            this.light = this.streetLight
            this.light.short_circuit_current_measure_date = this.timestampToDateInput(this.light.short_circuit_current_measure_date)
            this.light.bulb_replaced_on = this.timestampToDateInput(this.light.bulb_replaced_on)
            this.light.guarantee_expiration = this.timestampToDateInput(this.light.guarantee_expiration)
            this.light.last_maintenance = this.timestampToDateInput(this.light.last_maintenance)
            this.contract = this.light.contract ? this.light.contract : null
            this.selectedStation = this.light.power_station.id
        }
    },
    methods: {
        fetchStations() {
            this.loading = true
            this.stations = []
            this.restFetch(this.powerStationUrl, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            if (response && response.data) {
                response.data.forEach(station => {
                    this.stations.push({value: station.id, text: station.id + ' ' + station.location_name})
                })
            }
            this.loading = false
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        submit: function () {
            this.loading = true
            if (this.contract) {
                this.light.contract = this.contract
            }
            if (!this.selectedStation) {
                this.showAlert(this.$t('street_light.error_station'))
                return;
            } else {
                this.light.power_station = {id: this.selectedStation}
            }
            if (!this.light.position) {
                this.showAlert(this.$t('street_light.error_position'))
                return;
            } else if (this.coordinate) {
                // Coordinates given as props for new light
                this.light.position = {
                    lat: this.coordinate.lat,
                    lon: this.coordinate.lng
                }
            }
            if (this.light.id && this.light.id > 0) {
                this.restUpdate(this.streetLightUrl, this.light, this.success, this.fail)
            } else {
                this.restAdd(this.streetLightUrl, this.light, this.success, this.fail)
            }
        },
        success: function (response) {
            let photos = this.getValidAttachments(this.photos)
            if (photos.length > 0) {
                this.uploadPhoto(photos, response.id)
            } else {
                this.uploadCompleted()
            }
        },
        uploadPhoto: function (photos, id) {
            if (photos.length > 0) {
                this.loading = true
                let formData = new FormData()
                photos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                this.restPostWithFile(this.streetLightPhotoUrl  + '/' + id, {}, formData, this.uploadCompleted, this.fail)
            }
        },
        uploadCompleted: function () {
            this.loading = false
            this.showAlert(this.$t('road_sign.added'), 'success')
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_add_failed'))
        },
        setContract: function (contract) {
            this.contract = contract
        },
        getPhotoUrl: function (baseUrl, photo) {
            return baseUrl + '/' + this.light.id + '/' + photo.id
        },
        openImage: function () {
            const flicking = this.$refs.flicking
            const imageData = this.$refs.image
            this.fullScreenPhotoData = []
            imageData.forEach(src => {
                this.fullScreenPhotoData.push(src)
            })
            this.panelIndex = flicking.index

        },
        closePhoto() {
            this.fullScreenPhotoData = undefined
        }
    }
}
</script>
<style>
@import "../../styles/modal.css";
</style>
