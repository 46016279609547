<template>
    <div class="col-12 stat-container pt-3 pb-2">
        <b-row>
            <span class="section-title">{{ $t('menu.observation_types') }} ({{observationTypes.length}})</span>
                <b-col class="nopads">
                    <font-awesome-icon
                        icon="arrow-down"
                        class="pointer right no-focus arrow-icon dont-print"
                        v-bind:class="{rotated : observationTypesVisible}"
                        v-b-toggle.observationTypes
                        @click.stop="observationTypesVisible = !observationTypesVisible"/>
                </b-col>
        </b-row>
        <b-collapse
            id="observationTypes"
            class="mt-2 mb-2 item-details show-print"
        >
            <!-- Header Row -->
            <b-row class="nopads align-items-center">
                <div class="col-3">
                    <font-awesome-icon icon="chart-line" class="stat-icon dont-print"/>
                </div>
                <div class="col-7 text-left pl-4">
                    <span class="stat-title">{{ $t('contract_reports.observation_type') }}</span>
                </div>
                <div class="col-2 text-right">
                    <span class="stat-title">{{ $t('contract_reports.amount') }}</span>
                </div>
            </b-row>

            <!-- Observation Type Rows -->
            <b-row class="nopads">
                <div v-for="(observationType, index) in observationTypes" :key="index" class="col-12 mb-2">
                    <b-row class="align-items-center">
                        <!-- Observation Type Name -->
                        <div class="col-8">
                            <span class="font-weight-bold">{{ observationType.name }}</span>
                        </div>
                        <!-- Observation Type Count -->
                        <div class="col-4 text-right">
                            <span class="text-muted">{{ observationType.count }}</span>
                        </div>
                    </b-row>
                </div>
            </b-row>
        </b-collapse>
    </div>
</template>

<script>

export default {
    name: "ObservationTypeSummary",
    props: {
        observationTypes: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data: function () {
        return {
            observationTypesVisible: false
        }
    }
}
</script>

