<template>
    <base-modal style="z-index: 50">
        <div class="col-12" style="min-width: 70vw">
            <h3>{{ $t('observation_reports.add') }}</h3>
        </div>

        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div class="col-sm-12">
            <b-form class="col-sm-12 editor-form">
                <b-row class="nopads">
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('observation_reports.name') }}</span>
                        <b-form-input
                            id="name"
                            autocomplete="off"
                            v-model="report.name"
                            type="text"
                            size="sm"/>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('observation_reports.observation_type') }}</span>
                        <b-form-select id="observationType"  size="sm" v-model="observationType" :options="observationTypes"/>
                    </div>
                    <div class="col-sm-4 p-1">
                            <span class="span-title">{{ $t('observation_reports.x_axis') }}</span>
                            <b-form-select id="xAxis"  size="sm" v-model="report.x_axis" :options="axisOptions"/>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('observation_reports.upper_alert') }}</span>
                        <b-form-input
                            id="name"
                            autocomplete="off"
                            v-model="report.upper_alert_limit"
                            type="text"
                            size="sm"/>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('observation_reports.lower_alert') }}</span>
                        <b-form-input
                            id="name"
                            autocomplete="off"
                            v-model="report.lower_alert_limit"
                            type="number"
                            size="sm"/>
                    </div>
                </b-row>
                <b-row class="nopads pl-2">
                    <span class="tip-light">{{ $t('observation_reports.alert_hint')}}</span>
                </b-row>
                <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                    <b-button
                        variant="secondary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        :disabled="loading||this.report===null"
                        @click.stop="submit"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </base-modal>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import BaseModal from '../../BaseModal'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'ObservationReportEditor',
    components: {BaseModal},
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            variant: 'danger',
            observationType: null,
            observationTypes: [],
            report: {
                id: null,
                name: null,
                observation_type: null,
                x_axis: null,
                upper_alert_limit: null,
                lower_alert_limit: null
            },
            axisOptions: [
                {text: this.$t('observation_reports.x_time'), value: 1},
                {text: this.$t('observation_reports.x_road_number'), value: 2},
                {text: this.$t('observation_reports.x_road_section'), value: 3},
                {text: this.$t('observation_reports.x_street_name'), value: 4},
            ]
        }
    },
    mounted() {
        this.fetchObservationTypes()
    },
    methods: {
        fetchObservationTypes() {
            this.loading = true
            this.restFetchParams(this.observationTypeUrl, {only_numeric: 1}, this.handleTypes)
        },
        handleTypes (response) {
           this.loading = false
           if (response && response.data) {
               this.observationTypes = []
               response.data.forEach(item => {
                   this.observationTypes.push({text: item.name, value: item.id})
               })
           }
        },
        submit: function () {
            this.loading = true
            if (!this.observationType) {
                this.showAlert(this.$t('observation_reports.error_type'))
                return;
            }
            this.report.observation_type = {id: this.observationType}
            this.restAdd(this.observationReportTemplateUrl, this.report, this.success, this.fail)
        },
        success: function () {
            this.loading = false
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_add_failed'))
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        }
    }
}
</script>
<style>
@import "../../../styles/modal.css";
</style>
