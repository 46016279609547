import {equipmentOidTypes} from '@/components/mixins/ConversionMixin'

export default class VelhoPipesWiresCablesModel {
    // Private properties
    /** @type {string} */
    #external_system_id = equipmentOidTypes.EXTERNAL_PIPE_WIRE_CABLE

    constructor(
        type = null, material = null, condition = null, refinedMaterial = null, protectiveTube = null, distanceFromRoadEdge = null, insideDiameter = null, code = "",
        length = null, dischargePipe = false, overheadStructures = false, strengthClass = "", undergroundCableStructure = false, name = "", position = { x: null, y: null },
        roadNum = null, roadSec = null, distance = null, equipmentDamages = [], locationDeviation = null, locationEnding = { road_num: null, road_sec: null, distance: null },
        locationSpecification = { lane: [], side: null, tracks: null, central_area: null }, externalSystemId = ""
    ) {
        /** @type {number | null} */
        this.type = type

        /** @type {number | null} */
        this.material = material

        /** @type {number | null} */
        this.condition = condition

        /** @type {number | null} */
        this.refined_material = refinedMaterial

        /** @type {number | null} */
        this.protective_tube = protectiveTube

        /** @type {number | null} */
        this.distance_from_road_edge = distanceFromRoadEdge

        /** @type {number | null} */
        this.inside_diameter = insideDiameter

        /** @type {string} */
        this.code = code
        
        /** @type {number | null} */
        this.length = length

        /** @type {boolean} */
        this.discharge_pipe = dischargePipe

        /** @type {boolean} */
        this.overhead_structures = overheadStructures
        
        /** @type {string} */
        this.strength_class = strengthClass

        /** @type {boolean} */
        this.underground_cable_structure = undergroundCableStructure

        /** @type {string} */
        this.name = name
        
        /** @type {{ x: number, y: number }} */
        this.position = position

        /** @type {number | null} */
        this.road_num = roadNum

        /** @type {number | null} */
        this.road_sec = roadSec

        /** @type {number | null} */
        this.distance = distance

        /** @type {Array} */
        this.equipment_damages = equipmentDamages

        /** @type {number | null} */
        this.location_deviation = locationDeviation

        /** @type {{ road_num: number | null, road_sec: number | null, distance: number | null }} */
        this.location_ending = locationEnding

        /** @type {{ lane: number[], side: number | null, tracks: number | null, central_area: number | null }} */
        this.location_specification = locationSpecification

        if(externalSystemId) {
            this.#external_system_id = externalSystemId
        }
    }

    /**
    * Gets the unique identifier.
    * @returns {string}
    */
    get external_system_id() {
        return this.#external_system_id
    }

    /**
    * Get the json object.
    * @returns {object}
    */
    toJSON() {
        return JSON.parse(JSON.stringify({
            external_system_id: this.#external_system_id,
            type: this.type,
            material: this.material,
            condition: this.condition,
            refined_material: this.refined_material,
            protective_tube: this.protective_tube,
            distance_from_road_edge: this.distance_from_road_edge,
            inside_diameter: this.inside_diameter,
            code: this.code,
            length: this.length,
            discharge_pipe: this.discharge_pipe,
            overhead_structures: this.overhead_structures,
            strength_class: this.strength_class,
            underground_cable_structure: this.underground_cable_structure,
            name: this.name,
            position: this.position,
            road_num: this.road_num,
            road_sec: this.road_sec,
            distance: this.distance,
            equipment_damages: this.equipment_damages,
            location_deviation: this.location_deviation,
            location_ending: this.location_ending,
            location_specification: {
                ...this.location_specification,
                tracks: this.location_specification.tracks !== null ? [this.location_specification.tracks] : []
            }
        }))
    }

    /**
    * Create default Velho gate instance with position data.
    * @param {{ x: number, y: number }} position - coordinates.
    * @param {number | null} roadNum - Road number.
    * @param {number | null} roadSec - road section.
    * @param {number | null} distance - distance (road geometry).
    * @returns {VelhoPipesWiresCablesModel} A new Velho gate instance.
    */
    static instanceWithPosition(position, roadNum, roadSec, distance) {
        const instance = new VelhoPipesWiresCablesModel()
        instance.position = position
        instance.road_num = roadNum
        instance.road_sec = roadSec
        instance.distance = distance
        return instance
    }
}