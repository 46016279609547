import {material} from "./ConversionMixin";

export const postHelper = {
    methods: {
        getPostTypes: function () {
            return [
                {name: this.$t('post.pipes.title'), value: postTypes.PIPE},
                {name: this.$t('post.profiles.title'), value: postTypes.PROFILE},
                {name: this.$t('post.lattices.title'), value: postTypes.LATTICE},
            ]
        },
        getStandPostTypes: function () {
            return [
                {name: this.$t('post.stand_type.unknown'), value: postStandTypes.UNKNOWN},
                {name: this.$t('post.stand_type.concrete'), value: postStandTypes.CONCRETE},
                {name: this.$t('post.stand_type.impact_pile'), value: postStandTypes.IMPACT_PILE},
                {name: this.$t('post.stand_type.screw_pile'), value: postStandTypes.SCREW_PILE},
                {name: this.$t('post.stand_type.other'), value: postStandTypes.OTHER},
            ]
        },
        getPostThicknesses: function () {
            return [
                {name: this.$t('post.thickness.t_60'), value: postThicknesses.THICKNESS_60},
                {name: this.$t('post.thickness.t_90'), value: postThicknesses.THICKNESS_90},
                {name: this.$t('post.thickness.t_110'), value: postThicknesses.THICKNESS_110},
                {name: this.$t('post.thickness.t_114'), value: postThicknesses.THICKNESS_114},
                {name: this.$t('post.thickness.other'), value: postThicknesses.OTHER},
            ]
        },
        getStandMaterials: function () {
            return [
                {name: this.getMaterialString(material.ALUMINIUM), value: material.ALUMINIUM},
                {name: this.getMaterialString(material.PLYWOOD), value: material.PLYWOOD},
                {name: this.getMaterialString(material.GLAS), value: material.GLAS},
                {name: this.getMaterialString(material.WOOD), value: material.WOOD},
                {name: this.getMaterialString(material.STEEL), value: material.STEEL},
                {name: this.getMaterialString(material.CAST_IRON), value: material.CAST_IRON},
                {name: this.getMaterialString(material.GROUND_SUBSTANCE), value: material.GROUND_SUBSTANCE},
                {name: this.getMaterialString(material.OTHER), value: material.OTHER},
                {name: this.getMaterialString(material.CONCRETE), value: material.CONCRETE},
                {name: this.getMaterialString(material.PLASTIC), value: material.PLASTIC},
                {name: this.getMaterialString(material.ROCK), value: material.ROCK},
                {name: this.getMaterialString(material.PLEXIGLASS), value: material.PLEXIGLASS},
                {name: this.getMaterialString(material.GLASS_FIBER), value: material.GLASS_FIBER},
                {name: this.getMaterialString(material.ANIMAL_NET), value: material.ANIMAL_NET},
                {name: this.getMaterialString(material.ARMOR_MESH), value: material.ARMOR_MESH},
                {name: this.getMaterialString(material.METAL), value: material.METAL},
                {name: this.getMaterialString(material.ASPHALT), value: material.ASPHALT},
                {name: this.getMaterialString(material.ALUMINIUM_COMPOSITE), value: material.ALUMINIUM_COMPOSITE},
            ]
        },
        getPostMaterials: function () {
            return [
                {name: this.getMaterialString(material.ALUMINIUM), value: material.ALUMINIUM},
                {name: this.getMaterialString(material.WOOD), value: material.WOOD},
                {name: this.getMaterialString(material.STEEL), value: material.STEEL},
                {name: this.getMaterialString(material.OTHER), value: material.OTHER},
                {name: this.getMaterialString(material.CONCRETE), value: material.CONCRETE},
                {name: this.getMaterialString(material.PLASTIC), value: material.PLASTIC},
                {name: this.getMaterialString(material.ROCK), value: material.ROCK},
                {name: this.getMaterialString(material.GLASS_FIBER), value: material.GLASS_FIBER},
                {name: this.getMaterialString(material.METAL), value: material.METAL},
                {name: this.getMaterialString(material.ALUMINIUM_COMPOSITE), value: material.ALUMINIUM_COMPOSITE},
            ]
        },
    }
}

export const postTypes = {
    PIPE: 1,
    PROFILE: 2,
    LATTICE: 3,
}

export const postStandTypes = {
    UNKNOWN: 0,
    CONCRETE: 1,
    IMPACT_PILE: 2,
    SCREW_PILE: 3,
    OTHER: 4
}

export const postThicknesses = {
    THICKNESS_60: 1,
    THICKNESS_90: 2,
    THICKNESS_110: 3,
    THICKNESS_114: 4,
    OTHER: 5
}
