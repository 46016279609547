import { render, staticRenderFns } from "./EdgePileEditor.vue?vue&type=template&id=4881c448&scoped=true"
import script from "./EdgePileEditor.vue?vue&type=script&lang=js"
export * from "./EdgePileEditor.vue?vue&type=script&lang=js"
import style0 from "./EdgePileEditor.vue?vue&type=style&index=0&id=4881c448&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4881c448",
  null
  
)

export default component.exports