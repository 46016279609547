<template>
    <div>
        <b-row
            v-for="(newProperty, index) in newProperties"
            :key="'property'+index"
            class="p-1"
            style="border-bottom: 1px solid #f0f0f0">
                <div class="col-10 nopads" style="font-size: .9em">
                    {{ newProperty }}
                </div>
                <div class="col-2 nopads text-right">
                    <b-button
                        variant="danger"
                        size="sm"
                        class="mr-1"
                        @click.stop="() => $emit('removeProperty', index)"
                    >
                        <i class="fa fa-trash"/>
                    </b-button>
                </div>
        </b-row>

        <!-- Placeholder for a new one -->
        <order-geometry-property-selector
            :large-mode="true"
            ref="orderGeometryPropertySelector"
            class="mt-3"
            @addProperty="(newProperty) => $emit('addProperty', newProperty)"/>
    </div>
</template>

<script>
import OrderGeometryPropertySelector from "./OrderGeometryPropertySelector";
export default {
    name: "OrderGeometryPropertyEditor",
    components: {OrderGeometryPropertySelector},
    props: {
        newProperties: {
            type: Array,
            default: () => ([])
        },
    },
    methods: {
        reset() {
            this.$refs.orderGeometryPropertySelector.reset();
        }
    }
}
</script>
