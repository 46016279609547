<template>
    <b-container fluid
                 class="details-container"
                 @click.stop="close"
    >
        <div
            class="col-sm-12 dynamic_width"
            @click.stop="cancelClick($event)"
        >
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-title editor-title">
                    <div>
                        <h2 class="form-title editor-title">
                            {{ $t('observations.edit_observations') }}
                        </h2>
                    </div>
                </div>
                <b-row class="nopads pr-2">
                    <div class="col-sm-8">
                        <open-contract-filter
                            :from="now"
                            :show-company="true"
                            :show-title="true"
                            :apply-padding="false"
                            :showOnlyMyContractsOption="false"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 pr-1">
                        <!-- order selection -->
                        <b-form-group
                            class="nopads"
                            label-for="order"
                        >
                            <span class="span-title">{{ $t('observations.orders') }}</span>
                            <b-form-select
                                id="order"
                                v-model="order"
                                size="sm"
                                class="sm-3"
                                :disabled="orders.length < 1"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('observations.select_order') }}
                                    </option>
                                </template>
                                <option
                                    v-for="option in orders"
                                    :key="option.id"
                                    :value="option.id"
                                >
                                    {{ getOrderStringWithInfo(option) }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </b-row>

                <div class="col-sm-12">
                    <span class="span-title">{{ $t('observations.closed_time') }}</span>
                    <div
                        v-if="closedTime"
                        class="col-sm-6 nopads"
                    >
                        <input
                            class="mr-2"
                            id="checkbox"
                            v-model="open"
                            type="checkbox"
                        >
                        <label for="checkbox">{{ $t('observations.open') }}</label>
                    </div>
                    <div class="col-sm-6 nopads">
                        <datetime
                            v-model="closedTime"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </div>
                <div class="col-sm-12 button-container pb-3">
                    <b-button
                        variant="danger"
                        class="result-button"
                        @click.stop="close"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        :disabled="loading"
                        class="result-button"
                        @click.stop="submitObservation"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>

    </b-container>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {EventBus} from '@/event-bus'
import {orderMixin} from "@/components/mixins/OrderMixin";
import {observationHelper} from "@/components/mixins/ObservationMixin";

export default {
    name: 'ObservationBulkEditor',
    components: {OpenContractFilter},
    mixins: [restApi, orderMixin, observationHelper],
    props: {
        observations: {
            type: Array,
            default: function () {
                return []
            }
        },
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            closedTime: null,
            open: false,
            customer: null,
            contractor: null,
            contract: null,
            contracts: [],
            orders: [],
            order: null
        }
    },
    computed: {
        now() {
            return new Date().toISOString();
        }
    },
    methods: {
        setCustomer: function (customer) {
            this.customer = customer
            this.fetchOrders()
        },
        setContractor: function (contractor) {
            this.contractor = contractor
        },
        setContract: function (contract) {
            this.contract = contract
            if (contract) {
                this.fetchOrders()
            }
        },
        fetchOrders: function () {
            this.order = null
            this.loading = true
            var params = {}
            if (this.customer) {
                params.customer = this.customer
            }
            if (this.contract) {
                params.contract = this.contract.id
            }
            params.open = 1 // Get orders only for
            // open or in progress contracts
            this.restFetchParams(this.orderUrl, params, this.handleOrders)
        },
        handleOrders: function (response) {
            this.orders = response.data
            this.loading = false
        },
        submitObservation: function () {
            this.loading = true
            var params = {}
            var obsIds = []
            this.observations.forEach(function (observation) {
                obsIds.push(observation.id)
            })
            params.observations = obsIds
            if (this.contract) {
                params.contract_id = this.contract.id
            }
            if (this.order) {
                params.order_id = this.order
            }
            if (!this.open && this.closedTime) {
                params.closed_time = this.closedTime
            } else {
                params.closed_time = 'open'
            }
            if(params.observations && params.observations.length > 0) {
                // Bulk update of many observations
                this.restAdd(this.observationsUrl, params, this.success, this.fail)
            }
        },
        success: function () {
            this.loading = false
            this.$emit('closeAndUpdate', this.observation)
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        close: function () {
            this.assignmentMode = false
            this.$emit('close')
        },
        cancelClick: function (e) {
            e.stopPropagation()
        }
    }
}
</script>
<style scoped>
</style>

