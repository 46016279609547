<template>
    <div class="col-sm-12 nopads">
        <div class="ml-3 mr-3 white-bg">
            <div class="col-sm-12 form-title editor-title">
                {{ editOld ? $t('subcontractors.edit') : $t('subcontractors.add_new') }}
            </div>
            <b-row v-if="applicationUpdate.can_approve" class="pl-2 mb-4">
                <application-form :application="application"/>
            </b-row>
            <!-- Editor -->
            <div v-else-if="applicationUpdate">
                <b-row>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('subcontractors.name') }}</span>
                        <b-form-input
                            v-model="applicationUpdate.subcontractor_name"
                            :placeholder="$t('subcontractors.name')"
                            size="sm"
                            :disabled="!!loading || applicationUpdate.can_approve"
                        />
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('subcontractors.business_id') }}</span>
                        <b-form-input
                            v-model="applicationUpdate.subcontractor_business_id"
                            :placeholder="$t('subcontractors.business_id')"
                            size="sm"
                            :disabled="!!loading || applicationUpdate.can_approve"
                        />
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('subcontractors.customer') }}</span>
                        <b-form-select
                            v-model="customer"
                            size="sm"
                            :disabled="!!loading || applicationUpdate.can_approve"
                        >
                            <option :value="null">
                                {{ $t('subcontractors.select_customer') }}
                            </option>
                            <option
                                v-for="customer in customers"
                                :key="customer.id"
                                :value="customer.id"
                            >
                                {{ customer.name }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>
                <b-row>
                        <span class="search-title pl-3">
                          {{ $t('subcontractors.time_range') }}
                        </span>
                </b-row>
                <b-row>
                    <div class="col-sm-4">
                        <b-form-input
                            id="start"
                            type="date"
                            :value="timestampToDateInput(applicationUpdate.time_from)"
                            size="sm"
                            @change="updateStartDate"
                            :disabled="applicationUpdate.can_approve"
                        />
                    </div>
                    -
                    <div class="col-sm-4">
                        <b-form-input
                            id="start"
                            type="date"
                            :value="timestampToDateInput(applicationUpdate.time_to)"
                            size="sm"
                            @change="updateEndDate"
                            :disabled="applicationUpdate.can_approve"
                        />
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-12">
                        <span class="search-title">
                          {{ $t('orders.info') }}
                        </span>
                        <b-form-textarea
                            id="info"
                            v-model="applicationUpdate.info"
                            :rows="3"
                            :max-rows="6"
                        />
                    </div>
                </b-row>
            </div>
            <div class="col-sm-12 button-container pb-2">
                <b-button
                    @click.stop="$emit('close')"
                    variant="secondary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    v-if="!applicationUpdate.can_approve"
                    @click.stop="onSubmit"
                    variant="success"
                    class="result-button"
                    size="sm"
                    :disabled="!!loading"
                >
                    {{ editOld ? $t('common.edit') : $t('common.send') }}
                </b-button>
                <b-button
                    v-if="applicationUpdate.can_approve"
                    @click.stop="handleApplication(2)"
                    variant="danger"
                    class="result-button"
                    size="sm"
                    :disabled="!!loading"
                >
                    {{ $t('work_permission.reject') }}
                </b-button>
                <b-button
                    v-if="applicationUpdate.can_approve"
                    @click.stop="handleApplication(1)"
                    variant="success"
                    class="result-button"
                    size="sm"
                    :disabled="!!loading"
                >
                    {{ $t('work_permission.accept') }}
                </b-button>
            </div>
        </div>
        <div
            v-if="!!loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {EventBus} from "@/event-bus";
import {timeUtils} from "@/components/mixins/TimeUtils";
import ApplicationForm from "@/components/subcontractors/ApplicationForm";

export default {
    name: "ApplicationEditor",
    components: {ApplicationForm},
    mixins: [restApi, timeUtils],
    props: {
        application: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            applicationUpdate: this.application,
            customer: null,
            customers: [],
            loading: 0
        }
    },
    mounted() {
        this.customer = this.applicationUpdate.customer ? this.applicationUpdate.customer.id : null
        this.fetchCustomers()
    },
    computed: {
        editOld: function () {
            return this.applicationUpdate && this.applicationUpdate.id > 0
        }
    },
    watch: {
        customer: function (value) {
            this.applicationUpdate.customer = value ? {id: value} : null
        }
    },
    methods: {
        fetchCustomers: function () {
            this.loading++
            this.vehicleOptions = []
            this.restFetch(this.customerUrl, this.handleCustomers)
        },
        handleCustomers: function (response) {
            if (response && response.data) {
                this.customers = response.data
            }
            this.loading--
        },
        onSubmit: function () {
            let validated = this.validateInput(this.applicationUpdate)
            if (validated !== undefined) {
                this.loading++
                if (!this.editOld) {
                    this.restAdd(this.subcontractorApplicationUrl, validated, this.addSuccess, this.fail)
                } else {
                    this.restUpdate(this.subcontractorApplicationUrl, validated, this.addSuccess, this.fail)
                }
            }
        },
        handleApplication: function (approval) {
            let validated = this.validateInput(this.applicationUpdate)
            if (validated !== undefined) {
                this.loading++
                validated.approval = approval
                this.restUpdate(this.subcontractorApplicationUrl, validated, this.addSuccess, this.fail)
            }
        },
        validateInput: function (application) {
            if (!application.customer || application.customer.id < 0) {
                EventBus.$emit('show-alert', this.$t('subcontractors.error_customer'))
                return undefined
            }
            if (!application.subcontractor_name || !application.subcontractor_business_id ) {
                EventBus.$emit('show-alert', this.$t('subcontractors.error_subcontractor_data'))
                return undefined
            }
            if (!application.time_to) {
                EventBus.$emit('show-alert', this.$t('subcontractors.error_time_range'))
                return undefined
            }
            if (!application.time_from) {
                EventBus.$emit('show-alert', this.$t('subcontractors.error_time_range'))
                return undefined
            }
            return application
        },
        addSuccess: function () {
            this.loading--
            this.$emit('update')
        },
        fail: function () {
            this.loading--
            if (!this.editOld) {
                EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
            } else {
                EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            }
        },
        selectContract: function (id, update = true) {
            if (!this.editOld || (this.editOld && update)) {
                this.noteUpdate.order.id = -1
            }
        },
        updateStartDate: function (event) {
            if (event) {
                this.applicationUpdate.time_from = (new Date(event)).toISOString()
            } else {
                this.applicationUpdate.time_from = undefined
            }
        },

        updateEndDate: function (event) {
            if (event) {
                this.applicationUpdate.time_to = (new Date(event)).toISOString()
            } else {
                this.applicationUpdate.time_to = undefined
            }
        },
    }
}
</script>
<style>
.strong {
    font-weight: bold;
}
</style>
