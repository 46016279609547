<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
            @click="showList"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">
                  {{ $t('pricing_model_conversion.title') }}
                </span>
            </div>
            <div
                v-else
                class="header"
                @click="showList"
            >
                <span class="pointer">
                  <i class="fa fa-arrow-left"/>
                </span>
                <span class="pointer">
                    {{ $t('common.back') }}
                </span>
                <font-awesome-icon
                    icon="trash"
                    class="print-icon right mt-2"
                    @click.stop="deleteConversionModel"
                />
            </div>
        </b-navbar>

        <div
            v-if="!editMode"
            class="ml-2 mr-2 data-list-item add"
            @click.stop="add"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
                <span class="item-title-add">
                  {{ $t('common.add_new') }}
                </span>
                <font-awesome-icon
                    icon="calendar-check"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <conversion-model-list
                v-if="!editMode"
                :is-admin="isAdmin"
                :codes="codes"
                :results="results"
                @edit="edit"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <div
            v-if="editMode"
            class="col-sm-12 nopads"
        >
            <transition name="fade">
                <conversion-model-editor
                    ref="conversion-editor"
                    :is-admin="isAdmin"
                    :codes="codes"
                    :conversion-models="results"
                    :model="conversionModel"
                    @close="showList"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {conversionModelHelper} from '@/components/mixins/ConversionMixin'
import ConversionModelList from './ConversionModelList'
import ConversionModelEditor from './ConversionModelEditor'

export default {
    name: 'ConversionModels',
    components: {ConversionModelList, ConversionModelEditor},
    mixins: [restApi, conversionModelHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            conversionModel: null,
            editMode: false,
            codes: [],
            loading: false,
            searchCompanyId: null,
            company: null
        }
    },
    mounted: function () {
        this.fetchModels()
        this.fetchForestCodes()
    },
    methods: {
        fetchForestCodes: function () {
            this.loading = true
            this.restFetch(this.forestCodeUrl, this.handleForestCodes)
        },
        handleForestCodes: function (response) {
            this.codes = response.data
            this.loading = false
        },
        fetchModels: function (companyId) {
            this.loading = true
            this.results = []
            this.searchCompanyId = companyId
            this.restFetch(this.pricingModeConversionUrl,  this.handleResponse)
        },
        deleteConversionModel: function () {
            this.$refs["conversion-editor"].confirmDeleteConversionModel()
        },
        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },
        add: function () {
            this.conversionModel = this.newConversionModel()
            this.editMode = true
        },
        edit: function (cModel) {
            this.conversionModel = cModel
            this.editMode = true
        },
        showList: function () {
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.showList()
            this.fetchModels(this.searchCompanyId)
        }
    }
}
</script>
