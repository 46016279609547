
export const stairHelper = {
    methods: {
        getStairRailings: function () {
            return [
                {name: this.$t('stair.select_railing_type'), value: null},
                {name: this.$t('stair.type_no_railing'), value: stairRailingTypes.NO_RAILING},
                {name: this.$t('stair.type_one_side'), value: stairRailingTypes.ONE_SIDE},
                {name: this.$t('stair.type_both_side'), value: stairRailingTypes.BOTH_SIDE}
            ]
        },
    }
}

export const stairRailingTypes = {
    NO_RAILING: 1,
    ONE_SIDE: 2,
    BOTH_SIDE: 3
}

