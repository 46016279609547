<script src="../mixins/RestApiMixin.js"></script>
<template>
    <div v-show="false">
        <div v-if="svgPath" ref="svgIcon">
            <div class="default-marker">
                <img v-if="greyScale" :src="svgPath" height="20px" style="filter: grayscale(100%)" alt="Ei näy kuvaa">
                <img v-else :src="svgPath" height="20px" alt="Ei näy kuvaa">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapSvgMarker',
    data() {
        return {
            svgPath: null,
            greyScale: false,
            leaflet: Boolean
        }
    },
    methods: {
        setSvgIcon: function (svg, greyscale) {
            this.svgPath = svg;
            this.greyScale = greyscale
        },
        getSvgIcon: function () {
            return this.$refs.svgIcon.innerHTML
        }
    }
}
</script>
