<template>
    <div class="work-day-records p-3">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2 class="form-title editor-title">
                    {{ $t('work_time.add_record') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <div
                    v-if="isAdmin && !userId"
                    class="nopads"
                >
                    <span class="span-title">{{ $t('users.company') }}</span>
                    <b-form-select
                        id="company"
                        v-model="company"
                        size="sm"
                        class="mb-1"
                        :disabled="!companies || companies.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('users.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </b-form-select>
                </div>

                <!-- Users -->
                <div
                    v-if="!userId"
                    class="nopads"
                >
                    <span class="span-title">{{ $t('work_time.user') }}</span>
                    <b-form-select
                        id="user"
                        v-model="user"
                        size="sm"
                        class="mb-1"
                        :disabled="!users || users.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('work_time.select_user') }}
                            </option>
                        </template>
                        <option
                            v-for="item in users"
                            :key="item.id"
                            :value="item.id"
                        >
                            {{ item.last_name }}, {{ item.first_name }}
                        </option>
                    </b-form-select>
                </div>
                <div v-else>
                    <h3>
                        {{ name }}
                    </h3>
                    <span class="span-title">{{ timeRange }}</span>
                </div>

                <b-row class="nopads">
                    <!-- Work time modes -->
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('work_time.mode') }}</span>
                        <b-form-select
                            id="mode"
                            v-model="workTimeMode"
                            size="sm"
                            class="mb-1"
                            :disabled="!workTimeModes || workTimeModes.length < 1"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('work_time.select_mode') }}
                                </option>
                            </template>
                            <option
                                v-for="item in workTimeModes"
                                :key="item.id"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-6 nopads pl-2">
                        <!-- Cost centers -->
                        <span class="span-title">{{ $t('work_time.cost_center') }}</span>
                        <b-form-select
                            id="costCenter"
                            v-model="costCenter"
                            size="sm"
                            class="mb-1"
                            :disabled="!costCenters || costCenters.length < 1"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('work_time.select_cost_center') }}
                                </option>
                            </template>
                            <option
                                v-for="item in costCenters"
                                :key="item.id"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>

                <!-- Start time -->
                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('work_time.start') }}</span>
                        <datetime
                            id="start"
                            v-model="start"
                            size="sm"
                            type="datetime"
                            class="datetime-routa"
                            format="dd.MM.yyyy HH:mm"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>

                    <!-- End time -->
                    <div class="col-sm-6 nopads pl-2">
                        <span class="span-title">{{ $t('work_time.end') }}</span>
                        <datetime
                            id="end"
                            v-model="end"
                            size="sm"
                            type="datetime"
                            class="datetime-routa"
                            format="dd.MM.yyyy HH:mm"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>

                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('work_shift.shift') }}</span>
                        <b-form-select
                            v-model="shift"
                            :options="shifts"
                            size="sm"
                            class="no-margin"
                        />
                    </div>
                    <!-- Vehicle -->
                    <div class="col-sm-6 nopads pl-2">
                        <span class="span-title">{{ $t('trip_list.vehicle') }}</span>
                        <b-form-select
                            id="mode"
                            v-model="vehicle"
                            size="sm"
                            class="mb-1"
                            :disabled="!vehicles || vehicles.length < 1"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('work_time.select_vehicle') }}
                                </option>
                            </template>
                            <option
                                v-for="item in vehicles"
                                :key="item.id"
                                :value="item.id"
                            >
                                {{ item.make }} {{ item.vehicle_model }} - {{ item.license_plate }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>

                <!-- Contract -->
                <open-order-filter
                    :to="start"
                    :from="end"
                    @loading="setLoading"
                    @loadingCompleted="loadingDone"
                    @orderSelected="setOrder"/>

                <!-- Note -->
                <div class="col-sm-12 nopads">
                    <span class="span-title">{{ $t('work_time.note') }}</span>
                    <b-form-textarea
                        id="info"
                        v-model="note"
                        :placeholder="$t('work_time.note')"
                        :rows="4"
                        :max-rows="6"
                    />
                </div>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('closeAndUpdate')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">{{ $t('common.cancel') }}</span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submit"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">{{ $t('common.save') }}</span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'
import OpenOrderFilter from "../order/OpenOrderFilter";

export default {
    name: 'WorkTimeAdd',
    mixins: [workManagementHelper, restApi],
    components: {OpenOrderFilter},
    props: {
        isAdmin: Boolean,
        name: {
            type: String,
            default() {
                return ''
            }
        },
        userId: {
            type: Number,
            default: -1
        },
        timeRange: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data: function () {
        return {
            loading: false,
            company: null,
            companies: [],
            workTimeMode: null,
            workTimeModes: [],
            user: this.userId,
            users: [],
            record: this.newWorkTimeRecord(),
            start: null,
            end: null,
            costCenter: null,
            costCenters: [],
            order: null,
            note: '',
            shift: null,
            shifts: [
                {value: null, text: this.$t('work_shift.select_shift')},
                {value: 1, text: this.$t('work_shift.day_shift')},
                {value: 2, text: this.$t('work_shift.morning_shift')},
                {value: 3, text: this.$t('work_shift.evening_shift')},
                {value: 4, text: this.$t('work_shift.night_shift')}
            ],
            vehicles: [],
            vehicle: null
        }
    },

    watch: {
        company: function () {
            if (this.company) {
                this.initCompanyData()
            }
        }
    },

    mounted: function () {
        if (this.isAdmin && !this.userId) {
            this.fetchCompanies()
        } else {
            this.initCompanyData()
        }
    },

    methods: {
        fetchCompanies: function () {
            if (this.isAdmin) {
                this.loading = true
                this.restFetch(this.companyUrl, this.handleCompanies)
            }
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        initCompanyData: function () {
            this.fetchUsers()
            this.fetchWorkTimeModes()
            this.fetchCostCenters()
            this.fetchVehicles()
        },

        fetchUsers: function () {
            if (this.isAdmin) {
                this.restFetchParams(this.userUrl, {company: this.company}, this.handleUsers)
            } else {
                this.restFetch(this.userUrl, this.handleUsers)
            }
        },

        handleUsers: function (response) {
            this.users = response.data
            this.loading = false
        },

        fetchWorkTimeModes: function () {
            if (this.isAdmin) {
                this.restFetchParams(this.workTimeModeUrl, {company: this.company}, this.handleWorkTimeModes)
            } else {
                this.restFetch(this.workTimeModeUrl, this.handleWorkTimeModes)
            }
        },

        handleWorkTimeModes: function (response) {
            this.workTimeModes = response.data
            this.loading = false
        },

        fetchCostCenters: function () {
            if (this.isAdmin) {
                this.restFetchParams(this.costCenterUrl, {company: this.company}, this.handleCostCenters)
            } else {
                this.restFetch(this.costCenterUrl, this.handleCostCenters)
            }
        },

        handleCostCenters: function (response) {
            this.costCenters = response.data
            this.loading = false
        },

        fetchVehicles: function () {
            this.loading = true
            this.vehicles = []
            this.vehicle = null
            if (this.isAdmin) {
                this.restFetchParams(this.vehicleUrl, {company: this.company}, this.handleVehicles)
            } else {
                this.restFetch(this.vehicleUrl, this.handleVehicles)
            }
        },

        handleVehicles: function (response) {
            if (response && response.data) {
                this.vehicles = response.data
            }
            this.loading = false
        },

        setValues: function () {
            let item = this.newWorkTimeRecord()
            if (this.workTimeMode) {
                item.mode = {id: this.workTimeMode}
            } else {
                EventBus.$emit('show-alert', this.$t('work_time.error_mode_missing'))
                return
            }
            if (this.user) {
                item.user = {id: this.user}
            } else {
                EventBus.$emit('show-alert', this.$t('work_time.error_user_missing'))
                return
            }
            if (this.start) {
                item.start_time = this.start
            } else {
                EventBus.$emit('show-alert', this.$t('work_time.error_start_missing'))
                return
            }
            if (this.end) {
                if(!this.isValidEndTime(this.start, this.end)) {
                    EventBus.$emit('show-alert', this.$t('work_time.invalid_end_time'))
                    return
                }
                item.end_time = this.end
            }
            if (this.costCenter) {
                item.cost_center = {id: this.costCenter}
            }
            if (this.order) {
                item.order = {id: this.order.id}
            }
            if (this.shift) {
                item.shift = this.shift
            }
            if (this.vehicle) {
                item.vehicle = {id: this.vehicle}
            }
            item.note = this.note
            if (this.isWorkType() && !this.costCenter) {
                // Give error if work type mode is selected without cost center!
                EventBus.$emit('show-alert', this.$t('work_time.error_cost_center_missing'))
                return
            }
            return item
        },

        submit: function () {
            let json = this.setValues(this.record)
            if (json !== undefined) {
                this.loading = true
                this.restAdd(this.workTimeUserUrl, json, this.success, this.fail)
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function (response) {
            this.loading = false
            if (response.status === 406) {
                EventBus.$emit('show-alert', this.$t('work_time.error_overlapping'))
            } else {
                EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            }
        },

        isValidEndTime: function (startTime, endTime) {
            return new Date(startTime).getTime() < new Date(endTime).getTime()
        },

        isWorkType: function () {
            if (this.workTimeMode) {
                let foundMode = this.workTimeModes.find(function (item) {
                    return item.id === this.workTimeMode
                }, this)
                return foundMode && foundMode.work_type && foundMode.work_type === true
            }
            return false
        },

        setOrder: function (order) {
            this.order = order
        },

        setLoading: function () {
            this.loading = true
        },

        loadingDone: function () {
            this.loading = false
        }
    }
}
</script>
