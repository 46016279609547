<template>
    <div class="col-sm-12 list-content result-table nopads">
        <b-table
            striped
            hover
            small
            :items="selectorData"
            :fields="fields"
            @row-clicked="openModal">

            <template #cell(vastaa)="harjaType">
                <div class="mb-2 button-column">
                    <b-button
                        variant="success"
                        size="sm"
                        class="result-button"
                        @click.stop="openHarjaSubmitModal('vastaanotto', harjaType)"
                    >
                        {{ $t('harja.receive') }}
                    </b-button>
                    <b-button
                        variant="secondary"
                        size="sm"
                        class="result-button"
                        @click.stop="openHarjaSubmitModal('aloitus', harjaType)"
                    >
                        {{ $t('harja.start') }}
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="result-button"
                        @click.stop="openHarjaSubmitModal('muutos', harjaType)"
                    >
                        {{ $t('harja.change') }}
                    </b-button>
                    <b-button
                        variant="info"
                        size="sm"
                        class="result-button"
                        @click.stop="openHarjaSubmitModal('lopetus', harjaType)"
                    >
                        {{ $t('harja.end') }}
                    </b-button>
                    <b-button
                        variant="dark"
                        size="sm"
                        class="result-button"
                        @click.stop="openHarjaSubmitModal('vastaus', harjaType)"
                    >
                        {{ $t('harja.answer') }}
                    </b-button>
                </div>
            </template>
        </b-table>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>

import {mapHelper} from "@/components/mixins/MapMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'harjaMessagesList',
    mixins: [mapHelper,restApi, timeUtils],
    components: {},
    props: {
        data: {
            type: Array,
            forms: [],
            return: []
        },
    },
    data() {
        return {
            loading: false,
            selectorData: null,
            modalData: null,
        }
    },
    computed: {
        fields() {
            return [
                {key: 'ilmoitustyyppi', label: this.$t('harja.notification_type'), sortable: true},
                {key: 'tila', label: this.$t('area_report.status'), sortable: true},
                {key: 'ilmoitettuFormated', label: this.$t('harja.notified'), sortable: true},
                {key: 'paikankuvaus', label: this.$t('harja.site_description'), sortable: true},
                {key: 'vastaa', label: this.$t('harja.receipt'), sortable: false},
            ]
        },
    },
    watch: {
      data: function (val) {
          this.selectorData = []
          val.forEach(dat => {
              dat.ilmoitus.ilmoitettuFormated = this.getDate(dat.ilmoitus.ilmoitettu)
              this.selectorData.push(
                  dat.ilmoitus)
          })
      }
    },
    methods: {
        openHarjaSubmitModal: function (type, data) {
            let id = data.item.ilmoitusid
            this.$emit('openHarjaModal', type, id)
        },
        close: function () {
            this.$emit('close')
        },
        openModal: function (data) {
            this.$emit('openModal', data)
        },
    }
}
</script>

<style scoped>
.button-column {
    min-width: 26em;
}
</style>
