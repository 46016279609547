<template>
    <div class="search-form" style="border: none">
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="start"
                    >
                        <span class="span-title">{{ $t('work_time.start') }}</span>
                        <b-form-input
                            id="start"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group
                        class="nopads"
                        label-for="end"
                    >
                        <span class="span-title">{{ $t('work_time.end') }}</span>
                        <b-form-input
                            id="end"
                            v-model="toDate"
                            type="date"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <!-- User selection multiselect -->
            <b-row class="nopads">
                <div class="col-sm-8">
                    <span class="search-title">{{ $t('work_time.person') }}</span>
                    <multiselect
                        v-model="selectedUsers"
                        class="multiselect_routa"
                        :placeholder="$t('work_time.select_person')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="false"
                        :multiple="true"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="users"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </b-row>


            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import Multiselect from 'vue-multiselect'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'WorkTimeSearch',
    components: {Multiselect},
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            loading: false,
            users: [],
            selectedUsers: [],
            fromDate: null,
            toDate: null
        }
    },
    mounted: function () {
        this.fetchUsers()
        if (!this.fromDate) {
            this.fromDate = this.timestampToDateInput(this.getMonthStartAsDate().getTime())
        }
    },
    methods: {
        fetchUsers: function () {
            this.users = []
            this.restFetch(this.userUrl, this.handleUserResponse)
        },

        handleUserResponse: function (response) {
            if (response && response.data) {
                response.data.forEach(function (user) {
                    this.users.push({
                        value: user.id,
                        name: user.last_name + ', ' + user.first_name,
                        company: user.company ? user.company.name : '',
                        email: user.email
                    })
                }, this)
            }
            this.loading = false
        },

        onSubmit: function () {
            this.$emit('search', this.getSearchParams())
        },

        onReset: function () {
            this.selectedUsers = []
            this.fromDate = null
            this.toDate = null
        },

        getSearchParams() {
            var params = {}
            if (this.selectedUsers.length > 0) {
                let results = []
                this.selectedUsers.forEach(function (item) {
                    results.push(item.value)
                }, this)
                params.users = results
            }
            if (this.fromDate !== null && this.fromDate.trim()) {
                params.from = this.localTimeToUtcTime(this.fromDate).toISOString()
            }
            if (this.toDate !== null && this.toDate.trim()) {
                params.to = this.getEndOfTheDay(this.toDate).toISOString()
            }
            return params
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
