<template>
    <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            @submit.prevent="onSubmit"
        >
            <b-row class="nopads">
                <div class="col-sm-4">
                    <b-form-group
                        class="title"
                        label-for="road_num"
                    >
                        <span class="span-title">{{ $t('bridge.road_num') }}</span>
                        <b-form-input
                            id="road_num"
                            v-model="roadNumber"
                            type="number"
                            size="sm"
                        />
                    </b-form-group>
                </div>

                <div class="col-sm-2">
                    <b-form-group
                        class="title"
                        label-for="roadSecFrom"
                    >
                        <span class="span-title">{{ $t('trip_list.search_with_road_sec') }}</span>
                        <b-form-input
                            id="roadSecFrom"
                            v-model="roadSecFrom"
                            :placeholder="$t('trip_list.road_sec_from')"
                            type="number"
                            size="sm"
                        />
                    </b-form-group>
                </div>

                <div class="col-sm-2">
                    <b-form-group
                        class="title"
                        label-for="roadSecTo"
                    >
                        <span class="span-title">{{ $t('trip_list.search_with_road_sec') }}</span>
                        <b-form-input
                            id="roadSecTo"
                            v-model="roadSecTo"
                            :placeholder="$t('trip_list.road_sec_to')"
                            type="number"
                            size="sm"
                        />
                    </b-form-group>
                </div>

                <div class="col-sm-4">
                    <b-form-group
                        class="title"
                        label-for="road_num"
                    >
                        <span class="span-title">{{ $t('bridge.bridge_num') }}</span>
                        <b-form-input
                            id="road_num"
                            v-model="bridgeNumber"
                            type="number"
                            size="sm"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    variant="primary"
                    class="result-button"
                    size="sm"
                    @click.stop="fetchNearest"
                >
                    {{ $t('bridge.search_nearest') }}
                </b-button>
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>

import {EventBus} from "@/event-bus";

export default {
    name: 'bridge-search',
    data: function () {
        return {
            roadNumber: null,
            roadSecFrom: null,
            roadSecTo: null,
            bridgeNumber: null,
            userPosition: null
        }
    },
    created() {
        this.initPosition()
        this.watchUserPosition()
    },
    methods: {
        onSubmit: function () {
            let params = {}
            if (!this.roadNumber) {
                EventBus.$emit('show-alert', this.$t('bridge.input_road_num'))
                return
            } else {
                params.roadnum = this.roadNumber
            }
            if (this.roadSecFrom) {
                params.road_sec_from = this.roadSecFrom
            }
            if (this.roadSecTo) {
                params.road_sec_to = this.roadSecTo
            }
            if (this.bridgeNumber) {
                params.bridgenum = this.bridgeNumber
            }
            this.$emit('search', params)
        },
        fetchNearest: function () {
            if (this.userPosition) {
                this.$emit('search', {
                    lat: this.userPosition.coords.latitude,
                    lon: this.userPosition.coords.longitude
                })
            } else {
                EventBus.$emit('show-alert', this.$t('bridge.fetching_location'))
            }
        },
        initPosition: function () {
            navigator.geolocation.getCurrentPosition(pos => this.setPosition(pos))
        },
        setPosition: function (position) {
            this.userPosition = position
        },
        watchUserPosition: function () {
            if (navigator.geolocation) {
                this.watchID = navigator.geolocation.watchPosition(position => {
                    this.setPosition(position)
                }, () => {
                }, {timeout: 30000, enableHighAccuracy: true})
            }
        }
    }
}
</script>
