<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pt-2">
        <div class="col-sm-12 nopads">
            <!-- Search criteria -->
            <keep-alive>
                <transition
                    v-if="!editMode"
                    name="fade">
                    <work-time-search
                        ref="searchView"
                        @search="searchWithParams"/>
                </transition>
            </keep-alive>

            <div
                v-if="!editMode"
                class="button-container col-sm-12 trip-buttons pr-4 pl-4"
            >
                <b-button
                    v-if="results && results.length > 0"
                    variant="outline-success"
                    size="sm"
                    class="result-button"
                    @click="exportExcel"
                >
                    {{ $t('common.download_excel') }}
                </b-button>
                <b-button
                    variant="success"
                    size="sm"
                    class="result-button"
                    @click="addItem"
                >
                    {{ $t('common.add_new') }}
                </b-button>
            </div>

            <b-modal
                v-model="editMode"
                body-class="fill-modal"
                size="lg"
                :hide-header="true"
                :hide-footer="true"
            >
                <daily-allowance-editor
                    :daily-allowance="result"
                    :is-admin="isAdmin"
                    :is-observer="isObserver"
                    style="width: 100% !important;"
                    @closeAndUpdate="search"
                    @close="showList"
                />
            </b-modal>

            <div
                v-if="!editMode && results"
                id="accordion"
                class="col-sm-12 list-container"
            >
                <div class=" list-content result-table">
                    <b-table
                        small
                        style="cursor:pointer;"
                        :items="results"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        @row-clicked="editItem"
                    >
                        <template
                            v-slot:cell(start_time)="row"
                        >
                            <span>{{ toLocalTime(row.value) }}</span>
                        </template>
                        <template
                            v-slot:cell(end_time)="row"
                        >
                            <span>{{ toLocalTime(row.value) }}</span>
                        </template>
                        <template
                            v-slot:cell(user)="row"
                        >
                            <span>{{ row.value.first_name + ' ' + row.value.last_name }}</span>
                        </template>
                        <template
                            v-slot:cell(approver)="row"
                        >
                            <span v-if="row.value">{{ row.value.first_name + ' ' + row.value.last_name }}</span>
                        </template>
                        <template
                            v-slot:cell(type)="row"
                        >
                            <span>{{ getTypeString(row.value) }}</span>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import WorkTimeSearch from '../worktime/WorkTimeSearch'
import {timeUtils} from '../mixins/TimeUtils'
import {allowanceHelper} from '../mixins/AllowanceMixin'
import DailyAllowanceEditor from './DailyAllowanceEditor'
import {downloadHelper} from '../mixins/DownloadMixin'

export default {
    name: 'DailyAllowances',
    components: {WorkTimeSearch, DailyAllowanceEditor},
    mixins: [restApi, timeUtils, allowanceHelper, downloadHelper],
    props: {
        isAdmin: Boolean,
        isObserver: Boolean
    },
    data: function () {
        return {
            loading: false,
            searchParams: [],
            editMode: false,
            results: [],
            result: null,
            sortBy: 'start_time',
            sortDesc: false,
            fields: [
                {key: 'user', label: this.$t('daily_allowance.user'), sortable: true},
                {key: 'start_time', label: this.$t('work_report.start_time'), sortable: true},
                {key: 'start_place', label: this.$t('work_time.start_location'), sortable: true},
                {key: 'end_time', label: this.$t('work_report.end_time'), sortable: true},
                {key: 'end_place', label: this.$t('work_time.end_location'), sortable: true},
                {key: 'full_allowances', label: this.$t('daily_allowance.full'), sortable: true},
                {key: 'partial_allowances', label: this.$t('daily_allowance.partial'), sortable: true},
                {key: 'info', label: this.$t('work_report.info'), sortable: false},
                {key: 'approver', label: this.$t('daily_allowance.approver'), sortable: true}
            ]
        }
    },

    mounted() {
        this.searchParams = this.$refs.searchView.getSearchParams()
    },

    methods: {
        search() {
            this.results = []
            this.loading = true
            this.restFetchParams(this.dailyAllowanceUrl, this.searchParams, this.handleResponse)
        },

        searchWithParams: function (params) {
            this.searchParams = params
            this.search()
        },
        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
            this.showList()
        },
        showList: function () {
            this.editMode = false
        },
        editItem: function (item) {
            this.result = item
            this.editMode = true
        },
        addItem: function () {
            this.result = null
            this.editMode = true
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.dailyAllowanceReportUrl, this.getCommonReportParams(this.searchParams), this.fileDownloadSuccess, this.fileDownloadFail)
        },
        fileDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('daily_allowance.report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        }
    }
}
</script>
