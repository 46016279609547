<template>
    <b-table
        :items="items"
        :fields="fields"
        :small="true"
        :bordered="true"
    />
</template>

<script>
import {Roles, userHelper} from '../mixins/UserMixin'

export default {
    name: 'LicenseReportTable',
    mixins: [userHelper],
    props: {
        users: {
            type: Array,
            default: () => ([]),
        },
    },
    computed: {
        fields() {
            return [
                {key: 'username', label: this.$t('users.user_name'), sortable: true },
                {key: 'name', label: this.$t('users.name'), sortable: true},
                {key: 'email', label: this.$t('users.email'), sortable: true},
                {key: 'licenseHolder', label: this.$t('users.license_holder_title'), sortable: true},
                {key: 'rights', label: this.$t('users.rights'), sortable: true},
            ]
        },
        items() {
            return this.users.map((user) => ({
                name: `${user.last_name}, ${user.first_name}`,
                email: user.email,
                licenseHolder: user.license_holder,
                rights: this.getRoleString(user),
                username: user.username
            })).sort((a, b) => {
                return a.name < b.name ? -1 : b.name < a.name ? 1 : 0
            })
        }
    },
    methods: {
        getRoleString: function (user) {
            let result = ''
            if (user.superuser) {
                result += this.getRoleName(Roles.COMPANY_SUPER_USER)
            }
            if (user.observer) {
                if (result.length > 0) {
                    result += ', '
                }
                result += this.getRoleName(Roles.OBSERVER)
            }
            if (user.worker) {
                if (result.length > 0) {
                    result += ', '
                }
                result += this.getRoleName(Roles.WORKER)
            }
            return result;
        }
    }
}
</script>
