<template>
    <b-container fluid
                 class="details-container"
    >
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-data">
                <h3>{{ $t('map.select_editable_layer') }}</h3>
                <ul class="suggestions-list">
                    <li v-for="item in options" :key="item" @click="$emit('onSelectionSelected', item)">
                        {{ mapItemText(item) }}
                    </li>
                </ul>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="primary"
                        class="form-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.back') }}
                    </b-button>
                </div>
            </div>
        </b-form>
    </b-container>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";

export default {
    name: "MapSelectionEditor",
    mixins: [mapHelper],
    props: {
        options: {
            type: Array,
        },
    },
    methods: {
        mapItemText(item) {
            switch (item) {
                case this.OBSERVATION: return this.$t('menu.observations')
                case this.WORK_ASSIGNMENT: return this.$t('map_tooltips.work_assignments')
                case this.TRAFFIC_SIGN: return this.$t('map_tooltips.traffic_signs')
                case this.STREET_LIGHT: return this.$t('menu.street_lights')
                case this.SEWERS: return this.$t('storm_sewers.sewers')
                case this.DRAINS: return this.$t('storm_sewers.drains')
                case this.SEWERS_AND_DRAINS: return this.$t('storm_sewers.sewers_and_drains')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
ul.suggestions-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0 0;
    max-height: 400px;
    overflow: auto;

    li {
        padding: 1em 0.5em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        transition: background-color 0.25s ease-out;
        cursor: pointer;

    &:last-child {
         border-bottom: none;
     }
    &:only-child {
         border-top: 1px solid rgba(0, 0, 0, 0.1);
         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
     }
    &:hover {
         background-color: rgba(0, 0, 0, 0.1);
     }
    }
}
</style>
