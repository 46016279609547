<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <table class="table table-sm"
               v-if="culvert">
            <tbody>
            <tr>
                <td class="item-detail-title">
                    {{ $t('culvert.digiroad_id') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.digiroad_id">{{ culvert.digiroad_id }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.type') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.type">{{ culvert.type }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('culvert.material') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.material">{{ culvert.material }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('culvert.size') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.size">{{ culvert.size }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.city') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.city">{{ culvert.city }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.road_num') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.road_num">{{ culvert.road_num }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.sec_num') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.sec_num">{{ culvert.sec_num }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.start_distance') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.start_distance">{{ culvert.start_distance }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('bridge.length') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.length">{{ culvert.length }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('culvert.owner') }}
                </td>
                <td class="item-detail-text">
                    <span v-if="culvert.owner">{{ culvert.owner }}</span>
                    <span v-else>-</span>
                </td>
            </tr>
            </tbody>
        </table>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>

</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: 'TargetInfoCulvert',
    mixins: [restApi],
    props: {
        culvertId: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            culvert: null,
            loading: false
        }
    },
    created() {
        this.fetchCulvertInfo()
    },
    methods: {
        fetchCulvertInfo: function () {
            this.loading = true
            this.restFetchParams(this.culvertUrl + "/" + this.culvertId, this.params, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            if (response) {
                this.culvert = response.data
            }
        }
    }
}
</script>
