export const areaHelper = {
    data: function () {
        return {
            resultSkip: 1,
            resultOk: 2,
            resultDefect: 3,
            resultBan: 4
        }
    },

    methods: {
        newEquipment: function () {
            return {
                id: null,
                area: null,
                name: '',
                type: null,
                description: '',
                manufacturer: '',
                is_inactive: false,
                location: {x: null, y: null}
            }
        },

        jsonToEquipment: function (equipment) {
            return JSON.parse(JSON.stringify(equipment))
        },

        getEquipmentType: function (type) {
            let equipmentType
            switch (type) {
                case 1:
                    equipmentType = this.$t('equipment.type_equipment')
                    break
                case 2:
                    equipmentType = this.$t('equipment.type_instrument')
                    break
                case 3:
                    equipmentType = this.$t('equipment.type_device')
                    break
                case 4:
                    equipmentType = this.$t('equipment.type_other')
                    break
                default:
                    break
            }
            return equipmentType
        },
        getPhotoUrl: function (equipment) {
            if (equipment !== null && equipment.photos && equipment.photos.length > 0) {
                var photoId = equipment.photos[equipment.photos.length - 1].id
                if (photoId !== null) {
                    return this.areaEquipmentPhotoUrl + equipment.id + '/' + photoId
                }
            }
            return null
        },

        newDefect: function () {
            return {
                id: -1,
                area: {
                    id: -1
                },
                reporter: {
                    id: -1
                },
                description: '',
                status: -1,
                created_date: '',
                fixed_date: '',
                fixer: {
                    id: -1
                },
                area_equipment: null
            }
        }
    }
}
