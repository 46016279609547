<template>
    <div class="col-sm-12 list-container">
        <div class="col-sm-12 list-content result-table mb-3  p-1">
            <b-table
                size="sm"
                class="mt-3"
                show-empty
                :small="true"
                :responsive="true"
                :fields="fields"
                :items="tableItems"
                style="cursor: pointer"
                @row-clicked="emitEdit"
            >
                <template slot="empty">
                    <div class="text-center my-2">
                        {{ $t('common.no_results') }}
                    </div>
                </template>
                <template v-slot:cell(date)="row">
                    {{ toLocalTime(row.value) }}
                </template>
                <template v-slot:cell(work_distance)="row">
                    {{ row.value && row.value > 0 ? row.value.toFixed(2) : '' }}
                </template>
                <template v-slot:cell(resettle_distance)="row">
                    {{ row.value && row.value > 0 ? row.value.toFixed(2) : '' }}
                </template>
                <template v-slot:cell(status)="row">
                  <span class="nopads"
                        v-bind:class="{
                          transport: inDelivery(row.value),
                          unloading: unloading(row.value)
                        }">
                    {{ getStatusString(row.value) }}
                  </span>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: "ApplicationList",
    mixins: [timeUtils],
    props: {
        applications: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            tableItems: [],
            fields: [
                {key: 'subcontractor_name', label: this.$t('subcontractors.name'), sortable: true, sortByFormatted: true},
                {key: 'subcontractor_business_id', label: this.$t('subcontractors.business_id'), sortable: true, sortByFormatted: true},
                {key: 'customer', label: this.$t('subcontractors.customer'), sortable: true, sortByFormatted: true},
                {key: 'contractor', label: this.$t('subcontractors.contractor'), sortable: true, sortByFormatted: true},
                {key: 'period', label: this.$t('subcontractors.period'), sortable: false, sortByFormatted: true},
                {key: 'approved', label: this.$t('subcontractors.approval'), sortable: true, sortByFormatted: true},
            ]
        }
    },
    watch: {
      applications() {
          this.initTableItems()
      }
    },
    methods: {
        initTableItems() {
            this.tableItems = []
            this.applications.forEach(app => {
                this.tableItems.push({
                    id: app.id,
                    subcontractor_name: app.subcontractor_name,
                    subcontractor_business_id: app.subcontractor_business_id,
                    customer: app.customer.name,
                    contractor: app.contractor.name,
                    period: this.getShortDateString(app.time_from) + ' - ' + this.getShortDateString(app.time_to),
                    approved: app.approval === 1 ? this.$t('work_permission.accepted') : app.approval === 2 ? this.$t('work_permission.rejected') : this.$t('subcontractors.unhandled'),
                    _rowVariant: this.hasEnded(app.time_to) ? 'warning' : app.approval === 1 ? 'success' : app.approval === 2 ? 'danger' :  null
                })
            })
        },
        emitEdit: function (application) {
            let item = this.applications.find(item => item.id === application.id)
            if (item) {
                this.$emit('edit', item)
            }
        },
        hasEnded (time) {
            let compare = new Date(this.getEndOfTheDay(time));
            let now = new Date();
            return compare.getTime() < now.getTime()
        }
    }
}
</script>
<style scoped lang="scss">
.approved {
    color: #ffffff;
    background: #1F7F35;
    padding: .2em;
}

.unhandled {
    color: #ffffff;
    background: #1E88E5;
    padding: .2em;
}

.rejected {
    color: #ffffff;
    background: #9e0c0c;
    padding: .2em;
}

</style>
