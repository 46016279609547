<template>
    <div>
        <base-modal @cancel="$emit('close')">
            <div class="editor-form form-data">
                <button
                    class="button--nostyle"
                    aria-label="close modal"
                    @click="$emit('close')"
                    style="margin-left: auto; display: block; font-size: .9em"
                >
                    {{ $t('common.close') }} <i class="fa fa-times" />
                </button>
                <b-form-group
                    :description="$t('map.address_example')"
                    :label="$t('map.search_by_address')"
                    label-for="address-search"
                >
                    <b-form-input v-model="searchText" id="address-search" debounce="400" />
                </b-form-group>
                <hr/>
                    <b-row class="nopads">
                        <b-col class="nopads pl-1 pr-1">
                            <span class="span-title"> {{ $t('map.road_number') }} </span>
                        </b-col>
                        <b-col class="nopads pl-1 pr-1">
                            <span class="span-title"> {{ $t('map.road_section') }} </span>
                        </b-col>
                        <b-col class="nopads pl-1 pr-1">
                            <span class="span-title"> {{ $t('map.distance') }} </span>
                        </b-col>
                    </b-row>
                    <b-row class="nopads">
                        <b-col class="nopads pl-1 pr-1">
                            <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="roadNumber" id="road-number" debounce="400"/>
                        </b-col>
                        <b-col class="nopads pl-1 pr-1">
                            <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="sectionNumber" id="road-section" debounce="400"/>
                        </b-col>
                        <b-col class="nopads pl-1 pr-1">
                            <b-form-input size="sm" class="pb-2" type="number" min="0" step="1" v-model="distance" id="distance" debounce="400"/>
                        </b-col>
                    </b-row>
                <hr/>
                <div class="button-container">
                    <b-button
                        variant="primary"
                        class="result-button"
                        size="sm"
                        @click.stop="search"
                        :disabled="loading"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                </div>
                <ul class="suggestions-list">
                    <li v-for="item in suggestions" :key="item.id" @click="selectAddress(item)">
                        {{ item.text }}
                    </li>
                </ul>
            </div>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </base-modal>
    </div>
</template>

<script>
import BaseModal from '../BaseModal'
import { hereService } from '../mixins/HereServiceMixin'
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: 'address-search-wizard',
    mixins: [hereService, restApi],
    components: { BaseModal },
    props: {
        coordinates: {
            type: Object,
            default() {
                return null
            },
        },
        map: {
            type: Object,
            default: null,
        },
        getCoordinate: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            searchText: '',
            roadNumber: null,
            sectionNumber: null,
            distance: null,
            suggestions: [],
        }
    },

    methods: {
        search() {
            // Street name
            if (this.searchText && this.searchText.length > 1) {
                this.searchByAddress(
                    this.searchText,
                    (data) => {
                        if (data.length === 0) return (this.suggestions = [])
                        this.suggestions = data
                    },
                    () => {}
                )
            }
            // Number - section - distance
            if (this.roadNumber && this.sectionNumber && this.distance) {
                this.loading = true
                this.restFetchParams(this.roadGeometryPointUrl, {
                        road: this.roadNumber,
                        section: this.sectionNumber,
                        distance: this.distance
                    },
                    this.handleRoadSearch)
            }
        },

        handleRoadSearch(response) {
            this.loading = false
            if (response && response.data) {
                this.suggestions = [{
                    id: -1,
                    text: this.roadNumber + ' / ' + this.sectionNumber + ', ' + this.distance + ' m',
                    lat: response.data.y,
                    lon: response.data.x
                }]
            }
        },

        selectAddress(item) {
            this.map.zoomToPosition(item.lat, item.lon, 15)
            if(this.getCoordinate) {
                this.$emit('pointFetched', {lat: item.lat, lon: item.lon})
            } else {
                this.map.showMapMarker(item.id, this.OTHER, item.lat, item.lon, this.map.getMarkerStore().getSolidCircleIcon())
                this.$emit('close')
            }
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../styles/modal.css';
ul.suggestions-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0 0;
    max-height: 400px;
    overflow: auto;

    li {
        padding: 1em 0.5em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        transition: background-color 0.25s ease-out;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }
        &:only-child {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}
</style>
