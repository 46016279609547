<template>
    <base-modal style="z-index: 50">
        <div class="col-12">
            <h3>{{ $t('street_lights.edit') }}</h3>
            <span class="hint-text">{{ $t('street_lights.bulk_edit_hint')}}</span>
        </div>

        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div class="col-sm-12">
            <b-form v-if="light" class="col-sm-12 editor-form">
                <h4 class="form-header">{{ $t('street_lights.basic_info')}}</h4>
                <!--
                <b-row class="nopads">
                    <div class="col-sm-6 p-1">
                        <span class="span-title">{{ $t('street_lights.station') }}</span>
                        <b-form-select id="lightStation" size="sm" v-model="selectedStation" :options="stations">
                            <template #first>
                                <option :value="null">{{ $t('street_lights.select_station') }}</option>
                            </template>
                        </b-form-select>
                    </div>
                </b-row>
                -->
                <b-row class="nopads">
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.status') }}</span>
                        <b-form-select id="lightStatus" size="sm" v-model="light.status" :options="statusOptions">
                            <template #first>
                                <option :value="null">{{ $t('street_lights.select_status') }}</option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.lighting_class') }}</span>
                        <b-form-select id="lightClass"  size="sm" v-model="light.lighting_class" :options="lightingClassOptions"></b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.construction_year') }}</span>
                        <b-form-input
                            id="lightYear"
                            v-model="light.construction_year"
                            type="number"
                            min="0"
                            size="sm"/>
                    </div>
                </b-row>
                <hr/>
                <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.contract')}}</h4>
                <span class="tip-light">{{ $t('power_stations.contract_tip')}}</span>
                <open-contract-filter
                    :to="toDate"
                    :from="fromDate"
                    :contract="preselectedContract"
                    :show-company="false"
                    :show-title="false"
                    @loading="loading=true"
                    @loadingCompleted="loading=false"
                    @contractSelected="setContract"
                    style="margin-left: -2px; margin-right: -3px;"/>
                <hr/>
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('street_lights.light_info')}}</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : lightInfoVisible}"
                            v-b-toggle.lightSection
                            @click.stop="lightInfoVisible = !lightInfoVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="lightSection">
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.light_type') }}</span>
                            <b-form-select id="lightType" size="sm" v-model="light.light_type" :options="lightTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.power_rating') }}</span>
                            <b-form-input
                                id="powerRating"
                                v-model="light.power_rating"
                                type="number"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.light_manufacturer') }}</span>
                            <b-form-input
                                id="lightManufacturer"
                                v-model="light.light_manufacturer"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.model') }}</span>
                            <b-form-input
                                id="lightModel"
                                v-model="light.model"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.installation') }}</span>
                            <b-form-select id="lightInstallation" size="sm" v-model="light.installation" :options="installOptions"></b-form-select>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.optics') }}</span>
                            <b-form-input
                                id="optics"
                                v-model="light.optics"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.dimming_profile') }}</span>
                            <b-form-checkbox
                                id="dimmingProfile"
                                size="lg"
                                v-model="light.dimming_profile">
                            </b-form-checkbox>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.bulb_type') }}</span>
                            <b-form-select id="lightLampType" size="sm" v-model="light.bulb_type" :options="bulbTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.bulb_count') }}</span>
                            <b-form-input
                                id="bulbCount"
                                v-model="light.bulb_count"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.bulb_manufacturer') }}</span>
                            <b-form-input
                                id="bulbManufacturer"
                                v-model="light.bulb_manufacturer"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.guarantee_basis') }}</span>
                            <b-form-select id="guaranteeBasis" size="sm" v-model="light.guarantee_basis" :options="guaranteeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.guarantee_expiration') }}</span>
                            <b-form-input
                                id="guaranteeExp"
                                v-model="light.guarantee_expiration"
                                type="date"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.guarantor') }}</span>
                            <b-form-input
                                id="guarantor"
                                v-model="light.guarantor"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                </b-collapse>
                <hr/>
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('street_lights.pole_info')}}</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : poleVisible}"
                            v-b-toggle.poleSection
                            @click.stop="poleVisible = !poleVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="poleSection">
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.pole_type') }}</span>
                            <b-form-select id="poleType" size="sm" v-model="light.pole_type" :options="poleTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.pole_manufacturer') }}</span>
                            <b-form-input
                                id="poleManufacturer"
                                v-model="light.pole_manufacturer"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.installation_height') }}</span>
                            <b-form-input
                                id="installHght"
                                v-model="light.installation_height"
                                type="number"
                                min="0"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.stand') }}</span>
                            <b-form-input
                                id="stand"
                                v-model="light.stand"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.supporting_structure') }}</span>
                            <b-form-select id="supportStruct" size="sm" v-model="light.supporting_structure" :options="poleSupportOptions"></b-form-select>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.arm_type') }}</span>
                            <b-form-select id="armType" size="sm" v-model="light.arm_type" :options="armTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.arm_length') }}</span>
                            <b-form-input
                                id="armLength"
                                v-model="light.arm_length"
                                type="number"
                                min="0"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.cabling') }}</span>
                            <b-form-select id="lightCabling" size="sm" v-model="light.cabling" :options="cablingOptions"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('street_lights.earthing') }}</span>
                            <b-form-select id="lightCabling" size="sm" v-model="light.earthing" :options="earthingOptions"/>
                        </div>
                    </b-row>
                </b-collapse>
                <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                    <b-button
                        variant="secondary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        :disabled="loading||this.light===null"
                        @click.stop="updateLights"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </base-modal>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import BaseModal from '../BaseModal'
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {streetLightHelper} from "@/components/mixins/StreetLightMixin";

export default {
    name: 'StreetLightBulkEditor',
    components: {OpenContractFilter, BaseModal},
    mixins: [restApi, timeUtils, streetLightHelper],
    props: {
        selectedContract: {
            type: Object,
            default: null
        },
        coordinate: {
            type: Object,
            default: null
        },
        streetLights: {
            type: Array,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            light: null,  // A single street light object representing the values to be set for given ids
            contract: null,
            fullScreenPhotoData: null,
            photosVisible: true,
            lightInfoVisible: false,
            poleVisible: false,
            maintenanceVisible: false,
            photos: new Array(5),
            selectedStation: null,
            stations: [],
            lightsDetails: [],
            variant: 'danger',
            typeOptions: [
                {text: 'Type 1', value: 1},
                {text: 'Type 2', value: 2},
                {text: 'Type 4', value: 3}
            ]
        }
    },
    computed: {
        fromDate() {
            if (this.powerStation) {
                return this.powerStation.created_at
            }
            let d = new Date()
            return d.toISOString()
        },
        toDate() {
            let d = new Date()
            return d.toISOString()
        },
        preselectedContract() {
            return this.light && this.light.contract ? this.light.contract : null
        }
    },
    mounted() {
        this.fetchDetails()
        this.fetchStations()
    },
    methods: {
        initValues() {
            this.light = {
                construction_year: this.getCommonValue('construction_year'),
                lighting_class: this.getCommonValue('lighting_class'),
                light_manufacturer: this.getCommonValue('light_manufacturer'),
                light_type: this.getCommonValue('light_type'),
                arm_length: this.getCommonValue('arm_length'),
                arm_type: this.getCommonValue('arm_type'),
                bulb_count: this.getCommonValue('bulb_count'),
                bulb_manufacturer: this.getCommonValue('bulb_manufacturer'),
                bulb_type: this.getCommonValue('bulb_type'),
                guarantee_basis: this.getCommonValue('guarantee_basis'),
                guarantee_expiration: this.timestampToDateInput(this.getCommonValue('guarantee_expiration')),
                guarantor: this.getCommonValue('guarantor'),
                installation_height: this.getCommonValue('installation_height'),
                pole_type: this.getCommonValue('pole_type'),
                supporting_structure: this.getCommonValue('supporting_structure'),
                stand: this.getCommonValue('stand'),
//                power_station: this.getCommonValue('power_station'),
                status: this.getCommonValue('status'),
                contract: this.getCommonValue('contract'),
                earthing: this.getCommonValue('earthing'),
                cabling: this.getCommonValue('cabling'),
                optics: this.getCommonValue('optics'),
                installation: this.getCommonValue('installation'),
                power_rating: this.getCommonValue('power_rating'),
                model: this.getCommonValue('model'),
                dimming_profile: this.getCommonValue('dimming_profile'),
                pole_manufacturer: this.getCommonValue('pole_manufacturer')
            }
        },
        getCommonValue(propName) {
            let value = null
            for (let i = 0; i < this.lightsDetails.length; i++ ) {
                let item = this.lightsDetails[i]
                if (!item[propName]) {
                    return null
                }
                if (value && typeof value !== typeof item[propName]) {
                    // Type of value is different than the item's property
                    return null
                }
                if (typeof item[propName] === 'object') {
                    let propertyValue = item[propName]['id']
                    if (value && propertyValue !== value.id) {
                        // Items have different values
                        return null
                    }
                } else {
                    let propertyValue = item[propName]
                    if (!propertyValue || (value && propertyValue !== value)) {
                        // Items have different values
                        return null
                    }
                }
                value = item[propName]
            }
            return value
        },
        fetchDetails() {
            this.loading = true
            this.stations = []
            this.restFetchParams(this.streetLightsDetailsUrl, {lights: this.getLightIds()}, this.handleDetails, () => {
                this.loading = false
            })
        },
        handleDetails(response) {
              if (response) {
                  this.lightsDetails = response.data
                  this.initValues()
              }
              this.loading = false
        },
        fetchStations() {
            this.loading = true
            this.stations = []
            this.restFetch(this.powerStationUrl, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            if (response && response.data) {
                response.data.forEach(station => {
                    this.stations.push({value: station.id, text: station.id + ' ' + station.street})
                })
            }
            this.loading = false
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        updateLights: function () {
            this.loading = true
            if (this.contract) {
                this.light.contract = this.contract
            }
            let params = {
                data: this.light,
                lights : this.getLightIds()
            }
            this.restUpdate(this.streetLightBulkUrl, params, this.success, this.fail)
        },
        getLightIds() {
            return this.streetLights.map((item) => item.id)
        },
        success: function () {
            this.loading = false
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_add_failed'))
        },
        setContract: function (contract) {
            this.contract = contract
        }
    }
}
</script>
<style>
@import "../../styles/modal.css";
</style>
