<template>
    <div >
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header" v-if="!vehicleId">
                <span class="title">
                    {{ $t('trip.trip') }}
                </span>
            </div>
        </b-navbar>

        <vehicle-trips
            v-if="vehicleId"
            :user="user"
            :vehicle-id="vehicleId"
            @search="searchTrips"
            />
        <trip-search
            v-if="isAdmin || isObserver"
            :user="user"
            @search="searchTrips"
            @areaGroupSelected="onAreaGroupSelected"
            @areaSelect="customAreaSelection"
        />
        <trip-search-map-viewer
            v-if="isMapViewer"
            @search="searchTrips"
        />

        <trip-search-worker
            v-if="isWorker"
            :user="user"
            @search="searchTrips"
        />
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <div class="button-container col-sm-12 trip-buttons">
            <b-button
                class="result-button"
                variant="success"
                :disabled="!showMap && trips.length < 1"
                @click="toggleMap"
            >
                {{ this.$t(showMap ? 'trip.show_table' : 'trip.show_map') }}
            </b-button>
            <b-button
                v-if="!isMapViewer && showMap &&!loading"
                class="result-button"
                variant="success"
                :disabled="selectedTrip == null"
                @click="simulateTrip"
            >
                {{ $t('trip.simulate_trip') }}
            </b-button>
            <b-button
                v-if="!isMapViewer && !showMap"
                variant="outline-success"
                class="result-button"
                :disabled="trips.length < 1"
                @click="exportGeojson"
            >
                {{ $t('trip.download_geojson') }}
            </b-button>
            <b-button
                v-if="!isMapViewer && !showMap"
                variant="outline-success"
                class="result-button"
                :disabled="trips.length < 1"
                @click="exportExcel"
            >
                {{ $t('trip.download_excel') }}
            </b-button>
            <b-button
                v-if="!isMapViewer && !showMap"
                variant="outline-primary"
                :disabled="trips.length < 1"
                class="result-button"
                @click="print"
            >
                {{ $t('observations.details_report') }}
            </b-button>        </div>
        <div class="trip-results">
            <!-- Basic map view and trip layer -->
            <div class="col-sm-12 area-map-row " v-if="showMap">
                <div class="map-container" style="min-height: 40em;" >
                    <map-container
                        :find-user="false"
                        ref="mapContainer"
                        @onPolylineTap="onPolylineTap"
                        @onMapInfoClosed="onMapInfoClosed"
                        @onMapClicked="mapClicked"
                        @onPolylineHover="onPolylineHover"
                        @onPolylineHoverExit="onPolylineHoverExit"
                        @onMarkerPointerEnter="onMarkerPointerEnter"
                        @onDrag="onDrag"
                        @onDragEnd="onDragEnd"
                        @onMapChanged="mapChanged"
                        :menu-items="menuItems"
                    />
                    <trip-map
                        style="margin-top: 7em"
                        v-if="map"
                        ref="tripMap"
                        :trip-results="trips"
                        :trip-item="selectedTrip"
                        :map="map"
                        :area-group="selectedAreaGroup"
                        :custom-area="customAreaSelect"
                        :point-hover="true"
                    />
            </div>
            </div>
            <div class="map-controls" v-if="showMap">
                <div class="map-controls__list col-12 col-lg-4 nopads mt-2">
                    <measure-distance-map
                        v-if="measureDistanceEnabled"
                        ref="measureDistances"
                        :map="map"
                        :measure-distance-results="measureDistances"
                        :measure-distance-target-item="measureDistanceTargetItem"
                        :measure-distance-target-item-callback="measureDistanceTargetItemCallback"
                        :draggable="true"
                        @onRemoveMeasureDistances="removeMeasureDistances"
                    />
                    <measure-road-map
                        v-if="measureRoadEnabled"
                        ref="measureRoad"
                        :map="map"
                        :hide-make-new-observation-button="true"
                        :draggable="true"
                        @close="measureRoadEnabled = false"
                        @confirmSelection="createLineObservation"
                    />
                </div>
            </div>
            <map-layer-selector
                v-if="mapLayerSelector"
                @close="mapLayerSelector = false"
                @onMapSelected="onMapSelected"/>
            <!-- Result table -->
            <div
                v-if="!showMap"
                id="tripResultContainer"
                class="col-sm-12 list-container"
            >
                <div v-if="totalDuration && totalKilometers" class="col-12 nopads" style="background: #FFFFFF; box-shadow: #d9d9d9 1px 1px 4px; border-radius: .5em">
                    <div class="col-sm-3 item-detail-container">
                        <div class="item-detail-title">
                            {{ $t('trip_list.total_trip_length') + ' ' + '(km)' }}
                        </div>
                        <div class="item-detail-text">
                            <span>{{ totalKilometers }}</span>
                        </div>
                    </div>
                    <div class="col-sm-9 item-detail-container">
                        <div class="item-detail-title">
                            {{ $t('trip_list.total_duration') + ' ' + '(h)' }}
                        </div>
                        <div class="item-detail-text">
                            <span>{{ totalDuration }}</span>
                        </div>
                    </div>
                </div>
                <trip-list
                    :trips="trips"
                    :is-map-viewer="isMapViewer"
                    @row-clicked="showTripOnMap"
                    @downloadGeometry="downloadGeometry"
                    @downloadGeoJson="downloadGeoJson"/>
                <div class="print-only">
                    <span class="item-detail-text">  {{ $t('common.printed') }} {{ $d(new Date()) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import TripMap from './TripMap'
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from '../mixins/MapMixin'
import {geometryMixin} from '@/components/mixins/GeometryMixin';
import {measureDistanceMixin} from "../mixins/MeasureDistanceMixin";
import {downloadHelper} from '../mixins/DownloadMixin'
import {vehicleHelper} from '../mixins/VehicleMixin'
import {timeUtils} from '../mixins/TimeUtils'
import MeasureDistanceMap from "../map/MeasureDistanceMap";
import MeasureRoadMap from "../map/MeasureRoadMap";
import TripSearchWorker from "./TripSearchWorker";
import TripSearch from "./TripSearch";
import MapContainer from "../map/MapContainer";
import TripList from "./TripList";
import MapLayerSelector from "../map/MapLayerSelector";
import {coordinateConverter} from "@/components/mixins/CoordinateConversionMixin";
import {printHelper} from '../mixins/PrintMixin'
import {Printd} from "printd";
import TripSearchMapViewer from "@/components/trip/TripSearchMapViewer";
import VehicleTrips from "@/components/vehicle/tabbed/VehicleTrips.vue";


export default {
    name: 'Trips',
    components: {
        VehicleTrips,
        TripSearchMapViewer,
        MapLayerSelector,
        TripList, MapContainer, TripSearch, TripSearchWorker, TripMap, MeasureDistanceMap, MeasureRoadMap},
    mixins: [timeUtils, restApi, vehicleHelper, downloadHelper, mapHelper, measureDistanceMixin, coordinateConverter, printHelper, geometryMixin],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        isWorker: {
            type: Boolean,
            default: false
        },
        isMapViewer: {
            type: Boolean,
            default: false
        },
        vehicleId: Number,

    },
    data() {
        return {
            map: null,
            exportFields: [],
            showMap: false,
            lastParams: null,
            trips: [],
            measureRoadEnabled: false,
            measureDistances: [],
            measureDistanceEnabled: false,
            measureDistanceTargetItem: null,
            showObservationWizardOnCoord: null,
            loading: 0,
            selectedTripId: null,
            selectedAreaGroup: null,
            mapLayerSelector: false,
            mapData: null,
            customAreaSelect: null,
        }
    },
    computed: {
        selectedTrip() {
            return this.trips.find((trip) => trip.id === this.selectedTripId);
        },
        totalKilometers() {
            let totalAmount = 0
            this.trips && this.trips.forEach((trip) => {
                totalAmount += trip.length
            })
            return totalAmount ? totalAmount.toFixed(2) : null
        },
        totalDuration() {
            let totalDuration = 0
            this.trips && this.trips.forEach((trip) => {
                let duration_min = this.getDurationInMinutes(trip.start_time, trip.end_time);
                totalDuration += duration_min
            })
            return totalDuration ? this.getWorkTime(totalDuration) : null
        },
        menuItems() {
            return [
                !this.measureDistanceEnabled ? {
                    text: this.$t('map.measure_distance'),
                    onClick: this.initMeasureDistance
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureDistances
                },
                !this.measureRoadEnabled ? {
                    text: this.$t('map.measure_road'),
                    onClick: this.initMeasureRoad
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureRoads
                },
                {
                    text: this.$t('map.layers'),
                    onClick: this.showMapLayerSelector
                },
            ]
        }
    },
    mounted() {
        this.d = new Printd()
    },

    methods: {

        customAreaSelection: function (data) {
            this.customAreaSelect = data;
        },

        mapClicked: function (coord) {
            if (this.measureDistanceEnabled) {
                this.addMeasureDistance(coord)
            }

            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(coord)
            }
        },
        onMapInfoClosed: function () {
            if (this.mapVisible) {
                this.$refs.tripMap.onMapInfoClosed()
            }
        },
        async searchTrips(params) {
            this.lastParams = params
            this.lastParams.with_geom = this.showMap ? 1 : 0
            this.lastParams.with_geom = this.showMap ? 1 : 0
            this.lastParams.timestamps = this.showMap ? 1 : 0
            await this.loadTrips(this.lastParams)
        },
        async loadTrips(params) {
            if (this.showMap && this.$refs.tripMap) {
                this.$refs.tripMap.hideTrips();
            }
            this.lastParams = params;
            this.loading++;
            const { data, err } = await this.fetchTrips(params).catch((err) => ({err}));
            this.loading--;
            if (err) {
                if (err.response.status && err.response.status === 500) {
                    EventBus.$emit('show-alert', this.$t('trip_list.err_too_much'))
                } else {
                    EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
                }
                return;
            }
            this.trips = data.map((trip) => {
                // Ensure times will have timezone information. If not, then set as UTC!
                trip.start_time = this.setTime(trip.start_time)
                trip.end_time = this.setTime(trip.end_time)
                return trip;
            })
        },

        fetchTrips(params) {
            return this.axios.get(this.tripUrl, {params})
        },

        simulateTrip() {
            this.$refs.tripMap.showMovingCar()
        },

        async fetchTripGeometries() {
            const tripsHaveGeometry = this.trips.some((trip) => trip.geometry);
            if (this.lastParams !== undefined && !tripsHaveGeometry) {
                // Search trips again with geometries
                this.lastParams.with_geom = 1
                this.lastParams.timestamps = 1
                this.lastParams.speed = 1
                await this.loadTrips(this.lastParams);
            }
        },

        async toggleMap(state = null) {
            if (this.showMap === state) return;
            // Toggle state if none given
            if (typeof state !== "boolean") state = !this.showMap;
            this.showMap = state;
            if (this.showMap) {
                await this.fetchTripGeometries()
                await this.$nextTick();
                this.map = this.$refs.mapContainer.getMap()
                if (this.user && this.user.company.location) {
                    this.map.zoomToPosition(this.user.company.location.y, this.user.company.location.x)
                }
            } else {
                this.$refs.tripMap.stopUpdateCarPosition()
                this.map = null
                this.selectedTripId = null
                this.measureDistanceEnabled = false
                this.measureRoadEnabled = false
            }
        },

        print() {
            if (!this.loading) {
                this.d.print(document.getElementById('tripResultContainer'), this.printStyle)
            }
        },

        async exportExcel() {
            this.loading++;
            const { err, ...response } = await this.fetchExcel().catch((err) => ({err}));
            this.loading--;
            if (err) {
                EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
                return;
            }
            this.downloadFile(response, this.$t('trip.report_file_name'))
        },

        async exportGeojson() {
            this.loading++;
            const { err, ...response } = await this.fetchGeojson().catch((err) => ({err}));
            this.loading--;
            if(err) {
                EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
                return;
            }
            this.downloadFile(response, this.$t('trip.trips_json_file_name'))
        },

        fetchGeojson() {
            let params = this.getCommonReportParams(this.lastParams)
            params.with_geom = 1
            return this.axios.get(
                this.tripGeoJsonUrl,
                {
                    params: params,
                    responseType: 'arraybuffer'
                }
            )
        },

        fetchExcel() {
            return this.axios.get(
                this.tripReportUrl,
                {
                    params: this.getCommonReportParams(this.lastParams),
                    responseType: 'arraybuffer'
                }
            )
        },

        onAreaGroupSelected(group) {
            this.selectedAreaGroup = group
        },

        onPolylineTap: function (data) {
            this.selectedTripId = data.id;
            // Pass to visible function
            if (this.showMap) {
                this.$refs.tripMap.onPolylineTap(data)
            }
        },

        onDrag: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
        },

        onDragEnd: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(data)
            }
        },

        onPolylineHover: function (data, latlng, index) {
            this.map.removeGeoLabels("pointHoverLabel");
            if(this.selectedTrip && this.selectedTrip && data && index) {
                let time = this.selectedTrip.timestamps.length > 0 ? this.getDateStringHoursMinutesSeconds(this.selectedTrip.timestamps[index]) : ""
                let taskType = this.selectedTrip.order
                let label = ''
                if(time) {
                    label += `<div class="nopads pl-2" style="font-style: italic; color: #949494">${time}</div>`
                }
                label += `<span class="nopads">${taskType}</span><br>`
                this.map.addGeoLabel(latlng.lat, latlng.lng, label, "pointHoverLabel")
            }
        },

        onPolylineHoverExit() {
            this.map.removeGeoLabels("pointHoverLabel");
        },

        onMarkerPointerEnter: function (data) {
            switch (data.type) {
                case this.OBSERVATION:
                    this.showObservationTitle(data)
                    break
            }
        },

        showTripOnMap(tripId) {
            this.selectedTripId = tripId;
            if (this.selectedTrip) {
                this.toggleMap(true);
            }
        },

        showMapLayerSelector: function () {
            this.mapLayerSelector = true
        },

        async onMapSelected(layer) {
            this.loading = true
            await this.$refs.mapContainer.setMap(layer);
            await this.$refs.mapContainer.saveMapSettings();
            this.mapLayerSelector = false
            this.loading = false
        },

        mapChanged(map) {
            this.map = map
        },

        async downloadGeoJson(id) {
            let trip = this.trips.find(item => item.id === id);
            if (trip) {
                await this.fetchTripGeometries();
                // Re-assign with geometries
                let trip = this.trips.find(item => item.id === id);
                let geoJsonData = this.getGeometryAsGeoJson(trip);
                if (geoJsonData) {
                    // Generate GeoJSON and download
                    let blob = new Blob([JSON.stringify(geoJsonData)], { type: 'application/json;charset=utf-8;' });
                    let url = URL.createObjectURL(blob);
                    let pom = document.createElement('a');
                    pom.href = url;
                    pom.setAttribute('download', 'Routa_geometry.geojson');
                    pom.click();
                }
            }
        },

        async downloadGeometry (id) {
            let trip = this.trips.find(item => item.id === id)
            if (trip) {
                await this.fetchTripGeometries()
                // Re-assign with geometries
                let trip = this.trips.find(item => item.id === id)
                let csvData = this.getGeometryAsCsv(trip)
                if (csvData) {
                    // Generate csv and download
                    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                    let url = URL.createObjectURL(blob);
                    let pom = document.createElement('a');
                    pom.href = url;
                    pom.setAttribute('download', 'Routa_geometry.csv');
                    pom.click();
                }
            }
        },

        getGeometryAsGeoJson: function (trip) {
            let result = []
            if (trip.geometry) {
                trip.geometry.forEach(point => {
                    result.push([point['x'], point['y']])
                })

            }
            return this.addFeatureCollection(result)
        },

        getGeometryAsCsv: function (trip) {
            let result = ''
            if (trip.geometry) {
                trip.geometry.forEach(point => {
                    let tm35FinCoords = this.wgs84ToTm35Fin(point['x'], point['y'])
                    result += tm35FinCoords[0] + ',' + tm35FinCoords[1] + '\r\n'
                })
            }
            return result
        }

    }
}
</script>
