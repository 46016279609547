<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            @click="closeAndUpdate"
        >
            <div
                v-if="!user && !certificate"
                class="header"
            >
                <span class="title">{{ $t('menu.admin_users') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="closeAndUpdate"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer">{{ $t('common.back') }}</span>
            </div>
        </b-navbar>
        <!-- Company filter for admin -->
        <transition name="fade">
            <div class="col-sm-12">
                <keep-alive>
                    <company-filter
                        v-if="isAdmin && !user && !certificate"
                        v-model="company"
                    />
                </keep-alive>
            </div>
        </transition>

        <!-- Add certificates for many users at once -->
        <transition name="fade">
            <div class="col-sm-12">
                <div
                    v-if="!user && !certificate"
                    class="data-list-item add"
                    @click.stop="addCertificate">
                    <div class="item-img-cont add-title">
                        <i class="fa fa-plus"/>
                    </div>
                    <div class="item-text-cont">
                        <span class="item-title-add">{{ $t('certificate.add_multiple') }}</span>
                        <font-awesome-icon
                            icon="certificate"
                            class="card-body-icon"
                        />
                    </div>
                </div>
            </div>
        </transition>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <user-list
                    v-if="!user && !certificate"
                    :can-add="false"
                    :results="results"
                    @edit="editUser"
                />
            </transition>
            <transition name="fade">
                <users-certificate-list
                    v-if="user && !certificate"
                    :user="user"
                    @edit="editCertificate"
                    @add="addCertificate"
                />
            </transition>
            <transition name="fade">
                <certificate-editor
                    v-if="certificate"
                    :user="user"
                    :results="results"
                    :certificate="certificate"
                    @closeAndUpdate="closeEditorAndUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '.././mixins/RestApiMixin'
import UserList from '../user/UserList'
import CompanyFilter from '../company/CompanyFilter'
import UsersCertificateList from './UsersCertificateList'
import CertificateEditor from './CertificateEditor'

export default {
    name: 'UsersCertificate',
    components: {UserList, CompanyFilter, UsersCertificateList, CertificateEditor},
    mixins: [restApi],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            results: [],
            user: null,
            certificate: null,
            loading: true,
            company: null
        }
    },
    watch: {
        company: function () {
            this.fetchUsers()
        }
    },
    mounted: function () {
        this.fetchUsers()
    },
    methods: {
        fetchUsers: function () {
            this.loading = true
            this.results = []
            this.restFetchParams(this.userUrl, {company: this.company}, this.handleResponse)
        },

        handleResponse: function (response) {
            this.results = response.data
            this.loading = false
        },

        editUser: function (user) {
            this.user = user
        },
        closeEditorAndUpdate: function () {
            this.certificate = null
            this.user = null
            this.closeAndUpdate()
        },
        closeAndUpdate: function () {
            if (this.certificate) {
                this.certificate = null
            } else {
                this.user = null
                this.fetchUsers(this.company)
            }
        },
        editCertificate: function (certificate) {
            this.certificate = certificate
        },
        addCertificate: function () {
            this.certificate = {
                user: this.user,
                id: -1,
                granted_day: null,
                expiration_day: null,
                type: null
            }
        }
    }
}
</script>
