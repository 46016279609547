<template>
    <div class="search-form" style="border: none">
        <b-form
            @submit.prevent="onSubmit"
            @reset="onReset"
        >
            <b-form-group
                class="col-sm-12"
                label-for="start"
            >
                <company-filter
                    v-if="isAdmin"
                    v-model="company"
                />
            </b-form-group>
            <b-row class="nopads">
                <b-row class="nopads">
                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('ins_report.from_date') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="from_date"
                        >
                            <b-form-input
                                id="from_date"
                                v-model="fromDate"
                                type="date"
                                class="no-margin"
                                size="sm"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('ins_report.to_date') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="to_date"
                        >
                            <b-form-input
                                id="to_date"
                                v-model="toDate"
                                type="date"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                </b-row>
            </b-row>
            <!-- User selection multiselect -->
            <b-row class="nopads">
                <div class="col-sm-6">
                    <span class="search-title">{{ $t('work_time.person') }}</span>
                    <multiselect
                        v-model="selectedUsers"
                        class="multiselect_routa"
                        :placeholder="$t('work_time.select_person')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="false"
                        :multiple="true"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="users"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                                <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </b-row>

            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import Multiselect from 'vue-multiselect'
import {timeUtils} from '../mixins/TimeUtils'
import CompanyFilter from '../company/CompanyFilter'
import {EventBus} from '@/event-bus'

export default {
    name: 'WorkdayDetailsSearch',
    components: {CompanyFilter, Multiselect},
    mixins: [restApi, timeUtils],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            users: [],
            selectedUsers: [],
            fromDate: '',
            toDate: '',
            company: null
        }
    },
    mounted: function () {
        this.fetchUsers()
        if (!this.fromDate) {
            this.fromDate = this.timestampToDateInput(Date.now())
        }
        if (!this.toDate) {
            this.toDate = this.timestampToDateInput(Date.now())
        }
    },
    methods: {
        setCompany: function (c) {
            this.company = c
        },
        fetchUsers: function () {
            this.users = []
            this.restFetch(this.userUrl, this.handleUserResponse)
        },

        handleUserResponse: function (response) {
            if (response && response.data) {
                response.data.forEach(function (user) {
                    this.users.push({
                        value: user.id,
                        name: user.last_name + ', ' + user.first_name,
                        company: user.company ? user.company.name : '',
                        email: user.email
                    })
                }, this)
            }
            this.loading = false
        },

        onSubmit: function () {
            var params = {}
            if (this.selectedUsers.length > 0) {
                let results = []
                this.selectedUsers.forEach(function (item) {
                    results.push(item.value)
                }, this)
                params.users = results
            }
            if (this.fromDate.trim()) {
                params.from = this.fromDate
            } else {
                EventBus.$emit('show-alert', this.$t('work_time.error_start_missing'))
                return null
            }
            if (this.toDate.trim()) {
                params.to = this.getEndOfTheDay(this.toDate).toISOString()
            } else {
                EventBus.$emit('show-alert', this.$t('work_time.error_end_missing'))
                return null
            }
            if (this.isAdmin) {
                if (this.company) {
                    params.company = this.company
                } else {
                    EventBus.$emit('show-alert', this.$t('work_time.error_company_missing'))
                    return null
                }
            }
            params.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
            this.$emit('search', params)
        },

        onReset: function () {
            this.selectedUsers = []
        },

        resetTime: function () {
            this.year = new Date().getFullYear()
            this.month = new Date().getMonth() + 1
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
