<template>
    <div class="col-sm-12 list-container mobile-container">
        <transition name="fade">
            <div
                v-if="results && results.length"
                id="accordion"
                class="col-sm-12 panel-group data-container"
            >
                <div
                    v-for="result of results"
                    :key="result.id"
                    v-b-toggle="'accordion-'+result.id"
                    class="data-list-item"
                >
                    <div class="col-sm-12 item-header nopads">
                        <span class="item-title nopads">{{ getTitle(result) }}</span>
                        <span
                            class="item-text nopads"
                            v-bind:class="{new : result.status === 3, warning : result.status === 2}"
                        >
                            {{ getStatusString(result.status) }}
                        </span>
                    </div>
                    <b-collapse
                        :id="'accordion-'+result.id"
                        class="mt-2 item-details p-1"
                    >
                        <div class="col-sm-12 nopads">
                            <div class="item-detail-title">
                                {{ $t('work_assignment.address') }}
                            </div>
                            <div class="item-detail-text">
                                <span v-if="result.street_address || result.city">{{
                                        result.street_address
                                    }} {{ result.city }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                        <div class="col-sm-12 nopads">
                            <div class="item-detail-title">
                                {{ $t('work_assignment.info') }}
                            </div>
                            <div class="item-detail-text">
                                <span v-if="result.info">{{ result.info }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                        <!-- Observation data -->
                        <work-assignment-observation
                            v-if="result.observation"
                            :observation="result.observation"/>
                        <!-- Area defect data -->
                        <work-assignment-area-defect
                            v-if="result.area_defect"
                            :defect="result.area_defect"/>
                        <!-- Vehicle defect data -->
                        <work-assignment-vehicle-defect
                            v-if="result.vehicle_defect"
                            :defect="result.vehicle_defect"/>
                        <!-- WorkPermission data -->
                        <work-assignment-work-permission
                            v-if="result.special_work_permission"
                            :work-permission="result.special_work_permission"/>
                        <!-- Buttons -->
                        <div class="col-sm-12 button-container">
                            <b-button
                                v-if="result.status === OPEN"
                                variant="outline-primary"
                                class="result-button"
                                @click.stop="assignAssignment(result)"
                            >
                                <span class="button-text">{{ $t('work_assignment.self_assign') }}</span>
                            </b-button>
                            <b-button
                                v-if="result.status === ASSIGNED || result.status === OPEN"
                                variant="primary"
                                class="result-button"
                                @click.stop="startAssignment(result)"
                            >
                                <span class="button-text">{{ $t('work_assignment.start') }}</span>
                            </b-button>
                            <b-button
                                variant="success"
                                class="result-button"
                                @click.stop="completeAssignment(result)"
                            >
                                <span class="button-text">{{ $t('work_assignment.ready') }}</span>
                            </b-button>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </transition>
        <div v-if="loading"
             id="loader"
             class="spinner">
        </div>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'
import {workAssignmentHelper} from '../mixins/WorkAssignmentMixin'
import {workPermissionHelper} from '../mixins/WorkPermissionMixin'
import {EventBus} from '@/event-bus'
import WorkAssignmentObservation from './WorkAssignmentObservation'
import WorkAssignmentAreaDefect from './WorkAssignmentAreaDefect'
import WorkAssignmentVehicleDefect from './WorkAssignmentVehicleDefect'
import WorkAssignmentWorkPermission from './WorkAssignmentWorkPermission'

export default {
    name: 'WorkAssignmentMobileList',
    components: {
        WorkAssignmentVehicleDefect,
        WorkAssignmentAreaDefect,
        WorkAssignmentObservation,
        WorkAssignmentWorkPermission
    },
    mixins: [timeUtils, workAssignmentHelper, restApi, workPermissionHelper],
    data: function () {
        return {
            loading: false,
        }
    },
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        getTitle: function (item) {
            if (item.observation) {
                return item.observation.type.name
            }
            if (item.area_defect && !item.area_defect.area_equipment) {
                return item.area_defect.area.name
            }
            if (item.area_defect) {
                return item.area_defect.area.name + ': ' + item.area_defect.area_equipment.name
            }
            if (item.vehicle_defect) {
                return item.vehicle_defect.vehicle.license_plate + ' - ' + item.vehicle_defect.inspectable_name
            }
            if (item.special_work_permission) {
                let result = this.getTypeString(item.special_work_permission.type)
                if (item.contract) {
                    result += ' (' + item.contract.name + ')'
                }
                return result
            }
            if (item.contract) {
                return item.contract.name + " (" + item.contract.customer.name + " - " + item.contract.contractor.name + ")"
            }
            return item.info
        },
        startAssignment: function (assignment) {
            this.loading = true
            this.restUpdate(this.workAssignmentStartUrl + '/' + assignment.id, {}, this.handleResponse, this.handleError)
        },
        completeAssignment: function (assignment) {
            this.loading = true
            this.restUpdate(this.workAssignmentCompleteUrl + '/' + assignment.id, {}, this.handleResponse, this.handleError)
        },
        assignAssignment: function (assignment) {
            this.loading = true
            this.restUpdate(this.workAssignmentAssignUrl + '/' + assignment.id, {}, this.handleResponse, this.handleError)
        },
        handleResponse: function () {
            this.loading = false
            this.$emit('closeAndUpdate')
        },
        handleError: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        }
    }
}
</script>
