
export const fenceHelper = {
    methods: {
        getFenceTypes: function () {
            return [
                {name: this.$t('fence.select_type'), value: null},
                {name: this.$t('fence.type_protective'), value: fenceTypes.PROTECTIVE},
                {name: this.$t('fence.type_game'), value: fenceTypes.GAME},
                {name: this.$t('fence.type_snow'), value: fenceTypes.SNOW},
                {name: this.$t('fence.type_other'), value: fenceTypes.OTHER},
                {name: this.$t('fence.type_protective_fence_for_rock_cuts'), value: fenceTypes.PROTECTIVE_FENCE_FOR_ROCK_CUTS},
                {name: this.$t('fence.type_fence_preventing_crossing_the_traffic_lane'), value: fenceTypes.FENCE_PREVENTING_CROSSING_THE_TRAFFIC_LANE}
            ]
        },
    }
}

export const fenceTypes = {
    PROTECTIVE: 1,
    GAME: 2,
    SNOW: 3,
    OTHER: 5,
    PROTECTIVE_FENCE_FOR_ROCK_CUTS: 6,
    FENCE_PREVENTING_CROSSING_THE_TRAFFIC_LANE: 7
}

