export const workPermissionHelper = {
    data: function () {
        return {
            REQUESTED: 1,
            ACCEPTED: 2,
            REJECTED: 3,
            HOT_WORK: 1,
            HIGH_OFF_THE_GROUND: 2,
            LIFTING: 3,
            OTHER: 4
        }
    },
    methods: {
        newWorkPermission: function (like) {
            return {
                id: -1,
                info: '',
                type: null,
                contract: {id: null},
                ...like
            }
        },
        getStatus: function () {
            return [
                {name: this.$t('work_permission.requested'), value: this.REQUESTED},
                {name: this.$t('work_permission.accepted'), value: this.ACCEPTED},
                {name: this.$t('work_permission.rejected'), value: this.REJECTED}
            ]
        },
        getPermissionStatusString: function (status) {
            switch (status) {
                case this.REQUESTED:
                    return this.$t('work_permission.requested')
                case this.ACCEPTED:
                    return this.$t('work_permission.accepted')
                case this.REJECTED:
                    return this.$t('work_permission.rejected')
                default:
                    return status
            }
        },
        getTypeString: function (type) {
            switch (type) {
                case this.HOT_WORK:
                    return this.$t('work_permission.hot_work')
                case this.HIGH_OFF_THE_GROUND:
                    return this.$t('work_permission.high_off_the_ground')
                case this.LIFTING:
                    return this.$t('work_permission.lifting')
                case this.OTHER:
                    return this.$t('work_permission.other')
                default:
                    return type
            }
        },
        getTypes: function () {
            return [
                {name: this.$t('work_permission.hot_work'), value: this.HOT_WORK},
                {name: this.$t('work_permission.high_off_the_ground'), value: this.HIGH_OFF_THE_GROUND},
                {name: this.$t('work_permission.lifting'), value: this.LIFTING},
                {name: this.$t('work_permission.other'), value: this.OTHER}
            ]
        },
        getName: function (user) {
            if (!user) {
                return ''
            }
            return user.first_name + ' ' + user.last_name
        }
    }
}
