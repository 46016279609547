<template>
    <div class="col-sm-12 pb-3" style="min-height: 30em">
        <harja-search
            :contract-id="contractId"
            @search="handleData"
        />
        <b-row class="nopads">
            <div class="col-sm-6 nopads mb-2">
                <div
                    class="col-sm-12 stat-map-container nopads"
                    style="overflow: hidden; height: 40vh;"
                >
                    <map-container
                        ref="harjaMessages"
                        @onMapChanged="onMapChanged"
                        @onMarkerTap="markerTap"
                        :find-user="true"
                        :menu-enabled="false"
                    />
                </div>
            </div>
            <!-- CHART -->
            <div class="col-sm-6 nopads p-1 mb-2" style="overflow: hidden">
                <harja-message-chart
                :data="harjaData"
                />
            </div>
        </b-row>
        <harja-send-modal
            :harja-type="harjaType"
            :harja-id="harjaId"
            :contract="contractId"
        />
        <harja-messages-list
            :data="searchResults"
            @openModal="openModal"
            @openHarjaModal="openHarjaModal"
        />
        <transition name="fade">
            <harja-notification-modal
                v-if="editHarja !== null"
                style="pointer-events: all"
                @cancel="editHarja = null"
                :value="editHarja"
            />
        </transition>
    </div>
</template>

<script>

import {mapHelper} from "@/components/mixins/MapMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import MapContainer from "@/components/map/MapContainer.vue";
import harjaSearch from "@/components/harja/HarjaSearch.vue";
import HarjaMessagesList from "@/components/harja/HarjaMessagesList.vue";
import harjaNotificationModal from "@/components/velho/HarjaNotificationModal.vue";
import HarjaMessageChart from "@/components/harja/HarjaMessageChart.vue";
import HarjaSendModal from "@/components/harja/HarjaSendModal";

export default {
    name: 'harjaMessages',
    mixins: [mapHelper,restApi],
    components: {
        HarjaSendModal, HarjaMessageChart, harjaNotificationModal, HarjaMessagesList, harjaSearch, MapContainer},
    props: {
        item: {
            type: Object,
            default: null
        },
        contractId: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            map: null,
            loading: false,
            searchResults: null,
            editHarja: null,
            harjaData: null,
            harjaType: null,
            harjaId: null
        }
    },
    watch: {
        searchResults(val) {
            this.hideMarkers()
            this.showMarkers()
            this.harjaData = []
            val.forEach(dat => {
                this.harjaData.push(
                    dat.ilmoitus)
            })
        }
    },

    methods: {
        openHarjaModal(type, id) {
            this.harjaType = type
            this.harjaId = id
            this.$bvModal.show("harjaPutModal")
        },
        onMapChanged(map) {
            this.map = map;
        },
        openModal: function (data) {
            this.editHarja = data
        },
        markerTap: function (marker) {
             this.editHarja = this.getHarjaAlertId(marker.id)
        },
        getHarjaAlertId(id) {
            return this.editHarja = this.searchResults[id].ilmoitus
        },
        showMarkers() {
            for (let index in this.searchResults) {
                if (this.searchResults[index] && this.map != null) {
                    let harjaLocation = this.searchResults[index].ilmoitus.sijainti;
                    this.map.showMapMarker(index, this.HARJA_MESSAGES, harjaLocation.koordinaatit.y,
                        harjaLocation.koordinaatit.x, this.map.getMarkerStore().getHarjaNotificationMarkerIcon())
                }
            }
        },
        hideMarkers() {
            if (this.map != null) {
                this.map.removeMapMarkerByType(this.HARJA_MESSAGES)
            }
        },
        handleData: function (data) {
            if (!data) {
                return
            }
            this.searchResults = data
        },
        close: function () {
            this.$emit('close')
        },
    }
}
</script>

<style scoped>

</style>
